import React, { Component } from 'react';
import Wrapper from '@common/decorators/Wrapper';

import { Link } from 'react-router-dom';

import { Card, Icon } from 'antd';

import AccreditamentoServices from '../services/Accreditamento';

import FileSaver from 'file-saver';

import LogoForm from '@common/components/logoForm';


@Wrapper({ route: 'PdfRequest' })
class PdfRequest extends Component {
	state = {
		loading: true,
		success: false,
		error: false
	}
	componentDidMount() {
		setTimeout(() => AccreditamentoServices.pdfRequest(this.props.match.params.id, this.props.match.params.pwd, (res) => {
			const blob = new Blob([res.data], { type: "application/pdf;charset=utf-8" });
			FileSaver.saveAs(blob, "richiesta_iscrizione_" + this.props.match.params.id + "_.pdf");
			this.setState({ loading: false, success: true })
		}, (err) => this.setState({ loading: false, error: true })), 1000)
	}


	render() {

		return <div style={{ width: 400, margin: '40px auto' }}>
			<LogoForm />
			<p style={{ textAlign: 'center' }}>Piattaforma di gestione richieste di<br /> accreditamento per organizzazioni di volontariato</p>
			<Card
				className="shadow"
				bordered={false}
			>
				<p style={{
					margin: '40px auto',
					width: '320px',
					textAlign: 'center',
					fontSize: 18
				}}>{(!this.state.success && !this.state.error) ? "Il download inizierà a breve" : "Richiesta completata"}</p>
				{(!this.state.success && !this.state.error) ? <p style={{ textAlign: 'center' }}><Icon type="loading" theme="outlined" style={{ fontSize: 24 }} /></p> : null}
				{(this.state.error) ? <p style={{
					margin: '40px auto',
					width: '320px',
					textAlign: 'center',
					fontSize: 18
				}}>Si è verificato un errore nel caricamento del file, riprova più tardi</p> : null}
				<Link to="/login" className="login-form-forgot" style={{ float: 'right' }}>Accedi</Link>
			</Card>
		</div>
	}
}

export default PdfRequest;
