import React, { Component } from 'react';

import { message } from 'antd';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import Documenti from '@common/components/Documenti';
import allegatiStatic from '@common/utils/static/allegati'

import moment from 'moment';

class Files extends Component {

  state = {
    loaded: false,
    required_clausole: false,
    titolo_uso_locali: 1,
    required_files: [],
    medias: []
  }

 /* UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.media !== this.props.media ||
      this.props.titolo_uso_locali !== newProps.titolo_uso_locali ||
      this.props.required_clausole !== newProps.required_clausole) this.setState({
        medias: newProps.media,
        titolo_uso_locali: newProps.titolo_uso_locali,
        required_clausole: newProps.required_clausole
      })
  }
*/

  componentDidUpdate(prevProps, prevState){


    if (prevProps.media !== this.props.media ||
      this.props.titolo_uso_locali !== prevProps.titolo_uso_locali ||
      this.props.required_clausole !== prevProps.required_clausole) this.setState({
        medias: this.props.media,
        titolo_uso_locali: this.props.titolo_uso_locali,
        required_clausole: this.props.required_clausole
      })
  }

  componentDidMount() {

    //let req_files = [];

    this.setState({
      loaded: true,
      medias: this.props.media || [],
      titolo_uso_locali: this.props.titolo_uso_locali || 1,
      required_clausole: this.props.required_clausole || false
    });
  }

  updateClausoleRisolutorie(value) {
    this.setState({
      required_clausole: value
    })
  }

  updateTitoloUsoLocali(value) {
    this.setState({
      titolo_uso_locali: value
    })
  }

  getMediaClausoleTypes() {
    return allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el =>
      el.dominio === 'sede' && el.sottodominio === 'clausole_risolutorie'
    ), this.props.accreditamento.tipo_accreditamento)
  }

  getMediaContrattoTypes() {
    return allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el =>
      el.dominio === 'sede' && el.sottodominio === 'locale_contratto' && el.codice == 'CONTRATTO'
    ), this.props.accreditamento.tipo_accreditamento)
  }

  getMediaDisponibilitaTypes() {
    return allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el =>
      el.dominio === 'sede' && el.sottodominio === 'locale_contratto' && el.codice == 'ESCLUSIVITA'
    ), this.props.accreditamento.tipo_accreditamento)
  }

  getCaratteristicheTypes() {
    return allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el =>
      el.dominio === 'sede' && el.sottodominio === 'caratteristiche_sede'
    ), this.props.accreditamento.tipo_accreditamento)
  }

  async validate() {
    return new Promise((resolve) => {
      let valid = true;

      if (this.state.required_clausole) {
        if (!this.state.medias.find(el => el.tipo.sottodominio === 'clausole_risolutorie' && parseInt(el.deleted) !== 1)) {
          valid = false;
          message.error('Inserisci il documento con le clausole risolutorie');
        }
      }


      let contratto = this.state.medias.find(el => el.tipo.sottodominio === 'locale_contratto' && el.tipo.codice == 'CONTRATTO' && parseInt(el.deleted) !== 1);
      if (!contratto) {
        valid = false;
        message.error('Inserisci il contratto di disponibilità dell\'immobile');
      }

      let esclusivita = this.state.medias.find(el => el.tipo.sottodominio === 'locale_contratto' && el.tipo.codice == 'ESCLUSIVITA' && parseInt(el.deleted) !== 1);
      if (!esclusivita) {
        valid = false;
        message.error('Inserisci l\'autocertificazione esclusività della sede VI.1.1 Allegato tecnico');
      }


      /**
       * Valido la data del contratto
       */
       if (valid && this.state.titolo_uso_locali !== 1) {

        if (contratto.valido_al && contratto.valido_dal) {

          let dt_contratto = moment(contratto.valido_al, 'YYYY-MM-DD');
          let dt2_contratto = moment(contratto.valido_dal, 'YYYY-MM-DD');

          let min_months = (this.props.accreditamento.tipo === accreditamentoStatic.definitivo) ? 12 : 24;
          //console.log('months', min_months, dt_contratto.diff( moment(), "months" ) )
          if (dt_contratto.diff(dt2_contratto, "months") < min_months) {
            message.error('Data di validità del contratto non valida, minimo ' + min_months + ' mesi')
            valid = false;
          }

        } else {
          valid = false;
          message.error('Inserisci la validità del contratto');
        }

      }
      resolve(valid)
    });
  }

  render() {

    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;
    const mediaTypesContratto = this.getMediaContrattoTypes();
    const mediaTypesDisponibilita = this.getMediaDisponibilitaTypes();
    const allMedias = this.props.media.filter(el => el.tipo.sottodominio === 'locale_contratto' && (el.tipo.codice == 'CONTRATTO' || el.tipo.codice == 'ESCLUSIVITA') && parseInt(el.deleted) !== 1);
    let requiredMedia = '';
    let count;
    let mediaIdToSearch;

    let isRealReadOnlyContratto= isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMediaCodice(this.props.GlobalStore.stubs.tipo_media, 'sede', 'locale_contratto','CONTRATTO');
      
      mediaTypesContratto.map(element => {
        if(element.codice == requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyContratto = found;
      }
    }

    let isRealReadOnlyDisponibilita= isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMediaCodice(this.props.GlobalStore.stubs.tipo_media, 'sede', 'locale_contratto','ESCLUSIVITA');
      
      mediaTypesDisponibilita.map(element => {
        if(element.codice == requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyDisponibilita = found;
      }
    }

    return (

      <div>
        {this.state.loaded ?
          <React.Fragment>
            <h3>Upload Documenti</h3>
            {
              this.state.required_clausole && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'sede', 'clausole_risolutorie', this.props.accreditamento.tipo_accreditamento)
                ?
                <Documenti
                  canDelete={!isRealReadOnly}
                  unique={true}
                  media_types={this.getMediaClausoleTypes()}
                  readOnly={isRealReadOnly}
                  medias={this.state.medias}
                  current_domain="sede"
                  current_subdomain={null}
                  accreditation_id={this.props.accreditamento.id}
                  required_types={
                  allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'sede', 'clausole_risolutorie')}
                  required_dates={true}
                  ref_id={this.props.id_sede_accreditamento}
                  onUpload={(f) => {
                    this.setState({ medias: [...this.state.medias, f] })
                  }}
                  onDelete={(id) => {
                    this.setState({ medias: this.state.medias.filter(e => e.id !== id) })
                  }}
                />
                : null
            }
            <Documenti
              canDelete={!isRealReadOnlyContratto}
              unique={true}
              media_types={this.getMediaContrattoTypes()}
              readOnly={isRealReadOnlyContratto}
              medias={this.state.medias}
              current_domain="sede"
              current_subdomain={null}
              accreditation_id={this.props.accreditamento.id}
              required_types={allegatiStatic.filteredRequiredMediaCodice(this.props.GlobalStore.stubs.tipo_media, 'sede', 'locale_contratto','CONTRATTO')}
              required_dates={this.state.titolo_uso_locali !== 1}
              ref_id={this.props.id_sede_accreditamento}
              onUpload={(f) => {
                this.setState({ medias: [...this.state.medias, f] })
              }}
              onDelete={(id) => {
                this.setState({ medias: this.state.medias.filter(e => e.id !== id) })
              }}
            />
            <Documenti
              canDelete={!isRealReadOnlyDisponibilita}
              unique={true}
              media_types={this.getMediaDisponibilitaTypes()}
              readOnly={isRealReadOnlyDisponibilita}
              medias={this.state.medias}
              current_domain="sede"
              current_subdomain={null}
              accreditation_id={this.props.accreditamento.id}
              required_types={allegatiStatic.filteredRequiredMediaCodice(this.props.GlobalStore.stubs.tipo_media, 'sede', 'locale_contratto','ESCLUSIVITA')}
              required_dates={this.state.titolo_uso_locali !== 1}
              ref_id={this.props.id_sede_accreditamento}
              onUpload={(f) => {
                this.setState({ medias: [...this.state.medias, f] })
              }}
              onDelete={(id) => {
                this.setState({ medias: this.state.medias.filter(e => e.id !== id) })
              }}
            />
            {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'sede', 'caratteristiche_sede', this.props.accreditamento.tipo_accreditamento) &&
              <Documenti
                canDelete={!isRealReadOnly}
                unique={true}
                media_types={this.getCaratteristicheTypes()}
                readOnly={isRealReadOnly}
                medias={this.state.medias}
                current_domain="sede"
                current_subdomain={null}
                accreditation_id={this.props.accreditamento.id}
                required_types={
                  allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'sede', 'caratteristiche_sede')}
                required_dates={false}
                ref_id={this.props.id_sede_accreditamento}
                onUpload={(f) => {
                  this.setState({ medias: [...this.state.medias, f] })
                }}
                onDelete={(id) => {
                  this.setState({ medias: this.state.medias.filter(e => e.id !== id) })
                }}
              />
            }
          </React.Fragment>
          : null}
      </div>
    );
  }
}

export default Files;
