import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import List from '../components/ProfiliGestitiDaNormativeRegionaliNazionali/List';

@Wrapper({ route: 'ProfiliGestitiDaNormativeRegionaliNazionali', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['adminPermissions'])
class ProfiliGestitiDaNormativeRegionaliNazionali extends Component {

  state = {
    loading: false,
    asked: false,
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">Profili Gestiti Da Normative Regionali Nazionali</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Profili Gestiti Da Normative Regionali Nazionali</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <List ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default ProfiliGestitiDaNormativeRegionaliNazionali;
