import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm } from 'antd';

import ModelServices from '@accreditamento/services/ComunicazioneOstativa';

import moment from 'moment';

import FileSaver from 'file-saver';

import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';




const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica file p7m o pdf firmato",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

const formSelNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn_submit',
              text: 'Salva le note per poter scaricare il template',
              props: {
                className: "bg-green",
                icon: 'save'
              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};







class Controdeduzione extends Component {

  state = {
    loaded: false,
    open_modal_com: false,
    accreditamento: {
      id: null
    },
    comunicazione: {
      id: null
    }
  }

  componentDidMount() {
    if(this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if(newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento ||
        newProps.comunicazione !== this.props.comunicazione.controdeduzioni)) this.loaded(newProps)
  }

  loaded( props ) {
    this.setState({accreditamento: props.accreditamento, comunicazione: props.comunicazione, loaded: true});
  }


  async uploadFile(file) {

    if(!this.state.loading) {
      this.setState({
        loading: true
      }, () => {

        ModelServices.upload_controdeduzioni(this.props.accreditamento.id, this.props.comunicazione.id, this.props.comunicazione.controdeduzioni.id, {
          filename: 'file'
        }, { file: file, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_com: false, loading: false });

        }, () => this.setState({ open_modal_com: false, loading: false }))

      })

    }
    return;

  }

  saveBlob( blob ) {
    const _blob = new Blob([blob], {type: 'application/vnd.oasis.opendocument.text'});
    FileSaver.saveAs(_blob, "controdeduzioni.odt");

    //this.props.refresh();
  }

  canView(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) && this.props.UserStore.user.id === this.props.comunicazione.id_user) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user)
    )  ? true : false
  }

  canDownload(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) &&
      this.props.UserStore.user.id === this.props.accreditamento.id_user &&
      (this.props.comunicazione.controdeduzioni.note !== '' &&
      this.props.comunicazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare/* ||
          this.props.comunicazione.controdeduzioni.stato == comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare*/)
      ) ? true : false
  }

  canUpload(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user &&
      this.props.comunicazione.controdeduzioni.note !== '' &&
      this.props.comunicazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare
      ) ? true : false
  }

  canSend(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user &&
      this.props.comunicazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.firmata
      ) ? true : false
  }

  getTiming() {
    return moment(this.props.comunicazione.data_trasmissione, 'YYYY-MM-DD').add(this.props.GlobalStore.stubs.days_controdeduzioni, 'd').format('DD/MM/YYYY');
  }

  renderActions() {
    return <div>
        {this.canDownload() ?
        <div>
          <p>Scarica il template delle controdeduzioni</p>
          <Button
          loading={this.state.loading}
          disabled={this.state.loading}
          className="m5h" type="primary" icon="file-word" onClick={()=>
            {
              if(!this.state.loading) {
                this.setState({loading: true}, () => {
                  ModelServices.download_controdeduzioni(
                    this.props.accreditamento.id,
                    this.props.comunicazione.id,
                    this.props.comunicazione.controdeduzioni.id,
                    (res)=>{
                      this.saveBlob(res.data);
                      this.setState({loading: false})

                    },
                    () => this.setState({loading: false})
                  )
                })
              }
            }
            }>Scarica file</Button>
        </div> : null}
        {this.canUpload() ?
        <div>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={{
            }}
            fileListProp={() => []}
            customUploadRequest={obj => {
              this.uploadFile(obj.file);
            }}
          />

        </div> : null}
        {this.canSend() ?
        <div>
          <h4>Conferma</h4>
          <Popconfirm placement="top" title="Confermi la comunicazione?"
          onConfirm={() => {
            if(!this.state.loading) {
              this.setState({
                loading: true
              }, () => {
                ModelServices.confirm_controdeduzioni(
                this.props.accreditamento.id,
                this.props.comunicazione.id,
                this.props.comunicazione.controdeduzioni.id,
                (res)=> {
                  this.setState({loading: false});
                  this.props.refreshInline(res.data.data)
                },
                () => this.setState({loading: false}))
              })
            }
          }}
          okText="Si" cancelText="No">
            <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            className="m5h" type="primary" ghost icon="check">Conferma</Button>
          </Popconfirm>
        </div>
        : null}
    </div>
  }

  render() {
    return (this.state.loaded && this.props.comunicazione.controdeduzioni && this.canView()) ?
      <div style={{marginTop: 16}}>
        <div>
          <h4>Controdeduzioni</h4>
          <p style={{marginBottom: 0}}>Scadenza: <strong>{this.getTiming()}</strong></p>
          <p>Stato: <strong>{comunicazioneOstativaStatic.getStatoControdeduzioneLabel(this.props.comunicazione.controdeduzioni)}</strong></p>
          {this.props.comunicazione.controdeduzioni.note ?
            this.props.comunicazione.controdeduzioni.note : this.props.comunicazione.controdeduzioni.stato !== 4 ?
            ((checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])) ? <CommonForm
              wrappedComponentRef={inst => (this.formSelComIsp = inst)}
              loading={this.state.loading || false}
              form_model={formSelNoteLayout}
              values={{

              }}
              submitted={(data)=>{
                if(!this.state.loading) {
                  this.setState({loading: true}, () => {
                    ModelServices.edit_controdeduzioni(
                      this.props.accreditamento.id,
                      this.props.comunicazione.id,
                      this.props.comunicazione.controdeduzioni.id,
                      {note: data.note},
                      (res)=> {
                        this.setState({loading: false});
                        this.props.refreshInline(res.data.data)
                      },
                      () => this.setState({loading: false}))
                  })
                }
              }}
            />: null)  : null}
          {
            this.props.comunicazione.controdeduzioni.media.filter(el => el.tipo.sottodominio === comunicazioneOstativaStatic.tipo_media.sottodominio_controdeduzioni_firmata).map(l => {
              return <div key={l.id}><p style={{display: 'inline-block'}}>Controdeduzioni firmate: </p><DocSpan key={l.id} file={l} /></div>
            })
          }
          {this.renderActions()}
        </div>
      </div>
    : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(Controdeduzione);
