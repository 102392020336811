import React, { Component } from 'react';

import { Icon, message } from 'antd';

import CommonForm from '@common/components/form';
import ComuniService from '@common/services/locations/Comuni';

import CreateAnagrafica from '@anagrafica/forms/Create';

class CreateDiscenti extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    comuni: [],
    comuni_domicilio: [],
    comuni_residenza: [],
    residenza_same_domicilio_checked: true,
  }

  componentDidMount() {

    if (this.props.learner) {
      let req = [];

      //nascita
      if (this.props.learner._birthPlace.comune_nascita) {
        req.push(ComuniService.all({ id_provincia: this.props.learner._birthPlace.provincia_nascita }));
      } else {
        req.push(Promise.resolve(null));
      }

      //domicilio
      if (this.props.learner._domicileAddress.comune_domicilio) {
        req.push(ComuniService.all({ id_provincia: this.props.learner._domicileAddress.provincia_domicilio }));
      } else {
        req.push(Promise.resolve(null));
      }

      //residenza
      if (this.props.learner._residenceAddress.comune_residenza) {
        req.push(ComuniService.all({ id_provincia: this.props.learner._residenceAddress.provincia_residenza }));
      } else {
        req.push(Promise.resolve(null));
      }

      this.setState({ loaded: false });

      Promise.all(req).then(res => {
        let [res1, res2, res3] = res;
        this.setState({
          comuni: res1.data.data || [],
          comuni_domicilio: res2.data.data || [],
          comuni_residenza: res3.data.data || [],
          loading: false,
        }, () => {
          this.formRef.props.form.setFieldsValue({ _birthPlace: { comune_nascita: this.props.learner._birthPlace.comune_nascita } });
          this.formRef.props.form.setFieldsValue({ _domicileAddress: { comune_domicilio: this.props.learner._domicileAddress.comune_domicilio } });
          this.formRef.props.form.setFieldsValue({ _residenceAddress: { comune_residenza: this.props.learner._residenceAddress.comune_residenza } });
        });

      }).catch(r => {
        this.setState({
          loading: false,
        });
      });
    }

  }

  getFormLayout() {

    let form_rows = [];

    if (this.props.readOnly) {
      //Generalità
      form_rows.push(
        {
          cols: [
            {
              span: 12,
              items: [
                {
                  type: 'input',
                  label: 'Nome',
                  type_string: 'text', // field type
                  name: '_name',
                  props: {
                    size: 'large',
                    disabled: true,
                    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  },

                },
              ],
            },
            {
              span: 12,
              items: [
                {
                  type: 'input',
                  label: 'Cognome',
                  type_string: 'text', // field type
                  name: '_lastname',
                  props: {
                    size: 'large',
                    disabled: true,
                    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  },

                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              span: 24,
              items: [
                {
                  type: 'input',
                  label: 'Codice fiscale',
                  type_string: 'text', // field type
                  name: '_taxCode',
                  props: {
                    size: 'large',
                    disabled: true,
                    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  },
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              span: 12,
              items: [
                {
                  type: 'datepicker',
                  label: 'Data di nascita',
                  name: '_birthDate',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                },
              ],
            },
            {
              span: 12,
              items: [
                {
                  type: 'select',
                  label: 'Sesso',
                  name: '_gender',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options: [
                    { key: 'M', value: 'M', label: 'M' },
                    { key: 'F', value: 'F', label: 'F' }
                  ],
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              span: 8,
              items: [
                {
                  type: 'select',
                  label: 'Stato di nascita',
                  name: '_birthPlace.stato_nascita',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options_props: 'nazioni',
                },
              ],
            },
            {
              span: 8,
              items: [
                {
                  type: 'select',
                  label: 'Provincia di nascita',
                  name: '_birthPlace.provincia_nascita',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options_props: 'province',
                },
              ],
            },
            {
              span: 8,
              items: [
                {
                  type: 'select',
                  label: 'Comune di nascita',
                  name: '_birthPlace.comune_nascita',
                  props: {
                    size: 'large',
                    disabled: true,
                  },
                  options_props: 'comuni'
                },
              ],
            }
          ],
        },
      );
    }

    //domicilio
    form_rows.push(
      {
        cols: [
          {
            items: [
              {
                name: 'domicilio_header',
                type: 'title',
                element: 'h4',
                text: 'Domicilio',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h',
                  size: 'large',
                }
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text', // field type
                name: '_domicileAddress.via_domicilio',
                props: {
                  size: 'large',
                  disabled: this.props.readOnly,
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text', // field type
                name: '_domicileAddress.ncivico_domicilio',
                props: {
                  size: 'large',
                  placeholder: 'N.',
                  disabled: this.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: '_domicileAddress.cap_domicilio',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 5,
                  disabled: this.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9]{5,5}',
                    message: 'Cap non valido',
                  },
                  {
                    validator: 'validateCAP_domicilio',
                  }
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_domicileAddress.stato_domicilio',
                    operator: '==',
                    value: 1,
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: '_domicileAddress.cap_domicilio',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 10,
                  disabled: this.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9a-zA-Z]{4,10}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_domicileAddress.stato_domicilio',
                    operator: '!=',
                    value: 1,
                  },
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Nazione',
                name: '_domicileAddress.stato_domicilio',
                props: {
                  size: 'large',
                  //disabled: component.props.readOnly,
                  disabled: this.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStatoDomicilio',
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: '_domicileAddress.provincia_domicilio',
                props: {
                  size: 'large',
                  allowClear: true,
                  showSearch: true,
                  disabled: this.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: '_domicileAddress.stato_domicilio',
                    operator: '==',
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_domicileAddress.stato_domicilio',
                    operator: '==',
                    value: 1,
                  },
                ],
                options_props: 'province',
                onChange: 'selectedProvinciaDomicilio',
              },
            ],
          },
          {
            span: 14,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: '_domicileAddress.comune_domicilio',
                props: {
                  size: 'large',
                  showSearch: true,
                  disabled: this.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: '_domicileAddress.stato_domicilio',
                    operator: '==',
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_domicileAddress.stato_domicilio',
                    operator: '==',
                    value: 1,
                  },
                  {
                    type: 'field_validation',
                    field: '_domicileAddress.provincia_domicilio',
                    operator: '!=',
                    value: null,
                  },
                ],
                options_props: 'comuni_domicilio'
              },
            ],
          },
        ],
      }
    );

    form_rows.push(

    );
    //residenza
    form_rows.push(
      {
        cols: [
          {
            items: [
              {
                name: 'residenza_header',
                type: 'title',
                element: 'h4',
                text: 'Residenza',
                props: {
                  size: 'large',
                  style: { textAlign: 'left' },
                  className: 'm10h'
                }
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'La residenza corrisponde al domicilio ',
                name: 'residenza_same_domicilio',
                props: {
                  size: 'large',
                  onChange: (e) => this.setUnsetResidenzaAsDomicilio(e.target.checked)
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !this.props.readOnly; }
                  },
                ]
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text', // field type
                name: '_residenceAddress.via_residenza',
                props: {
                  size: 'large',
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ]
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text', // field type
                name: '_residenceAddress.ncivico_residenza',
                props: {
                  size: 'large',
                  placeholder: 'N.',
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ]
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: '_residenceAddress.cap_residenza',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 5,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9]{5,5}',
                    message: 'Cap non valido',
                  },
                  {
                    validator: 'validateCAP_residenza',
                  }
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_residenceAddress.stato_residenza',
                    operator: '==',
                    value: 1,
                  },
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: '_residenceAddress.cap_residenza',
                props: {
                  size: 'large',
                  placeholder: 'Cap',
                  maxLength: 10,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9a-zA-Z]{4,10}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_residenceAddress.stato_residenza',
                    operator: '!=',
                    value: 1,
                  },
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Nazione',
                name: '_residenceAddress.stato_residenza',
                props: {
                  size: 'large',
                  //disabled: component.props.readOnly,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStatoResidenza',
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                name: '_residenceAddress.provincia_residenza',
                props: {
                  size: 'large',
                  allowClear: true,
                  showSearch: true,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: '_residenceAddress.stato_residenza',
                    operator: '==',
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_residenceAddress.stato_residenza',
                    operator: '==',
                    value: 1,
                  },
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ],
                options_props: 'province',
                onChange: 'selectedProvinciaResidenza',
              },
            ],
          },
          {
            span: 14,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: '_residenceAddress.comune_residenza',
                props: {
                  size: 'large',
                  showSearch: true,
                  disabled: this.props.readOnly || this.state.residenza_same_domicilio_checked,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'requiredIf',
                    error_message: 'Campo obbligatorio',
                    field: '_residenceAddress.stato_residenza',
                    operator: '==',
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: '_residenceAddress.stato_residenza',
                    operator: '==',
                    value: 1,
                  },
                  {
                    type: 'field_validation',
                    field: '_residenceAddress.provincia_residenza',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'custom_func',
                    func: () => { return this.props.readOnly || !this.state.residenza_same_domicilio_checked; }
                  },
                ],
                options_props: 'comuni_residenza'
              },
            ],
          },
        ],
      }
    );


    form_rows.push(
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Cittadinanza',
                type_string: 'text', // field type
                name: '_citizenship',
                options_props: 'cittadinanza',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          }
        ]
      }
    );

    form_rows.push({
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Numero ore assegnate',
              placeholder: 'Numero ore assegnate',
              name: '_assignedHours',
              props: {
                min: 1,
                size: 'large',
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
                {
                  pattern: '^[1-9][0-9]*$',
                  message: 'Inserire un valore numerico maggiore di 0',
                }
              ],
            },
          ],
        },
      ],
    });


    form_rows.push(
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Titolo di studio',
                type_string: 'text', // field type
                name: '_qualification',
                options_props: 'tipo_titolo_studio',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Professione',
                name: '_profession',
                props: {
                  size: 'large',
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                type_string: 'text', // field type
                options_props: 'tipo_stato_lavorativo',
                //type: 'input',
                label: 'Stato occupazionale',
                name: '_employmentStatus',
                props: {
                  size: 'large',
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                type_string: 'text', // field type
                options_props: 'tipo_condizione_di_vulnerabilita',
                label: 'Condizione di vulnerabilià',
                name: '_vulnerableCondition',
                props: {
                  size: 'large',
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
    );

    if (!this.props.readOnly) {
      form_rows.push({
        cols: [
          {
            items: [
              {
                name: 'btn_discenti',
                type: 'button',
                text: 'Conferma',
                onClick: 'createDiscente',
                btn_type: 'primary'
              },
            ],
          },
        ],
      });
    } else {
      form_rows.push({
        cols: [
          {
            items: [
              {
                name: 'btn',
                type: 'button',
                text: 'Chiudi',
                onClick: 'createDiscente',
                btn_type: 'primary',
                props: {
                  disabled: false
                }
              },
            ],
          },
        ],
      });
    }

    return {
      layout: 'vertical',
      multistep: false,
      rows: form_rows,
      //onSubmit: 'form_submitted',
    }
  }

  domicileIsFullySet() {
    return (this.formRef?.props.form.getFieldsValue()?._domicileAddress?.via_domicilio || this.formRef?.props.form.getFieldsValue()?._domicileAddress?.via_domicilio?.trim() !== '') &&
      (this.formRef?.props.form.getFieldsValue()?._domicileAddress?.ncivico_domicilio || this.formRef?.props.form.getFieldsValue()?._domicileAddress?.ncivico_domicilio?.trim() !== '') &&
      (this.formRef?.props.form.getFieldsValue()?._domicileAddress?.cap_domicilio || this.formRef?.props.form.getFieldsValue()?._domicileAddress?.cap_domicilio?.trim() !== '') &&
      this.formRef?.props.form.getFieldsValue()?._domicileAddress?.stato_domicilio != null &&
      this.formRef?.props.form.getFieldsValue()?._domicileAddress?.provincia_domicilio != null &&
      this.formRef?.props.form.getFieldsValue()?._domicileAddress?.comune_domicilio != null;
  }

  setUnsetResidenzaAsDomicilio(checked) {
    this.setState({ residenza_same_domicilio_checked: checked });
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  async submit() {
    if (this.props.readOnly) {
      this.props.onClose && this.props.onClose();
    } else {

      let valid_form = await this.validate();
      if (valid_form) {
        let data = this.formRef.props.form.getFieldsValue();

        if (this.state.residenza_same_domicilio_checked) {
          data._residenceAddress = {
            via_residenza: data._domicileAddress.via_domicilio,
            ncivico_residenza: data._domicileAddress.ncivico_domicilio,
            cap_residenza: data._domicileAddress.cap_domicilio,
            stato_residenza: data._domicileAddress.stato_domicilio,
            provincia_residenza: data._domicileAddress.provincia_domicilio,
            comune_residenza: data._domicileAddress.comune_domicilio,
          };
        }

        if (!this.state.loading) {
          this.setState({ loading: true });
          data.id_anagrafica = this.state.model_anagrafica.id;
          data = {
            ...data,
            ...this.state.model_anagrafica
          };
          this.props.onCreate && this.props.onCreate(data);
        }
      }
    }
  }

  selectStatoDomicilio() {
    this.setState({
      comuni_domicilio: [],
    }, () => {
      this.formRef.props.form.setFieldsValue({ _domicileAddress: { comune_domicilio: null, provincia_domicilio: null } });
    });
  }

  selectProvinciaDomicilio(data) {
    this.setState({
      selected_provincia: data,
      comuni_domicilio: []
    }, () => {
      this.formRef.props.form.setFieldsValue({ _domicileAddress: { comune_domicilio: null } });
      ComuniService.all(
        { id_provincia: data },
        res => {
          this.setState({
            comuni_domicilio: res.data.data || []
          }, () => this.formRef.props.form.setFieldsValue({ _domicileAddress: { comune_domicilio: null } }));
        },
        () => null
      );
    });
  }

  selectStatoResidenza() {
    this.setState({
      comuni_residenza: [],
    }, () => {
      this.formRef.props.form.setFieldsValue({ _residenceAddress: { comune_residenza: null, provincia_residenza: null } });
    });
  }

  selectedProvinciaResidenza(data) {
    this.setState({
      selected_provincia: data,
      comuni_residenza: []
    }, () => {
      this.formRef.props.form.setFieldsValue({ _residenceAddress: { comune_residenza: null } });
      ComuniService.all(
        { id_provincia: data },
        res => {
          this.setState({
            comuni_residenza: res.data.data || []
          }, () => this.formRef.props.form.setFieldsValue({ _residenceAddress: { comune_residenza: null } }));
        },
        () => null
      );
    });
  }

  render() {
    return <div>
      {
        !this.state.model_anagrafica.id && !this.props.learner
          ?
          <CreateAnagrafica GlobalStore={this.props.GlobalStore} withMatricola={false} returnAnagrafica={(ana) => this.setState({ model_anagrafica: ana })} />
          :
          <CommonForm

            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={this.getFormLayout()}
            readOnly={this.props.readOnly}

            nazioni={this.props.GlobalStore.stubs.nazione}
            province={this.props.GlobalStore.stubs.provincia}
            cittadinanza={this.props.GlobalStore.stubs.cittadinanza}
            tipo_titolo_studio={this.props.GlobalStore.stubs.tipo_titolo_studio}
            tipo_stato_lavorativo={this.props.GlobalStore.stubs.condizione_mercato_ingresso}
            tipo_condizione_di_vulnerabilita={this.props.GlobalStore.stubs.gruppo_vulnerabile_partecipante}

            selectedStatoDomicilio={() => this.selectStatoDomicilio()}
            selectedProvinciaDomicilio={data => this.selectProvinciaDomicilio(data)}
            selectedStatoResidenza={() => this.selectStatoResidenza()}
            selectedProvinciaResidenza={data => this.selectedProvinciaResidenza(data)}

            validateCAP_domicilio={(rule, value, cb) => {
              let id_comune = this.formRef?.props.form.getFieldsValue()?._domicileAddress?.comune_domicilio;
              let comune = this.state.comuni_domicilio.find(c => c.id === id_comune);
              if (comune.cap.slice(-2) === 'xx') {
                if (comune.cap.substring(0, 3) !== value.substring(0, 3)) {
                  return cb("Cap non corrisponde al comune selezionato");
                }
              } else {
                if (comune.cap !== value) {
                  return cb("Cap non corrisponde al comune selezionato");
                }
              }
              return cb();
            }}

            validateCAP_residenza={(rule, value, cb) => {
              let id_comune = this.formRef?.props.form.getFieldsValue()?._residenceAddress?.comune_residenza;
              let comune = this.state.comuni_residenza.find(c => c.id === id_comune);
              if (comune.cap.slice(-2) === 'xx') {
                if (comune.cap.substring(0, 3) !== value.substring(0, 3)) {
                  return cb("Cap non corrisponde al comune selezionato");
                }
              } else {
                if (comune.cap !== value) {
                  return cb("Cap non corrisponde al comune selezionato");
                }
              }
              return cb();
            }}

            comuni={this.state.comuni.map(e => {
              return {
                key: 'comune_' + e.id,
                value: e.id,
                label: e.comune
              }
            })}
            comuni_domicilio={this.state.comuni_domicilio.map(e => {
              return {
                key: 'comune_domicilio' + e.id,
                value: e.id,
                label: e.comune
              }
            })}
            comuni_residenza={this.state.comuni_residenza.map(e => {
              return {
                key: 'comune_residenza' + e.id,
                value: e.id,
                label: e.comune
              }
            })}

            values={{
              residenza_same_domicilio: this.state.residenza_same_domicilio_checked,
              _domicileAddress: { stato_domicilio: 1 },
              _residenceAddress: { stato_residenza: 1 },
              _citizenship: 1,
              ...this.state.model_anagrafica,
              ...(this.props.learner || {}),
            }}
            createDiscente={() => this.submit()}
          //form_submitted={(data)=>this.submit(data)}
          />
      }
    </div>
  }

}
export default CreateDiscenti;