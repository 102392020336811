import React, { Component } from 'react';
import Wrapper from '../decorators/Wrapper';


@Wrapper({ route: 'NoteLegali', hasSidebar: false, hasHeader: true, hasFooter: true })
class NoteLegaliPageRoute extends Component {


  render() {

    return (
      <div>
        <h3>Premessa</h3>
        <p>
          La Regione Calabria vuole offrire, con questo sito, un'informazione il più possibile aggiornata, precisa e accessibile a chiunque desideri conoscere meglio le caratteristiche del suo territorio, la sua cultura e la sua realtà amministrativa.
          I contenuti del sito possono talvolta non essere esaurienti ed aggiornati, nonostante l'impegno della redazione.
        </p>
        <h3>Copyright</h3>
        <p>
          Tutti i testi, i suoni, le fotografie, i filmati, la grafica e il software presenti all'interno di questo sito sono protetti ai sensi delle normative sul diritto d'autore, sui brevetti e su quelle relative alla proprietà intellettuale.
          I marchi, le denominazioni e le ditte menzionati all'interno di questo sito appartengono ai rispettivi proprietari o titolari e possono essere protetti da brevetti e/o copyright concessi o registrati dalle autorità preposte. Possono quindi essere scaricati o utilizzati solo per uso personale e non commerciale: pertanto nulla, neppure in parte, potrà essere riprodotto, modificato o rivenduto per trarne profitto.
          I contenuti del sito, stante i tempi tecnici necessari per l'aggiornamento, non hanno valore legale ed occorre comunque, fare riferimento ai testi adottati ufficialmente e pubblicati sul BUR Calabria. Ricordiamo inoltre che in caso di discordanza questi ultimi prevalgono sui testi pubblicati nel sito.
        </p>
        <h3>Utilizzo del sito </h3>
        <p>
          In nessun caso la Regione Calabria potrà essere ritenuto responsabile dei danni di qualsiasi natura causati direttamente o indirettamente dall'accesso al sito, dall'utilizzo degli strumenti interattivi, dall'incapacità o impossibilità di accedervi, dall'utilizzo delle notizie in esso contenute. Sono consentite citazioni a titolo di cronaca o recensione se accompagnate dall'indicazione della fonte "Regione Calabria" e dalla sua Url (http://www.regione.calabria.it/).
          La Regione Calabria si riserva il diritto di riprodurre i testi in altre pubblicazioni.
          La Regione Calabria si riserva il diritto di modificare i contenuti del sito e delle note legali in qualsiasi momento e senza alcun preavviso.
            Segnalazione errori
          Qualora dovessero essere rilevati errori, inesattezze o malfunzionamenti, potranno essere inviate delle segnalazioni al seguente  link : Contattaci
          La Regione Calabria provvederà a correggerli nel più breve tempo possibile.
          Accesso a siti esterni collegati
          La Regione Calabria non ha alcuna responsabilità per quanto riguarda i siti ai quali è possibile accedere tramite i collegamenti posti all'interno del sito stesso.
          La Regione Calabria non è responsabile delle informazioni ottenute dall'utente dall'accesso ai siti raggiungibili tramite collegamento ipertestuale. Pertanto della completezza e precisione delle informazioni sono da ritenersi responsabili i soggetti titolari dei siti ai quali si accede alle condizioni dagli stessi previste.
        </p>
        <h3>Privacy Policy </h3>
        <p>
          La Regione Calabria garantisce che il trattamento dei dati personali che dovessero pervenire via posta elettronica o moduli elettronici, anche in sede di registrazione, è conforme a quanto previsto dalla vigente normativa sulla privacy (D.Lgs. 30 giugno 2003, n.196, in vigore dal 1 gennaio 2004).
          In particolare essi non saranno ceduti a terzi, il loro utilizzo servirà esclusivamente per comunicazioni relative al sito e ai suoi contenuti e per l'eventuale invio di informazioni su argomenti attinenti ai contenuti del sito.
          I dati personali verranno trattati esclusivamente per lo svolgimento delle attività di cui all'articolo 24 del codice sulla privacy, per le quali non è richiesto il consenso dell'interessato.
          I diritti dell'interessato sono quelli espressamente indicati all'articolo 7 del codice sulla privacy e possono essere esercitati con richiesta rivolta senza formalità, anche mediante lettera raccomandata, telefax o posta elettronica (art.9 del d.lgs. 30 giugno 2003, n.196) al titolare o al responsabile. Titolare del trattamento è il Direttore .... della Regione Calabria.
        </p>
        <h3>Accessibilità e Usabilità</h3>
        <p>
          Il portale è in continuo all'allineamento tecnico al fine di soddisfare le raccomandazioni per l'accessibilità fornite dal Consorzio mondiale per il Web (W3C) nell'ambito della iniziativa per l'accessibilità (WAI) oltre a quelle contenute nella normativa italiana di riferimento (L. 4/2004, c.d. legge Stanca e successive modifiche).
          Rendere accessibile un sito Web non è un punto d'arrivo ma un impegnativo percorso che coinvolge ogni momento della realizzazione, dal progetto alla manutenzione: mantenere la massima fruibilità possibile, attraverso il tempo, è certamente un obiettivo ambizioso, ma la cui necessità resta fuori discussione.
        </p>
        <h3>Download </h3>
        <p>
          Ogni oggetto presente su questo sito per lo scaricamento (download) come ad esempio documentazione tecnica, normativa, modulistica e software ecc., salvo diversa indicazione, è liberamente e gratuitamente disponibile a condizione che non venga utilizzato per trarne profitto o per il raggiungimento di fini illeciti.
        </p>
        <h3>Virus informatici </h3>
        <p>
          La Regione Calabria non garantisce che il sito sia privo di errori o virus. La Regione Calabria, i suoi fornitori ed i suoi collaboratori non sono responsabili per i danni subiti dall'utente a causa di tali elementi di carattere distruttivo.
        </p>
      </div>
    );
  }
}

export default NoteLegaliPageRoute;