import CommonForm from "@common/components/form";
import { Form, message } from "antd";
import { Component } from "react";
import CommissioneEsami from "@modules/affidamento/services/CommissioneEsami";
import React from 'react';

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Dichiarazioni rilasciate da parte del Legale rappresentante dell’Ente ed impegni assunti:",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Regolare svolgimento delle attività formative previste dal progetto autorizzato",
                name: "svolgimento_regolale",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "svolgimento_regolale_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "svolgimento_regolale",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  " Monitoraggio presenze/assenze allievi",
                name: "monitoraggio_presenze",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "monitoraggio_presenze_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "monitoraggio_presenze",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Verbale collegio docenti ammissione esami finali",
                name: "verbale_colleggio",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "varbale_colleggio_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "verbale_colleggio",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Polizza Responsabilità civile",
                name: "polizza_civile",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "polizza_civile_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "polizza_civile",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Validità Denuncia INAIL in essere",
                name: "validita_inail",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "validita_inail_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "validita_inail",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Istanza ente di formazione per autorizzazione esami",
                name: "istanza_ente",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "istanza_ente_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "istanza_ente",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit",
                text: "Salva",
                props: {
                  className: "bg-green",
                  //type: "primary",
                  icon: "save",
                },
                //onClick: 'showDrawer'
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submitted",
  };
};

class SchedaCommissioniValutazione extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
  }

  async confirm(data) {
    let istruttoria = null
   istruttoria = { istruttoria:{
    ...data,}
  }
    if (!this.state.loading) {
      await this.setState({ loading: true });
      await this.setState({ loading: false });



      

      CommissioneEsami.saveIstruttoria(
        istruttoria, 
        this.props.id_commissione,
        (res) => {
          this.setState({
            loading: false,
            model: res.data.data,
          });
          message.success("Dati salvati");
          if (this.props.onUpdate) this.props.onUpdate();
        }, 
        
        (err) => this.setState({ loading: false })
      );
    }
  }

  getValues() {
    let obj = this.props.progetto.istruttoria_progetti
    return obj;
  }


  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          submitted={(dati) => this.confirm(dati)}
          values={this.getValues()}
          readOnly={this.props.readOnly}

        />
      </div>
    );
  }
}

export default Form.create()(SchedaCommissioniValutazione);
