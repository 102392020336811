import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import format from '@common/utils/formatters/_';

import DettagliLezione from '@affidamento/components/registro/DettagliLezione';
import ModelServices from '@affidamento/services/Lezioni';
import CorsiServices from '@affidamento/services/Corsi';

import SpinnerLoading from '@components/SpinnerLoading';

@Wrapper({ route: 'Lezione', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewLezione', 'adminPermissions'])
class LezioneRoute extends Component {

  state = {
    loading: false,
    data: null,
  }

  componentDidMount() {
    this.fetch();
  }

  renderTitle() {
    let tipo_lezione = this.state.data?.stage? 'STAGE DEL ' : 'LEZIONE DEL ' 
    return tipo_lezione + format.formatDate(this.state.data?.data, 'DD/MM/YYYY')
  }

  fetch() {
    this.setState({ loading: true });

    Promise.all([
      ModelServices.detail({ expand: 'progetto,presenze,progetto.tutors' }, this.props.match.params.id_lezione || this.props.match.params.id),
      CorsiServices.myiscrizione(this.props.match.params.id_corso, { expand: 'giustificativo' })
    ]).then(
      res => {
        let [r1, r2] = res;

        this.setState(
          {
            data: r1.data.data,
            corso: r1.data.data.progetto,
            studente: r2?.data?.data,
            loading: false
          }
        );
      },
    ).catch(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <div className="scheda">
        {this.state.loading ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
              <p>Registro elettronico</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/affidamento/registro">Registro Elettronico</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={"/affidamento/registro/" + this.props.match.params.id_corso}>{this.state.data?.progetto?.titolo_progetto}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <DettagliLezione {...this.props} lezione={this.state.data} studente={this.state.studente} corso={this.state.corso} />
          </>
        }
      </div>
    );
  }
}

export default LezioneRoute;
