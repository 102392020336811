import React, { Component } from 'react';

import { Checkbox } from 'antd';


class UtenzeSpeciali extends Component {

  state = {
    loaded: true,
    selected_utenze: []
  }

  componentDidMount() {
    this.setState({
      selected_utenze: this.props.sede.utenzeSpeciali.map( a => {
        return a.id_utenza_speciale;
      })
    });

  }

  isSelectedUtenza(id) {
    return this.state.selected_utenze.find( el => el === id ) ? true : false;
  }

  selectUtenza(e, id) {

    if(e.target.checked) {
      this.setState({
        selected_utenze: [ ...this.state.selected_utenze, id ]
      });
    } else {
      this.setState({
        selected_utenze: this.state.selected_utenze.filter(el => el !== id)
      });
    }

  }

  getFormData() {
    return this.state.selected_utenze;
  }

  render() {
    return (
      <div style={{marginBottom: 16}}>
        {this.state.loaded ?
        <React.Fragment>
          <h3>Utenze speciali</h3>
          {
            this.props.accreditamento.accreditamentoutenzespeciali.map( utenza => {
              return <div key={"utenza_speciale_sede_"+utenza.id}>
                <Checkbox
                  disabled={this.props.readOnly}
                  checked={this.isSelectedUtenza(utenza.id)}
                  onChange={(e) => this.selectUtenza( e, utenza.id )}
                >
                  {utenza.tipoutenza}
                </Checkbox>
              </div>
            })
          }
        </React.Fragment>
         : null}
      </div>
    );
  }
}

export default UtenzeSpeciali;
