import React, { Component } from 'react';
import { Button, message } from 'antd';
import CommonForm from '@common/components/form';

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Titolo Modulo',
                                name: 'moduleTitle',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },

                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Descrizione modulo (Descrivere gli obiettivi formativi,  e i contenuti di ciascun Modulo in relazione agli obiettivi formativi generali)',
                                name: 'moduleDescription',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Competenze in ingresso',
                                name: 'competenzeIngresso',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Competenze in uscita',
                                name: 'competenzeUscita',
                                type_string: 'text',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Metodologie Utilizzate',
                                name: 'metodologieUtilizzate',
                                type_string: 'text',
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Media Utilizzati',
                                name: 'mediaUtilizzati',
                                type_string: 'text',
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                span: 10,
                items: [
                    {
                        type: 'select',
                        label: 'Piattaforma  Fad/E lerarning avente le caretteristiche tecniche di cui agli artt 4 e 5 dell’allegato 2 alla DGR 133/2022 ',
                        name: 'piattaforma',
                        options: [
                            {
                                value: 1,
                                label: "Si"
                            },
                            {
                                value: 0,
                                label: "No"
                            },
                        ],
                      }
                ],
            },
            ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Strumenti e attrezzature utilizzate',
                                name: 'strumentiAttrezzature',
                                type_string: 'text',
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Tutor multimendiale',
                                name: 'tutorMultimediale',
                                type_string: 'text',
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'integer',
                                label: 'Durata modulo (totale ore previste per il modulo)',
                                name: 'moduleDuration',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },
                            }
                        ],
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'integer',
                                label: 'Unità didattiche (Numero di Unità didattiche  previste per il modulo)',
                                name: 'teachingUnits',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },
                            }
                        ],
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Modalità formative',
                                name: 'teachingMethods',
                                options_props: '_modalita_formativa',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Test di verifica degli apprendimenti',
                                name: 'testVerificaApprendimenti',
                                type_string: 'text',
                            }
                        ],
                    },
                ]
            },
            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'select',
                                label: 'Annualità',
                                name: 'annuity',
                                options_props: '_annuity',
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ]
            }
        ],
    }
}

class EditModuliDidattici extends Component {

    state = {
        loaded: false,
        loading: true,
        moduloDidattico: {},
        unitList : []
    };

    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loaded: true,
            loading: false,
            moduloDidattico: props.moduloDidattico
        });
    }

    async validate() {
        return new Promise((resolve) => {
          this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
            if (!errors || errors.length === 0) {
              resolve(true);
            } else {
              message.error('Ricontrolla i campi');
              resolve(false);
            }
          });
        });
      }

    async submit() {
        if (await this.validate()) {
            let data = {};
           // data.unitList = this.state.unitList;
            data.unitList = [];
            data.moduleTitle = this.formSelRef?.props.form?.getFieldValue('moduleTitle');
            data.moduleDescription = this.formSelRef?.props.form?.getFieldValue('moduleDescription');
            data.moduleDuration = this.formSelRef?.props.form?.getFieldValue('moduleDuration');
            data.teachingUnits = this.formSelRef?.props.form?.getFieldValue('teachingUnits');
            data.teachingMethods = this.formSelRef?.props.form?.getFieldValue('teachingMethods');
            data.annuity = this.formSelRef?.props.form?.getFieldValue('annuity');
            data.testDescription = this.formSelRef?.props.form?.getFieldValue('testDescription');

            data.competenzeIngresso = this.formSelRef?.props.form?.getFieldValue('competenzeIngresso');
            data.competenzeUscita = this.formSelRef?.props.form?.getFieldValue('competenzeUscita');
            data.metodologieUtilizzate = this.formSelRef?.props.form?.getFieldValue('metodologieUtilizzate');
            data.strumentiAttrezzature = this.formSelRef?.props.form?.getFieldValue('strumentiAttrezzature');
            data.mediaUtilizzati = this.formSelRef?.props.form?.getFieldValue('mediaUtilizzati');
            data.tutorMultimediale = this.formSelRef?.props.form?.getFieldValue('tutorMultimediale');
            data.piattaforma = this.formSelRef?.props.form?.getFieldValue('piattaforma');
            data.testVerificaApprendimenti = this.formSelRef?.props.form?.getFieldValue('testVerificaApprendimenti');

            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    moduloDidattico: data
                });
                if (this.props.create) {
                    this.props.onCreate(data);
                } else {
                    this.props.onUpdate(data);
                }
            }
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    loading={this.state.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.moduloDidattico}
                    readOnly={this.props.readOnly}
                    _annuity={this.props.annuityList}
                    _modalita_formativa={this.props.GlobalStore.stubs.modalita_formativa_modulo_didattico}
                />
                <Button disabled={this.props.readOnly} type="primary" className="m5h" onClick={() => this.submit()}>Salva</Button>
            </div>
        )
    }
}

export default EditModuliDidattici;