import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';

import SchedaProcedura from "../components/SchedaProcedura";

@Wrapper({ route: 'DettaglioFormulario', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProcedura', 'viewOpenedOrClosedProcedura'])
class DettagliFormularioRoute extends Component {

  state = {
    loading: false,
    asked: false,
  }

  renderTitle() {
    return 'Gestione formulari'
  }

  render() {

    return (
      <div className="scheda">
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          {!checkPermissions.hasPermission( this.props.UserStore.user, ['entePermissions']) ? <p>Gestione formulari</p> : null}
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/affidamento/formulari">Gestione formulari</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Dettagli formulario</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <SchedaProcedura {...this.props} />

      </div>
    );
  }
}

export default DettagliFormularioRoute;
