import React, { Component } from "react";
import { Table, Input, Radio} from 'antd';

import DocList from '@common/components/DocList';
import format from '@common/utils/formatters/_';

const getJsonValue = (value) => {
  try {
    return JSON.parse( value )
  } catch(e) {
    console.error(e);
    return []
  }
}

const getCv = (record, component) => {
  let c = component.hasCurriculum(record);
  return (c) ?
  <DocList
    key={"file_"+c.id}
    file={c}
    canRemove={false}
    /> : null;
}


const table_columns = (component) => [
  {
    title: 'Conforme',
    dataIndex: '',
    render: (text, record) => (<div>
      <Radio.Group
        disabled={component.props.readOnly}
        onChange={(e)=>component.checkDocente(e, record)}
        value={component.validDocente(record)}>
        <Radio.Button value={1}>Ok</Radio.Button>
        <Radio.Button value={0}>Ko</Radio.Button>
      </Radio.Group>
      {!component.validDocente(record) ?
        <Input.TextArea
        style={{marginTop: 8}}
          value={component.getNoteDocente(record)}
          onChange={(e)=>component.changeNoteDocente(e, record)}
          disabled={component.props.readOnly}
          placeholder="Osservazioni"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        : null
      }
    </div>),
  }, {
    title: 'Dati anagrafici',
    dataIndex: 'nome',
    key: 'nome',
    render: (text, record) => (<div style={{width: 150}}>{record.anagrafica.nome + " " + record.anagrafica.cognome}<br />{record.anagrafica.cf}</div>)
  }, {
    title: 'Tipologia',
    dataIndex: 'tipologia',
    key: 'tipologia',
    render: (text, record) => (<div style={{width: 350}}>{ getJsonValue( record.tipo_formazione ).map( el => format.replaceStub( el, 'tipo_accreditamento', component.props.GlobalStore) ).join(", ") }</div>)
  },
  {
    title: 'Rapporto Lavoro',
    dataIndex: 'rapporto_lavoro',
    key: 'rapporto_lavoro',
    render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).id_tipo_rapporto_lavoro, 'tipo_rapporto_lavoro', component.props.GlobalStore ) }</div>)
  },
  {
    title: 'Titolo di studio',
    dataIndex: 'titolo_studio',
    key: 'titolo_studio',
    render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).titolo_studio, 'tipo_titolo_studio', component.props.GlobalStore ) }</div>)
  }, {
    title: 'Curriculum',
    dataIndex: 'curriculum',
    key: 'curriculum',
    render: (text, record) =>(<span>{getCv(record, component)}</span>)
  }];


/*
const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 5,
          items: [
            {
              type: 'input',
              label: 'Nome',
              type_string: 'text', // field type
              name: 'nome',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 5,
          items: [
            {
              type: 'input',
              label: 'Cognome',
              type_string: 'text', // field type
              name: 'cognome',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'select',
              label: 'Titolo di studio',
              type_string: 'text', // field type
              name: 'titolo_studio',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              options_props: 'titolo_studio',
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Modulo didattico',
              type_string: 'text', // field type
              name: 'modulo_didattico',
              props: {
                //size: 'large',
                //prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 2,
          items: [
            {
              type: 'radio',
              label: 'Curriculum',
              name: 'curriculum',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "Si",
                  label: "Si"
                },
                {
                  value: "No",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn_submit',
              text: 'Inserisci personale docente',
              props: {
                icon: 'save'
              },
              //onClick: 'showDrawer'
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'onSubmit',
};
*/
class Docenti extends Component {

  state = {
    mounted: false,
    loading: false,
    personale: [],
    validDocenti: {},
    noteDocenti: {},
  };

  componentDidMount() {
    if(this.props.values && this.props.values.validDocenti) this.setState({validDocenti: this.props.values.validDocenti})

    if(this.props.values && this.props.values.noteDocenti) this.setState({noteDocenti: this.props.values.noteDocenti})
  }

  getValues() {
    let obj = {
      validDocenti: this.state.validDocenti,
      noteDocenti: this.state.noteDocenti
    };
    return obj;
  }

  checkDocente(e, record) {
    let f = this.state.validDocenti;
    f[record.id] = e.target.value;
    this.setState({validDocenti: f});

    if(e.target.value === 1){
      this.setState(
        {
          noteDocenti: {
            ...this.state.noteDocenti,
            [record.id]: ''
          }
        }
      )
    }
  }

  validDocente(record) {
    return this.state.validDocenti[record.id] || 0;
  }

  getNoteDocente(record) {
    return this.state.noteDocenti[record.id] || '';
  }

  changeNoteDocente(e, record) {
    this.setState({
      noteDocenti: {
        ...this.state.noteDocenti,
        [record.id]: e.target.value
      }
    })
  }

  getRapportoLavoro(record) {
    try {
      return record.anagrafica.rapportoLavoro.find(el => el.id_ente === this.props.id_ente)
    } catch(e) {
      return {}
    }
  }

  hasCurriculum(record) {
    try {
      return this.getRapportoLavoro(record).media.find(m => m.tipo.tipo_media.toUpperCase() === 'CURRICULUM')
    } catch(e) {
      return {}
    }
  }

  render() {
    return <div style={{marginTop: 16}}>
            <h4>Personale docente</h4>
            <Table
            rowKey={(r)=>r.id}
            columns={table_columns(this)}
            dataSource={this.props.docenti} />
        </div>
  }
}


export default Docenti;