import BaseRequest from "../_baseRequest";


export default {

    QR_iscrizione_discente: (id, url, cb, ecb = null) => {
    var req = new BaseRequest();
    req.makeRequest(
      {},
      {},
      null,
      "get",
      url + id,
      cb,
      ecb || null,
      null
    );
  },
  

}
