import React, { Component } from 'react';
import moment from 'moment';
import { message, Modal } from 'antd';
import CommonForm from './form';
import MediaServices from '../services/media/Media';

const formLayout = (component) => { return {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: component.props.accept || '.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: component.props.uploadButtonText || 'Carica pdf',
              props: {
                showUploadList: false
              },
              downloadTemplateEnabled: component.props.downloadTemplateEnabled || false,
              downloadTemplateOnClick: component.props.downloadTemplateOnClick || undefined
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};
}

const formSelLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'textarea',
                label: 'Descrizione',
                name: 'note',
                props: {

                }
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'datepicker',
                label: 'Valido dal',
                name: 'valido_dal',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: (component.props.required_dates) ? [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_date'
                  },
                ] : [
                    {
                      validator: 'min_date'
                    }],
              },
            ],
          },
          {
            items: [
              {
                type: 'datepicker',
                label: 'Valido al',
                name: 'valido_al',
                format: 'DD/MM/YYYY',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: (component.props.required_dates) ? [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'max_date'
                  },
                ] : [{
                  validator: 'max_date'
                }]
              },
            ],
          },
        ]
      }
    ],
    onSubmit: 'submitted',
  };
}

class DocumentiForm extends Component {
  state = {
    loading: false,
    note: '',
    valido_dal: null,
    valido_al: null,
    open_modal: false,
    uploading: false
  };

  upload() {

    if (!this.props.tempFile && !this.state.uploading) {

      this.setState({ uploading: true });

      MediaServices.upload(
        this.props.accreditation_id,
        {
          tipo_media: this.props.tipo_media[0].id,
          related_id: this.props.ref_id || "",
          note: this.state.note || "",
          valido_dal: this.state.valido_dal || "",
          valido_al: this.state.valido_al || "",
          filename: 'file',
          root: this.props.root || null
        },
        { ...this.state.file, chiave: 'file' }, (res) => {

        //console.log('responso', res)
        if (this.props.onInsert) this.props.onInsert(res.data.data)
        this.setState({ open_modal: false, uploading: false });

      }, () => this.setState({ uploading: false }))

    } else {

      this.setState({ uploading: true });

      MediaServices.uploadTemp({
        tipo_media: this.props.tipo_media[0].id,
        related_id: this.props.ref_id || "",
        note: this.state.note || "",
        valido_dal: this.state.valido_dal || "",
        valido_al: this.state.valido_al || "",
        filename: 'file',
      }, { ...this.state.file, chiave: 'file' }, (res) => {

        if (this.props.onInsert) this.props.onInsert(res.data.data)
        this.setState({ open_modal: false, uploading: false });

      }, () => this.setState({ uploading: false }))

    }
  }

  async uploadImage(file) {
    if(this.props.hideModalNoteFacoltative){
      await this.setState({
        file: { file: file, name: file.name }, 
        open_modal: false,
        tipo_media: this.props.tipo_media[0].id,
        related_id: this.props.ref_id || "",
        filename: 'file',
        valido_dal: null,
        valido_al: null,
        note: ""
      }, () => this.upload())
    }else{
      await this.setState({ file: { file: file, name: file.name }, open_modal: true });
    }
    return;

  }

  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          readOnly={this.props.readOnly}
          form_model={formLayout(this)}
          values={{

          }}
          fileListProp={() => []}
          onRemove={data => {
            //this.setState({
            //  fileList: this.state.fileList.filter(f => f.uid !== data.uid),
            //});
          }}
          customUploadRequest={obj => {
            this.uploadImage(obj.file);
          }}
        />
        <Modal
          title="Inserisci note facoltative"
          visible={this.state.open_modal}
          confirmLoading={this.state.uploading}
          onOk={async () => {
            let valid = await this.validate();
            if (valid) {
              this.setState({
                tipo_media: this.props.tipo_media[0].id,
                related_id: this.props.ref_id || "",
                filename: 'file',
                valido_dal: this.formSelRef.props.form.getFieldsValue()['valido_dal'] || null,
                valido_al: this.formSelRef.props.form.getFieldsValue()['valido_al'] || null,
                note: this.formSelRef.props.form.getFieldsValue()['note'] || ""
              }, () => this.upload())
            }
          }}

          onCancel={() => this.setState({ open_modal: false })}
        >
          <CommonForm
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            readOnly={this.props.readOnly}
            loading={this.props.loading || false}
            form_model={formSelLayout(this)}
            values={{

            }}
            min_date={(rule, value, cb) => {
              if (!value || value.length === 0) cb();
              let min = value;
              let max = this.formSelRef.props.form.getFieldsValue()['valido_al'];

              if (max && min > max) cb("Data valido dal deve essere minore di valido al");
              else cb()
            }}
            max_date={(rule, value, cb) => {
              if (!value || value.length === 0) cb();
              let max = value;

              if (max < moment()) cb("Data 'valido al' deve essere successiva alla data odierna");

              let min = this.formSelRef.props.form.getFieldsValue()['valido_dal'];

              if (min && min > max) cb("Data valido dal deve essere minore di valido al");
              else cb()
            }}
            tipo_media={this.props.tipo_media.map(t => {
              return { value: t.id, label: t.descrizione }
            })}
          />
        </Modal>
      </div>
    );
  }
}

export default DocumentiForm;
