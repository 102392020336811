import React, { Component } from "react";

import eventBroker from "@common/utils/services/EventBroker";
import { Table, Input, Form, Button, InputNumber, Spin, message } from "antd";
import checkPermission from "@common/utils/check/permissions";
import affidamentoStatic from "@common/utils/static/affidamento";
import schedafinanziariaStaticFree from "@common/utils/static/schedafinanziariaFree";
import CommonForm from "@common/components/form";
import formatter from '@common/utils/formatters/_';

import PropTypes from "prop-types";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";
import TextArea from "antd/lib/input/TextArea";


const columns = (component) => {
  const { getFieldDecorator } = component.props.form;

  return [
    {
      title: "Risorse Umane",
      dataIndex: "sectionTitle",
      key: "sectionTitle",
      render: (text, record) => (
        <div style={{ width: 300 }}>{record.title}</div>
      ),
    },
    {
      title: "N",
      dataIndex: "number",
      key: "number",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "number", {
              initialValue: record.number,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Costo Orario",
      dataIndex: "hourlyCost",
      key: "hourlyCost",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "hourlyCost", {
              initialValue: record.hourlyCost,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Impegno orario",
      dataIndex: "hourlyEffort",
      key: "hourlyEffort",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "hourlyEffort", {
              initialValue: record.hourlyEffort,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Costo Giornata",
      dataIndex: "dayCost",
      key: "dayCost",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "dayCost", {
              initialValue: record.dayCost,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Impegno giornata",
      dataIndex: "dayEffort",
      key: "dayEffort",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "dayEffort", {
              initialValue: record.dayEffort,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Totale",
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "total", {
              initialValue: record.total,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
  ];
};

const columnsB = (component) => {
  const { getFieldDecorator } = component.props.form;

  return [
    {
      title: "Allievi",
      dataIndex: "sectionTitle",
      key: "sectionTitle",
      render: (text, record) => (
        <div style={{ width: 300 }}>{record.title}</div>
      ),
    },
    {
      title: "Totale",
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "total", {
              initialValue: record.total,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
  ];
};

const columnsC = (component) => {
  const { getFieldDecorator } = component.props.form;

  return [
    {
      title: "Spese di Funzionamento e Gestione",
      dataIndex: "sectionTitle",
      key: "sectionTitle",
      render: (text, record) => (
        <div style={{ width: 300 }}>{record.title}</div>
      ),
    },
    {
      title: "Totale",
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "total", {
              initialValue: record.total,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
  ];
};

const columnsD = (component) => {
  const { getFieldDecorator } = component.props.form;

  return [
    {
      title: "Altre Spese",
      dataIndex: "sectionTitle",
      key: "sectionTitle",
      render: (text, record) => (
        <div style={{ width: 300 }}>{record.title}</div>
      ),
    },
    {
      title: "N",
      dataIndex: "number",
      key: "number",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "number", {
              initialValue: record.number,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Costo Unitario",
      dataIndex: "hourlyCost",
      key: "hourlyCost",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "hourlyCost", {
              initialValue: record.hourlyCost,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Totale",
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "total", {
              initialValue: record.total,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
  ];
};

class SchedaFinanziariaFormFree extends Component {
  constructor(props) {
    super(props);
    this.getBackfinancialStatement = this.getBackfinancialStatement.bind(this);
    eventBroker.on("getBackfinancialStatement", this.getBackfinancialStatement);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  };

  state = {
    loaded: false,
    loaded_source: false,
    loading: true,
    financialStatement: {},
    dataSource: {},
  };

  componentDidMount() {
    this.load(this.props);
  }

  load(props) {
    this.setState(
      {
        loaded: true,
        financialStatement: props.financialStatement,
      },
      () => this.loadDataSource()
    );
  }

  getBackfinancialStatement() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  loadDataSource() {
    let dataSource = {
      dataSectionA: [],
      dataSectionB: [],
      dataSectionC: [],
      dataSectionD: [],
    };

    let sectionList = [
      "dataSectionA",
      "dataSectionB",
      "dataSectionC",
      "dataSectionD",
    ];

    sectionList.forEach((key) => {
      Object.entries(
        this.state.financialStatement.AfcProjectFreeCostsFormSection[key]
      ).forEach(([key_child, value_child]) => {
        dataSource[key].push({
          title: schedafinanziariaStaticFree.sectionMap[key_child],
          key: key_child,
          sectionName: key,
          number: value_child.number,
          hourlyCost: value_child.hourlyCost,
          hourlyEffort: value_child.hourlyEffort,
          dayCost: value_child.dayCost,
          dayEffort: value_child.dayEffort,
          total: value_child.total,
        });
      });
    });

    this.setState({
      loaded_source: true,
      loading: false,
      dataSource: dataSource,
    });
  }

  getValues() {
    let generalData = this.state.generalData;
    return generalData;
  }

  totalOneriImpostiAgliAllievi() {
    let data =
      this.formSelRef.props.form.getFieldsValue()["AfcLearnerPaymentsSection"];
    data["total"] =
      data["entryFee"] +
      data["courseCost"] +
      data["monthlyFee"] +
      data["otherPayment"];
    this.formSelRef.props.form.setFieldsValue({
      AfcLearnerPaymentsSection: data,
    });
  }

  getTotal(sectionName) {
    let sumDirect = 0;
    switch (sectionName) {
      case "dataSectionA":
        this.state.dataSource.dataSectionA.forEach((el) => {
          sumDirect =
            sumDirect + this.props.form.getFieldValue(el.key + "total");
        });

        this.props.form.setFieldsValue({
          totalA: sumDirect,
        });
        break;
      case "dataSectionB":
        this.state.dataSource.dataSectionB.forEach((el) => {
          sumDirect =
            sumDirect + this.props.form.getFieldValue(el.key + "total");
        });

        this.props.form.setFieldsValue({
          totalB: sumDirect,
        });
        break;
      case "dataSectionC":
        this.state.dataSource.dataSectionC.forEach((el) => {
          sumDirect =
            sumDirect + this.props.form.getFieldValue(el.key + "total");
        });

        this.props.form.setFieldsValue({
          totalC: sumDirect,
        });
        break;
      case "dataSectionD":
        this.state.dataSource.dataSectionD.forEach((el) => {
          sumDirect =
            sumDirect + this.props.form.getFieldValue(el.key + "total");
        });

        this.props.form.setFieldsValue({
          totalD: sumDirect,
        });
        break;
      default:
        break;
    }

    let totalDirect =
      this.props.form.getFieldValue("totalA") +
      this.props.form.getFieldValue("totalB") +
      this.props.form.getFieldValue("totalC") +
      this.props.form.getFieldValue("totalD");
    this.props.form.setFieldsValue({
      totalDirect: totalDirect,
    });

    let total = totalDirect;
    this.props.form.setFieldsValue({
      total: total,
    });
  }

  prepareData(data) {
    return data;
  }
  
  submit(data) {

    let domanda = this.props.domanda_json;
    domanda.generalData.AfcLearnerPaymentsSection = data
    this.props.saveJson(domanda);


  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let financialStatement = this.state.financialStatement;

        financialStatement.AfcLearnerPaymentsSection.entryFee = values.entryFee

        financialStatement.AfcLearnerPaymentsSection.courseCost = values.courseCost

        financialStatement.AfcLearnerPaymentsSection.monthlyFee = values.monthlyFee

        financialStatement.AfcLearnerPaymentsSection.otherPayment = values.otherPayment

        financialStatement.AfcProjectFreeCostsFormSection.totalA =
          values.totalA;

        financialStatement.AfcProjectFreeCostsFormSection.totalB =
          values.totalB;
        financialStatement.AfcProjectFreeCostsFormSection.totalC =
          values.totalC;

        financialStatement.AfcProjectFreeCostsFormSection.totalD =
          values.totalD;

        financialStatement.AfcProjectFreeCostsFormSection.studentSection.paramsStudentCost =
          values.paramsStudentCost;

        financialStatement.AfcProjectFreeCostsFormSection.studentSection.paramsStudentRegistration =
          values.paramsStudentRegistration;

        financialStatement.AfcProjectFreeCostsFormSection.studentSection.paramsTotalIn =
          values.paramsTotalIn;

        financialStatement.AfcProjectFreeCostsFormSection.studentSection.paramsTotalOut =
          values.paramsTotalOut;

        financialStatement.AfcProjectFreeCostsFormSection.studentSection.paramsInDescription =
          values.paramsInDescription;

        financialStatement.AfcProjectFreeCostsFormSection.studentSection.paramsOutDescription =
          values.paramsOutDescription;

          financialStatement.AfcProjectFreeCostsFormSection.descrizioneTempi =
          values.descrizioneTempi;

          financialStatement.AfcProjectFreeCostsFormSection.descrizioneRecesso =
          values.descrizioneRecesso;

        financialStatement.AfcProjectFreeCostsFormSection.total = values.total;

        let sectionList = [
          "dataSectionA",
          "dataSectionB",
          "dataSectionC",
          "dataSectionD",
        ];

        sectionList.forEach((section) => {
          Object.entries(
            this.state.financialStatement.AfcProjectFreeCostsFormSection[
              section
            ]
          ).forEach(([key_child, value_child]) => {
            let data = {
              number: values[key_child + "number"],
              hourlyCost: values[key_child + "hourlyCost"],
              hourlyEffort: values[key_child + "hourlyEffort"],
              dayCost: values[key_child + "dayCost"],
              dayEffort: values[key_child + "dayEffort"],
              total: values[key_child + "total"],
            };
            financialStatement.AfcProjectFreeCostsFormSection[section][
              key_child
            ] = data;
          });
        });

        let domanda = this.props.domanda_json;
        domanda.financialStatement = financialStatement;

        this.props.saveJson(domanda);
        if (this.props.saveJson(domanda)) {
          message.success("Dati salvati con successo!");
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      this.state.loaded_source && (
        <div style={{ opacity: !this.props.saved ? 0.5 : 1 }}>
          {!this.props.saved ? <Spin /> : null}
          {checkPermission.hasPermission(this.props.UserStore.user, [
            "viewValutazioneScheda",
          ]) && (
            <ValidazioneScheda
              canGiveFeedback={
                this.props.canGiveSectionFeedback &&
                this.props.stato_flusso ===
                  affidamentoStatic.stato_flusso_progetto.audit_iniziato
              }
              loadingFeedback={this.props.loadingFeedback}
              saveFeedback={(note, valid) =>
                this.props.saveFeedback(note, valid, "financialStatement")
              }
              feedback={
                this.props.section_feedback
                  ? this.props.section_feedback["financialStatement"]
                  : null
              }
            />
          )}

          {this.props.CourseType.AfcProjectInfoFormSection ? (
            <div>

              <Form onSubmit={this.handleSubmit}>
              <h4 className="m20h">Oneri imposti agli allievi</h4>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item colon={false} label="Tassa di iscrizione (in €)">
                    {getFieldDecorator(`entryFee`, {
                      rules: [
                        {
                          required: true,
                          message:
                            "Per favore inserisci Tassa di iscrizione (in €)",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcLearnerPaymentsSection.entryFee
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ maxWidth: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                  <Form.Item colon={false} label="Costo intero corso comprensivo di IVA (in €) (Per anno nel caso di corsi pluriennali)">
                    {getFieldDecorator(`courseCost`, {
                      rules: [
                        {
                          required: true,
                          message:
                            "Per favore inserisci Costo intero corso",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcLearnerPaymentsSection.courseCost
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ Width: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                  <Form.Item colon={false} label="Retta mensile di frequenza (in €)">
                    {getFieldDecorator(`monthlyFee`, {
                      rules: [
                        {
                          required: false,
                          message:
                            "Retta mensile di frequenza (in €)",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcLearnerPaymentsSection.monthlyFee
                    })(
                      <Input
                        placeholder="€"
                        type="number"
                        min={0}
                        style={{ Width: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                  <Form.Item colon={false} label="Altro gravame economico (in €) ">
                    {getFieldDecorator(`otherPayment`, {
                      rules: [
                        {
                          required: true,
                          message: "Per favore inserisci Altro gravame economico (in €)",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcLearnerPaymentsSection.otherPayment
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ Width: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                </div>
                
                <h4 className="m20h">Parametri Allievo</h4>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item colon={false} label="Parametro ora/allievo">
                    {getFieldDecorator(`paramsStudentCost`, {
                      rules: [
                        {
                          required: true,
                          message:
                            "Per favore inserisci Parametro ora/allievo!",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcProjectFreeCostsFormSection.studentSection
                          .paramsStudentCost,
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ maxWidth: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                  <Form.Item colon={false} label="Retta a carico dell’allievo">
                    {getFieldDecorator(`paramsStudentRegistration`, {
                      rules: [
                        {
                          required: true,
                          message:
                            "Per favore inserisci Retta a carico dell’allievo",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcProjectFreeCostsFormSection.studentSection
                          .paramsStudentRegistration,
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ Width: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                  <Form.Item colon={false} label="Entrate totali stimate">
                    {getFieldDecorator(`paramsTotalIn`, {
                      rules: [
                        {
                          required: true,
                          message:
                            "Per favore inserisci Entrate totali stimate",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcProjectFreeCostsFormSection.studentSection
                          .paramsTotalIn,
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ Width: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                  <Form.Item colon={false} label="Uscite totali stimate">
                    {getFieldDecorator(`paramsTotalOut`, {
                      rules: [
                        {
                          required: true,
                          message: "Per favore inserisci Uscite totali stimate",
                        },
                      ],
                      initialValue:
                        this.state.financialStatement
                          .AfcProjectFreeCostsFormSection.studentSection
                          .paramsTotalOut,
                    })(
                      <Input
                        placeholder="€"
                        required
                        type="number"
                        min={0}
                        style={{ Width: "450px" }}
                        disabled={this.props.readOnly}
                      />
                    )}
                  </Form.Item>
                </div>
                <Form.Item colon={false} label="Descrizione delle entrate">
                  {getFieldDecorator(`paramsInDescription`, {
                    rules: [
                      {
                        required: true,
                        message:
                          "Per favore inserisci Descrizione delle entrate",
                      },
                    ],
                    initialValue:
                      this.state.financialStatement
                        .AfcProjectFreeCostsFormSection.studentSection
                        .paramsInDescription,
                  })(
                    <Input
                      placeholder="Scrivi qui"
                      required
                      type="text"
                      disabled={this.props.readOnly}
                    />
                  )}
                </Form.Item>
                <Form.Item colon={false} label="Descrizione delle uscite">
                  {getFieldDecorator(`paramsOutDescription`, {
                    rules: [
                      {
                        required: true,
                        message:
                          "Per favore inserisci Descrizione delle uscite",
                      },
                    ],
                    initialValue:
                      this.state.financialStatement
                        .AfcProjectFreeCostsFormSection.studentSection
                        .paramsOutDescription,
                  })(
                    <Input
                      placeholder="Scrivi qui"
                      required
                      type="text"
                      disabled={this.props.readOnly}
                    />
                  )}
                </Form.Item>
                

                <h4 className="m20h">Voci di spesa </h4>
                <Table
                  loading={this.props.saving}
                  columns={columns(this)}
                  showPagination={false}
                  pagination={false}
                  dataSource={this.state.dataSource.dataSectionA}
                  rowKey="key"
                />

                {getFieldDecorator(`totalA`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectFreeCostsFormSection
                      .totalA,
                })}

                <Table
                  loading={this.props.saving}
                  columns={columnsB(this)}
                  showPagination={false}
                  pagination={false}
                  dataSource={this.state.dataSource.dataSectionB}
                  rowKey="key"
                />

                {getFieldDecorator(`totalB`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectFreeCostsFormSection
                      .totalB,
                })}

                <Table
                  loading={this.props.saving}
                  columns={columnsC(this)}
                  showPagination={false}
                  pagination={false}
                  dataSource={this.state.dataSource.dataSectionC}
                  rowKey="key"
                />

                {getFieldDecorator(`totalC`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectFreeCostsFormSection
                      .totalC,
                })}

                <Table
                  loading={this.props.saving}
                  columns={columnsD(this)}
                  showPagination={false}
                  pagination={false}
                  dataSource={this.state.dataSource.dataSectionD}
                  rowKey="key"
                />

                {getFieldDecorator(`totalD`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectFreeCostsFormSection
                      .totalD,
                })}

                <h4 className="m20h">totale costi</h4>
                <Form.Item colon={false} label="Totale costi">
                  {getFieldDecorator(`total`, {
                    initialValue:
                      this.state.financialStatement
                        .AfcProjectFreeCostsFormSection.total,
                  })(<Input disabled={true} placeholder="TOTALE COSTI" />)}
                </Form.Item>

                <Form.Item colon={false} label="Descrizione modalità e tempi di versamento della retta di partecipazione">
                  {getFieldDecorator(`descrizioneTempi`, {
                    rules: [
                      {
                        required: true,
                        message:
                          "Per favore inserisci Descrizione",
                      },
                    ],
                    initialValue:
                      this.state.financialStatement
                        .AfcProjectFreeCostsFormSection.descrizioneTempi,
                  })(
                    <TextArea
                      placeholder="Scrivi qui"
                      required
                      type="text"
                      disabled={this.props.readOnly}
                    />
                  )}
                </Form.Item>
                <Form.Item colon={false} label="Descrizione delle modalità di recesso">
                  {getFieldDecorator(`descrizioneRecesso`, {
                    rules: [
                      {
                        required: true,
                        message:
                          "Per favore inserisci Descrizione",
                      },
                    ],
                    initialValue:
                      this.state.financialStatement
                        .AfcProjectFreeCostsFormSection.descrizioneRecesso,
                  })(
                    <TextArea
                      placeholder="Scrivi qui"
                      required
                      type="text"
                      disabled={this.props.readOnly}
                    />
                  )}
                </Form.Item>

                {!this.props.anteprima && !this.props.readOnly && (
                  <Button
                    htmlType="submit"
                    disabled={this.props.readOnly}
                    loading={this.state.loading}
                    type="primary"
                    icon="save"
                    className="m5h bg-green"
                  >
                    Salva i dati
                  </Button>
                )}
              </Form>
            </div>
          ) : null}
        </div>
      )
    );
  }
}

//export default SchedaFinanziariaFormFree;
export default Form.create()(SchedaFinanziariaFormFree);
