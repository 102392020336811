import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import List from '../components/List';
import UserServices from "../services/User";
import SpinnerLoading from '@common/components/SpinnerLoading';

@Wrapper({ route: 'ListaUtenti', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['ListUser', 'adminPermissions'])
class ListRoute extends Component {

  state = {
    loading: false,
    asked: false,
    loaded_roles: false,
    roles: []
  }

  componentDidMount() {
    /*RbacServices.list({ type: 1 }, (data) => {
      this.setState({ roles: data.data.data.list, loaded_roles: true });
    }, (err) => console.log(err));*/
    UserServices.registrableUser({}, data => {
      this.setState({
        roles: data.data.data,
        loaded_roles: true
      });
    }, (err) => console.error(err));
  }

  loadOrganizzazione(props) {

  }

  getBreadCrumb() {
    let breads = [
      <Breadcrumb.Item key="dash"><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
    ];

    breads.push(<Breadcrumb.Item key="org"><Link to={"/utente"}>Utenti</Link></Breadcrumb.Item>)

    return breads;
  }

  getHeaderText() {

  }

  render() {

    return this.state.loaded_roles ?
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">Utenti</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            {this.getBreadCrumb()}
          </Breadcrumb>
        </div>

        <List roles={this.state.roles} ref="listTable" {...this.props} />

      </div> : <SpinnerLoading />
  }
}

export default ListRoute;
