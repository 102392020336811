import React, {Component} from 'react';
import {Spin} from 'antd';
import allegatiStatic from '@common/utils/static/allegati'
import DocumentiForm from '@common/components/Documenti';

class Documenti extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    open_drawer: false,
    selected_funzione: null
  }

  getFormLayout() {

  }

  componentDidMount () {
    this.setState({model_anagrafica: this.props.anagrafica});
    /*AnagraficaServices.view({expand: 'rapportoLavoro,rapportoLavoro.media'}, this.props.anagrafica.id, (res)=>{
      this.setState({model_anagrafica: res.data.data})
    })*/

  }

  getRapportoLavoro() {
    try {
      return this.state.model_anagrafica.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }

  getRapportoLavoroMedia() {
    try {
      return this.getRapportoLavoro().media
    } catch(e) {
      return {}
    }
  }

  /**
   * File obbligatori per le anagrafiche
   * @type {Array}
   */
 /* required_files = ['Autorizzazione al trattamento dei dati personali secondo i termini di legge', 'Dichiarazione della veridicità dei dati riportati, in base al D.P.R. 445 del 28/12/2000'];

  getRequiredMedia() {
    return this.required_files;
  }  */
  // ora li prendiamo dinamicamente dal db



  render () {

    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;

    let filteredTipoMedia = this.props.GlobalStore.stubs.tipo_media.filter(element => element.dominio === 'rapporto_lavoro' && element.sottodominio === 'capacita_gestionali' && element.obbligatorieta === true);

    return <div>
      <p>I File contrassegnati da asterisco sono obbligatori</p>
      <p>Per gli enti che richiederanno l'accreditamento per la macrotipologia obbligo formativo sarà necessario l'inserimento  anche dell'Unilav e copia LUL per i 4 operatori per cui è richiesta l'assunzione.</p>
      {
        this.state.model_anagrafica.id
        ?
        filteredTipoMedia.map(element => {
          let media_type = null;
          const media_types = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento);
          media_types.map(mt => {
            if(mt.key === element.key){
              media_type = mt;
            }
          })
          const medias = this.getRapportoLavoroMedia();
          let isRealReadOnlyDocumentoModificato = isRealReadOnly;
          let count = 0;
          medias.map(m => {
            if(m.tipo_media === element.value){
              count++;
            }
          })
          if(count === 0){
            isRealReadOnlyDocumentoModificato = false;
          }
          return <DocumentiForm
              accreditation_id={this.props.accreditation_id}
              canDelete={!isRealReadOnlyDocumentoModificato}
              canAddNote={false}
              readOnly={isRealReadOnlyDocumentoModificato}
              unique={true}
              media_types={[media_type]}
              medias={this.getRapportoLavoroMedia()}
              current_domain="rapporto_lavoro"
              current_subdomain="capacita_gestionali"
              required_types={[element.label]}
              ref_id={this.getRapportoLavoro().id}
              onUpload={(medias) => null}
          />
        })
        : <Spin size="large" />
      }
      {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'documenti_capacita_gestionali', this.props.accreditamento.tipo_accreditamento) &&
        <div>
          {
            this.state.model_anagrafica.id
              ?
              <DocumentiForm
                accreditation_id={this.props.accreditation_id}
                canDelete={!isRealReadOnly}
                canAddNote={false}
                readOnly={isRealReadOnly}
                unique={true}
                media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
                medias={this.getRapportoLavoroMedia()}
                current_domain="rapporto_lavoro"
                current_subdomain="documenti_capacita_gestionali"
                required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'rapporto_lavoro', 'documenti_capacita_gestionali')}
                ref_id={this.getRapportoLavoro().id}
                onUpload={(medias) => null}
              /> : <Spin size="large" />
          }</div>}

    </div>
  }

}
export default Documenti;