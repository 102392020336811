import React, { Component } from 'react';

import FileSaver from 'file-saver';
import { Button, Card, Tooltip, notification, AutoComplete } from 'antd';
import TableComponent from '@common/components/table';

import queryString from 'query-string';

import _ from '@common/utils/formatters/_';

import EnteServices from '@accreditamento/services/Ente';
import FilterForm from '@common/components/filterForm';

const model = (component) => {
    let cols = {
        with_server_pagination: true,
        columns: [
            {
                title: 'Ente',
                dataIndex: '',
                //sorter: true,
                key: 'id_ente',
                render: element => <div>
                    {getDenominazioneEnte(element, component)}
                </div>
            },              
            {
                title: 'Tipologia provvedimento',
                dataIndex: '',
                //sorter: true,
                key: 'tipologia',
                render: element => <div>
                    {getTipologia(element, component)}
                </div>
            },            
            {
                title: 'Stato',
                dataIndex: '',
                //sorter: true,
                key: 'stato',
                render: element => <div>
                    {getStato(element, component)}
                </div>
            },
            {
                title: 'Protocollo',
                dataIndex: '',
                //sorter: true,
                key: 'numero_protocollo',
                render: element => <div style={{textAlign: 'center'}}>
                    {
                        element.numero_protocollo &&
                        <p>
                           {element.numero_protocollo}
                        </p>
                    }
                </div>
            },
            {
                title: 'Data protocollo',
                dataIndex: '',
                //sorter: true,
                key: 'data_protocollo',
                render: element => <div style={{textAlign: 'center'}}>                    
                    {getDataITFormat(element.data_protocollo, component)}
                </div>
            },   
            {
                title: 'Termine controdeduzioni',
                dataIndex: '',
                //sorter: true,
                key: 'termine_presentazione_controdeduzioni',
                render: element => <div style={{textAlign: 'center'}}>
                    {
                        element.termine_presentazione_controdeduzioni &&
                        <p>
                           {element.termine_presentazione_controdeduzioni}
                        </p>
                    }
                </div>
            },  
            {
                title: 'Data controdeduzioni',
                dataIndex: '',
                //sorter: true,
                key: 'data_controdeduzioni',
                render: element => <div style={{textAlign: 'center'}}>                    
                    {getDataITFormat(element.data_controdeduzioni, component)}
                </div>
            },                                
            {
                title: 'Responsabile provvedimento',
                dataIndex: '',
                sorter: false,
                key: 'ana_nome',
                render: element => <div>
                    {
                        element.id_user &&
                        <p>
                            <strong>Istruttore</strong>
                            <br />
                            {element.id_user.anagrafica.nome} {element.id_user.anagrafica.cognome}
                        </p>
                    }
                </div>
            }
        ]
    }

    return cols;
}

const getStato = (record, component) => {
    let stato_label = '--';
    switch(record.stato){
        case 0: stato_label = 'Predisposto'; break;
        case 1: stato_label = 'Da firmare'; break;
        case 2: stato_label = 'Firmata'; break;
        case 3: stato_label = 'Attivato'; break;
        case 4: stato_label = 'Chiuso dall\'istruttore'; break;
        case 5: stato_label = 'Inviate controdeduzioni'; break;
        case 6: stato_label = 'Confermato dal dirigente'; break;
        case 7: stato_label = 'Annullato dal dirigente'; break;
    }

    return <div>{stato_label}</div>
}

const getTipologia= (record, component) => {
    let tipologia_label = '--';
    switch(record.tipologia){
        case 'COMUNICAZIONE_SOSPENSIONE': tipologia_label = 'Comunicazione avvio procedimento di sospensione'; break;
        case 'PROVVEDIMENTO_SOSPENSIONE': tipologia_label = 'Provvedimento di sospensione'; break;
        case 'COMUNICAZIONE_REVOCA'     : tipologia_label = 'Comunicazione avvio procedimento di revoca'; break;
        case 'PROVVEDIMENTO_REVOCA'     : tipologia_label = 'Provvedimento di revoca'; break;
    }

    return <div>{tipologia_label}</div>
}

const getDenominazioneEnte= (record, component) => {
    return <>        
        <Tooltip placement="top" title={"Visualizza dettaglio dell'ente"}>
            <Button className="m2w" size="small" shape="circle" icon={"eye"} onClick={() => component.props.history.push('/enti/' + record.id_ente.id + '/accreditamenti')} />
        </Tooltip> 
        <div>{record.id_ente.denominazione}</div>         
    </>
}

const getDataITFormat= (data, component) => {
    if(data === null || data == undefined ) {
        return '-';
    }
    const dArr = data.split("-");  // ex input "2010-01-18"
    return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0].substring(2); //ex out: "18/01/10"    
}

const filterFormModel = (component) => {

    return {
      layout: 'vertical',
      multistep: false,
      rows: [
        {
          cols: [

            {
              span: 6,
              items: [
                {
                  type: 'string',
                  label: 'Ente',
                  name: 'ente_denominazione',
                }
              ]
            },

            {
                span: 6,
                items: [
                  {
                    type: 'string',
                    label: 'Numero Protocollo',
                    name: 'numero_protocollo',
                  }
                ]
              },

            {
              span: 6,
              items: [
                { // TODO
                  type: 'select',
                  label: 'Stato',
                  name: 'stato',
                  options: [
                    {
                      value: "-1",
                      label: "Tutte"
                    },
                    {
                      value: "0",
                      label: "Predisposto"
                    },
                    {
                      value: "1",
                      label: "Da firmare"
                    },
                    {
                      value: "2",
                      label: "Firmata"
                    },
                    {
                      value: "3",
                      label: "Attivato"
                    },
                    {
                        value: "4",
                        label: "Chiuso dall'istruttore"
                    },
                    {
                        value: "5",
                        label: "Inviate controdeduzioni"
                    },
                    {
                        value: "6",
                        label: "Confermato dal dirigente"
                    },
                    {
                        value: "7",
                        label: "Annullato dal dirigente"
                    }  
                  ],
                }
              ]
            },

            {
                span: 6,
                items: [
                  { // TODO
                    type: 'select',
                    label: 'Tipologia',
                    name: 'tipologia',
                    options: [
                      {
                        value: "-1",
                        label: "Tutte"
                      },
                      {
                        value: "COMUNICAZIONE_SOSPENSIONE",
                        label: "Comunicazione avvio procedimento di sospensione"
                      },
                      {
                        value: "PROVVEDIMENTO_SOSPENSIONE",
                        label: "Provvedimento di sospensione"
                      },
                      {
                        value: "COMUNICAZIONE_REVOCA",
                        label: "Comunicazione avvio procedimento di revoca"
                      },
                      {
                        value: "PROVVEDIMENTO_REVOCA",
                        label: "Provvedimento di revoca"
                      },
                    ],
                  }
                ]
              },

          ]
        },
        {
          cols: [
            {
              items: [
                {
                  name: 'submit',
                  type: 'submit',
                  text: 'Cerca',
                  btn_type: 'primary'
                }
              ]
            }
          ]
        },
      ]
    };
  }

class ListAllProvvedimenti extends Component {
    base_url = '';

    state = {
        params:{},
    }

    constructor(props) {
        super(props);

        this.state = {
            page:{
                title: '',
                btnAddValue: '',
                btnAttivazioneValue: '',
                btnConfermaAValue: '',
                btnAnnullaValue: ''
            },
            loading: true,
            data: {
                refreshed_at: -1,
                total: 0,
                current: 1,
                data: []
            },
            drawer: {
                title: 'Aggiungi',
                open: false,
                loading: false,
                id: null,
                values: {},
                readOnly: false,
                onSubmit: undefined
            }
        }
      }

    componentDidMount() {
        this.callAndUpdate(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.data.refreshed_at != this.state.data.refreshed_at){
            this.callAndUpdate(this.props);
        }
    }

    callAndUpdate(props) {
        let { params, pager } = _.getFilterParam(props.location.search, this.default_order);

        this.setState({ loading: true });
        EnteServices.listProvvedimenti({provvedimentoType: 'ALL', filterParams: params}, null, (res) => {
            this.setState(prevState => ({
                loading: false,
                data: {
                    ...prevState.data,
                    data: res.data.data,
                    current: 1,//data.current,
                    total: 0,//data.total,
                    refreshed_at: -1,
                }
            }), () => this.refs.table_component.updateTableData(
                res.data.data,
                1,//data.current,
                0,//data.total,
                pager)
            );
        }, (err) => {
            this.setState({ loading: false });
            console.error("EnteServices.listProvvedimenti", err)
        });
    }

    /**
     * Se cambia la stringa aggiorna
     * @param  {[type]} newProps [description]
     * @return {[type]}          [description]
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.location.search !== this.props.location.search) {
        this.callAndUpdate(newProps)
        }
    }

    /**
     * Ricarica dopo update
     * @return {[type]} [description]
     */
    refresh() {
        this.callAndUpdate(this.props)
    }

    updateQueryStr(obj) {
        try {
            let q_s = queryString.parse(this.props.location.search);
            // brutto forte
            if (obj.sort) {
                if (q_s.sort && obj.sort) {
                    if (q_s.sort === obj.sort) {
                        if (q_s.sort[0] === "") {
                            q_s.sort = q_s.sort.replace("-", "");
                        } else {
                            q_s.sort = "-" + q_s.sort
                        }
                    } else {
                        q_s = { ...q_s, ...obj }
                    }
                } else {
                    q_s = { ...q_s, ...obj }
                }
            } else {
                q_s = { ...q_s, ...obj }
            }

            console.warn('TP - updateQueryStr obj', obj);
            console.warn('TP - updateQueryStr obj', this.base_url + '?' + queryString.stringify(q_s));

            this.props.history.push(this.base_url + '?' + queryString.stringify(q_s))
        } catch (e) {
            //console.log('errore cambio url',e);
        }

    }

    render() {
        return (
            <div>
                <h3 className="m10h">{this.state.page.title}</h3>
                <div style={{ padding: 14 }}>
                    <div>

                        <FilterForm
                        formLayout={filterFormModel(this)}
                        base_url={this.base_url}
                        search={this.state.params}
                        //skipParse={true}
                        history={this.props.history}
                        //date_fields={['data_protocollo', 'data_controdeduzioni']}  
                        />
                        <Card
                            className="shadow no-pad"
                            bordered={false}>
                            <TableComponent
                                data={this.state.data}
                                model={model(this)}
                                table_props={{ rowKey: 'id' }}
                                size="medium"
                                ref="table_component"
                                loading={this.state.loading}
                                with_server_pagination={true}
                                updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                                updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                            />
                        </Card>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default ListAllProvvedimenti;