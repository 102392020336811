import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm, message, Modal } from 'antd';
import {withRouter} from "react-router-dom";
import ModelServices from '@accreditamento/services/Esito';

import CommonForm from '@common/components/form';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';
import auditStatic from '@common/utils/static/audit';
import nominaStatic from '@common/utils/static/nomina';

import AccreditamentoServices from '../../services/Accreditamento';

import checkPermission from '@common/utils/check/permissions';

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};


class Esito extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    audit: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false,
    loading: false,

    ispezione_ok: false,
    ispezione_ko: false,
    selecting_sede: false,
    selected_sedi: [],

    accepting: false,
    refusing: false,
    suspending: false,

    confirming_refuse: false,
    confirming_accept: false,
    edit_macrotipologie: false, 
    confirm_macrotipologie: false,
    rollback_macrotipologie: false,
  }

  componentDidMount() {
    if (this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, loaded: true });
  }

  isConfirmed() {
    return this.props.accreditamento.stato === accreditamentoStatic.stato.accolta_e_confermata ? true : false
  }

  isTerminated() {
    return this.props.accreditamento.stato === accreditamentoStatic.stato.accolta ? true : false
  }

  isAuditTerminated() {
    // è sufficiente che lo stato sia accolta senza bisogno di verificare l'audit
    return this.props.accreditamento.stato === accreditamentoStatic.stato.accolta ? true : false;
    //return this.props.accreditamento.stato_interno == accreditamentoStatic.stato_interno.audit_completato ? true : false
  }

  isEndFirstAudit() {
    return this.props.accreditamento.audit.length === 1 && auditStatic.stato_end_audit.indexOf(this.props.accreditamento.audit[0].stato) !== -1 && (
      !this.props.accreditamento.nomina || this.props.accreditamento.nomina.filter(n => n.stato !== nominaStatic.stato.rifiutato_confermato).length === 0
    ) ? true : false
  }

  canTerminate() {
    return this.isIstruttore() || this.isDirigente() ? true : false;
  }

  isIstruttore() {
    return this.props.accreditamento.istruttore && this.props.accreditamento.istruttore.length > 0 && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id ? true : false;
  }

  isDirigente() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions']) ? true : false;
  }

  canConfirmAccreditamento() {
    return (this.isDirigente() && this.isAuditTerminated() && !this.isConfirmed()) ? true : false
  }

  hasControdeduzioni() {
    return this.props.accreditamento.comunicazioneOstativa && this.props.accreditamento.comunicazioneOstativa.controdeduzioni && this.props.accreditamento.comunicazioneOstativa.controdeduzioni.stato === comunicazioneOstativaStatic.stato.confermata ? true : false;
  }

  isEndVerbale() {
    return this.props.accreditamento.stato_flusso === 9 ? true : false;
    //return this.props.accreditamento.filter(n => n.verbale && n.verbale.stato == nominaStatic.stato_verbale.terminato).length > 0 && !this.hasControdeduzioni()
  }

  canRejectAccreditamento() {
    return (
      this.props.accreditamento.stato !== accreditamentoStatic.stato.rifiutata &&
      (
        (this.isDirigente() && this.isAuditTerminated() && !this.isConfirmed()) ||
        (this.isIstruttore() && this.hasControdeduzioni() && !this.isConfirmed() && this.props.accreditamento.stato === accreditamentoStatic.stato.sospesa)
      )
    ) ? true : false;
  }

  canSuspendAccreditamento() {
    return this.isIstruttore() && !this.isTerminated() && !this.hasControdeduzioni() && !this.isConfirmed() && !this.hasComunicazioneOstativaPending() &&
      (this.hasControdeduzioni() || this.isEndFirstAudit() || this.isAuditTerminated()) ? true : false;
  }

  canConfirmReject() {
    return this.isDirigente() && this.props.accreditamento.stato === accreditamentoStatic.stato.rifiutata_istruttore
  }

  canEndWork() {
    return this.props.accreditamento.stato !== accreditamentoStatic.stato.rifiutata && (
      this.isIstruttore() && !this.isTerminated() && !this.hasComunicazioneOstativaPending() &&
      (this.hasControdeduzioni() || this.isEndFirstAudit() || this.isAuditTerminated()
      )
    ) ? true : false;
  }



  isEndControdeduzioni() {
    if (!this.props.accreditamento.comunicazioneOstativa) return false;
    if (!this.props.accreditamento.comunicazioneOstativa.controdeduzioni) return false;

    return this.props.accreditamento.comunicazioneOstativa.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.confermata ? true : false;
  }

  canEndIstruttore() {
    return (
      this.isIstruttore() &&
      (this.props.accreditamento.stato_flusso === 19 || this.props.accreditamento.stato_flusso === 21)
        /*(
          !this.props.accreditamento.comunicazioneOstativa ||
          this.isEndControdeduzioni()
        ) &&
        (
          (
            this.props.accreditamento.need_ispezione == accreditamentoStatic.need_ispezione.unneeded && // ancora non è deciso
            this.props.accreditamento.audit.length == 1 && // c'è un solo audit
            auditStatic.stato_end_audit.indexOf(this.props.accreditamento.audit[0].stato) != -1
          ) ||
          (
            this.isEndVerbale() || this.isEndControdeduzioni()
          )
        ) && (
          this.props.accreditamento.stato != accreditamentoStatic.stato.rifiutata_istruttore &&
          this.props.accreditamento.stato != accreditamentoStatic.stato.accolta
        )
      */) ? true : false;
  }

  canEndDirigente() {
    return (
      this.isDirigente() &&
      (
        this.props.accreditamento.stato === accreditamentoStatic.stato.rifiutata_istruttore ||
        this.props.accreditamento.stato === accreditamentoStatic.stato.accolta
      )
    ) ? true : false
  }

  /**
   * è presente una comunicazione ostativa
   * @return {Boolean} [description]
   */
  hasComunicazioneOstativaPending() {
    return false;
  }

  renderActions() {
    return <div>
      {
        this.canEndIstruttore()
          ?
          <React.Fragment>
            <Popconfirm placement="top" title="Confermi i dati inseriti?"
              onConfirm={() => {
                if (!this.state.loading) {
                  this.setState({
                    loading: true,
                    accepting: true
                  }, () => {

                    ModelServices.accept(this.props.accreditamento.id,
                      () => {

                        this.props.refreshInline({
                          stato: accreditamentoStatic.stato.accolta,
                          stato_flusso: 10
                        })
                        this.setState({ loading: false, accepting: false })

                      },
                      () => this.setState({ loading: false, accepting: false }))

                  })
                }
              }}
              okText="Si" cancelText="No">
              <Button
                loading={this.state.accepting}
                disabled={this.state.loading}
                className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Conferma lavorazione</Button>
            </Popconfirm>
            {
              this.props.accreditamento.stato_flusso === 21
                ?
                <Popconfirm placement="top" title="Vuoi rigettare la pratica?"
                  onConfirm={() => {
                    if (!this.state.loading) {
                      this.setState({
                        loading: true,
                        refusing: true
                      }, () => {

                        ModelServices.refuse(this.props.accreditamento.id,
                          () => {
                            this.props.refreshInline({
                              stato: accreditamentoStatic.stato.rifiutata_istruttore,
                              stato_flusso: 13
                            })
                            this.setState({ loading: false, accepting: false })
                          },
                          () => this.setState({ loading: false, accepting: false })
                        )

                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                    loading={this.state.refusing}
                    disabled={this.state.loading}
                    className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Rigetta la domanda</Button>
                </Popconfirm>
                : null
            }
            {
              this.props.accreditamento.stato_flusso === 19
                ?
                <Popconfirm placement="top" title="Vuoi sospendere la pratica e creare una comunicazione ostativa?"
                  onConfirm={() => this.setState({ open_modal_doc: true })}
                  okText="Si" cancelText="No">
                  <Button
                    loading={this.state.suspending}
                    disabled={this.state.loading}
                    className="m5h" icon="stop" style={{ marginRight: 8 }}>Sospendi</Button>
                </Popconfirm>
                : null
            }
            <Modal
              title="Sospendi inserendo delle note"
              visible={this.state.open_modal_doc}
              onOk={async () => {
                if (!this.state.loading) {
                  this.setState({
                    loading: true,
                    suspending: true
                  }, () => {
                    if (!this.formSelRef.props.form.getFieldsValue()['note'] || this.formSelRef.props.form.getFieldsValue()['note'] === '') {
                      message.error("Inserisci delle note")
                      this.setState({ loading: false, suspending: false })
                      return
                    }
                    ModelServices.suspend(
                      this.props.accreditamento.id,
                      { note: this.formSelRef.props.form.getFieldsValue()['note'] },
                      (res) => {

                        this.props.refreshInline(res.data.data);
                        this.setState({ open_modal_doc: false, loading: false, suspending: false });

                      },
                      () => this.setState({ loading: false, suspending: false })
                    )
                  })
                }
              }}
              onCancel={() => this.setState({ open_modal_doc: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formSelRef = inst)}
                loading={this.state.loading || false}
                form_model={formNoteLayout}
                values={{

                }}

              />
            </Modal>
          </React.Fragment>
          : null
      }

      {
        this.isDirigente()
          ?
          <React.Fragment>
            {
              this.props.accreditamento.stato_flusso === 10
                ?
                <React.Fragment>
                <Popconfirm placement="top" title="Vuoi passare la pratica in stato accolta?"
                  onConfirm={() => {
                    if (!this.state.loading) {
                      this.setState({
                        loading: true,
                        confirming_accept: true
                      }, () => {

                        ModelServices.confirm_accept(this.props.accreditamento.id,
                          (res) => {
                            this.props.refreshInline(res.data.data)
                            this.setState({ loading: false, confirming_accept: false })
                          },
                          () => this.setState({ loading: false, confirming_accept: false }))

                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                    loading={this.state.confirming_accept}
                    disabled={this.state.loading}
                    className="m5h" type="primary" icon="save" style={{ marginRight: 8 }}>Conferma l&apos;accreditamento</Button>
                </Popconfirm>
                {
                this.props.accreditamento.data_modifica_macrotipologie == null  /*CIPRIANI*/?
                <Popconfirm placement="top" title="Si desidera procedere alla modifica delle macrotipologie specificate dall'utente?"
                  onConfirm={() => {
                    if (!this.state.loading) {
                      this.setState({
                        loading: true,
                        edit_macrotipologie: true
                      }, () => {

                        ModelServices.edit_macrotipologie(this.props.accreditamento.id,
                          (res) => {
                            this.props.refreshInline(res.data.data)
                            this.setState({ loading: false, edit_macrotipologie: false })
                          },
                          () => this.setState({ loading: false, edit_macrotipologie: false }))
                          this.props.handleEnableMacrotipologie(true);
                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                    loading={this.state.edit_macrotipologie}
                    disabled={this.state.loading}
                    className="m5h" type="primary" icon="edit" style={{ marginRight: 8 }}>Modifica le macrotipologie</Button>
                </Popconfirm>
                : null
                } 
                </React.Fragment>
                : null
            }
            {
              this.props.accreditamento.stato_flusso === 13
                ?
                <Popconfirm placement="top" title="Confermi il rigetto della pratica?"
                  onConfirm={() => {
                    if (!this.state.loading) {
                      this.setState({
                        loading: true,
                        confirming_refuse: true
                      }, () => {
                        ModelServices.refuse(this.props.accreditamento.id,
                          (res) => {
                            this.props.refreshInline(this.props.refreshInline(res.data.data))
                            this.setState({ loading: false, confirming_refuse: false })
                          },
                          () => this.setState({ loading: false, confirming_refuse: false }))
                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                    loading={this.state.confirming_refuse}
                    disabled={this.state.loading}
                    className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Conferma il rigetto della domanda</Button>
                </Popconfirm>
                : null
            }
            {
              this.props.accreditamento.stato_flusso === 36
                ?
                <React.Fragment>
                <Popconfirm placement="top" title="Confermi la modifica delle macrotipologie? L'operazione è irreversibile."
                  onConfirm={() => {
                    if (!this.state.loading) {
                      this.setState({
                        loading: true,
                        confirming_refuse: true
                      }, () => {
                        ModelServices.confirm_macrotipologie(this.props.accreditamento.id,
                          (res) => {
                            this.props.refreshInline(this.props.refreshInline(res.data.data))
                            this.setState({ loading: false, confirm_macrotipologie: false })
                          },
                          () => this.setState({ loading: false, confirm_macrotipologie: false }))
                          this.props.handleEnableMacrotipologie(false);
                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                    loading={this.state.confirm_macrotipologie}
                    disabled={this.state.loading}
                    className="m5h" type="primary" icon="save" style={{ marginRight: 8 }}>Conferma le macrotipologie</Button>
                </Popconfirm>
                <Popconfirm placement="top" title="Vuoi annullare le modifiche effettuate?"
                onConfirm={() => {
                  if (!this.state.loading) {
                    this.setState({
                      loading: true,
                      rollback_macrotipologie: true
                    }, () => {
                      ModelServices.rollback_macrotipologie(this.props.accreditamento.id,
                        (res) => {
                          this.props.refreshInline(this.props.refreshInline(res.data.data))
                          this.setState({ loading: false, rollback_macrotipologie: false })
                        },
                        () => this.setState({ loading: false, rollback_macrotipologie: false }))
                        this.props.handleEnableMacrotipologie(false);
                        this.props.handleReloadPanels(true);
                    })
                  }
                }}
                okText="Si" cancelText="No">
                <Button
                  loading={this.state.rollback_macrotipologie}
                  disabled={this.state.loading}
                  className="m5h" type="primary" icon="undo" style={{ marginRight: 8 }}>Annulla le modifiche alle macrotipologie</Button>
              </Popconfirm>
              </React.Fragment>
                : null
            }
            {
              [10, 13].includes(this.props.accreditamento.stato_flusso) ?
              <React.Fragment>
                <Button
                  disabled={this.state.loading}
                  className="m5h" type="primary" icon="edit" onClick={() => this.setState({ open_modal_note_dirigente: true })}
                  style={{ marginRight: 8 }}>
                  Note del dirigente
                </Button>
                <Modal
                  title="Inserisci note"
                  visible={this.state.open_modal_note_dirigente}
                  onOk={async () => {

                    if (!this.formNoteRef.props.form.getFieldsValue()['note_dirigente'] || this.formNoteRef.props.form.getFieldsValue()['note_dirigente'] === '') {
                      message.error('Inserisci le note');
                      return;
                    }
                    AccreditamentoServices.feedbackSectionDirigente(
                      this.props.accreditamento.id,
                      {
                        section: "finalSection",
                        note: this.formNoteRef.props.form.getFieldsValue()['note_dirigente'],
                      },
                      (res) => {
                        message.success("Note salvate correttamente");
                        this.props.refreshInline(res.data.data)
                        this.setState({ open_modal_note_dirigente: false });
                      },
                      (err) => {
                        message.error("Errore in fase di salvataggio");
                        this.setState({ open_modal_note_dirigente: false })
                      }
                    );
                  }}
                  onCancel={() => this.setState({ open_modal_note_dirigente: false })}
                >
                  <CommonForm
                    wrappedComponentRef={inst => (this.formNoteRef = inst)}
                    form_model={{
                      layout: 'vertical',
                      multistep: false,
                      rows: [
                        {
                          cols: [
                            {
                              items: [
                                {
                                  type: 'textarea',
                                  label: 'Note',
                                  name: 'note_dirigente',
                                  props: {},
                                  validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    },
                                  ],
                                },
                              ],
                            },
                    
                          ],
                        }
                      ],
                      onSubmit: 'submitted',
                    }}
                    values={
                      this.props.accreditamento && this.props.accreditamento.dirigente_sections_feedback && this.props.accreditamento.dirigente_sections_feedback['finalSection'] ?
                        { note_dirigente: this.props.accreditamento.dirigente_sections_feedback['finalSection'].note } : { note_dirigente: '' }
                    }
                  />
                </Modal>
              </React.Fragment>
              : null
            }
          </React.Fragment>
          : null
      }
    </div>
  }


  render() {
    console.warn("this.props.accreditamento", this.props.accreditamento)
    return (this.state.loaded /*&& this.canTerminate()*/) ?
      <div style={{ marginTop: 16 }}>
        {this.renderActions()}
        {
          this.props.accreditamento && this.props.accreditamento.dirigente_sections_feedback && this.props.accreditamento.dirigente_sections_feedback['finalSection'] ?
          <div>
            <b>Note del dirigente:</b>
            <br/>
            {this.props.accreditamento.dirigente_sections_feedback['finalSection'].note}
          </div>
          : null
        }
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(Esito);

