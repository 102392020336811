import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import SpinnerLoading from '@common/components/SpinnerLoading';
import SettoreServices from '@modules/tabelle/services/Settore';
import AmbitoServices from '@modules/tabelle/services/Ambiti';
import LivelloComplessitaServices from '@modules/tabelle/services/LivelliComplessita';

import Ateco2007Services from '@modules/tabelle/services/Ateco2007';
import Isco08Services from '@modules/tabelle/services/Isco08';
import IstatProfessioniServices from '@modules/tabelle/services/IstatProfessioni';

import ModelService from '../services/QualificaProfessionale';

import format from '@common/utils/formatters/_';
import { message, Table, Button, Drawer, Tooltip, Popconfirm } from 'antd';
import DettaglioAreaAttivita from '../components/DettaglioAreaAttivita';
import TabellaAreaAttivita from '../components/TabellaAreaAttivita';

const columns = (component) => {
  let ret = [
    {
      title: '',
      dataIndex: 'azione',
      key: 'azione',
      render: (text, record) => (
        <div>

          <React.Fragment>
            <Tooltip placement="top" title="Dettagli">
              <Button type='default' className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.open(record)} />
            </Tooltip>
          </React.Fragment>

          {!component.props.readOnly &&
            <React.Fragment>
              <Tooltip placement="top" title="Elimina">

                <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere l'area di attività?" onConfirm={() => { component.deleteAttivita(record) }} okText="Si" cancelText="No">
                  <Button className="m2w" size="small" shape="circle" icon="delete" />
                </Popconfirm>

              </Tooltip>
            </React.Fragment>
          }

        </div>
      ),
    },
    {
      title: 'Denominazione ADA',
      dataIndex: 'denominazione_ada',
      key: 'denominazione_ada',
      render: (text, record) => (<div>{record.denominazione_ada}</div>)
    },

    {
      title: 'UC',
      dataIndex: 'uc',
      key: 'uc',
      render: (text, record) => (<div>{record.uc}</div>)
    },

  ];
  return ret;
}

export const getForm = (component) => {

  return [
    {
      cols: [
        {
          items: [
            {
              name: 'dati_richiesta',
              type: 'title',
              element: 'h4',
              text: 'Dati',
              props: {
                disabled: component.props.readOnly,
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Denominazione Figura',
              type_string: 'text',
              name: 'denominazione_figura',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Esempi di possibili denominazioni ricorrenti nel mondo del lavoro',
              type_string: 'text',
              name: 'esempi_denominazione_figura',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Settore di riferimento',
              type_string: 'text',
              name: 'id_settore',
              props: {
                disabled: component.props.readOnly,
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              options_props: 'settori',
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Ambito di attività',
              type_string: 'text',
              name: 'id_ambito',
              props: {
                disabled: component.props.readOnly,
                showSearch: true,
                filterOption: (input, option) => {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              },
              options_props: 'ambiti',
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Livello di complessità',
              type_string: 'text',
              name: 'id_liv_compl',
              props: {
                disabled: component.props.readOnly,
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              options_props: 'livelliComplessita',
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Descrizione',
              type_string: 'text',
              name: 'descrizione',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'rangepicker',
              label: 'Periodo di validità',
              type_string: 'text',
              name: 'periodo_validita',
              props: {
                disabled: component.props.readOnly,
              },
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'integer',
              label: 'Decreto autorizzativo',
              type_string: 'text',
              name: 'decreto_autorizzativo',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'radio',
              label: 'Laboratorio',
              name: 'laboratorio',
              option_type: 'button',
              props: {
                disabled: component.props.readOnly,
              },
              options: [
                {
                  value: '1',
                  label: 'Si'
                },
                {
                  value: '0',
                  label: 'No'
                }
              ]
            },
          ],
        },
      ],
    },

    {
      cols: [
        {
          items: [
            {
              name: 'contesto_esercizio',
              type: 'title',
              element: 'h4',
              text: 'Contesto di esercizio',
              props: {
                disabled: component.props.readOnly,
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Tipologia rapporti di lavoro',
              type_string: 'text',
              name: 'tipologia_rapporti_lavoro',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Collocazione contrattuale',
              type_string: 'text',
              name: 'collocazione_contrattuale',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Collocazione organizzativa',
              type_string: 'text',
              name: 'collocazione_organizzativa',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Opportunità sul mercato del lavoro',
              type_string: 'text',
              name: 'opportunita_mercato_lavoro',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Percorsi formativi',
              type_string: 'text',
              name: 'percorsi_formativi',
              props: {
                disabled: component.props.readOnly,
              },
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
      ],
    },


    {
      cols: [
        {
          items: [
            {
              name: 'Indici di conversione',
              type: 'title',
              element: 'h4',
              text: 'Indici di conversione',
              props: {
                disabled: component.props.readOnly,
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              mode: 'multiple',
              label: 'ISCO - 08',
              type_string: 'text',
              name: 'isco08',
              props: {
                disabled: component.props.readOnly,
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              options_props: 'isco08',
              validations: [
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              mode: 'multiple',
              label: 'ISTAT Professioni (CP 2011) ',
              type_string: 'text',
              name: 'istat_cp_2011',
              props: {
                disabled: component.props.readOnly,
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              options_props: 'professioni',
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'select',
              mode: 'multiple',
              label: 'ATECO 2007',
              type_string: 'text',
              name: 'ateco_2007',
              props: {
                disabled: component.props.readOnly,
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              options_props: 'ateco2007',
              validations: [
                !component.props.readOnly ? {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                } : {},
              ],
            }
          ]
        },
      ],
    },


    {
      cols: [
        {
          items: [
            {
              name: 'repertori_descrizione',
              type: 'title',
              element: 'h4',
              text: 'Repertori di descrizione',
              props: {
                disabled: component.props.readOnly,
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Repertorio nazionale delle figure per i percorsi IFTS',
              type_string: 'text',
              name: 'ifts',
              props: {
                disabled: component.props.readOnly,
              },
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Repertorio nazionale delle figure per i percorsi IeFP',
              type_string: 'text',
              name: 'iefp',
              props: {
                disabled: component.props.readOnly,
              },
            }
          ]
        },
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Fonti documentarie',
              type_string: 'text',
              name: 'fonti_documentarie',
              props: {
                disabled: component.props.readOnly,
              },
            }
          ]
        },
      ],
    },


    {
      cols: [
        {
          items: [
            {
              name: 'elenco_aree_attivita',
              type: 'title',
              element: 'h4',
              text: 'Elenco Aree di Attività',
              props: {
                disabled: component.props.readOnly,
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },

    {
      cols: [
        {
          items: [
            {
              name: 'aree_attivita_table',
              type: 'child',
              child: <div style={{ marginBottom: 16 }}>
                {!component.props.readOnly &&
                  <Button className="m5h bg-green" icon="plus" onClick={() => component.setState({ open_drawer_add_attivita: true })}>Aggiungi area attività</Button>
                }
                <Table
                  rowKey={(record) => record.id}
                  loading={component.state.loading}
                  columns={columns(component)}
                  dataSource={component.state.data.areeAttivita}
                />
              </div>
            }
          ],
        },
      ],
    },
  ]
}

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [

      ...getForm(component),


      {
        cols: [
          {
            offset: 22,
            span: 2,
            items: [
              {
                type: 'submit',
                name: 'btn_submit',
                text: 'Salva',
                props: {
                  icon: 'save',
                  className: 'bg-green',
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.readOnly; }
                  }
                ],
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};

export default class QualificaProfessionaleForm extends Component {

  state = {
    data: null,
    loading: false,
    settori: [],
    isco08: [],
  };

  componentDidMount() {

    SettoreServices.list({sort:'nome_settore', pageSize: 0}, res => {
      let settori = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'settori_' + el.id,
            label: el.nome_settore,
            value: el.id
          }
        })
        : [];

      if (this.props.repertorio) {
        let data = format.replaceFormDatesIn([
          'valido_dal',
          'valido_al'
        ], this.props.repertorio, 'YYYY-MM-DD');

        if (this.props.repertorio.id_settore && !settori.find(el => el.value === this.props.repertorio.id_settore)) {
          SettoreServices.view({}, this.props.repertorio.id_settore, res => {
            settori.push({
              key: 'settori_' + res.data.data.id,
              label: res.data.data.nome_settore,
              value: res.data.data.id
            });
            this.setState({ settori: settori })
          })

        } else {
          this.setState({ settori: settori })
        }

        this.setState({ data: data },
          () => {
            let periodo_validita = [this.state.data?.valido_dal, this.state.data?.valido_al];
            this.formSelRef.props.form.setFieldsValue({ periodo_validita: periodo_validita });
          });
      } else {
        this.setState({ settori: settori })
      }

    })

    AmbitoServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'ambito_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ ambiti: rows })
    })

    LivelloComplessitaServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'livelloComplessita_' + el.id,
            label: el.gruppo + ' - ' + el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ livelliComplessita: rows })
    })

    Ateco2007Services.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'ateco2007_' + el.id,
            label: el.codice + ' - ' + el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ ateco2007: rows })
    })

    Isco08Services.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'isco08_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ isco08: rows })
    })

    IstatProfessioniServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'professione_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ professioni: rows })
    })
  }

  deleteAttivita(element) {
    let listaAttivita = this.state.data.areeAttivita;
    listaAttivita = listaAttivita.filter(el => el.id !== element.id);
    this.setState({data: {...this.state.data, areeAttivita : listaAttivita}})
   }

   addAda(element) {
    let listaAttivita = this.state.data.areeAttivita? this.state.data.areeAttivita : [];
    listaAttivita.push(element);
    this.setState({
      open_drawer_add_attivita: false, 
      data: {...this.state.data, areeAttivita : listaAttivita}})
   }

  onSubmit(data) {

    data.valido_dal = data.periodo_validita[0];
    data.valido_al = data.periodo_validita[1];

    data = format.replaceFormDatesOut([
      'valido_dal',
      'valido_al'
    ], data, 'YYYY-MM-DD');

    data = format.revertBoolean([
      'laboratorio'
    ], data);

    data= {...data, areeAttivita: this.state.data.areeAttivita}

    if (!this.state.loading) {
      this.setState({ loading: true }, () => {


        if (this.state.data.id) {
          ModelService.update(data, this.state.data.id, res => {
            message.success("Qualifica professionale salvata correttamente");
            //this.setState({ data: res.data.data, loading: false });
            this.props.history.push("/repertorio/qualifiche");
            //eventBroker.trigger('loadQualifica');
          }, () => this.setState({ loading: false }));

        } else {
          ModelService.create(data, res => {
            message.success("Qualifica professionale salvata correttamente");
            //this.setState({ data: res.data.data, loading: false });
            this.props.history.push("/repertorio/qualifiche");
            //eventBroker.trigger('loadQualifica');
          }, () => this.setState({ loading: false }));

        }

        // let save = this.state.data.id ? ModelService.update : ModelService.create
        /* save(data, this.state.data.id, res => {
           console.warn(res);
           message.success("Qualifica professionale salvata correttamente");
           //this.props.history.goBack();
           this.setState({ data: res.data.data, loading: false });
           //eventBroker.trigger('loadQualifica');
           }, () => this.setState({loading: false }) ); */

      });
    }
  }

  open(element) {
    this.setState({
      element_opened: element
    }, () => this.setState({ open_drawer: true }))
  }

  getValues() {
    let vals = this.state.data;

    if (typeof vals.isco08 === 'string' || vals.isco08 instanceof String){
      vals.isco08 = JSON.parse(vals.isco08);
    }
    if (typeof vals.istat_cp_2011 === 'string' || vals.istat_cp_2011 instanceof String){
      vals.istat_cp_2011 = JSON.parse(vals.istat_cp_2011);
    }
    if (typeof vals.ateco_2007 === 'string' || vals.ateco_2007 instanceof String){
      vals.ateco_2007 = JSON.parse(vals.ateco_2007);
    }

    return format.convertBoolean([
      'laboratorio'
    ], vals);
  }

  render() {
    return (!this.state.data ? <SpinnerLoading message='Caricamento dati...' /> :
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>
        <CommonForm
          loading={this.state.loading || false}
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          form_model={formLayout(this)}
          values={this.getValues()}
          readOnly={this.props.readOnly}
          onSubmit={this.onSubmit.bind(this)}
          settori={this.state.settori}
          ambiti={this.state.ambiti}
          livelliComplessita={this.state.livelliComplessita}
          ateco2007={this.state.ateco2007}
          isco08={this.state.isco08}
          professioni={this.state.professioni}
        />

        <Drawer
          title="AREA DI ATTIVITÀ"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false, element_opened: null })}
          visible={this.state.open_drawer}
          width={window.innerWidth - (window.innerWidth / 100 * 5)}
          destroyOnClose={true}
        >
          <div>
            <DettaglioAreaAttivita
              values={this.state.element_opened}
              subform={true}
            />
          </div>
        </Drawer>

        <Drawer
          title="AGGIUNGI AREA DI ATTIVITÀ"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer_add_attivita: false })}
          visible={this.state.open_drawer_add_attivita}
          width={window.innerWidth - (window.innerWidth / 100 * 5)}
          destroyOnClose={true}
        >
          <TabellaAreaAttivita 
          {...this.props}
          addAda={(data) => this.addAda(data)}
          base_url={this.state.data.id? "/repertorio/qualifiche/" + this.state.data.id : "/repertorio/qualifiche/nuova"}
          list={this.state.data.areeAttivita || []}
          />
        </Drawer>

      </div>
    )
  }
}

