import React, { Component } from 'react';
import { Button, Card, Tag, Tooltip, Icon, Upload } from 'antd';

import moment from 'moment';

import eventBroker from '@common/utils/services/EventBroker';

import TableComponent from '@common/components/table';
import affidamentoStatic from '@common/utils/static/affidamento';

import ModelServices from '../services/Progetto';

import _ from '@common/utils/formatters/_';


const renderActions = (element, component) => {

  return <div style={{ display: 'inline-block' }}>

    {
      <Tooltip placement="top" title="Visualizza domanda">
        <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => { component.props.history.push('/affidamento/progetto/' + component.props.match.params.id + '/iscrizione/' + element.id) }} />
      </Tooltip>
    }

    {
      <React.Fragment>
        {element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.iscritto &&
          <Tooltip placement="top" title='Scarica il contratto firmato'>
            <Button className="m2w btn-green" size="small" shape="circle" icon="file-text" onClick={() => component.props.downloadContratto(element.id, 'application/pdf', "contratto.pdf")} />
          </Tooltip>
        }
        {(element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.accettato
          || element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.iscritto
          || element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.convocazione_inviata) &&
          <Upload
            {...component.props}
            accept={'.pdf'}
            multiple={false}
            fileList={null}
            showUploadList={false}
            customRequest={obj => { component.props.uploadContratto(element.id, obj.file) }}
          >
            <Tooltip placement="top" title='Carica il contratto firmato'>
              <Button className="m2w" size="small" shape="circle" icon="file-text" />
            </Tooltip>
          </Upload>
        }
      </React.Fragment>
    }

    {(element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.accettato
      || element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.convocazione_inviata) &&
      <Tooltip placement="top" title="Scarica il contratto">
        <Button className="m2w" size="small" shape="circle" icon="download" onClick={() => component.props.downloadContratto(element.id, 'application/vnd.oasis.opendocument.text', "contratto.odt")} />
      </Tooltip>
    }
  </div>
}

const model = (component) => {
  return {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 90 }}>
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Discente',
        dataIndex: '',
        sorter: false,
        key: 'discente',
        render: element => <div style={{ width: 140 }}>
          {element.anagrafica?.nome + ' ' + element.anagrafica?.cognome}
        </div>
      },
      {
        title: 'Stato invito',
        dataIndex: '',
        sorter: false,
        key: 'stato_flusso',
        render: element => <div >
          {(element.stato === affidamentoStatic.stato_iscrizione.non_idoneo
            || element.stato_flusso === affidamentoStatic.stato_flusso_iscrizione.rifiutato)
            ?
            <React.Fragment>{
              element.stato_flusso
                ?
                affidamentoStatic.getStatoFlussoIscrizioneLabel(element.stato_flusso)
                :
                affidamentoStatic.getStatoIscrizioneLabel(element.stato)
            }
              <Tooltip placement="top" title={element.note || 'Nessuna motivazione fornita'}><Icon type="info-circle" className="ico-blue" />
              </Tooltip>
            </React.Fragment>
            :
            affidamentoStatic.getStatoFlussoIscrizioneLabel(element.stato_flusso)}
        </div>
      },
      {
        title: 'Data invio',
        dataIndex: '',
        sorter: true,
        key: 'data',
        render: element => <div style={{ width: 140 }}>
          {(element.data) ? moment(element.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        </div>,
      },
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {renderLabelTag(element.stato, component)}
        </div>,
      }
    ]
  };
}

const renderLabelTag = (stato, component) => {
  switch (stato) {

    case affidamentoStatic.stato_iscrizione.idoneo:
    case affidamentoStatic.stato_iscrizione.iscritto:
      return <Tag color="green">{affidamentoStatic.getStatoIscrizioneLabel(stato)}</Tag>;

    case affidamentoStatic.stato_iscrizione.non_idoneo:
    case affidamentoStatic.stato_iscrizione.abbandonato:
      return <Tag color="red">{affidamentoStatic.getStatoIscrizioneLabel(stato)}</Tag>;

    case affidamentoStatic.stato_iscrizione.in_attesa:
      return <Tag color="yellow">{affidamentoStatic.getStatoIscrizioneLabel(stato)}</Tag>;

    default:
      return null;
  }

}


class TableIscrizioni extends Component {

  constructor(props) {
    super(props);
    this.refreshIscrizioni = this.refreshIscrizioni.bind(this);
    eventBroker.on('refreshIscrizioni', this.refreshIscrizioni);
  }


  base_url = '/affidamento/progetto/' + this.props.match.params.id + '/domande'
  default_order = 'id'

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  refreshIscrizioni() {
    this.refresh();
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);
    //params.with = 'domande';
    this.setState({ loading: true });
    ModelServices.getDomande(this.props.match.params.id, params, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }



  printPdf(id) {
    /*DomandaServices.printPdf(id, res => {
      const _blob = new Blob([res.data], { type: 'application/pdf' });
      FileSaver.saveAs(_blob, "formulario.pdf");
    });*/
  }


  render() {
    return (
      <Card
        className="shadow no-pad"
        bordered={false}>
        <TableComponent
          data={this.state.data}
          model={model(this)}
          table_props={{ rowKey: 'id' }}
          size="medium"
          ref="table_component"
          loading={this.state.loading || this.props.loadingContratto}
          with_server_pagination={true}
          updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
          updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
        />
      </Card>
    );
  }
}

export default TableIscrizioni;