import React, {Component} from 'react';

import {Button, Popconfirm, List, Drawer, message} from 'antd';


import AnagraficaServices from '../services/Anagrafica';
import ModelForm from '../forms/Indirizzo';
import checkPermissions from '@common/utils/check/permissions';
class Indirizzi extends Component {


  state = {
    loading: false,
    element_values: {}, // valori elemento da modificare
    open_drawer: false,
    drawer_title: "Aggiungi un indirizzo",
    eliminating: []
  }

  update() {
    //this.props.onUpdate({documenti})
  }

  create() {
    this.setState(
      {
        element_values: {},
        drawer_title: 'Aggiungi un indirizzo',
        open_drawer: true
      }
    );
  }

  edit(item) {
    this.setState({ element_values: { ...item }, drawer_title: 'Aggiorna indirizzo',  open_drawer: true });
  }

  addIndirizzo(data) {
    this.setState({ loading: true });
    AnagraficaServices.addIndirizzo( data, this.props.model.id, (res) => {
      message.success("Indirizzo aggiunto con successo");
      this.props.onUpdate({
        ...this.props.model,
        conAnagraficaIndirizzi: [
          ...this.props.model.conAnagraficaIndirizzi,
          res.data.data
        ]
      })
      this.setState({open_drawer: false, loading: false}, () => this.form.reset());
    }, () => {
      this.setState({ loading: false });
    })
  }

  editIndirizzo(item_id, data) {

    AnagraficaServices.editIndirizzo(item_id, data, this.props.model.id, (res) => {
      message.success("Indirizzo aggiornato con successo");
      this.props.onUpdate({
        ...this.props.model,
        contatti: [
          res.data.data
        ]
      })
      this.setState({ open_drawer: false });
    }, () => null)
  }

  deleteIndirizzo(id) {
    if(this.state.eliminating.indexOf(id) !== -1) return;

    this.setState({eliminating: [...this.state.eliminating, id]}, ()=>{
      AnagraficaServices.deleteIndirizzo( this.props.model.id, id, () => {
        message.success("Indirizzo eliminato con successo");
        this.setState({eliminating: this.state.eliminating.filter(el => el !== id)});
        this.props.onUpdate({
            ...this.props.model,
            conAnagraficaIndirizzi: this.props.model.conAnagraficaIndirizzi.filter(els => els.id !== id)
          })
      }, () => this.setState({eliminating: this.state.eliminating.filter(el => el !== id)}) )
    })
  }

  getSource() {
    try {
      return this.props.model.conAnagraficaIndirizzi
    } catch(e) {
      return []
    }
  }

  getItemActions(item) {
    return checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica','adminPermissions'])
    ? [
      <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento?" onConfirm={()=>this.deleteIndirizzo(item.id)} okText="Si" cancelText="No">
        <Button loading={this.state.eliminating.indexOf(item.id) !== -1} type="danger" size="small" ghost>Elimina</Button>
      </Popconfirm>,
      /* <Button type="primary" size="small" onClick={() => this.edit(item)} ghost>Modifica</Button> */
    ]
    : null
  }


  render () {
    return (
      <div>
        {
          checkPermissions.hasPermission(this.props.UserStore.user, ['EditAnagrafica','adminPermissions']) &&
          <Button type="primary" ghost size="small" style={{marginBottom: 12}} onClick={()=>this.create()}>Aggiungi</Button>
        }
        <List
          dataSource={this.getSource()}
          renderItem={item => (
            <List.Item
              actions={this.getItemActions(item)}
            >  {item.indirizzo.full_address + " - " + item.tipo + " - "}
            </List.Item>
          )}
        />
        {/*<Modal
          title={this.state.drawer_title}
          visible={this.state.open_drawer}
          okText={null}
          width={800}
          okButtonProps={{ style: {display: 'none' }} }
          onCancel={() => this.setState({ open_drawer: false }, () => this.form.reset())}
        >*/}
        <Drawer
        title={this.state.drawer_title}
        width={720}
        placement="right"
        onClose={()=>this.setState({open_drawer: false})}
        maskClosable={true}
        visible={this.state.open_drawer}
        style={{
          overflow: 'auto',
        }}
      >
      <ModelForm
        ref={(ref) => this.form = ref?.getWrappedInstance()}
        //values={this.state.element_values}
        model={this.props.model}
        loading={this.state.loading}
        submit={(data) => {
          !this.state.element_values.id ?
            this.addIndirizzo(data) :
            this.editIndirizzo(this.state.element_values.id, data)
        }} />
      </Drawer>
      {/*</Modal>*/}
    </div>
    );
  }
}
export default Indirizzi;
