import React, { Component } from 'react';

import { Button, Drawer, Table, Tooltip, Popconfirm } from 'antd';

import format from '@common/utils/formatters/_';

import Create from '@affidamento/forms/risorse-capacita/docenti/Create';
import Update from '@affidamento/forms/risorse-capacita/docenti/Update';
import TitoliCompetenze from '@affidamento/forms/risorse-capacita/TitoliCompetenze';
import Documenti from '@affidamento/forms/risorse-capacita/Documenti';

import CapacitaGestionaliServices from '@affidamento/services/CapacitaGestionali';



const columns = (component) => {
  return [
      {
        title: 'Dati anagrafici',
        dataIndex: 'nome',
        key: 'nome',
        render: (text, record) => (<div style={{width: 150}}>{record.anagrafica.nome + " " + record.anagrafica.cognome}<br />{record.anagrafica.cf}</div>)
      }, 
      /*{
        title: 'Sedi',
        dataIndex: 'sedi',
        key: 'sedi',
        render: (text, record) => (<div style={{width: 150}}>{
            record.sedi.map(sede=>{
              return <p key={"docente_sede_"+record.id+"_"+sede.id}>{sede.indirizzo.via + " " + sede.indirizzo.ncivico + " " + sede.indirizzo.cap}</p>
            })
        }</div>)
      },*/
      /*{
        title: 'Rapporto Lavoro',
        dataIndex: 'rapporto_lavoro',
        key: 'rapporto_lavoro_',
        render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).id_tipo_rapporto_lavoro, 'tipo_rapporto_lavoro', component.props.GlobalStore ) }</div>)
      },
      {
        title: 'Durata',
        dataIndex: 'rapporto_lavoro_durata',
        key: 'rapporto_lavoro_durata_',
        render: (text, record) => (<div style={{width: 50}}>{ component.getRapportoLavoro( record ).durata_rapporto_lavoro }</div>)
      },
      {
        title: 'Titolo di studio',
        dataIndex: 'titolo_studio',
        key: 'titolo_studio',
        render: (text, record) => (<div style={{width: 100}}>{ format.replaceStub( component.getRapportoLavoro( record ).titolo_studio, 'tipo_titolo_studio', component.props.GlobalStore ) }</div>)
      },*/
      {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
          <div style={{width: 120}}>
            <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Aggiorna"}>
              <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.update(record.id)} />
            </Tooltip>
            <Tooltip placement="top" title="Titoli e competenze">
              <Button className="m2w" size="small" shape="circle" icon="book" onClick={() => component.titoliCompetenze(record.id)} />
            </Tooltip>
            <Tooltip placement="top" title="Documenti">
              <Button
              //type={component.validRecordMedia(record) ? 'default' : 'danger'}
              type={'default'}
              className="m2w" size="small" shape="circle" icon="folder" onClick={() => component.documenti(record.id)} />
            </Tooltip>
            <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa scheda?"
            onConfirm={() => component.delete(record.id)} okText="Si" cancelText="No">
              <Button disabled={component.props.readOnly} loading={component.state.deleting.indexOf(record.id) !== -1} className="m2w" size="small" shape="circle" icon="delete" />
            </Popconfirm>

          </div>
        ),
      }
    ];
  }

class Docenti extends Component {

  state = {
    loading: true,
    open_drawer: false,
    updating_model: {},
    deleting: [],
    docenti: []
  };

  loadDocenti(){
    let newDocenti = [];

    CapacitaGestionaliServices.listProgetto( this.props.id_progetto, (res) => {
      this.setState({
        loading:false,
        docenti: res.data.data.docenti
      })
    })
  }

  componentDidUpdate(propsPrecedenti) {
    if (this.props.docenti !== propsPrecedenti.docenti) {
      this.loadDocenti();
    }
  }

  componentDidMount() {
    this.loadDocenti();
  }

  update(id) {
    this.setState({
      open_edit_drawer: true,
      updating_model: this.props.docenti.find(el => el.id === id)
    })
  }

  titoliCompetenze(id) {
    this.setState({
      open_titoli_competenze: true,
      updating_model: this.props.docenti.find(el => el.id === id)
    })
  }

  delete(id) {
    if(this.state.deleting.indexOf(id) === -1) {
      this.setState({deleting: [...this.state.deleting, id]}, ()=>{
        CapacitaGestionaliServices.deleteDocente( id, this.props.accreditamento.id, ()=>{

          this.setState({deleting: this.state.deleting.filter(el => el !== id)});
          if(this.props.onUpdate) this.props.onUpdate();

        }, () => this.setState({deleting: this.state.deleting.filter(el => el !== id)}) )

      })

    }
  }

  documenti(id) {
    this.setState({
      open_documenti: true,
      updating_model: this.props.docenti.find(el => el.id === id)
    })
  }

  getRapportoLavoro(record) {
    try {
      return record.anagrafica.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }

  /**
   * File obbligatori per le anagrafiche
   * @type {Array}
   */
  required_files = ['Autorizzazione al trattamento dei dati personali secondo i termini di legge', 'Dichiarazione della veridicità dei dati riportati, in base al D.P.R. 445 del 28/12/2000'];

  getRequiredMedia() {
    return this.props.GlobalStore.stubs.tipo_media.filter(el => this.required_files.indexOf(el.tipo_media) !== -1)
  }

  validRecordMedia(record) {
    let valid = true;
    this.getRequiredMedia().forEach( r => {
      if(!this.getRapportoLavoro(record).media.find( el => el.tipo_media === r.id && el.deleted !== 1 )) valid = false;
    })
    return valid;
  }

  refresh() {
    return this.props.refresh();
  }

  render() {
    return (
      <div style={{marginTop: 16}}>
        <h4>Anagrafica Docenti (inserire i docenti coinvolti)</h4>
        <Button
          type="primary"
          icon="plus"
          disabled={this.props.readOnly}
          onClick={()=>this.setState({open_drawer: true})}>
          Inserisci docente
        </Button>
        <Drawer
          title="ANAGRAFICA DOCENTE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={1024}
          destroyOnClose={true}
        >
          <Create 
            compilazioneAccreditamento={false} 
            {...this.props} 
            onUpdate={()=>{ this.loadDocenti(); this.setState({open_drawer: true}); this.props.onUpdate(); }}
            onCloseDrawerAnagraficaDocenteAFCInsert={ () => this.setState({open_drawer: false}) }
          />
        </Drawer>
        <Drawer
          title="ANAGRAFICA DOCENTE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_edit_drawer: false })}
          visible={this.state.open_edit_drawer}
          width={1024}
          destroyOnClose={true}
        >
          <Update readOnly={this.props.readOnly} {...this.props} model={this.state.updating_model} onUpdate={()=>{ this.setState({open_edit_drawer: false}); this.props.onUpdate(); }} />
        </Drawer>
        <Drawer
          title="TITOLI E COMPETENZE"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_titoli_competenze: false })}
          visible={this.state.open_titoli_competenze}
          width={1024}
          destroyOnClose={true}
        >
          <TitoliCompetenze readOnly={this.props.readOnly} {...this.props} anagrafica={this.state.updating_model.anagrafica} onUpdate={()=>{ this.setState({open_titoli_competenze: false}); this.props.onUpdate(); }} />
        </Drawer>
        <Drawer
          title="DOCUMENTI"
          placement="right"
          closable={true}
          onClose={() => {
            this.props.onUpdate();
            this.setState({ open_documenti: false })
          }}
          visible={this.state.open_documenti}
          width={1024}
          destroyOnClose={true}
        >
          <Documenti
            {...this.props}
            accreditation_id={this.props.accreditamento.id}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
            anagrafica={this.state.updating_model.anagrafica}
            onUpdate={()=>{ this.setState({open_documenti: false}); }}
          />
        </Drawer>
        {
          this.state.loading === false ?
          <Table
          pagination={false}
          loading={this.state.loading || this.props.loading_data || this.props.loading_docenti} columns={columns(this)} dataSource={ this.state.docenti.filter(docente => docente.id_progetto !== null) } rowKey="id" />
          : null
        }
        
      </div>
    );
  }
}
export default Docenti;