import React from 'react';
import { message, notification } from 'antd';

import { store } from '../../App';

var axios = require('axios');

message.config({
  duration: 5
});

notification.config({
  placement: 'topLeft',
  duration: 10,
});

/**
 * Handler richieste http
 *
 * ```js
 *  let req = new BaseRequest();
 *
 *   req.makeRequest(
 *      {GET params (query string)},
 *      {POST|PUT params},
 *      null,
 *      "get|post|put|delete",
 *      "url",
 *      {Func Success callback},
 *      {Func Error callback},
 *      {Func Progress callback}
 *    );
 * ```
 * @author Fabio Rizzo
 *
 * @component
 */
class BaseRequest {
  /**
   * Costruttore
   *
   * @param  {Array} props  Proprietà di default
   * @return {void}
   *
   * @method
   * @public
   */
  constructor(props) {
    this.successCallback = null;
    this.errorCallback = null;
    this.progressCallback = null;
    this.withFile = false;
    this.params = {};
    this.data = {};
    this.file = null;
    this.instance = null;
    this.mantainNotification = false;
    this.block_notification = false;
  }

  /**
   * Crea richiesta http
   *
   * @param  {Object} params           Parametri per query string
   * @param  {Object} data             Parametri POST e PUT
   * @param  {Object} file             File da inserire come formData
   * @param  {String} method           GET|POST|PUT|DELETE
   * @param  {String} url              Url
   * @param  {Function} successCallback  Callback ok
   * @param  {Function} errorCallback    Callback error
   * @param  {Function} progressCallback Callback progress tracking
   * @return {void}
   *
   * @method
   * @public
   */
  async makeRequest(
    params = {},
    data = {},
    file = null,
    method = 'get',
    url = '/',
    successCallback = null,
    errorCallback = null,
    progressCallback = null,
    mantainNotification = false,
    block_notification = false
  ) {
    if (params.withFile) this.withFile = true;

    //console.log('ci sono file', file);
    this.successCallback = successCallback;
    this.errorCallback = errorCallback;
    this.progressCallback = progressCallback;

    this.params = params;
    this.data = data;
    this.file = file;
    this.mantainNotification = mantainNotification;
    this.block_notification = block_notification; 

    this._createAxiosInstance(url);

    return this.doCall(url, method);
  }

  
  _createAxiosInstance(url){
    this.instance = axios.create({
      baseURL: store.getState().GlobalStore.API_URL + url,
      timeout: 300000,
      headers: {
        ...axios.defaults.headers,
      },
    });
  }

  _getAuthorizationHeader(){
    return localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : null;
  }


  /**
   * Effettua la richiesta e richiama la callback in base al responso
   *
   * @param  {String} url    Url
   * @param  {String} method Metodo
   * @return {void}
   *
   * @method
   * @public
   */
  async doCall(url, method) {
    //if(!this.mantainNotification) notification.destroy();

    //console.log ('sending', url, method, this.data);
    let axios_config = {
      method: (method === 'get_file') ? 'get' : (method === 'post_file') ? 'post' : method,
      headers: {
        ...axios.defaults.headers,
        Authorization: this._getAuthorizationHeader()
      },
      params: this.params,
      data: this.data,
      responseType: (method === 'get_file' || method === 'post_file') ? "blob" : "json",
      onUploadProgress: function (progressEvent) {
        if (this.progressCallback) this.progressCallback();
      },
      maxRedirects: 0,
    };


    if (this.file) {

      let formData = new FormData();

      if (Array.isArray(this.file)) {
        this.file.map((f, n, a) =>
          formData.append(f.chiave + '[' + n + ']', f.file)
        );
      } else {
        formData.append(this.file.chiave, this.file.file);
      }

      let data_array_keys = Object.keys(this.data);
      if (data_array_keys.length > 0) {
        if (data_array_keys.length > 0) {
          data_array_keys.forEach(key => {
            if (typeof this.data[key] === 'string' || typeof this.data[key] === 'number') {
              if(this.data[key] !== null) formData.append(key, this.data[key]);
            } else {
              if(this.data[key] !== null) formData.append(key, JSON.stringify(this.data[key]));
            }

          });
        }
      }



      axios_config.headers = {
        //...axios.defaults.headers,
        ...axios_config.headers,
        'content-type': 'multipart/form-data',
      };
      axios_config.data = formData;

    }



    //console.log('invio dati', axios_config)

    return this.instance
      .request(axios_config)
      .then(response => {
        if (this.successCallback)
          this.successCallback(response);
        return response;
      })
      .catch(async e => {
        console.error('base Request', e);

        //if(this.block_notification) return;

        /**
         * Gestiamo la scadenza della sessione
         * @param  {[type]} e.response.status [description]
         * @return {[type]}                   [description]
         */
        if(e.response && e.response.status === 401 && e.response.data && e.response.data.data && e.response.data.data.message && e.response.data.data.message === "Non sei autorizzato, verrai sloggato e reindirizzato alla home") {
          localStorage.removeItem('token');
          if(!this.block_notification){
            notification.error({
              message: 'Errore',
              description: e.response.data.data.message,
            });
          }
          setTimeout(()=>store.dispatch({
            type: 'LOGOUT'
          }), 1500);
          return Promise.reject(e);
        }
        //console.log ('errore', e, e.response);
        let showed_error = false;
        var json = e.response && e.response.data && e.response.data.data
          ? e.response.data.data
          : '';

        /**
         * Responso blob
         * @param  {[type]} e.response &&            e.response.data && e.response.data instanceof Blob [description]
         * @return {[type]}            [description]
         */
        if (e.response && e.response.data && e.response.data instanceof Blob) {
          json = await this.parseBlob(e.response.data);
        }

        if (e.response && e.response.status && e.response.status === 422) {
          if (Array.isArray(json)) {
            let errs = [];
            json.forEach(err => {
              if (err.field !== undefined && err.message) {
                errs.push(React.createElement('p', {}, toTitleCase(err.field  ? err.field.replace('id_', '') + ': ': '') + err.message))
              }
            })
            showed_error = true;
            if(!this.block_notification){
              notification.error({
                message: 'Errore',
                description: React.createElement(
                  'div', {}, errs
                ),
              });
            }
          }

        }

        /**
         * Single error
         * @param  {[type]} json.error [description]
         * @return {[type]}            [description]
         */
        if (json.error) {
          showed_error = true
          if (json.error !== 'Unauthenticated.') {
            //message.error(json.error);
            if(!this.block_notification){
              notification.error({
                message: 'Errore',
                description: json.error,
              });
            }
          }
        }

        /**
         * multiple errors
         * @param  {[type]} json.errors [description]
         * @return {[type]}             [description]
         */
        if (json.errors) {
          try {
            let errs = [];
            Object.keys(json.errors).forEach(key => {

              json.errors[key].forEach(txt => {
                errs.push(React.createElement('p', {}, txt));
              });

            });

            showed_error = true;

            if(!this.block_notification){
              notification.error({
                message: 'Errore',
                description: React.createElement(
                  'div', {}, errs
                ),
              });
            }
          } catch (ex) {
            showed_error = true
            //message.error('Errore');
            if(!this.block_notification){
              notification.error({
                message: 'Errore',
                description: '',
              });
            }
          }
        }

        /**
         * Messaggi da yii2
         * @param  {[type]} json.message &&            !showed_error [description]
         * @return {[type]}              [description]
         */
        if (json.message && !showed_error) {
          //showed_error = true;
          if (json.message !== 'Unauthenticated.') {
            try {
              // se json valido faccio il parsing
              var json_array;
              var errs = [];
              try{
                json_array = JSON.parse(json.message);
                Object.keys(json_array).forEach(key => {
                  json_array[key].forEach(txt => {
                    errs.push(React.createElement('p', {}, txt))
                  });
                });
              }catch( error ){
                errs.push(React.createElement('p', {}, json.message));
              }              
              
              showed_error = true;
              if(!this.block_notification){
                notification.error({
                  message: 'Errore',
                  description: React.createElement(
                    'div', {}, errs
                  ),
                });
              }
            }
            catch (ex) {
              console.error('ex', ex)
              // altrimenti mostro messaggio singolo
              showed_error = true
              //message.error(json.message);
              if(!this.block_notification){
                notification.error({
                  message: 'Errore',
                  description: json.message,
                });
              }
              return;
            }
          }
        }

        /**
         * Non ho potuto fare il parse del responso
         */
        if (!showed_error) {
          //message.error('Errore non definito');
          if(!this.block_notification){
            notification.error({
              message: "Errore non definito",
              description: 'Contatta l\'amministrazione per maggiori dettagli',
            });
          }
        }
        if (this.errorCallback)
          this.errorCallback(e);
        return Promise.reject(e);
      });
  }


  async parseBlob(blob) {
    try {
      const blobbed_error = await this.readFile(blob);
      return JSON.parse(blobbed_error).data;
    } catch (e) {
      return "";
    }
  }

  async readFile(content) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new Error('Problem parsing file'));
      };

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.readAsText(content);
    });
  }

}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

/**
 * @component
 */
export default BaseRequest;

