import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, message, Modal } from 'antd';

import CommonForm from '@common/components/form';
import checkPermission from "@common/utils/check/permissions";

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Scheda valida?',
              name: 'valid',
              option_type: 'button', // normal|button
              options: [
                {
                  value: true,
                  label: "Si"
                },
                {
                  value: false,
                  label: "No"
                }
              ],
              props: {

              },
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },

      ],
    }
  ],
  onSubmit: 'submitted',
};

class ValidazioneScheda extends Component {

  state = {
    open_modal_validate: false,
    disabled_button: this.props.feedback,
  }

  componentDidMount() {
    if(this.props.UserStore.user.role[0].name == "IstruttoreAmministrativo") {
      this.setState({ disabled_button: false });
    }
  }


  render() {
    return <div>{
      this.props.canGiveFeedback ?
        <React.Fragment>
          <Button
            loading={this.props.loadingFeedback}
            disabled={this.state.disabled_button}
            className="m5h" type="primary" icon="edit" onClick={() => this.setState({ open_modal_validate: true })}
            style={{ marginRight: 8 }}>
            Conferma scheda
          </Button>
          <Modal
            title="Inserisci note"
            confirmLoading={this.props.loadingFeedback}
            visible={this.state.open_modal_validate}
            onOk={async () => {

              if (!this.formNoteRef.props.form.getFieldsValue()['note'] || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
                message.error('Inserisci le note');
                return;
              }
              this.props.saveFeedback(this.formNoteRef.props.form.getFieldsValue()['note'], this.formNoteRef.props.form.getFieldsValue()['valid']);
              this.setState({ open_modal_validate: false });
            }}
            onCancel={() => this.setState({ open_modal_validate: false })}
          >
            <CommonForm
              wrappedComponentRef={inst => (this.formNoteRef = inst)}
              loading={this.props.loadingFeedback || false}
              form_model={formNoteLayout}
              values={
                this.props.feedback ?
                  { valid: this.props.feedback.valid, note: this.props.feedback.note } : { valid: true, note: '' }
              }
            />
          </Modal>
        </React.Fragment>
        : null
    }
      {
        this.props.feedback
          ?
          <div>
            <p>{this.props.feedback.note}<br />
              <strong>Scheda {!this.props.feedback.valid ? 'non ' : ''}valida</strong></p>
          </div>
          : null
      }
    </div>
  }
}

const AuditExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ValidazioneScheda);

export default withRouter(AuditExport);
