import React, { Component } from "react";
import moment from "moment";
import { Icon, message } from "antd";
import CommonForm from "@common/components/form";
import Documenti from '@common/components/Documenti';
import allegatiStatic from '@common/utils/static/allegati'

import enteStatic from "@common/utils/static/ente";
import formatter from "@common/utils/formatters/_";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "titolo_organizzazo",
                type: "title",
                element: "h4",
                text: "Dati ente",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "input",
                label: "Denominazione",
                type_string: "text", // field type
                name: "denominazione",
                props: {
                  // size: 'large',
                  disabled: component.props.accreditamento.ente
                    .aggiunto_manualmente
                    ? false
                    : true, //component.props.readOnly,
                  prefix: (
                    <Icon type="bank" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "input",
                label: "Codice fiscale",
                type_string: "text", // field type
                name: "cfente",
                props: {
                  // size: 'large',
                  disabled: component.props.accreditamento.ente
                    .aggiunto_manualmente
                    ? false
                    : true, //component.props.readOnly,
                  prefix: (
                    <Icon type="audit" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    validator: "requiredCf",
                  },
                  {
                    pattern: "^[0-9]{11,11}$",
                    message: "Codice fiscale non valido",
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: "input",
                label: "P.Iva",
                type_string: "text", // field type
                name: "partita_iva",
                props: {
                  // size: 'large',
                  disabled: component.props.accreditamento.ente
                    .aggiunto_manualmente
                    ? false
                    : true, //component.props.readOnly,
                  prefix: (
                    <Icon type="wallet" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    validator: "requiredPiva",
                  },
                  {
                    pattern: "^[0-9]{11,11}$",
                    message: "Codice fiscale non valido",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "input",
                label: "Email",
                type_string: "email", // field type
                name: "email",
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: "input",
                label: "Pec",
                type_string: "email", // field type
                name: "pec",
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: "input",
                label: "Fax",
                type_string: "text", // field type
                name: "fax",
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="file" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    pattern: "^([+]{0,1})([0-9 ]{6,50})$",
                    message: "Fax non valido",
                  },
                ],
              },
            ],
          },
        ],
      },

      ,
      {
        cols: [
          {
            items: [
              {
                type: "input",
                label: "Telefono",
                type_string: "text", // field type
                name: "telefono",
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^([+]{0,1})([0-9 ]{6,50})$",
                    message: "Telefono non valido",
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: "input",
                label: "Cellulare",
                type_string: "text", // field type
                name: "cellulare",
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mobile" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
              },
            ],
          },
        ],
      },

      {
        cols: [
          {
            items: [
              {
                type: "input",
                label: "Sito web",
                type_string: "text", // field type
                name: "sitoweb",
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="laptop" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "select",
                label: "Tipologia ente",
                name: "fine_lucro",
                options_props: "tipi_ente",
                onChange: "setTipologia",
                props: {
                  //size: 'large'
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "select",
                label: "Divisione forma giuridica",
                name: "divisione",
                options_props: "divisioni",
                onChange: "setDivisione",
                props: {
                  //size: 'large'
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Descrizione forma giuridica",
                name: "id_descrizione_forma_giuridica",
                options_props: "descrizioni",
                onChange: "setDescrizione",
                props: {
                  //size: 'large'
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: component.state.divisione_forma_giuridica_2_6
          ? [
              {
                items: [
                  {
                    type: "radio",
                    label:
                      "Dichiaro di avere in corso procedure di adeguamento alle norme di sicurezza e prevenzione",
                    name: "divisione_forma_giuridica_2_6",
                    option_type: "button",
                    options: [
                      {
                        value: true,
                        label: "Si",
                      },
                      {
                        value: false,
                        label: "No",
                      },
                    ],
                    props: {
                      style: { textAlign: "center" },
                    },
                    validations: [
                      {
                        type: "required",
                        error_message: "Campo obbligatorio",
                      },
                    ],
                  },
                ],
              },
            ]
          : [],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: "monthpicker",
                label: "Data di costituzione",
                name: "anno_costituzione",
                format: "MM-YYYY",
                props: {
                  //size: 'large',
                  disabled: component.props.readOnly,
                  style: { width: "100%" },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    validator: "onlyPastDate",
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: "input",
                label: "N. iscrizione CCIAA",
                type_string: "text", // field type
                name: "numero_ccia",
                props: {
                  disabled: component.props.readOnly,
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: component.state.CCIAA_required
                  ? [
                      {
                        type: "required",
                        error_message: "Campo obbligatorio",
                      },
                      {
                        pattern: "^[0-9]{1}[0-9]*$",
                        message: "Inserire un valore numerico",
                      },
                    ]
                  : [],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: "datepicker",
                label: "Data iscrizione CCIAA",
                name: "data_iscrizione",
                props: {
                  disabled: component.props.readOnly,
                  //size: 'large',
                  style: { width: "100%" },
                },
                validations: component.state.CCIAA_required
                  ? [
                      {
                        type: "required",
                        error_message: "Campo obbligatorio",
                      },
                      {
                        validator: "onlyPastDate",
                      },
                    ]
                  : [],
              },
            ],
          }, 
        ],
      },
    ],
    onSubmit: "submitted",
  };
};

class DatiEnteForm extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni_nascita: [],
    files: {},
    fine_lucro: null,
    divisione: null,
    divisione_forma_giuridica_2_6: false,
    CCIAA_required: true,
    visura_camerale_show: false,
  };

  async componentDidMount() {
    await this.setFineLucroEDivisione(this.props);
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id !== this.props.accreditamento.id)
      await this.setFineLucroEDivisione(newProps);
  }

  async setFineLucroEDivisione(props) {
    let divisione_forma_giuridica_2_6 = false;
    if (
      props.accreditamento &&
      props.accreditamento.ente &&
      props.accreditamento.ente.divisione_forma_giuridica_2_6
    ) {
      divisione_forma_giuridica_2_6 =
        props.accreditamento.ente.divisione_forma_giuridica_2_6;
    }

    const descrizioneFormaGiuridica =
      props.GlobalStore.stubs.descrizione_forma_giuridica.filter(
        (el) =>
          parseInt(el.id) ===
          parseInt(props.accreditamento.ente.id_descrizione_forma_giuridica)
      );
    const CCIAA_required = [
      "1.8.00",
      "2.5.00",
      "2.6.10",
      "2.6.20",
      "2.7.11",
      "2.7.12",
      "2.7.20",
      "2.7.30",
      "2.7.40",
      "2.7.51",
      "2.7.52",
      "2.7.53",
      "2.7.54",
      "2.7.55",
      "2.7.56",
      "2.7.90",
    ].includes(descrizioneFormaGiuridica.codice)
      ? true
      : false;

    await this.setState({
      fine_lucro: this.getFineLucro(props),
      divisione: this.getDivisione(props)?.value,
      divisione_forma_giuridica_2_6: divisione_forma_giuridica_2_6,
      CCIAA_required: CCIAA_required,
    });
    this.props.onChangeLucro({ ente: { ...props.accreditamento.ente } });
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error("Ricontrolla i campi");
          resolve(false);
        }
      });
    });
  }

  getFormData() {
    return formatter.replaceFormDatesOut(
      ["anno_costituzione"],
      formatter.replaceFormDatesOut(
        ["data_iscrizione"],
        this.formRef.props.form.getFieldsValue()
      ),
      "MM-YYYY"
    );
  }

  getDivisione(props) {
    try {
      return props.GlobalStore.stubs.divisione_forma_giuridica.find(
        (d) =>
          parseInt(d.value) ===
          parseInt(
            props.GlobalStore.stubs.descrizione_forma_giuridica.find(
              (e) =>
                parseInt(e.value) ===
                parseInt(
                  props.accreditamento.ente.id_descrizione_forma_giuridica
                )
            ).id_divisione
          )
      );
    } catch (e) {
      return {};
    }
  }

  getFineLucro(props) {
    try {
      return this.getDivisione(props).fine_lucro === enteStatic.lucro.si
        ? 1
        : 2;
    } catch (e) {
      return 1;
    }
  }

  getValues() {
    try {
      return {
        ...this.props.accreditamento.ente,
        anno_costituzione: moment(
          this.props.accreditamento.ente.anno_costituzione,
          "MM-YYYY"
        ),
        ...this.props.accreditamento.ente.contatti[0],
        fine_lucro: this.state.fine_lucro,
        divisione: this.state.divisione,
      };
    } catch (e) {
      return this.props.accreditamento.ente;
    }
  }

  getDivisioni() {
    try {
      //console.log('lista divisioni', this.props.GlobalStore.stubs.divisione_forma_giuridica.filter(el => parseInt(el.fine_lucro) == parseInt(this.state.fine_lucro)) )
      return this.props.GlobalStore.stubs.divisione_forma_giuridica.filter(
        (el) => parseInt(el.fine_lucro) === parseInt(this.state.fine_lucro)
      );
    } catch (e) {
      return [];
    }
  }

  getDescrizioni() {
    try {
      return this.props.GlobalStore.stubs.descrizione_forma_giuridica.filter(
        (el) => parseInt(el.id_divisione) === parseInt(this.state.divisione)
      );
    } catch (e) {
      return [];
    }
  }

  selectedTipologia(value) {
    if (this.state.fine_lucro !== value) {
      this.formRef.props.form.setFields({
        divisione: {
          value: [],
        },
        id_descrizione_forma_giuridica: {
          value: [],
        },
      });
    }
    this.setState({ fine_lucro: value });
  }

  selectedDivisione(value) {
    console.warn(
      "selectedDivisione this.state.divisione",
      this.state.divisione
    );
    console.warn("selectedDivisione value", value);

    if (this.state.divisione !== value) {
      this.formRef.props.form.setFields({
        id_descrizione_forma_giuridica: {
          value: [],
        },
      });
    }

    //Task#380145
    let divisione_forma_giuridica_2_6 = false;
    if (value == 10) {
      divisione_forma_giuridica_2_6 = true;
    }

    this.setState({
      divisione: value,
      divisione_forma_giuridica_2_6: divisione_forma_giuridica_2_6,
    });
  }

  selectedDescrizione(value) {
    this.props.onChangeLucro({
      ente: {
        ...this.props.accreditamento.ente,
        id_descrizione_forma_giuridica: value,
      },
    });
  }

  getYears() {
    let now = parseInt(moment().format("YYYY"));
    let y = [];
    for (let n = 0; n <= 100; n++) {
      y.push({
        key: "anno_" + (now - n),
        value: "" + (now - n),
        label: "" + (now - n),
      });
    }

    return y;
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          handleVisura={(key, e) =>
            this.setState({
              visura_camerale_show:
                parseInt(e.target.value) === 1 ? true : false,
            })
          }
          values={this.getValues()}
          readOnly={this.props.readOnly}
          tipi_ente={[
            {
              key: "lucro_ente_" + enteStatic.lucro.no,
              label: "Ente senza fine di lucro",
              value: enteStatic.lucro.no,
            },
            {
              key: "lucro_ente_" + enteStatic.lucro.si,
              label: "Ente con fine di lucro",
              value: enteStatic.lucro.si,
            },
          ]}
          divisioni={this.getDivisioni()}
          descrizioni={this.getDescrizioni()}
          setTipologia={(value) => this.selectedTipologia(value)}
          setDivisione={(value) => this.selectedDivisione(value)}
          setDescrizione={(value) => this.selectedDescrizione(value)}
          onlyPastDate={(rule, value, cb) => {
            if (moment() > value) {
              cb();
            } else {
              cb("Data non valida");
            }
          }}
          requiredCf={(rule, value, cb) => {
            let piva = this.formRef.props.form.getFieldsValue()["partita_iva"];
            if ((value === "" || !value) && (piva === "" || !piva))
              cb("Codice fiscale o partita iva obbligatorio");
            else cb();
          }}
          requiredPiva={(rule, value, cb) => {
            /*
            cfente
            partita_iva
             */
            let cf = this.formRef.props.form.getFieldsValue()["cfente"];
            if ((value === "" || !value) && (cf === "" || !cf))
              cb("Codice fiscale o partita iva obbligatorio");
            else cb();
          }}
        />
        {
          this.state.visura_camerale_show == 1 ?
            
            <Documenti
              canDelete={!this.props.readOnly}
              canAddNote={false}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.accreditamento.ente.media}
              unique={true}
              current_domain="ente"
              current_subdomain="documenti_ente"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'documenti_ente')}
              ref_id={this.props.accreditamento.ente.id}
              readOnly={this.props.readOnly}
              accreditation_id={this.props.accreditamento.id}
            />
            : false
        }
      </div>
    );
  }
}

export default DatiEnteForm;
