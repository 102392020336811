import React, { Component } from 'react';
import { Button, Card, Modal, Popconfirm, Tabs } from 'antd';
import FileSaver from 'file-saver';
import { connect } from "react-redux";
import DocSpan from '@common/components/DocSpan';
import CommonForm from '@common/components/form';
import checkPermission from '@common/utils/check/permissions';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import auditStatic from '@common/utils/static/audit';
import AssegnazioneServices from '@accreditamento/services/Assegnazione';
import ModelServices from '@accreditamento/services/Complete';
import AuditList from '../audit/ListAudit';
import ComunicazioneOstativaList from '../comunicazione-ostativa/ListComunicazioneOstativa';
import Ispezione from '../ispezione/Ispezione';
import SceltaIspezione from '../ispezione/SceltaIspezione';
import SceltaSedi from '../ispezione/SceltaSedi';
import Esito from './Esito';
import accreditamento from '@common/utils/static/accreditamento';


const TabPane = Tabs.TabPane;

const getStato = (record, component) => {
  let stato = 'ente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';
  return record.stato_flusso_formatted['stato_'+stato];
}

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica file p7m o pdf firmato",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

const formSelLayout = (component) => {
  return {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: component.tipo === accreditamentoStatic.tipo.variazione ? [
                {
                  type: "required",
                  error_message: "Campo obbligatorio",
                },
              ] : [],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};
};



class ActionsAccreditamento extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },

    loading: false,
    confirming: false,
    downloading: false,
    uploading: false,
    sending: false,
    taking_assignment: false,

    fileList: [],
    note: '',
    open_modal_doc: false
  }

  handleEnableMacrotipologie = (data) =>{
    this.props.handleEnableMacrotipologie(data)
  }

  handleReloadPanels = (data) =>{
    this.props.handleReloadPanels(data)
  }

  componentDidMount() {
    if (this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id &&
      newProps.accreditamento !== this.props.accreditamento) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, loaded: true });
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "accreditamento");

    //this.props.refresh();
  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload() {
    if(!this.state.loading) {

      this.setState({
        loading: true,
        uploading: true
      }, () => {
      //console.log('ff', this.state.file)
        ModelServices.upload(this.state.accreditamento.id, {
          filename: 'file',
          note: this.state.note || "",
          valido_dal: this.state.valido_dal || "",
          valido_al: this.state.valido_al || "",
        }, { ...this.state.file, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.endLoading()
          this.setState({ open_modal_doc: false });

        }, () => this.endLoading() )
      })

    }
  }

  async uploadFile(file) {
    await this.setState({ file: { file: file, name: file.name }, open_modal_doc: true });

    return;

  }

  renderRequestFileFirmato() {
    return <div style={{ marginTop: 18 }}>
      {
        this.state.accreditamento.media.filter(el => el.tipo.sottodominio === accreditamentoStatic.tipo_media.sottodominio_richiesta_firmata).map(l => {
          return <div key={'acc_' + l.id}>
            <p style={{ display: 'inline-block' }}>Richiesta firmata: </p>
            <DocSpan key={l.id} file={l} />
            {l.note && l.note.map(n => {
              return <div key={"nota_" + n.id}><small><strong>{n.user ? n.user.anagrafica?.nome + ' ' + n.user.anagrafica?.cognome : " - "}:</strong> {n.note}</small></div>
            })}
          </div>
        })
      }
    </div>
  }

  isEnte() {
    return (this.props.accreditamento.id_user === this.props.UserStore.user.id) ? true : false;
  }

  canConfirm() {
    return this.props.accreditamento.stato === accreditamentoStatic.stato.lavorazione
      ? true : false
  }

  canDownloadTemplate() {
    return this.props.accreditamento.stato === accreditamentoStatic.stato.compilata /*||
      this.props.accreditamento.stato == accreditamentoStatic.stato.da_firmare*/
      ? true : false
  }

  canUpload() {
    return this.props.accreditamento.stato === accreditamentoStatic.stato.compilata
      ? true : false
  }

  canSend() {
    return this.props.accreditamento.stato === accreditamentoStatic.stato.pronta
      ? true : false
  }

  /**
   * resetta gli stati di invio
   * @return {[type]} [description]
   */
  endLoading() {
    this.setState({
      loading: false,
      confirming: false,
      downloading: false,
      uploading: false,
      sending: false
    })
  }

  /**
   * Azioni specifiche dell'ente
   * @return {[type]} [description]
   */
  renderEnteActions() {
    if (!this.isEnte()) return null;
    return <div>
      {
        (this.canConfirm() && (this.props.progress.progress >= 100))
          ?
          <div>
            <p>Se hai completato la lavorazione della richiesta procedi con la conferma</p>
            <Popconfirm placement="top" title="Confermi i dati inseriti?"
              onConfirm={() => {

                if(!this.state.loading) {
                  this.setState({
                    confirming: true,
                    loading: true
                  }, () =>
                    ModelServices.confirm(
                      this.state.accreditamento.id,
                      (res) => {

                        this.props.refreshInline(res.data.data);
                        this.endLoading();
                      },
                      () => {
                        this.endLoading();
                      })
                  )
                }
              }}
              okText="Si" cancelText="No">
              <Button
              disabled={this.state.loading}
              loading={this.state.confirming}
              className="m5h" type="primary" icon="check">Conferma lavorazione</Button>
            </Popconfirm>
          </div>
          : null
      }
      {/*
        (this.canDownloadTemplate())
          ?
          <div>
            <p>Scarica il file di riepilogo da firmare per l'inoltro all'amministrazione</p>
            <Button
            disabled={this.state.loading}
            loading={this.state.downloading}
            className="m5h" type="primary" icon="download" onClick={
              () => {
                if(!this.state.loading) {
                  this.setState({
                    downloading: true,
                    loading: true
                  }, () =>
                    ModelServices.downloadFiles(
                      this.state.accreditamento.id,
                      (res) => {
                        this.saveBlob(res.data)
                        this.props.refreshInline({
                          stato: accreditamentoStatic.stato.da_firmare
                        });
                        this.endLoading();
                      },
                      (err) => {
                        this.endLoading();
                      })
                  )
                }
            }}>Scarica riepilogo</Button>
          </div>
          : null
      */}
      {
        (this.canUpload())
          ?
          <div>
            <p>Scarica il riepilogo, firmalo digitalmente e carica il file contenente la firma</p>
            <CommonForm
              wrappedComponentRef={inst => (this.formRef = inst)}
              loading={this.state.loading || false}
              form_model={formLayout}
              values={{
              }}
              fileListProp={() => []}
              customUploadRequest={obj => {
                this.uploadFile(obj.file);
              }}
            />
            <Modal
              title={this.props.accreditamento.tipo === accreditamentoStatic.tipo.variazione ? 'Oggetto della variazione' : 'Inserisci note facoltative '}
              confirmLoading ={this.state.uploading}
              visible={this.state.open_modal_doc}
              onOk={async () => {

                this.setState({
                  filename: 'file',
                  valido_dal: this.formSelRef.props.form.getFieldsValue()['valido_dal'] || "",
                  valido_al: this.formSelRef.props.form.getFieldsValue()['valido_al'] || "",
                  note: this.formSelRef.props.form.getFieldsValue()['note'] || ""
                }, () => this.upload())
              }}
              onCancel={() => this.setState({ open_modal_doc: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formSelRef = inst)}
                loading={this.state.loading || false}
                form_model={formSelLayout(this.props.accreditamento)}
                values={{

                }}

              />
            </Modal>
          </div>
          : null
      }
      {
        (this.canSend())
          ?
          <div>
            <Popconfirm placement="top" title="Conferma l’invio della richiesta"
              onConfirm={() => {
                if(!this.state.loading) {
                  this.setState({
                    sending: true,
                    loading: true
                  }, () =>
                    ModelServices.send(
                      this.state.accreditamento.id,
                      (res) => {
                        this.props.refreshInline(res.data.data)
                        this.endLoading();
                      },
                      () => this.endLoading()
                    )
                  )
                }
              }}
              okText="Si" cancelText="No">
              <Button
              loading={this.state.sending}
              disabled={this.state.loading}
              className="m5h" type="primary" icon="mail">Invia all&apos;amministrazione</Button>
            </Popconfirm>
          </div>
          : null
      }
    </div>
  }


  /**
   * Azioni specifiche dell'amministrazione
   * @return {[type]} [description]
   */
  renderAdminActions() {
    return null
    /*
    return <div>
      {
        (this.state.accreditamento.stato == accreditamentoStatic.stato.inviata && this.state.loaded == true)
          ?
          (
            ModelServices.acquire(
              this.state.accreditamento.id,
              (res) => { this.props.refreshInline(res.data.data) },
              (err) => console.log(err)
            ),
            this.setState({ loaded: false })
          )
          : null
      }
    </div>
    */
  }

  /**
   * Azioni specifiche dell'istruttore
   * @return {[type]} [description]
   */
  renderIstruttoreActions() {
    return <div>
      {
        (this.state.accreditamento.stato === accreditamentoStatic.stato.acquisita
          && this.state.accreditamento.stato_interno === accreditamentoStatic.stato_interno.assegnata
          && !this.state.taking_assignment)
          ?
            (
              AssegnazioneServices.take(
                this.state.accreditamento.id,
                (res) => { this.props.refreshInline({ stato: accreditamentoStatic.stato.in_istruttoria_documentale }) }
              ),
              this.setState({ taking_assignment: true })
            )
          : null
      }
      {
        (this.state.accreditamento.stato === accreditamentoStatic.stato.integrazione_inviata && this.state.accreditamento.stato_interno === accreditamentoStatic.stato_interno.sospesa_in_attesa_integrazioni && this.state.loaded)
        ?
          (
            ModelServices.changeStato(
              this.state.accreditamento.id,
              { stato: accreditamentoStatic.stato.in_istruttoria_documentale,
              stato_interno: accreditamentoStatic.stato_interno.in_istruttoria_documentale },
              (res) => {
                this.props.refreshInline(
                  {
                    stato: accreditamentoStatic.stato.in_istruttoria_documentale,
                    stato_interno: accreditamentoStatic.stato_interno.in_istruttoria_documentale
                  }
                )
              }
            ),
            this.setState({ loaded: false })
          )
        : null
      }
    </div>

  }

  getDefaultActiveKey() {
    if(
      this.props.accreditamento.stato_flusso === 7 ||
      this.props.accreditamento.stato_flusso === 8 ||
      this.props.accreditamento.stato_flusso === 9 ||
      this.props.accreditamento.stato_flusso === 16 ||
      this.props.accreditamento.stato_flusso === 17 ||
      this.props.accreditamento.stato_flusso === 18
      ) return "3";

    if(
      this.props.accreditamento.stato_flusso === 20 ||
      this.props.accreditamento.stato_flusso === 21
      ) return "2";

    return "1";
  }

  render() {

    let auditTabVisible = (this.state.accreditamento.audit && this.state.accreditamento.audit.filter(a => a.level === auditStatic.level.istruttore).length > 0) || checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']);

    return (this.state.loaded) ?
      <div>
        <Card
          className="no-pad"
          bordered={false}
        >
          <p style={{marginBottom: 0}}>Stato: <strong>{
            getStato(this.state.accreditamento, this)
            /*accreditamentoStatic.getStatoLabel(this.state.accreditamento)*/
          }</strong></p>
          {/*<Alert message={'Stato della domanda: ' + accreditamentoStatic.getStatoLabel(this.state.accreditamento)} type="success" className="m10h" />*/}
          {/* <Tag color="purple">{accreditamentoStatic.getStatoLabel(this.state.accreditamento)}</Tag> */}
          {/*checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'istruttorePermissions', 'ispettorePermissions', 'adminPermissions']) &&
            accreditamentoStatic.getStatoInternoLabel(this.state.accreditamento) != '' ?
            <Alert message={'Stato interno: ' + accreditamentoStatic.getStatoInternoLabel(this.state.accreditamento)} type="info" />
            <p><strong className="color-orange">{accreditamentoStatic.getStatoInternoLabel(this.state.accreditamento)}</strong></p>
            // <Tag color="orange">{accreditamentoStatic.getStatoInternoLabel(this.state.accreditamento)}</Tag>
            : null*/}
          {checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'istruttorePermissions', 'entePermissions', 'adminPermissions']) ? this.renderRequestFileFirmato() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions', 'adminPermissions']) ? this.renderEnteActions() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ? this.renderAdminActions() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) ? this.renderIstruttoreActions() : null}
        </Card>
        <React.Fragment>
          <Esito
          refreshInline={(data) => this.props.refreshInline(data)}
          refresh={() => this.props.refresh()}
          accreditamento={this.state.accreditamento} 
          handleEnableMacrotipologie = {this.handleEnableMacrotipologie}
          handleReloadPanels = {this.handleReloadPanels}
          />
        </React.Fragment>
        <React.Fragment>
          <SceltaIspezione
            refreshInline={(data) => this.props.refreshInline(data)}
            refresh={() => this.props.refresh()}
            accreditamento={this.state.accreditamento}
          />
          {
            auditTabVisible || this.state.accreditamento.comunicazioneOstativa || this.state.accreditamento.need_ispezione === 1 ?
          <Tabs defaultActiveKey={this.getDefaultActiveKey()} size="medium">
            {
              auditTabVisible ?
              <TabPane tab={
                <span>Audit</span>
              } key="1">
                <AuditList
                  level={auditStatic.level.istruttore}
                  refreshInline={(data) => this.props.refreshInline(data)}
                  refresh={() => this.props.refresh()}
                  accreditamento={this.state.accreditamento}
                  progress={this.props.progress}
                />
              </TabPane>
              : null }
            {
              this.state.accreditamento.comunicazioneOstativa
              ?
              <TabPane tab={
                <span>Comunicazione ostativa</span>
              } key="2">
                <ComunicazioneOstativaList
                refreshInline={(data) => this.props.refreshInline(data)}
                refresh={() => this.props.refresh()}
                accreditamento={this.state.accreditamento} />
              </TabPane>
              : null
            }
            {
              this.state.accreditamento.need_ispezione === 1
              ?
              <TabPane tab={
                <span>Ispezione</span>
              } key="3">

                  <SceltaSedi
                  refreshInline={(data) => this.props.refreshInline(data)}
                  refresh={() => this.props.refresh()}
                  accreditamento={this.state.accreditamento}
                    />

                  <Ispezione
                  refreshInline={(data) => this.props.refreshInline(data)}
                  refresh={() => this.props.refresh()}
                  accreditamento={this.state.accreditamento}
                    />

              </TabPane>
              : null}
          </Tabs>
          : null}

      </React.Fragment>

      </div>
      : null
  }
}


export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ActionsAccreditamento);