import React, {Component} from 'react';
import {connect} from "react-redux";

import CommonForm from '@common/components/form';
import Documenti from '@common/components/Documenti';

import checkPermission from '@common/utils/check/permissions';

import { Button } from 'antd';

import FileSaver from 'file-saver';
import MediaServices from '../../../../common/services/media/Media';

const formLayout = (component) => {
  const dirigentePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions']);
  const istruttorePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions']);
  const entePermissions = checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']);
  
  let mediasAttivazione = [];
  if( component.props.values !== undefined && component.props.values.id_media_attivazione ) {
    mediasAttivazione = [component.props.values.id_media_attivazione];
  } else {
    mediasAttivazione = component.props.values && component.props.values.medias || [];
  }

  let mediasControdeduzione = [];
  if( component.props.values !== undefined && component.props.values.mediaControdeduzioni ) {
    mediasControdeduzione = [component.props.values.mediaControdeduzioni];
  } else {
    mediasControdeduzione = component.props.values && component.props.values.medias || [];
  }

  console.warn('component.props.values', component.props.values)
  console.warn('mediasAttivazione', mediasAttivazione)
  console.warn('mediasControdeduzione', mediasControdeduzione)

  let max_termine_presentazione_controdeduzioni = 0;

  switch(component.props.provvedimentoType){
    case 'COMUNICAZIONE_SOSPENSIONE':
      max_termine_presentazione_controdeduzioni = 45;
        break;
    case 'PROVVEDIMENTO_SOSPENSIONE':
      max_termine_presentazione_controdeduzioni = 180;
        break;
    case 'COMUNICAZIONE_REVOCA':
      max_termine_presentazione_controdeduzioni = 10;
        break;
    case 'PROVVEDIMENTO_REVOCA':
      max_termine_presentazione_controdeduzioni = 0;
        break;
  }

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: component.props.provvedimentoType == 'PROVVEDIMENTO_REVOCA' ? [] : [
              {
                type: 'input',
                label: entePermissions ? 'Termine presentazione controdeduzioni' : 'Termine presentazione controdeduzioni (massimo ' + max_termine_presentazione_controdeduzioni + ' giorni)',
                type_string: 'text', // field type
                name: 'termine_presentazione_controdeduzioni',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions
                ? []
                : 
                [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    pattern: '^[1-9]{1}[0-9]*$',
                    message: 'Campo non valido'
                  },
                  {
                    validator: 'range'
                  },                    
                ],
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: 
            [
              {
                type: 'textarea',
                label: 'Campo motivazioni',
                type_string: 'text', // field type
                name: 'note_provvedimento',
                props: {
                  size: 'large',
                  readOnly: entePermissions ? true : component.props.readOnly
                },
                validations: entePermissions ?
                [] : 
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: entePermissions || (dirigentePermissions && component.props.values !== undefined && component.props.values.controdeduzioni && component.props.values.controdeduzioni.note !== undefined && component.props.values.controdeduzioni.note !== null && component.props.values.controdeduzioni.note !== '') ?
            [
              {
                type: 'textarea',
                label: 'Note sulle controdeduzioni',
                type_string: 'text', // field type
                name: 'controdeduzioni.note',
                props: {
                  size: 'large',
                  readOnly: component.props.readOnly
                },
                validations:
                [
                  { type: 'required', error_message: 'Campo obbligatorio' }
                ]
              }
            ]
            : []
          }
        ]
      },


      {
        cols: [
          {
            items: component.props.provvedimentoType == 'PROVVEDIMENTO_REVOCA' ? [
              {
                type: 'select',
                label: 'Motivo della revoca',
                name: 'motivo_revoca',
                options: [
                  /*
                  {
                    key: 0,
                    value: null,
                    label: "Seleziona motivazione"
                  },
                  */
                  ...component.props.GlobalStore.stubs.motivo_revoca.map(t=>{
                    return {
                      ...t,
                      value: "" + t.value
                    }
                  })
                ],
                /*
                onChange: 'selectedLegaleProvincia',                
                validations: [
                    {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                    }
                ],
                */
                props: {
                    readOnly: entePermissions ? true : component.props.readOnly                    
                    /*
                    disabled: entePermissions ? true : component.props.readOnly,
                    showSearch: true,
                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    */
                },
            }
            ] : []
          }
        ]
      },

      {
        cols: [
          {
            items: dirigentePermissions ? 
            [
              {
                name: 'child',
                type: 'child',
                child: <Documenti
                  canDelete={true}
                  canAddNote={false}
                  tempFile={true}
                  unique={true}
                  media_types={component.props.GlobalStore.stubs.tipo_media}
                  medias={mediasAttivazione}
                  //medias={component.props.values !== undefined && component.props.values.id_media_attivazione !== undefined ? [component.props.values.id_media_attivazione] : []}
                  //medias={component.props.values && component.props.values.medias || []}
                  current_domain="provvedimento"
                  current_subdomain="sospensione_attivazione"
                  required_types={component.props.GlobalStore.stubs.tipo_media
                    .filter(el => el.sottodominio === 'sospensione_attivazione' && el.dominio === 'provvedimento')
                    .map(e => e.tipo_media)
                  }
                  ref_id={component.props.id_ente}
                  onUpload={(f) => component.updateMedias(f)}
                  onDelete={(id)=>component.deleteMedia(id)}
                  readOnly={entePermissions ? true : component.props.readOnly}
                  descriptionSection={{lg:22}}
                  iconSection={{lg:2}}
                  uploadSection={{lg:24}}
                  hideModalNoteFacoltative={true}
                />
                }
              ] : []
            }
          ]
        },

        {
          cols: [
            {
              items: component.props.provvedimentoType != 'PROVVEDIMENTO_REVOCA' && dirigentePermissions && mediasControdeduzione.length != 0 && mediasControdeduzione[0] !== undefined && mediasControdeduzione[0].tipo.sottodominio == 'sospensione_attivazione_controdeduzioni'? 
              [
                {
                  name: 'download',
                  type: 'child',
                  child: <div>
                  <h4 class="text-danger">Documento di controdeduzione</h4>
                  <Button
                    loading={component.props.downloading || false}
                    disabled={component.props.downloading || component.props.uploading || false}
                    icon="download" onClick={
                      () => {

                        /*
                        //Scarichiamo il file della controdeduzione
                        EnteServices.getMediasControdeduzione({provvedimentoType: component.props.provvedimentoType}, component.props.values.id_ente, component.props.values.id, (res) => {
                          //console.warn('getMediasControdeduzione res', res);
                          //console.warn('mediasControdeduzione[0]', mediasControdeduzione[0]);                          
                          const blob = new Blob([res.data], { type: mediasControdeduzione[0].mime_type });
                          //var fileURL = window.URL.createObjectURL(blob);
                          //window.open(fileURL, '_blank');
                          FileSaver.saveAs(blob, mediasControdeduzione[0].nome_file);
                        }, (err) => {
                            console.error("EnteServices.getMediasControdeduzione", err)
                        });  
                        */     
                        MediaServices.download(mediasControdeduzione[0].id, async (res) => {
                          const blob = new Blob([res.data], { type: mediasControdeduzione[0].mime_type });
                          FileSaver.saveAs(blob, mediasControdeduzione[0].nome_file);
                        }, () => null)

                      }
                    }
                  ><span>{mediasControdeduzione[0].nome_file}</span></Button>                  
                  <br/><br/>
                  </div>
                }                  
              ] 
              : []  
            }          
          ]
        },
      
      {
        cols: [
          {
            items: entePermissions && mediasAttivazione.length != 0 && mediasAttivazione[0] !== undefined ? 
            [
              {
                name: 'download',
                type: 'child',
                child: <div>
                <h4 class="text-danger">Documento di attivazione</h4>
                <Button
                  loading={component.props.downloading || false}
                  disabled={component.props.downloading || component.props.uploading || false}
                  icon="download" onClick={
                    () => {

                      /*
                      //Scarichiamo il file della attivazione
                      EnteServices.getMediasAttivazione({provvedimentoType: component.props.provvedimentoType}, component.props.values.id_ente, component.props.values.id, (res) => {                        
                        console.warn('getMediasAttivazione res', res);
                        console.warn('mediasAttivazione[0]', mediasAttivazione[0]);
                        const blob = new Blob([res.data], { type: mediasAttivazione[0].mime_type });
                        //var fileURL = window.URL.createObjectURL(blob);
                        //window.open(fileURL, '_blank');
                        FileSaver.saveAs(blob, mediasAttivazione[0].nome_file);
                      }, (err) => {
                          console.error("EnteServices.getMediasAttivazione", err)
                      });  
                      */     
                      MediaServices.download(mediasAttivazione[0].id, async (res) => {
                        const blob = new Blob([res.data], { type: mediasAttivazione[0].mime_type });
                        FileSaver.saveAs(blob, mediasAttivazione[0].nome_file);
                      }, () => null)

                    }
                  }
                ><span>{mediasAttivazione[0].nome_file}</span></Button>                  
                <br/><br/>
                </div>
              }                  
            ] : []
          }
        ]
      },

      {
        cols: [
          {
            items: entePermissions && component.props.provvedimentoType != 'PROVVEDIMENTO_REVOCA' ? 
              [
                {
                  name: 'child',
                  type: 'child',
                  child: <Documenti
                    canDelete={true}
                    canAddNote={false}
                    tempFile={true}
                    unique={true}
                    media_types={component.props.GlobalStore.stubs.tipo_media}
                    medias={mediasControdeduzione}
                    //medias={component.props.values !== undefined && component.props.values.id_media_controdeduzioni !== undefined ? [component.props.values.id_media_controdeduzioni] : []}
                    //medias={component.props.values && component.props.values.medias || []}
                    current_domain="provvedimento"
                    current_subdomain="sospensione_attivazione_controdeduzioni"
                    required_types={component.props.GlobalStore.stubs.tipo_media
                      .filter(el => el.sottodominio === 'sospensione_attivazione_controdeduzioni' && el.dominio === 'provvedimento')
                      .map(e => e.tipo_media)
                    }
                    ref_id={component.props.id_ente}
                    onUpload={(f) => component.updateMedias(f)}
                    onDelete={(id)=>component.deleteMedia(id)}
                    readOnly={entePermissions ? component.props.readOnly : true}
                    descriptionSection={{lg:22}}
                    iconSection={{lg:2}}
                    uploadSection={{lg:24}}
                    hideModalNoteFacoltative={true}
                  />
                }
              ] 
              : [],
          },
        ],
      },   
            
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit_popconfirm',
                text: 'Conferma',
                btn_type: 'primary',
                Popconfirm:{
                  placement: 'leftTop',
                  title: "Confermi l'azione?",
                  okText: 'Sì',
                  cancelText: 'No'
                }
              }
            ]
          }
        ]
      },
    ],
    onSubmit: 'submitted',
  };
};


class ProvvedimentoForm extends Component {

  state = {
    loading: false,
    medias: [],
  };

  confirm(data) {
    this.props.submit(data);
  }

  getValues() {
    return this.props.values || {};
  }

  updateMedias(media) {
    let just = localStorage.getItem('document_form_provvedimento');
    if(just) {
      just = JSON.parse(just)
    } else {
      just = { medias: [] }
    }

    just.medias = [...just.medias, media];
    localStorage.setItem('document_form_provvedimento', JSON.stringify(just));

    if (this.props.onUpload) this.props.onUpload(media)
    this.setState({ medias: [...this.state.medias, media] })
  }

  /*
  async validate() {
    return new Promise((resolve, reject) => {

        this.formRef.props.form.validateFields((errors, values) => {

            if (this.state.medias.filter(e => e.tipo.sottodominio === 'sospensione_attivazione' && e.tipo.dominio === 'provvedimento' && e.deleted !== 1).length === 0) {
              message.error('Inserire documentio');
              resolve(false)
            } else {
              resolve(true);
            }

        });
    });
  }
  */

  deleteMedia(id) {
    try{
      if (this.props.onDeleteMedia) this.props.onDeleteMedia(id)
    } catch(e) {
      console.error(e)
    }

  }

  render () {
    console.warn("TP.1 - ", this.props.provvedimentoType)

    let max_termine_presentazione_controdeduzioni = 0;

    switch(this.props.provvedimentoType){
      case 'COMUNICAZIONE_SOSPENSIONE':
        max_termine_presentazione_controdeduzioni = 45;
          break;
      case 'PROVVEDIMENTO_SOSPENSIONE':
        max_termine_presentazione_controdeduzioni = 180;
          break;
      case 'COMUNICAZIONE_REVOCA':
        max_termine_presentazione_controdeduzioni = 10;
          break;
      case 'PROVVEDIMENTO_REVOCA':
        max_termine_presentazione_controdeduzioni = 0;
          break;
    }   

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          provvedimentoType={this.props.provvedimentoType}
          readOnly={this.props.readOnly}
          submitted={(dati) => this.confirm(dati)}
          range={(rule, value, cb) => {
            if(value > max_termine_presentazione_controdeduzioni) {
              cb("Il termine presentazione controdeduzioni non può andare oltre i " + max_termine_presentazione_controdeduzioni + " giorni");
            } else {
              cb();
            }
          }}          
        />
      </div>
    );
  }
}

export default connect(({UserStore, GlobalStore})=>({UserStore, GlobalStore}), null, null, { withRef: true })(ProvvedimentoForm);