import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
//import CheckPermissions from '@common/decorators/CheckPermissions';

import List from '../components/TabellaQualificheProfessionali';

@Wrapper({ route: 'QualificheProfessionali', hasSidebar: true, hasHeader: true, hasFooter: true, public: true })
//@CheckPermissions(['viewQualificaProfessionale'])
class QualificheProfessionaliRoute extends Component {

  state = {
    loading: false,
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          <h3 className="sectionTitle">Repertorio delle qualifiche professionali</h3>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/repertorio/qualifiche">Qualifiche Professionali</Link></Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <List ref="listTable" {...this.props}
        base_url='/repertorio/qualifiche'
        />

      </div>
    );
  }
}

export default QualificheProfessionaliRoute;
