import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';

import List from '../components/ListRinunce';


@Wrapper({ route: 'RinunceRequest', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['ViewRequest'])
class RinunceRequest extends Component {

  state = {
    loading: false,
    asked: false,
  }

  renderTitle() {
    return 'Richieste di rinuncia';
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow"
        >
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          {!checkPermissions.hasPermission( this.props.UserStore.user, ['entePermissions']) ? <p>Gestione delle richieste di rinuncia pervenute</p> : null}
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Richieste di rinuncia</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <List ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default RinunceRequest;
