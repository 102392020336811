import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Nomina';

import moment from 'moment';

import FileSaver from 'file-saver';

import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';
import nominaStatic from '@common/utils/static/nomina';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';


const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica file p7m o pdf firmato",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};







class VerbaleControdeduzioni extends Component {

  state = {
    loaded: false,
    open_modal_com: false,
    accreditamento: {
      id: null
    },
    comunicazione: {
      id: null
    }
  }

  componentDidMount() {
    if(this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if(newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento)) this.loaded(newProps)
  }

  loaded( props ) {
    this.setState({accreditamento: props.accreditamento, loaded: true});
  }




  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload() {

    if(!this.state.loading) {
      this.setState({loading: true}, () => {
        ModelServices.uploadControdeduzioni(this.props.accreditamento.id, this.props.nomina.id, {
          filename: 'file',
          note: this.state.note || ""
        }, { ...this.state.file, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_com: false, loading: false });

        }, () => this.setState({ open_modal_com: false, loading: false }))
      })
    }
  }

  async uploadFile(file) {
    await this.setState({ file: { file: file, name: file.name }, open_modal_com: true });

    return;

  }

  saveBlob( blob ) {
    const _blob = new Blob([blob], {type: 'application/pdf'});
    FileSaver.saveAs(_blob, "controdeduzioni");

    //this.props.refresh();
  }

  canView(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) && this.props.accreditamento.istruttore && this.props.accreditamento.istruttore.length > 0 && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user)
    )  ? true : false
  }

  canDownload(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user &&
      (this.props.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.in_corso)
      ) ? true : false
  }

  canUpload(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user &&
      this.props.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare
      ) ? true : false
  }

  canSend(){
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user &&
      this.props.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.firmata
      ) ? true : false
  }

  renderActions() {
    return <div>
        {this.canDownload() ?
        <div>
          <p>Scarica il documento delle controdeduzioni</p>
          <Button
          loading={this.state.loading}
          disabled={this.state.loading}
          className="m5h" type="primary" icon="file" onClick={()=> {
            if(!this.state.loading) {
              this.setState({loading: true}, () => {

                ModelServices.getControdeduzioni(
                  this.props.accreditamento.id,
                  this.props.nomina.id,
                  (res)=> {
                    this.setState({loading: false});
                    this.props.refreshInline({
                      ...this.props.accreditamento,
                      nomina: [
                        ...this.props.accreditamento.nomina.filter(n => n.id !== this.props.nomina.id),
                        {
                          ...this.props.nomina,
                          verbale: {
                            ...this.props.nomina.verbale,
                            controdeduzioni: {
                              ...this.props.nomina.verbale.controdeduzioni,
                              stato: nominaStatic.stato_controdeduzioni.da_firmare
                            }
                          }
                        }
                      ]
                    })

                    this.saveBlob(res.data);
                  },
                  () => this.setState({loading: false})
                )

              })

            }
          }}>Scarica file</Button>
        </div> : null}
        {this.canUpload() ?
        <div>
          <h4>Carica controdeduzioni firmate digitalmente</h4>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={{
            }}
            fileListProp={() => []}
            customUploadRequest={obj => {
              this.uploadFile(obj.file);
            }}
          />
          <Modal
            title="Inserisci note facoltative"
            visible={this.state.open_modal_com}
            onOk={async () => {
              this.setState({
                filename: 'file',
                note: this.formSelComIsp.props.form.getFieldsValue()['note'] || ""
              }, () => this.upload())
            }}
            onCancel={() => this.setState({ open_modal_com: false })}
          >
            <CommonForm
              wrappedComponentRef={inst => (this.formSelComIsp = inst)}
              loading={this.state.loading || false}
              form_model={formSelLayout}
              values={{

              }}
            />
          </Modal>
        </div> : null}
        {this.canSend() ?
        <div>
          <h4>Conferma</h4>
          <Popconfirm placement="top" title="Confermi la comunicazione?"
          onConfirm={() => {
            if(!this.state.loading) {
              this.setState({loading: true}, () => {
                ModelServices.sendControdeduzioni(
                  this.props.accreditamento.id,
                  this.props.nomina.id,
                  (res)=> {
                    this.setState({loading: false});
                    this.props.refreshInline(res.data.data)
                  },
                  () => this.setState({loading: false})
                )
              })
            }
          }}
          okText="Si" cancelText="No">
            <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            className="m5h" type="primary" ghost icon="check">Conferma</Button>
          </Popconfirm>
        </div>
        : null}
    </div>
  }

  getTiming() {
    return moment(this.props.nomina.verbale.data_trasmissione, 'YYYY-MM-DD').add(this.props.GlobalStore.stubs.days_controdeduzioni, 'd').format('DD/MM/YYYY');
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) &&
    this.props.accreditamento.id_user === this.props.UserStore.user.id
    ? true : false
  }

  hasControdeduzioni() {
    return this.props.nomina.verbale.controdeduzioni ? true : false
  }

  renderEnteActions() {
    return this.isEnte() && this.props.nomina.verbale.stato === nominaStatic.stato_verbale.confermato ?
      <div>
        <p>Data scadenza per invio controdeduzioni: {this.getTiming()}</p>
        {
          !this.hasControdeduzioni()
          ?
          <React.Fragment>
          <strong>Vuoi inviare delle controdeduzioni?</strong>
            <Button
            loading={this.state.loading} disabled={this.state.loading}
            className="m5h" type="primary"
            style={{marginLeft: 8}}
            onClick={()=> {
              if(!this.state.loading) {
                this.setState({loading: true}, () => {
                  ModelServices.addControdeduzioni(
                    this.state.accreditamento.id,
                    this.props.nomina.id,
                    (res)=> {
                      this.setState({loading: false})
                      this.props.refreshInline(res.data.data)
                    },
                    () => this.setState({loading: false})
                  )
                })
              }
            }}>Si</Button>
          </React.Fragment>
          : null
        }
      </div>
      : null

  }

  render() {
    return <React.Fragment>
    {(this.state.loaded && this.props.controdeduzioni && this.canView()) ?
      <div style={{marginTop: 16}}>
        <div>
          <h4>Controdeduzioni</h4>
          <p style={{marginBottom: 0}}>Scadenza: <strong>{this.getTiming()}</strong></p>
          <p>Stato: <strong>{nominaStatic.getStatoControdeduzioneLabel(this.props.controdeduzioni)}</strong></p>
          {this.props.controdeduzioni.note.map(n => <p key={"cont_"+n.id}>{n.note}</p>)}
          {
            this.props.controdeduzioni.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_controdeduzioni_firmata).map(l => {
              return <div key={l.id}><p style={{display: 'inline-block'}}>Controdeduzioni firmate: </p><DocSpan key={l.id} file={l} /></div>
            })
          }
          {this.renderActions()}
        </div>
      </div>
    : null
    }
    {this.renderEnteActions()}
    </React.Fragment>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(VerbaleControdeduzioni);
