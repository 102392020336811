import { Affix, Breadcrumb, Button, Card, Col, Drawer, Icon, Modal, Progress, Row, Tabs, Popconfirm } from "antd";
import FileSaver from 'file-saver';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Sticky, StickyContainer } from 'react-sticky';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CheckPermissions from "@common/decorators/CheckPermissions";
import eventBroker from '@common/utils/services/EventBroker';
import Wrapper from "@common/decorators/Wrapper";
import checkPermission from '@common/utils/check/permissions';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import enteStatic from '@common/utils/static/ente';
import AssegnazioneIstruttore from '../components/assegnazione/AssegnazioneIstruttore';
import Actions from '../components/complete/Actions';
import ComunicazioniPecList from '../components/comuncazioni-pec/ComunicazioniPecList.js';
import Decreto from '../components/decreto/Decreto';
import DocumentiList from '../components/documenti/DocumentiList';
import Affidabilita from '../components/scheda/Affidabilita';
import DatiForm from '../components/scheda/Dati';
import Efficacia from '../components/scheda/Efficacia';
import Infrastruttura from '../components/scheda/Infrastruttura';
import Interrelazioni from '../components/scheda/Interrelazioni';
import Qualita from '../components/scheda/Qualita';
import RisorseCapacita from '../components/scheda/RisorseCapacita';
import AccreditamentoService from '../services/Accreditamento';
//import OrganizzazioneServices from "../../organizzazione/services/Organizzazione";
import CompleteServices from "../services/Complete";


const TabPane = Tabs.TabPane;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
    )}
  </Sticky>
);

const renderDescriptionText = (record, component) => {
  if(!record.stato_flusso_formatted) return ''
  let user = 'ente';

  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';

  return record.stato_flusso_formatted['descrizione_'+user];
}

const getStato = (record, component) => {
  if(!record.stato_flusso_formatted) return ''
  let stato = 'ente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';
  return record.stato_flusso_formatted['stato_'+stato];
}

@Wrapper({
  route: "CompleteAccreditamentoRequest",
  hasSidebar: true, hasHeader: true, hasFooter: true
})
@CheckPermissions(["ispettorePermissions", "istruttorePermissions", "entePermissions", "adminPermissions", "dirigentePermissions", "HelpdeskPermissions"])
class AccreditamentoScheda extends Component {

  constructor(props){
    super(props);
    this.eventDecreto = this.eventDecreto.bind(this);
    eventBroker.on('updateDecretoPregressoInfrastruttura', this.eventDecreto);
  }

  state = {
    mounted: false,
    loading: false,
    loaded: false,
    id_ente: null,
    calculating_progress: false,
    organizzazione: {},
    stubs: {},
    loading_riepilogo: false,
    loading_documenti: false,
    loading_request: false,
    loading_rollback_state: false,
    tipo_media: [],
    tipi_organizzazione: [],
    dati: {},
    affidabilita_economica: {},
    values: {},
    progress: 0,
    checkDati: 0,
    show_peclog: false,
    show_documenti_dr: false,
    current_key: "1",
    reload: false,
    enable_macrotipologie: false,
    progress_obj: {
      progress: 0,
      required_sections: {},
      valid_sections: {},
      showed_sections: {},
      errors: {}
    }
  };


  async componentDidMount() {
    //console.log(process.env);
    await this.setState({ mounted: true });

    if (this.props.match.params.id) {
      this.loadAccreditamento(this.props);
    }
  }

  handleEnableMacrotipologie = (data) =>{
    this.setState({enable_macrotipologie: data})
  }

  handleReloadPanels = (data) =>{
    if(data){
      this.setState({current_key: "1", 
                    reload: true})
    }
  }

  isDirigente() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions']) ? true : false;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    try {
      if (this.props.match.params.id !== newProps.match.params.id) this.loadAccreditamento(newProps);
    } catch (e) {
      //console.log(e)
    }
  }

  loadAccreditamento(props) {

    AccreditamentoService.detail(
      {
        expand: [
          'comunicazioneOstativa',
          'comunicazioneOstativa.media',
          'comunicazioneOstativa.user',
          'comunicazioneOstativa.controdeduzioni',
          'comunicazioneOstativa.controdeduzioni.media',
          'media',
          'istruttore',
          'audit,audit.note',
          'audit.integrazione',
          'audit.integrazione.note',
          'audit.integrazione.media',
          'audit.note.user',
          'audit.integrazione.note.user',
          'audit.user',
          'ispezione',
          'sedi',
          'sedi.media',
          'sedi.indirizzo',
          'sedi.indirizzo.locComune',
          'variazione_diff'
        ].join(',')
      },
      props.match.params.id,
      async (res) => {

        let datas = res.data.data;
        
        // Se è un dirigente e se lo stato flusso è 36 (quello adibito alla modifica delle macrotipologie).
        this.setState({
          enable_macrotipologie: this.isDirigente() && datas.stato_flusso==36,
        });

        this.setState({
          progress_obj: res.data.data.progress,
        });

        if(
          checkPermission.hasPermission(props.UserStore.user, ['dirigentePermissions']) &&
          datas.stato === accreditamentoStatic.stato.inviata
        ) {
          
          CompleteServices.acquire(
            res.data.data.id,
            async (res2) => {
              //datas.stato = accreditamentoStatic.stato.acquisita;
              await this.setState({
                loaded: true,
                dati: {
                  ...datas,
                  ...res2.data.data
                },
              });
              //this.calculateProgress();
            },
            async (err) => {
              await this.setState({
                loaded: true,
                dati: datas,
              });
              //this.calculateProgress();
            }
          );

        } else {
          await this.setState({
            loaded: true,
            dati: datas,
          });
          //this.calculateProgress();
        }


      },
      err => null
    );
  }

  async componentWillUnmount() {
    await this.setState({ mounted: false });
  }

  calculateProgress() {
    if(!this.state.calculating_progress) {
      this.setState({calculating_progress: true});
      AccreditamentoService.progress(
        this.props.match.params.id,
        async res => {
          await this.setState({
            progress_obj: res.data.data,
            calculating_progress: false
          });
        },
        err => this.setState({calculating_progress: false})
      );
    }
  }

  loadDiff() {

    this.setState({
      diff_obj: this.state.dati.variazione_diff,
      show_diff: true
    });

    /*
    if (!this.state.loading_diff && !this.state.diff_obj) {
      this.setState({ loading_diff: true });
      AccreditamentoService.storicoDiff(
        this.props.match.params.id,
        {},
        async (res) => {
          this.setState({
            diff_obj: res.data.data,
            loading_diff: false,
            show_diff: true
          });
        },
        () => this.setState({ loading_diff: false })
      );
    } else if (this.state.diff_obj) {
      this.setState({show_diff: true});
    }
    */
  }

  flattenObject(ob, predicate = () => {}) {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (!predicate(i))
        continue;

      let skipLabel = Object.keys(ob).filter(p => predicate(p)).length <= 1;

      if ((typeof ob[i]) == 'object' && ob[i] !== null && skipLabel) {  // mettendo qui skiplabel nel caso di piu oggetti figli stampa solo l'etichetta di ogni figlio
        var flatObject = this.flattenObject(ob[i], predicate);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[skipLabel ? x : i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderDiff() {

    let filter = i => i !== 'accreditamento' && i !== 'id' && !i.startsWith('id_') && !i.endsWith('_at');
    let filterEnte = i => i !== 'ente' && filter(i);

    return <div style={{ textAlign: 'center' }}>
      <Button
        size="small"
        icon="reload"
        type="primary"
        style={{ marginLeft: 8 }}
        ghost
        loading={this.state.loading_diff}
        onClick={() => this.loadDiff()}>Mostra differenze</Button>
      <Modal
        visible={this.state.show_diff}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={500}
        destroyOnClose={false}
        onOk={() => this.setState({ show_diff: false })}
        onCancel={() => this.setState({ show_diff: false })}
      >
        {
          this.state.diff_obj && Object.keys(this.state.diff_obj).length > 0 ?
            <div>
              <h3>Variazioni riscontrate</h3>
              {
                Object.keys(this.state.diff_obj).map(e => {
                  return this.sectiontMappingGetName(e) ?
                    <div key={"section_" + e}>
                      <h4>{this.sectiontMappingGetName(e)} </h4>
                      <ul>
                        {
                          Object.keys(this.flattenObject(this.state.diff_obj[e], e === 'dati_identificativi' ? filter : filterEnte))
                            //.filter(d => d !== 'ente' && d !== 'accreditamento' && d !== 'id' && !d.startsWith('id_') && !d.endsWith('_at'))
                            .map(d => <li>{this.capitalizeFirstLetter(d.replace(/_/g, ' '))}</li>)
                        }
                      </ul>
                    </div>
                    : null;
                })
              }
            </div>
            : <h3>Nessuna varizione</h3>
        }
      </Modal>
  </div>
  }


  update(obj) {
    //this.setState({ organizzazione: { ...this.state.organizzazione, ...obj } });
  }

  refresh(reset = true) {
    //console.log('REFRESH', this.props);
    if(reset) {
      this.setState({
        loaded: false,
      })
    }
    this.loadAccreditamento(this.props);
  }

  eventDecreto(){
    this.refresh(false);

  }

  canEdit() {
    return true
  }

  submit() {
    this.loadAccreditamento(this.props);
  }


  refreshInline(data) {

    this.setState({
      dati: {...this.state.dati, ...data }
    }, () => {
        this.calculateProgress();
        //console.log('dati attuali', this.state.dati)
      }
    );
  }

  editableState(schedaSelected = null) {
    console.warn("Scheda editableState", this.state.dati.stato);
    //checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])

    let editableStates = [0,1];
    /**
     * L'ente, in caso di "richiesta integrazione", deve poter modificare tutte le schede
    */
    if(checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])){
      if(schedaSelected !== null){
        const accreditamento = this.state.dati;
        console.warn("accreditamento.istruttore_sections_feedback", accreditamento.istruttore_sections_feedback);
        let editable = false;
        if(accreditamento && accreditamento.istruttore_sections_feedback){
          for (const [nomeScheda, feedback] of Object.entries(accreditamento.istruttore_sections_feedback)) {
              if(feedback?.valid === false){
                editable = true;
              }
          }
          if(editable){
            editableStates.push(13);
          }
        }
      }
    }

    let editable = editableStates.indexOf(this.state.dati.stato) !== -1 ? true : false;

    return editable;
  }

  /*
  Quando l'ente è in stato di integrazione documentale, i form che si presentano
  non devono permettere di caricare i documenti. Quindi Anche se il form è abilitato, i 
  documenti non devono essere caricati. 
  Questa funzione si occupa di dire se i documenti sono caricabili o meno, a partire 
  dalla premessa iniziale.
  */
  editableStateDocumenti(schedaSelected = null) {
    console.warn("Scheda editableStateDocumenti", this.state.dati.stato);

    return this.state.dati.stato === 13 ? false : true;
  }

  /**
   * Effettuare controllo sulla forma giuridica 2.6 "Istituto, scuola e università pubblica" e non rendere obbligatoria la compilazione delle sezioni di Affidabilità economica e finanziaria e Capacità gestionali e Risorse professionali
   */

   isUniversitaFormaGiuridica(){
     try{
       return this.getDivisione(this.props).codice === "2.6" ? true : false
     } catch(e) {
       return false;
     }
   }

   isFineLucro() {
     try {
      return this.getDivisione(this.props).fine_lucro === enteStatic.lucro.si ? true : false
    } catch(e) {
      return 1
    }
   }

   getDivisione(props) {
    try {
      return props.GlobalStore.stubs.divisione_forma_giuridica.find(d => parseInt(d.value) === parseInt(
          props.GlobalStore.stubs.descrizione_forma_giuridica.find(
            e => parseInt(e.value) === parseInt(this.state.dati.ente.id_descrizione_forma_giuridica)
        ).id_divisione)
      )
    } catch(e) {
      return {}
    }
  }

  requiredCapacita() {
    if(this.isUniversitaFormaGiuridica()) return false;

    return true;
  }

  requiredAffidabilita() {
    if(this.isUniversitaFormaGiuridica()) return false;

    return true;
  }

  requiredRisorse() {
    if(this.isUniversitaFormaGiuridica()) return false;

    return true;
  }

  requiredQualita() {
    try {
      if( this.state.dati.tipo === accreditamentoStatic.tipo.provvisorio ) return false
    } catch(e) {
      return false;
    }

    return true;
  }

  requiredEfficacia() {
    try {
      if( this.state.dati.tipo === accreditamentoStatic.tipo.provvisorio ) return false
    } catch(e) {
      return false;
    }
    return true;
  }

  requiredInterrelazioni() {
    try {
      if( this.state.dati.tipo === accreditamentoStatic.tipo.provvisorio ) return false
    } catch(e) {
      return false;
    }
    return true;
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  sectiontMappingGetName(name) {

    let o = {
      dati_identificativi: 'Dati identificativi',
      capacita_gestionali: 'Capacità gestionali',
      qualita: 'Qualità',
      risorse_infrastrutturali: 'Risorse infrastrutturali e disponibilità logistiche',
      affidabilita: 'Affidabilità economica e finanziaria',
      interrelazioni: 'Interrelazioni maturate con il territorio',
      efficacia: 'Efficacia ed efficienza nelle attività precedentemente realizzate'
    }

    return o[name];
  }

  isInvalid(section) {
    return this.state.progress_obj.required_sections[section] && !this.state.progress_obj.valid_sections[section];
  }

  showSectionClass(name) {

    if (this.state.dati.istruttore_sections_feedback && this.state.dati.istruttore_sections_feedback[name]) {
      return this.state.dati.istruttore_sections_feedback[name].valid ? 'checked' : 'invalid';
    } else if (this.state.dati.stato_flusso === 6 && (!this.state.dati.istruttore_sections_feedback || !this.state.dati.istruttore_sections_feedback[name])) {
      return 'default';
    }

    if (this.state.dati.stato_flusso_formatted && this.state.dati.stato_flusso_formatted.data_static) {
      return this.state.progress_obj.valid_sections && this.state.progress_obj.valid_sections[name] ? 'checked' : 'default';
    }

    if (this.state.progress_obj.valid_sections[name])
      return 'checked';
    else if (this.isInvalid(name))
      return 'invalid';

    return 'default';

  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "accreditamento.pdf");

    this.refresh(false);
  }


  render() {
    return (

      <div id="scheda-accreditamento">

        <Drawer
          title="Comunicazioni"
          width={1024}
          closable={true}
          onClose={() => {
            this.setState(
              {
                show_peclog: false
              }
            )

          }}
          visible={this.state.show_peclog}
          destroyOnClose={true}
        >
          <ComunicazioniPecList
            id_accreditamento={this.state.dati.id}
            filter_by="accreditamento" />

        </Drawer>

        <Drawer
          title="Documenti"
          width={1024}
          closable={true}
          onClose={() => {
            this.setState({ show_documenti_dr: false })
          }}
          visible={this.state.show_documenti_dr}
          destroyOnClose={true}
        >
          <DocumentiList
              accreditamento={this.state.dati} />

        </Drawer>


        <Card
          className="shadow"
          style={{
            marginBottom: "12px",
            //  backgroundColor: "#fff"
          }}
          bordered={false}
        >
          <h3 className="sectionTitle">
            Richiesta di {accreditamentoStatic.getTipoTitle(this.state.dati)} {accreditamentoStatic.getTipoNoRifLabel(this.state.dati)} {accreditamentoStatic.getTipoVariazioneLabel(this.state.dati)}
          </h3>
          <p>
            Compila il formulario richiesta di {accreditamentoStatic.getTipoTitle(this.state.dati)} {accreditamentoStatic.getTipoLabel(this.state.dati)}.
          </p>
          </Card>
          <div style={{ padding: "12px 0" }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/dashboard">Bacheca</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/accreditamento/richieste">Lista richieste</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                Compilazione scheda
            </Breadcrumb.Item>
            </Breadcrumb>
          </div>

        <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
          <Col style={{ flex: "1 1 70%" }} >
            <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
              <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
                bordered={false}>
                {this.state.dati.ente ? <h4><span style={{ color: '#000' }}>ENTE DI FORMAZIONE:</span> {this.state.dati.ente.denominazione}</h4> : ''}
                <Button icon="file-pdf" ghost className="m5h m5w" type="primary" loading={this.state.loading_riepilogo} onClick={() => {
                  this.setState({ loading_riepilogo: true })
                  AccreditamentoService.printPdf(this.state.dati.id, (res) => {
                    this.saveBlob(res.data);
                    this.setState({ loading_riepilogo: false })
                  }, (err) => {
                    this.setState({ loading_riepilogo: false })
                  })
                }}>Riepilogo</Button>

                {
                  (this.isEnte() && this.state.dati.stato > accreditamentoStatic.stato.lavorazione && this.state.dati.stato < accreditamentoStatic.stato.inviata)
                    ?

                    <Popconfirm placement="top" title="Confermi di voler riportare la pratica in lavorazione?"
                      onConfirm={() => {

                        if (!this.state.loading_rollback_state) {
                          this.setState({
                            loading_rollback_state: true
                          }, () =>
                            CompleteServices.rollback(
                              this.state.dati.id,
                              (res) => {
                                this.refresh(true);
                                this.setState({
                                  loading_rollback_state: false
                                });
                              },
                              () => {
                                this.setState({
                                  loading_rollback_state: false
                                });
                              })
                          )
                        }
                      }}
                      okText="Si" cancelText="No">
                      <Button
                        disabled={this.state.loading_rollback_state}
                        loading={this.state.loading_rollback_state}
                        className="m5h" type="primary" icon="undo">Riporta in lavorazione</Button>
                    </Popconfirm>

                    : null
                }



                <Button icon="mail" className="m5h m5w" type="primary" onClick={() => {
                  this.setState({ show_peclog: true })
                }}>Lista Comunicazioni</Button>

                <Button icon="file" className="m5h m5w" type="primary" onClick={() => {
                  this.setState({ show_documenti_dr: true })
                }}>Lista Documenti</Button>

                {this.state.dati.id_from ? (
                  <Button icon="file" className="m5h m5w" type="primary" onClick={
                    () => this.props.history.push("/accreditamento/scheda/" + this.state.dati.id_from)
                  }>Visualizza richiesta di riferimento</Button>
                ) : null}


              </Card>
              <Card style={{ flex: "1 1 auto" }}
                bordered={false}
                className="shadow">
                <div>
                  <Row>
                    <Col lg={24} sm={24} xs={24}>
                      <div id="actions">
                        <h4 className="p10h">Stato di lavorazione pratica</h4>
                        <p>{renderDescriptionText(this.state.dati, this)}</p>
                        <Decreto
                          accreditamento={this.state.dati}
                          refreshInline={(data) => this.refreshInline(data)}
                          refresh={() => this.refresh(false)} />
                        <Actions
                          progress={this.state.progress_obj}
                          accreditamento={this.state.dati}
                          refreshInline={(data) => this.refreshInline(data)}
                          refresh={() => this.refresh(false)} 
                          handleEnableMacrotipologie = {this.handleEnableMacrotipologie}
                          handleReloadPanels = {this.handleReloadPanels}/>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Row>
          </Col>
          {(checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.editableState()) || this.state.dati ?
            <Col style={{ flex: "1 1 30%" }} >
              <Card
                style={{ height: "100%" }}
                bordered={false}>
                {checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.editableState() ?
                  <div style={{ textAlign: 'center' }}>
                    <h4 className="p10h">Percentuale popolamento della scheda</h4>
                    <Progress width={100} type="circle" percent={this.state.progress_obj.progress} />
                    <p className="p30h p20w text-justify">
                      {
                        this.state.progress_obj.progress !== 100
                          ?
                          "Compila le sezioni del formulario fino al raggiungimento del 100%, clicca sul pulsante informazioni per avere dettagli sui dati mancanti"
                          : "Congratulazioni, la compilazione è completa, puoi procedere all'inoltro della domanda"
                      }
                    </p>
                    {
                      this.state.progress_obj.progress < 100
                        ?
                        <Button
                          size="small"
                          icon="info"
                          type="primary"
                          onClick={() => this.setState({ show_errors: true })}>Informazioni</Button>
                        : null
                    }

                    <Button
                      size="small"
                      icon="reload"
                      type="primary"
                      style={{ marginLeft: 8 }}
                      ghost
                      loading={this.state.calculating_progress}
                      onClick={() => this.calculateProgress()}>Aggiorna</Button>
                    <Modal
                      visible={this.state.show_errors}
                      cancelButtonProps={{ style: { display: 'none' } }}
                      width={500}

                      destroyOnClose={false}
                      onOk={() => this.setState({ show_errors: false })}
                      onCancel={() => this.setState({ show_errors: false })}
                    >
                      <ul>
                        {
                          Object.keys(this.state.progress_obj.errors).map(e => {
                            if (this.state.progress_obj.required_sections[e]) {
                              if (this.state.progress_obj.errors[e].length > 0) {
                                return <div key={"section_" + e}>
                                  <h4>{this.sectiontMappingGetName(e)}</h4>
                                  <ul>{
                                    this.state.progress_obj.errors[e].map((err, i, a) => {
                                      return <li key={"err_" + e + "_" + i}>{err}</li>
                                    })
                                  }
                                  </ul>
                                </div>
                              }
                            }
                            return null;
                          })
                        }
                      </ul>
                    </Modal>
                  </div> : null
                }
                {
                  this.state.dati
                    ?
                    <React.Fragment>
                      <div style={{ padding: 20 }}>
                        <AssegnazioneIstruttore
                          refreshInline={(data) => this.refreshInline(data)}
                          refresh={() => this.refresh(false)}
                          accreditamento={this.state.dati} />
                      </div>
                    </React.Fragment>
                    : null
                }
                {
                  this.state.dati.variazione_diff && checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions', 'adminPermissions', 'dirigentePermissions']) ? this.renderDiff() : null
                }
              </Card>
            </Col> : null
          }
        </Row>
          <Card
            style={{ marginBottom: '12px' }}
            bordered={false}
            className="shadow">
          {/*<Divider />*/}
          {(!this.state.loaded) ? <SpinnerLoading message='Caricamento dati...' /> :
            <div>
              <StickyContainer>
              {this.state.dati.ente && !this.isEnte()
                ?
                <Affix offsetTop={20}>
                  <div
                  style={{width: this.props.GlobalStore.open_sidebar ? 'calc(100% - 298px)' : 'calc(100% - 98px)'}}
                  className="affix_element_for_display">
                    <p style={{marginBottom: 0}}>
                    Richiesta di {accreditamentoStatic.getTipoTitle(this.state.dati)} <b></b> <strong>
                    {accreditamentoStatic.getTipoLabel(this.state.dati)} {accreditamentoStatic.getTipoVariazioneLabel(this.state.dati)}
                    </strong> (ENTE: {this.state.dati.ente.denominazione})
                    <br />
                    Stato: <strong>{getStato(this.state.dati, this)/*accreditamentoStatic.getStatoLabel(this.state.dati)*/}</strong></p>
                    <Button icon="mail" className="m5h" type="primary" onClick={() => {
                      this.setState({ show_peclog: true })
                    }}>Lista Comunicazioni</Button>

                    <Button icon="file" className="m5h m5w" type="primary" onClick={() => {
                      this.setState({ show_documenti_dr: true })
                    }}>Lista Documenti</Button>
                  </div>
                </Affix>
                : null
              }
                <Tabs
                onChange={(k)=>this.setState({current_key: ""+k})}
                defaultActiveKey="1" size="medium" renderTabBar={renderTabBar}>
                  <TabPane
                    tab={
                      <span className={this.showSectionClass('dati_identificativi')}>Dati identificativi<br />del soggetto<br /><Icon type="check-circle" /></span>} key="1">
                    <div id="dati">
                      <h3 className="m10h">Dati identificativi del soggetto</h3>
                      <div style={{ padding: 14 }}>
                        <DatiForm
                          current_key={this.state.current_key}
                          reload={this.state.reload}
                          readOnly={(
                            !checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                            ||
                            /**
                             * Questo non andava fatto così
                             */
                            (
                              checkPermission.hasPermission(
                                this.props.UserStore.user,
                                ['entePermissions']
                              ) && !this.editableState('dati_identificativi')
                            )
                          )}
                          readOnlyDocumenti={(
                            !checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                            ||
                            /**
                             * Questo non andava fatto così
                             */
                            (
                              checkPermission.hasPermission(
                                this.props.UserStore.user,
                                ['entePermissions']
                              ) && !this.editableStateDocumenti('dati_identificativi')
                            )
                          )}
                          isFineLucro={this.isFineLucro()}
                          reloadProgress={()=>this.calculateProgress()}
                          ref="dati_form"
                          refreshInline={(data)=> this.refreshInline(data)}
                          accreditamento={this.state.dati}
                          progress={this.state.progress_obj}
                          enableMacrotipologie={this.state.enable_macrotipologie}
                          {...this.props}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab={<span className={this.showSectionClass('risorse_infrastrutturali')}>Risorse infrastrutturali<br />e disponibilità logistiche<br /><Icon type="check-circle" /></span>} key="4">
                    <div id="risorse">
                      <h3 className="m10h">Risorse infrastrutturali e disponibilità logistiche</h3>
                      <div style={{ padding: 14 }}>
                        <Infrastruttura
                          reloadProgress={()=>this.calculateProgress()}
                          current_key={this.state.current_key}
                          accreditamento={this.state.dati}
                          refreshInline={(data)=> this.refreshInline(data)}
                          readOnly={(!checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              ||
                              (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                                && !this.editableState('risorse_infrastrutturali')
                                )
                            )}
                          readOnlyDocumenti={(
                            !checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                            ||
                            /**
                             * Questo non andava fatto così
                             */
                            (
                              checkPermission.hasPermission(
                                this.props.UserStore.user,
                                ['entePermissions']
                              ) && !this.editableStateDocumenti('risorse_infrastrutturali')
                            )
                          )}
                          progress={this.state.progress_obj}
                          enableMacrotipologie={this.state.enable_macrotipologie}
                          {...this.props}
                        />
                      </div>
                    </div>
                  </TabPane>
                    <TabPane
                    tab={<span className={this.showSectionClass('capacita_gestionali')}>Capacità gestionali<br />e risorse professionali<br />
                    <Icon type="check-circle" /></span>} key="2">
                      <div id="capacita">
                        <h3 className="m10h">Capacità gestionali e risorse professionali</h3>
                        <div style={{ padding: 14 }}>
                          <RisorseCapacita
                          reloadProgress={()=>this.calculateProgress()}
                          current_key={this.state.current_key}
                          accreditamento={this.state.dati}
                          refreshInline={(data)=> this.refreshInline(data)}
                          readOnly={(!checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              ||
                              (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                                && !this.editableState('capacita_gestionali')
                                )
                            )}
                          readOnlyDocumenti={(
                            !checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                            ||
                            /**
                             * Questo non andava fatto così
                             */
                            (
                              checkPermission.hasPermission(
                                this.props.UserStore.user,
                                ['entePermissions']
                              ) && !this.editableStateDocumenti('dati_identificativi')
                            )
                          )}
                          progress={this.state.progress_obj}
                          {...this.props}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tab={<span className={this.showSectionClass('affidabilita')}>Affidabilità economica<br />e finanziaria<br /><Icon type="check-circle" /></span>} key="3">
                      <div id="affidabilita">
                        <h3 className="m10h">Affidabilità economica e finanziaria</h3>
                        <div style={{ padding: 14 }}>

                          <Affidabilita
                            reloadProgress={()=>this.calculateProgress()}
                            accreditamento={this.state.dati}
                            current_key={this.state.current_key}
                            refresh={() => this.refresh(false)}
                            refreshInline={(data)=> this.refreshInline(data)}
                            readOnly={(!checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              ||
                              (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                                && !this.editableState('affidabilita')
                                )
                            )}
                            readOnlyDocumenti={(
                              !checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              ||
                              /**
                               * Questo non andava fatto così
                               */
                              (
                                checkPermission.hasPermission(
                                  this.props.UserStore.user,
                                  ['entePermissions']
                                ) && !this.editableStateDocumenti('dati_identificativi')
                              )
                            )}
                            progress={this.state.progress_obj}
                            {...this.props}
                          />
                        </div>
                      </div>
                    </TabPane>

                  <TabPane 
                  key="5"
                  tab={
                    <span className={this.showSectionClass('qualita')}>
                      Qualità<br />
                      <Icon type="check-circle" />
                    </span>}
                  >
                    <div id="qualita">
                      <h3 className="m10h">Qualità</h3>
                      <div style={{ padding: 14 }}>

                        <Qualita
                          reloadProgress={()=>this.calculateProgress()}
                          current_key={this.state.current_key}
                          accreditamento={this.state.dati}
                          ref="qualita_form"
                          refresh={() => this.refresh(false)}
                          refreshInline={(data)=> this.refreshInline(data)}
                          readOnly={(!checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                            ||
                            (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              && !this.editableState('qualita')
                              )
                          )}
                          readOnlyDocumenti={(
                            !checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                            ||
                            /**
                             * Questo non andava fatto così
                             */
                            (
                              checkPermission.hasPermission(
                                this.props.UserStore.user,
                                ['entePermissions']
                              ) && !this.editableStateDocumenti('dati_identificativi')
                            )
                          )}
                          progress={this.state.progress_obj}
                          {...this.props}
                        />
                      </div>
                    </div>
                  </TabPane>
                  {(this.state.progress_obj.showed_sections['efficacia'] ) &&
                    <TabPane tab={<span className={this.showSectionClass('efficacia')}>Efficacia ed efficienza<br /> attività precedentemente realizzate<br /><Icon type="check-circle" /></span>} key="6">
                      <div id="efficacia_efficienza">
                        <h3 className="m10h">Efficacia ed efficienza nelle attività precedentemente realizzate</h3>
                        <div style={{ padding: 14 }}>

                          <Efficacia
                            reloadProgress={()=>this.calculateProgress()}
                            current_key={this.state.current_key}
                            ref="efficacia_form"
                            accreditamento={this.state.dati}
                            refreshInline={(data)=> this.refreshInline(data)}
                            readOnly={(!checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              ||
                              (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                                && !this.editableState('efficacia')
                                )
                            )}
                            progress={this.state.progress_obj}
                            {...this.props}
                          />
                        </div>
                      </div>
                    </TabPane>
                  }
                  {
                    /*
                    Sezione Interrelazioni maturate con il territorio (obbligatoria per accreditamento definitivo e successivi rinnovi ad eccezione degli Enti senza fine di lucro che rientrano nelle seguenti tipologie: università, scuole statali e non statali, legalmente riconosciute e i CFP pubblici, per i quali non è mai obbligatoria ((informazione presente nella sezione dati identificativi del soggetto))
                     */
                    (this.state.progress_obj.showed_sections['interrelazioni'] ) &&
                    <TabPane tab={<span className={this.showSectionClass('interrelazioni')}>Interrelazioni maturate<br />con il territorio<br /><Icon type="check-circle" /></span>} key="7">
                      <div id="territorio">
                        <h3 className="m10h">Interrelazioni maturate con il territorio</h3>
                        <div style={{ padding: 14 }}>

                          <Interrelazioni
                            reloadProgress={()=>this.calculateProgress()}
                            current_key={this.state.current_key}
                            ref="interrelazioni_form"
                            accreditamento={this.state.dati}
                            refreshInline={(data)=> this.refreshInline(data)}
                            tipo_media={this.props.GlobalStore.stubs.tipo_media}
                            readOnly={(!checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                              ||
                              (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions'])
                                && !this.editableState('interrelazioni')
                                )
                            )}
                            progress={this.state.progress_obj}
                            {...this.props}
                          />
                        </div>
                      </div>
                    </TabPane>
                  }
                </Tabs>
              </StickyContainer>
            </div>
          }
        </Card>
      </div>
    );
  }
}


export default AccreditamentoScheda;
