import React, { Component } from "react";

import { message } from "antd";
import CommonForm from "@common/components/form";

const formLayout = (component) => {
  let numeroAllievi = component.props.sede.numero_totale_allievi;
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "input",
                label: "Nome/numero struttura",
                name: "nome_aula",
                input_type: "string",
                props: {},
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Tipo struttura",
                name: "tipo_struttura",
                onChange: "setTipoStruttura",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "tipo_struttura",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Tipo aula",
                name: "id_tab_tipo_aula",
                onChange: "setTipoAula",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "tipo_aula",
              },
            ],
          },
        ],
      },
      /*  JayBee_bugfix_#2977: rimozione tipo laboratorio
            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipo laboratorio',
                                name: 'id_tab_tipo_laboratorio',
                                props: {
                                    disabled: component.props.readOnly,
                                    // size: 'large',
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                showIf: [
                                    {
                                      type: 'prop_func',
                                      func: 'isLaboratorio'
                                    }
                                ],
                                options_props: 'tipo_laboratorio'
                            },
                        ],
                    },
                ],
            },
            */
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "integer",
                label: "Mq",
                name: "metri_quadrati",
                props: {
                  disabled: component.props.readOnly,
                  min: 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "integerwithzero",
                label: "N. Pc",
                name: "numero_pc",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                showIf: [
                  {
                      type: 'prop_func',
                      func: 'IsNotServizi'
                  }
              ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "integerwithzero",
                label: "Di cui collegati ad Internet",
                name: "numero_pc_collegati_internet",
                min: 0,
                props: {
                  disabled: component.props.readOnly,
                  // // size: 'large',
                  // prefix: (
                  //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  // ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                showIf: [
                  {
                      type: 'prop_func',
                      func: 'IsNotServizi'
                  }
              ],
              },
            ],
          },
          {
            span: 12,
            items: [
                {
                    type: 'integer',
                    label: 'N. Allievi',
                    name: 'numero_allievi',

                    props: {
                        disabled: component.props.readOnly,
                        min: 0,
                        // size: 'large',
                        // prefix: (
                        //     <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                        // ),
                    },
                    validations: [
                        {
                            type: 'required',
                            error_message: 'Campo obbligatorio',
                        }
                    ],
                    showIf: [
                        {
                          type: 'prop_func',
                          func: 'IsAllieviRichiesti'
                        }
                    ],
                },
            ],
        },
    ],
},

      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit_aula",
                text: "Salva aula",
                //onClick: 'showDrawer',
                props: {
                  icon: "save",

                  className: "bg-green",
                },
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "onSubmit",
  };
};

class CreateAula extends Component {
  state = {
    loading: false,
    loaded: false,
    tipo_struttura: null,
    is_laboratorio: false,
  };

  async componentDidMount() {}

  changeClausoleRisolutorie(e, field) {
    let value = e.target.value === "1" ? true : false;
    this.props.onChangeClausole(value);
  }

  changedTitoloUsoLocali(value) {
    if (value !== 3 && value !== 2) {
      this.changeClausoleRisolutorie({ target: { value: "0" } }, "");
      this.formRef.props.form.setFields({
        contratto_clausole_risolutorie: {
          value: "0",
        },
      });
    }
  }

  submitFormData(data) {
    let tipo_aula_obj = this.props.GlobalStore.stubs.mappatura_attivita_formativa.tipo_aula
    .find(a => a.id === data.tipo_struttura).tipoAula
    .find(el => parseInt(el.id) === parseInt(data.id_tab_tipo_aula))
if(parseInt(data.metri_quadrati) < parseInt(tipo_aula_obj.min_mq)) {
    message.error('Minimo ' + tipo_aula_obj.min_mq + ' mq');
    return;
}

if(parseInt(data.numero_pc) < parseInt(tipo_aula_obj.min_pc)) {
    message.error('Minimo ' + tipo_aula_obj.min_pc + ' pc');
    return;
}

if(parseInt(data.numero_pc_collegati_internet) < parseInt(tipo_aula_obj.min_pc_internet)) {
    message.error('Almento ' + tipo_aula_obj.min_pc_internet + ' pc collegati a internet');
    return;
}

if(parseInt(data.numero_pc) < parseInt(data.numero_pc_collegati_internet)) {
    message.error('Hai più pc collegati a internet di quanti presenti in totale?');
    return;
}

let capacitaMinima = (parseInt(data.numero_allievi) + 1)*2;
if(data.tipo_struttura == 1 && parseInt(data.metri_quadrati) < parseInt(capacitaMinima)) {
    message.error('L\'aula non ha la quadratura adeguata, la quadratura minima è: ' + capacitaMinima + 'mq');
    return;
}
this.props.onSubmit(data)
}

  /**
   * Crea la mappatura per vedere quali laboratori possono essere inseriti
   * @return {[type]} [description]
   */
  getAvaibleLaboratori() {
    try {
      let avaible_macrotipologie =
        this.props.GlobalStore.stubs.mappatura_attivita_formativa.macrotipologie.filter(
          (el) =>
            this.props.sede.tipoAccreditamento.find(
              (t) => t.id_tipologia === el.id
            )
        );
      //console.log('macro', avaible_macrotipologie)
      let settori = [];

      avaible_macrotipologie.forEach((el) => {
        el.settori
          .filter((s) =>
            this.props.sede.settore.find((ss) => ss.id_settore === s.id)
          )
          .forEach((ss) => settori.push(ss));
      });
      //console.log('settori', settori)
      let id_laboratori = [];
      settori.forEach((s) => {
        s.laboratori.forEach((l) => {
          if (id_laboratori.indexOf(l.id) === -1) id_laboratori.push(l.id);
        });
      });
      //console.log('laboratori', id_laboratori)

      return this.props.GlobalStore.stubs.tipo_laboratorio.filter(
        (t) => id_laboratori.indexOf(t.value) !== -1
      );
    } catch (e) {
      //console.log('errore',e);
      return [];
    }
  }

  render() {
    return (
      <div>
        <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}
            form_model={formLayout(this)}
            values={{}}
            readOnly={this.props.readOnly}
            onSubmit={(data)=>this.submitFormData(data)}

            setTipoStruttura={(value)=>this.setState({tipo_struttura: value})}
            setTipoAula={(value)=>{
                let t = this.props.GlobalStore.stubs.tipo_aula.find(e=>e.value === value);
                if(t && t.label.toLowerCase() === 'laboratorio' ){
                    this.setState({is_laboratorio: true });
                } else {
                    this.formRef.props.form.setFields(
                    {
                      id_tab_tipo_laboratorio: {
                        value: null
                      }
                    });
                    this.setState({is_laboratorio: false });
                }
            }}
            tipo_aula={this.props.GlobalStore.stubs.tipo_aula.filter(el => el.id_tab_tipo_aula_struttura === this.state.tipo_struttura)}
            tipo_struttura={this.props.GlobalStore.stubs.tipo_aula_struttura}
            tipo_laboratorio={this.getAvaibleLaboratori()}

            IsAllieviRichiesti={(value, form) => {
                return (form.getFieldValue('id_tab_tipo_aula') === 3 || 
                        form.getFieldValue('id_tab_tipo_aula') === 4 || 
                        form.getFieldValue('id_tab_tipo_aula') === 5);
            }}

            IsNotServizi={(value, form) => {
                return (form.getFieldValue('id_tab_tipo_aula') != 22);
            }}

            isLaboratorio={(value, form, field_name) => {
                return this.state.is_laboratorio;
            }}
        />
      </div>
    );
  }
}
export default CreateAula;
