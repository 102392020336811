import React, { Component } from 'react';

class PrivacyContent extends Component {

  render() {

    return (
      <div>
        <h2>Privacy</h2>
        <h3>INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI (ai sensi dell'articolo 13 del Regolamento (UE) 2016/679)</h3>
        <p>
        La presente informativa descrive le modalità di gestione del Sistema Informativo per la Formazione (SISFO) della Regione Calabria in riferimento al trattamento dei dati personali degli utenti, identificati o identificabili, che lo consultano e che interagiscono con esso e con i servizi web accessibili per via telematica. L'informativa è resa ai sensi dell’art. 13 del Regolamento UE 2016/679 ‘Regolamento Generale sulla protezione dei dati' (di seguito RGPD) e della normativa italiana vigente per la protezione dei dati personali degli utenti in relazione all’indirizzo http://sisfo-test.regione.calabria.it/ e non per altri siti web esterni ed eventualmente collegati a questo tramite link. 
        Per trattamento di dati personali si intende qualsiasi operazione o insieme di operazioni, compiute con o senza l'ausilio di processi automatizzati e applicate a dati personali o insiemi di dati personali, anche se non registrati in una banca di dati, come la raccolta, la registrazione, l'organizzazione, la strutturazione, la conservazione, l'elaborazione, la selezione, il blocco, l'adattamento o la modifica, l'estrazione, la consultazione, l'uso, la comunicazione mediante trasmissione, la diffusione o qualsiasi altra forma di messa a disposizione, il raffronto o l'interconnessione, la limitazione, la cancellazione o la distruzione.
      </p>
        <h3>TITOLARE DEL TRATTAMENTO</h3>
        <p>
        A seguito della consultazione del sito sopra indicato e dei servizi telematici annessi, possono essere trattati dati relativi a persone fisiche 
        identificate o identificabili. Titolare del trattamento è la Regione Calabria, secondo le competenze delineate nella delibera di Giunta Regionale 
        Regionale n.29 del 2021, e, nello specifico, il Dott. Menotti Lucchetta dirigente del Settore nr. 3 Formazione, Istruzione Professionale ITS, 
        del Dipartimento Istruzione, Formazione Pari Opportunità con sede in Viale Europa, Cittadella Regionale, Località Germaneto 88100 – Catanzaro- 
        telefono 0961 856039 –indirizzo di posta elettronica certificata formazione@pec.regione.calabria.it, telefono 0961857842 
        (di seguito “Delegato del Titolare”).
        </p>
        
        <br />
        <h3>RESPONSABILE DELLA PROTEZIONE DEI DATI</h3>
        <p>
        Il responsabile della protezione dei dati personali come normato dall'art.37 del RGPD è un soggetto designato ad assolvere funzioni di supporto e controllo, consultive, formative e informative relativamente all'applicazione del Regolamento medesimo. 
        Coopera con l'Autorità Garante e costituisce il punto di contatto, anche rispetto agli interessati, per le questioni connesse al trattamento dei dati personali (artt. 38 e 39 del RGPD), nonché al coordinamento per l'implementazione e la tenuta dei registri delle attività di trattamento (art. 30 del RGPD). 
        Si riportano di seguito i dati di contatto del Responsabile della Protezione dei Dati.
        </p>

        <p>
          <strong>Nominativo Responsabile della Protezione dei Dati personali (RPD)</strong><br /><br />
          <strong>Avv. Angela STELLATO</strong><br />
          <strong>Recapito Postale:</strong> Regione Calabria - Cittadella Regionale - Viale Europa, Località Germaneto 88100 – Catanzaro - Piano 5° - Zona Z4.Tramontana <br />
          <strong>Email:</strong> angela.stellato@regione.calabria.it<br />
          <strong>Tel.:</strong> 0961-854152 - <strong>Cell.:</strong> 3371456269<br />
          <strong>PEC.:</strong> rpd@pec.regione.calabria.it<br />
        </p>
        <p>
          <strong>Nominativo Responsabile Esterno della Protezione dei Dati personali</strong><br /><br />
          I riferimenti dei Responsabili Esterni del trattamento dei dati sono:<br />
          R.T. I. Almaviva S.p.A/ Almawave S.p.A/Indra Italia S.p.A/Pwc Public Sector Srl come da contratto ai sensi dell'art.28 del GDPR. 
        </p>
        <h3>BASE GIURIDICA DEL TRATTAMENTO</h3>
        <p>
        Il “Delegato del Titolare”, informa, ai sensi del GDPR e della vigente normativa nazionale in materia di protezione dei dati personali, che i dati raccolti attraverso il SISFO (Sistema Informativo per la FOrmazione) saranno trattati per le finalità e con le modalità indicate di seguito.
        I dati personali sono trattati dalla Regione Calabria nell'esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri, ivi incluso il compito di promuovere la consapevolezza e favorire la comprensione del pubblico riguardo ai rischi, alle norme, alle garanzie e ai diritti in relazione al trattamento e, altresì, di promuovere la consapevolezza dei titolari del trattamento e dei responsabili del trattamento riguardo agli obblighi imposti loro dal RGPD (art. 57, par. 1, lett. b e d).

          <br />A seguito della consultazione di questo sito possono essere trattati dati relativi a persone identificate o identificabili. I dati forniti liberamente e volontariamente dagli utenti vengono acquisiti e trattati nel rispetto delle regole fissate dal RGPD per le seguenti finalità:
          <br />• esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri, ivi incluse le finalità di archiviazione, di ricerca storica e di analisi per scopi statistici;
          <br />• adempimenti di obblighi previsti da norme di legge, regolamenti, normativa nazionale ed europea.
          <br />La Regione Calabria si impegna a rispettare e a proteggere la riservatezza trattando i dati personali forniti nel rispetto delle disposizioni di legge atte a garantire la sicurezza, l'esattezza, l'aggiornamento e la pertinenza dei dati rispetto alle finalità dichiarate. I dati personali forniti dagli utenti che inoltrano richieste sono utilizzati al solo fine di dare esecuzione alla richiesta di volta in volta inoltrata, e sono comunicati a terzi nel solo caso in cui ciò sia strettamente necessario e funzionale a tale finalità, nel rispetto delle disposizioni europee e nazionali.  
          <em>I dati sono trattati da personale appositamente incaricato al trattamento dei dati, solo qualora il trattamento sia necessario allo svolgimento delle mansioni assegnate.</em>
        </p>

        <br />
        <h3>ULTERIORI FINALITÀ DEL TRATTAMENTO A SEGUITO DI SPECIFICO ED ESPRESSO CONSENSO DEGLI UTENTI</h3>
        <p>
          Oltre che per le finalità di cui sopra, i dati conferiti potrebbero essere trattati, previo espresso consenso anche per comunicare eventi o altre iniziative di informazione o di particolare interesse, nonché per eventuali inviti a partecipare ad iniziative di rilevazione di soddisfazione della “clientela” (cd. indagini di “customer satisfaction”) promosse da Regione Calabria. Il consenso per queste “Ulteriori finalità del trattamento" è facoltativo.
        </p>
        <h3>FACOLTATIVITÀ CONFERIMENTO DEI DATI</h3>
        <p>
        A parte quanto specificato sotto per i dati di navigazione, l’utente è libero di fornire volontariamente i dati personali riportati nei moduli di registrazione o di interfaccia ai diversi servizi del Sistema informativo per la Formazione della Regione Calabria. Il conferimento dei dati presenti nei campi contrassegnati da asterisco è obbligatorio solo per poter dar corso ai servizi od alle informative richieste. Il mancato conferimento di determinati dati personali, nei moduli e nelle comunicazioni di richiesta di informazioni, di suggerimenti o di segnalazioni alla redazione del sito o comunque conferiti nel corso di contatti telefonici con le strutture della Regione, può comportare esclusivamente l’impossibilità di ottenere una risposta a quanto segnalato o richiesto.
        </p>
        <h3>MODALITÀ DEL TRATTAMENTO</h3>
        <p>
        I trattamenti connessi ai servizi web di questo sito hanno luogo presso la predetta sede dell’Ente, e sono curati solo da personale tecnico incaricato del trattamento, oppure da eventuali incaricati di occasionali operazioni di manutenzione. Nessun dato derivante dal servizio web viene comunicato o diffuso, salvo nei casi espressamente previsti dalla legge. I dati personali forniti dagli utenti sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta ed espressa nelle finalità del trattamento e sono comunicati a terzi nel solo caso in cui ciò sia a tal fine necessario.
        </p>
        <br />
        <h3>DURATA DEL TRATTAMENTO</h3>
        <p>
        In relazione alle diverse finalità e agli scopi per i quali sono stati raccolti, i dati saranno conservati per il tempo previsto dalla normativa di riferimento ovvero per quello strettamente necessario al perseguimento delle finalità.
        </p>

        <h3>TIPI DI DATI TRATTATI E FINALITÀ DEL TRATTAMENTO</h3>
        <p>
          <u>Dati personali forniti dall’utente</u><br />
          <p>
          Il sito può richiedere di fornire informazioni personali (es: indirizzi mail, numeri di cellulare) ma solo al fine di fornire strumenti di comunicazione. Tali dati sono conservati in forma protetta per impedire l'accesso illecito ai detti. L'invio di segnalazioni comporta l'acquisizione automatica degli indirizzi mittenti che sono associati ai dati anagrafici già in possesso dell'Ente. Per accedere all'Area Riservata è necessario fornire dati personali al fine di verificare l'identità del richiedente l'accesso. Senza autenticazione non è possibile accertare l'identità del richiedente.
          </p>
          <br /><u>Dati di navigazione</u><br />
          <p>
          I sistemi informatici e le procedure software preposte al funzionamento di questo sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano indirizzi IP, nomi di dominio, indirizzi url che non sono adoperati da Regione Calabria per l'identificazione ed il tracciamento dell'utenza ma sono usati solo a finalità statistiche e tecniche, salva eventuale richiesta della Autorità Giudiziaria che voglia individuare eventuali reati informatici. I dati non sono trasmessi a terzi, a meno che la trasmissione non sia imposta o consentita dalla legge.
          </p>
        </p>
        <h3>INFORMATIVA SUI 'COOKIE'</h3>
        <p>
        Con la presente informativa, ai sensi e per gli effetti degli artt. 13 e 122 del D. Lgs. 196/2003 (“Codice in materia di protezione dei dati personali” – d’ora in avanti solo “Codice”) nonché in base a quanto previsto dal Provvedimento generale del Garante privacy “Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie”  dell'<a href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884"  target="_blank" rel="noopener noreferrer">8 maggio 2014</a> (doc. web 3118884), la Regione Calabria, Titolare del trattamento, fornisce agli utenti del Sistema Informativo per la Formazione alcune informazioni relative ai cookie utilizzati.
          
          <br /><br/>
          <u>Cosa sono i 'cookie'.</u>
          <br /><br/>
          Un “cookie” è un piccolo file di testo creato sul computer dell’utente al momento in cui questo accede ad un determinato sito, con lo scopo di immagazzinare e trasportare informazioni. I cookie sono inviati da un server web (che è il computer sul quale è in esecuzione il sito web visitato) al browser dell’utente (es. Internet Explorer, Mozilla Firefox, Google Chrome ecc.) e memorizzati sul dispositivo di quest’ultimo; vengono quindi re-inviati al sito web al momento delle visite successive.
          Nel corso della navigazione l’utente potrebbe ricevere sul suo dispositivo anche cookie di siti diversi (cosiddetti cookie di “terze parti”) impostati direttamente da gestori di detti siti web e utilizzati per le finalità e secondo le modalità da questi definiti.

        </p>
        
        <p><u>Tipologie di cookie utilizzate dal presente sito</u><br/><br/>
        Il sito memorizza "cookies" per rendere più agevoli successivi accessi dello stesso utente. La memorizzazione è episodica per i cookies di sessione che consentono la fruibilità del sito, mentre è temporanea per i cookies di analisi del traffico internet che consentono l'elaborazione di statistiche di accesso. Cookies a memorizzazione permanente possono essere eseguiti solo ove giustificato dal servizio istituzionale offerto dalla Regione Calabria e comunque non sono usati cookies di profilazione. I cookies sono disabilitabili tramite browser ma ciò impedirà l'autenticazione per l'accesso all'Area Riservata del sito dove è possibile consultare i propri dati.
        </p>



        <br/>
        <h3>TRASFERIMENTO DEI DATI PERSONALI VERSO PAESI NON APPARTENENTI ALL’UNIONE EUROPEA</h3>
        <p>I dati raccolti ed elaborati non vengono trasferiti presso Società o altre entità al di fuori del territorio comunitario.</p>

        <h3>DIRITTI DEGLI INTERESSATI</h3>
        <p>
        Gli interessati hanno il diritto di ottenere, nei casi previsti, l'accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che li riguarda o di opporsi al trattamento (artt. 15 e ss.del RGPD). L'apposita istanza alla Regione Calabria è presentata contattando il Responsabile della protezione dei dati.
        </p>
        <h3>DIRITTO DI RECLAMO</h3>
        <p>
        Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti, effettuato attraverso questo sito, avvenga in violazione di quanto previsto dal RGPD hanno il diritto di proporre reclamo al Garante per la protezione dei dati personali (art. 77 del RGPD) , seguendo le procedure e le indicazioni pubblicate sul sito web ufficiale dell’Autorità su <a href="http://www.garanteprivacy.it/" target="_blank" rel="noopener noreferrer">www.garanteprivacy.it</a>.
        </p>
      </div>
    );
  }
}

export default PrivacyContent;