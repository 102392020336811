import { Card, Row, Col, Button, message, Popconfirm } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';

import format from '@common/utils/formatters/_';
import ModelServices from '@affidamento/services/Lezioni';

import CommonForm from '@common/components/form';

import moment from 'moment';

const formLayoutIngresso = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                //label: 'Registra ingresso',
                name: 'fromTime',
                placeholder: component.props.lesson.presenza_docente_dalle !== null ? component.props.lesson.presenza_docente_dalle : 'Orario',
                props: {
                  style: { width: '100%' },
                  disabled: (component.props.lesson.presenza_docente_dalle !== null || component.props.lesson.presenza_docente === false) ? true : false
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_time'
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                name: 'btn',
                type: 'submit',
                text: 'Registra ingresso',
                btn_type: 'primary',
                props: {
                  disabled: (component.props.lesson.presenza_docente_dalle !== null || component.props.lesson.presenza_docente === false) ? true : false
                },
              },
            ],
          },
        ]
      },
    ],
    onSubmit: 'submitted',
  }
}

const formLayoutUscita = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                //label: 'Registra ingresso',
                name: 'fromTime',
                placeholder: component.props.lesson.presenza_docente_alle !== null ? component.props.lesson.presenza_docente_alle : 'Orario',
                props: {
                  style: { width: '100%' },
                  disabled: (component.props.lesson.presenza_docente_alle !== null || component.props.lesson.presenza_docente === false) ? true : false
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_time'
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                name: 'btn',
                type: 'submit',
                text: 'Registra uscita',
                btn_type: 'primary',
                props: {
                  disabled: (component.props.lesson.presenza_docente_alle !== null || component.props.lesson.presenza_docente === false) ? true : false
                },
              },
            ],
          },
        ]
      },
    ],
    onSubmit: 'submitted',
  }
}

export default class PannelloLezione extends Component {

  static propTypes = {
    lesson: PropTypes.object,
    isNextLesson: PropTypes.bool,
    loading: PropTypes.bool,
  }

  state = {
    loading: false,
  }

  setPresenza(presente) {
    this.setState({ loading: true });
    ModelServices.setPresenza({ presente: presente }, this.props.lesson.id,
      res => {
        this.setState({ loading: false });
        message.success("Operazione eseguita correttamente");
        if(this.props.refreshInline) this.props.refreshInline(res.data.data)
      }, () => {
        this.setState({ loading: false });
      });

  }

  setPresenzaDocente(presente) {
    this.setState({ loading: true });
    ModelServices.setPresenzaDocente({ presente: presente }, this.props.lesson.id,
      res => {
        if(this.props.refreshInline) this.props.refreshInline(res.data.data);
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      });

  }

  setRegistraIngressoDocente(data) {
    this.setState({ loading: true });
    ModelServices.setRegistraIngressoDocente({ orario: data.fromTime.format('HH:mm') }, this.props.lesson.id,
      res => {
        if(this.props.refreshInline) this.props.refreshInline(res.data.data);
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      });

  }

  setRegistraUscitaDocente(data) {
    this.setState({ loading: true });
    ModelServices.setRegistraUscitaDocente({ orario: data.fromTime.format('HH:mm') }, this.props.lesson.id,
      res => {
        if(this.props.refreshInline) this.props.refreshInline(res.data.data);
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      });

  }

  setRegistraAssenzaDocente() {
    this.setState({ loading: true });
    ModelServices.setRegistraAssenzaDocente({ presente: false }, this.props.lesson.id,
      res => {
        if(this.props.refreshInline) this.props.refreshInline(res.data.data);
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false });
      });

  }

  canSetPresenza() {
    if (this.props.corso?.stato !== affidamentoStatic.stato_progetto.erogazione)
      return false;

      if(this.props.lesson){
        const _today = moment();
        let data_lezione = moment(this.props.lesson.data, 'YYYY-MM-DD');
        if(data_lezione.diff( _today, 'days' ) > 0) 
          return false;
      }
      

    return this.props.lesson && this.props.lesson.id
      && checkPermission.hasPermission(this.props.UserStore.user, ['manageMyLezioneDocente'])
      && this.props.lesson.docente?.id === this.props.UserStore.user.anagrafica.id;
  }

  canSetPresenzaDiscente() {
    if(this.props.lesson){
      const _today = moment();
      let data_lezione_da = moment(this.props.lesson.data + ' ' + this.props.lesson.dalle, 'YYYY-MM-DD HH:mm');
      let data_lezione_a = moment(this.props.lesson.data + ' ' + this.props.lesson.alle, 'YYYY-MM-DD HH:mm');

      if (data_lezione_da.diff(_today, 'minutes') > 0)
        return false;
      if (data_lezione_a.diff(_today, 'minutes') < 0)
        return false;
    }

    return true;
  }

  render() {
    return (<>
      <Card style={{ flex: "1 1 auto" }} bordered={false}>
        {this.props.lesson && this.props.lesson.data && <>
          <h4>{this.props.isNextLesson ? 'PROSSIMA LEZIONE' : this.props.lesson.stage ? 'STAGE' : 'LEZIONE'}</h4>
          <h4><span style={{ color: '#000' }}>Materia:</span> {affidamentoStatic.getMateriaLabel(this.props.lesson?.id_materia)}</h4>
          <h4><span style={{ color: '#000' }}>Data:</span> {format.formatDate(this.props.lesson?.data, 'DD/MM/YYYY')}</h4>
          <h4><span style={{ color: '#000' }}>Orario:</span> {format.formatDate(this.props.lesson?.dalle, 'HH:mm', 'HH:mm:ss')} - {format.formatDate(this.props.lesson?.alle, 'HH:mm', 'HH:mm:ss')}</h4>
          <h4><span style={{ color: '#000' }}>Tipo svolgimento:</span> {affidamentoStatic.getTipoLezioneLabel(this.props.lesson?.id_tipo_lezione)}</h4> 
        {this.props.isNextLesson || !this.props.lesson.stage ? 
        <h4><span style={{ color: '#000' }}>Docente:</span> {this.props.lesson?.docente?.nome} {this.props.lesson?.docente?.cognome}</h4> 
        : <h4><span style={{ color: '#000' }}>Azienda:</span> {this.props.lesson?.azienda} {this.props.lesson?.docente?.cognome}</h4>}  
          <h4><span style={{ color: '#000' }}>PROGRAMMA:</span></h4>
          {this.props.lesson?.descrizione_programma}
        </>}
        {this.props.isNextLesson && !(this.props.lesson && this.props.lesson.data) && <h4> NESSUNA LEZIONE PIANIFICATA NELLE PROSSIME GIORNATE</h4>}
      </Card>

      {this.canSetPresenza() &&
        <React.Fragment>
          <Card style={{ flex: "1 1 auto", marginTop: 12 }}
          bordered={false}
          className="shadow">
          <Row>
            <Col lg={24} sm={24} xs={24}>
              <div id="actions">
                <h4 className="p10h">Presenza</h4>
                <CommonForm
                  wrappedComponentRef={inst => (this.formSelRef = inst)}
                  form_model={formLayoutIngresso(this)}
                  submitted={(data) => this.setRegistraIngressoDocente(data)}
                  
                  min_time={(rule, value, cb) => {
                    if (!value || value.length === 0) cb();
                    let min = value;

                    let max = this.formSelRef.props.form.getFieldsValue()['toTime'];

                    if (max && min > max) cb("L'orario di avvio deve essere minore dell'orario di conclusione");
                    else cb()
                  }}
                  max_time={(rule, value, cb) => {
                    if (!value || value.length === 0) cb();
                    let max = value;

                    let min = this.formSelRef.props.form.getFieldsValue()['fromTime'];

                    if (min && min > max) cb("L'orario di avvio deve essere minore dell'orario di conclusione");
                    else cb()
                  }}
                />
                <CommonForm
                  wrappedComponentRef={inst => (this.formSelRef = inst)}
                  form_model={formLayoutUscita(this)}
                  submitted={(data) => this.setRegistraUscitaDocente(data)}
                  
                  min_time={(rule, value, cb) => {
                    if (!value || value.length === 0) cb();
                    let min = value;

                    let max = this.formSelRef.props.form.getFieldsValue()['toTime'];

                    if (max && min > max) cb("L'orario deve essere minore dell'orario di conclusione");
                    else cb()
                  }}
                  max_time={(rule, value, cb) => {
                    if (!value || value.length === 0) cb();
                    let max = value;

                    let min = this.formSelRef.props.form.getFieldsValue()['fromTime'];

                    if (min && min > max) cb("L'orario deve essere minore dell'orario di conclusione");
                    else cb()
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} sm={24} xs={24}>
              <div id="actions">
                <h4 className="p10h">Assenza</h4>
                <Button 
                icon="file-pdf" 
                ghost={this.props.lesson?.presenza_docente === null || this.props.lesson?.presenza_docente === true} 
                className="m5h m5w" 
                type="danger" 
                loading={this.props.loading || this.state.loading} 
                onClick={() => { this.setRegistraAssenzaDocente() }}
                disabled={(this.props.lesson.presenza_docente_dalle !== null  || this.props.lesson.presenza_docente_alle !== null || this.props.lesson.presenza_docente === false )? true : false}
                >
                  Assente
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
        {/*<Card style={{ flex: "1 1 auto", marginTop: 12 }}
          bordered={false}
          className="shadow">
          <Row>
            <Col lg={24} sm={24} xs={24}>
              <div id="actions">
                <h4 className="p10h">Presenza</h4>
                <p>Seleziona una delle opzioni</p>
                {this.props.lesson?.presenza_docente !== null && <p>Impostata come: {this.props.lesson?.presenza_docente ? 'presente' : 'assente'}</p>}
                <Button icon="file-pdf" ghost={this.props.lesson?.presenza_docente === null || this.props.lesson?.presenza_docente === false} className="m5h m5w" type="primary" loading={this.props.loading || this.state.loading} onClick={() => { this.setPresenzaDocente(true) }}>Presente</Button>
                <Button icon="file-pdf" ghost={this.props.lesson?.presenza_docente === null || this.props.lesson?.presenza_docente === true} className="m5h m5w" type="danger" loading={this.props.loading || this.state.loading} onClick={() => { this.setPresenzaDocente(false) }}>Assente</Button>
              </div>
            </Col>
          </Row>
        </Card>*/}
        </React.Fragment>
      }
      {this.props.lesson && this.canSetPresenzaDiscente() && this.props.lesson.id && checkPermission.hasPermission(this.props.UserStore.user, ['manageMyPresenze']) && this.props.studente?.stato === affidamentoStatic.stato_iscrizione.iscritto &&
        <Card style={{ flex: "1 1 auto", marginTop: 12 }}
          bordered={false}
          className="shadow">
        <Row>
          <Col lg={24} sm={24} xs={24}>
            <div id="actions">
              <h4 className="p10h">Presenza</h4>
              { this.props.lesson.conIscrizioneLezioneDiscente && this.props.lesson.conIscrizioneLezioneDiscente.length === 0 && 
                <div>
                  <p>Seleziona una delle 2 opzioni</p>
                  <Popconfirm
                    placement="leftTop" title="Confermi?" onConfirm={() => this.setPresenza(true)} okText="Si" cancelText="No">
                    <Button icon="file-pdf" ghost className="m5h m5w" type="primary" loading={this.props.loading || this.state.loading}>Presente</Button>
                  </Popconfirm>

                  <Popconfirm
                    placement="leftTop" title="Confermi?" onConfirm={() => this.setPresenza(false)} okText="Si" cancelText="No">
                    <Button icon="file-pdf" ghost className="m5h m5w" type="danger" loading={this.props.loading || this.state.loading}>Assente</Button>
                  </Popconfirm>
                </div>
              }
              {this.props.lesson.conIscrizioneLezioneDiscente && this.props.lesson.conIscrizioneLezioneDiscente.length > 0 &&
                <div>
                  <p>Hai selezionato: </p>
                  {
                    this.props.lesson.conIscrizioneLezioneDiscente[0].presente ? 'PRESENTE' : 'ASSENTE'
                  }
                   <p> alla prossima lezione </p>
                </div>
              }


              </div>
            </Col>
          </Row>
        </Card>
      }
    </>
    );
  }
}

