import React, { Component } from 'react';

import { Radio } from 'antd';
import allegatiStatic from '@common/utils/static/allegati'
import Documenti from '@common/components/Documenti';

import DownloadCasellarioGiudiziale from '@accreditamento/components/scheda/DownloadCasellarioGiudiziale';



class FileEnte extends Component {
  state = {
    loading: false,
    loaded: false,
    modifiche_statuto: "0",
    files: {}

  };



  async componentDidMount() {
    this.setModificheStatuto(this.props)
  }



  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id !== this.props.accreditamento.id) this.setModificheStatuto(newProps)
  }

  setModificheStatuto(props) {
    this.setState({ modifiche_statuto: props.accreditamento.ente.modifiche_statuto })
  }

  getFormData() {
    return { modifiche_statuto: this.state.modifiche_statuto }
  }


  render() {

    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;

    let isRealReadOnlyDocumentiStatutoModificato = isRealReadOnly;
    if(this.state.modifiche_statuto){
      let filteredDocumentiStatutoModificato = this.props.accreditamento.ente.media.filter(element => element.tipo.dominio === 'ente' && element.tipo.sottodominio === 'statuto_modificato');
      if(isRealReadOnlyDocumentiStatutoModificato){
        //Dovrebbe essere readOnly, ma se non ci sono documenti statuto_modificato devo comunque
        //consentire l'inserimento
        if(filteredDocumentiStatutoModificato.length === 0){
          isRealReadOnlyDocumentiStatutoModificato = false;
        }
      }

    }
    return (
      <div>
        <Documenti
          canDelete={!isRealReadOnly}
          canAddNote={false}
          unique={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          medias={this.props.accreditamento.ente.media}
          current_domain="ente"
          current_subdomain="atto_statuto"
          required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'atto_statuto')}
          ref_id={this.props.accreditamento.ente.id}
          readOnly={isRealReadOnly}
          accreditation_id={this.props.accreditamento.id}
        />
        <div style={{ marginBottom: 16 }}>
          <h4>Modifiche statuto</h4>
          <Radio.Group value={this.state.modifiche_statuto} disabled={this.props.readOnly} onChange={(e) => this.setState({ modifiche_statuto: e.target.value })}>
            <Radio.Button value={true}>Si</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </div>
        {
          this.state.modifiche_statuto
            ?
            <Documenti
              canDelete={!isRealReadOnlyDocumentiStatutoModificato}
              canAddNote={false}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.accreditamento.ente.media}
              unique={true}
              current_domain="ente"
              current_subdomain="statuto_modificato"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'statuto_modificato')}
              ref_id={this.props.accreditamento.ente.id}
              readOnly={isRealReadOnlyDocumentiStatutoModificato}
              accreditation_id={this.props.accreditamento.id}
            />
            : false
        }
        <Documenti
          canDelete={!isRealReadOnly}
          canAddNote={false}
          canDownloadTemplate={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          medias={this.props.accreditamento.ente.media}
          current_domain="ente"
          unique={true}
          current_subdomain="casellario_giudiziale"
          required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'casellario_giudiziale')}
          ref_id={this.props.accreditamento.ente.id}
          readOnly={isRealReadOnly}
          accreditation_id={this.props.accreditamento.id}
        >
          {(!this.props.readOnly) ?
            <DownloadCasellarioGiudiziale
              idAccreditamento={this.props.accreditamento.id}
            /> : null}
        </Documenti>
        <Documenti
          canDelete={!isRealReadOnly}
          canAddNote={false}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          medias={this.props.accreditamento.ente.media}
          current_domain="ente"
          unique={true}
          current_subdomain="autoceritificazione_dati_personali"
          required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'autoceritificazione_dati_personali')}
          ref_id={this.props.accreditamento.ente.id}
          readOnly={isRealReadOnly}
          accreditation_id={this.props.accreditamento.id}
        />
        {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'documenti_ente', this.props.accreditamento.tipo_accreditamento) &&
          <Documenti
            canDelete={!isRealReadOnly}
            canAddNote={false}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.accreditamento.ente.media}
            current_domain="ente"
            unique={true}
            current_subdomain="documenti_ente"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'documenti_ente')}
            ref_id={this.props.accreditamento.ente.id}
            readOnly={isRealReadOnly}
            accreditation_id={this.props.accreditamento.id}
          />}
      </div>
    );
  }
}

export default FileEnte;