import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import List from '../components/List';

@Wrapper({ route: 'ListaNomine', hasSidebar: true, hasHeader: true })
@CheckPermissions(['ispettorePermissions', 'dirigentePermissions', 'adminPermissions', 'HelpdeskPermissions'])
class ListRoute extends Component {

  state = {
    loading: false,
    asked: false,
    loaded_roles: false,
    roles: []
  }





  loadOrganizzazione(props) {

  }

  getBreadCrumb() {
    let breads = [
      <Breadcrumb.Item key="dash"><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
    ];

    breads.push(<Breadcrumb.Item key="org"><Link to={"/nomina"}>Nomine</Link></Breadcrumb.Item>)

    return breads;
  }

  getHeaderText() {

  }

  render() {

    return <div>
      <Card
        style={{ marginBottom: '12px', backgroundColor: '#fff' }}
        bordered={false}
      >
        <h3 className="sectionTitle">Nomine</h3>
      </Card>
      <div style={{ padding: '12px' }}>
        <Breadcrumb>
          {this.getBreadCrumb()}
        </Breadcrumb>
      </div>

      <List ref="listTable" {...this.props} />

    </div>
  }
}

export default ListRoute;
