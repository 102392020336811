import React, { Component } from "react";
import { Button, Card, Popconfirm, message, Tooltip, Row, Icon } from "antd";

import moment from "moment";

import checkPermission from "@common/utils/check/permissions";
import FilterForm from '@common/components/filterForm';

import TableComponent from "@common/components/table";

import ModelServices from "../services/CommissioneEsami";

import FileSaver from "file-saver";

import affidamentoStatic from "@common/utils/static/affidamento";
import ProjectServices from "@affidamento/services/Progetto";

import _ from "@common/utils/formatters/_";

const renderActions = (element, component) => {
  return (
    <div style={{ display: "inline-block" }}>
      {checkPermission.hasPermission(component.props.UserStore.user, [
        "viewProgetto",
      ]) &&
        checkPermission.hasPermission(component.props.UserStore.user, [
          "editProgetto",
        ]) && (
          <Tooltip
            placement="top"
            title={
              element.stato === affidamentoStatic.stato_progetto.registrato ||
              element.stato === affidamentoStatic.stato_progetto.predisposizione
                ? "Modifica scheda di commissione"
                : "Visualizza scheda di commissione"
            }
          >
            <Button
              className="m2w"
              size="small"
              shape="circle"
              icon={
                element.stato === affidamentoStatic.stato_progetto.registrato ||
                element.stato ===
                  affidamentoStatic.stato_progetto.predisposizione
                  ? "edit"
                  : "eye"
              }
              onClick={() =>
                component.props.history.push(
                  "/affidamento/commissioni/" + element.id
                )
              }
            />
          </Tooltip>
        )}

      {checkPermission.hasPermission(component.props.UserStore.user, [
        "viewProgetto",
      ]) &&
        !checkPermission.hasPermission(component.props.UserStore.user, [
          "editProgetto",
        ])  && (
          <Tooltip placement="top" title="Visualizza scheda di commissione">
            <Button
              className="m2w"
              size="small"
              shape="circle"
              icon="eye"
              onClick={() =>
                component.props.history.push(
                  "/affidamento/commissioni/" + element.id
                )
              }
            />
          </Tooltip>
        )}

      {element.stato < affidamentoStatic.stato_progetto.presentato &&
        checkPermission.hasPermission(component.props.UserStore.user, [
          "deleteProgetto",
        ]) && (
          <Tooltip placement="top" title="Elimina il progetto">
            <Popconfirm
              disabled={component.state.loading}
              placement="leftTop"
              title="Sicuro di voler rimuovere la domanda di Commissione?"
              onConfirm={() => component.deleteProgetto(element.id)}
              okText="Si"
              cancelText="No"
            >
              <Button
                disabled={component.state.loading}
                className="m2w"
                size="small"
                shape="circle"
                icon="delete"
              />
            </Popconfirm>
          </Tooltip>
        )}
    </div>
  );
};

const filterFormModel = (component) => {

  const isEnte = component.props.UserStore.user.role.some(r => r.name !== 'Ente');
  const isDiscente = component.props.UserStore.user.role.some(r => r.name === 'Discente');

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: isEnte ? 6 : 8,
            items: [
              { // TODO
                type: 'select',
                label: 'Stato',
                name: 'stato',
                options: [
                  {
                    value: "-1",
                    label: "Tutti"
                  },
                  {
                    value: "0",
                    label: "Registrato"
                  },
                  {
                    value: "1",
                    label: "In predisposizione"
                  },
                  {
                    value: "2",
                    label: "Presentato"
                  },
                  {
                    value: "3",
                    label: "Assegnato"
                  },
                  {
                    value: "4",
                    label: "In istruttoria"
                  },
                  {
                    value: "5",
                    label: "Avvio sospeso"
                  },
                  {
                    value: "6",
                    label: "Rigettato"
                  },
                  {
                    value: "7",
                    label: "Avvio"
                  },
                  {
                    value: "8",
                    label: "Iscrizioni aperte"
                  },
                  {
                    value: "10",
                    label: "In erogazione"
                  },
                  {
                    value: "11",
                    label: "Completato"
                  },
                  {
                    value: "12",
                    label: "Revoca corso"
                  },
                  {
                    value: "13",
                    label: "Sospensione corso"
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']); }
                  },
                ]
              }
            ]
          },
          !isEnte ? {
            span: 6,
            items: [
              {
                type: 'string',
                label: 'Ente erogatore',
                name: 'ente_erogatore',
              }
            ]
          } : {},
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato domanda',
                name: 'stato_iscrizione',
                options: [
                  {
                    value: "",
                    label: "Tutti"
                  },
                  {
                    value: "0",
                    label: "Bozza"
                  },
                  {
                    value: "1",
                    label: "Compilata"
                  },
                  {
                    value: "2",
                    label: "Inviata"
                  },
                  {
                    value: "3",
                    label: "Idoneo"
                  },
                  {
                    value: "4",
                    label: "Non idoneo"
                  },
                  {
                    value: "5",
                    label: "Abbandonato"
                  },
                  {
                    value: "6",
                    label: "In attesa"
                  },
                  {
                    value: "7",
                    label: "Iscritto"
                  },
                  {
                    value: "8",
                    label: "Espulso"
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return checkPermission.hasPermission(component.props.UserStore.user, ['viewProgettoDiscente']); }
                  },
                ]
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

const renderStatusIndicator = (record, component) => {
  let user = "";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "manageOwnProgetto",
    ])
  )
    user = "ente";
  if (
    checkPermission.hasRole(component.props.UserStore.user, [
      "DirigenteAmministrativo",
    ])
  )
    user = "dirigente";

    if (
      checkPermission.hasRole(component.props.UserStore.user, [
        "Valutatore",
      ])
    )
      user = "presidente";

    if (
      checkPermission.hasRole(component.props.UserStore.user, [
        "FunzionarioCoordinatoreAmministrativo",
      ])
    )
      user = "funzionario";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, ["addAudit"]) && !checkPermission.hasRole(component.props.UserStore.user, [
      "DirigenteAmministrativo",
    ])
  )
    user = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "manageOwnNomina",
    ])
  )
    user = "ispettore";

  let colors = ["green", "red"];
  let defined_c = "";

  if (user !== "") {
    colors.forEach(c => {
      if (affidamentoStatic.getPallino(user, c, parseInt(record.stato_flusso)) !== -1) defined_c = c;
    });
  }

  return defined_c !== "" ? (
    <Tooltip
      placement="topRight"
      title={record.stato_flusso_description["descrizione_" + user]}
    >
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
  ) : (
    <span className={"pallino invisible"}></span>
  );
};

const model = (component) => {
  return {
    with_server_pagination: true,
    columns: [
      {
        title: "",
        dataIndex: "",
        sorter: false,
        key: "actions",
        //width: "300px",
        render: (element) => (
          <div style={{ MaxWidth: 150 }}>
            {renderStatusIndicator(element, component)}
            {renderActions(element, component)}
          </div>
        ),
      },
      {
        title: "Numero",
        dataIndex: "",
        sorter: false,
        key: "Numero",
        render: (element) => <div>{element.id}</div>,
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {affidamentoStatic.getProgettoLabel(element.stato, checkPermission.hasPermission(component.props.UserStore.user, ['entePermission']))}
        </div>,
      },
      {
        title: "Nome Progetto",
        dataIndex: "",
        sorter: false,
        key: "titolo_progetto",
        render: (element) => (
          <div>
            {element.progetto ? element.progetto.titolo_progetto : element.titolo}
          </div>
        ),
      },
      {
        title: "Edizione",
        dataIndex: "",
        sorter: false,
        key: "edizione",
        render: (element) => <div>{element.edizione_progetto}</div>,
      },
      !checkPermission.hasPermission(component.props.UserStore.user, [
        "entePermissions",
      ]) && {
        title: "Ente erogatore",
        dataIndex: "",
        sorter: false,
        key: "ente_erogatore",
        render: (element) => <div>{element.ente?.denominazione || ""}</div>,
      },
      {
        title: "Data creazione",
        dataIndex: "",
        sorter: false,
        key: "data_creazione",
        render: (element) => (
          <div style={{ width: 140 }}>
            {element.data_protocollo
              ? moment(element.data_protocollo, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : ""}
          </div>
        ),
      },
      {
        title: "Numero protocollo",
        dataIndex: "",
        sorter: false,
        key: "numero_protocollo",
        render: (element) => <div>{element.numero_protocollo}</div>,
      },
      {
        title: "Acquisita il",
        dataIndex: "",
        sorter: false,
        key: "data_protocollo",
        render: (element) => (
          <div style={{ width: 140 }}>
            {element.data_protocollo
              ? moment(element.data_protocollo, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : ""}
          </div>
        ),
      },
    ],
  };
};

class TableProgetti extends Component {
  base_url = "/affidamento/commissioni";
  default_order = "-created_at";

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: [],
    },
    id_commissione: null,
  };

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search);
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager, } = _.getFilterParam(query, this.default_order);

    params.expand = ["progetti"].join(",");

    this.setState({ loading: true });
    ModelServices.list(
      params,
      (res) => {
        let data = _.getResponse(res);
        this.setState(
          {
            loading: false,
            data: data,
          },
          () =>
            this.refs.table_component.updateTableData(
              data.data,
              data.current,
              data.total,
              pager
            )
        );
      },
      (e) => {
        this.setState({ loading: false });
      }
    );
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search);
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  deleteProgetto(id) {
    this.setState({ loading: true }, () => {
      ModelServices.delete(
        id,
        (res) => {
          message.success("Domanda di Commissione eliminata con successo!");
          this.setState({ loading: false });
          this.refresh();
        },
        () => this.setState({ loading: false })
      );
    });
  }

  printPdf(id) {
    this.setState(
      {
        loading: true,
      },
      () => {
        ModelServices.printPdf(id, (res) => {
          const _blob = new Blob([res.data], { type: "application/pdf" });
          FileSaver.saveAs(_blob, "commissione_esame.pdf");
          this.setState({ loading: false });
        });
      }
    );
  }

  addNew() {
    this.setState({ loading: true }, () => {
      ModelServices.createCommissione(
        null,
        (res) => {
          message.success(
            "Nuova Commissione creata con successo, procedere con la compilazione dei dati"
          );
          this.setState({ loading: false, id_commissione: res.data.data.id });
          this.props.history.push(
            "/affidamento/commissioni/" + res.data.data.id
          );
        },
        () => this.setState({ loading: false })
      );
    });
  }

  newEdition(id) {
    this.setState({ loading: true }, () => {
      ModelServices.newEdition(
        id,
        (res) => {
          message.success(
            "Nuova edizione creata con successo, procedere con la compilazione dei dati"
          );
          this.setState({ loading: false });
          this.props.history.push("/affidamento/progetto/" + res.data.data.id);
        },
        () => this.setState({ loading: false })
      );
    });
  }

  delete(id) {
    this.setState({ loading: true }, () => {
      ModelServices.delete(
        id,
        (res) => {
          message.success("Formulario eliminato con successo!");
          this.setState({ loading: false });
          this.refresh();
        },
        () => this.setState({ loading: false })
      );
    });
  }

  loadRegistrationData(id) {
    this.setState({ loading: true }, () => {
      ProjectServices.loadRegistrationData(
        {},
        id,
        (res) => {
          this.setState({
            model_anagrafica: res.data.data,
            loading: false,
          });
          this.props.history.push(
            "/affidamento/progetto/" + id + "/iscrizione/" + res.data.data.id
          );
        },
        () => this.setState({ loading: false })
      );
    });
  }


  render() {
    return (
      <div>
                <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.props.location.search}
          history={this.props.history}
          date_fields={['data_avvio']}
        />
        <Card className="shadow no-pad" bordered={false}>
          <Row type="flex" justify="start" className="m10h">
            {checkPermission.hasPermission(this.props.UserStore.user, [
              "entePermissions",
            ]) && (
              <div className="m5h m5w">
                <Button
                  className="m5h bg-green"
                  icon="plus"
                  onClick={() => this.addNew()}
                >
                  Nuova Richiesta di commissione
                </Button>
              </div>
            )}
          </Row>
          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: "id" }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TableProgetti;
