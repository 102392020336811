import React, { Component } from "react";
import CommonForm from '@common/components/form';
import { Icon } from "antd";

import Documenti from '@common/components/Documenti';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Soggetto incaricato',
              name: 'soggetto_incaricato',
              onChange: 'changedVal',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Rappr. legale"
                },
                {
                  value: "2",
                  label: "Altro soggetto legato all'ente"
                },
                {
                  value: "3",
                  label: "Utente esterno"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'select',
              label: 'Soggetto incaricato',
              name: 'altra_anagrafica',
              // le opzioni come le selects
              options_props: 'anagrafiche',
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "2"
                }
              ]
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'soggetto_accompagnamento_title',
              type: 'title',
              element: 'h4',
              text: 'Soggetto incaricato all’accompagnamento degli ispettori durante l’ispezione:',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ],
            }
            ,
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Nome',
              type_string: 'text', // field type
              name: 'accompagnatore_nome',
              props: {
                //size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Cognome',
              type_string: 'text', // field type
              name: 'accompagnatore_cognome',
              props: {
                //size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'datepicker',
              label: 'Data di nascita',
              name: 'accompagnatore_data_nascita',
              props: {
                style: {width: '100%'},
                //size: 'large',
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Luogo di nascita',
              type_string: 'text', // field type
              name: 'accompagnatore_luogo_nascita',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Comune di residenza',
              type_string: 'text', // field type
              name: 'accompagnatore_residenza',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Via',
              type_string: 'text', // field type
              name: 'accompagnatore_via',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Qualifica',
              type_string: 'text', // field type
              name: 'qualifica_accompagnatore',
              props: {
                //size: 'large',
                prefix: <Icon type="setting" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'soggetto_incaricato',
                  operator: '==',
                  value: "3"
                }
              ]
            },
          ],
        },
      ],
    },

  ],
  onSubmit: 'onSubmit',
};


class CorsiAutofinanziati extends Component {
  state = {
      mounted: false,
      loading: false,
      soggetto_accompagnatore: 1
    };

  componentDidMount() {
    this.setState({soggetto_accompagnatore: parseInt(this.props.values.soggetto_incaricato)})
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    /*if(newProps.values.soggetto_incaricato !== this.props.values.soggetto_incaricato) {
      this.setState({soggetto_accompagnatore: parseInt(newProps.soggetto_incaricato)})
    }*/
  }

  async validate() {
    return new Promise((resolve,) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue()
    };
    return obj;
  }

  changedVal(e,v) {
    this.setState({soggetto_accompagnatore: parseInt(v.target.value) })
  }

  render() {
    return <div>
          <CommonForm
            anagrafiche={this.props.anagrafiche.map(a => {
              return {
                key: a.id,
                label: a.nome + " " + a.cognome + " (" + a.cf + ")",
                value: a.id
              }
            })}
            changedVal={(e,v)=>this.changedVal(e,v)}
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={this.props.values}
            readOnly={this.props.readOnly}
            onSubmit={(data) => null}
            tipicorsi={[]}
          />
          {this.state.soggetto_accompagnatore && parseInt(this.state.soggetto_accompagnatore) !== 1 ?
          <Documenti
            canDelete={!this.props.readOnly}
            canAddNote={false}
            unique={true}
            required_dates={true}
            media_types={this.props.GlobalStore.stubs.tipo_media}
            medias={this.props.verbale.media}
            current_domain="verbale_ispezione"
            current_subdomain="deleghe"
            required_types={[]}
            ref_id={this.props.verbale.id}
            readOnly={this.props.readOnly}
          />
          : null}
      </div>
  }
}


export default CorsiAutofinanziati;