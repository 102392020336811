import React, { Component } from 'react';
import { Button, message, Modal } from 'antd';
import CommonForm from '@common/components/form';
import Documenti from '@common/components/Documenti';

import moment from 'moment';
import PrivacyContent from '@common/pages/PrivacyContent';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'child',
                type: 'child',
                child: <Documenti
                  canDelete={true}
                  canAddNote={false}
                  tempFile={true}
                  unique={true}
                  media_types={component.props.tipo_media}
                  medias={component.props.values.medias}
                  current_domain="ente"
                  current_subdomain="logo"
                  required_types={component.props.tipo_media
                    .filter(el => el.sottodominio === 'logo' && el.dominio === 'ente')
                    .map(e => e.tipo_media)
                  }
                  ref_id={component.props.id_ente}
                  onUpload={(f) => component.updateMedias(f)}
                  onDelete={(id)=>component.deleteMedia(id)}
                  accept=".png,.jpeg,.jpg"
                  uploadButtonText="carica il logo in formato immagine .png, .jpeg, .jpg"
                />
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submitted',
  }
}

class DocumentsLogoForm extends Component {
  state = {
    loading: false,
    fileListAc: [],
    fileListStatuto: [],
    fileListStatutoMod: [],
    medias: [],
    loaded: false,
    disabled_trattamento: true,
    CCIAA_required: true
  };



  componentDidMount() {
    console.warn("Documents componentDidMount", this.props)

    const descrizioneFormaGiuridica = this.props.GlobalStore.stubs.descrizione_forma_giuridica.filter(el => parseInt(el.id) === parseInt(this.props.organization.id_descrizione_forma_giuridica));
    const CCIAA_required = ['2.3.00'].includes(descrizioneFormaGiuridica.codice) ? true : false;

    if (this.props.values.medias) {
      this.setState({
        medias: [...this.state.medias, ...this.props.values.medias]
      });
    }

    this.setState({ loaded: true, CCIAA_required: CCIAA_required });

    if (this.props.values.files) {
      this.setState({
        fileListAc: this.props.values.files.filter(f => f.chiave === "atto_costitutivo"),
        fileListStatuto: this.props.values.files.filter(f => f.chiave === "statuto_ente"),
        fileListStatutoMod: this.props.values.files.filter(f => f.chiave === "statuto_ente_modificato")
      });
    }
  }



  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  getValues() {

    let obj = {
      ...this.formRef.props.form.getFieldsValue(),
      medias: [...this.props.values.medias]
    };
    return obj;
  }

  updateMedias(media) {
    let just = localStorage.getItem('document_form_accreditamento');
    if(just) {
      just = JSON.parse(just)
    } else {
      just = { medias: [] }
    }

    just.medias = [...just.medias, media];
    localStorage.setItem('document_form_accreditamento', JSON.stringify(just));

    if (this.props.onUpload) this.props.onUpload(media)
    //this.setState({ medias: [...this.state.medias, media] })
  }

  deleteMedia(id) {
    try{
      if (this.props.onDeleteMedia) this.props.onDeleteMedia(id)
    } catch(e) {
      console.error(e)
    }

  }

  getYears() {
    let now = parseInt(moment().format('YYYY'));
    let y = [];
    for ( let n = 0; n <= 100; n++) {

      y.push({
        key: 'anno_'+(now-n),
        value: ""+(now-n),
        label: ""+(now-n)
      })

    }

    return y;
  }

  openTrattamento() {
    this.setState({open_modal_trattamento: true})
  }

  render() {

    return (
      <div>
        {this.state.loaded ?
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}

            form_model={formLayout(this)}
            values={{
              ...this.props.values,
            }}

            onlyPast={(rule, value, cb) => {
              return moment() > value ? cb() : cb("Data non valida");
            }}

            anni={this.getYears()}

          /> : null}
          <Modal
            title="Informativa sul trattamento dei dati personali"
            visible={this.state.open_modal_trattamento}
            onOk={()=> {
              this.formRef.props.form.setFields(
              {
                accettazione_termini: {
                  value: "1"
                }
              });
              this.setState({open_modal_trattamento: false, disabled_trattamento: false})
            }}
            onCancel={() => this.setState({ open_modal_trattamento: false })}
          >
            <PrivacyContent/>
          </Modal>
      </div>
    );
  }
}

export default DocumentsLogoForm;
