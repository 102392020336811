import BaseRequest from "@common/services/_baseRequest";


export default {

  start: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/start',
      cb,
      ecb || null,
      null
    );
  },

  EsameStart: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/esame-audit/' + id + '/start',
      cb,
      ecb || null,
      null
    );
  },

  prepareApprove: (id, id_audit, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/prepare_approve/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  prepareApproveAttestati: (id, id_audit, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/esame-audit/' + id + '/prepare_approve/' + id_audit,
      cb,
      ecb
    );
  },


  approveControdeduzioni: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/approve_controdeduzioni',
      cb,
      ecb || null,
      null
    );
  },

  confirmApprove: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/confirm_approve',
      cb,
      ecb || null,
      null
    );
  },

  integration: (id, id_audit, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/integration/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  suspend: (id, id_audit, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/suspend/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  suspendComunicazioneOstativa: (id, id_audit, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/suspend_comunicazione_ostativa/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  confirm_integrazione: (id, id_audit, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/confirm-integration-request/' + id_audit,
      cb,
      ecb || null,
      null
    );
  },

  downloadComunicazione: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame-audit/' + id + '/download_comunicazione',
      cb,
      ecb || null,
      null
    );
  },

  uploadComunicazione: (id, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/upload_comunicazione',
      cb,
      ecb || null,
      null
    );
  },

  editControdeduzioni: (id, id_comunicazione, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/comunicazione/' + id_comunicazione + '/edit_controdeduzioni',
      cb,
      ecb || null,
      null
    );
  },

  downloadControdeduzioni: (id, id_comunicazione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame-audit/' + id + '/comunicazione/' + id_comunicazione + '/controdeduzioni',
      cb,
      ecb || null,
      null
    );
  },

  uploadControdeduzioni: (id, id_comunicazione, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/comunicazione/' + id_comunicazione + '/controdeduzioni',
      cb,
      ecb || null,
      null
    );
  },

  sendControdeduzioni: (id, id_comunicazione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/comunicazione/' + id_comunicazione + '/controdeduzioni/send',
      cb,
      ecb || null,
      null
    );
  },

  prepareReject: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/prepare_reject',
      cb,
      ecb || null,
      null
    );
  },

  confirmReject: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/confirm_reject',
      cb,
      ecb || null,
      null
    );
  },

  downloadDecreto: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame-audit/' + id + '/decreto_autorizzativo',
      cb,
      ecb || null,
      null
    );
  },

  uploadDecreto: (id, data = {}, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/decreto_autorizzativo',
      cb,
      ecb || null,
      null
    );
  },

  saveDecretoInfo: (id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame-audit/' + id + '/decreto_autorizzativo_info',
      cb,
      ecb || null,
      null
    );
  },
}
