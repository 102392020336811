import React, { Component } from 'react';
import { Button, message } from 'antd';
import CommonForm from '@common/components/form';
import Documenti from '@common/components/Documenti';
import format from '@common/utils/formatters/_';
import checkPermission from '@common/utils/check/permissions';
import allegatiStatic from '@common/utils/static/allegati'
import DownloadTemplatesServices from '@accreditamento/services/DownloadTemplates';
import FileSaver from 'file-saver';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Bilancio certificato',
                name: 'bilancio_certificato',
                option_type: 'button', // normal|button
                onChange: 'changedBilancio',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                },
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'radio',
                label: 'Bilancio degli ultimi 3 anni',//negli ultimi tre anni come da prospetto predisposto acquisibile nella sezione Modulistica firmato dal rappresentante legale o da revisore contabile
                name: 'conformita_bilancio_3anni',
                option_type: 'button', // normal|button
                onChange: 'changedConformitaBilancio',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                // validations: [
                //   {
                //     type: 'required',
                //     error_message: 'Campo obbligatorio',
                //   },
                // ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Certifico il possesso di un sistema contabile per centri di costo contabile, come da regolamento CE N° 1083/2006',
                name: 'sistema_contabile_ce',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^1$',
                    message: 'Devi selezionare "Si"'
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Certifico Assenza di stato di fallimento, liquidazione coatta, concordato preventivo o procedimenti',
                name: 'assenza_fallimento',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^1$',
                    message: 'Devi selezionare "Si"'
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'obbligo_bilanci_title',
                type: 'title',
                element: 'h4',
                text: 'Certifico che i bilanci sono stati',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Certificati dal Revisore dei Conti',
                name: 'bilanci_certificati_revisori_conti',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Certificati da un Revisore esterno inscritto all’Albo',
                name: 'bilanci_certificati_revisore_esterno',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Sono stati regolarmente assolti, per il personale dipendente e per quello a prestazione, gli obblighi previdenziali con il versamento degli importi sia a carico del lavoratore che quelli a carico del datore di lavoro. Non sono tenuti a dimostrare tale requisito i soggetti richiamati all’art. 9.',
                name: 'obblighi_previdenziali_assolti',
                option_type: 'button', // normal|button
                onChange: 'changedObblighiPrevidenziali',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^1$',
                    message: 'Devi selezionare "Si"'
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Il soggetto si trova nelle condizioni di assolvimento della L.68/99',
                name: 'assolvimento_l68_99',
                option_type: 'button',
                //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
                onChange: 'changedEsenzione',
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No"
                  },
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Viene applicato il Contratto Collettivo Nazionale di Lavoro della formazione professionale al personale assunto',
                name: 'applicazione_ccnl',
                option_type: 'button', // normal|button
                onChange: 'changedCCNL',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'needContrattoCollettivo'
                  },
                ],
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
};

class AffidabilitaEconomicaForm extends Component {

  state = {

    loaded: false,
    loading: false,
    files: {},
    id_affidabilita_economica: '',
    obblighi_previdenziali_assolti: false,
    esenzione: null,
    applicazione_ccnl: false,
    Autocertificaizone_centri_di_costo: false,
    bilancio: null,
    bilancio_certificato: null,
    medias: []
  };

  componentDidMount() {
    this.load(this.props)
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if(newProps.ente !== this.props.ente || newProps.accreditamento !== this.props.accreditamento || newProps.values !== this.props.values) {
      this.load(newProps);
    }
  }


  load(props) {

    let obj = Object.assign({}, props.values);

    if(obj.assenza_fallimento !== false ) obj.assenza_fallimento = true;
    if(obj.sistema_contabile_ce !== false ) obj.sistema_contabile_ce = true;
    if(obj.obblighi_previdenziali_assolti !== false ) obj.obblighi_previdenziali_assolti = true;

    console.warn("props.ente.media", props.ente.media);
    this.setState({
      loaded:true,
      id_affidabilita_economica: obj.id,
      obblighi_previdenziali_assolti: obj.obblighi_previdenziali_assolti,

      //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
      esenzione: (parseInt(obj.assolvimento_l68_99,) === 1) ? true : false,

      applicazione_ccnl: obj.applicazione_ccnl,
      bilancio: (parseInt(obj.conformita_bilancio_3anni,) === 1) ? true : false,
      bilancio_certificato: (parseInt(obj.bilancio_certificato,) === 1) ? true : false,
      medias: props.ente.media
    })

  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }


  getValues(with_validation = false) {

    let obj = Object.assign({}, this.props.values);

    if(obj.assenza_fallimento !== false ) obj.assenza_fallimento = true;
    if(obj.sistema_contabile_ce !== false ) obj.sistema_contabile_ce = true;
    if(obj.obblighi_previdenziali_assolti !== false ) obj.obblighi_previdenziali_assolti = true;

    return format.convertBoolean([
      'applicazione_ccnl',
      'assolvimento_l68_99',
      'bilanci_certificati_revisore_esterno',
      'bilanci_certificati_revisori_conti',
      'bilancio_certificato',
      'conformita_bilancio_3anni',
      'esente_l68_99',
      'in_amministrazione_controllata',
      'in_concordato_preventivo',
      'in_fallimento',
      'in_liquidazione_coatta',
      'obblighi_previdenziali_assolti',
      'sistema_contabile_ce',
      'assenza_fallimento',
      'esente_nomotivazione_l68_99'
    ], obj);

  }

  refresh() {
    return this.props.refresh();
  }

  verifyFileObbligatori() {

  }

  async submitAffidabilita() {
    let data = this.formRef.props.form.getFieldsValue();

    //console.log('dati', data)
    let valid_form = await this.validate();
    if(valid_form) {

      if(this.obblighiPrevidenzialiAssolti()){
        if(this.state.medias.filter(m => m.tipo.tipo_media?.toLowerCase() === 'autocertificazione durc' && m.tipo.sottodominio === 'durc_f24' && parseInt(m.deleted) !== 1).length === 0) {
          message.error('Allegato Mancante: DURC');
          return;
        }
      }

      if(this.needCertificatoCameraDiCommercio()){
        if(this.state.medias.filter(m => m.tipo.sottodominio === 'certificato_fallimentare' && parseInt(m.deleted) !== 1).length === 0) {
          message.error('Allegato mancante: Certificato fallimentare');
          return;
        }
      }

      if(this.esenzione()){
        if(this.state.medias.filter(m => m.tipo.sottodominio === 'esenzione_l68_99' && parseInt(m.deleted) !== 1).length === 0) {
          message.error('Allegato mancante: Esenzione assolvimento L.68/99');
          return;
        }
      }

    } else {
      return;
    }

    this.submitted(data);
  }

  submitted(data) {

    this.props.onSubmit(data);

  }

  needCertificatoCameraDiCommercio() {

    return (this.props.ente.numero_ccia && this.props.ente.numero_ccia !== '' &&
      this.props.ente.data_iscrizione && this.props.ente.data_iscrizione !== '') ? true : false

  }

  needContrattoCollettivo() {

    let tipologia = this.props.GlobalStore.stubs.tipo_accreditamento.find(t => t.label === 'Istruzione e Formazione Professionale');
    if(!tipologia) return false;

    return (this.props.accreditamento.accreditamentotipologie.filter(el => el.id_tipologia === tipologia.value).length > 0) ? true : false
  }

  obblighiPrevidenzialiAssolti() {
    return this.state.obblighi_previdenziali_assolti;
  }

  esenzione() {
    return this.state.esenzione;
  }

  applicazione_ccnl() {
    return this.state.applicazione_ccnl;
  }

  bilancio() {
    return this.state.bilancio
  }

  bilancioCertificato() {
    return this.state.bilancio_certificato
  }

  render() {
    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;
    const mediaTypes = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento);
    const allMedias = this.props.ente.media;
    let requiredMedia = '';
    let count;
    let mediaIdToSearch;
    
    let isRealReadOnlyBilancioCertificato = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredOptionalMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'bilancio_certificato')[0];
      
      mediaTypes.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyBilancioCertificato = found;
      }
    }

    let isRealReadOnlyBilancio = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredOptionalMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'bilancio_ultimi_3_anni')[0];

      mediaTypes.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            console.warn("bilancio_ultimi_3_anni media found", m)
            found = true;
          }
        })
        isRealReadOnlyBilancio = found;
      }
    }

    let isRealReadOnlyObblighiPrevidenzialiAssolti = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')[0];
      const mediaTypesObblighiPrevidenzialiAssolti = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)
      mediaTypesObblighiPrevidenzialiAssolti.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyObblighiPrevidenzialiAssolti = found;
      }
    }

    let isRealReadOnlyObblighiPrevidenzialiAssolti2 = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')[0];
      const mediaTypesObblighiPrevidenzialiAssolti2 = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)
      mediaTypesObblighiPrevidenzialiAssolti2.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyObblighiPrevidenzialiAssolti2 = found;
      }
    }
    
    let isRealReadOnlyEsenzione = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredOptionalMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'esenzione_l68_99')[0];
      const mediaTypesEsenzione = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.sottodominio === 'esenzione_l68_99'), this.props.accreditamento.tipo_accreditamento)
      mediaTypesEsenzione.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyEsenzione = found;
      } else {
        isRealReadOnlyEsenzione = false;
      }
    }

    let isRealReadOnlyCertificatoFallimentare = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_fallimentare')[0];
      const mediaTypesCertificatoFallimentare = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)
      mediaTypesCertificatoFallimentare.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyCertificatoFallimentare = found;
      }
    }

    
    let isRealReadOnlyDurcF24 = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() != 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')[0];
      const mediaTypesDurcF24 = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() != 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)
      mediaTypesDurcF24.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyDurcF24 = found;
      }
    }

    
    let isRealReadOnlyAutocertificazioneCcnl = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'autocertificazione_ccnl')[0];
      const mediaTypesAutocertificazioneCcnl = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)
      mediaTypesAutocertificazioneCcnl.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyAutocertificazioneCcnl = found;
      }
    }

    let isRealReadOnlyAffidabilitaEconomica = isRealReadOnly;
    if(isRealReadOnly){
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'affidabilita_economica')[0];
      const mediaTypesAffidabilitaEconomica = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)
      mediaTypesAffidabilitaEconomica.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyAffidabilitaEconomica = found;
      }
    }

    return this.state.loaded ? (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          onSubmit={(values) => {}}
          changedObblighiPrevidenziali={(key, e)=>this.setState({obblighi_previdenziali_assolti: (parseInt(e.target.value) === 1) ? true : false })}
          changedEsenzione={(key, e)=>this.setState({esenzione: (parseInt(e.target.value) === 1) ? true : false })}
          changedCCNL={(key, e)=>this.setState({applicazione_ccnl: (parseInt(e.target.value) === 1) ? true : false })}
          Autocertificaizone_CDC={(key, e)=>this.setState({Autocertificaizone_centri_di_costo: (parseInt(e.target.value) === 1) ? true : false })}
          changedConformitaBilancio={(key, e)=>this.setState({bilancio: (parseInt(e.target.value) === 1) ? true : false })}
          changedBilancio={(key, e)=>this.setState({bilancio_certificato: (parseInt(e.target.value) === 1) ? true : false })}
          province={this.props.province}
          readOnly={this.props.readOnly}
          checkPermissions={() => { return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) }}
          needCertificato={(value, form, field_name) => this.needCertificatoCameraDiCommercio()}
          needContrattoCollettivo={(value, form, field_name) => this.needContrattoCollettivo()}
        />
        <h4>Documenti</h4>
        {
          this.bilancioCertificato() && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'bilancio_certificato', this.props.accreditamento.tipo_accreditamento)
          ?
          <Documenti
            onUpload={(f) => {
              this.setState({medias: [...this.state.medias, f]})
            }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
            canDelete={!isRealReadOnlyBilancioCertificato}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="bilancio_certificato"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'bilancio_certificato')}
            ref_id={this.props.ente.id}
            readOnly={isRealReadOnlyBilancioCertificato}
            />
          : null
        }
        {
          this.bilancio() && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'bilancio_ultimi_3_anni', this.props.accreditamento.tipo_accreditamento)
          ?
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
            canDelete={!isRealReadOnlyBilancio}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="bilancio_ultimi_3_anni"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'bilancio_ultimi_3_anni')}
            ref_id={this.props.ente.id}
            readOnly={isRealReadOnlyBilancio}
          />
          : null
        }
        {
          this.obblighiPrevidenzialiAssolti()
          ?
          <React.Fragment>
            {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24', this.props.accreditamento.tipo_accreditamento) &&
            <Documenti
              onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!isRealReadOnlyObblighiPrevidenzialiAssolti2}
              canAddNote={false}
              unique={true}
              required_dates={true}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="durc_f24"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() === 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')}
              ref_id={this.props.ente.id}
              readOnly={isRealReadOnlyObblighiPrevidenzialiAssolti2}
              downloadTemplateEnabled={true}
              downloadTemplateOnClick={(event) => {
                console.warn("downloadTemplateOnClick Click is ok")
                DownloadTemplatesServices.download("durc_f24", (res) => {
                  const _blob = new Blob([res.data], { type: 'application/vnd.oasis.opendocument.text' });
                  FileSaver.saveAs(_blob, "Durc.odt");
                });
                event.stopPropagation();
              }}
            /> }
          </React.Fragment>
          : null
        }
        {
          //JayBee_bugfix_#2917: aggiunta allegato ESENZIONE ASSOLVIMENTO L.68/99
          this.esenzione() && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'esenzione_l68_99', this.props.accreditamento.tipo_accreditamento)
          ?
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!isRealReadOnlyEsenzione}
              canAddNote={false}
              unique={true}
              required_dates={true}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.sottodominio === 'esenzione_l68_99'), this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="esenzione_l68_99"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'esenzione_l68_99')}
              ref_id={this.props.ente.id}
              readOnly={isRealReadOnlyEsenzione}
            />
          : null
        }

        {this.needCertificatoCameraDiCommercio() && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_fallimentare', this.props.accreditamento.tipo_accreditamento)
          ?
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!isRealReadOnlyCertificatoFallimentare}
              canAddNote={false}
              unique={true}
              required_dates={true}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="certificato_fallimentare"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_fallimentare')}
              ref_id={this.props.ente.id}
              readOnly={isRealReadOnlyCertificatoFallimentare}
              downloadTemplateEnabled={true}
              downloadTemplateOnClick={(event) => {
                console.warn("downloadTemplateOnClick Click is ok")
                DownloadTemplatesServices.download("certificato_fallimentare", (res) => {
                  const _blob = new Blob([res.data], { type: 'application/vnd.oasis.opendocument.text' });
                  FileSaver.saveAs(_blob, "CertificatoFallimentare.odt");
                });
                event.stopPropagation();
              }}
            />
            : null
          }
        
        
        {
           allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'durc_f24', this.props.accreditamento.tipo_accreditamento)
            ?
            <Documenti
              onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
              accreditation_id={this.props.accreditamento.id}
              onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
              canDelete={!isRealReadOnlyDurcF24}
              canAddNote={false}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() != 'autocertificazione durc' && el.sottodominio === 'durc_f24'), this.props.accreditamento.tipo_accreditamento)}
              medias={this.props.ente.media}
              current_domain="ente"
              current_subdomain="durc_f24"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media.filter(el => el.tipo_media && el.tipo_media.toLowerCase() != 'autocertificazione durc' && el.sottodominio === 'durc_f24'), 'ente', 'durc_f24')}
              ref_id={this.props.ente.id}
              readOnly={isRealReadOnlyDurcF24}
            />
            : null
        }
        {
          allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'autocertificazione_ccnl', this.props.accreditamento.tipo_accreditamento)
          ?
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
            canDelete={!isRealReadOnlyAutocertificazioneCcnl}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="autocertificazione_ccnl"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'autocertificazione_ccnl')}
            ref_id={this.props.ente.id}
            readOnly={isRealReadOnlyAutocertificazioneCcnl}
          />
          : null
        }
        
        {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'affidabilita_economica', this.props.accreditamento.tipo_accreditamento) &&
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
            canDelete={!isRealReadOnlyAffidabilitaEconomica}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="affidabilita_economica"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'affidabilita_economica')}
            ref_id={this.props.ente.id}
            readOnly={isRealReadOnlyAffidabilitaEconomica}
          />}
           {this.state.Autocertificaizone_centri_di_costo == 1 ?
          <Documenti
            onUpload={(f) => {
                this.setState({medias: [...this.state.medias, f]})
              }}
            accreditation_id={this.props.accreditamento.id}
            onDelete={(id) => this.setState({medias: this.state.medias.filter(el=>el.id !== id)})}
            canDelete={!isRealReadOnlyAffidabilitaEconomica}
            canAddNote={false}
            unique={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.ente.media}
            current_domain="ente"
            current_subdomain="affidabilita_economica"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'affidabilita_economica')}
            ref_id={this.props.ente.id}
            readOnly={isRealReadOnlyAffidabilitaEconomica}
          /> : ''}
        <Button
          type="primary"
          className="bg-green"
          loading={this.props.loading || false}
          icon="save"
          style={{float: 'right', marginTop: 50}}
          disabled={this.props.readOnly}
          onClick={() => this.submitAffidabilita()}
        >Salva affidabilità economica</Button>
      </div>
    ) : null
  }
}

export default AffidabilitaEconomicaForm;
