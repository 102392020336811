import React, { Component } from "react";
import CapacitaGestionali from "@modules/affidamento/services/CapacitaGestionali";
import CommonForm from "@common/components/form";

import { Table, Checkbox, Button, Radio } from "antd";

const columnsOrganigramma = (component) => {
  return [
    {
      title: "Funzione",
      dataIndex: "funzione",
      key: "funzione",
      render: (text, record) => <div>{record.funzione}</div>,
    },
    {
      title: "Dati anagrafici",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.nome + " " + record.cognome}
          <br />
          {record.cf}
        </div>
      ),
    },
    {
      title: 'Valutazione',
      dataIndex: '',
      key: 'selezione',
      render: (text, record) => (<div>
        <Radio.Group
          onChange={(e)=>component.props.checkOrganigramma(e, record)}
          value={component.props.validOrganigramma(record)}>
          <Radio.Button value={1}>Si</Radio.Button>
          <Radio.Button value={0}>No</Radio.Button>
        </Radio.Group>
      </div>),    
     },
  ];
};

const columnsDocenti = (component) => {
  return [
    {
      title: "Dati anagrafici",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.nome + " " + record.cognome}
          <br />
          {record.cf}
        </div>
      ),
    },
    {
      title: 'Valutazione',
      dataIndex: '',
      key: 'selezione',
      render: (text, record) => (<div>
        <Radio.Group
          onChange={(e)=>component.props.checkDocente(e, record)}
          value={component.props.validDocente(record)}>
          <Radio.Button value={1}>Si</Radio.Button>
          <Radio.Button value={0}>No</Radio.Button>
        </Radio.Group>
      </div>),    
     },
  ];
};

const columnsUtenzeSpeciali = (component) => {
  return [
    {
      title: "Dati anagrafici",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.nome + " " + record.cognome}
          <br />
          {record.cf}
        </div>
      ),
    },
    {
      title: "Professione",
      dataIndex: "professione",
      key: "professione",
      render: (text, record) => <div>{record.funzione}</div>,
    },
    {
      title: 'Valutazione',
      dataIndex: '',
      key: 'selezione',
      render: (text, record) => (<div>
        <Radio.Group
          onChange={(e)=>component.props.checkUtenzaSpeciale(e, record)}
          value={component.props.validUtenzaSpeciale(record)}>
          <Radio.Button value={1}>Si</Radio.Button>
          <Radio.Button value={0}>No</Radio.Button>
        </Radio.Group>
      </div>),    
     },
  ];
};



class RisorseUmaneTable2 extends Component {
  state = {
    loading: false,
    loading_organigramma: false,
    loading_docenti: false,
    loading_utenze_speciali: false,
    open_drawer: false,
    capacita: {},
    sedi: [],
    funzioni: [],
    docenti: [],
    utenze_speciali: [],
    accreditamento: {},
  };


  componentDidMount() {
    this.loadData(this.props, null, false);
 console.error(this,"wella")
  }

  loadData(props, el = null, refresh = true) {
    if (props.reloadProgress && refresh) props.reloadProgress();

    let str_loading = !el ? "loading" : "loading_" + el;

    this.setState({ [str_loading]: true });

    CapacitaGestionali.listProgetto(
      props.progetto_padre.id,
      (res) => {
        this.setState({
          accreditamento: res.data.data.accreditamento,
          capacita: res.data.data.capacita,
          funzioni: res.data.data.funzioni,
          docenti: res.data.data.docenti,
          utenze_speciali: res.data.data.utenze_speciali,
          sedi: res.data.data.sedi,
          [str_loading]: false,
        });
      },
      () => this.setState({ [str_loading]: false })
    );
  }



  getPr() {
    let p = Object.assign({}, this.props);
    delete p.accreditamento;

    return p;
  }

  render() {
    return (
      <div>
        <h4 className="m20h">Organigramma aziendale</h4>
        <Table
          locale={{
            emptyText: this.props.domanda_json.relatedSubjects
              ?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData
              ?.numero_decreto_accreditamento
              ? ""
              : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali",
          }}
          loading={this.props.loading}
          columns={columnsOrganigramma(this)}
          dataSource={this.props.professionalita?.organigramma}
          rowKey="cf"
        />

        <br />
        <br />
        <h4>Docenti</h4>
        <Table
          locale={{
            emptyText: this.props.domanda_json.relatedSubjects
              ?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData
              ?.numero_decreto_accreditamento
              ? ""
              : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali",
          }}
          loading={this.props.loading}
          columns={columnsDocenti(this)}
          dataSource={this.props.professionalita?.docenti}
          rowKey="cf"
        />
        <br />
        <br />
        <h4>Utenze speciali</h4>
        <Table
          locale={{
            emptyText: this.props.domanda_json.relatedSubjects
              ?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData
              ?.numero_decreto_accreditamento
              ? ""
              : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali",
          }}
          loading={this.props.loading}
          columns={columnsUtenzeSpeciali(this)}
          dataSource={this.props.professionalita?.utenzeSpeciali}
          rowKey="cf"
        />
      </div>
    );
  }
}
export default RisorseUmaneTable2;
