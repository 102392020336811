import React, { Component } from 'react';

import { Button, Drawer, Table, Tooltip, Popconfirm } from 'antd';

import EditModuliDidattici from './EditModuliDidattici';
import UnitaDidatticheTable from './UnitaDidatticheTable';

const columns = (component) => {
    let ret = [
        {
            title: 'Titolo Modulo',
            dataIndex: 'moduleTitle',
            key: 'moduleTitle',
            render: (text, record) => (<div className= {!component.recordIsValid(record)?  'record-not-valid' :  ''}>{record.moduleTitle}</div>)
        },
        {
            title: 'Descrizione modulo',
            dataIndex: 'moduleDescription',
            key: 'moduleDescription',
            render: (text, record) => (<div  className= {!component.recordIsValid(record)?  'record-not-valid' :  ''}>{record.moduleDescription}</div>)
        },
        {
            title: 'Durata modulo',
            dataIndex: 'moduleDuration',
            key: 'moduleDuration',
            render: (text, record) => (<div  className= {!component.recordIsValid(record)?  'record-not-valid' :  ''}>{record.moduleDuration}</div>)
        },
        {
            title: 'Annualità',
            dataIndex: 'annuity',
            key: 'annuity',
            render: (text, record) => (<div  className= {!component.recordIsValid(record)?  'record-not-valid' :  ''}>{record.annuity}
             {!component.recordIsValid(record) && <Tooltip placement="top" title={"Attenzione: annualità non valida"}><Button className="m2w record-not-valid" size="small" shape="circle" icon={"exclamation"} /></Tooltip>}</div>)
        },
    ];

    if (!component.props.hiddenActions) {
        ret.push(
            {
                title: 'Azioni',
                dataIndex: 'azione',
                key: 'azione',
                render: (text, record) => (
                    <div>
                        {!component.props.loading_data &&
                            <React.Fragment>
                                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                                    <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => { component.open(record, record.id, 'edit_modulo') }} />
                                </Tooltip>
                                {component.props.teachingProgramming.AfcTeachingUnitsFormSection &&
                                    <Tooltip placement="top" title="Unità didattiche">
                                        <Button className="m2w" size="small" shape="circle" icon="copy" onClick={() => { component.open(record, record.id, 'edit_unita') }} />
                                    </Tooltip>}
                                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo modulo?"
                                    onConfirm={() => component.deleteModule(record, record.id)} okText="Si" cancelText="No">
                                    <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                ),
            }
        )
    }
    return ret;
}

class ModuliDidattici extends Component {

    state = {
        open_drawer_edit_modulo: false,
        open_drawer_edit_unita: false,
        create_modulo: false,
        element_opened: {},
        moduleList: []
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loading: false,
            moduleList: props.moduleList
        });
    }

    recordIsValid(record){
        if(this.props.readOnly == true) {
            return true;
        }
        return this.props.annuityList.find(el => el.value === record.annuity);
    }

    deleteModule(record, id) {
        this.setState({
            moduleList: this.state.moduleList.filter(item => item.id !== id)
        }, () => {
            this.props.saveModuleList(this.state.moduleList)
        });
    }

    async update(data) {
        let list = this.state.moduleList;
        var index = this.state.moduleList.map(el => el.id).indexOf(this.state.id_element_opened);
        data.id = this.state.id_element_opened;
        list[index] = data
        this.setState(
            {
                open_drawer_edit_modulo: false,
                open_drawer_edit_unita: false,
                moduleList: list,
                element_opened: {},
                id_element_opened: null
            }, () => { this.props.saveModuleList(this.state.moduleList) }
        );
    }

    async create(data) {
        let id = 0;
        if (this.state.moduleList.length > 0) {
            id = Math.max.apply(Math, this.state.moduleList.map(function (o) { return o.id; })) + 1
        }
        let list = this.state.moduleList;
        data.id = id
        list.push(data);
        this.setState(
            {
                open_drawer_edit_modulo: false,
                moduleList: list,
                element_opened: {},
                id_element_opened: null
            }, () => {
                this.props.saveModuleList(this.state.moduleList);
            });
    }

    open(element, id, action) {
        this.setState({
            create_module: false,
            element_opened: element,
            id_element_opened: id,
        }, () => this.setState({ ['open_drawer_' + action]: true }))
    }

    render() {
        return (
            <div>
                {!this.props.readOnly && <Button disabled={this.props.readOnly} type="primary" className="m5h" icon="plus" onClick={() => this.setState({ open_drawer_edit_modulo: true, create_module: true })}>Aggiungi Modulo</Button>}
                <Table
                    rowKey={(record) => record.id}
                    columns={columns(this)}
                    dataSource={this.state.moduleList}
                />
                <Drawer
                    title="MODULO DIDATTICO"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_modulo: false, element_opened: null })}
                    visible={this.state.open_drawer_edit_modulo}
                    width={window.innerWidth - (window.innerWidth / 100 * 5)}
                    destroyOnClose={true}
                >
                    <EditModuliDidattici
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_modulo: false })}
                        moduloDidattico={this.state.element_opened}
                        idModulo={this.state.id_element_opened}
                        create={this.state.create_module}
                        onUpdate={(data) => {
                            this.update(data);
                        }}
                        onCreate={(data) => this.create(data)}
                        readOnly={this.props.readOnly}
                    />
                </Drawer>
                <Drawer
                    title="ARTICOLAZIONE DEL MODULO IN UNITA DIDATTICHE"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_unita: false, element_opened: null })}
                    visible={this.state.open_drawer_edit_unita}
                    width={window.innerWidth - (window.innerWidth / 100 * 5)}
                    destroyOnClose={true}
                >
                     <UnitaDidatticheTable
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_unita: false })}
                        modulo = {this.state.element_opened}
                        unitList={this.state.element_opened?.unitList}
                        saveUnitaDidatticaList={(data) => this.update(data)}
                />

                </Drawer>
            </div>
        );
    }
}
export default ModuliDidattici;