import React, { Component } from 'react';

import { Button } from 'antd';

import ModelServices from '@accreditamento/services/Nomina';


import FileSaver from 'file-saver';




class DownloadDichiarazioneDisponibilitaIspettore extends Component {

  state = {
    loading: false,
    downloading: false
  }


  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, "DichiarazioneDisponibilità.odt");

    this.setState({loading: false, downloading: false})

  }



  render() {
    return <div>
      <Button size="small" disabled={this.state.loading} loading={this.state.downloading} className="m5h" type="secondary" icon="file-word" onClick={() => {
        this.setState({loading: true, downloading: true},()=>{
          ModelServices.download_dichiarazione_disponibilita(
            this.props.accreditamento.id,
            this.props.nomina.id,
            (res) => {
              this.setState({loading: false, downloading: false});
              this.saveBlob(res.data)
            },
            () => this.setState({loading: false, downloading: false})
          )
        });

      }}>Scarica formato Odt</Button>
    </div>
  }
}

export default DownloadDichiarazioneDisponibilitaIspettore;
