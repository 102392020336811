export default {
  stato: {
    bozza: 0,
    compilata: 1,
    inviata: 2,
    vincitore: 3,
    non_vincitore: 4,

  },
  /**
   * mappa gli stati flusso per dare un colore al pallino per utente
   * @type {Object}
   */
  pallini: {
    ente: {
      green: [0, 1],
      red: [3],
      orange: [2]
    },
    dirigente: {
      green: [],
      red: [3],
      orange: [0, 1]
    },
    istruttore: {
      green: [2],
      red: [],
      orange: []
    },
    ispettore: {
      green: [],
      red: [],
      orange: []
    }
  },

  tipo_finanziamento: {
    finanziato: 1,
    autofinanziato: 2
  },

  getStatoLabel: (domanda) => {
    let label = '';
    let stato = domanda.stato || domanda;
    switch (stato) {
      case 0: label = 'Bozza'; break;
      case 1: label = 'Compilata'; break;
      case 2: label = 'Inviata'; break;
      case 3: label = 'Vincitore'; break;
      case 4: label = 'Non vincitore'; break;
      default: break;
    }
    return label;
  },

  stato_procedura: {
    attivo: 0,
    aperto: 1,
    chiuso: 2
  },

  stato_progetto: {
    registrato: 0,
    predisposizione: 1,
    presentato: 2,
    assegnato: 3,
    istruttoria: 4,
    avvio_sospeso: 5,
    rigettato: 6,
    avvio_progetto: 7,
    iscrizioni_aperte: 8,
    iscrizioni_chiuse: 9,
    erogazione: 10,
    completato: 11,
    revoca_corso: 12,
    sospensione_corso: 13,
    istruttoria_rup: 14,
    istruttoria_coordinatore: 15,
  },

  stato_flusso_progetto: {
    registrato: 0,
    predisposizione: 1,
    compilato: 2,
    firmato: 3,
    presentato: 4,
    assegnato: 5,
    istruttoria: 6,
    assegnato_rup: 35,
    assegnato_coordinatore: 36,
    audit_iniziato: 7,
    sospeso: 8,
    integrazioni: 9,
    comunicazione_ostativa_richiesta: 10,
    comunicazione_ostativa_inviata: 11,
    controdeduzioni_inviate: 12,
    rigetto_predisposto: 13,
    rigettato: 14,
    avvio_progetto_predisposto: 15,
    registrazione_necessaria: 16,
    preparazione_registrazioni: 17,
    approvazione_registrazioni: 18,
    registrazioni_confermate: 19,
    attesa_apertura_iscrizioni: 20,
    registrazioni_aperte: 21,
    corso_in_preparazione: 22,
    corso_pronto_all_avvio: 23,
    corso_in_erogazione: 24,
    ispezione_necessaria: 25,
    attesa_ispettori: 26,
    progetto_completato: 27,
    visita_ispettiva: 28,
    verbale_firmato: 29,
    attesa_esito: 30,
    corso_sospeso: 31,
    revoca_corso: 32,
    conferma_sospeso: 33,
    conferma_revoca: 34,
    comincia_audit: 35,
    assegna_presidente: 37,
    accetta_incarico: 36
  },

  stato_flusso_esame: {
    cerca_rdp_attestati: 2,
    comincia_audit: 35,
    firma_presidente: 15,
    firma_dirigente: 19
  },

  /**
   * mappa gli stati flusso del progetto per dare un colore al pallino per utente
   * @type {Object}
   */

  getPallino(user, c, stato) {

    let pallini = {
      dirigente: {
        green: [this.stato_flusso_progetto.presentato,
        this.stato_flusso_progetto.sospeso,
        this.stato_flusso_progetto.comunicazione_ostativa_richiesta,
        this.stato_flusso_progetto.rigetto_predisposto,
        this.stato_flusso_progetto.avvio_progetto_predisposto,
        this.stato_flusso_progetto.corso_in_erogazione,
        this.stato_flusso_progetto.ispezione_necessaria,
        this.stato_flusso_progetto.corso_sospeso,
        this.stato_flusso_progetto.revoca_corso,
        this.stato_flusso_progetto.conferma_revoca,
        this.stato_flusso_progetto.approvazione_registrazioni,
        this.stato_flusso_progetto.conferma_sospeso,
        this.stato_flusso_progetto.comincia_audit
        ],
        red: [this.stato_flusso_progetto.assegnato,
        this.stato_flusso_progetto.istruttoria,
        this.stato_flusso_progetto.audit_iniziato,
        this.stato_flusso_progetto.integrazioni,
        this.stato_flusso_progetto.comunicazione_ostativa_inviata,
        this.stato_flusso_progetto.controdeduzioni_inviate,
        this.stato_flusso_progetto.registrazione_necessaria,
        this.stato_flusso_progetto.preparazione_registrazioni,
        this.stato_flusso_progetto.registrazioni_chiuse,
        this.stato_flusso_progetto.attesa_ispettori,
        this.stato_flusso_progetto.visita_ispettiva,
        this.stato_flusso_progetto.verbale_firmato,
        this.stato_flusso_progetto.attesa_esito
        ],
      },

      istruttore: {
        green: [this.stato_flusso_progetto.assegnato,
        this.stato_flusso_progetto.istruttoria,
        this.stato_flusso_progetto.audit_iniziato,
        this.stato_flusso_progetto.controdeduzioni_inviate,
        this.stato_flusso_progetto.preparazione_registrazioni,
        this.stato_flusso_progetto.attesa_esito
        ],
        red: [this.stato_flusso_progetto.sospeso,
        this.stato_flusso_progetto.integrazioni,
        this.stato_flusso_progetto.comunicazione_ostativa_richiesta,
        this.stato_flusso_progetto.comunicazione_ostativa_inviata,
        this.stato_flusso_progetto.rigetto_predisposto,
        this.stato_flusso_progetto.avvio_progetto_predisposto,
        this.stato_flusso_progetto.registrazione_necessaria,
        this.stato_flusso_progetto.registrazioni_chiuse,
        this.stato_flusso_progetto.ispezione_necessaria,
        this.stato_flusso_progetto.attesa_ispettori,
        this.stato_flusso_progetto.visita_ispettiva,
        this.stato_flusso_progetto.verbale_firmato,
        this.stato_flusso_progetto.approvazione_registrazioni
        ],
      },
      ente: {
        green: [this.stato_flusso_progetto.registrato,
        this.stato_flusso_progetto.predisposizione,
        this.stato_flusso_progetto.compilato,
        this.stato_flusso_progetto.firmato,
        this.stato_flusso_progetto.integrazioni,
        this.stato_flusso_progetto.comunicazione_ostativa_inviata,
        this.stato_flusso_progetto.registrazione_necessaria,
        this.stato_flusso_progetto.registrazioni_chiuse,
        this.stato_flusso_progetto.verbale_firmato,
        this.stato_flusso_progetto.registrazioni_confermate,
        this.stato_flusso_progetto.corso_in_preparazione
        ],
        red: [this.stato_flusso_progetto.presentato,
        this.stato_flusso_progetto.assegnato,
        this.stato_flusso_progetto.istruttoria,
        this.stato_flusso_progetto.audit_iniziato,
        this.stato_flusso_progetto.sospeso,
        this.stato_flusso_progetto.comunicazione_ostativa_richiesta,
        this.stato_flusso_progetto.controdeduzioni_inviate,
        this.stato_flusso_progetto.rigetto_predisposto,
        this.stato_flusso_progetto.avvio_progetto_predisposto,
        this.stato_flusso_progetto.preparazione_registrazioni,
        this.stato_flusso_progetto.attesa_esito,
        this.stato_flusso_progetto.corso_sospeso,
        this.stato_flusso_progetto.revoca_corso,
        this.stato_flusso_progetto.approvazione_registrazioni,
        this.stato_flusso_progetto.conferma_sospeso
        ],
      },
      ispettore: {
        green: [this.stato_flusso_progetto.ispezione_necessaria,
        this.stato_flusso_progetto.attesa_ispettori,
        this.stato_flusso_progetto.visita_ispettiva],
        red: [],
      },
      presidente: {
        green: [this.stato_flusso_esame.firma_presidente,this.stato_flusso_progetto.assegna_presidente],
        red: [],
      },
      funzionario: {
        green: [this.stato_flusso_progetto.accetta_incarico,this.stato_flusso_esame.cerca_rdp_attestati],
        red: [],
      },
      rdpAttestati: {
        green: [this.stato_flusso_esame.comincia_audit],
        red: [],
      },
      direttore: {
        green: [this.stato_flusso_esame.firma_dirigente],
        red: [],
      }
    }

    return pallini[user][c].indexOf(stato);

  },


  tipo_media: {
    sottodominio_dichiarazione_accettazione_firmata: 'dichiarazione_accettazione_firmata',
    sottodominio_comunicazione_ostativa: 'comunicazione_ostativa_istruttore',
    sottodominio_decreto_autorizzativo: 'decreto_autorizzativo',
    sottodominio_richiesta_pubblicizzazione_corso: 'richiesta_pubblicizzazione_corso'
  },

  getProgettoLabel: (stato, isEnte) => {
    let label = '';
    switch (stato) {
      case 0: label = 'Registrato'; break;
      case 1: label = 'In predisposizione'; break;
      case 2: label = 'Presentato'; break;
      case 3: label = isEnte ? 'Presentato' : 'Assegnato'; break;
      case 4: label = isEnte ? 'Presentato' : 'In istruttoria'; break;
      case 5: label = 'Avvio sospeso'; break;
      case 6: label = 'Rigettato'; break;
      case 7: label = 'Avvio'; break;
      case 8: label = 'Iscrizioni aperte'; break;
      case 9: label = 'Iscrizioni chiuse'; break;
      case 10: label = 'Erogazione'; break;
      case 11: label = 'Completato'; break;
      case 12: label = 'Revoca corso'; break;
      case 13: label = 'Sospensione corso'; break;
      case 14: label = 'Assegnato al RdP'; break;
      case 15: label = 'Assegnato al Funzionario'; break;
      case 16: label = 'Assegnato al Presidente'; break;
      default: break;
    }
    return label;
  },

  getMateriaLabel: (id) => {
    let label = '';
    switch (id) {
      case 1: label = 'Programmi di base'; break;
      case 2: label = 'Alfabetizzazione e competenza matematica'; break;
      case 3: label = 'Sviluppo personale'; break;
      case 4: label = 'Insegnamento e formazione'; break;
      case 5: label = "Scienze dell'educazione"; break;
      case 6: label = 'Arti figurative'; break;
      case 7: label = 'Musica e arti dello spettacolo'; break;
      case 8: label = 'Tecniche audio-visive e produzioni mediali'; break;
      case 9: label = 'Design'; break;
      case 10: label = "Artigianato"; break;
      case 11: label = 'Religione e teologia'; break;
      case 12: label = 'Lingue e culture straniere'; break;
      case 13: label = 'Madrelingua'; break;
      case 14: label = 'Storia, filosofia e materie collegate'; break;
      case 15: label = "Scienze sociali e del comportamento"; break;
      case 16: label = 'Giornalismo e reportage'; break;
      case 17: label = "Biblioteconomia, scienze dell'informazione e archivistica"; break;
      case 18: label = "Commercio all'ingrosso e al dettaglio"; break;
      case 19: label = 'Marketing e pubblicità '; break;
      case 20: label = "Finanza, credito e assicurazioni"; break;
      case 21: label = 'Contabilità  e fisco'; break;
      case 22: label = 'Gestione e amministrazione aziendale'; break;
      case 23: label = "Segreteria e lavoro d'ufficio"; break;
      case 24: label = 'Mondo del lavoro'; break;
      case 25: label = "Diritto"; break;
      case 26: label = 'Scienze della vita'; break;
      case 27: label = "Scienze fisiche e naturali"; break;
      case 28: label = "Matematica e statistica"; break;
      case 29: label = 'Informatica '; break;
      case 30: label = "Uso del computer"; break;
      case 31: label = 'Meccanica e lavorazione dei metalli'; break;
      case 32: label = 'Elettricità  ed energia'; break;
      case 33: label = 'Elettronica e automazione'; break;
      case 34: label = 'Chimica e chimica dei processi'; break;
      case 35: label = "Veicoli a motore, navi e aeromobili"; break;
      case 36: label = 'Trasformazione degli alimenti'; break;
      case 37: label = 'Tessili, abbigliamento, calzature, cuoio e pelle'; break;
      case 38: label = 'Materiali (legno, carta, plastica, vetro)'; break;
      case 39: label = 'Attività  minerarie ed estrattive'; break;
      case 40: label = "Architettura e urbanistica"; break;
      case 41: label = 'Edilizia e ingegneria civile'; break;
      case 42: label = 'Produzione agricola e animale'; break;
      case 43: label = 'Giardinaggio e orticoltura'; break;
      case 44: label = 'Silvicoltura'; break;
      case 45: label = "Pesca"; break;
      case 46: label = 'Veterinaria'; break;
      case 47: label = 'Medicina'; break;
      case 48: label = 'Servizi medici'; break;
      case 49: label = 'Infermieristica'; break;
      case 50: label = "Odontoiatria"; break;
      case 51: label = "Servizi per l'infanzia e per i giovani"; break;
      case 52: label = 'Assistenza sociale, consulenza e orientamento'; break;
      case 53: label = 'Servizi alberghieri e della ristorazione'; break;
      case 54: label = 'Viaggi, turismo e tempo libero'; break;
      case 55: label = "Sport"; break;
      case 56: label = 'Servizi per la casa'; break;
      case 57: label = 'Acconciatura ed estetica'; break;
      case 58: label = 'Trasporti'; break;
      case 59: label = "Protezione dell'ambiente"; break;
      case 60: label = "Tutela della proprietà  privata e della persona"; break;
      case 61: label = 'Salute e sicurezza sul luogo di lavoro'; break;
      case 62: label = 'Difesa'; break;
      case 63: label = 'Programmi estesi - Discipline artistiche'; break;
      case 64: label = 'Programmi estesi - Attività  imprenditoriali e amministrazione aziendale'; break;
      case 65: label = "Programmi estesi - Informatica"; break;
      case 66: label = 'Programmi estesi - Ingegneria e professioni collegate'; break;
      case 67: label = 'Programmi estesi - Attività  manifatturiere e di trasformazione'; break;
      case 68: label = 'Programmi estesi - Architettura ed edilizia'; break;
      case 69: label = 'Programmi estesi - Agricoltura'; break;
      case 70: label = "Sconosciuto/non specificato"; break;
      case 71: label = "Supporto all'attuazione dell'Agenda digitale"; break;
      case 72: label = 'Supporto alla semplificazione ed alla riduzione degli oneri regolatori'; break;
      case 73: label = 'Legalità '; break;
      case 74: label = 'Attuazione del codice degli appalti'; break;
      case 75: label = "Sviluppo dei processi organizzativi"; break;
      case 76: label = 'Sviluppo della gestione delle risorse umane'; break;
      case 77: label = 'Sviluppo di reti fra amministrazioni'; break;
      case 78: label = 'Rafforzamento del partenariato'; break;
      case 79: label = 'Miglioramento della qualità  dei servizi per cittadini e imprese'; break;
      case 80: label = "Sviluppo dei processi e degli strumenti di gestione della performance"; break;
      default: break;
    }
    return label;
  },

  getTipoLezioneLabel: (id) => {
    let label = '';
    switch (id) {
      case 1: label = 'Aula'; break;
      case 2: label = 'Pratica'; break;
      case 3: label = 'Stage'; break;
      case 4: label = 'E-learning - sincrono'; break;
      case 5: label = 'E-learning - asincrono'; break;
      default: break;
    }
    return label;
  },

  tipo_lezione: {
    aula: 1,
    pratica: 2,
    stage: 3,
    el_sincrono: 4,
    el_asincrono: 5
  },

  stato_iscrizione: {
    bozza: 0,
    compilata: 1,
    inviata: 2,
    idoneo: 3,
    non_idoneo: 4,
    abbandonato: 5,
    in_attesa: 6,
    iscritto: 7,
    attesa_giustificativo:8,
    espulso: 9,
  },

  stato_flusso_iscrizione: {
    invitato: 0,
    sospeso: 1,
    accettato: 2,
    convocazione_inviata: 3,
    convocazione_sospesa: 4,
    rifiutato: 5,
    iscritto: 6,
    attesa_giustificativo: 7,
  },

  getStatoIscrizioneLabel(stato) {
    let label = '';
    switch (stato) {
      case this.stato_iscrizione.bozza:
        label = 'Bozza'; break;
      case this.stato_iscrizione.compilata:
        label = 'Compilata'; break;
      case this.stato_iscrizione.inviata:
        label = 'Inviata'; break;
      case this.stato_iscrizione.idoneo:
        label = 'Idoneo'; break;
      case this.stato_iscrizione.non_idoneo:
        label = 'Non idoneo'; break;
      case this.stato_iscrizione.abbandonato:
        label = 'Abbandonato'; break;
      case this.stato_iscrizione.in_attesa:
        label = 'In attesa'; break;
      case this.stato_iscrizione.iscritto:
        label = 'Iscritto'; break;
      case this.stato_iscrizione.espulso:
        label = 'Espulso'; break;
      case this.stato_iscrizione.attesa_giustificativo:
        label = 'Sospeso'; break;
      default: break;
    }
    return label;
  },

  getStatoFlussoIscrizioneLabel(stato_flusso) {
    let label = '';
    switch (stato_flusso) {
      case this.stato_flusso_iscrizione.invitato:
        label = 'Invitato'; break;
      case this.stato_flusso_iscrizione.sospeso:
        label = 'Invito sospeso'; break;
      case this.stato_flusso_iscrizione.accettato:
        label = 'Invito accettato'; break;
      case this.stato_flusso_iscrizione.convocazione_inviata:
        label = 'Convocazione inviata'; break;
      case this.stato_flusso_iscrizione.convocazione_sospesa:
        label = 'Convocazione sospesa'; break;
      case this.stato_flusso_iscrizione.rifiutato:
        label = 'Rifiutato'; break;
      case this.stato_flusso_iscrizione.iscritto:
        label = 'Contratto firmato'; break;
      case this.stato_flusso_iscrizione.attesa_giustificativo:
        label = 'Sospeso'; break;
      default: break;
    }
    return label;
  },

  stato_docente: {
    attivo: 0,
    sostituito: 1,
  },

  getStatoDocenteLabel(stato) {
    let label = '';
    switch (stato) {
      case this.stato_docente.attivo:
        label = 'Attivo'; break;
      case this.stato_docente.sostituito:
        label = 'Sostituito'; break;
      default: break;
    }
    return label;
  }

}
