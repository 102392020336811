import React, {Component} from 'react';
//import { connect } from "react-redux";

import {Icon} from 'antd';
import CommonForm from '@common/components/form';

import format from '@common/utils/formatters/_';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'datepicker',
              label: 'Dal',
              name: 'dal',
              props: {
                size: 'large',
                style: {width: '100%'},
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'datepicker',
              label: 'Al',
              name: 'al',
              props: {
                size: 'large',
                style: {width: '100%'},
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Organizzazione',
              type_string: 'text', // field type
              name: 'organizzazione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Posizione',
              type_string: 'text', // field type
              name: 'posizione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Settore',
              type_string: 'text', // field type
              name: 'id_settore',
              options_props: 'settori',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Tipo rapporto di lavoro',
              type_string: 'text', // field type
              name: 'id_tipo_rapporto_lavoro',
              options_props: 'tipo_rapporto_lavoro',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Attivita',
              type_string: 'text', // field type
              name: 'attivita',
              mode: 'tags',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
};


class EsperienzaProfessionaleForm extends Component {

  state = {
    loading: false
  };





  confirm(data) {
    this.setState({loading: true});
    this.props.submit( format.replaceArrayToString( ['attivita'], format.replaceFormDatesOut( ['dal', 'al'], data ) ), ()=>this.setState({loading: false}) );
  }

  getValues() {
    let v = this.props.values;
    v.attivita = v.attivita || []
    return v;
  }

  reset() {
    this.formRef.props.form.resetFields();
  }

  render () {
    return (
      <div>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={ this.getValues() }
            submitted={(dati) => this.confirm(dati)}
            settori={this.props.GlobalStore.stubs.settori}
            tipo_rapporto_lavoro={this.props.GlobalStore.stubs.tipo_rapporto_lavoro}
          />
      </div>
    );
  }
}

export default EsperienzaProfessionaleForm