import React from 'react';
import { Button, Popconfirm } from 'antd';
import PagamentoService from './PagamentoServiceDiscente';
import PropTypes from 'prop-types';
import { useState } from 'react';
import _ from "lodash";

const PagamentoButtonDiscente = props => {

    const {idProgetto, rispostaPagamentoUrl} = props;

    const [processing, setProcessing] = useState(false);
    
    return (     
        <Popconfirm placement="top" title="Confermi di voler procedere al pagamento del bollo virtuale?"
            onConfirm={ () => paga(idProgetto, rispostaPagamentoUrl, setProcessing) }
            okText="Si" cancelText="No"
        >
            <Button
                loading={processing}
                className="m5h m5w" 
                type="primary" 
                icon="undo"                
            >
                Paga            
            </Button>
        </Popconfirm>
    );
}

export default PagamentoButtonDiscente;

PagamentoButtonDiscente.propTypes = {
    idProgetto: PropTypes.string.isRequired,
    rispostaPagamentoUrl: PropTypes.string,
    verifyEsame: PropTypes.func.isRequired
}

function paga(idProgetto, rispostaPagamentoUrl, setProcessing){
    setProcessing(true);
    PagamentoService.inviaPagamento(idProgetto, rispostaPagamentoUrl)
    .then( response => {
        const redirectUrl = _.get(response.data, 'data.redirectUrl');
        window.location.assign(redirectUrl);
    })
    .catch( error => setProcessing(false) );
    
}