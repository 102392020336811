import React, { Component } from "react";
import { Button, Card, Popconfirm, message, Tooltip, Row, Icon } from "antd";

import moment from "moment";

import checkPermission from "@common/utils/check/permissions";
import FilterForm from "@common/components/filterForm";

import TableComponent from "@common/components/table";

import Esami from "../services/Esami";

import affidamentoStatic from "@common/utils/static/affidamento_esami";

import _ from "@common/utils/formatters/_";

const renderActions = (element, component) => {
  return (
    <div style={{ display: "inline-block" }}>
      {component.viewCommissioniPermission() && (
        <Tooltip placement="top" title="Visualizza scheda di commissione">
          <Button
            className="m2w"
            size="small"
            shape="circle"
            icon="team"
            onClick={() =>
              component.props.history.push(
                "/affidamento/commissioni/" + element.id
              )
            }
          />
        </Tooltip>
      )}

      {component.createEsamePermission() && element.esame == null ? (
        <Tooltip placement="top" title="Nuova scheda esame">
          <Button
            className="m2w"
            size="small"
            shape="circle"
            icon="plus"
            onClick={() => component.addNew(element.id)}
          />
        </Tooltip>
      ) : null}

      {component.viewEsamePermission() && element.esame != null ? (
        <Tooltip placement="top" title="Visualizza scheda di esame">
          <Button
            className="m2w"
            size="small"
            shape="circle"
            icon="solution"
            onClick={() =>
              component.props.history.push(
                "/affidamento/esami/" + element.esame.id
              )
            }
          />
        </Tooltip>
      ) : null}

      {component.deleteEsamePermission() && element.esame != null ? (
        <Tooltip placement="top" title="Elimina scheda di esame">
          <Popconfirm
            disabled={component.state.loading}
            placement="leftTop"
            title="Sicuro di voler rimuovere la scheda di esame?"
            onConfirm={() => component.deleteProgetto(element.esame.id)}
            okText="Si"
            cancelText="No"
          >
            <Button
              disabled={component.state.loading}
              className="m2w"
              size="small"
              shape="circle"
              icon="delete"
            />
          </Popconfirm>
        </Tooltip>
      ) : null}
    </div>
  );
};

const filterFormModel = (component) => {
  const isEnte = component.props.UserStore.user.role.some(
    (r) => r.name !== "Ente"
  );
  const isDiscente = component.props.UserStore.user.role.some(
    (r) => r.name === "Discente"
  );

  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: isEnte ? 6 : 8,
            items: [
              {
                // TODO
                type: "select",
                label: "Stato",
                name: "stato",
                options: [
                  {
                    value: "-1",
                    label: "Tutti",
                  },
                  {
                    value: "0",
                    label: "Registrato",
                  },
                  {
                    value: "1",
                    label: "In predisposizione",
                  },
                  {
                    value: "2",
                    label: "Presentato",
                  },
                  {
                    value: "3",
                    label: "Assegnato",
                  },
                  {
                    value: "4",
                    label: "In istruttoria",
                  },
                  {
                    value: "5",
                    label: "Avvio sospeso",
                  },
                  {
                    value: "6",
                    label: "Rigettato",
                  },
                  {
                    value: "7",
                    label: "Avvio",
                  },
                  {
                    value: "8",
                    label: "Iscrizioni aperte",
                  },
                  {
                    value: "10",
                    label: "In erogazione",
                  },
                  {
                    value: "11",
                    label: "Completato",
                  },
                  {
                    value: "12",
                    label: "Revoca corso",
                  },
                  {
                    value: "13",
                    label: "Sospensione corso",
                  },
                ],
                showIf: [
                  {
                    type: "custom_func",
                    func: () => {
                      return !checkPermission.hasPermission(
                        component.props.UserStore.user,
                        ["viewProgettoDiscente"]
                      );
                    },
                  },
                ],
              },
            ],
          },
          !isEnte
            ? {
                span: 6,
                items: [
                  {
                    type: "string",
                    label: "Ente erogatore",
                    name: "ente_erogatore",
                  },
                ],
              }
            : {},
          {
            span: 6,
            items: [
              {
                type: "select",
                label: "Stato domanda",
                name: "stato_iscrizione",
                options: [
                  {
                    value: "",
                    label: "Tutti",
                  },
                  {
                    value: "0",
                    label: "Bozza",
                  },
                  {
                    value: "1",
                    label: "Compilata",
                  },
                  {
                    value: "2",
                    label: "Inviata",
                  },
                  {
                    value: "3",
                    label: "Idoneo",
                  },
                  {
                    value: "4",
                    label: "Non idoneo",
                  },
                  {
                    value: "5",
                    label: "Abbandonato",
                  },
                  {
                    value: "6",
                    label: "In attesa",
                  },
                  {
                    value: "7",
                    label: "Iscritto",
                  },
                  {
                    value: "8",
                    label: "Espulso",
                  },
                ],
                showIf: [
                  {
                    type: "custom_func",
                    func: () => {
                      return checkPermission.hasPermission(
                        component.props.UserStore.user,
                        ["viewProgettoDiscente"]
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "submit",
                type: "submit",
                text: "Cerca",
                btn_type: "primary",
              },
            ],
          },
        ],
      },
    ],
  };
};

const renderStatusIndicator = (record, component) => {
  let user = "";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "manageOwnProgetto",
    ])
  )
    user = "ente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "manageProgetto",
    ])
  )
    user = "dirigente";

  if (checkPermission.hasRole(component.props.UserStore.user, ["Valutatore"]))
    user = "presidente";

    if (checkPermission.hasRole(component.props.UserStore.user, ["FunzionarioCoordinatoreAmministrativo"]))
    user = "funzionario";

    if (checkPermission.hasRole(component.props.UserStore.user, ["RdP Attestati"]))
    user = "rdpAttestati";
    if (checkPermission.hasRole(component.props.UserStore.user, ["DirettoreAmministrativo"]))
    user = "direttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "manageOwnNomina",
    ])
  )
    user = "ispettore";
    console.error(user,"ducati")

  let colors = ["green", "red"];
  let defined_c = "";

  if (user !== "") {
    colors.forEach((c) => {

      if ( record.esame != null &&
        affidamentoStatic.getPallino(user, c, parseInt(record.esame.stato_flusso)) !==
        -1
      )
        defined_c = c;


      if (
        record.esame == null && record.stato_flusso == 27 && checkPermission.hasRole(component.props.UserStore.user, ["DipendenteRegionale"])
      )
      {
        defined_c = "green";
      }

    });
  }

  return defined_c !== "" ? (
    <Tooltip
      placement="topRight"
      title={record.stato_flusso_description["descrizione_" + user]}
    >
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
  ) : (
    <span className={"pallino invisible"}></span>
  );
};

const model = (component) => {
  return {
    with_server_pagination: true,
    columns: [
      {
        title: "Azioni Esame",
        dataIndex: "",
        sorter: false,
        key: "actions",
        //width: "300px",
        render: (element) => (
          <div style={{ MaxWidth: 150 }}>
            {renderStatusIndicator(element, component)}
            {renderActions(element, component)}
          </div>
        ),
      },
      {
        title: "Numero",
        dataIndex: "",
        sorter: false,
        key: "Numero",
        render: (element) => <div>{element.id}</div>,
      },
      {
        title: "Stato",
        dataIndex: "",
        sorter: true,
        key: "stato",
        render: (element) => (
          <div style={{ width: 140 }}>
            {affidamentoStatic.getEsamiLabel(
              element.esame?.stato_flusso ? element.esame?.stato_flusso : null,
              checkPermission.hasPermission(component.props.UserStore.user, [
                "entePermission",
              ])
            )}
          </div>
        ),
      },
      {
        title: "Nome Progetto",
        dataIndex: "",
        sorter: false,
        key: "titolo_progetto",
        render: (element) => (
          <div>
            {element.progetto
              ? element.progetto.titolo_progetto
              : element.titolo}
          </div>
        ),
      },
      {
        title: "Edizione",
        dataIndex: "",
        sorter: false,
        key: "edizione",
        render: (element) => <div>{element.edizione_progetto}</div>,
      },
      !checkPermission.hasPermission(component.props.UserStore.user, [
        "entePermissions",
      ]) && {
        title: "Ente erogatore",
        dataIndex: "",
        sorter: false,
        key: "ente_erogatore",
        render: (element) => <div>{element.ente?.denominazione || ""}</div>,
      },
      {
        title: "Data creazione",
        dataIndex: "",
        sorter: false,
        key: "data_creazione",
        render: (element) => (
          <div style={{ width: 140 }}>
            {element.data_protocollo
              ? moment(element.data_protocollo, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : ""}
          </div>
        ),
      },
    ],
  };
};

class TableEsami extends Component {
  base_url = "/affidamento/esami";
  default_order = "-created_at";

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: [],
    },
    id_commissione: null,
  };

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search);
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);

    //params.expand = ["progetti"].join(",");
    if (checkPermission.hasRole(this.props.UserStore.user, ["Valutatore"])){
      params.stato = 11;
    }

    this.setState({ loading: true });
    if (checkPermission.hasRole(this.props.UserStore.user, ["Ente"])) {
    Esami.list(
      params,
      (res) => {
        let data = _.getResponse(res);
        this.setState(
          {
            loading: false,
            data: data.data.filter(tipo => tipo.stato_flusso === 27 && tipo.esame != null),
          },
          () =>
            this.refs.table_component.updateTableData(
              data.data.filter(tipo => tipo.stato_flusso === 27 && tipo.esame != null ),
              data.current,
              data.total,
              pager
            )
        );
      },
      (e) => {
        this.setState({ loading: false });
      }
    );
  } else {
    Esami.list(
      params,
      (res) => {
        let data = _.getResponse(res);
        this.setState(
          {
            loading: false,
            data: data.data.filter(tipo => tipo.stato_flusso === 27),
          },
          () =>
            this.refs.table_component.updateTableData(
              data.data.filter(tipo => tipo.stato_flusso === 27),
              data.current,
              data.total,
              pager
            )
        );
      },
      (e) => {
        this.setState({ loading: false });
      }
    );
  }
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search);
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  deleteProgetto(id) {
    this.setState({ loading: true }, () => {
      Esami.delete(
        id,
        (res) => {
          message.success("Domanda di Commissione eliminata con successo!");
          this.setState({ loading: false });
          this.refresh();
        },
        () => this.setState({ loading: false })
      );
    });
  }

  viewCommissioniPermission() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, [
        "viewProgetto",
      ]) &&
      !checkPermission.hasPermission(this.props.UserStore.user, [
        "editProgetto",
      ])
    );
  }

  createEsamePermission() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, [
        "viewProgetto",
      ]) &&
      !checkPermission.hasPermission(this.props.UserStore.user, [
        "editProgetto",
      ])
    );
  }

  viewEsamePermission() {
    return (
      (checkPermission.hasPermission(this.props.UserStore.user, [
        "viewProgetto",
      ]) &&
        !checkPermission.hasPermission(this.props.UserStore.user, [
          "editProgetto",
        ])) ||
      checkPermission.hasRole(this.props.UserStore.user, "Ente") ||
      checkPermission.hasRole(this.props.UserStore.user, "EnteAfc") ||
      checkPermission.hasRole(this.props.UserStore.user, "RdP Attestati") ||
      checkPermission.hasRole(this.props.UserStore.user, "Istruttore Attestati")
    );
  }

  deleteEsamePermission() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, [
        "viewProgetto",
      ]) &&
      !checkPermission.hasPermission(this.props.UserStore.user, [
        "editProgetto",
      ])
    );
  }

  addNew(id) {
    this.setState({ loading: true }, () => {
      Esami.createEsame(
        { id_commissione_esame: id },
        (res) => {
          message.success(
            "Nuova Commissione creata con successo, procedere con la compilazione dei dati"
          );
          this.setState({ loading: false, id_commissione: res.data.data.id });
          this.props.history.push("/affidamento/esami/" + res.data.data.id);
        },
        () => this.setState({ loading: false })
      );
    });
  }

  delete(id) {
    this.setState({ loading: true }, () => {
      Esami.delete(
        id,
        (res) => {
          message.success("Formulario eliminato con successo!");
          this.setState({ loading: false });
          this.refresh();
        },
        () => this.setState({ loading: false })
      );
    });
  }

  render() {
    return (
      <div>
        {/*<FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.props.location.search}
          history={this.props.history}
          date_fields={["data_avvio"]}
        />*/}
        <Card className="shadow no-pad" bordered={false}>
          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: "id" }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TableEsami;
