import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';

import TableFormulariCompilati from '../components/TableFormulariCompilati';


@Wrapper({ route: 'FormulariCompilati', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewDomanda'])
class FormulariCompilatiRoute extends Component {

  state = {
    loading: false,
    asked: false,
  }

  renderTitle() {
    return 'Formulari compilati'
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          {!checkPermissions.hasPermission( this.props.UserStore.user, ['dirigenteAmministrativoPermissions','istruttoreAmministrativoPermissions']) ? <p>{this.renderTitle()}</p> : null}
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/affidamento/formulari">Gestione formulari</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <TableFormulariCompilati ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default FormulariCompilatiRoute;
