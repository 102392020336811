import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import {Button, Card, Tooltip} from 'antd';
import TableComponent from '@common/components/table';

import ModelServices from '../../accreditamento/services/Nomina';

import queryString from 'query-string';
import checkPermission from '@common/utils/check/permissions';
import nominaStatic from '@common/utils/static/nomina';

import _ from '@common/utils/formatters/_';

const renderPallino = (record, component) => {

  let user = 'ente';

  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';

  let colors = ['green', 'red', 'orange'];
  let defined_c = '';
  colors.forEach(c => {
    if (nominaStatic.pallini[user][c].indexOf(parseInt(record.stato)) !== -1) defined_c = c;
  })

  return (defined_c !== '') ? <Tooltip placement="topRight" title={record.stato_flusso_formatted['descrizione_'+user]}>
      <span className={"pallino "+defined_c}></span>
    </Tooltip> : <span className={"pallino invisible"}></span>;
}


const getStato = (record, component) => {
  let stato = 'ente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';


  return record.stato_flusso_formatted['stato_'+stato]
}

const model = (component) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns:[
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{width: 200}}>
          {
            renderPallino(element, component)
          }
          <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push(component.getNominaUrl(element))} />
        </div>,
      },
      {
        title: 'Num. protocollo',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'numero_protocollo',
        render: element => <div style={{width: 50}}>#{element.numero_protocollo}</div>,
      },
      {
        title: 'Ente',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: 'accreditamento',
        render: element => <div>{element.accreditamento? element.accreditamento.ente.denominazione : element.ispezione.progetto?.ente.denominazione }</div>,
      },
      {
        title: 'Stato',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: 'stato',
        render: element => <div>{getStato(element, component)}</div>,
      },
      {
        title: 'Protocollo accreditamento',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: 'id_accreditamento',
        render: element => element.accreditamento &&  <div>
          <Link to={component.getAccreditamentoUrl(element)}>#{element.accreditamento?.numero_protocollo}</Link>
        </div>,
      },
      {
        title: 'Protocollo progetto',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: 'id_progetto',
        render: element => element.ispezione.progetto && <div>
          <Link to={component.getProgettoUrl(element)}>#{element.ispezione.progetto?.numero_protocollo}</Link>
        </div>,
      }
    ]
  }
}


class ListModel extends Component {

  default_order = '-updated_at'

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    roles: [],
    data: {
      total: 0,
      current: 1,
      data: []
    }
  }

  componentDidMount () {
    this.callAndUpdate(this.props)
  }

  getNominaUrl(item) {
    return item.accreditamento? "/accreditamento/"+item.id_accreditamento+"/nomina/" + item.id : "/affidamento/"+item.ispezione.id_progetto + "/nomina/" + item.id
  }

  getAccreditamentoUrl(item) {
    return "/accreditamento/scheda/"+item.id_accreditamento
  }

  getProgettoUrl(item) {
    return "/affidamento/progetto/"+item.ispezione.id_progetto
  }

  getBaseUrl() {
    return "/nomina";
  }

  setDefaultSearchParams(str) {
    if(checkPermission.hasPermission(this.props.UserStore.user, ['ispettorePermissions'])) {
      str.id_ispettore = this.props.UserStore.user.id;
      str.stato_start = 3;
      str.stato_end = 9;
      //str.media = 20;
    } else {
      str.id_user = this.props.UserStore.user.id;
    }

    str.with =  [
      'ispezione' , 'ispezione.progetto'
    ].join(',')

    return str
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(props, this.default_order);

    params = this.setDefaultSearchParams(params);

    this.setState({loading: true});
    ModelServices.list ( params, (res) => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, ()=>this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({loading: false});

    })
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  UNSAFE_componentWillReceiveProps(newProps) {

    if(newProps.location.search !== this.props.location.search) {
      this.callAndUpdate(newProps.location.search)
    }

  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh(){
    this.callAndUpdate(this.props)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    try {
      let q_s = queryString.parse(this.props.location.search);
      // brutto forte
      if(obj.sort) {
        if( q_s.sort && obj.sort ) {
          if(q_s.sort === obj.sort) {
            if(q_s.sort[0] === "") {
              q_s.sort = q_s.sort.replace("-","");
            } else {
              q_s.sort = "-"+q_s.sort
            }
          } else {
            q_s = {...q_s, ...obj}
          }
        } else {
          q_s = {...q_s, ...obj}
        }
      } else {
        q_s = {...q_s, ...obj}
      }



      this.props.history.push(this.getBaseUrl()+'?'+queryString.stringify( q_s ) )
    } catch(e) {
      //console.log('errore cambio url',e);
    }

  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  async updateRecord(id) {
    await this.setState({editing_id: id});
    this.setState({open_drawer: true});
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  deleteRecord(id) {
    ModelServices.delete(id, (res) => this.refresh(), (err) => null)
  }


  /**
   * Record aggiornato in form
   * @return {[type]} [description]
   */
  updatedRecord() {
    this.setState({open_drawer: false, editing_id: false});
    this.callAndUpdate(this.props);
  }

  render () {
    return (
      <div>
        <Card
        className="shadow no-pad"
        bordered={false}
        >
          <TableComponent
            data={this.state.data} model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}
export default ListModel;
