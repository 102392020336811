import React, {Component} from 'react';
import moment from 'moment';
import CommonForm from '@common/components/form';

import accreditamentoStatic from '@common/utils/static/accreditamento';
import eventBroker from '@common/utils/services/EventBroker';

import ComuniService from '@common/services/locations/Comuni';

import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';

import { Icon, message } from 'antd';

const formLayout = (component) => {
  return {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
        cols: [
          {
            span: 14,
            items: [
              {
                type: 'input',
                label: 'Via',
                type_string: 'text', // field type
                name: 'via',
                props: {
                  // size: 'large'
                  disabled: component.state.disabledInput,
                  prefix: (
                    <Icon type="pushpin" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: 'input',
                label: 'N',
                type_string: 'text', // field type
                name: 'ncivico',
                props: {
                  // size: 'large',
                  placeholder: 'N.',
                  disabled: component.state.disabledInput,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: 'cap',
                props: {
                  //size: 'large',
                  placeholder: 'Cap',
                  maxLength: 5,
                  disabled: component.state.disabledInput,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9]{5,5}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '==',
                    value: 1,
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'input',
                label: 'Cap',
                type_string: 'text', // field type
                name: 'cap',
                props: {
                  //size: 'large',
                  placeholder: 'Cap',
                  maxLength: 10,
                  disabled: component.state.disabledInput,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '[0-9a-zA-Z]{4,10}',
                    message: 'Cap non valido',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato',
                    operator: '!=',
                    value: 1,
                  },
                ],
              },
            ],
          }
        ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'select',
              label: 'Nazione',
              name: 'stato',
              props: {
                //size: 'large',
                //disabled: component.props.readOnly,
                disabled: true,
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
              options_props: 'nazioni',
              onChange: 'selectedStato',
            },
          ],
        },
        {
          span: 4,
          items: [
            {
              type: 'select',
              label: 'Provincia',
              name: 'provincia',
              props: {
                // size: 'large',
                allowClear: true,
                showSearch: true,
                disabled: component.state.disabledInput,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'requiredIf',
                  error_message: 'Campo obbligatorio',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                },
              ],
              options_props: 'province',
              onChange: 'selectedProvincia',
            },
          ],
        },
        {
          span: 14,
          items: [
            {
              type: 'select',
              label: 'Comune',
              name: 'comune',
              props: {
                showSearch: true,
                disabled: component.state.disabledInput,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              validations: [
                {
                  type: 'requiredIf',
                  error_message: 'Campo obbligatorio',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                },
              ],
              showIf: [
                {
                  type: 'field_validation',
                  field: 'stato',
                  operator: '==',
                  value: 1,
                },
                {
                  type: 'field_validation',
                  field: 'provincia',
                  operator: '!=',
                  value: null,
                },
              ],
              options_props: 'comuni'
            },
          ],
        },
      ],
    },
    {
              cols: [
                  {
                      span: 12,
                      items: [
                          {
                              type: 'input',
                              label: 'Telefono',
                              type_string: 'text', // field type
                              name: 'telefono',
                              props: {
                                  // size: 'large',
                                  //disabled: component.state.disabledInput,
                                  disabled: false,
                                  prefix: <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                              },
                              validations: [
                                  {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio'
                                  },
                                  {
                                    pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                    message: 'Telefono non valido'
                                  },
                              ],
                          },
                      ]
                  },
                  {
                      span: 12,
                      items: [
                          {
                              type: 'input',
                              label: 'Cellulare',
                              type_string: 'text', // field type
                              name: 'cellulare',
                              props: {
                                  // size: 'large',
                                  //disabled: component.state.disabledInput,
                                  disabled: false,
                                  prefix: <Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />
                              },
                              validations: [
                                  {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio'
                                  },
                                  {
                                    pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                    message: 'Telefono non valido'
                                  }
                              ],
                          },
                      ]
                  }
              ],
    },
    {
        cols: [
            {
                span: 12,
                items: [
                    {
                        type: 'input',
                        label: 'Fax',
                        type_string: 'text', // field type
                        name: 'fax',
                        props: {
                            // size: 'large',
                            disabled: component.state.disabledInput,
                            prefix: <Icon type="file" style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                    },
                ]
            },
            {
                span: 12,
                items: [
                    {
                        type: 'input',
                        label: 'Email',
                        type_string: 'text', // field type
                        name: 'email',
                        props: {
                            // size: 'large',
                            disabled: component.state.disabledInput,
                            prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                    },
                ]
            }
        ],
    },

    {
      cols: [
        {
          items: [
            {
              name: 'dati_di_accreditamento_pregresso',
              type: 'title',
              element: 'h4',
              text: 'Dati di accreditamento pregresso',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
              showIf: [
                {
                    type: 'prop_func',
                    func: 'isPregresso'
                }
              ]
            }
          ],
        }
      ],
    },
    {
      cols: [
          {
              span: 12,
              items: [
                  {
                      type: 'input',
                      label: 'Numero repertorio',
                      type_string: 'text', // field type
                      name: 'numero_protocollo',
                      props: {
                        placeholder: 'Numero repertorio'
                      },
                      validations: [
                        {
                          type: 'required',
                          error_message: 'Campo obbligatorio',
                        }
                      ],
                      showIf: [
                        {
                            type: 'prop_func',
                            func: 'isPregresso'
                        }
                      ]
                  }
              ]
          }
      ]
  },
  {
    cols: [
        {
            span: 12,
            items: [
                {
                    type: 'datepicker',
                    label: 'Data repertorio',
                    type_string: 'text', // field type
                    name: 'data_protocollo',

                    props: {
                      style: { width: '100%' },
                      prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />,
                      placeholder: 'Data repertorio'
                    },
                    validations: [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                      {
                        validator: 'max_date'
                      },
                    ],
                    showIf: [
                      {
                          type: 'prop_func',
                          func: 'isPregresso'
                      }
                    ]
                },
            ]
        }
    ]
},
{
  cols: [
      {
          span: 12,
          items: [
            {
              type: 'simple_upload',
              name: 'decreto',
              accept: '.pdf,.p7m',
              action: '/',
              customRequest: 'customUploadRequest',
              fileList: 'files',
              listType: 'text', // text,picture,picture-card
              multiple: false,
              text: "Carica il decreto p7m o pdf firmato",
              props: {
                style: { width: '100%' },
                showUploadList: true
              },
             /* validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                }
              ],*/
              showIf: [
                {
                    type: 'prop_func',
                    func: 'isPregresso'
                }
              ]
            }
          ]
      }
  ]
},



    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn_submit_aula',
              text: 'Salva dati',
              //onClick: 'showDrawer',
              props: {
                  icon: 'save',

                  className: 'bg-green'
              }
            }
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
  };
}


class CreateModel extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    comuni: [],
    disabledInput:false,
    open_drawer: false,
    model: {
      esperienze_nella_funzione: [],
      sedi: []
    }
  }



  getFormLayout() {

  }



  componentDidMount() {
    this.setState({disabledInput: this.props.readOnly});

    if (this.props.sede && this.props.sede.indirizzo && this.props.sede.indirizzo.comune) {
      ComuniService.all(
        { id_provincia: this.props.sede.indirizzo.provincia },
        res => {
          this.setState({
            comuni: res.data.data || []
          },() => {
            this.formRef.props.form.setFieldsValue({ comune: this.props.sede.indirizzo.comune });
          });
        },
        err => null
      );
    }


  }




  updateQueryStr() {

  }

  uploadDecretoPregresso(file){
    this.setState({file_decreto: file});
  }


  async confirm(data) {

    if((this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio ||
      this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo) &&
      (!this.state.fileList || this.state.fileList === null || this.state.fileList.length < 1)){
      message.error("Carica il file del decreto");
      return;
    }

    if(!this.state.loading) {
      await this.setState({loading: true});

      data.id_anagrafica = this.state.model_anagrafica.id;
      let file = null;
      if(this.state.fileList && this.state.fileList.length > 0){
        file = this.state.fileList;
      } 
      
      if(this.props.sede){
        const idSede = { id: this.props.sede.id };
        data = {...idSede, ...data};
      }

      InfrastrutturaServices.create( this.props.accreditamento.id, data, file, ()=>{
        if(this.props.onUpdate)this.props.onUpdate();
        eventBroker.trigger('updateDecretoPregressoInfrastruttura');
        this.setState({loading: false});
      }, () => this.setState({loading: false}) )




    }
  }


  render () {
    return <div>
      <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.props.sede ? { ...this.props.sede.indirizzo, ...this.props.sede.recapiti, stato: 1 } : {stato: 1 }}
          submitted={(dati) => this.confirm(dati)}
          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}
          selectedStato={() => {
            //console.log (this.formRef.props.form);
            this.setState({
              comuni: [],
            }, ()=>{
              this.formRef.props.form.setFieldsValue({ comune: null, provincia: null });
            });
          }}

          max_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            if (value > moment()) cb("La data protocollo non può essere successiva alla data odierna");
            else cb();
          }}

          isPregresso={() => {

            return this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio ||
                   this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo;
          }}

          files={()=>this.state.fileList}

          onRemove={() => {

              this.setState ({
                fileList: [],
              });

            }}

            customUploadRequest={obj => {
              this.setState({
                fileList: [{
                    chiave: 'file',
                    uid: obj.file.uid,
                    name: obj.file.name,
                    file: obj.file,
                    status: 'done',
                }]
              });
            }}


          selectedProvincia={data => {
            //console.log (this.formRef.props.form);

            this.setState({
              selected_provincia: data,
              comuni: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni: res.data.data || []
                  }, ()=>this.formRef.props.form.setFieldsValue({ comune: null }) );
                },
                () => null
              );
            });

          }}

          comuni={this.state.comuni.map(e=>{
            return {
              key: 'residenza_comune_'+e.id,
              value: e.id,
              label: e.comune
            }
          })}


        />
    </div>
  }

}
export default CreateModel;