import BaseRequest from "@common/services/_baseRequest";


export default {

	view: (id_audit, vars = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			vars,
			{},
			null,
			"get",
			'accreditamento/audit/' + id_audit,
			cb,
			ecb || null,
			null
		);
	},

	start: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/audit/' + id + '/start',
			cb,
			ecb || null,
			null
		);
	},

	approve: (id, id_audit, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"post",
			'accreditamento/audit/' + id + '/approve/' + id_audit,
			cb,
			ecb || null,
			null
		);
	},

	integration: (id, id_audit, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/audit/' + id + '/integration/' + id_audit,
			cb,
			ecb || null,
			null
		);
	},

	refuse: (id, id_audit, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/audit/' + id + '/refuse/' + id_audit,
			cb,
			ecb || null,
			null
		);
	},

	confirm_integrazione: (id, id_audit, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/audit/' + id + '/confirm-integrazione/' + id_audit,
			cb,
			ecb || null,
			null
		);
	},

	reject_integrazione: (id, id_audit, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/audit/' + id + '/reject-integrazione/' + id_audit,
			cb,
			ecb || null,
			null
		);
	},


	integrazione_file: (id, id_integrazione, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'accreditamento/audit/' + id + '/integrazione/' + id_integrazione,
			cb,
			ecb || null,
			null
		);
	},

	integrazione_file_upload: (id, id_integrazione, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/audit/' + id + '/integrazione/' + id_integrazione,
			cb,
			ecb || null,
			null
		);
	},

	integrazione_send: (id, id_integrazione, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/audit/' + id + '/integrazione/' + id_integrazione + '/send',
			cb,
			ecb || null,
			null
		);
	},

	uploadIntegration: (id_accreditamento, id_integrazione, data, file, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			file,
			"post",
			'accreditamento/audit/' + id_accreditamento + '/integrazione/' + id_integrazione + '/upload-integration',
			cb,
			ecb || null,
			null
		);
	},

	confirmIntegrazione: (id_accreditamento, id_integrazione, data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			data,
			null,
			"post",
			'accreditamento/audit/' + id_accreditamento + '/integrazione/' + id_integrazione + '/confirm-integration',
			cb,
			ecb || null,
			null
		);
	}

}
