import React, { Component, } from 'react';
import FileSaver from 'file-saver';
import QRServices from '@common/services/qrCodeServices/qrCodeServices';

export default class QrIscrizione extends Component {

  state = {
    loading: false,
    png: null,
    payload: null,
  }

  componentDidMount() {
    this.download_QR(this.props.registration_id, this.props.url)
  }

  download_QR(id,url) {

    QRServices.QR_iscrizione_discente(id,url,async (res) => {
      const blob = new Blob([Buffer.from(res.data.data.qrcode,'base64' )], { type: 'image/png' });
      const urlout = URL.createObjectURL( blob );
      console.warn(blob)
      console.warn('resp', res.data.data.payload)
      this.setState({ loading: true, png: urlout, payload: res.data.data.payload});
      
    }, () => null)

   };

  handleClick(payload){
    navigator.clipboard.writeText(payload)
    .then( () => alert("PayloadCopiato"))
    .catch( () => alert("Si è verificato un errore"));

  }

copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}


  render() {
    return this.state.loading == true ? <div style={{display:"flex",flexFlow: "column",alignItems: "center"}}>
      <img src={this.state.png} style={{maxWidth:"300px"}} alt="SiSfo - Regione Calabria" />  
      <button type="button" onClick={ () => this.copyToClipboard(this.state.payload)
        .then( () => alert("PayloadCopiato"))
        .catch( () => alert("Si è verificato un errore")) }>COPIA PAYLOAD</button>
    </div> : []
  }
}