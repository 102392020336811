import React, { Component, useMemo, useRef, useState } from 'react';
import CommonForm from '@common/components/form';
import CorsoService from './../services/Corso'

import formatter from '@common/utils/formatters/_';
import { Spin, message } from 'antd';
import ComuniService from '@common/services/locations/Comuni';


import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

const center = [39.202560, 16.318307]
const L = require('leaflet');

const myIcon = L.icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: null,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

function locationToArry(location) {
  const parsed = /\((-*[0-9][0-9.]*),(-*[0-9][0-9.]*)\)/g.exec(location);
  return [parsed[1], parsed[2]];
}

function DraggableMarker({value, onMove}) {
  value = value ? locationToArry(value) : null;
  const [position, setPosition] = useState(value || center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
          onMove && onMove(marker.getLatLng());
        }
      },
    }),
    [onMove],
  );

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={myIcon}
      >
    </Marker>
  );
}

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Tipologia',
                type_string: 'text', // field type
                name: 'id_tipologia',
                options_props: 'tipologie',
                props: {
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Settore',
                type_string: 'text', // field type
                name: 'id_settore',
                options_props: 'settori',
                props: {
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Oggetto',
                type_string: 'text', // field type
                name: 'oggetto',
                props: {
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Livello',
                type_string: 'text', // field type
                name: 'id_livello',
                props: {
                  disabled: component.state.loading
                },
                options_props: 'livelli',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Durata (in ore)',
                type_string: 'text', // field type
                name: 'durata',
                props: {
                  min: 0,
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Metodologia didattica',
                type_string: 'text', // field type
                name: 'metodologia_didattica',
                props: {
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di avvio del corso',
                format: 'DD/MM/YYYY',
                name: 'dal',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                  disabled: component.state.loading
                },
                validations: [
                  {
                    validator: 'min_date'
                  },
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di conclusione del corso',
                format: 'DD/MM/YYYY',
                name: 'al',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                  disabled: component.state.loading
                },
                validations: [
                  {
                    validator: 'max_date'
                  },
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Ciclo unico/pluriennale',
                type_string: 'text', // field type
                name: 'id_ciclo_unico_pluriennale',
                props: {
                  disabled: component.state.loading
                },
                options_props: 'tipo_ciclo',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'integer',
                label: 'Costo previsto',
                type_string: 'text', // field type
                name: 'costo_previsto',
                props: {
                  min: 0,
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
        ]
      },

      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Destinatari',
                type_string: 'text', // field type
                name: 'destinatari',
                props: {
                  disabled: component.state.loading
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
        ]
      },


      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Provincia',
                type_string: 'text', // field type
                name: 'id_provincia',
                props: {
                  allowClear: true,
                  showSearch: true,
                  disabled: component.state.loading || component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'province',
                onChange: 'selectedProvincia',
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Comune',
                name: 'id_comune',
                props: {
                  showSearch: true,
                  disabled: component.state.loading || component.props.readOnly,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'comuni'
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Luogo',
                type_string: 'text', // field type
                name: 'location',
                props: {
                  disabled: true
                },
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'mappa',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <MapContainer center={center} zoom={9} scrollWheelZoom={false} style={{ height: "100vh" }}>
                    <TileLayer
                      attribution='&copy; SISFO'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <DraggableMarker onMove={location => component.updateField('location', `(${location.lat},${location.lng})`)} value={component.state.data?.location}/>
                  </MapContainer >
                </div>
              }
            ],
          },
        ],
      },


      {
        cols: [
          {
            items: [
              {
                name: 'btn_submit_corso',
                type: 'submit',
                text: 'Salva',
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ]
          }
        ]
      },
    ],
    onSubmit: 'submit',
  }
};

class Corso extends Component {

  state = {
    loading: false,
    data: null,
    comuni: [],
  }

  componentDidMount() {
    if (this.props.corso) {
      let data = formatter.replaceFormDatesIn([
        'dal',
        'al'
      ], this.props.corso, 'YYYY-MM-DD');
      this.setState({
        data: data,
        selected_provincia: data.id_provincia,
      }, () => this.fetchComuni(data.id_provincia))
    }
  }


  submit(data) {
    data = formatter.replaceFormDatesOut(['dal', 'al'], data, 'YYYY-MM-DD');
    data.id_ente = this.props.UserStore.user.ente.id;
    this.setState({ loading: true })

    if (this.props.corso.id) {
      CorsoService.update(
        data,
        this.props.corso.id,
        (res) => {
          message.success("Corso modificato con successo");
          this.setState({ loading: false })
          this.props.close();
        },
        () => this.setState({ loading: false }))
    } else {
      CorsoService.create(
        data,
        (res) => {
          message.success("Nuovo corso creato con successo");
          this.setState({ loading: false })
          this.props.close();
        },
        () => this.setState({ loading: false }))
    }



  }

  getValues() {
    return this.state.data;
  }

  updateField(field, value) {
    let data = {};
    data[field] = value;
    this.formSelRef.props.form.setFieldsValue(data);
  }

  fetchComuni(id_provincia) {
    ComuniService.all(
      { id_provincia: id_provincia },
      res => {
        this.setState({
          comuni: res.data.data || []
        }, () => this.formSelRef.props.form.setFieldsValue({ comune: null }));
      },
      () => null
    );
  }

  render() {
    return (
      <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>
        {
          this.state.loading ? <Spin /> : null
        }
        <CommonForm
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          settori={this.props.GlobalStore.stubs.settori}
          tipologie={this.props.GlobalStore.stubs.tipologia_attivita}
          livelli={this.props.GlobalStore.stubs.livello_offerta_formativa}
          tipo_ciclo={this.props.GlobalStore.stubs.tipo_ciclo}
          submit={(data) => this.submit(data)}

          min_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            let min = value;
            let max = this.formSelRef.props.form.getFieldsValue()['al'];
            if (max && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
            else cb()
          }}

          max_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            let max = value;
            let min = this.formSelRef.props.form.getFieldsValue()['dal'];
            if (min && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
            else cb()
          }}

          province={this.props.GlobalStore.stubs.provincia}

          selectedProvincia={data => {
            this.setState({
              selected_provincia: data,
              comuni: []
            }, () => {
              this.formSelRef.props.form.setFieldsValue({ comune: null });
              this.fetchComuni(data);
            });

          }}

          comuni={this.state.comuni.map(e => {
            return {
              key: 'comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}

        />
      </div>
    );
  }
}
export default Corso;
