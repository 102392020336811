

import { Card, Row, Col, Button, Popconfirm, message, Upload, Icon } from "antd";
import React, { Component } from 'react';
import ModelServices from '../services/RichiestaRuolo';
import FileSaver from 'file-saver';
import ruoloStatic from '@common/utils/static/ruolo';
import DocSpan from "@common/components/DocSpan";
import checkPermission from '@common/utils/check/permissions';

const getStato = (richiesta, component) => {

  if (!richiesta.stato_flusso_description) return '';
  let stato = 'dipendente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  return richiesta.stato_flusso_description['stato_' + stato];
}

const getDescription = (record, component) => {
  if (!record.stato_flusso_description) return ''
  let user = 'dipendente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';

  return record.stato_flusso_description['descrizione_' + user];
}

export default class PannelloStatoRichiestaRuolo extends Component {

  state = {
    loading: false,
    data: {},
    progress_obj: { progress: 0 },
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.richiesta !== this.props.richiesta) {
      this.loaded(this.props);
    }
  }

  componentDidMount() {
    if (this.props.richiesta) this.loaded(this.props);
  }

  loaded(props) {
    this.setState({ data: props.richiesta, progress_obj: props.richiesta?.progress || { progress: 0 } });
  }

  saveBlob(blob, title) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, title);
  }

  endLoading() {
    this.setState({
      loading: false,
      confirming: false
    })
  }

  renderDecreto() {

    let media = this.state.data?.media?.filter(el => el.tipo.sottodominio === ruoloStatic.tipo_media.sottodominio_decreto);

    return media && <div>
      {
        media.map(l => {
          return <div key={'com_ost_' + l.id}>
            <p style={{ display: 'inline-block' }}>Decreto aggiornamento elenco regionale: </p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }


  confirmReject() {
    if (!this.state.rejecting) {
      this.setState({
        rejecting: true
      }, () => {
        ModelServices.confirmReject(
          this.state.data.id,
          (res) => {
            this.setState({ rejecting: false })
            message.success("La candidatura è stata rigettata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ rejecting: false })
        )
      })
    }
  }


  renderDescriptionText(data) {
    if (data.stato === ruoloStatic.stato.predisposizione && data.progress && data.progress.valid) {
      return (<div>

        <Popconfirm placement="top" title="Confermi i dati inseriti?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.confirm(
                  data.id,
                  (res) => {
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Conferma lavorazione</Button>
        </Popconfirm>
      </div>)
    } else if (data.stato === ruoloStatic.stato.compilata) {
      return (<div>
        <Popconfirm placement="top" title="Sei sicuro di voler inviare la richiesta?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.send(
                  data.id,
                  (res) => {
                    message.success("Richiesta inoltrata correttamente");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Invia all'amministrazione</Button>
        </Popconfirm>
      </div>)
    } else if (data.stato_flusso === ruoloStatic.stato_flusso.sent  && checkPermission.hasPermission(this.props.UserStore.user, ['manageRichiestaRuolo'])){
      return (<div>
        <React.Fragment>
          <Popconfirm placement="top" title="Confermi l'approvazione della candidatura?"
            onConfirm={() => {
              this.setState({
                approving: true,
              }, () =>
                ModelServices.approve(
                  data.id,
                  (res) => {
                    message.success("Candidatura approvata correttamente");
                    this.props.refreshInline(res.data.data);
                    this.setState({approving:false})
                  },
                  () => {
                    this.setState({approving:false})
                  })
              )
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.state.approving}
              disabled={this.state.approving || this.state.rejecting}
              className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Approva</Button>
          </Popconfirm>
          <Popconfirm placement="top" title="Vuoi rigettare la candidatura?"
            onConfirm={() => {
               this.confirmReject()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.state.rejecting}
              disabled={this.state.approving || this.state.rejecting}
              className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Rigetta</Button>
          </Popconfirm>
        </React.Fragment>


      </div>)
    }else if (data.stato_flusso === ruoloStatic.stato_flusso.approved  && checkPermission.hasPermission(this.props.UserStore.user, ['manageRichiestaRuolo'])){
      return (<div>
        <React.Fragment>
        <Upload
            accept={'.p7m,.pdf'}
            multiple={false}
            fileList={null}
            customRequest={obj => {
              this.uploadDecreto(obj.file);
            }}
            {...this.props} >
            <Button className="m5w"
              disabled={this.state.uploading || false}
              loading={this.state.uploading || false} >
              <Icon type="upload" />{"Carica"}
            </Button>
          </Upload>
        </React.Fragment>
      </div>)
    }

  }

  editableState() {
    return this.state.data.stato <= ruoloStatic.stato.compilata;
  }





  uploadDecreto(file) {
    if (!this.state.uploading) {
      this.setState({
        uploading: true
      }, () => {
        ModelServices.uploadDecreto(
          this.state.data.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploading: false })
            message.success("Il decreto è stato caricato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploading: false })
        )
      })
    }
  }


  saveBlobWord(blob, title) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, title);
  }


  render() {

    return (<>

      <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
        <Col style={{ flex: "1 1 70%" }} >
          <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
            <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
              bordered={false}>
              {this.state.data?.ente &&
                <h4><span style={{ color: '#000' }}>ENTE DI FORMAZIONE:</span> {this.state.data.ente.denominazione}</h4>
              }
              {this.state.data && (this.state.data.tipo_richiesta === ruoloStatic.tipo_richiesta.nuova) &&

                <Button icon="file-pdf" className="m5h m5w" type="primary" loading={this.state.loading_proposta} onClick={() => {
                  this.setState({ loading_proposta: true })
                  ModelServices.printRichiesta(this.state.data.id, (res) => {
                    this.saveBlob(res.data, "richiesta.pdf");
                    this.setState({ loading_proposta: false })
                  }, (err) => {
                    this.setState({ loading_proposta: false })
                  })
                }}>Scarica candidatura</Button>
              }

            </Card>
            <Card style={{ flex: "1 1 auto" }}
              bordered={false}
              className="shadow">
              <Row>
                <Col lg={24} sm={24} xs={24}>
                  <div id="actions">
                    <h4 className="p10h">Stato di compilazione della richiesta</h4>
                    <p>Stato: <strong>{this.state.data ? getStato(this.state.data, this) : ''}</strong></p>
                    <p>{this.state.data ? getDescription(this.state.data, this) : ''}</p>

                    <p>{this.state.data ? this.renderDescriptionText(this.state.data, this) : ''}</p>
                    {this.renderDecreto()}

                  </div>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
      </Row>
    </>
    );
  }
}
