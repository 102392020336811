import React, { Component } from "react";
import Esami from "../../services/Esami";
import CommonForm from "@common/components/form";
import PagamentoButtonDiscente from "./PagamentoButtonDiscente";
import DownloadUpload from "@common/components/DownloadUpload";
import FileSaver from "file-saver";
import ModelServices from "../../services/Esami";
import PagamentoService from "./PagamentoServiceDiscente";
import checkPermission from "@common/utils/check/permissions";
import affidamentoStatic from "@common/utils/static/affidamento";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";
import MediaServices from "@common/services/media/Media";
import _ from "lodash";
import DocSpan from "@common/components/DocSpan";

import {
  Table,
  Checkbox,
  Button,
  Radio,
  Form,
  Input,
  Upload,
  message,
} from "antd";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "organigramma_table",
                type: "child",
                child: (
                  <div>
                    <h4>Discenti</h4>
                    <Table
                      loading={component.props.loading}
                      readOnly={component.props.readOnly}
                      values={component.getValues}
                      {...component.props}
                      columns={columnsDiscenti(component)}
                      dataSource={component.state.discenti}
                      rowKey="cf"
                      validDiscenteValutazione={
                        component.validDiscenteValutazione
                      }
                      checkDiscenteValutazione={
                        component.checkDiscenteValutazione
                      }
                      validDiscenteGiudizio={component.validDiscenteGiudizio}
                      checkDiscenteGiudizio={component.checkDiscenteGiudizio}
                      validDiscenteSuperata={component.validDiscenteSuperata}
                      validDiscenteMedia={component.validDiscenteMedia}
                      validDiscentePayment={component.validDiscentePayment}
                      checkDiscenteSuperata={component.checkDiscenteSuperata}
                    />
                    <br></br>
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit",
                text: "Salva",
                props: {
                  className: "bg-green",
                  //type: "primary",
                  icon: "save",
                },
                //onClick: 'showDrawer'
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submit",
  };
};

const columnsDiscenti = (component) => {
  return [
    {
      title: "Discente",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.anagrafica.nome + " " + record.anagrafica.cognome}
          <br />
          {record.anagrafica.cf}
        </div>
      ),
    },
    {
      title: "Valutazione risultato prova",
      dataIndex: "valutazione",
      key: "valutazione",
      render: (text, record) => (
        <div>
          <Form.Item>
            <Input
              type="text"
              disabled={component.props.readOnly}
              onChange={(e) => component.checkDiscenteValutazione(e, record)}
              defaultValue={component.validDiscenteValutazione(record)}
            />
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Giudizio",
      dataIndex: "Giudizio",
      key: "Giudizio",
      render: (text, record) => (
        <div>
          <Form.Item>
            <Input
              type="text"
              disabled={component.props.readOnly}
              onChange={(e) => component.checkDiscenteGiudizio(e, record)}
              defaultValue={component.validDiscenteGiudizio(record)}
            />
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Superata",
      dataIndex: "superata",
      key: "superata",
      render: (text, record) => (
        <div>
          <Radio.Group
            onChange={(e) => component.checkDiscenteSuperata(e, record)}
            defaultValue={component.validDiscenteSuperata(record)}
            disabled={component.props.readOnly}
          >
            <Radio.Button value={true}>Si</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </div>
      ),
    },
    component.props.isEnte
      ? {
          title: "Azione",
          dataIndex: "Action",
          key: "Action",
          render: (text, record) =>
            !component.validDiscenteSuperata(record) == true ? (
              component.props.progetto.scheda_progetto_json?.valutazioni[
                record.anagrafica.cf
              ]?.media?.tipo.sottodominio !== "certificato_frequenza" ? (
                component.renderUploadCertificatoFrequenza(component, record)
              ) : (
                component.renderDownloadCertificatoFrequenza(component, record)
              )
            ) : component.validDiscenteMedia(record) == null &&
              component.props.progetto.scheda_progetto_json
                .valutazioniComplete != true ? (
              <div>
                <DownloadUpload
                  accept={".p7m,.pdf"}
                  downloading={component.state.downloading}
                  uploading={component.state.uploading}
                  download={() => {
                    component.setState({ downloading: true }, () => {
                      ModelServices.downloadTemplateAttestato(
                        component.props.id_commissione,
                        component.props.scheda_progetto_json.valutazioni[
                          record.anagrafica.cf
                        ].id_esame_valutazione,
                        (res) => {
                          component.saveBlob(res.data, "attestato.pdf");
                          //this.props.refresh(false);
                          component.props.refreshInline(res.data.data);
                          component.endLoading();
                        },
                        () => component.endLoading()
                      );
                    });
                  }}
                  upload={(file) => {
                    console.warn("upload file", file);
                    return component.uploadFile(
                      { file: file, name: file.name },
                      record
                    );
                  }}
                ></DownloadUpload>
              </div>
            ) : component.props.scheda_progetto_json.valutazioni[
                record.anagrafica.cf
              ].media != null &&
              !component.state.discenti.filter(
                (m) => m.anagrafica.cf == record.anagrafica.cf
              )[0].pagamento[0]? (
              <div>
                <PagamentoButtonDiscente
                  verifyEsame={() =>
                    component.verifyEsame(component.props.progetto.id)
                  }
                  idProgetto={
                    component.props.scheda_progetto_json.valutazioni[
                      record.anagrafica.cf
                    ].id_esame_valutazione
                  }
                  rispostaPagamentoUrl={`${process.env.REACT_APP_BASE_URL}#${component.props.location.pathname}`}
                />
                <div style={{ marginTop: 18 }}>
                  <div
                    key={
                      "prog_" +
                      component.props.scheda_progetto_json.valutazioni[
                        record.anagrafica.cf
                      ].media.id
                    }
                  >
                    <p style={{ display: "inline-block" }}>
                      Attestato Discente:{" "}
                    </p>
                    <br></br>
                    <DocSpan
                      key={
                        component.props.scheda_progetto_json.valutazioni[
                          record.anagrafica.cf
                        ].media.id
                      }
                      file={
                        component.props.scheda_progetto_json.valutazioni[
                          record.anagrafica.cf
                        ].media
                      }
                    />
                    {component.props.scheda_progetto_json.valutazioni[
                      record.anagrafica.cf
                    ].media.note &&
                      component.props.scheda_progetto_json.valutazioni[
                        record.anagrafica.cf
                      ].media.note.map((n) => {
                        return (
                          <div key={"nota_" + n.id}>
                            <small>
                              <strong>
                                {n.user
                                  ? n.user.anagrafica?.nome +
                                    " " +
                                    n.user.anagrafica?.cognome
                                  : " - "}
                                :
                              </strong>{" "}
                              {n.note}
                            </small>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : component.props.scheda_progetto_json.valutazioni[
                record.anagrafica.cf
              ].media != null ? (
              <div style={{ marginTop: 18 }}>
                <div
                  key={
                    "prog_" +
                    component.props.scheda_progetto_json.valutazioni[
                      record.anagrafica.cf
                    ].media.id
                  }
                >
                  <p style={{ display: "inline-block" }}>
                    Attestato Discente:{" "}
                  </p>
                  <br></br>
                  <DocSpan
                    key={
                      component.props.scheda_progetto_json.valutazioni[
                        record.anagrafica.cf
                      ].media.id
                    }
                    file={
                      component.props.scheda_progetto_json.valutazioni[
                        record.anagrafica.cf
                      ].media
                    }
                  />
                  {component.props.scheda_progetto_json.valutazioni[
                    record.anagrafica.cf
                  ].media.note &&
                    component.props.scheda_progetto_json.valutazioni[
                      record.anagrafica.cf
                    ].media.note.map((n) => {
                      return (
                        <div key={"nota_" + n.id}>
                          <small>
                            <strong>
                              {n.user
                                ? n.user.anagrafica?.nome +
                                  " " +
                                  n.user.anagrafica?.cognome
                                : " - "}
                              :
                            </strong>{" "}
                            {n.note}
                          </small>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null,
        }
      : component.props.scheda_progetto_json.valutazioni
      ? {
          title: "Azione",
          dataIndex: "Action",
          key: "Action",
          render: (text, record) =>
            component.props.scheda_progetto_json.valutazioni[
              record.anagrafica.cf
            ].media != null ? (
              <div style={{ marginTop: 18 }}>
                <div
                  key={
                    "prog_" +
                    component.props.scheda_progetto_json.valutazioni[
                      record.anagrafica.cf
                    ].media.id
                  }
                >
                  <p style={{ display: "inline-block" }}>
                    Attestato Discente:{" "}
                  </p>
                  <br></br>
                  <DocSpan
                    key={
                      component.props.scheda_progetto_json.valutazioni[
                        record.anagrafica.cf
                      ].media.id
                    }
                    file={
                      component.props.scheda_progetto_json.valutazioni[
                        record.anagrafica.cf
                      ].media
                    }
                  />
                  {component.props.scheda_progetto_json.valutazioni[
                    record.anagrafica.cf
                  ].media.note &&
                    component.props.scheda_progetto_json.valutazioni[
                      record.anagrafica.cf
                    ].media.note.map((n) => {
                      return (
                        <div key={"nota_" + n.id}>
                          <small>
                            <strong>
                              {n.user
                                ? n.user.anagrafica?.nome +
                                  " " +
                                  n.user.anagrafica?.cognome
                                : " - "}
                              :
                            </strong>{" "}
                            {n.note}
                          </small>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null,
        }
      : {},
    checkPermission.hasRole(component.props.UserStore.user, "Valutatore") ||
    checkPermission.hasRole(
      component.props.UserStore.user,
      "DirettoreAmministrativo"
    )
      ? {
          title: "Azione",
          dataIndex: "Action",
          key: "Action",
          render: (text, record) =>
            checkPermission.hasRole(
              component.props.UserStore.user,
              "Valutatore"
            ) &&
            component.props.progetto.stato == 16 &&
            component.props.progetto.stato_flusso == 15 ? (
              <div>
                <DownloadUpload
                  accept={".p7m,.pdf"}
                  downloading={component.state.downloading}
                  uploading={component.state.uploading}
                  download={() => {
                    component.setState({ downloading: true }, () => {
                      ModelServices.downloadTemplateAttestatoEnte(
                        component.props.id_commissione,
                        component.props.scheda_progetto_json.valutazioni[
                          record.anagrafica.cf
                        ].id_esame_valutazione,
                        (res) => {
                          component.saveBlob(res.data, "attestato.pdf");
                          //this.props.refresh(false);
                          component.props.refreshInline(res.data.data);
                          component.endLoading();
                        },
                        () => component.endLoading()
                      );
                    });
                  }}
                  upload={(file) =>
                    component.uploadFile(
                      { file: file, name: file.name },
                      record
                    )
                  }
                ></DownloadUpload>
              </div>
            ) : checkPermission.hasRole(
                component.props.UserStore.user,
                "DirettoreAmministrativo"
              ) &&
              component.props.progetto.stato == 17 &&
              component.props.progetto.stato_flusso == 19 ? (
              <div>
                <DownloadUpload
                  accept={".p7m,.pdf"}
                  downloading={component.state.downloading}
                  uploading={component.state.uploading}
                  download={() => {
                    component.setState({ downloading: true }, () => {
                      ModelServices.downloadTemplateAttestatoPresidente(
                        component.props.id_commissione,
                        component.props.scheda_progetto_json.valutazioni[
                          record.anagrafica.cf
                        ].id_esame_valutazione,
                        (res) => {
                          component.saveBlob(res.data, "attestato.pdf");
                          //this.props.refresh(false);
                          component.props.refreshInline(res.data.data);
                          component.endLoading();
                        },
                        () => component.endLoading()
                      );
                    });
                  }}
                  upload={(file) =>
                    component.uploadFile(
                      { file: file, name: file.name },
                      record
                    )
                  }
                ></DownloadUpload>
              </div>
            ) : null,
        }
      : {},
  ];
};

class discentiEsameForm extends Component {
  constructor(props) {
    super(props);
    this.validDiscenteSuperata = this.validDiscenteSuperata.bind(this);
    this.checkDiscenteSuperata = this.checkDiscenteSuperata.bind(this);
    this.checkDiscenteGiudizio = this.checkDiscenteGiudizio.bind(this);
    this.checkDiscenteValutazione = this.checkDiscenteValutazione.bind(this);
    this.validDiscenteGiudizio = this.validDiscenteGiudizio.bind(this);
    this.validDiscenteValutazione = this.validDiscenteValutazione.bind(this);
  }

  state = {
    loading: false,
    validDiscente: this.props ? {} : {},
    pagamenti: [],
    stopload: false,
    role: checkPermission.hasRole(this.props.UserStore.user, "Ente")
      ? "ente"
      : checkPermission.hasRole(this.props.UserStore.user, "Valutatore")
      ? "presidente"
      : "dirigente",
  };

  componentDidMount() {
    this.load(this.props);
    if (
      checkPermission.hasRole(this.props.UserStore.user, "Ente") &&
      checkPermission.hasRole(this.props.UserStore.user, "EnteAfc") &&
      this.props.progetto.scheda_progetto_json.valutazioniComplete != true
    ) {
      this.verifyEsame(this.props.progetto.id);
    }
  }

  loadPagamenti(data) {
    PagamentoService.pagamentiDiscente(data).then((response) => {
      const pagamenti = [...this.state.pagamenti, _.get(response.data, "data")];
      this.setState({ pagamenti });
    });
  }

  load(props) {
    this.setState({ loading: true });

    Esami.getDiscenti({}, props.id_commissione, (res) => {
      this.setState({
        discenti: res.data.data,
      });
    });

    this.setState({ loading: false });
  }

  verifyEsame(id) {
    this.setState({ loading: true }, () => {
      ModelServices.verifyEsame(
        id,
        (res) => {
          this.setState({ loading: false });
          if (res.data.data.scheda_progetto_json.valutazioniComplete == true) {
            this.props.refresh();
          }
        },
        () => this.setState({ loading: false })
      );
    });
  }

  getValues() {
    let obj = this.props.scheda_progetto_json;
    return obj;
  }

  checkDiscenteSuperata(e, record) {
    let f = this.state.validDiscente;
    f[record.anagrafica.cf] = {
      ...f[record.anagrafica.cf],
      superata: e.target.value,
      id_anagrafica: record.id_anagrafica,
      id_iscrizione: record.id,
    };
    this.setState({ validDiscente: f });
  }
  checkDiscenteGiudizio(e, record) {
    let f = this.state.validDiscente;
    f[record.anagrafica.cf] = {
      ...f[record.anagrafica.cf],
      giudizio: e.target.value,
    };
    this.setState({ validDiscente: f });
  }

  checkDiscenteValutazione(e, record) {
    let f = this.state.validDiscente;
    f[record.anagrafica.cf] = {
      ...f[record.anagrafica.cf],
      valutazione: e.target.value,
    };
    this.setState({ validDiscente: f });
  }

  validDiscenteSuperata(record) {
    if (
      this.props.scheda_progetto_json.valutazioni &&
      this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
    ) {
      return this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
        .superata;
    } else {
      return null;
    }
  }
  validDiscenteMedia(record) {
    if (
      this.props.scheda_progetto_json.valutazioni &&
      this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
    ) {
      return this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
        .media;
    } else {
      return null;
    }
  }
  validDiscentePayment(record) {
    if (
      this.props.scheda_progetto_json.valutazioni &&
      this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
    ) {
      if (
        this.state.pagamenti.find(
          (m) =>
            m.id_esame_valutazione ==
            this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
              .id_esame_valutazione
        )
      ) {
        return true;
      } else if (this.state.stopload == false) {
        this.loadPagamenti(
          this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
            .id_esame_valutazione
        );
        this.setState({ stopload: true });
        return false;
      }
    }
  }

  validDiscenteValutazione(record) {
    if (
      this.props.scheda_progetto_json.valutazioni &&
      this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
    ) {
      return this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
        .valutazione;
    } else {
      return "";
    }
  }

  validDiscenteGiudizio(record) {
    if (
      this.props.scheda_progetto_json.valutazioni &&
      this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
    ) {
      return this.props.scheda_progetto_json.valutazioni[record.anagrafica.cf]
        .giudizio;
    } else {
      return "";
    }
  }

  renderUploadCertificatoFrequenza(component, record) {
    return (
      <div>
        <Upload
          showUploadList={false}
          customRequest={(obj) => {
            component.uploadFile(obj, record, "certificato_frequenza");
          }}
        >
          <Button disabled={false} size="medium" icon="upload" loading={false}>
            Carica Attestato
          </Button>
        </Upload>
      </div>
    );
  }

  renderDownloadCertificatoFrequenza(component, record) {
    return (
      <Button
        loading={component.props.downloading || false}
        disabled={
          component.props.downloading || component.props.uploading || false
        }
        icon="download"
        onClick={() => {
          const lastMedia =
            component.props.progetto.scheda_progetto_json.valutazioni[
              record.anagrafica.cf
            ].media;
          MediaServices.preview(
            lastMedia.id,
            async (res) => {
              const blob = new Blob([lastMedia], { type: lastMedia.mime_type });
              FileSaver.saveAs(blob, lastMedia.nome_file);
            },
            () => null
          );
        }}
      >
        <span>Download certificato di frequenza</span>
      </Button>
    );
  }

  saveBlob(blob, title) {
    const _blob = new Blob([blob], { type: "application/pdf" });
    FileSaver.saveAs(_blob, title);
  }

  endLoading() {
    this.setState({
      loading: false,
      uploading: false,
      uploaded: false,
      confirming: false,
      sending: false,
      downloading: false,
      integrating: false,
      rejectingOpenRegistration: false,
      approvingOpenRegistration: false,
      confirmOpenRegistration: false,
    });
  }

  uploadFile(file, record, sottodominio = "attestato") {
    this.setState(
      {
        file: file,
        filename: "file",
        sottodominio: sottodominio,
      },
      () => this.upload(record)
    );
    return;
  }

  upload(data) {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          uploading: true,
        },
        () => {
          ModelServices.uploadAttestato(
            this.props.id_commissione,
            this.props.scheda_progetto_json.valutazioni[data.anagrafica.cf]
              .id_esame_valutazione,
            {
              filename: "file",
              note: this.state.note || "",
              sottodominio: this.state.sottodominio || "attestato",
            },
            { ...this.state.file, chiave: "file" },
            this.state.role,
            (res) => {
              message.success(
                "La dichiarazione è stata caricata correttamente"
              );
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data);
              this.endLoading();
              this.setState({ open_modal_doc: false });
              this.verifyEsame(this.props.progetto.id);
            },
            () => {
              this.endLoading();
            }
          );
        }
      );
    }
  }

  submit(data) {
    let dati = {
      valutazioni: this.state.validDiscente,
      scheda: "discenti",
    };
    this.setState({ send: true });

    this.props.SaveData({ discenti: dati });
  }

  render() {
    return (
      <div>
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "viewValutazioneScheda",
        ]) && (
          <ValidazioneScheda
            refresh={(reset) => this.props.refresh(reset)}
            canGiveFeedback={
              this.props.canGiveSectionFeedback &&
              this.props.stato_flusso ===
                affidamentoStatic.stato_flusso_progetto.audit_iniziato
            }
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) =>
              this.props.saveFeedback(note, valid, "discent")
            }
            feedback={
              this.props.section_feedback
                ? this.props.section_feedback["discent"]
                : null
            }
          />
        )}
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          submit={(data) => this.submit(data)}
          values={this.getValues()}
          readOnly={this.props.readOnly}
          validDiscenteSuperata={(record) => this.validDiscenteSuperata(record)}
          validDiscenteMedia={(record) => this.validDiscenteMedia(record)}
          validDiscentePayment={(record) => this.validDiscentePayment(record)}
          validDiscenteGiudizio={(record) => this.validDiscenteGiudizio(record)}
          validDiscenteValutazione={(record) =>
            this.validDiscenteValutazione(record)
          }
          checkDiscenteSuperata={(e, record) =>
            this.checkDiscenteSuperata(e, record)
          }
          checkDiscenteGiudizio={(e, record) =>
            this.checkDiscenteGiudizio(e, record)
          }
          checkDiscenteValutazione={(e, record) =>
            this.checkDiscenteValutazione(e, record)
          }
        />
      </div>
    );
  }
}
export default discentiEsameForm;
