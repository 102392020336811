import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Card, Col } from "antd";

import Wrapper from "@common/decorators/Wrapper";
import CheckPermissions from "@common/decorators/CheckPermissions";
import TableEdizioni from "../components/TableEdizioniBackoffice";
import checkPermission from '@common/utils/check/permissions';
import "../less/Affidamento.less";

@Wrapper({
  route: "ProgettiFormativi",
  hasSidebar: true,
  hasHeader: true,
  hasFooter: true,
})
@CheckPermissions([
  "direttoreAmministrativoPermissions",
  "istruttoreAmministrativoPermissions",
  "dirigenteAmministrativoPermissions",
  "adminPermissions",
  "HelpdeskPermissions",
])
class EdizioniBackofficeRoute extends Component {
  state = {
    loading: false,
    asked: false,
    progetto_id: null,
  };

  constructor(props) {
    super(props);
    this.state.progetto_id = this.props.match.params.id;
  }

  renderTitle() {
    return "Edizioni";
  }

  render() {
    return (
      <div>
        <Card
          style={{ marginBottom: "12px", backgroundColor: "#fff" }}
          bordered={false}
          className="shadow"
        >
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          <p>Ricerca edizioni</p>
        </Card>
        <div style={{ padding: "12px", marginBottom: "40px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Bacheca</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/affidamento/progetti">Progetti Formativi</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Edizioni</Breadcrumb.Item>
          </Breadcrumb>
          {checkPermission.hasRole(this.props.UserStore.user, ['FunzionarioCoordinatoreAmministrativo', 'DirettoreAmministrativo'])
      &&
          <Col span={18} style={{ paddingTop: "15px",}}>
            <a
              // DEV href="http://40.68.92.14/regione-calabria-monitoraggio/"
              href="http://blockchain-monitoraggio.regione.calabria.it/cruscotto"
              title="Cruscotto monitoraggio Block Chain"
            >
              Cruscotto monitoraggio Block Chain
            </a>
          </Col>
  }
        </div>


        <TableEdizioni ref="listTable" {...this.props} />
      </div>
    );
  }
}

export default EdizioniBackofficeRoute;
