import React, {Component} from 'react';
import { message} from 'antd';
import CommonForm from '@common/components/form';
import format from '@common/utils/formatters/_';
import CapacitaGestionaliServices from '@accreditamento/services/CapacitaGestionali';

const formLayout = component => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Professione',
                name: 'id_professione',
                options_props: 'professioni',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Funzione',
                name: 'select_funzione',
                onChange: 'select_funzione_change',
                options: [
                  {
                      value: "TutorDidattico",
                      label: "Tutor didattico"
                  },
                  {
                      value: "TutorElearning",
                      label: "Tutor e-learning"
                  },
                  {
                      value: "Altro",
                      label: "Altro"
                  },
                ],
                props: {
                  //disabled: component.props.edit,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Specificare la funzione',
                name: 'funzione',
                input_type: 'text',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.state.select_funzione_altro_selected }
                  }
                ],
              }
            ]
          }
          /*{
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Funzione',
                name: 'funzione',
                input_type: 'text',
                mode: 'multiple',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          }*/
        ]
      },
      {
        cols: [
          {
            span: 16,
            items: [
              {
                type: 'select',
                label: 'Tipo rapporto di lavoro',
                type_string: 'text', // field type
                name: 'id_tipo_rapporto_lavoro',
                options_props: 'tipo_rapporto_lavoro',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'integerwithzero',
                label: 'Durata (anni)',
                type_string: 'number', // field type
                name: 'durata_rapporto_lavoro',
                min: 1,
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                  {
                    validator: 'minUno'
                  }
                ],
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Titolo di studio',
                type_string: 'text', // field type
                name: 'titolo_studio',
                options_props: 'tipo_titolo_studio',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Sedi',
                name: 'sedi',
                options_props: 'sedi',
                mode: 'multiple',
                props: {
                  size: 'large'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ]
          }
        ]
      },
      /*
      {
          cols: [
            {
              items: [
                {
                  type: 'radio',
                  label: 'Autorizzazione al trattamento dei dati personali secondo i termini di legge',
                  name: 'autorizz_tratt_dati',
                  option_type: 'button', // normal|button
                  options: [
                    {
                      value: "1",
                      label: "Si"
                    },
                    {
                      value: "0",
                      label: "No"
                    }
                  ],
                  props: {
  
                  },
                  validations: [
                    {
                      type: 'required',
                      error_message: 'Campo obbligatorio'
                    }
                  ],
                }
              ],
            }
          ],
      },*/
      {
          cols: [
            {
              items: [
                {
                  type: 'radio',
                  label: 'Dichiarazione della veridicità dei dati riportati, in base al D.P.R. 445 del 28/12/2000.',
                  name: 'dichiaraz_veridicita_dati',
                  option_type: 'button', // normal|button
                  options: [
                    {
                      value: "1",
                      label: "Si"
                    },
                    {
                      value: "0",
                      label: "No"
                    }
                  ],
                  props: {
  
                  },
                  validations: [
                    {
                      type: 'required',
                      error_message: 'Campo obbligatorio'
                    }
                  ],
                }
              ],
            }
          ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Conferma',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ],
    onSubmit: 'submitted',
  }
};


class UpdateModel extends Component {

  state = {
    loading: false
  }



  getFormLayout() {

  }






  updateQueryStr() {

  }




  confirm(data) {

    if(!data.dichiaraz_veridicita_dati || data.dichiaraz_veridicita_dati === 'No' || data.dichiaraz_veridicita_dati === '0'
      ) {
      message.error("Devi accettare l'autorizzazione al trattamento dei dati personali e la dichiarazione della veridicità dei dati riportati");
      return;
    }


    if(!this.state.loading) {
      this.setState({loading: true});

      if(data.select_funzione === 'TutorDidattico'){
        data.funzione = 'Tutor didattico';
      }
      if(data.select_funzione === 'TutorElearning'){
        data.funzione = 'Tutor e-learning';
      }
      CapacitaGestionaliServices.updateUtenzaSpeciale( 
        data, 
        this.props.model.id, 
        this.props.accreditamento.id, 
        () => {
          this.setState({loading: false});
          if(this.props.onUpdate) this.props.onUpdate();
        },
        () => this.setState({loading: false}) )

    }
  }


  getAnaRapportoLavoro(ana) {
    try{
      return ana.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }

  getSedi() {
    return this.props.accreditamento.sedi
  }

  getValues() {
    let vals = Object.assign({}, this.props.model);

    try {
      vals.sedi = vals.sedi.map(el => ""+el.id)
    } catch(e) {
      vals.sedi = [];
    }

    let select_funzione = vals.funzione;
    if(select_funzione == 'Tutor didattico'){
      select_funzione = 'TutorDidattico';
    }else if(select_funzione == 'Tutor e-learning'){
      select_funzione = 'TutorElearning';
    }

    vals = {
      ...vals,
      ...this.getAnaRapportoLavoro(this.props.model.anagrafica),
      select_funzione
    }
    return format.convertBoolean(['autorizz_tratt_dati','dichiaraz_veridicita_dati'], vals);
  }

  render () {
    return <div>
      <h4>Dettagli funzione</h4>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.getValues()}
          readOnly={this.props.readOnly}
          submitted={(dati) => this.confirm(dati)}

          tipo_accreditamento={this.props.GlobalStore.stubs.tipo_accreditamento}
          tipo_rapporto_lavoro={this.props.GlobalStore.stubs.tipo_rapporto_lavoro}
          tipo_titolo_studio={this.props.GlobalStore.stubs.tipo_titolo_studio}

          professioni={this.props.GlobalStore.stubs.professioni}

          minUno={(rule, value, cb) => {
            if (parseInt(value) < 1) cb("Minimo 1");
            else cb()
          }}

          sedi={this.props.sedi.map(el => {
            return {
              key: 'sede_' + el.enteSede.id,
              label: format.getIndirizzo( el.enteSede.indirizzo, this.props.GlobalStore),//el.enteSede.indirizzo.via + " " + el.enteSede.indirizzo.ncivico + " (" + el.enteSede.indirizzo.locComune.comune + ")",
              value: el.enteSede.id
            }
          })}

          select_funzione_change={valueSelected => {
            if(valueSelected === 'Altro'){
              this.setState({select_funzione_altro_selected: true});
            }else{
              this.setState({select_funzione_altro_selected: false});
            }
          }}

        />
    </div>
  }

}
export default UpdateModel;