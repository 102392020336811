import React, { Component } from 'react';

import { Button, Table, Popconfirm, Modal } from 'antd';

import format from '@common/utils/formatters/_';

import Create from '@accreditamento/forms/completeRequest/efficacia/CostiAttivita';

import EfficaciaServices from '@accreditamento/services/Efficacia';




const columns = (component) => {
  return [
      {
        title: 'Anno',
        dataIndex: 'anno',
        key: 'anno',
        render: (text, record) => (<div style={{width: 150}}>{record.anno}</div>)
      }, {
        title: 'Macrotipologia',
        dataIndex: 'id_tipo_accreditamento',
        key: 'id_tipo_accreditamento',
        render: (text, record) => (<div style={{width: 150}}>{format.replaceStub(record.id_tipo_accreditamento, 'tipo_accreditamento', component.props.GlobalStore)}</div>)
      },
      {
        title: 'Costi allievo autorizzati',
        dataIndex: 'costi_allievo_autorizzati',
        key: 'costi_allievo_autorizzati',
        render: (text, record) => (<div style={{width: 150}}>{record.costi_allievo_autorizzati}</div>)
      },
      {
        title: 'Costi allievo rendicontati',
        dataIndex: 'costi_allievo_rendicontati',
        key: 'costi_allievo_rendicontati',
        render: (text, record) => (<div style={{width: 150}}>{record.costi_allievo_rendicontati}</div>)
      },
      {
        title: '%',
        dataIndex: 'calc',
        key: 'calc',
        render: (text, record) =>
          <div style={{ width: 150 }}>
            {record.costi_allievo_autorizzati > 0 ? parseInt(record.costi_allievo_rendicontati / record.costi_allievo_autorizzati * 100) : 0}
          </div>
      },
      {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
          <div style={{width: 120}}>
            {// non dobbiamo mostrare l'ultima
              record.id_tipo_accreditamento ?
            <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo elemento?"
            onConfirm={() => component.delete(record.id)} okText="Si" cancelText="No">
              <Button loading={component.state.deleting.indexOf(record.id) !== -1} className="m2w" size="small" shape="circle" icon="delete" />
            </Popconfirm>
            : null}
          </div>
        ),
      }
    ];
  }


class CostiAttivitaList extends Component {

  state = {
    loading: false,
    open_drawer: false,
    updating_model: {},
    deleting: [],
    uploading: [],
    deleting_child: [],
    removing_file: [],
    open_modal: false
  };



  componentDidMount() {
    this.setState({

    })
  }





  delete(id) {
    if(this.state.deleting.indexOf(id) === -1) {
      this.setState({deleting: [...this.state.deleting, id]}, ()=>{
         EfficaciaServices.deleteCostiAttivita( this.props.accreditamento.id, id, () => {
           this.setState({deleting: this.state.deleting.filter(e=>e !== id)});
           if(this.props.onDelete) this.props.onDelete(id);
         }, ()=>this.setState({deleting: this.state.deleting.filter(e=>e !== id)}))
      })

    }
  }

  getDataSource() {
    let datas = [...this.props.costi_attivita];
    let last_col = {
      id_tipo_accreditamento: null,
      costi_allievo_autorizzati: 0.00,
      costi_allievo_rendicontati: 0.00
    }
    this.props.costi_attivita.forEach( c => {
      last_col.costi_allievo_autorizzati += parseFloat(c.costi_allievo_autorizzati);
      last_col.costi_allievo_rendicontati += parseFloat(c.costi_allievo_rendicontati)
    });
    last_col.costi_allievo_autorizzati = parseFloat(last_col.costi_allievo_autorizzati).toFixed(2);
    last_col.costi_allievo_rendicontati = parseFloat(last_col.costi_allievo_rendicontati).toFixed(2);
    datas.push(last_col);
    return datas;
  }

  render() {
    return (
      <div style={{marginTop: 32}}>
        <h4>COSTI DELLE ATTIVITÀ FORMATIVE PRECEDENTEMENTE REALIZZATE</h4>
        <Button
          type="primary"
          icon="plus"
          className="bg-green"
          disabled={this.props.readOnly}
          onClick={()=>this.setState({open_modal: true})}>
          Aggiungi costi
        </Button>
        <Modal
          title="Inserisci costi"
          visible={this.state.open_modal}
          onCancel={() => {
            this.setState({ open_modal: false }); this.formRef.reset();
          }}
          width={window.innerWidth / 100 * 80}
          okButtonProps={{style: {display: 'none'}}}

        >
          <Create
            ref={inst => (this.formRef = inst)}
            accreditamento={this.props.accreditamento}
            GlobalStore={this.props.GlobalStore}
            tipo_accreditamento={this.props.tipo_accreditamento}
            onCreate={()=>{
              this.setState({open_modal: false})
              this.props.onCreate()
            }}
          />
        </Modal>

        <Table
        loading={this.props.loading_data || this.props.loading_costi_attivita}
        columns={columns(this)}
        rowKey={(record)=>"corso_"+record.id}
        pagination={false}
        dataSource={this.getDataSource()} />
      </div>
    );
  }
}
export default CostiAttivitaList;