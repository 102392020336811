import BaseRequest from '@common/services/_baseRequest';


export default {
  getSediAccreditate: (id_comune, id_macrotipologia, id_ente, id_progetto, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/sede/get-sedi-accreditate/' + id_comune + '/' + id_macrotipologia + '/' + id_ente + '/' + id_progetto,
      cb,
      ecb
    );
  },

  getProvinceSediAccreditate: (id_macrotipologia, id_ente, id_progetto, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/sede/get-province-sedi-accreditate/' + id_macrotipologia + '/' + id_ente + '/' + id_progetto,
      cb,
      ecb
    );
  },

  getComuniSediAccreditate: (provincia, id_macrotipologia, id_ente, id_progetto, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/sede/get-comuni-sedi-accreditate/' + provincia + '/' + id_macrotipologia + '/' + id_ente + '/' + id_progetto,
      cb,
      ecb
    );
  },

  getAule: (id_sede, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/sede/' + id_sede + '/get-aule',
      cb,
      ecb
    );
  },

  view: (params, id, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			'affidamento/sede/' + id,
			cb,
			ecb || null,
			null
		);
	},

}
