import React, { Component } from 'react';
import { Button, Card, Steps, notification } from 'antd';
import { Link } from 'react-router-dom';
import Wrapper from '@common/decorators/Wrapper';

import ProfileForm from '@accreditamento/forms/request/Profile';
import OrganizationForm from '@accreditamento/forms/request/Organization';
import DocumentsForm from '@accreditamento/forms/request/Documents';

import ProvinceService from '@common/services/locations/Province';
import TipoMediaServices from '@common/services/media/TipoMedia';

import AccreditamentoRequest from '@accreditamento/services/Request';

import format from '@common/utils/formatters/_';
import '../less/Accreditamento.less';

import moment from 'moment';

const Step = Steps.Step;

@Wrapper({ route: 'AccreditamentoRequest', hasSidebar: false, hasHeader: true, hasFooter: true, public: true })
class RegistrazioneEnte extends Component {

  state = {
    current: 0,
    loading: false,
    mounted: false,
    tipi_media: [],
    tipi_organizzazione: [],
    province: [],
    loaded: false,
    stato: [
      { key: 1, value: 1, label: "Italia" },
      { key: 2, value: 2, label: "Estero" }
    ],
    values: {},
    profile: {
      nome: "",
      cf: "",
      cognome: "",
      civicolegale: "",
      caplegale: "",
      provincialegale: null,
      comunelegale: null,
      comune_nascita: null,
      stato_nascita: 1,
      statolegale: 1,
      vialegale: "",
      nato_a: "",
      nato_il: null,
      telefonolegale: "",
      id_tipo_ragione_sociale: "1",
      universita_scuole: "1",
      id_documento: 1,
      medias: []
    },
    organization: {
      cfente: "",
      viaente: "",
      civicoente: "",
      capente: "",
      provinciaente: null,
      comuneente: null,
      statoente: 1,
      partita_iva: "",
      telefonoente: "",
      anno_costituzione: "",
      iscrizione_ccia: "",
      numero_ccia: "",
      data_iscrizione: "",
      denominazione: "",
      email: "",
      pec: "",
      id_tipo_ragione_sociale: null,
      tipologia_ente: null
    },
    document: {
      anno_costituzione: null,
      data_isc_cciaa: null,
      numero_cciaa: "",
      //tipo_media_atto_costitutivo: 1,
      //tipo_media_statuto: 18,
      medias: []
    },
  };

  componentDidMount() {
    this.setState({ mounted: true }, () => {

      TipoMediaServices.all((res) => {
        this.setState({ tipo_media: res.data.data })
      }, err => console.error(err));


      ProvinceService.all(res => {
        this.setState({
          province: res.data.data.map(h => {
            return { key: h.id, value: h.id, label: h.provincia + ' ' + h.sigla };
          }),
        });
      });

      this.setState({ loaded: true });
    });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  async next() {
    this.setState({ loading: true })
    const current = this.state.current + 1;
    let valid = false;
    switch (this.state.current) {
      case 0:
        valid = await this.refs.profile_form.validate();

        if (valid)
          var valori_profilo = this.refs.profile_form.getValues(true);
        if (valori_profilo) {
          this.setState({
            current: current,
            profile: valori_profilo
          });
          valori_profilo.nato_il = moment(
            valori_profilo.nato_il
          ).format('YYYY-MM-DD')
        }
        this.setState({ loading: false })
        break;
      case 1:
        valid = await this.refs.organization_form.validate();

        if (valid) {
          var valori_org = format.convertBoolean(['universita_scuole'], this.refs.organization_form.getValues(true));
          if (valori_org) {
            this.setState({
              current: current,
              organization: valori_org,
            });
          }
        }
        this.setState({ loading: false })
        break;
      default: break;
    }
  }

  async submitRequest() {

    let valid = await this.refs.tipi_media_form.validate();
    //console.log(this.refs.tipi_media_form.getValues());
    if (valid) {
      this.setState({
        document: this.refs.tipi_media_form.getValues(),
      });
      let values = {
        profile: this.state.profile,
        organization: this.state.organization,
        document: this.state.document,
      };
      //console.log ('valori da inviare', values);
      this.sendRequest(values);
    }
  }

  sendRequest(values) {

    var val_to_send = Object.assign({}, { ...values });
    //console.log ('valori', val_to_send);
    if (!this.state.loading) {
      this.setState({ loading: true });

      val_to_send.profile.nato_il = moment(values.profile.nato_il).format('YYYY-MM-DD');
      val_to_send.document.anno_costituzione = moment(values.document.anno_costituzione).format('MM-YYYY');
      val_to_send.document.data_iscrizione = moment(values.document.data_iscrizione).format('YYYY-MM-DD');

      let medias = [...values.profile.medias, ...values.document.medias];

      AccreditamentoRequest.registrazione(
        {
          ...val_to_send.profile,
          ...val_to_send.organization,
          ...val_to_send.document,
          medias: medias,
          base_url: this.props.GlobalStore.BASE_URL,
        },
        val_to_send.document.files,
        res => {
          this.setState({ loading: false, loaded: false });
          notification.destroy();
        },
        err => {
          //console.log('errore', err);
          this.setState({ loading: false });
        }
      );
    }
  }

  prev() {
    const current = this.state.current - 1;

    switch (this.state.current) {
      case 1:
        this.setState({
          organization: this.refs.organization_form.getValues(),
        });
        break;
      case 2:
        this.setState({ document: { ...this.state.document, ...this.refs.tipi_media_form.getValues() } });
        break;
      default: break;
    }

    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    return (
      <div style={{ maxWidth: 800, margin: '20px auto' }}>
        {/* <LogoForm /> */}
        <h3 className="sectionTitle" style={{ textAlign: 'center' }}>REGISTRAZIONE ENTE</h3>
        <p style={{ textAlign: 'center' }}>
          <span><em className="color-red">*</em> I file contrassegnati da asterisco sono obbligatori</span>
        </p>
        <Card className="shadow" bordered={false}>
          {this.state.loaded && <Steps current={current}>
              <Step key="profile" title="Rappresentante Legale" />
              <Step key="organization" title="Dati Ente" />
              <Step key="document" title="Documenti societari" />
            </Steps>
          }

          {this.state.loaded
            ? <div className="steps-content">
              {current === 0
                ? <ProfileForm
                  values={this.state.profile}
                  province={this.state.province}
                  stato={this.state.stato}
                  nazione_estera={this.state.stato}
                  tipo_media={this.props.GlobalStore.stubs.tipo_media}
                  comunelegale={(this.state.profile && this.state.profile.comunelegale) ? this.state.profile.comunelegale : null}
                  comune_nascita={(this.state.profile && this.state.profile.comune_nascita) ? this.state.profile.comune_nascita : null}
                  ref="profile_form"
                  onUpload={(media) => {
                    try {

                      this.setState({
                        profile: {
                          ...this.state.profile,
                          medias: [...this.state.profile.medias, media]
                        }
                      })
                    } catch (e) {
                      console.error(e);
                    }
                  }}

                  onDeleteMedia={id => {
                    try {

                      this.setState({
                        profile: {
                          ...this.state.profile,
                          medias: this.state.profile.medias.filter(el => el.id !== id)
                        }
                      })
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  {...this.props}
                />
                : null}
              {current === 1
                ? <OrganizationForm
                  values={this.state.organization}
                  province={this.state.province}
                  tipi_organizzazione={this.state.tipi_organizzazione}
                  tipo_media={this.state.tipo_media}
                  tipi_media={this.state.tipi_media}
                  stato={this.state.stato}
                  ref="organization_form"
                  {...this.props}
                />
                : null}
              {current === 2
                ? <DocumentsForm
                  organization={this.state.organization}
                  tipo_media={this.props.GlobalStore.stubs.tipo_media}
                  id_ente={null}
                  medias={this.state.document.medias}
                  values={this.state.document}
                  tipi_media={this.state.tipi_media}
                  ref="tipi_media_form"
                  onUpload={(media) => {

                    this.setState({
                      document: {
                        ...this.state.document,
                        medias: [...this.state.document.medias, media]
                      }
                    })
                  }}

                  onDeleteMedia={id => {
                    try {

                      this.setState({
                        document: {
                          ...this.state.document,
                          medias: this.state.document.medias.filter(el => el.id !== id)
                        }
                      })
                    } catch (e) {
                      console.error(e);
                    }
                  }}

                  {...this.props}
                />
                : null
              }
            </div>
            : <p
              style={{
                margin: '40px auto',
                width: '320px',
                textAlign: 'center',
                fontSize: 18,
              }}
            >
              Hai inoltrato una richiesta di registrazione, verifica di aver ricevuto la mail di conferma all'indirizzo PEC fornito
              </p>
          }

          <Link
            to="/login"
            className="login-form-forgot"
            style={{ float: 'right' }}
            onClick={() => {
            }}
          >
            Annulla
          </Link>
        </Card>
        {this.state.loaded &&
        <div className="steps-action m10h">
          {current > 0 &&
            <Button
              style={{ marginRight: 8 }}
              size="large"
              onClick={() => this.prev()}
            >
              Precedente
            </Button>
          }
          {current < 2 &&
            <Button
              type="primary"
              size="large"
              loading={this.state.loading}
              onClick={() => this.next()}
            >
              Prossimo
            </Button>
          }
          {current === 2 &&
            <Button
              type="primary"
              loading={this.state.loading}
              size="large"
              onClick={() => this.submitRequest()}
            >
              Conferma i dati
            </Button>
          }

        </div>
        }
      </div>
    );
  }
}

export default RegistrazioneEnte;

//export default connect(({UserStore, GlobalStore}) => ({UserStore, GlobalStore}))(FormConnected);
//export default Form.create()(LoginForm)
