import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';


import ModelServices from '../services/RichiesteQualifiche';

import SchedaRichiestaRepertorio from '../components/SchedaRichiestaRepertorio';

import SpinnerLoading from '@components/SpinnerLoading';
import PannelloStatoRichiestaRepertorio from '../components/PannelloStatoRichiestaRepertorio';

@Wrapper({ route: 'RichiestaRepertorioRoute', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewRichiesteRepertorio', 'adminPermissions'])
class RichiestaRepertorioRoute extends Component {

  state = {
    loading: false,
    data: null,
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true }, () => {
      this.loadDetail();
    });
  }

  loadDetail() {
    ModelServices.detail({
      expand: [
        'media', 
        'responsabile', 
        'audit',
        'audit.user',
        'audit.integrazione',
        'audit.note',
        'comunicazioneOstativa',
        'comunicazioneOstativa.controdeduzioni',
        'comunicazioneOstativa.controdeduzioni.media'
      ].join(',')
    }, this.props.match.params.id_repertorio || this.props.match.params.id,
      res => {
        console.warn('yyyyyy', res.data)
        this.setState(
          {
            data: res.data.data,
            loading: false,
            refreshing: false,
          },
        );
      },
      () => {
        this.setState({ refreshing: false, loading: false });
      }
    )
  }

  renderTitle() {
    return this.state.data?.json?.denominazione || 'Nuova richiesta di repertorio';
  }

  refresh() {
    this.setState({ refreshing: true });
    this.loadDetail();
  }

  refreshInline(data) {

    this.setState({
      data: { ...this.state.data, ...data }
    });
  }


  render() {

    return (

      <div className="scheda" style={{opacity: this.state.refreshing ? 0.6 : 1}} >
        {this.state.loading || !this.state.data ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
              <p>Richieste di repertorio</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/repertorio/richieste">Repertorio</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <PannelloStatoRichiestaRepertorio {...this.props} repertorio={this.state.data} refresh={() => this.refresh()} refreshInline={(data) => this.refreshInline(data)} />
            <SchedaRichiestaRepertorio {...this.props} refresh={() => this.refresh()} refreshInline={(data) => this.refreshInline(data)} repertorio={this.state.data} progress_obj={this.state.data?.progress || { progress: 0, valid_sections: [] }} />
          </>
        }
      </div>
    );
  }
}

export default RichiestaRepertorioRoute;
