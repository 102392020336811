import CommonForm from "@common/components/form";
import RisorseUmaneTable2 from "@modules/affidamento/components/ProgrammazioneDidattica/edizione/RisorseUmaneTable2";
import { Form, message } from "antd";
import { Component } from "react";
import progettoService from "@modules/affidamento/services/Progetto";
import React from 'react';

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Dichiarazioni rilasciate da parte del Legale rappresentante dell’Ente ed impegni assunti:",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Marca da bollo € 16.00 sull’istanza di autorizzazione",
                name: "marca_da_bollo",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note Marca da Bollo",
                name: "marca_da_bollo_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "marca_da_bollo",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che lui stesso e i componenti degli eventuali organi collegiali non hanno riportato sentenze penali di condanna passate in giudicato per qualsiasi reato che incida sulla loro moralità professionale o per delitti finanziari;",
                name: "no_sentenze_penali_legale_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_sentenze_penali_legale_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_sentenze_penali_legale_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che lui stesso e i componenti degli eventuali organi collegiali non hanno commesso, nell’esercizio della propria attività professionale, un errore grave, accertato dall’Amministrazione pubblica con qualsiasi mezzo di prova;",
                name: "no_errore_grave_legale_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_errore_grave_legale_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_errore_grave_legale_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che lui stesso, nonché l’Ente dallo stesso rappresentato e i componenti degli eventuali organi collegiali,sono in regola con gli obblighi relativi al pagamento delle imposte e delle tasse nonché con gli obblighi relativi al pagamento dei contributi previdenziali, assistenziali e contributivi a favore dei propri dipendenti;",
                name: "in_regola_pagamenti_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "in_regola_pagamenti_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "in_regola_pagamenti_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che lui stesso e i componenti degli eventuali organi collegiali non sono stati dichiarati falliti e/o non hanno in corso una procedura fallimentare,  non sono sottoposti a misure di prevenzione e che non sussistono cause di divieto, decadenza o sospensione di cui al D.Lgs 159/2011;",
                name: "no_fallimento_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_fallimento_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_fallimento_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che non sussistono rapporti coniugali o di parentela e/o affinità tra il sottoscritto o tra  i componenti degli eventuali organi collegiali o i dipendenti dell’Ente rappresentato, con i dirigenti e i dipendenti della  Regione Calabria deputati alla trattazione del procedimento di che trattasi;",
                name: "no_rapporti_coniugali_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_rapporti_coniugali_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_rapporti_coniugali_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di non stipulare contratti di lavoro o attribuire incarichi a ex dipendenti pubblici, in violazione dell’art. 53 co.16-ter del D. Lgs 165 del 30 marzo 2001;",
                name: "no_contratti_ex_dip_pubblici_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_contratti_ex_dip_pubblici_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_contratti_ex_dip_pubblici_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di ammettere al corso esclusivamente  soggetti che abbiano i requisiti formativi e i titoli previsti dal progetto formativo e dalla normativa comunitaria, nazionale e regionale vigente.",
                name: "ammettere_soggetti_requ_formativi_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "ammettere_soggetti_requ_formativi_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "ammettere_soggetti_requ_formativi_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di inserire, per ciascun corso, un numero di allievi non superiore alle capacità logistiche e funzionali della sede.",
                name: "inserimento_congruo_allievi_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "inserimento_congruo_allievi_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "inserimento_congruo_allievi_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di accettare e non ostacolare il controllo della Regione Calabria, anche mediante ispezioni a cura dell’Unità Territoriale competente, al fine di accertare il regolare svolgimento dell'attività formativa;",
                name: "accettazione_controllo_regione_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "accettazione_controllo_regione_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "accettazione_controllo_regione_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di impegnarsi a  comunicare, tempestivamente, alla Regione Calabria tutte le variazioni intervenute sulle condizioni e sui requisiti che hanno costituito la base per il riconoscimento del corso.",
                name: "impegno_comunicazione_tempestiva_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "impegno_comunicazione_tempestiva_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "impegno_comunicazione_tempestiva_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di rispettare la normativa comunitaria, nazionale e regionale in materia di formazione professionale;",
                name: "rispetto_normativa_comunitaria_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "rispetto_normativa_comunitaria_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "rispetto_normativa_comunitaria_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di rispettare la normativa in materia fiscale, previdenziale, di sicurezza sui luoghi di lavoro (con particolare riguardo al D.Lgs 81/2008) e di lavoro dei disabili;",
                name: "rispetto_normativa_fiscale_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "rispetto_normativa_fiscale_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "rispetto_normativa_fiscale_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che per la realizzazione dell’attività formativa in argomento, nessun onere finanziario diretto o indiretto è a carico della Regione Calabria e che nessun finanziamento sarà richiesto ad altri Organismi pubblici;",
                name: "no_finanziamento_richiesto_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_finanziamento_richiesto_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_finanziamento_richiesto_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di non richiedere ai partecipanti al percorso formativo spese extra, che non siano comprese nella retta di partecipazione al percorso formativo in argomento;",
                name: "no_spese_extra_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_spese_extra_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_spese_extra_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di essere a conoscenza che eventuali irregolarità riscontrate nella gestione dell’attività formativa, possono comportare sanzioni fino alla revoca dell’autorizzazione all’avvio del corso ovvero del riconoscimento o della presa d’atto del progetto e di impegnarsi, in tal caso, alla restituzione della somma eventualmente versata dai partecipanti a titolo di retta per la partecipazione al corso.",
                name: "no_conoscenza_irregolarita_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_conoscenza_irregolarita_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_conoscenza_irregolarita_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di non avere in corso contenziosi con la Regione Calabria;",
                name: "no_contenziosi_regione_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_contenziosi_regione_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_contenziosi_regione_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Ci impegnarsi a comunicare tempestivamente al competente Settore della Regione Calabria, ogni eventuale variazione circa l’attività programmata (es. variazione orario, docenti, eventuali sospensioni, dimissione allievi, ecc.);",
                name: "impegno_comunicazione_regione_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "impegno_comunicazione_regione_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "impegno_comunicazione_regione_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di impegnarsi a fornire tutte le eventuali informazioni e i dati necessari per il monitoraggio fisico-procedurale, secondo le modalità indicate dalla Regione Calabria;",
                name: "impegno_informazioni_monitoraggio_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "impegno_informazioni_monitoraggio_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "impegno_informazioni_monitoraggio_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di impegnarsi a verificare i requisiti in ingresso dei richiedenti la partecipazione al percorso formativo e ad ammettere allo stesso solo coloro che hanno i requisiti previsti dalla normativa e, per i cittadini stranieri, a verificare il possesso delle capacità linguistiche;",
                name: "impegno_verifica_requisiti_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "impegno_verifica_requisiti_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "impegno_verifica_requisiti_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di impegnarsi ad eseguire il progetto con la dovuta diligenza necessaria al raggiungimento degli obiettivi  preventivati e secondo criteri di adeguata qualità;",
                name: "impegno_diligenza_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "impegno_diligenza_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "impegno_diligenza_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Di assicurare tutte le condizioni – in termini di dotazioni tecniche/tecnologiche e ambientali e di risorse umane - idonee per lo svolgimento del progetto garantendo altresì, una volta avviato, la conclusione dello stesso anche in  presenza di un solo partecipante.",
                name: "impegno_dotazioni_tecniche_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "impegno_dotazioni_tecniche_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "impegno_dotazioni_tecniche_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Che nessuna modifica è stata apportata successivamente alla data della eventuale visita preliminare/accreditamento",
                name: "no_modifiche_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "no_modifiche_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "no_modifiche_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Indicazione del medico competente.",
                name: "indicazione_medico_competente_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "indicazione_medico_competente_rappresentante_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "indicazione_medico_competente_rappresentante",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Autocertificazione Antimafia",
                name: "autocertificazione_antimafia",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "autocertificazione_antimafia_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "autocertificazione_antimafia",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Copia di un documento di identità in corso di validità del Legale Rappresentante",
                name: "copia_documento",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "copia_documento_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "copia_documento",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Format contratto da stipulare con gli allievi",
                name: "format_contratto_allievi",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "format_contratto_allievi_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "format_contratto_allievi",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Format domanda di iscrizione allievi.",
                name: "format_iscrizione_allievi",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "format_iscrizione_allievi_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "format_iscrizione_allievi",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Copia Nomina medico competente in corso di validità.",
                name: "copia_nomina_medico",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "copia_nomina_medico_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "copia_nomina_medico_allievi",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Copertina D.V.R. D.lgs. 81/2008",
                name: "copertina",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "copertina_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "copertina",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Formulario di Progetto del corso:",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Pagine firmate ovvero presenza di firma digitale",
                name: "pagine_firmate",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "pagine_firmate_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "pagine_firmate",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Descrizione del profilo o dell’AdA",
                name: "descrizione_profilo",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "descrizione_profilo_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "descrizione_profilo",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Codice ATECO",
                name: "codice_ateco",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "codice_ateco_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "codice_ateco",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Requisiti accesso allievi.",
                name: "requisiti_accessi",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "requisiti_accessi_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "requisiti_accessi",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Retta allievi",
                name: "retta_allievi",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "retta_allievi_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "retta_allievi",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Durata del corso.",
                name: "durata_corso",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "durata_corso_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "durata_corso",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Programmazione didattica modulare.",
                name: "programmazione_modulare",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "programmazione_modulare_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "programmazione_modulare",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Elenco personale docente e non docente",
                name: "ammettere_soggetti_requ_formativi_rappresentante",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "elenco_personale_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "elenco_personale",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Curricula personale impegnato nel corso",
                name: "Curriculum personale",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "curriculum_personale_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "curriculum_personale",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Presenza dei prototipi dei prodotti che l’Ente intende usare per la pubblicizzazione",
                name: "prototipi",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "prototipi_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "prototipi",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Altro",
                name: "altro",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "altro_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "altro",
                    operator: "==",
                    value: "1",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Sezione 3: Verifica Competenza Regione Calabria",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Di competenza",
                name: "pagine_firmate",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "di_competenza_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "di_competenza",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "NON di pertinenza in quanto di competenza di: (ad es. Dipartimento …., Ministero …..)",
                name: "di_pertinenza",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "di_pertinenza_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "di_pertinenza",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Sezione 4: Verifiche per Autorizzazione Corso",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h6",
                text: "Profilo in uscita compatibile con: ",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Repertorio Nazionale delle qualifiche e dei diplomi di Istruzione e formazione professionale (Accordo Stato-Regioni 137/CSR del 27 luglio 2011 e ss. mm. e ii.",
                name: "repertorio_nazionale_qualifiche",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "repertorio_nazionale_qualifiche_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "repertorio_nazionale_qualifiche",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Repertorio Regionale delle Qualificazioni e delle Competenze (D.G.R. n. 335 del 2015 ss. mm. ii.)",
                name: "repertorio_regionale_qualifiche",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "repertorio_regionale_qualifiche_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "repertorio_regionale_qualifiche",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label: "Formazione regolamentata",
                name: "fomrazione_regolamentata",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "fomrazione_regolamentata_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "fomrazione_regolamentata",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Coerenza dell’Attestato rilasciato con il profilo in uscita",
                name: "coerenza_attestato",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h6",
                text: "Conformità del programma del corso agli indirizzi e all'articolazione didattica di cui alla L.R. 18/85 e agli specifici riferimenti normativi, nazionali laddove esistenti, ovvero a quelli contenuti nel Repertorio Regionale delle Qualificazioni e delle Competenze",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 13,
            items: [
              {
                type: "radio",
                label:
                  "Programma conforme agli standard formativi minimi nazionali e/o regionali",
                name: "programma_conforme",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  },
                ],
              },
            ],
          },
          {
            span: 11,
            items: [
              {
                type: "input",
                input_type: "text",
                label: "Note",
                name: "programma_conforme_note",
                showIf: [
                  {
                    type: "field_validation",
                    field: "programma_conforme",
                    operator: "==",
                    value: "0",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "organigramma_table",
                type: "child",
                child: (
                  <div style={{ marginBottom: 16 }}>
                    <RisorseUmaneTable2
                      values={component.getValues}
                      progetto_padre={{ id: component.props.id_progetto }}
                      loading={component.state.loading}
                      {...component.props}
                      validOrganigramma={component.validOrganigramma}
                      validDocente={component.validDocente}
                      validUtenzaSpeciale={component.validUtenzaSpeciale}
                      checkOrganigramma={component.checkOrganigramma}
                      checkDocente={component.checkDocente}
                      checkUtenzaSpeciale={component.checkUtenzaSpeciale}
                    />
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit",
                text: "Salva",
                props: {
                  className: "bg-green",
                  //type: "primary",
                  icon: "save",
                },
                //onClick: 'showDrawer'
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submitted",
  };
};

class SchedaIstruttoriaProgettiFormativi extends Component {
  constructor(props) {
    super(props);
    this.validOrganigramma = this.validOrganigramma.bind(this);
    this.validDocente = this.validDocente.bind(this);
    this.validUtenzaSpeciale = this.validUtenzaSpeciale.bind(this);
    this.checkOrganigramma = this.checkOrganigramma.bind(this);
    this.checkDocente = this.checkDocente.bind(this);
    this.checkUtenzaSpeciale = this.checkUtenzaSpeciale.bind(this);
    
  }

  state = { validDocenti: this.props.progetto.istruttoria_progetti? this.props.progetto.istruttoria_progetti.validDocenti : {} , validOrganigramma: this.props.progetto.istruttoria_progetti? this.props.progetto.istruttoria_progetti.validOrganigramma : {} , validUtenzaSpeciale:this.props.progetto.istruttoria_progetti? this.props.progetto.istruttoria_progetti.validUtenzaSpeciale : {} };

  async confirm(data) {
   let dati = {...data, 'validDocenti' : this.state.validDocenti,'validUtenzaSpeciale' : this.state.validUtenzaSpeciale,'validOrganigramma' : this.state.validOrganigramma, };
   let istruttoria_data = {"ducati" : dati}
    if (!this.state.loading) {
      await this.setState({ loading: true });
      await this.setState({ loading: false });



      

      progettoService.saveIstruttoria(
        istruttoria_data, 
        this.props.id_progetto,
        (res) => {
          this.setState({
            loading: false,
            model: res.data.data,
          });
          message.success("Dati salvati");
          if (this.props.onUpdate) this.props.onUpdate();
        }, 
        
        (err) => this.setState({ loading: false })
      );
    }
  }

  getValues() {
    let obj = this.props.progetto.istruttoria_progetti
    return obj;
  }

  checkOrganigramma(e, record) {
    let f = this.state.validOrganigramma;
    f[record.id_funzione] = e.target.value;
    this.setState({ validOrganigramma: f });
  }
  checkDocente(e, record) {
    let f = this.state.validDocenti;
    f[record.cf] = e.target.value;
    this.setState({ validDocenti: f });
  }
  checkUtenzaSpeciale(e, record) {
    let f = this.state.validUtenzaSpeciale;
    f[record.id_funzione] = e.target.value;
    this.setState({ validUtenzaSpeciale: f });
  }

  validOrganigramma(record) {
    return this.state.validOrganigramma[record.id_funzione] || 0;
  }
  validDocente(record) {
    return this.state.validDocenti[record.cf] || 0;
  }
  validUtenzaSpeciale(record) {
    return this.state.validUtenzaSpeciale[record.id_funzione] || 0;
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          submitted={(dati) => this.confirm(dati)}
          values={this.getValues()}
          readOnly={this.props.progetto.conIstruttore[0]}

        />
      </div>
    );
  }
}

export default Form.create()(SchedaIstruttoriaProgettiFormativi);
