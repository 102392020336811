import React, { Component } from 'react';
import { message } from 'antd';
import moment from 'moment';
import Documenti from '@common/components/Documenti';
import CommonForm from '@common/components/form';
import format from '@common/utils/formatters/_';
import allegatiStatic from '@common/utils/static/allegati'

const formLayout = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'radio',
                label: 'Indicare il possesso del certificato ISO UNI EN 9001-settore 37- o versioni successive',
                name: 'certificato_ISO_UNI_EN_9001_settore_37',
                onChange: 'changeIso',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No",
                    //disabled: (component.props.tipo_accreditamento && component.props.tipo_accreditamento == accreditamentoStatic.tipo.definitivo) ? true : false
                  }
                ],
                validations:
                  component.props.isDefinitivoOrRinnovo ?
                    [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      },
                      {
                        pattern: '^1$',
                        message: 'Seleziona si'
                      }
                    ] :
                    [
                      {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                      }
                    ]
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'datepicker',
                label: 'Data di conseguimento',
                name: 'data_conseguimento',
                props: {
                  //size: 'large',
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'certificato_ISO_UNI_EN_9001_settore_37',
                    operator: '==',
                    value: '1'
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'pastDate'
                  }
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'datepicker',
                label: 'Data di scadenza',
                name: 'data_scadenza',
                props: {
                  //size: 'large',
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'certificato_ISO_UNI_EN_9001_settore_37',
                    operator: '==',
                    value: '1'
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'futureDate'
                  },
                  {
                    validator: 'afterConseguimento'
                  }
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'datepicker',
                label: 'Data ultimo rinnovo',
                name: 'data_ultimo_rinnovo',
                props: {
                  //size: 'large',
                  style: { width: '100%' },
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'certificato_ISO_UNI_EN_9001_settore_37',
                    operator: '==',
                    value: '1'
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'pastDate'
                  },
                  {
                    validator: 'afterConseguimento'
                  }
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                input_type: 'text',
                label: 'Ente certificatore',
                name: 'ente_certificatore',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'certificato_ISO_UNI_EN_9001_settore_37',
                    operator: '==',
                    value: '1'
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                input_type: 'text',
                label: 'Numero certificazione',
                name: 'numero_certificato',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'certificato_ISO_UNI_EN_9001_settore_37',
                    operator: '==',
                    value: '1'
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      }
    ],
    onSubmit: 'onSubmit',
  }
};

export default class IsoCertificatoForm extends Component {
  state = {
    loading: false,
    files: {},
    has_iso: 0,
    media_files: []
  };

  componentDidMount() {
    this.setState({
      media_files: this.props.ente.media,
      has_iso: this.props.values && parseInt(this.getValues().certificato_ISO_UNI_EN_9001_settore_37) === 1
    });
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.ente !== this.props.ente) {
      this.setState({ media_files: this.props.ente.media });
    }

    if (prevProps.values !== this.props.values) {
      this.setState({ has_iso: this.props.values && parseInt(this.getValues().certificato_ISO_UNI_EN_9001_settore_37) === 1 });
    }
  }

 /* UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.ente !== this.props.ente) {
      this.setState({ media_files: newProps.ente.media });
    }

    if (newProps.values !== this.props.values) {
      this.setState({ has_iso: newProps.values && parseInt(newProps.values.certificato_ISO_UNI_EN_9001_settore_37) === 1 });
    }
  } */

  async validate() {
    return new Promise((resolve, reject) => {
      if (!this.props.isDefinitivoOrRinnovo && this.getFormData().certificato_ISO_UNI_EN_9001_settore_37 === '0') {
        resolve(true);
      } else {

        this.formRef.props.form.validateFields((errors, values) => {
          if (!errors || errors.length === 0) {

            if (this.state.media_files.filter(e => e.tipo.sottodominio === 'certificato_qualita' && e.tipo.dominio === 'ente' && e.deleted !== 1).length === 0) {
              message.error('Inserisci il certificato di qualità');
              resolve(false)
            }

            if (this.props.isDefinitivoOrRinnovo && this.state.media_files.filter(e => e.tipo.sottodominio === 'allegato_iso_9001' && e.tipo.dominio === 'ente' && e.deleted !== 1).length === 0) {
              message.error('Inserisci il documento di certificazione ISO 9001');
              resolve(false)
            }

            resolve(true);
          } else {
            message.error('Ricontrolla i campi');
            resolve(false);
          }
        });
      }
    });
  }

  getValues() {
    return format.convertBoolean([
      'certificato_ISO_UNI_EN_9001_settore_37'
    ], format.replaceFormDatesIn(['data_conseguimento', 'data_ultimo_rinnovo', 'data_scadenza'], this.props.values))
  }

  getFormData() {
    return format.replaceFormDatesOut(['data_conseguimento', 'data_ultimo_rinnovo', 'data_scadenza'], this.formRef.props.form.getFieldsValue());
  }

  initDataForm() {

  }

  render() { 

    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;
    const allMedias = this.state.media_files;
    let requiredMedia = '';
    let mediaIdToSearch;

    let isRealReadOnlyAllegatoIso9001 = isRealReadOnly;
    if(isRealReadOnly){
      let mediaTypes = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento);
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'allegato_iso_9001')[0];

      mediaTypes.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyAllegatoIso9001 = found;
      }
    }

    let isRealReadOnlyCertificatoQualita = isRealReadOnly;
    if(isRealReadOnly){
      let mediaTypes = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento);
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_qualita')[0];

      mediaTypes.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyCertificatoQualita = found;
      }
    }

    let isRealReadOnlyQualita = isRealReadOnly;
    if(isRealReadOnly){
      let mediaTypes = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento);
      mediaIdToSearch = 0;
      requiredMedia = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'qualita')[0];

      mediaTypes.map(element => {
        if(element.label === requiredMedia){
          mediaIdToSearch = element.value;
        }
      });
      if(mediaIdToSearch !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch){
            found = true;
          }
        })
        isRealReadOnlyQualita = found;
      }
    }

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          onSubmit={(values) => null}
          form_model={formLayout(this)}
          values={this.getValues()}
          changeIso={(name, value) => {
            let new_val = parseInt(value.target.value) === 1;
            this.setState({ has_iso: new_val })
          }}
          readOnly={this.props.readOnly}
          pastDate={(rule, value, cb) => {
            if (moment() < value) cb("Inserisci una data passata");
            else cb()
          }}
          futureDate={(rule, value, cb) => {
            if (moment() > value) cb("Inserisci una data futura");
            else cb()
          }}
          afterConseguimento={(rule, value, cb) => {
            if (this.formRef.props.form.getFieldsValue()['data_conseguimento'] > value) cb("Inserisci una data successiva al conseguimento");
            else cb()
          }}
        />
        {
          this.props.isDefinitivoOrRinnovo && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'allegato_iso_9001', this.props.accreditamento.tipo_accreditamento)
            ?
            <Documenti
              canDelete={!isRealReadOnlyAllegatoIso9001}
              canAddNote={false}
              accreditation_id={this.props.accreditamento.id}
              media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
              medias={this.state.media_files}
              unique={true}
              current_domain="ente"
              current_subdomain="allegato_iso_9001"
              required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'allegato_iso_9001')}
              ref_id={this.props.ente.id}
              readOnly={isRealReadOnlyAllegatoIso9001}
              onUpload={(f) => this.setState({ media_files: [...this.state.media_files, f] })}
              onDelete={(id) => this.setState({ media_files: this.state.media_files.filter(e => e.id !== id) })}
            />
            : null
        }
        {this.state.has_iso && allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_qualita', this.props.accreditamento.tipo_accreditamento) ?
          <Documenti
            canDelete={!isRealReadOnlyCertificatoQualita}
            canAddNote={false}
            accreditation_id={this.props.accreditamento.id}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.state.media_files}
            unique={true}
            current_domain="ente"
            current_subdomain="certificato_qualita"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'certificato_qualita')}
            ref_id={this.props.ente.id}
            readOnly={isRealReadOnlyCertificatoQualita}
            onUpload={(f) => this.setState({ media_files: [...this.state.media_files, f] })}
            onDelete={(id) => this.setState({ media_files: this.state.media_files.filter(e => e.id !== id) })}
          />
          : null}
        { allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'qualita', this.props.accreditamento.tipo_accreditamento) &&
          <Documenti
            canDelete={!this.props.readOnly}
            canAddNote={false}
            accreditation_id={this.props.accreditamento.id}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)} 
            medias={this.state.media_files}
            unique={true}
            current_domain="ente"
            current_subdomain="qualita"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'qualita')}
            ref_id={this.props.ente.id}
            readOnly={this.props.readOnly}
            onUpload={(f) => this.setState({ media_files: [...this.state.media_files, f] })}
            onDelete={(id) => this.setState({ media_files: this.state.media_files.filter(e => e.id !== id) })}
          />
        }
      </div>
    );
  }
}

