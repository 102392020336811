import React, { Component } from 'react';
import { connect } from "react-redux";
import nominaStatic from '@common/utils/static/nomina';
import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';

const getStato = (record, component) => {
  let stato = 'ente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  if(checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';


  return record.stato_flusso_formatted['stato_'+stato]
}

class SingleNominaKo extends Component {

  state = {
    loaded: false,
    loading: false,
    downloading: false
  }



  render() {
    return <div>
        <p>Stato: <strong>{getStato(this.props.nomina, this)}</strong><br />
        {this.props.nomina.ispettore.username} - {this.props.nomina.ispettore.anagrafica.nome} {this.props.nomina.ispettore.anagrafica.cognome}<br />
        {this.props.nomina.note_ispettore && this.props.nomina.note_ispettore !== '' ? <span><strong>Note:</strong> {this.props.nomina.note_ispettore}</span> : ''}
        </p>
        {
          this.props.nomina.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_feedback_ispettore).map(l => {
            return <div key={l.id}><p style={{ display: 'inline-block' }}>Documento firmato dall&apos;ispettore: </p><DocSpan key={l.id} file={l} /></div>
          })
        }
      </div>
    }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SingleNominaKo);