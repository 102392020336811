import React, { Component } from 'react';

import { Icon, message } from 'antd';

import CommonForm from '@common/components/form';



const formLayout = () => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Telefono',
                                type_string: 'text', // field type
                                name: 'telefono',
                                props: {
                                    // size: 'large',
                                    prefix: <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio'
                                    },
                                    {
                                      pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                      message: 'Telefono non valido'
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Cellulare',
                                type_string: 'text', // field type
                                name: 'cellulare',
                                props: {
                                    // size: 'large',
                                    prefix: <Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio'
                                    },
                                    {
                                      pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                      message: 'Telefono non valido'
                                    }
                                ],
                            },
                        ]
                    }
                ],
            },
            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Fax',
                                type_string: 'text', // field type
                                name: 'fax',
                                props: {
                                    // size: 'large',
                                    prefix: <Icon type="file" style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                            },
                        ]
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Email',
                                type_string: 'text', // field type
                                name: 'email',
                                props: {
                                    // size: 'large',
                                    prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                            },
                        ]
                    }
                ],
            },
    ],
    onSubmit: 'submitted',
  }
}

class RecapitoForm extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni_nascita: [],
    files: {},
    fine_lucro: null,
    divisione: null,
  };



  async componentDidMount() {

  }



  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  getFormData() {
    return this.formRef.props.form.getFieldsValue();
  }


  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout()}
          values={
            this.props.values
          }
          readOnly={this.props.readOnly}

        />
      </div>
    );
  }
}

export default RecapitoForm;