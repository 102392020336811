import React, { Component } from 'react';
import { Table, Icon, Row, Col, Select, Button, Modal, Radio, Spin, Tooltip, Drawer, message } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import LezioniServices from '@affidamento/services/Lezioni';
import CorsiServices from '@affidamento/services/Corsi';
import CommonForm from '@components/form';
import affidamentoStatic from '@common/utils/static/affidamento';
import checkPermission from '@common/utils/check/permissions';
import { Link } from 'react-router-dom';
import LezioneServices from '@affidamento/services/Lezioni';
import QrIscrizione from '@modules/affidamento/forms/courseRegistration/QrIscrizione';

const formLayout = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows:
      [
        {
          cols:
            [
              {
                items:
                  [
                    {
                      type: 'textarea',
                      name: 'descrizione_stage',
                      label: 'Descrizione delle attività svolte nello stage',
                      placeholder: 'Descrizione breve',
                      props: {
                        autoSize: { minRows: 3, maxRows: 5 },
                      }
                    },
                  ],
              },
            ],
        },
        {
          props: {
            style: { float: 'right' }
          },
          cols:
            [
              {
                items:
                  [
                    {
                      span: 2,
                      name: 'submit',
                      type: 'submit',
                      text: 'Modifica',
                      btn_type: 'primary',
                      props: {
                        icon: 'save',
                        className: 'bg-green'
                      },
                      showIf: [
                        {
                         // type: 'custom_func',
                         // func: () => { return component.canEditProgramma() && component.isEditableState() }
                        }
                      ],
                    }
                  ]
              }
            ]
        }
      ],
    onSubmit: 'submit',
  };
}

const formLayoutIngresso = (component) => {
  const valueAssente = component.props.lesson.presenze.find(obj => obj.presente === false);
  const lastIngressoObject = component.props.lesson.presenze.reduce((previousValue, currentValue) => {
    return currentValue.tipo === "INGRESSO" ? currentValue : previousValue;
  }, null);
  const presenza_dalle = lastIngressoObject !== null ? lastIngressoObject.timestamp : null;
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                //label: 'Registra ingresso',
                name: 'fromTime',
                placeholder: presenza_dalle !== null ? moment.unix(presenza_dalle).format("HH:mm") : 'Orario',
                props: {
                  style: { width: '100%' },
                  //disabled: (presenza_dalle !== null || valueAssente === true) ? true : false
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_time'
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                name: 'btn',
                type: 'submit',
                text: 'Registra ingresso',
                btn_type: 'primary',
                props: {
                  //disabled: (presenza_dalle !== null || valueAssente === true) ? true : false
                },
              },
            ],
          },
        ]
      },
    ],
    onSubmit: 'submitted',
  }
}

const formLayoutUscita = (component) => {
  const valueAssente = component.props.lesson.presenze.find(obj => obj.presente === false);
  const lastIngressoObject = component.props.lesson.presenze.reduce((previousValue, currentValue) => {
    return currentValue.tipo === "USCITA" ? currentValue : previousValue;
  }, null);
  const presenza_alle = lastIngressoObject !== null ? lastIngressoObject.timestamp : null;
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                //label: 'Registra ingresso',
                name: 'fromTime',
                placeholder: presenza_alle !== null ? moment.unix(presenza_alle).format("HH:mm") : 'Orario',
                props: {
                  style: { width: '100%' },
                  //disabled: (presenza_alle !== null || valueAssente === true) ? true : false
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_time'
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                name: 'btn',
                type: 'submit',
                text: 'Registra uscita',
                btn_type: 'primary',
                props: {
                  //disabled: (presenza_alle !== null || valueAssente === true) ? true : false
                },
              },
            ],
          },
        ]
      },
    ],
    onSubmit: 'submitted',
  }
}

function getDateRange(component, lessons) {
  let columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: element => <div>
        {component.canViewDescrizioneStage(element) &&
                <Tooltip placement="top" title={component.canEditDescrizioneStage()? "Aggiungi attività del Discente" : "Visualizza le attività del discente"}>
                  <Button className="m10w" size="small" shape="circle" icon={component.canEditDescrizioneStage()? "edit" : "eye"} onClick={() => component.setState({ open_drawer: true, element_opened: element })} />
                </Tooltip>}
      </div>,
    },
    {
      title: 'Discente',
      dataIndex: '',
      key: 'cognome',
      render: element => <div>
        {checkPermission.hasPermission(component.props.UserStore.user, ['viewDiscentiPerProgetto']) && component.props.allowRedirect ?
          <Link to={"/affidamento/registro/" + component.props.corso?.id + "/discente/" + element.id}>
            {element.anagrafica ? element.anagrafica.cognome + ' ' + element.anagrafica.nome : element.cognome + ' ' + element.nome}
          </Link>
          : <>{ element.anagrafica ? element.anagrafica.cognome + ' ' + element.anagrafica.nome : element.cognome + ' ' + element.nome } </>
        }
      </div>,
    }
  ];
  let now = moment();
  lessons.forEach(l => {
    let day = moment(l.data);
    columns.push(
      {
        title: day.format('DD/MM'),
        dataIndex: '',
        key: l.id, //day.format('DD/MM/YYYY'),
        className: 'ant-table-row-center',
        render: element => {
          const valuePresente = component.props.lesson.presenze.find(obj => obj.id_iscrizione === element.id && obj.presente === true);
          const presenze = component.props.lesson.presenze.filter(obj => obj.id_iscrizione === element.id && obj.presente === true);
          const lastIngressoObject = presenze.reduce((previousValue, currentValue) => {
            return currentValue.tipo === "INGRESSO" ? currentValue : previousValue;
          }, null);
          const lastUscitaObject = presenze.reduce((previousValue, currentValue) => {
            return currentValue.tipo === "USCITA" ? currentValue : previousValue;
          }, null);
          
          return <div>
          {!day.isAfter(now) && !day.isBefore(element.data_iscrizione) &&
            <>
            {
              <div style={{ minWidth: 160 }}>
                <b> {affidamentoStatic.getMateriaLabel(l.id_materia)} </b>
              </div>
            }
            {
              valuePresente &&
              <div style={{ minWidth: 160 }}>
                <Icon type="check" style={{ color: 'green' }} shape="circle"/> Presente
              </div>
            }
            {
              valuePresente && lastIngressoObject !== null &&
              <div style={{ minWidth: 160 }}>
                <Icon type="arrow-right" style={{ color: 'green' }} shape="circle"/> Ingresso ore {moment.unix(lastIngressoObject.timestamp).format("HH:mm")} [Fonte: {lastIngressoObject.source}]
              </div>
            }
            {
              valuePresente && lastUscitaObject !== null &&
              <div style={{ minWidth: 160 }}>
                <Icon type="arrow-left" style={{ color: 'green' }} shape="circle"/> Uscita ore {moment.unix(lastUscitaObject.timestamp).format("HH:mm")} [Fonte: {lastUscitaObject.source}]
              </div>
            }
            {
              component.canEditPresenza(element) &&
              <div style={{ minWidth: 160 }}>
                <Button size="small" type="link" shape="circle" icon="edit" onClick={() => component.editPresenza(element, day)} /> Aggiorna registro
              </div>
            }
            
            </>
          }
        </div>
        }
      }
    );
  })

  return columns;
}

export default class PresenzeGiornaliere extends Component {
  static propTypes = {
    lesson: PropTypes.object,
  };

  state = {
    data: null,
    loading: false,
    start: moment().startOf('month'),
    end: moment().endOf('month'),
    open_modal: false,
    edit: null,
    open_drawerQRIN: false,
    open_drawerQROUT: false,
    urlIn: null,
    urlOut: null,
    tipo_lezione: this.props.lezione ? this.props.lezione.id_tipo_lezione : null ,
    handler_button_qr_disable: true
  };

  componentDidMount() {
    
    this.load(this.props.corso?.id);
    
  }

  load(id) {
    this.fetchData();
    this.loadUrls(this.state.tipo_lezione);
    if(this.state.tipo_lezione!= null) {
      this.loadButtonState();
    }
  }

  loadUrls(tipo_lezione) {
    if(checkPermission.hasPermission(this.props.UserStore.user, ['docentePermissions'])) {
      if(tipo_lezione == affidamentoStatic.tipo_lezione.aula) {
        this.setState({urlIn: "affidamento/creazioneqr/qrlezionedocenteingressofip/"})
        this.setState({urlOut: "affidamento/creazioneqr/qrlezionedocenteuscitafip/"})
      }
      else if(tipo_lezione == affidamentoStatic.tipo_lezione.el_sincrono) {
        this.setState({urlIn: "affidamento/creazioneqr/qrlezionedocenteingressofadsincrona/"})
        this.setState({urlOut: "affidamento/creazioneqr/qrlezionedocenteuscitafadsincrona/"})
      }else {
        this.setState({urlIn: false})
        this.setState({urlOut: false})
      }
    }else if(checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])) {
      if(tipo_lezione == affidamentoStatic.tipo_lezione.el_asincrono) {
        this.setState({urlIn: "affidamento/creazioneqr/qrlezionediscenteingressofadasincrona/"})
        this.setState({urlOut: "affidamento/creazioneqr/qrlezionediscenteuscitafadasincrona/"})
      }
      else if(tipo_lezione == affidamentoStatic.tipo_lezione.el_sincrono) {
        this.setState({urlIn: "affidamento/creazioneqr/qrlezionediscenteingressofadsincrona/"})
        this.setState({urlOut: "affidamento/creazioneqr/qrlezionediscenteuscitafadsincrona/"})
      }else {
        this.setState({urlIn: false})
        this.setState({urlOut: false})
      }
    }
  }

  loadButtonState() {

    const lessonDate = moment(this.props.lesson.data).format('DD-MM-YYYY');
    let today = moment().format('DD-MM-YYYY');
    const endLessonTime = moment(this.props.lesson.alle, 'HH:mm:ss').format('HH:mm:ss')
    const actualHour = moment().format('HH:mm:ss')

    if(today == lessonDate && endLessonTime >= actualHour ) {
      this.setState({handler_button_qr_disable: false})
    }

  }


  headerRender(value, onChange) {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let index = start; index < end; index++) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`}>
          {months[index]}
        </Select.Option>,
      );
    }
    const month = value.month();

    const year = value.year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      );
    }
    return (
      <div style={{ padding: 10 }}>
        <Row type="flex" style={{ flexDirection: 'row-reverse' }}>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              className="my-year-select"
              onChange={newYear => {
                const now = value.clone().year(newYear);
                onChange && onChange(now);
              }}
              value={String(year)}
            >
              {options}
            </Select>
          </Col>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              value={String(month)}
              onChange={selectedMonth => {
                const newValue = value.clone();
                newValue.month(parseInt(selectedMonth));
                onChange && onChange(newValue);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }

  onChangeDate(date) {
    this.setState({
      start: date.startOf('month').clone(),
      end: date.endOf('month').clone(),
    }, () => {
      this.fetchData();
    })
  }

  canEditDescrizioneStage() {   
    return (checkPermission.hasPermission(this.props.UserStore.user, ['editMyDescrizioneStage']) && this.props.corso?.tutors?.some(t => t.id === this.props.UserStore.user.anagrafica.id))
  }

  canViewDescrizioneStage(element) {
    if (!this.props.lesson) return false;
   
    if (!this.props.lesson.stage) return false;

    let presenza = element.presenze.find(p => p.id_lezione === this.props.lesson.id);
    if(!(presenza && presenza.presente)) return false;

    return true;

  }

  canEditPresenza(presenza) {
    if (!this.props.lesson)
      return false;

    if (this.props.corso?.stato !== affidamentoStatic.stato_progetto.erogazione)
      return false;

    return (checkPermission.hasPermission(this.props.UserStore.user, ['editMyPresenze']) && presenza.id_anagrafica === this.props.UserStore.user.anagrafica.id)
    ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['editOwnPresenze']) &&
        (
          (this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.props.corso?.ente.id) ||
          (this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.props.lesson?.progetto.id_ente) ||
          this.props.lezione.docente?.id === this.props.UserStore.user.anagrafica.id ||
          this.props.corso?.tutors?.some(t => t.id === this.props.UserStore.user.anagrafica.id)
        )
      )
    //|| checkPermission.hasPermission(this.props.UserStore.user, ['editPresenze']);
  }

  editPresenza(presenza, date) {
    this.setState({
      open_modal: true,
      edit: { ...presenza.presenze[0], id_iscrizione: presenza.id, anagrafica: { nome: presenza.nome, cognome: presenza.cognome, id: presenza.id_anagrafica } }
    });
  }

  updatePresenza(presenza) {
    this.setState({ loading: true });
    LezioniServices.detail({ fields: 'presenze', expand: 'presenze' }, presenza.id_lezione, res => {
      this.props.lesson.presenze = res.data.data.presenze;
      this.setState({ open_modal: false, loading: false }, () => this.fetchData());
    }, () => this.setState({ loading: false }))

  }

  setRegistraIngresso(presenza, data) {
    this.setState({ loading: true });

    presenza.presente = true;
    presenza.tipo = 'INGRESSO';
    presenza.orario_ingresso = data.fromTime.format('HH:mm');
    LezioniServices.setPresenza(
      presenza,
      presenza.id_lezione,
      () => {
        LezioniServices.detail({ fields: 'presenze', expand: 'presenze' }, presenza.id_lezione, res => {
          this.props.lesson.presenze = res.data.data.presenze;
          this.setState({ open_modal: false, loading: false }, () => this.fetchData());
        }, () => this.setState({ loading: false }))
      },
      () => this.setState({ loading: false })
    );

  }

  setRegistraUscita(presenza, data) {
    this.setState({ loading: true });

    presenza.presente = true;
    presenza.tipo = 'USCITA';
    presenza.orario_uscita = data.fromTime.format('HH:mm');
    LezioniServices.setPresenza(
      presenza,
      presenza.id_lezione,
      () => {
        LezioniServices.detail({ fields: 'presenze', expand: 'presenze' }, presenza.id_lezione, res => {
          this.props.lesson.presenze = res.data.data.presenze;
          this.setState({ open_modal: false, loading: false }, () => this.fetchData());
        }, () => this.setState({ loading: false }))
      },
      () => this.setState({ loading: false })
    );

  }

  setRegistraAssenza(presenza) {
    this.setState({ loading: true });

    presenza.presente = false;
    presenza.tipo = null;
    LezioniServices.setPresenza(
      presenza,
      presenza.id_lezione,
      () => {
        LezioniServices.detail({ fields: 'presenze', expand: 'presenze' }, presenza.id_lezione, res => {
          this.props.lesson.presenze = res.data.data.presenze;
          this.setState({ open_modal: false, loading: false }, () => this.fetchData());
        }, () => this.setState({ loading: false }))
      },
      () => this.setState({ loading: false })
    );

  }

  fetchData() {
    let {start, end} = this.state;
    this.setState({
      loading: true,
    });

      let req = null;

      if (this.props.lesson)
        req = [
          //LezioniServices.list({ id: this.props.lesson.id }, this.props.corso.id),
          Promise.resolve({ data: { data: { list: [this.props.lesson] } } }),
          CorsiServices.listPresenze({lezione: this.props.lesson.id }, this.props.lesson.id_progetto)
        ];
      else
        req = [
          LezioniServices.list({ dal: start.format('YYYY-MM-DD'), al: end.format('YYYY-MM-DD') }, this.props.corso.id),
          CorsiServices.listPresenze({  dal: start.format('YYYY-MM-DD'), al: end.format('YYYY-MM-DD') }, this.props.corso.id)
        ];

      Promise.all(req).then(res => {

        let [res1, res2] = res;

        let map = [];
        res2.data.data.forEach(d => {
          let entry = map[d.id_iscrizione];
          if (!entry) {
            entry = {
              id: d.id_iscrizione,
              id_cil: d.id,
              nome: d.nome,
              cognome: d.cognome,
              data_iscrizione: moment(d.data_iscrizione),
              presenze: [],
              descrizione_stage: d.descrizione_stage
            };
            map[d.id_iscrizione] = entry;
          }
          entry.presenze.push({
            id_lezione: d.id_lezione || this.props.lesson?.id,
            data: d.data || this.props.lesson?.data,
            dalle: d.dalle || this.props.lesson?.dalle,
            alle: d.alle || this.props.lesson?.alle,
            presente: d.presente, 
          });
        });

        let data = Array.from(map.values()).filter(Boolean);

        this.setState({
          data: data,
          columns: getDateRange(this, res1.data.data.list),
          loading: false,
        });

      }).catch(r => {
        this.setState({
          loading: false,
        });
      });

  }

  render() {
    let {start/*, end*/} = this.state;
    if (this.props.lesson) {
      start = this.props.lesson.date;
      //end = this.props.lesson.date;
    }

    return (
      <div className="no-margin">
        {/** inizio blocco docente */}
        { checkPermission.hasPermission(this.props.UserStore.user, ['docentePermissions']) && this.state.tipo_lezione != null && 
          <>
            {this.state.urlIn  &&
              <>
                <Button style={{margin:10}}
                  type="primary"
                  icon="plus"
                  disabled={this.state.handler_button_qr_disable}
                  onClick={()=>this.setState({open_drawerQRIN: true})}>
                  Genera Qr Ingresso lezione
                </Button>
                <Drawer
                  title="Genera Qr Ingresso lezione"
                  placement="right"
                  closable={true}
                  onClose={() => this.setState({ open_drawerQRIN: false })}
                  visible={this.state.open_drawerQRIN}
                  width={650}
                  destroyOnClose={true}
                >
                  <QrIscrizione
                    registration_id={this.props.lezione.id}
                    url={this.state.urlIn}                
                  />
                </Drawer>
              </>
            }

            {this.state.urlOut &&
              <>
                <Button style={{margin:10}}
                  type="primary"
                  icon="plus"
                  disabled={this.state.handler_button_qr_disable}
                  onClick={()=>this.setState({open_drawerQROUT: true})}>
                  Genera Qr Uscita lezione
                </Button>
                <Drawer
                  title="Genera Qr Uscita lezione"
                  placement="right"
                  closable={true}
                  onClose={() => this.setState({ open_drawerQROUT: false })}
                  visible={this.state.open_drawerQROUT}
                  width={650}
                  destroyOnClose={true}
                >
                  <QrIscrizione
                    registration_id={this.props.lezione.id}
                    url={this.state.urlOut}                  
                  />                
                </Drawer>
              </>
            }
          </>
        }
        {/** fine blocco docente */}

        {/** inizio blocco discente */}

        { checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions']) && this.state.tipo_lezione != null  &&
          <>
            {this.state.urlIn  &&
              <>
                <Button style={{margin:10}}
                  type="primary"
                  icon="plus"
                  disabled={this.state.handler_button_qr_disable}
                  onClick={()=>this.setState({open_drawerQRIN: true})}>
                  Genera Qr Ingresso lezione
                </Button>
                <Drawer
                  title="Genera Qr Ingresso lezione"
                  placement="right"
                  closable={true}
                  onClose={() => this.setState({ open_drawerQRIN: false })}
                  visible={this.state.open_drawerQRIN}
                  width={650}
                  destroyOnClose={true}
                >          
                  <QrIscrizione
                    registration_id={this.props.lezione.id}
                    url={this.state.urlIn}              
                  />
                </Drawer>
              </>
            } 
            
            {this.state.urlOut &&
              <>
                <Button style={{margin:10}}
                  type="primary"
                  icon="plus"
                  disabled={this.state.handler_button_qr_disable}
                  onClick={()=>this.setState({open_drawerQROUT: true})}>
                  Genera Qr Uscita lezione
                </Button>
                <Drawer
                  title="Genera Qr Uscita lezione"
                  placement="right"
                  closable={true}
                  onClose={() => this.setState({ open_drawerQROUT: false })}
                  visible={this.state.open_drawerQROUT}
                  width={650}
                  destroyOnClose={true}
                >
                  <QrIscrizione
                    registration_id={this.props.lezione.id}
                    url={this.state.urlOut}          
                  />
                </Drawer>
              </>
            }
          </>
        }
        {/** fine blocco discente */}

        {!this.props.lesson && this.headerRender(start, this.onChangeDate.bind(this))}
        {this.state.loading && <Spin className="spin-overlay" />}
        <Table
          dataSource={this.state.data}
          columns={this.state.columns}
          pagination={false}
          rowKey="id"
          style={{ opacity: this.state.loading ? 0.6 : 1 }}
        />

        {/*this.props.lesson &&*/ this.state.edit &&
          <Modal
            confirmLoading={this.state.loading}
            visible={this.state.open_modal}
            onOk={() => this.updatePresenza(this.state.edit)}
            width="300px"
            onCancel={() => this.setState({ open_modal: false })}
          >
            <p style={{marginTop: 20}}>
              Segna la presenza del discente {this.state.edit.anagrafica.nome} {this.state.edit.anagrafica.cognome} per la lezione del {moment(this.state.edit.data).format('DD/MM/YYYY')} dalle {this.state.edit.dalle} alle  {this.state.edit.alle}
            </p>
            <Row>
              <Col lg={24} sm={24} xs={24}>
                <div id="actions">
                  <h4 className="p10h">Presenza</h4>
                  <CommonForm
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    form_model={formLayoutIngresso(this)}
                    submitted={(data) => this.setRegistraIngresso(this.state.edit, data)}
                    
                    min_time={(rule, value, cb) => {
                      if (!value || value.length === 0) cb();
                      let min = value;

                      let max = this.formSelRef.props.form.getFieldsValue()['toTime'];

                      if (max && min > max) cb("L'orario di avvio deve essere minore dell'orario di conclusione");
                      else cb()
                    }}
                    max_time={(rule, value, cb) => {
                      if (!value || value.length === 0) cb();
                      let max = value;

                      let min = this.formSelRef.props.form.getFieldsValue()['fromTime'];

                      if (min && min > max) cb("L'orario di avvio deve essere minore dell'orario di conclusione");
                      else cb()
                    }}
                  />
                  <CommonForm
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    form_model={formLayoutUscita(this)}
                    submitted={(data) => this.setRegistraUscita(this.state.edit, data)}
                    
                    min_time={(rule, value, cb) => {
                      if (!value || value.length === 0) cb();
                      let min = value;

                      let max = this.formSelRef.props.form.getFieldsValue()['toTime'];

                      if (max && min > max) cb("L'orario deve essere minore dell'orario di conclusione");
                      else cb()
                    }}
                    max_time={(rule, value, cb) => {
                      if (!value || value.length === 0) cb();
                      let max = value;

                      let min = this.formSelRef.props.form.getFieldsValue()['fromTime'];

                      if (min && min > max) cb("L'orario deve essere minore dell'orario di conclusione");
                      else cb()
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24} sm={24} xs={24}>
                <div id="actions">
                  <h4 className="p10h">Assenza</h4>
                  <Button 
                  icon="file-pdf" 
                  ghost={this.props.lesson?.presenze !== null && this.props.lesson.presenze.length > 0} 
                  className="m5h m5w" 
                  type="danger" 
                  loading={this.props.loading || this.state.loading} 
                  onClick={() => { this.setRegistraAssenza(this.state.edit) }}
                  disabled={( this.props.lesson?.presenze !== null && this.props.lesson.presenze.length > 0 )? true : false}
                  >
                    Assente
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal>
        }

        {this.state.open_drawer &&
          <Drawer
            title="ATTIVITÀ SVOLTE NELLO STAGE"
            placement="right"
            closable={true}
            onClose={() => this.setState({ open_drawer: false })}
            visible={this.state.open_drawer}
            width={window.innerWidth - (window.innerWidth / 100 * 5)}
            destroyOnClose={true}
          >
           <CommonForm
          readOnly={!this.canEditDescrizioneStage(this.state.element_opened)}
          wrappedComponentRef={inst => (this.formNoteRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={this.state.element_opened}
          submit={data => {
            this.setState({ loading: true });
            LezioneServices.editDescrizioneStage(data, this.state.element_opened.id_cil,
              (res) => {
                this.setState({ loading: false, open_drawer: false }, () => this.fetchData());
                message.success('Attività aggiunte con successo');
              },
              () => this.setState({ loading: false })
            );
          }}
        />
          </Drawer>
        }

      </div>
    )
  }
}
