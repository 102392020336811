import React, { Component } from 'react';
import { Button,Row, Col } from 'antd';
import Wrapper from '@common/decorators/Wrapper';
import AuthServices from '../services/Auth';
import '../script/actions';
//import '../script/spid-sp-access-button.min';
import '../../../spid_css/spid.css'

@Wrapper({ route: 'Login', hasSidebar: false, hasHeader: true, hasFooter: true })

class samlSsoResponse extends Component {

  state = {
    loading: false,
    mounted: false,
    loginerror: null
  }

  componentDidMount() {
    try {
      const search = this.props.location.search;
      const samlData = new URLSearchParams(search).get("samlData");
  
      AuthServices.verifySamlData(samlData, async (response_user) => {
        // login andato a buon fine, posso impostare utente
  
        localStorage.setItem('token', response_user.data.data.token);
        localStorage.setItem('provider', response_user.data.data.provider);
        await this
          .props
          .dispatch({
            type: 'SET_USER_STORE',
            obj: {
              jwtProvider: response_user.data.data.provider,
              jwt: response_user.data.data.token,
              loggedIn: true,
              user: response_user.data.data.user,
              role: response_user.data.data.user.role.name
            }
          });
      }, async (e) => {
        let loginerror = JSON.parse(e.response.data.data.message);
        if(loginerror.NOT_FOUND_CF){
          loginerror.NOT_FOUND_CF.primary_text = "Utente senza ruolo";
          loginerror.NOT_FOUND_CF.secondary_text = "Gentile utente, non ha associato alcun ruolo su SISFO. Se rappresenta un ente di formazione deve prima fare la registrazione cliccando sul tasto Registra il tuo ente.";
        }
        this.setState({loginerror: loginerror});
        return false;
      })
    } catch (err) {
      console.warn(err)
    }
  }

  renderCreateButtons(){
    return <React.Fragment>
      {
        this.state.loginerror.NOT_FOUND_CF && 
        this.state.loginerror.NOT_FOUND_CF.primary_text &&
        <h3 className="sectionTitle" style={{ textAlign: 'center' }}>{this.state.loginerror.NOT_FOUND_CF.primary_text}</h3>
      }
      {
        this.state.loginerror.NOT_FOUND_CF && 
        this.state.loginerror.NOT_FOUND_CF.secondary_text &&
        <h4 className="sectionTitle" style={{ textAlign: 'center' }}>{this.state.loginerror.NOT_FOUND_CF.secondary_text}</h4>
      }
      <Row type="flex" justify="center">
        <Col lg={8} md={24} xs={24} sm={24} style={{ margin: '40px auto' }}>
          
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => {
            localStorage.removeItem('token');
            this.props.dispatch({ type: 'LOGOUT' });
        
            if(localStorage.getItem('provider') === null){
              this.props.history.push('/login');
            } else {
              const apiUrl = this.props.GlobalStore.API_URL;
              const baseUrl = this.props.GlobalStore.BASE_URL;
              const returnTo = encodeURIComponent(`${baseUrl}#`);
              console.warn("returnTo", returnTo)
              window.location.href = `${apiUrl}auth/saml/slo?returnTo=${returnTo}`;
              
              return null;
            }
          }}>Logout da Calabria LOGIN</Button>
          <br/>
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/')}>Home</Button>
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/accreditamento')}>Registra il tuo ente</Button>
          {/*
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/learner')}>Registrazione Discente</Button>
          <Button type="primary" size="large" className="green-form-button m5h" block={true} onClick={() => this.props.history.push('/utente-generico')}>Registrazione Utente</Button>
          */}
        </Col>
      </Row>
    </React.Fragment>
  }

  render() {

    return (
      <Row type="flex" justify="center">
        <Col lg={24} md={24} xs={24} sm={24}>
          {
            this.state.loginerror === null ?
            <h3 className="sectionTitle" style={{ textAlign: 'center' }}>Accesso in corso ...</h3>
            : 
              this.state.loginerror.NOT_FOUND_CF ?
              this.renderCreateButtons()
              : <h3 className="sectionTitle" style={{ textAlign: 'center' }}>Errore generico in fase di login</h3>
          }
        </Col>
      </Row>
    );
  }
}

export default samlSsoResponse;