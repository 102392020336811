import React, { Component } from 'react';
import moment from 'moment';

import { message } from 'antd';
import CommonForm from '@common/components/form';

import formatter from '@common/utils/formatters/_';

import Estremi from './accreditamento/Estremi';

import { notification } from 'antd';
import Documenti from '@common/components/Documenti';
import allegatiStatic from '@common/utils/static/allegati'

const formLayout = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Tipologia di attività formativa',
                name: 'id_tipologia',
                mode: 'tags',
                onChange: 'setTipoAccreditamento',
                props: {
                  disabled: (component.props.readOnly && !component.props.enableMacrotipologie),
                  // size: 'large',
                  // showSearch: true,
                  // filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  onDeselect: value => {
                    let tipologia = component.props.accreditamento.accreditamentotipologie.find(e => e.id_tipologia === parseInt(value));
                    if(component.props.enableMacrotipologie){
                      notification.warn({
                        message: "Attenzione",
                        description: `In questo momento è possibile solo rimuovere macrotipologie già selezionate dall'utente.`,
                      });
                    } else if (tipologia) {
                      let label = component.props.GlobalStore.stubs.tipo_accreditamento.find(e => e.value === parseInt(value)).label;
                      notification.warn({
                        message: "Attenzione",
                        description: `Al salvataggio verrà rimosso il personale dall'organigramma, verrà rimossa la macrotipologia "${label}" dalle sedi e dai docenti a cui è associata e verranno rimossi i docenti che non hanno altre macrotipologie.`,
                      });
                    }
                  },
                  /*
                  onSelect: e => {
                    console.error("PROPS", component.props);
                    if (component.props.enableMacrotipologie) {
                      notification.warn({
                        message: "Attenzione",
                        description: `In questo momento non è possibile aggiungere, ma solo rimuovere le macrotipologie.`,
                      });
                    }
                  },*/
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'tipologia_accreditamento',
                //onChange: 'selectedResidenzaProvincia',
              },
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Formazione a distanza',
                name: 'formazione_a_distanza',
                option_type: 'button',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ]
              }
            ],
          },
        ],
      },/*
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Settori',
                name: 'id_settore',
                mode: 'tags',
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'tipo_settore',
              },
            ],
          },

        ],
      },*/
      /*{ 
        cols: [
          {
            items: [
              {
                name: '_settori',
                type: 'child',
                child: <div style={{marginBottom: 16}}>
                <Settori
                  readOnly={component.props.readOnly}
                  ref={component.settoriRef}
                  GlobalStore={component.props.GlobalStore}
                  tipi_accreditamento={component.state.tipi_accreditamento}
                  id_settore={component.state.id_settore}
                /></div>
              }
            ],
          },
        ],
      },*/
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Utenze speciali per le quali viene richiesto accreditamento',
                name: 'id_utenza_speciale',
                mode: 'tags',
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  //showSearch: true,
                  //filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  // {
                  //   type: 'required',
                  //   error_message: 'Campo obbligatorio',
                  // },
                ],
                options_props: 'utenze_speciali',
                //onChange: 'selectedResidenzaProvincia',
              },
              {
                name: 'requisito_art_6',
                type: 'child',
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'existsUtenteSpeciale',

                  },

                ],
                child: 
                  <Documenti
                    canDelete={component.props.readOnly}
                    canAddNote={false}
                    media_types={allegatiStatic.filteredAccreditationMedia(component.props.GlobalStore.stubs.tipo_media, component.props.accreditamento.tipo_accreditamento)}
                    medias={component.props.accreditamento.ente.media}
                    current_domain="ente"
                    unique={true}
                    current_subdomain="requisiti_art_6"
                    required_types={allegatiStatic.filteredRequiredMedia(component.props.GlobalStore.stubs.tipo_media, 'ente', 'requisiti_art_6')}
                    ref_id={component.props.accreditamento.ente.id}
                    readOnly={component.props.readOnly}
                    accreditation_id={component.props.accreditamento.id}
                  />
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Ha svolto, in precedenza per almeno un anno, attività di formazione professionale per l’utenza speciale di cui alla domanda di accreditamento con le Regioni, Ministero del lavoro o l’Unione Europea',
                name: 'formazione_speciale_anno',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                initialValue: "0",
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'existsUtenteSpeciale',

                  },

                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Ha svolto in precedenza, per almeno un anno, una delle seguenti attività: sociale, educativa, formativa e assistenziale',
                name: 'formazione_speciale_assistenza',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                initialValue: "0",
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'existsUtenteSpeciale',

                  },

                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Ha svolto, in precedenza per almeno tre anni, attività sociali, professionali, educative ed assistenziali per l’utenza speciale di cui alla domanda con finanziamento pubblico',
                name: 'formazione_speciale_treanni',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'existsUtenteSpeciale',

                  },

                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'È un’Associazione di Categoria, riconosciuta per legge, di tutela o rappresentanza per utenza speciale di cui alla domanda',
                name: 'formazione_speciale_associazione',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'existsUtenteSpeciale',

                  },

                ],
              }
            ],
          },
        ],
      },
      {
        cols: [{
          span: 24,
          items: [
            {
              type: 'label',
              name: 'soggetto_presso_accreditato_fake',
              text: 'Il Soggetto è già accreditato presso',
            }
          ]
        }]
      },
      {
        cols: [
          {
            span: 4,
            items: [
              {

                type: 'radio',
                label: 'Altra Regione',
                name: 'tipo_soggetto_accreditato_1',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],

                props: {
                  disabled: component.props.readOnly,
                  onChange: (obj) => {
                    component.setState({ tipo_soggetto_accreditato_1: obj.target.value });
                  },
                }
              }
            ]
          },
          {
            span: 10,
            items: [
              {
                type: 'select',
                label: 'Regioni',
                name: 'tipo_soggetto_accreditato_regioni',
                mode: 'multiple',
                onChange: 'changeRegione',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_regioni',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_1',
                    operator: '==',
                    value: '1'
                  }
                ]
              },
            ],
          },
          {
            span: 10,
            items: [
              {
                name: 'estremi_soggetto_accreditato_regioni',
                type: 'child',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_1',
                    operator: '==',
                    value: '1'
                  }
                ],
                child: <div style={{marginBottom: 16}}>
                <Estremi
                  readOnly={component.props.readOnly}
                  ref={component.estremiRefRegioni}
                  stubs={component.props.GlobalStore.stubs.regione}
                  parents={component.state.soggetto_accreditato_regioni}
                  initialValue={component.getAccreditatoEstremi(1)}
                /></div>
              }/*
              {
                type: 'input',
                label: 'Indicare gli estremi del provvedimento di accreditamento',
                name: 'estremi_provvedimento_1',
                props: {
                  disabled: component.props.readOnly,
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_1',
                    operator: '==',
                    value: '1'
                  }
                ]
              },*/
            ]
          }
        ]

      }
      ,
      {
        cols: [
          {
            span: 4,
            items: [
              {
                type: 'radio',
                label: 'Provincia Autonoma',
                name: 'tipo_soggetto_accreditato_2',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  onChange: (obj) => {
                    component.setState({ tipo_soggetto_accreditato_2: obj.target.value });
                  },

                }

              }
            ]
          },
          {
            span: 10,
            items: [
              {
                type: 'select',
                label: 'Province',
                name: 'tipo_soggetto_accreditato_province',
                onChange: 'changeProvincia',
                mode: 'multiple',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_province_autonome',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_2',
                    operator: '==',
                    value: '1'
                  }
                ]
              },
            ],
          },
          {
            span: 10,
            items: [
              {
                name: 'estremi_soggetto_accreditato_province',
                type: 'child',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_2',
                    operator: '==',
                    value: '1'
                  }
                ],
                child: <div style={{marginBottom: 16}}>
                <Estremi
                  readOnly={component.props.readOnly}
                  ref={component.estremiRefProvince}
                  stubs={component.props.GlobalStore.stubs.provincia.filter(p => p.autonoma === 1)}
                  parents={component.state.soggetto_accreditato_province}
                  initialValue={component.getAccreditatoEstremi(2)}
                /></div>
              }/*
              {
                type: 'input',
                label: 'Indicare gli estremi del provvedimento di accreditamento',
                name: 'estremi_provvedimento_2',
                props: {
                  disabled: component.props.readOnly,
                  style: { width: '100%' }
                }, validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_2',
                    operator: '==',
                    value: '1'
                  }
                ]

              },*/
            ]
          }
        ]

      }
      ,
      {
        cols: [
          {
            span: 4,
            items: [
              {
                /*type:'checkbox',
                options_props: 'tipo_soggetto_accreditato',
                name: 'tipo_soggetto_accreditato_3',
                label: 'Stato Aderente all’UE',
                */
                type: 'radio',
                label: 'Stato Aderente all’UE',
                name: 'tipo_soggetto_accreditato_3',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  onChange: (obj) => {
                    component.setState({ tipo_soggetto_accreditato_3: obj.target.value });
                  }
                }
              }
            ]
          },
          {
            span: 10,
            items: [
              {
                type: 'select',
                label: 'Nazioni',
                name: 'tipo_soggetto_accreditato_nazioni',
                onChange: 'changeNazione',
                mode: 'multiple',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_nazioni_eu',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_3',
                    operator: '==',
                    value: '1'
                  }
                ]
              },
            ],
          },
          {
            span: 10,
            items: [
              {
                name: 'estremi_soggetto_accreditato_nazioni',
                type: 'child',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_3',
                    operator: '==',
                    value: '1'
                  }
                ],
                child: <div style={{marginBottom: 16}}>
                <Estremi
                  readOnly={component.props.readOnly}
                  ref={component.estremiRefNazioni}
                  stubs={component.props.GlobalStore.stubs.nazione.filter(n => n.eu_member === 1)}
                  parents={component.state.soggetto_accreditato_nazioni}
                  initialValue={component.getAccreditatoEstremi(3)}
                /></div>
              }/*
              {
                type: 'input',
                label: 'Indicare gli estremi del provvedimento di accreditamento',
                name: 'estremi_provvedimento_3',
                props: {
                  disabled: component.props.readOnly,
                  style: { width: '100%' }
                }, validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'tipo_soggetto_accreditato_3',
                    operator: '==',
                    value: '1'
                  }
                ]

              },*/
            ]
          }
        ]

      },/* @todo rimuovere da db
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Nello statuto è espressamente prevista come finalità la formazione professionale',
                name: 'formazione_professionale',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'E\' previsto nell\'atto costitutivo o statuto il fine di lucro',
                name: 'fine_lucro_in_statuto',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ],
      },*/
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Nello statuto è specificato che gli utili derivanti dalla formazione, non sono divisi tra i soci ma sono reinvestiti in altra attività formativa',
                name: 'utili_divisi_soci',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  disabled: component.props.readOnly,
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'isFineLucro'
                  }
                ],
              }             
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Dichiaro che nello statuto è prevista, tra le finalità dell’Ente, l’attività di formazione',
                name: 'dichiarazione_formazione',
                option_type: 'button',
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  style: { textAlign: 'center' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          }
        ],
      },
    ],
    onSubmit: 'submitted',
  }
}

class DatiEnteForm extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni_nascita: [],
    files: {},
    fine_lucro: null,
    divisione: null,
    tipi_accreditamento: [],
    id_settore: [],
    soggetto_accreditato_regioni: [],
    soggetto_accreditato_province: [],
    soggetto_accreditato_nazioni: [],
    isFineLucro: false
  };

  constructor(props) {
    super(props);

    this.settoriRef = React.createRef();
    this.estremiRefRegioni = React.createRef();
    this.estremiRefProvince = React.createRef();
    this.estremiRefNazioni = React.createRef();


  }

  componentDidMount() {
    this.setState({
      loaded: true,
      tipi_accreditamento: this.props.accreditamento.accreditamentotipologie.map(el => el.id_tipologia.toString()),
      id_settore: this.props.accreditamento.accreditamentosettori,
      soggetto_accreditato_regioni: this.getAccreditatoValues(1),
      soggetto_accreditato_province: this.getAccreditatoValues(2),
      soggetto_accreditato_nazioni: this.getAccreditatoValues(3),
      isFineLucro: this.props.isFineLucro
    });

  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.isFineLucro !== this.props.isFineLucro) {
      this.setState({isFineLucro: newProps.isFineLucro})
    }
  }

  getFormData() {
    return {
      ...this.formRef.props.form.getFieldsValue(),
      estremi_provvedimento_1: (this.estremiRefRegioni.current) ? this.estremiRefRegioni.current.getFormData() : null,
      estremi_provvedimento_2: (this.estremiRefProvince.current) ? this.estremiRefProvince.current.getFormData() : null,
      estremi_provvedimento_3: (this.estremiRefNazioni.current) ? this.estremiRefNazioni.current.getFormData() : null
    };
  }



  async validate() {
    if(this.estremiRefRegioni.current && !this.estremiRefRegioni.current.validate()) return false;
    if(this.estremiRefProvince.current && !this.estremiRefProvince.current.validate()) return false;
    if(this.estremiRefNazioni.current && !this.estremiRefNazioni.current.validate()) return false;

    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  getAccreditatoValues(n){
    let item = this.props.accreditamento.entegiaaccreditatopresso.find(el => el.id_accreditato_presso === n);
    if( item ) {
      if(n === 2) console.error('errore', item);
      return JSON.parse( item.accreditamento_associazioni ).map(el => el.toString());
    } else {
      return [];
    }
  }

  getAccreditatoEstremi(n){
    let item = this.props.accreditamento.entegiaaccreditatopresso.find(el => el.id_accreditato_presso === n);
    if( item ) {
      return item.estremi_provvedimento
    } else {
      return [];
    }
  }

  getAccreditatoPresso() {
    let vals = {};
    for(let n = 1; n <= 3; n++) {
      let item = this.props.accreditamento.entegiaaccreditatopresso.find(el => el.id_accreditato_presso === n);
      if( item ) {
        vals['tipo_soggetto_accreditato_' + n ] = "1";
        vals['estremi_provvedimento_' + n ] = item.estremi_provvedimento;
      } else {
        vals['tipo_soggetto_accreditato_' + n ] = "0";
        vals['estremi_provvedimento_' + n ] = "";
      }

      switch( n ) {
        case 1:
          vals['tipo_soggetto_accreditato_regioni'] = (item) ? JSON.parse( item.accreditamento_associazioni ).map(el => el.toString()) : []
          //this.setState({soggetto_accreditato_regioni: vals['tipo_soggetto_accreditato_regioni']});
        break;
        case 2:
          vals['tipo_soggetto_accreditato_province'] = (item) ? JSON.parse( item.accreditamento_associazioni ).map(el => el.toString()) : []
          //this.setState({soggetto_accreditato_province: vals['tipo_soggetto_accreditato_province']});
        break;
        case 3:
          vals['tipo_soggetto_accreditato_nazioni'] = (item) ? JSON.parse( item.accreditamento_associazioni ).map(el => el.toString()) : []
          //this.setState({soggetto_accreditato_nazioni: vals['tipo_soggetto_accreditato_nazioni']});
        break;
        default: break;
      }


    }

    return vals;
  }

  getValues() {
    try {
      return formatter.convertBoolean([
        'formazione_speciale_anno',
        'formazione_speciale_treanni',
        'formazione_speciale_associazione',
        'formazione_professionale',
        'fine_lucro_in_statuto',
        'utili_divisi_soci',
        'dichiarazione_formazione',
        'tipo_soggetto_accreditato_1',
        'tipo_soggetto_accreditato_2',
        'tipo_soggetto_accreditato_3',
        'formazione_speciale_assistenza'
      ], {
        ...this.props.accreditamento.ente,
        id_tipologia: this.props.accreditamento.accreditamentotipologie.map(el => el.id_tipologia.toString()),
        id_settore: this.props.accreditamento.accreditamentosettori.map(el => el.id_settore.toString()),
        id_utenza_speciale: this.props.accreditamento.accreditamentoutenzespeciali.map(el => el.id.toString()),
        formazione_a_distanza: (this.props.accreditamento.formazione_a_distanza) ? "1" : "0",
        ...this.getAccreditatoPresso()
      })
    } catch(e) {
      return this.props.accreditamento
    }
  }

  getDateAccettazione() {

    try {
      return "Accettazione del trattamento dei dati personali esplicitata il " +
      moment(this.props.accreditamento.ente.accettazione_termini_data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') + " alle " + moment(this.props.accreditamento.ente.accettazione_termini_data, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
    } catch(e) {
      return "";
    }
  }

  async setTipoAccreditamento(value) {
    this.setState({tipi_accreditamento: value})
  }

  render() {
    let tipologieAccreditamento = this.props.GlobalStore.stubs.tipo_accreditamento;
    
    if(this.props.enableMacrotipologie){
      let arrayTipologieSelezionabili = [];
      this.props.accreditamento.accreditamentotipologie.map(elem => 
        {
        arrayTipologieSelezionabili.push(elem.id_tipologia)
      })
      tipologieAccreditamento = tipologieAccreditamento.filter(t => arrayTipologieSelezionabili.indexOf(t.value) !== -1);
    }
    return (
      <div>
        {this.state.loaded ?
        <React.Fragment>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}
            form_model={formLayout(this)}
            values={
              this.getValues()
            }
            readOnly={this.props.readOnly}

            existsUtenteSpeciale={(value, form) => {
              var arr = form.getFieldValue('id_utenza_speciale');
              return arr && arr.length > 0;
            }}

            isFineLucro={()=>this.state.isFineLucro}

            setTipoAccreditamento={(value)=>this.setTipoAccreditamento(value)}

            _regioni={this.props.GlobalStore.stubs.regione}
            _province_autonome={this.props.GlobalStore.stubs.provincia.filter(p => p.autonoma === 1)}
            _nazioni_eu={this.props.GlobalStore.stubs.nazione.filter(n => n.eu_member === 1)}

            tipologia_accreditamento={tipologieAccreditamento}
            tipo_settore={this.props.GlobalStore.stubs.settori}
            utenze_speciali={this.props.GlobalStore.stubs.utenze_speciali}
            
            tipo_soggetto_accreditato={this.props.GlobalStore.stubs.tipo_soggetto_accreditato}

            changeRegione={(value)=>this.setState({soggetto_accreditato_regioni: value})}
            changeProvincia={(value)=>this.setState({soggetto_accreditato_province: value})}
            changeNazione={(value)=>this.setState({soggetto_accreditato_nazioni: value})}
          />
          <p>{this.getDateAccettazione()}</p>
        </React.Fragment>
        : null}
      </div>
    );
  }
}

export default DatiEnteForm;