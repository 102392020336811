import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm, message, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Audit';

import moment from 'moment';

import FileSaver from 'file-saver';

import auditStatic from '@common/utils/static/audit';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';
import CaricamentoDocumentiIntegrazione from './CaricamentoDocumentiIntegrazione';
import DocumentiCaricati from './DocumentiCaricati';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica file p7m o pdf firmato",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};

const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};



class SingleIntegrazione extends Component {

  state = {
    loaded: false,
    open_modal_integrate_file: false,
    accreditamento: {
      id: null
    },
    audit: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false
  }

  componentDidMount() {
    if (this.props.accreditamento.id && this.props.audit.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id && this.props.audit.id &&
      (newProps.accreditamento !== this.props.accreditamento || newProps.audit !== this.props.audit)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, audit: props.audit, loaded: true });
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, ['integrazioni.pdf']);

    //this.props.refresh();
  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload(file) {
    if(!this.state.loading) {
      this.setState({loading: true}, () => {
        //console.log('ff', this.state.file)
        ModelServices.integrazione_file_upload(this.state.accreditamento.id, this.props.integrazione.id, {
          filename: 'file'/*,
          note: this.state.note || "",
          valido_dal: this.state.valido_dal || "",
          valido_al: this.state.valido_al || "",*/
        }, { file: file, name: file.name, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_doc: false, loading: false });

        }, (err) => this.setState({open_modal_doc: false, loading: false}))
      })

    }
  }

  async uploadFile(file) {
    await this.setState({ file: { file: file, name: file.name }, open_modal_doc: true });

    return;

  }

  renderRequestFileFirmato() {
    return <div style={{ marginTop: 18 }}>
      {
        this.props.integrazione.media.filter(el => el.tipo.sottodominio === auditStatic.tipo_media.sottodominio_integrazione_firmata).map(l => {
          return <div><p style={{ display: 'inline-block' }}>Integrazione documentale firmata: </p><DocSpan key={l.id} file={l} /></div>
        })
      }
    </div>
  }

  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) &&
      this.props.accreditamento.istruttore && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id
      ? true : false
  }

  canView() {

    return (
      this.props.audit.id_user === this.props.UserStore.user.id ||
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      this.isIstruttore() ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) &&
        this.props.accreditamento.id_user === this.props.UserStore.user.id)
    ) ? true : false;
  }

  /**
   * chi ha creato l'audit
   */
  canGiveFeedback() {
    return (this.props.audit.id_user === this.props.UserStore.user.id && this.props.integrazione.stato === auditStatic.stato_integrazione.inviata
    ) ? true : false;
  }



  /**
   * l'ente
   */
  canGiveIntegrazione() {
    return this.props.accreditamento.id_user === this.props.UserStore.user.id ? true : false;
  }

  canDownloadIntegrazione() {
    if(!this.hasIntegratedEnd()) return false;

    return this.props.integrazione.stato === auditStatic.stato_integrazione.integrazione_confermata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.da_firmare;
  }

  canUploadIntegrazione() {
    return this.props.integrazione.stato === auditStatic.stato_integrazione.da_firmare;
  }

  canConfirmIntegrazione() {
    return this.props.integrazione.stato === auditStatic.stato_integrazione.firmata;
  }

  canConfirm() {
    return parseInt(this.props.integrazione.stato) === parseInt(auditStatic.stato_integrazione.in_corso) && this.hasIntegratedEnd();
  }

  hasConfirmed() {
    return this.props.integrazione.stato === auditStatic.stato_integrazione.integrazione_confermata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.da_firmare ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.firmata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.inviata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.confermata ||
      this.props.integrazione.stato === auditStatic.stato_integrazione.rifiutata;

  }

  canIntegrate() {
    return this.canGiveIntegrazione() && !this.hasConfirmed()
  }

  hasIntegratedEnd() {
    let i = true;
    this.props.integrazione.requested_media.map(r => {
      if(!r.new_id) i = false;
      return r;
    });
    return i;
  }

  renderActions() {
    return <div>
      {
        this.canIntegrate() && !this.expiredIntegration()
        ?
        <div>
          <Button
            disabled={this.state.loading}
            className="m5h" type="primary" icon="upload" onClick={() => {
              this.setState({open_modal_integrate_file: true})
            }}>
            Integra la documentazione richiesta</Button>
            <Modal
              title="Documentazione richiesta"
              width="90%"
              destroyOnClose={true}
              visible={this.state.open_modal_integrate_file}
              onOk={async () => {
                this.setState({
                  open_modal_integrate_file: false
                })
              }}
              onCancel={() => this.setState({ open_modal_integrate_file: false })}
            >
              <CaricamentoDocumentiIntegrazione
                accreditamento={this.props.accreditamento}
                integrazione={this.props.integrazione}
                refreshInline={this.props.refreshInline}
                documents={this.props.integrazione.requested_media}
              />
            </Modal>
        </div>
        : null
      }
      {
        this.canConfirm()
        ?
        <React.Fragment>
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            ghost
            className="m5h" type="primary" icon="check" onClick={() => {
              this.setState({open_modal_confirm_integrazione: true})
            }}>Conferma i documenti caricati</Button>
            <Modal
              title="Inserisci note"
              visible={this.state.open_modal_confirm_integrazione}
              confirmLoading={this.state.loading}
              onOk={async () => {
                let note = this.formSelRef.props.form.getFieldsValue()['note'];
                if(note === '') {
                  message.error('Inserisci delle note');
                }
                if(!this.state.loading) {
                  await this.setState({loading: true});
                  ModelServices.confirmIntegrazione(this.props.accreditamento.id, this.props.integrazione.id, {note:note}, (res) => {
                    this.props.refreshInline(res.data.data);
                    this.setState({open_modal_confirm_integrazione: false, loading: false})
                  })
                }
              }}
              onCancel={() => this.setState({ open_modal_confirm_integrazione: false, loading: false })}
            >
              <CommonForm
                wrappedComponentRef={inst => (this.formSelRef = inst)}
                loading={this.state.loading || false}
                form_model={formSelLayout}
                values={{

                }}

              />
            </Modal>
        </React.Fragment>
        : null
      }
      {
        (this.canGiveIntegrazione())
          ?
          <div>
            {this.canDownloadIntegrazione() ?
              <div>
                <Button
                loading={this.state.loading}
                disabled={this.state.loading}
                className="m5h" type="primary" icon="download" onClick={() => {
                  if(!this.state.loading) {
                    this.setState({loading: true}, () => {
                      ModelServices.integrazione_file(
                        this.state.accreditamento.id,
                        this.props.integrazione.id,
                        (res) => {

                          this.props.refreshInline({
                              ...this.props.accreditamento,
                              audit: [
                                ...this.props.accreditamento.audit.filter(a => a.id !== this.props.audit.id),
                                {
                                  ...this.props.audit,
                                  integrazione: [
                                    ...this.props.audit.integrazione.filter(i => i.id !== this.props.integrazione.id),
                                    {
                                      ...this.props.integrazione,
                                      stato: auditStatic.stato_integrazione.da_firmare
                                    }
                                  ]
                                }
                              ]
                            });

                          this.saveBlob(res.data);
                          this.setState({loading: false});
                        },
                        (err) => console.error(err)
                      )
                    })
                  }
                }}>Scarica documento di riepilogo delle integrazioni</Button>
              </div> : null}
            {this.canUploadIntegrazione() ?
              <div>
                <p>Carica il file contenente la firma</p>
                <CommonForm
                  wrappedComponentRef={inst => (this.formRef = inst)}
                  loading={this.state.loading || false}
                  form_model={formLayout}
                  values={{
                  }}
                  fileListProp={() => []}
                  customUploadRequest={obj => {
                    this.upload(obj.file);
                  }}
                />{/*
                <Modal
                  title="Inserisci note facoltative"
                  visible={this.state.open_modal_doc}
                  onOk={async () => {
                    this.setState({
                      filename: 'file',
                      valido_dal: this.formSelRef.props.form.getFieldsValue()['valido_dal'] || "",
                      valido_al: this.formSelRef.props.form.getFieldsValue()['valido_al'] || "",
                      note: this.formSelRef.props.form.getFieldsValue()['note'] || ""
                    }, () => this.upload())
                  }}
                  onCancel={() => this.setState({ open_modal_doc: false })}
                >
                  <CommonForm
                    wrappedComponentRef={inst => (this.formSelRef = inst)}
                    loading={this.state.loading || false}
                    form_model={formSelLayout}
                    values={{

                    }}

                  />
                </Modal>*/}
              </div> : null}
            {/*this.canConfirmIntegrazione() ?
              <div>
                <p>Conferma il file e inoltra l'integrazione</p>
                <Popconfirm placement="top" title="Confermi l'invio dell'integrazione?"
                  onConfirm={() => {
                    if(!this.state.loading) {
                      this.setState({loading: true}, () => {
                        ModelServices.integrazione_send(this.state.accreditamento.id, this.props.integrazione.id, {},
                          (res) => {
                            this.props.refreshInline(res.data.data);
                            this.setState({loading: false});
                          },
                          (err) => this.setState({loading: false})
                        )
                      })
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  className="m5h" type="primary" icon="mail">Invia all'amministrazione</Button>
                </Popconfirm>
              </div> : null*/}
          </div>
          : null
      }
      {
        (this.canGiveFeedback())
          ?
          <div>
            <p style={{marginBottom: 0}}><strong>Esito integrazione documentale</strong></p>
            <Popconfirm placement="top" title="L'integrazione è valida?"
              onConfirm={() => {
                if(!this.state.loading) {
                  this.setState({
                    loading: true,
                    accepting: true
                  }, () => {
                    ModelServices.confirm_integrazione(this.state.accreditamento.id, this.props.integrazione.id, {},
                      (res) => {
                        this.props.refreshInline(res.data.data);
                        this.setState({loading: false, accepting: false})
                      },
                      (err) => this.setState({loading: false, accepting: false}))
                  });
                }
              }}
              okText="Si" cancelText="No">
              <Button
              loading={this.state.accepting}
              disabled={this.state.loading}
              style={{ marginRight: 8 }} className="m5h" type="primary" icon="check">Positivo</Button>
            </Popconfirm>
            <Popconfirm placement="top" title="Sicuro di voler rigettare la domanda?"
              onConfirm={() => {
                if(!this.state.loading) {
                  this.setState({
                    loading: true,
                    refusing: true
                  }, () => {
                    ModelServices.reject_integrazione(this.state.accreditamento.id, this.props.integrazione.id, {},
                      (res) => {
                        this.props.refreshInline(res.data.data);
                        this.setState({loading: false, refusing: false})
                      },
                      (err) => this.setState({loading: false, refusing: false})
                    )
                  });
                }
              }}
              okText="Si" cancelText="No">
              <Button
              loading={this.state.refusing}
              disabled={this.state.loading}
              className="m5h" type="danger" ghost icon="close">Negativo</Button>
            </Popconfirm>
          </div>
          : null
      }
    </div>
  }

  getDaysRemaining() {
    return (this.props.integrazione.stato < auditStatic.stato_integrazione.inviata ) && !this.expiredIntegration() ?
    '(' + (this.props.GlobalStore.stubs.days_integrazione - moment().diff(moment.unix(this.props.integrazione.created_at), 'days')) + ' giorni alla scadenza)'
    : (this.props.integrazione.stato < auditStatic.stato_integrazione.inviata) && this.expiredIntegration() ? '(tempo scaduto per le integrazioni)': ''
  }

  expiredIntegration(){
   return (this.props.GlobalStore.stubs.days_integrazione - moment().diff(moment.unix(this.props.integrazione.created_at), 'days')) < 0;
  }

  hasIntegrated() {
    return this.props.integrazione.stato >= auditStatic.stato_integrazione.inviata ? true : false
  }

  render() {
    return (this.state.loaded && this.canView()) ?
      <div>
        <p>Data richiesta integrazione documentale: <strong>{moment.unix(this.props.integrazione.created_at).format('DD/MM/YYYY')} </strong>{this.getDaysRemaining()}</p><br />
        {this.props.integrazione.note ? this.props.integrazione.note : ''}
        {
          this.isIstruttore() && this.hasIntegrated()
          ?
          <div>
            <Button
            disabled={this.state.loading}
            size="small"
            className="m5h" type="primary" icon="search" onClick={() => {
              this.setState({open_modal_integrate_file: true})
            }}>
            Vedi documenti caricati</Button>
            <Modal
              title="Documenti caricati"
              width="90%"
              destroyOnClose={true}
              visible={this.state.open_modal_integrate_file}
              onOk={async () => {
                this.setState({
                  open_modal_integrate_file: false
                })
              }}
              onCancel={() => this.setState({ open_modal_integrate_file: false })}
            >
              <DocumentiCaricati
                accreditamento={this.props.accreditamento}
                integrazione={this.props.integrazione}
                refreshInline={this.props.refreshInline}
                documents={this.props.integrazione.requested_media}
              />
            </Modal>
          </div>
          : null
        }
        {/*this.props.integrazione.note && this.props.integrazione.note.map(n => {
          return <div key={n.id}>
            <strong>{(n.user) ? n.user.username : " - "}</strong>: {n.note}
          </div>
        })*/}
        <div style={{ marginTop: 16, marginBottom: 8 }}>{this.renderRequestFileFirmato()}</div>
        {this.renderActions()}
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SingleIntegrazione);
