import React, { Component } from 'react';
import Documenti from '@common/components/Documenti';
import allegatiStatic from '@common/utils/static/allegati'

class FileRappresentanteLegale extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni_nascita: [],
    files: {}
  };



  async componentDidMount() {

  }





  render() {

    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;

    return (
      <div>
        <Documenti
          canDelete={!isRealReadOnly}
          canAddNote={false}
          unique={true}
          required_dates={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          medias={this.props.accreditamento.ente.media}
          current_domain="ente"
          current_subdomain="documenti_legale"
          required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'documenti_legale')}
          ref_id={this.props.accreditamento.ente.id}
          readOnly={isRealReadOnly}
          accreditation_id={this.props.accreditamento.id}
        />
        {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'ente', 'rappresentante_legale', this.props.accreditamento.tipo_accreditamento) &&
          <Documenti
            canDelete={!isRealReadOnly}
            canAddNote={false}
            unique={true}
            required_dates={true}
            media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
            medias={this.props.accreditamento.ente.media}
            current_domain="ente"
            current_subdomain="rappresentante_legale"
            required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'ente', 'rappresentante_legale')}
            ref_id={this.props.accreditamento.ente.id}
            readOnly={isRealReadOnly}
            accreditation_id={this.props.accreditamento.id}
          />}
      </div>
    );
  }
}

export default FileRappresentanteLegale;