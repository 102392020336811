import React, { Component } from 'react';

import { Button, Icon, notification } from 'antd';

import ComuniService from '@common/services/locations/Comuni';
import ModelServices from '../services/Anagrafica';
import checkPermission from '@common/utils/check/permissions';

import CommonForm from '@common/components/form';
import format from '@common/utils/formatters/_';

const searchModel = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Codice fiscale',
              type_string: 'text', // field type
              name: 'cf',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'button',
              name: 'btn_search',
              text: 'Cerca',
              onClick: 'submitted'
            }
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
}

const createModel = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'nome',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'cognome',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'cf',
                props: {
                  size: 'large',
                  disabled: true,
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di nascita',
                name: 'nato_il',
                props: {
                  size: 'large',
                  disabled: true,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Sesso',
                name: 'genere',
                props: {
                  size: 'large',
                  disabled: true,
                },
                options: [
                  { key: 'M', value: 'M', label: 'M' },
                  { key: 'F', value: 'F', label: 'F' }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Matricola',
                input_type: 'text',
                name: 'matricola',
                props: {
                  size: 'large'
                },
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'needMatricola'
                  }
                ]
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Valido dal',
                name: 'valido_dal',
                props: {
                  size: 'large'
                },
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'needMatricola'
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Stato di nascita',
                name: 'stato_nascita',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStato',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Provincia di nascita',
                name: 'provincia_nascita',
                props: {
                  size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedNascitaProvincia',
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'select',
                label: 'Comune di nascita',
                name: 'comune_nascita',
                props: {
                  size: 'large',
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'provincia_nascita',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'field_validation',
                    field: 'stato_nascita',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni'
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'button',
                name: 'btn_create',
                text: 'Inserisci',
                onClick: 'submitted'
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submitted',
  }
}

class CreateModel extends Component {

  state = {
    loading: false,
    searched: false,
    hasToBuild: false,
    comuni: [],
    model: {}
  }

  search(dataInput) {
    let data = this.formRef.props.form.getFieldsValue();
    if (true) {
      this.setState({ loading: true });
      ModelServices.search(data.cf, res => {
        if (res.data.data.id) {
          if (this.props.UserStore && checkPermission.hasPermission(this.props.UserStore.user, ['AddUser', 'adminPermissions', 'enteAddUser', 'UpdateElenchiProfessionali']) ) {
            ModelServices.getUtentiByAnagrafica(
              res.data.data.id, utenti => {
                if (utenti.data.data.length > 0 && this.props.role === 'Valutatore') {

                  let candidatoValutatore = utenti.data.data.find(el => 
                    (el.role.find(r => (r.name === 'DipendenteRegionale') && !el.role.find(r => r.name === 'Valutatore')))
                  )
                  if(candidatoValutatore){
                    this.props.returnUtente({...candidatoValutatore, anagrafica : res.data.data});
                  } else {
                    notification.warning({
                      placement: 'topLeft',
                      duration: 5,
                      message: 'Attenzione',
                      description: "Questo codice fiscale è già associato ad un utente con ruolo Valutatore oppure ad un utente che non è un Dipendente regionale",
                    });
                    this.setState({ loading: false });
                  } 
                      
                }else if (utenti.data.data.length > 0 && this.props.role === 'IspettoreAccreditamento') {

                  let candidatoIspettore = utenti.data.data.find(el => (!el.role.find(r => r.name === 'IspettoreAccreditamento')))

                  if(candidatoIspettore){
                    this.props.returnUtente({...candidatoIspettore, anagrafica : res.data.data});
                  } else {
                    notification.warning({
                      placement: 'topLeft',
                      duration: 5,
                      message: 'Attenzione',
                      description: "Questo codice fiscale è già associato ad un utente con ruolo Ispettore",
                    });
                    this.setState({ loading: false });
                  }
                } else if (utenti.data.data.length > 0 && this.props.UserStore.user.role[0].name != "Ente") {
                  notification.warning({
                    placement: 'topLeft',
                    duration: 5,
                    message: 'Attenzione',
                    description: "Questo codice fiscale è già associato ad un utente",
                  });
                  this.setState({ loading: false });
                } else {
                  this.setState({ loading: false, searched: true, hasToBuild: false, model: res.data.data });
                }
              }
            )
          } else {
            this.setState({ loading: false, searched: true, hasToBuild: false, model: res.data.data });
          }
        } else {
          ComuniService.all(
            { id_provincia: res.data.data.provincia_nascita },
            res2 => {
              this.setState({
                comuni: res2.data.data || []
              });
            }
          );
          this.setState({ loading: false, searched: true, hasToBuild: true, model: res.data.data });
        }
      }, () => {
        this.setState({ loading: false })
      })
    }
  }

  create(dataInput) {
    let data = this.formRefCreate.props.form.getFieldsValue();
    if (!this.state.loading) {
      this.setState({ loading: true });

      ModelServices.create(format.replaceFormDatesOut(['nato_il'], data, 'YYYY-MM-DD'), res => {
        this.setState({ loading: false }, () => {
          if (res.data.data.id) this.props.returnAnagrafica(res.data.data)
        });
      }, () => {
        this.setState({ loading: false })
      })
    }
  }

  refuseToUseExisting() {
    this.setState({ searched: false, hasToBuild: false, model: {} });
  }

  acceptToUseExisting() {
    this.props.returnAnagrafica(this.state.model)
  }

  render() {
    return !this.state.searched
      ?
      <CommonForm

        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.state.loading || false}
        form_model={searchModel}
        values={{

        }}
        submitted={(data) => this.search(data)}
      />
      : ((this.state.hasToBuild)
        ? <CommonForm
          wrappedComponentRef={inst => (this.formRefCreate = inst)}
          loading={this.state.loading || false}
          form_model={createModel(this)}
          nazioni={this.props.GlobalStore.stubs.nazione}
          values={{
            ...this.state.model
          }}
          needMatricola={(value, form, field_name) => {
            return this.props.withMatricola
          }}

          selectedStato={data => {
            //console.log (this.formRef.props.form);
            this.formRefCreate.props.form.setFieldsValue({ comune_nascita: null, provincia_nascita: null });
            this.setState({
              comuni: [],
            });
          }}

          province={this.props.GlobalStore.stubs.provincia}

          selectedNascitaProvincia={data => {
            //console.log (this.formRef.props.form);
            this.formRefCreate.props.form.setFieldsValue({ comune_nascita: null });
            this.setState({
              selected_nascita_provincia: data,
              comuni: []
            }, () => {
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni: res.data.data || []
                  });
                },
                err => null
              );
            });

          }}

          comuni={this.state.comuni.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}


          submitted={(data) => this.create(data)}
        />
        : <div style={{ textAlign: 'center' }}>
          <p>&Egrave; stata trovata una anagrafica con questo codice fiscale, vuoi utilizzarla?</p>
          <h3>{this.state.model.nome + " " + this.state.model.cognome}</h3>
          <p>{format.formatDate(this.state.model.nato_il, 'DD/MM/YYYY') + " " + this.state.model.nato_a}</p>
          <Button type="primary" size="large" style={{ marginRight: 12 }} onClick={() => this.acceptToUseExisting()}>Si</Button>
          <Button type="primary" ghost size="large" style={{ marginRight: 12 }} onClick={() => this.refuseToUseExisting()}>No</Button>
        </div>

      )
  }
}
export default CreateModel;