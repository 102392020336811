import BaseRequest from '@common/services/_baseRequest';

export default {

  inviaPagamento: (idProgetto, rispostaPagamentoUrl) => {
    const req = new BaseRequest();
    return req.makeRequest(
      {},
      {
        progettoId: idProgetto,
        rispostaPagamentoUrl: rispostaPagamentoUrl
      },
      null,
      'post',
      `pagamento/invia-pagamento`,
    );
  },

  pagamentiProgetto: idProgetto => {
    const req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      `pagamento/pagamenti-progetto/${idProgetto}`,
    );
  },

  isProgettoPagato: pagamenti => {
    if(!Array.isArray(pagamenti)){
      return false;
    }
    return pagamenti.filter( pagamento => pagamento.stato == 1 ).length > 0;
  },
  
  existsPagamentoInStatus: (pagamenti, status = []) => {
    if(!Array.isArray(pagamenti)){
      return false;
    }
    const pagamentiFiltered = pagamenti.filter( pagamento => status.includes(pagamento.stato) );
    return pagamentiFiltered.length > 0;
  }

}
