import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, message, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Accreditamento';

import CommonForm from '@common/components/form';

import checkPermission from '@common/utils/check/permissions';

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Scheda valida?',
              name: 'valid',
              option_type: 'button', // normal|button
              options: [
                {
                  value: true,
                  label: "Si"
                },
                {
                  value: false,
                  label: "No"
                }
              ],
              props: {

              },
            }
          ],
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },

      ],
    }
  ],
  onSubmit: 'submitted',
};




class ValidationScheda extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    audit: {},
    open_modal_validate: false,
    loading: false
  }


  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) &&
      this.props.accreditamento.istruttore?.some(i => i.id === this.props.UserStore.user.id);
  }

  canGiveFeedback() {
    return this.isIstruttore() && [6, 12].includes(this.props.accreditamento.stato_flusso) &&
      this.props.progress.showed_sections[this.props.section];
  }

  render() {
    return <div>{
    this.canGiveFeedback() ?
        <React.Fragment>
          <Button
            loading={this.state.loading}
            //disabled={this.props.accreditamento.istruttore_sections_feedback && this.props.accreditamento.istruttore_sections_feedback[this.props.section]}
            className="m5h" type="primary" icon="edit" onClick={()=>this.setState({open_modal_validate: true})}
            style={{marginRight: 8}}>
              { (this.props.accreditamento.istruttore_sections_feedback && this.props.accreditamento.istruttore_sections_feedback[this.props.section]) ? "Modifica stato scheda" : "Conferma scheda" }
          </Button>
          <Modal
            title="Inserisci note"
            confirmLoading={this.state.loading}
            visible={this.state.open_modal_validate}
            onOk={async () => {

                if(!this.formNoteRef.props.form.getFieldsValue()['note'] || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
                  message.error('Inserisci le note');
                  return;
                }

                if(!this.state.loading) {
                  this.setState({
                    loading: true,
                  }, () => {

                  ModelServices.feedbackSection(
                    this.props.accreditamento.id,
                    {
                      section: this.props.section,
                      note: this.formNoteRef.props.form.getFieldsValue()['note'],
                      valid: this.formNoteRef.props.form.getFieldsValue()['valid']
                    },
                    (res) => {
                      this.props.refreshInline(res.data.data);
                      this.setState({open_modal_validate: false, loading: false});
                    },
                    (err) => this.setState({loading: false})
                  );
                });
              }
            }}
            onCancel={() => this.setState({ open_modal_validate: false })}
          >
            <CommonForm
              wrappedComponentRef={inst => (this.formNoteRef = inst)}
              loading={this.state.loading || false}
              form_model={formNoteLayout}
              values={
                this.props.accreditamento && this.props.accreditamento.istruttore_sections_feedback ?
                this.props.accreditamento.istruttore_sections_feedback[this.props.section] || {valid: true, note: ''} :
                {valid: true, note: ''}
              }
            />
          </Modal>
        </React.Fragment>
          : null
        }
        {
          this.props.accreditamento.istruttore_sections_feedback && this.props.accreditamento.istruttore_sections_feedback[this.props.section]
          ?
          <div>
            <p>{this.props.accreditamento.istruttore_sections_feedback[this.props.section].note}<br />
            <strong>Scheda {!this.props.accreditamento.istruttore_sections_feedback[this.props.section].valid ? 'non ' : ''}valida</strong></p>
          </div>
          : null
        }
      </div>
  }
}

const AuditExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ValidationScheda);

export default withRouter(AuditExport);
