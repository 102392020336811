import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, Card, Drawer, Popconfirm } from 'antd';

import ModelServices from '@accreditamento/services/Assegnazione';

import moment from 'moment';

import accreditamentoStatic from '@common/utils/static/accreditamento';
import auditStatic from '@common/utils/static/audit';
import IstruttoriList from '@modules/utente/components/ListIstruttori';

import checkPermission from '@common/utils/check/permissions';

const istruttori_table = (component_list, component_assegnazione) => {

  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: 'Nome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'nome',
        render: element => <div>{element.anagrafica ? element.anagrafica.nome : ''}</div>,
      },
      {
        title: 'Cognome',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'cognome',
        render: element => <div>{element.anagrafica ? element.anagrafica.cognome : ''}</div>,
      },
      {
        title: 'Email',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: 'email',
        render: element => <div>{element.email}</div>,
      },
      {
        title: 'Pratiche assegnate',
        dataIndex: '',// dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: 'pratiche',
        //  JayBee_BugFix_#4809
        render: element => <div>{element.count_dossier || 0}</div>,
      },
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'created_at',
        render: element => <div style={{ width: 120 }}>
          <Popconfirm placement="top" title="Confermi di voler assegnare la pratica a questo istruttore?"
            onConfirm={() => component_assegnazione.assign(element.id)}
            okText="Si" cancelText="No">
            <Button
              disabled={component_assegnazione.state.assigning}
              className="m5h" type="primary" icon="save" size="small">Assegna la pratica</Button>
          </Popconfirm>
          {/* <Button style={{marginRight: 12}} type="primary" size="small" ghost
          onClick={()=>component_list.props.history.push(component_list.getUserUrl(element))}>Scheda</Button> */}
        </div>,
      }
    ]
  };
};



class AssegnazioneIstruttore extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    assigning: false,
    open_modal_istruttori: false
  }

  componentDidMount() {
    if (this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, loaded: true });
  }

  assign(istruttore) {
    if (!this.state.assigning) {
      this.setState({
        assigning: true
      }, () => {
        ModelServices.assign(this.state.accreditamento.id, { id_user: istruttore }, (res) => {
          this.props.refreshInline(res.data.data);
          this.setState({ open_modal_istruttori: false, assigning: false });
        }, () => {
          this.setState({
            assigning: true
          });
        });
      }
      );
    }
  }

  getUserUrl(item) {
    return "/istruttore/" + item.id;
  }

  canView() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'istruttorePermissions', 'adminPermissions']) ? true : false;
  }

  canViewAssegnazione() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) &&
      this.state.accreditamento.istruttore && this.state.accreditamento.istruttore.length > 0
      ? true : false;
  }

  canSearch() {
    return this.state.accreditamento.stato_interno === accreditamentoStatic.stato_interno.da_assegnare ? true : false;
  }

  canChange() {
    return this.state.accreditamento.istruttore.length > 0 &&
      this.props.accreditamento.stato_flusso !== 14 &&
      this.props.accreditamento.stato_flusso !== 15 ? true : false;
  }

  canDoSomething() {
    return this.canView() && (this.canViewAssegnazione()  || this.canSearch() || this.canChange() || (this.needShowTimer() && this.hasTimer()));
  }

  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) ? true : false;
  }

  needShowTimer() {
    return this.isIstruttore();
  }

  hasTimer() {
    return this.state.accreditamento.data_timer_audit && !this.state.accreditamento.data_termine_audit ? true : false;
  }

  render() {
    return (this.state.loaded && this.canDoSomething()) ?
      <div>
        <Card
          bordered={false}
        >
          {!this.isIstruttore() ? <h4>Istruttore</h4> : null}
          {
            this.needShowTimer()
              ?
              <div>
                {
                  this.hasTimer()
                    ?
                    <React.Fragment>
                      <h4>Timer</h4>
                      {this.state.accreditamento.blocked_timer === 1 ? <span style={{ display: 'block', fontWeight: 'bold' }}>Timer bloccato</span> : <div>
                        <span>Giorni entro cui terminare l&apos;audit documentale</span>
                        <span
                          style={{ display: 'block', fontSize: 50, fontWeight: 'bold' }}
                        >{30 + this.state.accreditamento.integration_added_days - moment().diff(moment(this.state.accreditamento.data_timer_audit, 'YYYY-MM-DD'), 'days')}</span></div>}
                    </React.Fragment>
                    : null
                }

              </div>
              : null
          }
          {this.canSearch() ?
            <div>
              <p style={{ marginBottom: 0 }}>Devi assegnare la pratica a un istruttore</p>
              {this.canSearch() ? <Button
                className="m5h"
                style={{ marginRight: 8 }}
                type="primary"
                icon="user-add"
                onClick={() => this.setState({ open_modal_istruttori: true })}>Cerca un istruttore</Button> : null}
            </div> : null}
          {this.canViewAssegnazione() ?
            <div>
              <p>Pratica assegnata all&apos;istruttore: {this.state.accreditamento.istruttore.map(i => <strong key={i.id}>{`${i.anagrafica.cognome} ${i.anagrafica.nome} ${i.username ? '(' + i.username + ')': ''}`}</strong>)}</p>
              <Button style={{ marginRight: 12 }} type="primary" size="small" ghost
                onClick={() => this.props.history.push(this.getUserUrl(this.state.accreditamento.istruttore[0]))}>Profilo</Button>
              {
                this.canChange() ?
                  (
                    this.state.accreditamento.audit.filter(a => auditStatic.stato_end_audit.indexOf(a.stato) !== -1)
                      ?
                      <Popconfirm placement="top" title="Cambiando istruttore in questa fase, con una istruttoria in corso, questa verrà sospesa e il nuovo istruttore dovrà iniziarne una nuova, confermi la scelta?"
                        onConfirm={() => this.setState({ open_modal_istruttori: true })}
                        okText="Si" cancelText="No">
                           {this.state.accreditamento.stato!==accreditamentoStatic.stato.accolta_e_confermata  && this.state.accreditamento.stato!==accreditamentoStatic.stato.accolta &&
                        <Button className="m5h" type="primary" icon="sync" size="small">Cambia istruttore</Button>}
                      </Popconfirm>
                      :
                      <Button className="m5h" type="primary" icon="sync" size="small" onClick={() => this.setState({ open_modal_istruttori: true })}>Cambia istruttore</Button>
                  )
                  : null}
            </div> : null}
          {
            this.canSearch() || this.canChange()
              ?
              <Drawer
                title="Cerca un istruttore"
                placement="right"
                closable={true}
                onClose={() => {
                  this.props.history.replace('/accreditamento/scheda/' + this.state.accreditamento.id);
                  this.setState({ open_modal_istruttori: false });
                }}
                visible={this.state.open_modal_istruttori}
                width={1024}

              >
                <IstruttoriList {...this.props}
                  base_url={'/accreditamento/scheda/' + this.state.accreditamento.id}
                  table_model={(component) => istruttori_table(component, this)}
                />
              </Drawer>
              : null
          }
        </Card>
      </div>
      : null;
  }
}

const AssegnazioneExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(AssegnazioneIstruttore);

export default withRouter(AssegnazioneExport);
