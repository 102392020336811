import BaseRequest from "./_baseRequest";


export default {
	
	profile: (cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get",
			'user/profile',
			cb,
			ecb || null,
			null
		);
	},

	changePassword: (data, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{
				'UserChangePassword':{
					old_password: data.old_password,
					new_password: data.new_password,
					repeat_password: data.repeat_password
				}
			},
			null,
			"post",
			'user/change-password',
			cb,
			ecb || null,
			null
		);
	},

	entiList: (userId, params, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			{},
			null,
			'get',
			`user/${userId}/enti-list`,
			cb,
			ecb || null,
			null
		);
	},

	entiSwap: (userId, enteId, params, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			params,
			null,
			'put',
			`user/${userId}/change-ente/${enteId}`,
			cb,
			ecb || null,
			null
		);
	},

}
