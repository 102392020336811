import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import RegistroElettronico from '@affidamento/components/registro/RegistroElettronico';

import ModelServices from '@affidamento/services/Corsi';

import SpinnerLoading from '@components/SpinnerLoading';

import eventBroker from '@common/utils/services/EventBroker';

@Wrapper({ route: 'RegistroElettronico', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProgetto', 'adminPermissions'])
class RegistroElettronicoRoute extends Component {

  state = {
    loading: false,
    data: null,
  }

  constructor(props) {
    super(props);
    eventBroker.on('reloadCorso', data => {
      this.setState(
        {
          data: data,
        }
      );
    });
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true });
    Promise.all([
      ModelServices.detail({expand: 'countIscritti,countDocenti,countLezioni'}, this.props.match.params.id_corso || this.props.match.params.id),
      ModelServices.myiscrizione(this.props.match.params.id_corso || this.props.match.params.id, {expand: 'giustificativo'})
    ]).then(
      res => {
        let [r1, r2] = res;
        this.setState(
          {
            data: r1.data.data,
            studente: r2?.data?.data,
            loading: false
          }
        );
      },
    ).catch(() => {
      this.setState({ loading: false });
    });
  }

  renderTitle() {
    return this.state.data?.titolo_progetto || '#Titolo non valido#';
  }

  render() {

    return (

      <div className="scheda">
        {this.state.loading ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
              <p>Registro elettronico</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/affidamento/registro">Registro Elettronico</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <RegistroElettronico {...this.props} corso={this.state.data} studente={this.state.studente} />
          </>
        }
      </div>
    );
  }
}

export default RegistroElettronicoRoute;
