import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';
import EnteService from '@accreditamento/services/Ente';

import formatter from '@common/utils/formatters/_';
import moment from 'moment';
import QualificaProfessionaliService from '../../../../repertorio/services/QualificaProfessionale'

import { Spin } from 'antd';

import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';
import Ateco2007Services from '@modules/tabelle/services/Ateco2007';
import IstatProfessioniServices from '@modules/tabelle/services/IstatProfessioni';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'dati_generali_progetto_padre',
                type: 'title',
                element: 'h4',
                text: 'Dati generali progetto',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                }
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Tipologia del progetto',
                name: 'progetto_padre.scheda_progetto_json.generalData.AfcProjectInfoFormSection.courseType',
                option_type: 'button',
                props: {
                  disabled: true,
                },
                options: [
                  {
                    value: 1, //TODO controllare i valori dello stub
                    label: "Finanziato"
                  },
                  {
                    value: 2, //TODO controllare i valori dello stub
                    label: "Autofinanziato"
                  }
                ]
              }
            ],
          },
          {
            span: 16,
            items: [
              {
                type: 'input',
                label: 'Denominazione del progetto',
                type_string: 'text', // field type
                name: 'progetto_padre.scheda_progetto_json.generalData.AfcProjectInfoFormSection.courseTitle',
                props: {
                 disabled: true
                }
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Macrotipologia del progetto',
                name: 'progetto_padre.scheda_progetto_json.generalData.AfcMacroTypologySection.macroTypology',
                props: {
                  disabled: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_macrotipologie'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di avvio del progetto',
                format: 'DD/MM/YYYY',
                name: 'progetto_padre.scheda_progetto_json.generalData.AfcCourseTimeSection.startDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                  disabled: true
                }
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di conclusione del progetto',
                format: 'DD/MM/YYYY',
                name: 'progetto_padre.scheda_progetto_json.generalData.AfcCourseTimeSection.endDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                  disabled: true
                }
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'modalita_formativa',
                type: 'title',
                element: 'h4',
                text: 'Modalità formativa',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalModalitySection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },



      {
        cols: [
          {
            items: [
              {
                name: 'dati_generali_progetto',
                type: 'title',
                element: 'h4',
                text: 'Dati generali',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 16,
            items: [
              {
                type: 'input',
                label: 'Denominazione dell\'edizione',
                type_string: 'text', // field type
                name: 'AfcProjectInfoFormSection.courseTitle',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Numero dell\'edizione',
                type_string: 'text', // field type
                name: 'AfcProjectInfoFormSection.courseNumber',
                props: {
                  min: 1,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ],
      },     
      {
        cols: [
          {
            items: [
              {
                name: 'periodo_svolgimento_corso',
                type: 'title',
                element: 'h4',
                text: 'Periodo svolgimento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di avvio',
                format: 'DD/MM/YYYY',
                name: 'AfcCourseTimeSection.startDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_date'
                  }
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di conclusione',
                format: 'DD/MM/YYYY',
                name: 'AfcCourseTimeSection.endDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'max_date'
                  }
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit_dati_generali',
                text: 'Salva i dati',
                //onClick: 'showDrawer',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submit',
  }
};

export default class DatiGeneraliForm extends Component {

  constructor(props) {
    super(props);
    this.getBackGeneralData = this.getBackGeneralData.bind(this);
    eventBroker.on('getBackGeneralData', this.getBackGeneralData);
  }

  static propTypes = {
    domanda_json: PropTypes.object,
    generalData: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    loaded: false,
    loading: false,
    generalData: {},
    settori: [],
    qualifiche: [],
    ateco2007: [],
    istatCp2011:[],
    _aree_tematiche_corso_altro_selected: false,
    _settori_riferimento_formulario_selected: false,
    qualifiche_repertorio_nazionale: this.props.GlobalStore.stubs.qualifiche_repertorio_nazionale || [],
    profili_gestiti_da_normative_regionali_nazionali: this.props.GlobalStore.stubs.profili_gestiti_da_normative_regionali_nazionali || [],
    _settori_riferimento_formulario_selected: false
  };

  componentDidMount() {
    this.load(this.props);

    Ateco2007Services.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'ateco2007_' + el.id,
            label: el.codice + ' - ' + el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ ateco2007: rows })
    });

    IstatProfessioniServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'istatCp2011_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ istatCp2011: rows })
    })
  }

  load(props) {

    this.setState({ loading: true })
    let req = [];
    if (props.generalData.AfcMacroTypologySection?.macroTypology) {
      req.push(EnteService.settoriAccreditatiByMacrotipologia(props.generalData.AfcMacroTypologySection.macroTypology, this.props.id_ente))
    } else {
      req.push(Promise.resolve(null));
    }

    if (props.generalData.AfcSectorSection?.sectorType) {
      req.push(QualificaProfessionaliService.getBySettore(props.generalData.AfcSectorSection.sectorType))
    } else {
      req.push(Promise.resolve(null));
    }

    Promise.all(req).then(res => {

      let [res1, res2] = res;

      let settori = res1 && res1.data && res1.data.data || [];
      if (props.generalData.AfcSectorSection?.sectorType && !settori.find(el => el.value === props.generalData.AfcSectorSection?.sectorType)) {
        settori.push( this.props.GlobalStore.stubs.settori.find(el => el.value === props.generalData.AfcSectorSection?.sectorType))
      }

      this.setState(
        {
          settori: settori || [],
          qualifiche: res2.data.data || [],
          loading: false,
        }
      );
    },
    ).catch(() => {
      this.setState({ loading: false });
    });

    this.setState({
      loaded: true,
      loaded_source: true,
      generalData: props.generalData,
      teachingProgramming : props.teachingProgramming,
    });
  }

  getBackGeneralData() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  validationQualificaProfessionale() {
    let cert_type = this.formSelRef?.props.form?.getFieldValue('AfcExamSection.certificateType');
    return cert_type !== 1 && cert_type !== 2 && cert_type !== 3 && cert_type !== 14;

  }

  validationSectionRepertorio(){
    const title = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
    const nationalRepertoireQualification = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.nationalRepertoireQualification');
    const profilesManagedByRegulations = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.profilesManagedByRegulations');
    
    //almeno uno dei tre deve essere valorizzato
    return title===null && nationalRepertoireQualification===null && profilesManagedByRegulations===null ? true : false;
  }

  validationSectionRepertorioAteco(){
    const title = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
    const ateco2007 = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.ateco2007');
    
    //Se Qualifica professionale è selezioanto -> anche ateco deve esserlo
    return title!==null && ateco2007===null ? true : false;
  }

  validationSectionRepertorioIstatProfessioni(){
    const title = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
    const istatCp2011 = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.istatCp2011');
    
    //Se Qualifica professionale è selezioanto -> anche istat professioni deve esserlo
    return title!==null && istatCp2011===null ? true : false;
  }

  validationFiguraProfessionale() {
    return this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  getValues() {
    let generalData = this.state.generalData;
    if (generalData.AfcCourseTimeSection) {
      generalData.AfcCourseTimeSection = formatter.replaceFormDatesIn(['startDate', 'endDate'], generalData.AfcCourseTimeSection)
    }
    if(this.props.progetto_padre){
      generalData.progetto_padre = this.props.progetto_padre
      if (generalData.progetto_padre.scheda_progetto_json.generalData.AfcCourseTimeSection) {
        generalData.progetto_padre.scheda_progetto_json.generalData.AfcCourseTimeSection = formatter.replaceFormDatesIn(['startDate', 'endDate'], generalData.progetto_padre.scheda_progetto_json.generalData.AfcCourseTimeSection)
      }
    }
    return generalData
  }

  prepareData(data) {
    if (this.props.generalData.AfcCourseTimeSection) {
      let AfcCourseTimeSection = formatter.replaceFormDatesOut(['startDate', 'endDate'], data.AfcCourseTimeSection, 'YYYY-MM-DD');
      data.AfcCourseTimeSection = AfcCourseTimeSection;
    }
    if(this.props.progetto_padre){
      data.progetto_padre = this.props.progetto_padre
    }
    return data;
  }

  totalOneriImpostiAgliAllievi() {
    let data = this.formSelRef.props.form.getFieldsValue()['AfcLearnerPaymentsSection']
    data['total'] = data['entryFee'] + data['courseCost'] + data['monthlyFee'] + data['otherPayment'];
    this.formSelRef.props.form.setFieldsValue({ AfcLearnerPaymentsSection: data });
  }

  submit(data) {
    let domanda = this.props.domanda_json;
    domanda.generalData = this.prepareData(data);
    if (this.state.decreto) {
      if (domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection?.entityPersonalData) {
        domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.entityPersonalData.decreto = this.state.decreto;
      }
      if (domanda.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList) {
        domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList = [];
        domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative = null;
        domanda.relatedSubjects.AfcProjectStaffSection.numberOfTeachers = 0;
      }

    }

    domanda.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList = this.props.methodList;
    /*this.setState({
   loading: true,
   loaded: false,
 });*/

    this.props.saveJson(domanda);

    /*ModelServices.save_json(domanda, this.props.match.params.id,
      res => {
        if (this.props.updateProgress) {
          this.props.updateProgress(res.data.data.progress);
          this.props.refresh(false);
          message.success('Dati salvati con successo');
        }
        this.setState({
          loading: false,
          loaded: true,
          generalData: res.data.data.domanda_json.generalData
        });
      },
      () => {
        this.setState({
          loading: false,
          loaded: true
        });
      }
    );*/
  }

  getSettoreByMacrotipologia(data) {
    let sectorSection = this.formSelRef.props.form.getFieldsValue()['AfcSectorSection'];
    if (sectorSection) {
      sectorSection.sectorType = null;
      this.formSelRef.props.form.setFieldsValue({ AfcSectorSection: sectorSection });
      this.setState({ 
        settori: this.props.GlobalStore.stubs.settori,
        loading: false
      });
      /*EnteService.settoriAccreditatiByMacrotipologia(data, this.props.id_ente, res => {
        this.setState({
          settori: res.data.data.length !== 0 ? res.data.data : this.props.GlobalStore.stubs.settori,
          loading: false,
        });
      }, () => this.setState({ loading: false }));*/
    }
  }

  /*selectedSettore(data) {
    this.formSelRef.props.form.setFieldsValue({
      AfcIndexSection: {
        title: null
      }
    });
    this.setState({ loading: true });

    let req = [
      AccreditamentoService.getBySettore(data),
      QualificaProfessionaliService.getBySettore(data)
    ];

    Promise.all(req).then(res => {
      let [res1, res2] = res;
      const decreto = res1.data.data;

      if(decreto !== -1){
        let req1 = [
          EnteService.professionalitaCoinvolte({}, this.props.id_ente, res1.data.data),
          EnteService.attrezzatureAccreditate({}, this.props.id_ente, res1.data.data),
          EnteService.audiovisiviAccreditati({}, this.props.id_ente, res1.data.data),
        ]
  
        Promise.all(req1).then(res2 => {
          let [res3, res4, res5] = res2
          this.props.resetRisorseUmane(res3.data.data, res4.data.data, res5.data.data)
          this.setState({ loading: false,});
        })
      }
      
      this.setState(
        {
          decreto: decreto,
          qualifiche: res2.data.data,
          loading: false
        },
        ()=> {this.props.resetPersonaleEnti(decreto);}
      );

    },
    ).catch(() => {
      this.setState({ loading: false });
    });

  }*/

  selectedSettoreRiferimentoFormulario(data) {
    const settori_riferimento_formulario = this.props.GlobalStore.stubs.settori_riferimento_formulario;
    const settori_riferimento_formulario_selected = settori_riferimento_formulario.find(el => el.value == data && el.key == 'settori_riferimento_formulario_00' );
    if(typeof settori_riferimento_formulario_selected !== 'undefined' ){
      this.setState({_settori_riferimento_formulario_selected: true});
    }else{
      this.setState({_settori_riferimento_formulario_selected: false});
    }

  }

  selectedAreaTematicaCorso(data) {
    const aree_tematiche_corso = this.props.GlobalStore.stubs.aree_tematiche_corso
    const areaTEmaticaselected = aree_tematiche_corso.find(el => el.value == data && el.key == 'aree_tematiche_corso_00' );
    if(typeof areaTEmaticaselected !== 'undefined' ){
      this.setState({_aree_tematiche_corso_altro_selected: true});
    } else {
      this.setState({_aree_tematiche_corso_altro_selected: false});
    }
  }

  selectedAreaTematicaCorso(data) {
    const aree_tematiche_corso = this.props.GlobalStore.stubs.aree_tematiche_corso
    const areaTEmaticaselected = aree_tematiche_corso.find(el => el.value == data && el.key == 'aree_tematiche_corso_00' );
    if(typeof areaTEmaticaselected !== 'undefined' ){
      this.setState({_aree_tematiche_corso_altro_selected: true});
    }
  }

  render() {
    return <div style={{ opacity: !this.props.saved || this.state.loading ? 0.5 : 1 }}>
      {
        !this.props.saved ? <Spin /> : null
      }
      { checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'generalData')}
          feedback={this.props.section_feedback ? this.props.section_feedback['generalData'] : null}
        />
      }
      <CommonForm
        loading={this.props.saving || this.state.loading || false}
        wrappedComponentRef={inst => (this.formSelRef = inst)}
        form_model={formLayout(this)}
        values={this.getValues()}
        readOnly={this.props.readOnly}
        selectedMacrotipologia={data => {
          this.getSettoreByMacrotipologia(data);
          this.props.loadProvinceSediAccreditate(data)
        }}
        //selectedSettore={data => this.selectedSettore(data)}
        selectedAreaTematicaCorso={data => this.selectedAreaTematicaCorso(data)}
        selectedSettoreRiferimentoFormulario={data => this.selectedSettoreRiferimentoFormulario(data)}
        selectedSettore={data => this.selectedSettore(data)}
        _macrotipologie={this.props.macrotipologie}
        _modalita_formativa={this.props.GlobalStore.stubs.modalita_formativa}
        _contenuto_formativo={this.props.GlobalStore.stubs.contenuto_formativo}
        _modalita_svolgimento={this.props.GlobalStore.stubs.modalita_svolgimento}
        _livello_offerta_formativa={this.props.GlobalStore.stubs.livello_offerta_formativa}
        _tipologia_attivita={this.props.GlobalStore.stubs.tipologia_attivita}
        _attestazione_finale={this.props.GlobalStore.stubs.attestazione_finale}
        _tipo_funzione={this.props.GlobalStore.stubs.tipo_funzioni}
        _figura_professionale={this.props.GlobalStore.stubs.tipo_funzioni}  //TODO
        _settori={this.state.settori}
        _settori_riferimento_formulario={this.props.GlobalStore.stubs.settori_riferimento_formulario}
        _settori_riferimento_formulario_selected={this.state._settori_riferimento_formulario_selected}
        //_qualifiche={this.state.qualifiche}
        _figure_professionali={this.props.GlobalStore.stubs.figure_professionali}
        _qualifiche={this.state.qualifiche}
        _qualifiche_repertorio_nazionale={this.state.qualifiche_repertorio_nazionale}
        _ateco2007={this.state.ateco2007}
        _istatCp2011={this.state.istatCp2011}
        _aree_tematiche_corso={this.props.GlobalStore.stubs.aree_tematiche_corso}
        _aree_tematiche_corso_altro_selected={this.state._aree_tematiche_corso_altro_selected}
        _profili_gestiti_da_normative_regionali_nazionali={this.state.profili_gestiti_da_normative_regionali_nazionali}
        submit={(data) => this.submit(data)}

        min_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let min = value;

          if (min < moment()) cb("La data di avvio deve essere maggiore della data odierna");
          let max = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['endDate'];

          if (max && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
          else cb()
        }}
        max_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let max = value;

          let min = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['startDate'];

          if (min && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
          else cb()
        }}
      />

    </div>
  }
}