import React, { Component } from "react";

import { Icon, AutoComplete, message } from "antd";
import ComuniService from "@common/services/locations/Comuni";
import CommonForm from "@common/components/form";
import allegatiStatic from "@common/utils/static/allegati";
import formatter from "@common/utils/formatters/_";
import attivita_formativa from "@common/utils/formatters/mappatura_attivita_formativa";
import DocSpan from "@common/components/DocSpan";

const Option = AutoComplete.Option;

const getTipoAula = (component, record) => {
  try {
    let type = formatter.replaceStub(
      record.id_tab_tipo_aula,
      "tipo_aula",
      component.props.GlobalStore
    );

    return type;
  } catch (e) {
    return "errore";
  }
};

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Aula",
                name: "id_aula",
                onChange: "selectedAula",
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "aule",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Tipologia",
                name: "tipologia",
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: component.state.tipologia_required
                  ? [
                      {
                        type: "required",
                        error_message: "Campo obbligatorio",
                      },
                    ]
                  : [],
                options_props: "tipologia_attrezzature",
                onChange: "setTipologia",
              },
            ],
          },
        ],
      },
      {
        cols: [
          component.state.selected_tipologia_attrezzatura ===
          component.state.altro_tipologia_attrezzatura
            ? {
                span: 24,
                items: [
                  {
                    type: "input",
                    label: "Specificare la tipologia",
                    name: "tipologia_altro",
                    validations: [
                      {
                        type: "required",
                        error_message: "Campo obbligatorio",
                      },
                    ],
                  },
                ],
              }
            : {},
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "integer",
                label: "Quantità",
                name: "quantita",
                props: {
                  min: 1,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "integerwithzero",
                label: "Mesi di disponibilità dalla data della domanda",
                name: "mesi_disponibilita",
                min: 1,
                validations: [{}],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                label: "Titolo di possesso",
                name: "id_tab_attrezzature_possesso",
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "titolo_possesso",
                onChange: "setTitoloPossesso",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "datepicker",
                label: "Data registrazione",
                name: "data_registrazione",
                props: {
                  //size: 'large',
                  style: { width: "100%" },
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "validateTitoloPossesso",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "datepicker",
                label: "Data in cui entra in vigore",
                name: "data_in_vigore",
                props: {
                  //size: 'large',
                  style: { width: "100%" },
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "validateTitoloPossesso",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "autocomplete",
                label: "Ufficio di registro presso cui è depositato",
                type_string: "text", // field type
                name: "ufficio_registro_depositato",
                props: {
                  disabled: component.props.readOnly,
                  // size: 'large',
                  prefix: (
                    <Icon type="home" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "validateTitoloPossesso",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                withIcon: true,
                dataFunc: "getComune",
                onSelectFunc: "setComune",
                onSearch: "searchingComune",
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "datepicker",
                label: "Data di scadenza",
                name: "data_scadenza",
                props: {
                  //size: 'large',
                  style: { width: "100%" },
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "validateTitoloPossesso",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label: "Sono previste clausole risolutorie",
                name: "clausole_risolutorie",
                option_type: "button",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "upload_clausole_risolutorie",
                type: "title",
                element: "h3",
                text: "Upload clausole risolutorie",
                props: {
                  style: { textAlign: "center" },
                },
                showIf: [
                  {
                    type: "field_validation",
                    field: "clausole_risolutorie",
                    operator: "==",
                    value: 1,
                  },
                  {
                    type: "custom_func",
                    func: () => {
                      return allegatiStatic.filteredAccreditationMedia(
                        component.props.GlobalStore.stubs.tipo_media.filter(
                          (el) =>
                            el.dominio === "ente_sede_attrezzatura" &&
                            el.sottodominio === "clausole_risolutorie"
                        ),
                        component.props.accreditamento.tipo_accreditamento
                      ).length > 0
                        ? true
                        : false;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "template_clausole_risolutorie",
                type: "child",
                child: (
                  <div style={{ marginBottom: 16 }}>
                    {component.facSimileClausoleRisolutorie() && (
                      <DocSpan
                        key={component.facSimileClausoleRisolutorie().id}
                        file={component.facSimileClausoleRisolutorie()}
                      ></DocSpan>
                    )}
                  </div>
                ),
                showIf: [
                  {
                    type: "field_validation",
                    field: "clausole_risolutorie",
                    operator: "==",
                    value: 1,
                  },
                  {
                    type: "custom_func",
                    func: () => {
                      return allegatiStatic.filteredAccreditationMedia(
                        component.props.GlobalStore.stubs.tipo_media.filter(
                          (el) =>
                            el.dominio === "ente_sede_attrezzatura" &&
                            el.sottodominio === "clausole_risolutorie"
                        ),
                        component.props.accreditamento.tipo_accreditamento
                      ).length > 0 &&
                        component.facSimileClausoleRisolutorie() !== null
                        ? true
                        : false;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "upload",
                name: "dragger",
                accept: ".pdf",
                action: "/", // url upload
                listType: "text", // text,picture,picture-card
                multiple: false,
                fileList: "files", // funzione che ritorna la lista dei file correntemente caricati
                customRequest: "customUploadRequest", // sovrascrive upload
                onRemove: "onRemove", // funzione richiamata alla cancellazione del file
                showIf: [
                  {
                    type: "field_validation",
                    field: "clausole_risolutorie",
                    operator: "==",
                    value: 1,
                  },
                  {
                    type: "custom_func",
                    func: () => {
                      return allegatiStatic.filteredAccreditationMedia(
                        component.props.GlobalStore.stubs.tipo_media.filter(
                          (el) =>
                            el.dominio === "ente_sede_attrezzatura" &&
                            el.sottodominio === "clausole_risolutorie"
                        ),
                        component.props.accreditamento.tipo_accreditamento
                      ).length > 0
                        ? true
                        : false;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Le attrezzature indicate sono tutte disponibili presso la sede indicata all’atto nella presente richiesta",
                name: "disponibili_sede",
                option_type: "button", // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Numero di matricola",
                name: "numero_matricola",
                min: 1,
                validations: [{}],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            props: {
              style: { float: "right" },
            },
            items: [
              {
                type: "submit",
                name: "btn_submit_attrezzatura",
                text: "Salva attrezzatura",
                //onClick: 'showDrawer',
                props: {
                  icon: "save",

                  className: "bg-green",
                },
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "onSubmit",
  };
};

class CreateAttrezzatura extends Component {
  state = {
    loading: false,
    loaded: false,
    fileList: [],
    comuni: [],
    selected_aula: null,
    altro_tipologia_attrezzatura: null,
    selected_tipologia_attrezzatura: -1,
    tipologia_required: false,
  };

  async componentDidMount() {}

  facSimileClausoleRisolutorie() {
    return this.props.GlobalStore.stubs.tipo_media.find(
      (el) =>
        el.dominio === "ente_sede_attrezzatura" &&
        el.sottodominio === "clausole_risolutorie"
    )?.facsimile;
  }

  submitFormData(data) {
    if (data.tipologia !== this.state.altro_tipologia_attrezzatura) {
      //Se la tipologia NON è Altro (id 39) allora tolgo la stringa
      data.tipologia_altro = null;
    }

    if (
      parseInt(data.clausole_risolutorie) === 1 &&
      this.state.fileList.length === 0 &&
      allegatiStatic
        .filteredAccreditationMedia(
          this.props.GlobalStore.stubs.tipo_media.filter(
            (el) =>
              el.dominio === "ente_sede_attrezzatura" &&
              el.sottodominio === "clausole_risolutorie"
          ),
          this.props.accreditamento.tipo_accreditamento
        )
        .find((el) => el.obbligatorieta)
    ) {
      message.error("Carica il documento con le clausole risolutorie");
      return;
    }

    this.props.onSubmit(
      formatter.replaceFormDatesOut(
        ["data_registrazione", "data_in_vigore", "data_scadenza"],
        data,
        "YYYY-MM-DD"
      ),
      parseInt(data.clausole_risolutorie) === 1 ? this.state.fileList : null
    );
  }

  getTipologie(id_aula = null) {
    let selected_aula = id_aula === null ? this.state.selected_aula : id_aula;
    if (!selected_aula) return [];
    let aula = this.props.sede.aule.find((a) => a.id === selected_aula);

    return attivita_formativa
      .getAttrezzatureByTipoAula(
        this.props.GlobalStore.stubs.mappatura_attivita_formativa,
        aula.id_tab_tipo_aula
      )
      .map((el) => {
        return {
          key: "tipo_attrezzatura_" + el.id,
          label: el.descrizione,
          value: el.id,
        };
      });
  }

  selectedTipologia(value) {
    this.setState({ selected_tipologia_attrezzatura: value });
  }

  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout(this)}
          values={{}}
          readOnly={this.props.readOnly}
          onSubmit={(data) => this.submitFormData(data)}
          validateTitoloPossesso={(value, form) => {
            return (
              form.getFieldValue("id_tab_attrezzature_possesso") === 2 ||
              form.getFieldValue("id_tab_attrezzature_possesso") === 3 ||
              form.getFieldValue("id_tab_attrezzature_possesso") === 4
            );
          }}
          titolo_possesso={this.props.GlobalStore.stubs.possesso}
          tipologia_attrezzature={this.getTipologie()}
          setTipologia={(value) => this.selectedTipologia(value)}
          selectedAula={(value) => {
            let tipologia_required = false;
            this.props.sede.aule.map((aula) => {
              if (this.props.values && this.props.values.id_aula) {
                if (value === this.props.values.id_aula) {
                  const tipo_aula = this.getTipoAula(this, aula);
                  if (
                    tipo_aula === "Aula Informatica" ||
                    tipo_aula === "Aula Corso"
                  ) {
                    tipologia_required = true;
                  }
                }
              }

              //console.warn("Aula", aula)
              //console.warn("Tipo Aula", getTipoAula(this, aula))
              return null;
            });

            const allTipologie = this.getTipologie(value);
            let altro_tipologia_attrezzatura = null;
            allTipologie.map((el) => {
              if (el.label.toLowerCase() === "altro") {
                altro_tipologia_attrezzatura = el.value;
              }
              return null;
            });

            this.setState({
              altro_tipologia_attrezzatura: altro_tipologia_attrezzatura,
              selected_aula: value,
              tipologia_required: tipologia_required,
            });
            this.formRef.props.form.setFields({
              tipologia: {
                value: null,
              },
            });
          }}
          setTitoloPossesso={() => {
            //console.log('selezionato possesso', value)
          }}
          aule={this.props.sede.aule.map((aula) => {
            return {
              value: aula.id,
              label: aula.nome_aula + " " + getTipoAula(this, aula),
              key: "aula_" + aula.id,
            };
          })}
          files={() => this.state.fileList}
          onRemove={() => {
            this.setState({
              fileList: [],
            });
          }}
          customUploadRequest={(obj) => {
            this.setState({
              fileList: [
                {
                  chiave: "file",
                  uid: obj.file.uid,
                  name: obj.file.name,
                  file: obj.file,
                  status: "done",
                },
              ],
            });
          }}
          getComune={() => {
            //console.log('GET COMUNE NASCITA', this.state.comuni);
            return this.state.comuni.map((city) => {
              return (
                <Option key={"comune_" + city.id} value={"" + city.comune}>
                  {city.comune}
                </Option>
              );
            });
          }}
          searchingComune={async (value) => {
            if (!this.state.searching_comuni) {
              this.setState({ searching_comuni: false });
              //console.log(this.state.selected_residenza_provincia)
              ComuniService.list(
                {
                  id_regione: 18,
                  comune: value,
                },
                (res) => {
                  this.setState({
                    comuni: res.data.data.list || [], //(cities.length > 0) ? cities.slice(0, 10) : [],
                    searching_comuni: false,
                  });
                },
                () => null
              );
            }
          }}
        />
      </div>
    );
  }
}
export default CreateAttrezzatura;
