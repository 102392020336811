import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import RisorseUmaneTable from './RisorseUmaneTable'

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 2,
                        items: [
                            {
                                type: 'integer',
                                label: 'Annualità',
                                name: 'annuity',
                                props: {
                                    min: 0,
                                    disabled: true,
                                },
                                showIf: [
                                    {
                                      type: 'custom_func',
                                      func: () => { return false }
                                    }
                                  ],

                            }
                        ],
                    },
                ],
            },
            /*{
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'textarea',
                                label: 'Metodologie adottate (Descrivere le metodologie didattiche adottate per il raggiungimento degli obiettivi di apprendimento)',
                                name: 'methods',
                                type_string: 'text',
                                showIf: [
                                    {
                                      type: 'custom_func',
                                      func: () => { return false }
                                    }
                                  ],
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                  ]
                            }
                        ],
                    },
                ]
            },*/
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'professionalita_coinvolte',
                                type: 'title',
                                element: 'h4',
                                text: 'Professionalità coinvolte (Indicare le figure professionali interne ed esterne coinvolte)',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                }
                            }
                        ],
                    }
                ],
            },
            {
                cols: [
                  {
                    items: [
                      {
                        name: 'organigramma_table',
                        type: 'child',
                        child: <div style={{ marginBottom: 16 }}>
                          <RisorseUmaneTable
                          loading={component.state.loading}
                            {...component.props}
                            checkOrganigramma = {(e, record) => {
                                component.checkPersonale(e, record, 'organogramList');
                            }}
                            checkDocente = {(e, record) => {
                                component.checkPersonale(e, record, 'teacherList');
                            }}
                            checkUtenzaSpeciale = {(e, record) => {
                                component.checkPersonale(e, record, 'specialUserList');
                            }}
                            checkTutorDidattico = {(e, record) => {
                                component.checkPersonale(e, record, 'tutorDidatticoList');
                            }}
                          />
                        </div>
                      }
                    ],
                  },
                ],
              },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'btn',
                                type: 'submit',
                                text: 'Salva',
                                btn_type: 'primary'
                            },
                        ],
                    },
                ],
            }

        ],
        onSubmit: 'submitted',
    }
}

class EditMetodologie extends Component {

    state = {
        loaded: false,
        loading: false,
        loadingTable: false,
        metodologie: {}
    };

    componentDidMount() {
        this.load(this.props)
    }



    load(props) {
        this.setState({
            loaded: true,
            metodologie: props.metodologie
        });
        this.props.onUpdate(props.metodologie);
    }


    submit(data) {
        let metodologie = this.state.metodologie;
        metodologie.annuity=data.annuity;
        metodologie.methods=data.methods;
        if (!this.state.loading) {
            this.setState({
                loading: true,
                metodologie: metodologie
            });
            this.props.onUpdate(metodologie);
            this.props.onCloseDrawerEditMetodologie('EditMetodologie');
        }
    }

    checkPersonale(e, record, listname) {
        let metodologie = this.state.metodologie

        if(listname === 'organogramList'){
            if(typeof metodologie['organogramList'] === 'undefined'){
                metodologie['organogramList'] = [];
            }
    
            if (e.target.checked) {
                metodologie['organogramList'].push(record)
            } else {
                metodologie['organogramList'] = metodologie['organogramList'].filter(item => !(item.id_funzione === record.id_funzione && item.cf === record.cf ));
            }
        }
        if(listname === 'teacherList'){
            if(typeof metodologie['teacherList'] === 'undefined'){
                metodologie['teacherList'] = [];
            }
    
            if (e.target.checked) {
                metodologie['teacherList'].push(record)
            } else {
                metodologie['teacherList'] = metodologie['teacherList'].filter(item => !( item.anagrafica.cf === record.anagrafica.cf ));
            }
        }
        if(listname === 'specialUserList'){
            if(typeof metodologie['specialUserList'] === 'undefined'){
                metodologie['specialUserList'] = [];
            }
    
            if (e.target.checked) {
                metodologie['specialUserList'].push(record)
            } else {
                metodologie['specialUserList'] = metodologie['specialUserList'].filter(item => !(item.id_funzione === record.id_funzione && item.cf === record.cf ));
            }
        }
        if(listname === 'tutorDidatticoList'){
            if(typeof metodologie['tutorDidatticoList'] === 'undefined'){
                metodologie['tutorDidatticoList'] = [];
            }
    
            if (e.target.checked) {
                metodologie['tutorDidatticoList'].push(record)
            } else {
                metodologie['tutorDidatticoList'] = metodologie['tutorDidatticoList'].filter(item => !(item.id_funzione === record.id_funzione && item.cf === record.cf ));
            }
        }

        
        this.setState({
            metodologie : metodologie
        })
      }

    render() {
        return (
            <div>
                <CommonForm
                    loading={this.props.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.metodologie}
                    readOnly={this.props.readOnly}
                    submitted={(data) => this.submit(data)}
                    _professionalita={[]}
                    _attrezzature={this.props.attrezzature?.attrezzature}
                    _audiovideo={this.props.attrezzature?.audiovisivi}
                />
            </div>
        )
    }
}

export default EditMetodologie;