import { Button, Card, message, Popconfirm } from "antd";
import moment from 'moment';
import React, { Component } from "react";
import CheckPermissions from "@common/decorators/CheckPermissions";
import Wrapper from "@common/decorators/Wrapper";
import checkPermissions from '@common/utils/check/permissions';
import format from '@common/utils/formatters/_';
import nominaStatic from '@common/utils/static/nomina';
import AttrezzatureImpianti from '../components/verbale/AttrezzatureImpianti';
import AttrezzatureMacchine from '../components/verbale/AttrezzatureMacchine';
import Considerazioni from '../components/verbale/Considerazioni';
import DettaglioCorsi from '../components/verbale/DettaglioCorsi';
import Ente from '../components/verbale/Ente';
import Locali from '../components/verbale/Locali';
import LocaliEAttrezzature from '../components/verbale/LocaliEAttrezzature';
import PersonaleDocente from '../components/verbale/PersonaleDocente';
import PersonaleNonDocente from '../components/verbale/PersonaleNonDocente';
import RegistroPresenze from '../components/verbale/RegistroPresenze';
import SoggettoIncaricato from '../components/verbale/SoggettoIncaricato';
import NominaServices from '../services/Nomina';

/*
const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Visita in itinere',
              name: 'visita_in_itinere',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },{
              type: 'datepicker',
              label: 'Data concordata',
              name: 'data_concordata',
              props: {
                //size: 'large',
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              showIf: [
                {
                  type: 'field_validation',
                  field: 'visita_in_itinere',
                  operator: '==',
                  value: "0"
                }
              ],
            },
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'soggetto_accompagnamento_title',
              type: 'title',
              element: 'h4',
              text: 'Soggetto incaricato all’accompagnamento degli ispettori durante l’ispezione:',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
            ,
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Nome',
              type_string: 'text', // field type
              name: 'accompagnatore_nome',
              props: {
                //size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Cognome',
              type_string: 'text', // field type
              name: 'accompagnatore_cognome',
              props: {
                //size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'datepicker',
              label: 'Data di nascita',
              name: 'accompagnatore_data_nascita',
              props: {
                style: {width: '100%'},
                //size: 'large',
                prefix: <Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Luogo di nascita',
              type_string: 'text', // field type
              name: 'accompagnatore_luogo_nascita',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Comune di residenza',
              type_string: 'text', // field type
              name: 'accompagnatore_residenza',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Via',
              type_string: 'text', // field type
              name: 'accompagnatore_via',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Qualifica',
              type_string: 'text', // field type
              name: 'qualifica_accompagnatore',
              props: {
                //size: 'large',
                prefix: <Icon type="setting" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'corsi_title',
              type: 'title',
              element: 'h4',
              text: 'Indicazione corsi autofinanziati',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Articolo 40',
              name: 'art_40',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Articolo 41',
              name: 'art_41',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'tipologia_corsi_title',
              type: 'title',
              element: 'h4',
              text: 'Tipologia corsi convenzionati',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'select',
              label: 'Selezionare le tipologie',
              name: 'tipo_corsi',
              mode: 'multiple',
              props: {
                //size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              // validations: [
              //   {
              //     type: 'required',
              //     error_message: 'Campo obbligatorio',
              //   },
              // ],
              options_props: 'tipicorsi',
              //onChange: 'selectedResidenzaProvincia',
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'locali_title',
              type: 'title',
              element: 'h4',
              text: 'Locali',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'select',
              label: 'Titolo di possesso',
              name: 'locali_possesso',
              props: {
                //size: 'large',
                showSearch: true,
                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              // validations: [
              //   {
              //     type: 'required',
              //     error_message: 'Campo obbligatorio',
              //   },
              // ],
              options_props: 'locali_possesso',
              //onChange: 'selectedResidenzaProvincia',
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Sono destinati esclusivamente ad attività formativa?',
              name: 'locali_esclusivi_formazione',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Sono inseriti in struttura condominiale?',
              name: 'locali_struttura_condominio',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste perizia giurata corredata da elaborato grafico in scala 1:200 indicante gli spazi destinati all’attività, redatta da un tecnico iscritto all’albo?',
              name: 'locali_scala_tecnico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Numeri totali locali',
              type_string: 'number', // field type
              name: 'locali_numero_totale',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 16,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'locali_numero_totale_note',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Numero aule teoriche',
              type_string: 'number', // field type
              name: 'aule_numero_teorico',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 16,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'aule_numero_teorico_note',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Numero aule teorico pratiche',
              type_string: 'number', // field type
              name: 'aule_numero_teorico_pratiche',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 16,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'aule_numero_teorico_pratiche_note',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Numero aule esercitazioni pratiche',
              type_string: 'number', // field type
              name: 'aule_numero_esercitazioni_pratiche',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 16,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'aule_numero_esercitazioni_pratiche_note',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Direzione',
              type_string: 'number', // field type
              name: 'aule_numero_direzione',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 16,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'aule_numero_direzione_note',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Segreteria',
              type_string: 'number', // field type
              name: 'aule_numero_segreteria',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 16,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'aule_numero_segreteria_note',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Numero servizi igienici',
              type_string: 'number', // field type
              name: 'aule_numero_servizi_igenici',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Di cui idonei',
              type_string: 'number', // field type
              name: 'aule_numero_servizi_igenici_idonei',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
        {
          span: 8,
          items: [
            {
              type: 'textarea',
              label: 'Note',
              type_string: 'text', // field type
              name: 'aule_numero_servizi_igenici_note',
              props: {
                //size: 'large',
                //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              }
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Locali rispondenti alle norme vigenti in materia di edilizia scolastica',
              name: 'locali_norma_edilizia_scolastica',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Locali adatti ad essere destinati ad aule teoriche o a laboratorio per le esercitazioni pratiche dei corsi che si intendono attivare',
              name: 'locali_adatti',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Locali rispondenti alle norme di prevenzione degli infortuni e igiene del lavoro, nonché alle condizioni di sicurezza contro i rischi derivanti dagli incendi',
              name: 'locali_norma_prevenzione_lavoro',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Numero degli allievi che possono frequentare contemporaneamente la sede formativa',
              name: 'numero_allievi_contemporaneamente',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste certificato di agibilità o di collaudo statico',
              name: 'certificato_agibilita',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il certificato di rispondenza ai requisiti igienico sanitari?',
              name: 'certificato_igienico_sanitario',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esistono in disegni planimetrici in scala 1:50 o 1:100 dei locali destinati ad aule, reparti, laboratori, ecc. con l’indicazione delle attrezzature e degli arredi?',
              name: 'planimetria_locali_attrezzature',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'attrezzature_title',
              type: 'title',
              element: 'h4',
              text: 'Attrezzature:',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'impianti_fissi_title',
              type: 'title',
              element: 'h4',
              text: 'Impianti fissi e installati',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Illuminazione',
              name: 'impianti_illuminazione',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Forza Motrice',
              name: 'impianti_forza_motrice',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Distribuzione gas climatizzazione',
              name: 'impianti_gas_climatizzazione',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Climatizzazione',
              name: 'impianti_climatizzazione',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Altro',
              name: 'impianti_altro',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'impianti_fissi_title',
              type: 'title',
              element: 'h4',
              text: 'Elenco delle macchine, delle attrezzature e degli arredi',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 8,
          items: [
            {
              type: 'input',
              label: 'Acquisite agli atti n. riportate nella tabella',
              type_string: 'text', // field type
              name: 'verbale_elenco_macchine_numero',
              // props: {
              //   //size: 'large',
              //   prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              // },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Sono rispondenti a quelli riportati in perizia e a quelli elencati nella monografia elaborata dall’Ente Gestore e inoltrata all’Assessorato alla Formazione Professionale',
              name: 'verbale_elenco_macchine_corrispondente_domanda',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Le attrezzature da utilizzare per l’attività formativa sono funzionali?',
              name: 'attrezzature_formative_funzionali',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'I macchinari e le attrezzature mossi elettronicamente sono dotati dei prescritti accorgimenti antinfortunistici?',
              name: 'attrezzature_elettroche_antinfortunistici',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il certificato di conformità alla L. 46/90 dell’impianto elettrico',
              name: 'certificato_conformita_impianto_elettrico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il certificato di conformità alla L. 46/90 dell’impianto termico',
              name: 'certificato_conformita_impianto_termico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il certificato di accreditamento della sede operativa',
              name: 'certificato_conformita_sede_operativa',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il certificato di prevenzione incendi',
              name: 'certificato_prevenzione_incendi',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esistono i verbali di collaudo e di verifica per gli impianti, attrezzature e macchine soggetti a controllo preventivo e periodico(ascensori, impianti di messa a terra, dispositivi contro le scariche atmosferiche, estintori ecc.',
              name: 'verbali_collaudo_verifica_impianti',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'sicurezza_luoghi_lavoro_title',
              type: 'title',
              element: 'h4',
              text: 'Sicurezza sui luoghi di lavoro',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Esiste il documento di valutazione dei rischi',
              name: 'documento_valutazione_rischi',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              props: {
                //style: { width: '100%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'personale_docente_nondocente_title',
              type: 'title',
              element: 'h4',
              text: 'Personale docente e non docente',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'direttore_centro_formazione',
              type: 'title',
              element: 'h4',
              text: 'Direttore del Centro di Formazione',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            }
          ],
        }
      ],
    },
    {
      cols: [
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Nome',
              type_string: 'text', // field type
              name: 'direttore_nome',
              props: {
                //size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
        {
          span: 6,
          items: [
            {
              type: 'input',
              label: 'Cognome',
              type_string: 'text', // field type
              name: 'direttore_cognome',
              props: {
                //size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Considerazioni conclusive',
              type_string: 'text', // field type
              name: 'considerazioni_conclusive',
              props: {
                //size: 'large',
                prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn_submit',
              text: 'Salva verbale ispezione',
              props: {
                className: 'm10h',
                icon: 'save'
              },
              //onClick: 'showDrawer'
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'onSubmit',
};
*/

@Wrapper({
  route: "VerbaleIspezione",
  hasSidebar: true,
  hasHeader: true
})
@CheckPermissions(["ispettorePermissions", "dirigentePermissions", "istruttorePermissions", "adminPermissions", "entePermissions"])
class VerbaleIspezione extends Component {

  state = {
    mounted: false,
    open_folder: 0,
    loading: false,
    loaded: false,
    accreditamento: {},
    verbale: {},
    visita: 1,
    tipo_media: [],
    locali_possesso: [
      { key: 1, label: "Proprietà", value: 1 },
      { key: 2, label: "Locazione", value: 2 },
      { key: 3, label: "Comodato", value: 3 }
    ],
    ente: {},
    anagrafiche: [],
    funzioni: [],
    docenti: [],
    utenze_speciali: [],
    sede: {},
    infrastruttura: [],
    aule: [],
    attrezzature: [],
    audiovisivi: [],
    ispettori: []
  };

  async componentDidMount() {

    await this.setState({ mounted: true, loaded: true });

    this.loadVerbale(this.props);

  }

  UNSAFE_componentWillReceiveProps(newProps) {
    try {
      if (this.props.match.params.id !== newProps.match.params.id ||
        this.props.match.params.id_accreditamento !== newProps.match.params.id_accreditamento) this.loadVerbale(newProps);
    } catch (e) {
      //console.log(e);
    }
  }


  formatInitialPersonale( verbale_data ) {
    let anagrafiche = [];

    let funzioni = [];
    let docenti = [];
    let utenze_speciali = [];
    verbale_data.capacita_gestionali.funzioni.map(el => {
      if(el.conSedi.find(el2 => el2.id_ente_sede === verbale_data.sede.id_ente_sede )) {
        // la funzione sta nella sede
        if(!anagrafiche.find(a => a.id === el.anagrafica.id)) anagrafiche.push(el.anagrafica);
        funzioni.push(el);
      }
      return el;
    })

    verbale_data.capacita_gestionali.docenti.map(el => {
      if(el.conSedi.find(el2 => el2.id_ente_sede === verbale_data.sede.id_ente_sede )) {
        // la funzione sta nella sede
        if(!anagrafiche.find(a => a.id === el.anagrafica.id)) anagrafiche.push(el.anagrafica);
        docenti.push(el);
      }
      return el;
    })

    verbale_data.capacita_gestionali.utenze_speciali.map(el => {
      if(el.conSedi.find(el2 => el2.id_ente_sede === verbale_data.sede.id_ente_sede )) {
        // la funzione sta nella sede
        if(!anagrafiche.find(a => a.id === el.anagrafica.id)) anagrafiche.push(el.anagrafica);
        utenze_speciali.push(el);
      }
      return el;
    });

    this.setState({
      anagrafiche: anagrafiche,
      funzioni: funzioni,
      docenti: docenti,
      utenze_speciali: utenze_speciali,
    })
  }




  loadVerbale(props) {
    NominaServices.viewVerbale( props.match.params.id_accreditamento, props.match.params.id_nomina, props.match.params.id_verbale, (res) =>{
      this.formatInitialPersonale( res.data.data );
      this.setState({
        accreditamento: res.data.data.accreditamento,
        tipoAccreditamento: res.data.data.tipoAccreditamento,
        verbale: res.data.data.verbale,
        ente: res.data.data.ente,
        sede: res.data.data.sede,
        infrastruttura: res.data.data.risorse_infrastrutturali,
        aule: res.data.data.aule,
        attrezzature: res.data.data.attrezzature,
        audiovisivi: res.data.data.audiovisivi,
        ispettori: res.data.data.ispettori,
        visita: res.data.data.verbale.dati_verbale ? parseInt(res.data.data.verbale.dati_verbale.visita_in_itinere) : 1,
        loaded: true
      });


    });
    /*
    IspezioneService.view( props.match.params.id_accreditamento, (res) => {
      let verbale = res.data.data.verbali.find(v => v.id === props.match.params.id_verbale );
      if(verbale) this.setState({verbale: verbale})

    } )*/
  }

  loadAccreditamento(props) {
    /*
    AccreditamentoService.detail(
      {
        expand:
          'media, nomina, nomina.media, nomina.note, nomina.verbale, nomina.verbale.media, nomina.verbale.note,' +
          'nomina.verbale.verbaleIspezione,nomina.verbale.verbaleIspezione.corsi,' +
          'sediIspezione,sediIspezione.indirizzo,sediIspezione.indirizzo.locComune,'+
          'funzioni, docenti, utenzespeciali, funzioni.anagrafica, docenti.anagrafica, utenzespeciali.anagrafica, funzioni.sedi, docenti.sedi, utenzespeciali.sedi, ' +
          'funzioni.anagrafica.rapportoLavoro, docenti.anagrafica.rapportoLavoro, utenzespeciali.anagrafica.rapportoLavoro, funzioni.anagrafica.rapportoLavoro.media, docenti.anagrafica.rapportoLavoro.media, utenzespeciali.anagrafica.rapportoLavoro.media,' +
          'ente, ente.contatti, ente.sedeLegale, ente.sedeLegale.indirizzo, ente.sedeLegale.indirizzo.locComune, ente.media, accreditamentosettori, accreditamentotipologie,' +
          'ente.affidabilitaEconomica, ente.qualita, ente.enteSede, ente.enteSede.indirizzo.locComune, ente.enteSede.indirizzo.locProvincia, docenti'
      },
      props.match.params.id_accreditamento,
      async res => {
        await this.setState({
          loaded: true,
          accreditamento: res.data.data
        });
      },
      err => null
    );
    */
  }

  async componentWillUnmount() {
    await this.setState({ mounted: false });
  }


  update(obj) {

  }

  getValues() {

    let verb = this.getVerbale();

    return format.convertMultipleInString( ['tipo_corsi'], format.convertBoolean([
      'visita_in_itinere','art_40', 'art_41', 'locali_esclusivi_formazione', 'locali_struttura_condominio',
      'locali_scala_tecnico', 'locali_norma_edilizia_scolastica', 'locali_adatti', 'locali_norma_prevenzione_lavoro',
      'numero_allievi_contemporaneamente', 'certificato_agibilita', 'certificato_igienico_sanitario', 'planimetria_locali_attrezzature',
      'impianti_illuminazione', 'impianti_forza_motrice', 'impianti_gas_climatizzazione', 'impianti_climatizzazione',
      'impianti_altro', 'verbale_elenco_macchine_corrispondente_domanda', 'attrezzature_formative_funzionali', 'attrezzature_elettroche_antinfortunistici',
      'certificato_conformita_impianto_elettrico', 'certificato_conformita_impianto_termico', 'certificato_conformita_sede_operativa',
      'certificato_prevenzione_incendi', 'verbali_collaudo_verifica_impianti', 'documento_valutazione_rischi'
    ], verb))
  }

  // refresh() {
  //   this.loadAccreditamento(this.props);
  // }

  canEdit() {
    return true
  }

  async saveVerbale(notify = false, cb = null) {

    //let valid = true;

    if(!this.state.loading || !notify) {
      this.setState({
        loading: true,
        saving: true
      }, async () => {


        let data = {};

        data = { ...data, ...this.refs.ente_form.getValues()};
        data = { ...data, ...this.refs.soggetto_form.getValues()};
        data = { ...data, ...this.refs.considerazioni_form.getValues()};
        data = { ...data, ...this.refs.locali_form.getValues()};
        data = { ...data, ...this.refs.impianti_form.getValues()};
        data = { ...data, ...this.refs.attrezzature_form.getValues()};
        //Bohr: sembra non utilizzato altrove e quindi qui risulta undefined
        //data = { ...data, ...this.refs.personale_form.getValues()};
        data = { ...data, ...this.refs.personale_docente_form.getValues()};
        data = { ...data, ...this.refs.personale_non_docente_form.getValues()};

        data = { ...data, ...this.refs.personale_non_docente_form.getValues()};

        //data = { ...data, ...this.refs.registro_presenze_form.getValues()};
        //data = { ...data, ...this.refs.locali_attrezzature_form.getValues()};


        NominaServices.saveVerbale(
          this.props.match.params.id_accreditamento,
          this.state.verbale.id,
          {dati: data},
          //format.convertMultipleOutString( ['tipo_corsi'], data),
          (res) => {

            this.setState({loading: false, saving: false, verbale: {...this.state.verbale, ...res.data.data} });
            if(notify) message.success("Dati salvati con successo!");
            if(cb) cb();
            //this.props.history.push("/accreditamento/" + this.state.accreditamento.id + "/nomina/" + this.props.match.params.id)
          },
          (err) => {
            this.setState({loading: false,saving: false});
            if(cb) cb();
          }
        )

      })
    }
  }

  terminate() {

    if(!this.state.loading) {
      this.setState({
        loading: true,
        confirming: true
      }, async () => {

        let valid = true;


        var v = await this.refs.ente_form.validate();
        if(!v) {valid = false;}
        v = await this.refs.soggetto_form.validate();
        if(!v) {valid = false;}
        v = await this.refs.considerazioni_form.validate();
        if(!v) {valid = false;}

        if(!valid) {
          this.setState({
            loading: false,
            confirming: false
          });
          return;
        }

        //Bohr validazione saltata - non ha senso qui

        v = await this.refs.locali_form.validate();
        if(!v) {valid = false;}
        v = await this.refs.impianti_form.validate();
        if(!v) {valid = false;}
        v = await this.refs.attrezzature_form.validate();
        if(!v) {valid = false;}

        //Bohr: sembra non utilizzato altrove e quindi qui risulta undefined
        /*
        var v = await this.refs.personale_form.validate();
        if(!v) valid = false;
        */
        //Bohr - commentato ab origine e non modificato da noi
        /*var v = await this.refs.personale_docente_form.validate();
        if(!v) valid = false;
        var v = await this.refs.personale_non_docente_form.validate();
        if(!v) valid = false; */

        if(!valid) {
          this.setState({
            loading: false,
            confirming: false
          });
          message.error('Ci sono errori nella scheda dell\'ispezione in loco');
          return;
        }


        /*if(this.state.visita !== 1) {
          v = await this.refs.registro_presenze_form.validate();
          if(!v) valid = false;
          v = await this.refs.locali_attrezzature_form.validate();
          if(!v) valid = false;

          if(!valid) {
            this.setState({
              loading: false,
              confirming: false
            });
            message.error('Ci sono errori nella scheda della visita in itinere/mirata');
            return;
          }
        }*/


        let data = {};

        data = { ...data, ...this.refs.ente_form.getValues()};
        data = { ...data, ...this.refs.soggetto_form.getValues()};
        data = { ...data, ...this.refs.considerazioni_form.getValues()};
        data = { ...data, ...this.refs.locali_form.getValues()};
        data = { ...data, ...this.refs.impianti_form.getValues()};
        data = { ...data, ...this.refs.attrezzature_form.getValues()};
        //Bohr: sembra non utilizzato altrove e quindi qui risulta undefined
        //data = { ...data, ...this.refs.personale_form.getValues()};
        data = { ...data, ...this.refs.personale_docente_form.getValues()};
        data = { ...data, ...this.refs.personale_non_docente_form.getValues()};

        data = { ...data, ...this.refs.personale_non_docente_form.getValues()};

        //if(this.state.visita !== 1) {
        //  data = { ...data, ...this.refs.registro_presenze_form.getValues()};
        //  data = { ...data, ...this.refs.locali_attrezzature_form.getValues()};
       // }



        NominaServices.saveVerbale(
          this.props.match.params.id_accreditamento,
          this.state.verbale.id,
          {dati: data},
          //format.convertMultipleOutString( ['tipo_corsi'], data),
          (res) => {

            NominaServices.confirmCompilazioneVerbale(
              this.props.match.params.id_accreditamento,
              this.state.verbale.id,
              (res2) => {
                this.setState({loading: false, confirming: false});
                message.success("Dati salvati con successo!");
                this.props.history.push("/accreditamento/" + res2.data.data.id_accreditamento + "/nomina/" + res2.data.data.id)
              },
              (err) => this.setState({loading: false, confirming: false})
            )
            //this.props.history.push("/accreditamento/" + this.state.accreditamento.id + "/nomina/" + this.props.match.params.id)
          },
          (err) => this.setState({loading: false, confirming: false})
        )
      })
    }
  }



  getNomina() {
    return this.state.accreditamento.nomina.find(n => n.id === this.props.match.params.id)
  }

  getVerbale() {
    //let v = this.getNomina().verbale.verbaleIspezione.find(v => v.id_sede === this.props.match.params.id_sede);
    //return v || {}
    return this.state.verbale;
  }

  getSede() {
    return this.state.accreditamento.sediIspezione.find(s => s.id === this.props.match.params.id_sede)
  }

  getSedeString() {
    let ss = this.getSede();
    return ss.indirizzo.via +" " + ss.indirizzo.ncivico + " " + ss.indirizzo.locComune.comune;
  }

  readOnly() {
    return this.state.verbale.stato !== nominaStatic.stato_verbale_ispezione.in_compilazione ? true : false
    /*try {
      return this.getVerbale().stato !== nominaStatic.stato_verbale_ispezione.in_compilazione ? true : false
    } catch(e) {
      return true;
    }*/
  }

  canSave() {
    if(!checkPermissions.hasPermission(this.props.UserStore.user, ['ispettorePermissions'])) return false;
    return this.state.verbale.id_ispettore === this.props.UserStore.user.id || !this.state.verbale.id_ispettore ? true : false;
  }

  canConfirm() {
    if(!checkPermissions.hasPermission(this.props.UserStore.user, ['ispettorePermissions'])) return false;
    return this.state.verbale.id_ispettore === this.props.UserStore.user.id ? true : false;
  }

  getTotaleOre() {
    return this.state.aule.length * 8 * 260
  }

  getPercentOre(percent) {
    return parseInt(this.getTotaleOre() / 100 * percent)
  }

  getMacrotipologie() {
    let v = this.state.sede.tipoAccreditamento.map(t => {
      try {
        return this.props.GlobalStore.stubs.tipo_accreditamento.find(e => e.value === t.id_tipologia).label + " (" + this.getPercentOre(t.percent) + "h)"
      } catch(e) {
        console.error(e)
        return null
      }
    } ).join(", ");

    return v
  }

  getSettori() {
    let v = this.state.sede.settore.map(t => {
      try {
        return this.props.GlobalStore.stubs.settori.find(e => e.value === t.id_settore).label
      } catch(e) {
        return null
      }
    } ).join(", ");

    return v
  }

  getUtenzeSpeciali() {
    let v = this.state.sede.utenzeSpeciali.map(t => {
      try {
        return this.props.GlobalStore.stubs.utenze_speciali.find(e => e.value === t.id_utenza_speciale).label
      } catch(e) {
        return null
      }
    } ).join(", ");

    return v
  }

  render() {

    return <div className="m20h">
        {this.state.loaded && this.state.ente.id ?
          <div>
            <Button
              onClick={()=>{
                if(!this.readOnly()){
                  this.saveVerbale(false, () => this.props.history.push('/accreditamento/scheda/'+this.state.accreditamento.id) );
                } else {
                  this.props.history.push('/accreditamento/scheda/'+this.state.accreditamento.id)
                }

              }}
              type="link"
            >
              <strong>SCHEDA ENTE</strong>
            </Button><br />
            {
              this.state.open_folder !== 0 ?
              <Button
                onClick={()=>this.setState({open_folder: 0})}
                type="link"
              >
                <strong>TORNA AL VERBALE</strong>
              </Button>
              : null
            }
            {
              this.state.open_folder === 0 ?
              <React.Fragment>
                <Button
                  onClick={()=>this.setState({open_folder: 1})}
                  type="link"
                >
                  <strong>VISITA ISPETTIVA IN LOCO</strong>
                </Button>
                 {
                //  this.state.visita !== 1
                //  ?
                //  <Button
                //    onClick={()=>this.setState({open_folder: 2})}
                //    type="link"
                //  >
                //</React.Fragment>    <strong>VISITA ISPETTIVA IN ITINERE/MIRATA</strong>
                //  </Button>
                //  : null
               }
              </React.Fragment>
              : null
            }
            <div style={{display: (this.state.open_folder === 0) ? 'block' : 'none'}}>

              <Card
              title="Verbale di visita ispettiva"
              //extra={<a href="#">More</a>}
              //style={{ width: 300 }}
              >

                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Ente di formazione</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.ente.denominazione}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Sede legale</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.ente.sedeLegale.indirizzo.full_address}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Rappr. legale</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.ente.rappresentanteLegale.nome + " " + this.state.ente.rappresentanteLegale.cognome}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Tipo accreditamento</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.tipoAccreditamento}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                className="m20h"
                title="Accertamenti preliminari"
              //extra={<a href="#">More</a>}
              //style={{ width: 300 }}
              >

                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Verbale n.</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.verbale.id}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Del</label>
                      </td>
                      <td className="gridViewCell">
                        {moment().format('DD/MM/YYYY')}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Relativo all'ispezione effettuta dai sottoscritti all'Ente Gestore</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.ispettori.map(i => {
                          return i.anagrafica.nome + " " + i.anagrafica.cognome
                        }).join(", ")}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Presso la sede formativa sita in:</label>
                      </td>
                      <td className="gridViewCell">
                        {this.state.sede.enteSede.indirizzo.full_address}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Macrotipologie:</label>
                      </td>
                      <td className="gridViewCell">
                        {this.getMacrotipologie()}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Settori:</label>
                      </td>
                      <td className="gridViewCell">
                        {this.getSettori()}
                      </td>
                    </tr>
                    <tr className="gridView">
                      <td className="gridViewCell width25">
                        <label>Utenze speciali:</label>
                      </td>
                      <td className="gridViewCell">
                        {this.getUtenzeSpeciali()}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </Card>
            </div>

            <div style={{display: (this.state.open_folder === 0) ? 'block' : 'none'}}>
              <Card
                className="m20h"

              //extra={<a href="#">More</a>}
              //style={{ width: 300 }}
              >
                <Ente ref="ente_form"
                readOnly={this.readOnly() || !this.canSave()}
                onChangeVisita={(f, value)=>{
                  this.setState({visita: parseInt(value.target.value)})
                }}
                values={this.state.verbale.dati_verbale || {}} />
                <SoggettoIncaricato ref="soggetto_form"
                  anagrafiche={this.state.anagrafiche}
                  verbale={this.state.verbale}
                  values={this.state.verbale.dati_verbale || {}}
                  GlobalStore={this.props.GlobalStore}
                  readOnly={this.readOnly() || !this.canSave()} />
                <Considerazioni ref="considerazioni_form"
                  values={this.state.verbale.dati_verbale || {}}
                  readOnly={this.readOnly() || !this.canSave()}
                  accreditamento={this.state.accreditamento}
                  verbale={this.state.verbale} />
                {/* Da spostare in check list
                <CorsiAutofinanziati ref="corsi_form" values={this.state.verbale.dati_verbale || {}} />*/}
                {/*<TipiCorsi ref="tipi_corsi_form" GlobalStore={this.props.GlobalStore}
              values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />*/}
                {/*<DettaglioCorsi verbale={this.getVerbale()} accreditamento={this.state.accreditamento} {...this.props} />
                <SiglaIspettori ref="ispettori_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                <Locali ref="locali_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                <AttrezzatureImpianti ref="impianti_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                <AttrezzatureMacchine ref="attrezzature_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                <Personale
                ref="personale_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                <PersonaleDocente
                docenti={this.state.accreditamento.docenti}
                sede={this.getSede()}
                id_ente={this.state.accreditamento.id_ente}
                values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                <PersonaleNonDocente
                funzioni={this.state.accreditamento.funzioni}
                utenze_speciali={this.state.accreditamento.utenzespeciali}
                sede={this.getSede()}
                id_ente={this.state.accreditamento.id_ente}
                values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                */}
              </Card>
            </div>

            <div style={{display: (this.state.open_folder === 1) ? 'block' : 'none'}}>
              <Card
                className="m20h"
                title="Check list verifica ispettiva in loco"
              >
                {/* Da spostare in check list
                <CorsiAutofinanziati ref="corsi_form" values={this.state.verbale.dati_verbale || {}} />*/}
                {/*<TipiCorsi ref="tipi_corsi_form" GlobalStore={this.props.GlobalStore}
              values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />*/}
                {/*<DettaglioCorsi verbale={this.getVerbale()} accreditamento={this.state.accreditamento} {...this.props} />*/}
                {/*<SiglaIspettori ref="ispettori_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />*/}
                <Locali
                sede={this.state.sede}

                aule={this.state.aule}
                ref="locali_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props}
                readOnly={this.readOnly() || !this.canSave()}
                />
                <AttrezzatureImpianti
                ref="impianti_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props}
                readOnly={this.readOnly() || !this.canSave()} />
                <AttrezzatureMacchine
                attrezzature={this.state.attrezzature}
                audiovisivi={this.state.audiovisivi}
                ref="attrezzature_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props}
                readOnly={this.readOnly() || !this.canSave()} />
                {/*
                <Personale
                readOnly={this.readOnly()}
                ref="personale_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props}
                readOnly={this.readOnly()} />
                */}
                <PersonaleDocente
                ref="personale_docente_form"
                docenti={this.state.docenti}
                sede={this.state.sede}
                id_ente={this.state.accreditamento.id_ente}
                values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props}
                readOnly={this.readOnly() || !this.canSave()}
                />
                <PersonaleNonDocente
                ref="personale_non_docente_form"
                funzioni={this.state.funzioni}
                utenze_speciali={this.state.utenze_speciali}
                sede={this.state.sede}
                id_ente={this.state.accreditamento.id_ente}
                values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props}
                readOnly={this.readOnly() || !this.canSave()}
                />

              </Card>
            </div>
        
            {
            /*
              <div style={{display: (this.state.open_folder === 2) ? 'block' : 'none'}}>
              <Card
                className="m20h"
                title="Check lista di verifica ispettiva in itinere/Mirata"
              >
                <DettaglioCorsi
                  readOnly={this.readOnly() || !this.canSave()}
                  verbale={this.state.verbale}
                  accreditamento={this.state.accreditamento} {...this.props}
                />
                <RegistroPresenze
                  ref="registro_presenze_form"
                  verbale={this.state.verbale}
                  values={this.state.verbale.dati_verbale || {}}
                  accreditamento={this.state.accreditamento} {...this.props}
                  readOnly={this.readOnly() || !this.canSave()}
                />

                <LocaliEAttrezzature
                  ref="locali_attrezzature_form"
                  verbale={this.state.verbale}
                  values={this.state.verbale.dati_verbale || {}}
                  accreditamento={this.state.accreditamento} {...this.props}
                  readOnly={this.readOnly() || !this.canSave()}
                />
                // Da spostare in check list
                // <CorsiAutofinanziati ref="corsi_form" values={this.state.verbale.dati_verbale || {}} />
                // <TipiCorsi ref="tipi_corsi_form" GlobalStore={this.props.GlobalStore}
                // values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
                // <DettaglioCorsi verbale={this.getVerbale()} accreditamento={this.state.accreditamento} {...this.props} />
                // <SiglaIspettori ref="ispettori_form" values={this.state.verbale.dati_verbale || {}} accreditamento={this.state.accreditamento} {...this.props} />
              </Card>
            </div>
            */
            }
            {
              !this.readOnly() && this.canSave()
              ?
              <div style={{marginBottom: 8}}>
                <Button
                  className="bg-green"
                  loading={this.state.saving}
                  disabled={this.state.loading}
                  icon="file-text"
                  onClick={()=>this.saveVerbale(true)}
                >
                  Salva dati
                </Button>
              </div>
              : null
            }
            {
              !this.readOnly() && this.canConfirm() && this.state.open_folder === 0
              ?
              <Popconfirm dplacement="leftTop" title="Confermando termini la compilazione del verbale, sei sicuro di voler proseguire?"
                onConfirm={() => this.terminate()} okText="Si" cancelText="No">
                <Button
                  type="primary"
                  loading={this.state.confirming}
                  disabled={this.state.loading}
                >
                  Conferma e termina la compilazione del verbale
                </Button>
                </Popconfirm>
              : null
            }
          </div>
          : null
        }
      </div>
    }
}


export default VerbaleIspezione;
