import React, { Component } from "react";
import CommonForm from "@common/components/form";
import checkPermission from "@common/utils/check/permissions";
import affidamentoStatic from "@common/utils/static/affidamento";
import ComuniService from '@common/services/locations/Comuni';
import eventBroker from '@common/utils/services/EventBroker';
import moment from 'moment';

import formatter from "@common/utils/formatters/_";

import { Icon, Spin, Tooltip } from "antd";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "dati_generali_progetto",
                type: "title",
                element: "h4",
                text: "Dati generali",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "periodo_svolgimento_corso",
                type: "title",
                element: "h4",
                text: "Periodo svolgimento corso",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "datepicker",
                label: "Data di avvio del corso",
                format: "DD/MM/YYYY",
                name: "AfcCourseTimeSection.startDate",
                props: {
                  size: "large",
                  style: { width: "100%" },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "datepicker",
                label: "Data di conclusione del corso",
                format: "DD/MM/YYYY",
                name: "AfcCourseTimeSection.endDate",
                props: {
                  size: "large",
                  style: { width: "100%" },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "date_proposte",
                type: "title",
                element: "h4",
                text: "Date proposte per lo svolgimento delle prove d’esame",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: "datepicker",
                label: "1° data proposta",
                format: "DD/MM/YYYY",
                name: "AfcProposedDate.first.date",
                props: {
                  size: "large",
                  style: { width: "100%" },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    validator: "dateProposteDifferenti"
                  }
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: "datepicker",
                label: "2° data proposta",
                format: "DD/MM/YYYY",
                name: "AfcProposedDate.second.date",
                props: {
                  size: "large",
                  style: { width: "100%" },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    validator: "dateProposteDifferenti"
                  }
                ],
              },
            ],
          },
          {
            span: 8,
            items: [
              {
                type: "datepicker",
                label: "3° data proposta",
                format: "DD/MM/YYYY",
                name: "AfcProposedDate.third.date",
                props: {
                  size: "large",
                  style: { width: "100%" },
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    validator: "dateProposteDifferenti"
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "date_proposte",
                type: "title",
                element: "h4",
                text: "Sede d'esame",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label: "La sede è la stessa dello svolgimento del corso ?",
                name: "AfcExamSite.sameSite",
                option_type: "button",
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: "select",
                label: "Nazione",
                name: "AfcExamSite.nation",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  //size: 'large',
                  //disabled: component.props.readOnly,
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "nazioni",
                onChange: "selectedStato",
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: "select",
                label: "Provincia",
                name: "AfcExamSite.province",
                props: {
                  // size: 'large',
                  allowClear: true,
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "requiredIf",
                    error_message: "Campo obbligatorio",
                    field: "AfcExamSite.nation",
                    operator: "==",
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                options_props: "province",
                onChange: "selectedProvincia",
              },
            ],
          },
          {
            span: 14,
            items: [
              {
                type: "select",
                label: "Comune",
                name: "AfcExamSite.city",
                props: {
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "requiredIf",
                    error_message: "Campo obbligatorio",
                    field: "AfcExamSite.nation",
                    operator: "==",
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  },
                  {
                    type: "field_validation",
                    field: "AfcExamSite.province",
                    operator: "!=",
                    value: null,
                  },
                ],
                options_props: "comuni",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: "input",
                label: "Via",
                type_string: "text", // field type
                name: "AfcExamSite.address",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  // size: 'large'
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="pushpin" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: "input",
                label: "N",
                type_string: "text", // field type
                name: "AfcExamSite.addressNumber",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  // size: 'large',
                  placeholder: "N.",
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: "input",
                label: "Cap",
                type_string: "text", // field type
                name: "AfcExamSite.addressZipCode",
                props: {
                  //size: 'large',
                  placeholder: "Cap",
                  maxLength: 5,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "[0-9]{5,5}",
                    message: "Cap non valido",
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Telefono",
                type_string: "text", // field type
                name: "AfcExamSite.telephone",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  // size: 'large',
                  //disabled: component.state.disabledInput,
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^([+]{0,1})([0-9 ]{6,50})$",
                    message: "Telefono non valido",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Cellulare",
                type_string: "text", // field type
                name: "AfcExamSite.cellular",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  // size: 'large',
                  //disabled: component.state.disabledInput,
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mobile" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^([+]{0,1})([0-9 ]{6,50})$",
                    message: "Telefono non valido",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Fax",
                type_string: "text", // field type
                name: "AfcExamSite.fax",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="file" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Email",
                type_string: "text", // field type
                name: "AfcExamSite.email",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: false
                  }
                ],
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: "select",
                label: "Nazione",
                name: "AfcExamSite.nation",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  //size: 'large',
                  //disabled: component.props.readOnly,
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
                options_props: "nazioni",
                onChange: "selectedStato",
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: "select",
                label: "Provincia",
                name: "AfcExamSite.province",
                props: {
                  // size: 'large',
                  allowClear: true,
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "requiredIf",
                    error_message: "Campo obbligatorio",
                    field: "AfcExamSite.nation",
                    operator: "==",
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                options_props: "province",
                onChange: "selectedProvincia",
              },
            ],
          },
          {
            span: 14,
            items: [
              {
                type: "select",
                label: "Comune",
                name: "AfcExamSite.city",
                props: {
                  showSearch: true,
                  disabled: component.props.readOnly,
                  filterOption: (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0,
                },
                validations: [
                  {
                    type: "requiredIf",
                    error_message: "Campo obbligatorio",
                    field: "AfcExamSite.nation",
                    operator: "==",
                    value: 1,
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  },
                  {
                    type: "field_validation",
                    field: "AfcExamSite.province",
                    operator: "!=",
                    value: null,
                  },
                ],
                options_props: "comuni",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 14,
            items: [
              {
                type: "input",
                label: "Via",
                type_string: "text", // field type
                name: "AfcExamSite.address",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  // size: 'large'
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="pushpin" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 4,
            items: [
              {
                type: "input",
                label: "N",
                type_string: "text", // field type
                name: "AfcExamSite.addressNumber",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  // size: 'large',
                  placeholder: "N.",
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: "input",
                label: "Cap",
                type_string: "text", // field type
                name: "AfcExamSite.addressZipCode",
                props: {
                  //size: 'large',
                  placeholder: "Cap",
                  maxLength: 5,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "[0-9]{5,5}",
                    message: "Cap non valido",
                  },
                ],
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Telefono",
                type_string: "text", // field type
                name: "AfcExamSite.telephone",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  // size: 'large',
                  //disabled: component.state.disabledInput,
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^([+]{0,1})([0-9 ]{6,50})$",
                    message: "Telefono non valido",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Cellulare",
                type_string: "text", // field type
                name: "AfcExamSite.cellular",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  // size: 'large',
                  //disabled: component.state.disabledInput,
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mobile" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                  {
                    pattern: "^([+]{0,1})([0-9 ]{6,50})$",
                    message: "Telefono non valido",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Fax",
                type_string: "text", // field type
                name: "AfcExamSite.fax",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="file" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "input",
                label: "Email",
                type_string: "text", // field type
                name: "AfcExamSite.email",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcExamSite.sameSite',
                    operator: '==',
                    value: true
                  }
                ],
                props: {
                  // size: 'large',
                  disabled: component.props.readOnly,
                  prefix: (
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  ),
                },
              },
            ],
          },
        ],
      },
            {
        cols: [
          {
            items: [
              {
                name: 'AfcReferrer',
                type: 'title',
                element: 'h4',
                text: 'Referente dell’organismo per gli esami',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Nome',
                type_string: 'text', // field type
                name: 'AfcReferrer.name',
                props: {
                  //size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: 'input',
                label: 'Cognome',
                type_string: 'text', // field type
                name: 'AfcReferrer.surname',
                props: {
                  // size: 'large',
                  prefix: (
                    <Icon type="solution" style={{ color: 'rgba(0,0,0,.25)' }} />
                  ),
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'datepicker',
                label: 'Data di nascita',
                name: 'AfcReferrer.birthDate',
                props: {
                  // size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'age18Validator'
                  }
                ]
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Stato di nascita',
                name: 'AfcReferrer.birthNation',
                props: {
                  //size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'nazioni',
                onChange: 'selectedStato',
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Provincia di nascita',
                name: 'AfcReferrer.birthProvince',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcReferrer.birthNation',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'province',
                onChange: 'selectedProvincia2',
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'select',
                label: 'Comune di nascita',
                name: 'AfcReferrer.birthCity',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'AfcReferrer.birthProvince',
                    operator: '!=',
                    value: null,
                  },
                  {
                    type: 'field_validation',
                    field: 'AfcReferrer.birthNation',
                    operator: '==',
                    value: 1,
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options_props: 'comuni2'
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Codice fiscale',
                type_string: 'text', // field type
                name: 'AfcReferrer.fiscalCode',
                props: {
                  // size: 'large',
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^[A-z0-9]{16}$',
                    message: 'Codice fiscale non valido'
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Telefono',
                type_string: 'text', // field type
                name: 'AfcReferrer.telephone',
                props: {
                  // size: 'large',
                  prefix: <Icon type="copy" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                    message: 'Telefono non valido'
                  },
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'AfcLearnerNumber',
                type: 'title',
                element: 'h4',
                text: 'Numero allievi',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
        span: 6,
        items: [
            {
                type: 'integer',
                label: 'Numero allievi inizio corso',
                name: 'AfcLearnerNumber.startCourse',

                props: {
                    disabled: component.props.readOnly,
                    min: 0,
                },
                validations: [
                    {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                    }
                ],
            },
        ],
      },
      {
        span: 6,
        items: [
            {
                type: 'integer',
                label: 'Numero allievi fine corso',
                name: 'AfcLearnerNumber.endCourse',
                validations: [
                    {
                        type: 'required',
                        error_message: 'Campo obbligatorio',
                    },
                    {
                      validator: "numeroAllieviQuantita"
                    }
                ],
            },
        ],
      },
    ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'AfcLearnerNumber.corseHours',
              type: 'title',
              element: 'h4',
              text: 'Ore corso',
              props: {
                style: { textAlign: 'left' },
                className: 'm10h'
              },
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
      span: 6,
      items: [
          {
              type: 'integer',
              label: 'Ore complessive previste corso',
              name: 'AfcLearnerNumber.courseTotalHours',

              props: {
                  disabled: component.props.readOnly,
                  min: 0,
              },
              validations: [
                  {
                      type: 'required',
                      error_message: 'Campo obbligatorio',
                  }
              ],
          },
      ],
    },
    {
      span: 6,
      items: [
          {
              type: 'integer',
              label: 'Ore effettive svolte',
              name: 'AfcLearnerNumber.courseWorkedHours',
              validations: [
                  {
                      type: 'required',
                      error_message: 'Campo obbligatorio',
                  }
              ],
          },
      ],
    },
  ],
  },
  {
    cols: [
      {
        items: [
          {
            name: "AfcTeachers",
            type: "title",
            element: "h4",
            text: "Docenti designati",
            props: {
              style: { textAlign: "left" },
              className: "m10h",
            },
          },
        ],
      },
    ],
  },
  {
    cols: [
      {
        span: 8,
        items: [
          {
            type: 'select',
            label: '1° Docente',
            name: 'AfcTeachers.first.teacher',
            options_props: 'teachers',
            onChange: '',
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio',
              },
              {
                validator: "docentiDifferenti",
              }
            ]
          }
        ],
      },
      {
        span: 8,
        items: [
          {
            type: 'select',
            label: '2° Docente',
            name: 'AfcTeachers.second.teacher',
            options_props: 'teachers',
            onChange: '',
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio',
              },
              {
                validator: "docentiDifferenti",
              }
            ]
          }
        ],
      },
      {
        span: 8,
        items: [
          {
            type: 'select',
            label: '3° Docente (eventuale sostituto)',
            name: 'AfcTeachers.third.teacher',
            options_props: 'teachers',
            onChange: '',
            validations: [
              {
                validator: "docentiDifferenti"
              }
            ]
          }
        ],
      },
    ],
  },
      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit_dati_generali",
                text: "Salva i dati",
                //onClick: 'showDrawer',
                showIf: [
                  {
                    type: "custom_func",
                    func: () => {
                      return (
                        !component.props.anteprima && !component.props.readOnly
                      );
                    },
                  },
                ],
                props: {
                  icon: "save",

                  className: "bg-green",
                },
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submit",
  };
};

export default class DatiGeneraliForm extends Component {
  constructor(props) {
    super(props);
    this.getBackGeneralData = this.getBackGeneralData.bind(this);
    eventBroker.on('getBackGeneralData', this.getBackGeneralData);
  }


  state = {
    loaded: false,
    loading: false,
    generalData: this.props.generalData,
    selected_nascita_provincia: null,
    selected_nascita_provincia2: null,
    comuni: [],
    comuni2: [],
    teacherList: this.props.domanda_json.generalData.AfcTeachers.teacherList != undefined ? this.props.domanda_json.generalData.AfcTeachers.teacherList : [] 
  };

  componentDidMount() {
    this.load(this.props);

  }

  load(props) {
    this.setState({ loading: true })
    this.setState({
      loading: false,
      loaded: true,
      generalData: props.generalData,
    });
  

  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, [
      "entePermissions",
    ]);
  }

  getValues() {
    let data = this.props.domanda_json.references.mainSection.id_progetto == null ? this.props.datiProgetto.generalData : this.props.domanda_json.generalData  ;
  

       if (this.state.comuni.length < 1) {
        ComuniService.all(
          {
            id_provincia: this.props.domanda_json.generalData.AfcExamSite.province
          },
          res => {
  
            this.setState({
              comuni: res.data.data || [],
              selected_provincia: this.props.domanda_json.generalData.AfcExamSite.province
            });
          },
          () => null
        );
        ComuniService.all(
          {
            id_provincia: this.props.domanda_json.generalData.AfcReferrer.birthProvince
          },
          res => {
  
            this.setState({
              comuni2: res.data.data || [],
              selected_provincia2: this.props.domanda_json.generalData.AfcReferrer.birthProvince
            });
          },
          () => null
        );
       }
      
  
      data.AfcCourseTimeSection = formatter.replaceFormDatesIn(['startDate', 'endDate'], data.AfcCourseTimeSection)
      data.AfcProposedDate.first = formatter.replaceFormDatesIn(['date'], data.AfcProposedDate.first)
      data.AfcProposedDate.second = formatter.replaceFormDatesIn(['date'], data.AfcProposedDate.second)
      data.AfcProposedDate.third = formatter.replaceFormDatesIn(['date'], data.AfcProposedDate.third)
      
    
    return data;
  }

  getBackGeneralData() {
    return this.formSelRef?.props.form.getFieldsValue();
  }


  submit(data) {    
    data.AfcCourseTimeSection.startDate = moment(data.AfcCourseTimeSection.startDate).format('YYYY-MM-DD')
    data.AfcCourseTimeSection.endDate = moment(data.AfcCourseTimeSection.endDate).format('YYYY-MM-DD')

    data.AfcProposedDate.first.date = moment(data.AfcProposedDate.first.date).format('YYYY-MM-DD')
    data.AfcProposedDate.second.date = moment(data.AfcProposedDate.second.date).format('YYYY-MM-DD')
    data.AfcProposedDate.third.date  = moment(data.AfcProposedDate.third.date).format('YYYY-MM-DD')


    let dati = {
      generalData: data,
      references: this.props.domanda_json.references,
      attachments: this.props.domanda_json.attachments,
      idEnte: this.props.domanda_json.idEnte,
      scheda: "dati_generali"
    }
    console.warn("submit", dati)
    this.props.SaveData(dati)
  }

  selectedProvincia(provincia) {

    this.setState({ loading: true });


    ComuniService.all(
      {
        id_provincia: provincia
      },
      res => {

        this.setState({
          loading: false,
          comuni: res.data.data || [],
          selected_nascita_provincia: provincia
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }


  render() {
    return (
      this.props.domanda_json.references.mainSection.id_progetto == null && this.props.datiProgetto == null  ?
      <div><p>completa la sezione "Riferimenti" prima di procedere</p></div>
      :
      <div
        style={{ opacity: this.state.loading ? 0.5 : 1 }}
      >
        {!this.props.saved ? <Spin /> : null}
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "viewValutazioneScheda",
        ]) && (
          <ValidazioneScheda
            canGiveFeedback={
              this.props.canGiveSectionFeedback &&
              this.props.stato_flusso ===
                affidamentoStatic.stato_flusso_progetto.audit_iniziato
            }
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) =>
              this.props.saveFeedback(note, valid, "generalData")
            }
            feedback={
              this.props.section_feedback
                ? this.props.section_feedback["generalData"]
                : null
            }
          />
        )}
        <CommonForm
          nazioni={this.props.GlobalStore.stubs.nazione}
          province={this.props.GlobalStore.stubs.provincia}
          loading={this.props.saving || this.state.loading || false}
          wrappedComponentRef={inst => (this.formRef = inst)}
          form_model={formLayout(this)}
          values={this.getValues()}
          age18Validator={(rule, value, cb) => {
            var today = moment();
            if (today.diff(value, 'years') < 18) cb("Il referente deve maggiorenne!");
            else cb()
          }}
          min_date={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            let min = value;
  
            if (min < moment()) cb("La data proposta deve essere maggiore della data odierna");
            let max = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['endDate'];
  
            if (max && min > max) cb("La data proposta deve essere minore della data di conclusione del corso");
            else cb()
          }}
          dateProposteDifferenti={(rule, value, cb) => {
            const firstDate = this.formRef.props.form.getFieldValue('AfcProposedDate.first.date');
            const secondDate = this.formRef.props.form.getFieldValue('AfcProposedDate.second.date');
            const thirdDate = this.formRef.props.form.getFieldValue('AfcProposedDate.third.date');

            if(firstDate === null && secondDate === null && thirdDate === null){
              cb();
            }
            if(firstDate !== null && secondDate === null && thirdDate === null){
              cb();
            }
            if(firstDate === null && secondDate !== null && thirdDate === null){
              cb();
            }
            if(firstDate === null && secondDate === null && thirdDate !== null){
              cb();
            }
            if(firstDate !== null && secondDate !== null && firstDate.format('YYYY-MM-DD') == secondDate.format('YYYY-MM-DD')){
              cb("Le date proposte devono essere differenti tra di loro");
            }
            if(firstDate !== null && thirdDate !== null && firstDate.format('YYYY-MM-DD') == thirdDate.format('YYYY-MM-DD')){
              cb("Le date proposte devono essere differenti tra di loro");
            }
            if(secondDate !== null && thirdDate !== null && secondDate.format('YYYY-MM-DD') == thirdDate.format('YYYY-MM-DD')){
              cb("Le date proposte devono essere differenti tra di loro");
            }
            cb();
          }}
          numeroAllieviQuantita = {(rule, value, cb) => {
            const startCourse = this.formRef.props.form.getFieldValue('AfcLearnerNumber.startCourse');
            const endCourse = this.formRef.props.form.getFieldValue('AfcLearnerNumber.endCourse');

            if(startCourse === null && endCourse === null){
              cb();
            }
            if(startCourse !== null && endCourse === null){
              cb();
            }
            if(startCourse === null && endCourse !== null){
              cb();
            }
            if(startCourse !== null && endCourse !== null && startCourse < endCourse){
              cb("Numero allievi fine corso deve essere minore o uguale al Numero allievi inizio corso");
            }
            cb();
          }}
          docentiDifferenti={(rule, value, cb) => {
            const firstDate = this.formRef.props.form.getFieldValue('AfcTeachers.first.teacher');
            const secondDate = this.formRef.props.form.getFieldValue('AfcTeachers.second.teacher');
            const thirdDate = this.formRef.props.form.getFieldValue('AfcTeachers.third.teacher');
            console.warn("docentiDifferenti", firstDate, secondDate, thirdDate);

            if(firstDate === null && secondDate === null && thirdDate === null){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 1);
              cb();
            }
            if(firstDate !== null && secondDate === null && thirdDate === null){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 2);
              cb();
            }
            if(firstDate === null && secondDate !== null && thirdDate === null){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 3);
              cb();
            }
            if(firstDate === null && secondDate === null && thirdDate !== null){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 4);
              cb();
            }
            if(firstDate !== null && secondDate !== null && firstDate == secondDate){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 5);
              cb("I docenti devono essere differenti tra di loro");
            }
            if(firstDate !== null && thirdDate !== null && firstDate == thirdDate){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 6);
              cb("I docenti devono essere differenti tra di loro");
            }
            if(secondDate !== null && thirdDate !== null && secondDate == thirdDate){
              console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 7);
              cb("I docenti devono essere differenti tra di loro");
            }
            console.warn("docentiDifferenti", firstDate, secondDate, thirdDate, 8);
            cb();
          }}
          teachers={ this.props.teachers != undefined ? this.props.teachers.map(e => {
            return {
                cf: e.cf,
                value: e.nome +' ' +  e.cognome,
                label: e.nome +' ' +  e.cognome,
                funzione: e.funzione
            }
        }) : []}
          
          readOnly={this.props.readOnly}
          submit={(data) => this.submit(data)}
          
          comuni={this.state.comuni.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}
                    
          comuni2={this.state.comuni2.map(e => {
            return {
              key: 'residenza_comune_' + e.id,
              value: e.id,
              label: e.comune
            }
          })}
          selectedProvincia={data => {
            //console.log (this.formRef.props.form);

            this.setState({
              selected_provincia: data,
              comuni: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni: res.data.data || []
                  }, ()=>{
                    this.formRef.props.form.setFieldsValue({ comune: null });
                  });
                },
                () => null
              );
            });
          }}

          selectedProvincia2={data => {
            //console.log (this.formRef.props.form);

            this.setState({
              selected_provincia2: data,
              comuni2: []
            }, () => {
              this.formRef.props.form.setFieldsValue({ comune2: null });
              ComuniService.all(
                { id_provincia: data },
                res => {
                  this.setState({
                    comuni2: res.data.data || []
                  }, ()=>{
                    this.formRef.props.form.setFieldsValue({ comune2: null });
                  });
                },
                () => null
              );
            });
          }}
        />
      </div>
    );
  }
}
