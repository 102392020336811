import React, { Component } from 'react';

import { Divider } from 'antd';

import Wrapper from '@common/decorators/Wrapper';

import NotaPubblicazione from "../../../assets/Pubblicazione_Elenco_enti _accreditati_in_Piattaforma_SISFO.pdf";


@Wrapper({ route: 'Dashboard', hasSidebar: true, hasHeader: true, hasFooter: true, public: true })
class Dashboard extends Component {

  state = {
    loading: false
  }

  render() {
    return (
      <div>

        <h2>SISFO</h2>
        <p>"Il S.I.S.FO. - “Sistema Informativo di Supporto alla Formazione” è una piattaforma informatica concepita per rendere più efficiente ed efficace la gestione dei macroprocessi della formazione professionale, sia per la Regione Calabria che per gli Enti di Formazione e i discenti coinvolti.
          La piattaforma permette di gestire i processi inerenti le richieste di accreditamento, di riconoscimento, di autorizzazione all’erogazione dei corsi autofinanziati, di partecipazione ai bandi di finanziamento, di nomina commissari/presidenti d’esame, nonché il monitoraggio dei corsi finanziati e autofinanziati e la rendicontazione dei corsi finanziati."
          <br />Il S.I.S.FO. governa anche il flusso dei documenti, ed è direttamente collegato agli altri sistemi informatici regionali e nazionali del mercato del lavoro.
          <br />L’accesso è consentito, per le loro precise competenze, a tutti gli operatori della formazione professionale regionale.
          <br />Il S.I.S.FO. segue la normativa vigente in materia di formazione professionale.
          <br />
          <br /><a href={NotaPubblicazione} target="_blank" rel="noopener noreferrer">Elenco degli enti accreditati aggiornato al 31/03/2023.pdf</a><br />
          </p>
        <Divider />
        {/* <DashboardLayout /> */}
        {/* {this.returnComponent()} */}
      </div>
    );
  }
}

export default Dashboard