import React, { Component } from 'react';
import Organigramma from './risorse-capacita/Organigramma';
import Docenti from './risorse-capacita/Docenti';
import UtenzeSpeciali from './risorse-capacita/UtenzeSpeciali';
import CapacitaGestionaliServices from '../../services/CapacitaGestionali';

import Main from '../../forms/completeRequest/risorse-capacita/Main';

import ValidationScheda from '../audit/ValidationScheda';

class RisorseCapacita extends Component {

  state = {
    loading: false,
    loading_organigramma: false,
    loading_docenti: false,
    loading_utenze_speciali: false,
    open_drawer: false,
    capacita: {},
    sedi: [],
    funzioni: [],
    docenti: [],
    utenze_speciali: [],
    accreditamento: {}
  };



  componentDidMount() {
    if(this.props.current_key === "2") this.loadData(this.props, null, false);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.current_key !== this.props.current_key && newProps.current_key === "2") this.loadData(newProps, null, false)
  }

  loadData(props, el = null, refresh = true) {

    if(props.reloadProgress && refresh) props.reloadProgress();

    let str_loading = (!el) ? 'loading' : 'loading_' + el;

    this.setState({[str_loading]: true},()=>{
      setTimeout(() => {
        CapacitaGestionaliServices.list( props.accreditamento.id, (res) => {
          this.setState({
            accreditamento: res.data.data.accreditamento,
            capacita: res.data.data.capacita,
            funzioni: res.data.data.funzioni,
            docenti: res.data.data.docenti,
            utenze_speciali: res.data.data.utenze_speciali,
            sedi: res.data.data.sedi,
            [str_loading]: false
          })
        }, () => this.setState({[str_loading]: false}))
      }, 500);
    })

  }

  refresh() {
    return this.props.refresh();
  }

  getPr() {
    let p = Object.assign({}, this.props);
    delete p.accreditamento;

    return p
  }

  render() {
    return (
      <React.Fragment>

        <ValidationScheda
            section="capacita_gestionali"
            refreshInline={this.props.refreshInline}
            accreditamento={this.props.accreditamento}
            progress={this.props.progress}
          />
        <div>

          <Organigramma

            accreditamento={this.state.accreditamento}
            funzioni={this.state.funzioni}
            onUpdate={()=>this.loadData(this.props, 'organigramma')}
            loading_data={this.state.loading}
            loading_organigramma={this.state.loading_organigramma}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
            sedi={this.state.sedi}
            {...this.getPr()}
          />
          <Docenti

            accreditamento={this.state.accreditamento}
            docenti={this.state.docenti}
            onUpdate={()=>this.loadData(this.props, 'docenti')}
            loading_data={this.state.loading}
            loading_docenti={this.state.loading_docenti}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
            sedi={this.state.sedi}
            {...this.getPr()}
          />
          <UtenzeSpeciali

            accreditamento={this.state.accreditamento}
            utenze_speciali={this.state.utenze_speciali}
            onUpdate={()=>this.loadData(this.props, 'utenze_speciali')}
            loading_data={this.state.loading}
            loading_utenze_speciali={this.state.loading_utenze_speciali}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
            sedi={this.state.sedi}
            {...this.getPr()}
          />
          <Main
            GlobalStore={this.props.GlobalStore}
            accreditamento={this.props.accreditamento}
            capacita={this.state.capacita}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
            onUpdate={()=>{
              if(this.props.reloadProgress) this.props.reloadProgress();
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default RisorseCapacita;