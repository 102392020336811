import React, { Component } from 'react';

import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';

import RequisitiForm from '@accreditamento/forms/completeRequest/infrastruttura/Requisiti';

class Requisiti extends Component {

  state = {
    loaded: false,
    loading: false,
    requisiti: {},
    media: []
  };



  componentDidMount() {
    this.setState({
      loaded: true
    })
  }

  async save(data) {

    if(!this.state.loading) {
      await this.setState({loading: true});
      InfrastrutturaServices.addRequisiti(
        this.props.accreditamento.id,
        this.props.sede.id_ente_sede,
        data,
        () => {
          if(this.props.onUpdate) this.props.onUpdate()
          this.setState({loading: false});
          if(this.props.close) this.props.close()
        }, () => this.setState({loading: false})
      )
    }
  }

  render() {
    return (
      <div>
        {this.state.loaded ?
        <RequisitiForm
          values={this.props.sede.requisitiAule}
          media={this.props.sede.media}
          id_sede_accreditamento={this.props.sede.id}
          onSubmit={(data)=>this.save(data)}
          readOnly={this.props.readOnly}
          readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
          loading={this.state.loading}
          accreditation_id={this.props.accreditamento.id}
          accreditamento={this.props.accreditamento}
          type={this.props.type}
        /> : null}
      </div>
    );
  }
}
export default Requisiti;