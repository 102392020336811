import React, { Component } from "react";
import { Card, Divider, Col, Row, Button, message, Spin } from "antd";
import Wrapper from "@common/decorators/Wrapper";
import nominaService from '../services/Nomina';
import { Link } from "react-router-dom";
import Documenti from '@common/components/Documenti';


import nominaStatic from '@common/utils/static/nomina';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CheckPermissions from "@common/decorators/CheckPermissions";
import NominaProgettoActions from "../components/nomina/NominaProgettoActions";
import checkPermission from '@common/utils/check/permissions';
import FileSaver from 'file-saver';
import IspezioneVerbale from "../components/ispezione/IspezioneVerbale";

@Wrapper({ route: 'NominaProgetto', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewNomina'])
class NominaProgettoRoute extends Component {

  state = {
    mounted: false,
    loaded: false,
    nomina: null,
  }

  componentDidMount() {
    this.setState({ mounted: true, loaded: false }, () => {
      if (this.props.match.params.id) {
        this.loadNomina(this.props);
      }
    });
  }

  loadNomina(props) {
    nominaService.detail({
      expand: [
        'ispezione', 'ispezione.progetto', 'media', 'ispezione.verbali', 'ispezione.verbali.media', 'ispezione.verbali.sede', 'ispezione.verbali.sede.indirizzo'
      ].join(',')
    }, props.match.params.id,
      (res) => {
        this.setState({
          nomina: res.data.data,
          loaded: true,
          refreshing: false
        });
      },
      err => this.setState({
        loaded: true,
        refreshing: false
      })
    );
  }

  refreshInline(data) {
    this.setState({
      nomina: {
        ...data
      }
    });
  }

  refresh() {
    this.setState({refreshing: true},
      ()=>this.loadNomina(this.props))

  }

  removing(){
    this.setState({loading: true})
  }

  finish(){
    this.setState({loading: false})
  }


  canEditOwnNomina() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnNomina']) && this.props.UserStore.user.id === this.state.nomina.id_ispettore
  }

  saveBlobWord(blob, title) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, title);
  }

  saveBlob(blob, filename) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, filename);
  }

  feedback(feedback, note) {
    if (!this.state.refusing) {
      this.setState({
        refusing: true
      }, () => {
        nominaService.feedback(
          this.state.nomina.ispezione.id_progetto,
          this.state.nomina.id,
          { accept: feedback, note: note },
          (res) => {
            this.setState({
              refusing: false
            })
            this.refreshInline(res.data.data)
          },
          () => this.setState({
            refusing: false
          }))
      })
    }
  }

  downloadNomina() {
    this.setState({ downloading_nomina: true }, () => {
      nominaService.download_ispettore(
        this.state.nomina.ispezione.id_progetto,
        this.state.nomina.id,
        (res) => {
          this.saveBlob(res.data, 'nomina.pdf');
          this.setState({
            downloading_nomina: false,
          })
        }, () => this.setState({
          downloading_nomina: false,
        })
      )
    })
  }

  uploadNomina(file) {
    this.setState({
      uploading_nomina: true
    }, () => {
      nominaService.confirm_feedback(this.state.nomina.ispezione.id_progetto, this.state.nomina.id, {
        filename: 'file'
      }, { file: file, chiave: 'file' }, (res) => {
        this.refreshInline(res.data.data)
        this.setState({ uploading_nomina: false });
      }, () => this.setState({ uploading_nomina: false }))
    })
  }

  downloadVerbale(idVerbale) {
    this.setState({ downloading_verbale: true }, () => {
      nominaService.download_verbale(
        this.state.nomina.ispezione.id_progetto,
        idVerbale,
        (res) => {
          this.saveBlob(res.data, 'verbale.pdf');
          this.setState({
            downloading_verbale: false,
          })
        }, () => this.setState({
          downloading_verbale: false,
        })
      )
    })
  }

  uploadVerbale(file, idVerbale) {
    if (!this.state.uploading_verbale) {
      this.setState({
        uploading_verbale: true
      }, () => {
        nominaService.upload_verbale(
          this.state.nomina.ispezione.id_progetto, idVerbale,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploading_verbale: false })
            message.success("Il verbale è stato caricato correttamente");
            this.refresh();
          },
          (err) => this.setState({ uploading_verbale: false })
        )
      })
    }
  }


  render() {
    return (
      <div style={{ opacity: this.state.loading || this.state.refreshing ? 0.5 : 1 }}>
      {
        this.state.loading || this.state.refreshing ? <Spin /> : null
      }
        <Card
          style={{
            marginBottom: "12px",
            backgroundColor: "#fff"
          }}
          bordered={false}
        >
          <h3 className="sectionTitle">
            Nomina progetto
              </h3>
          {!this.state.loaded ? <SpinnerLoading message='Caricamento dati...' /> :
            this.state.nomina && <React.Fragment>
              <p>Numero protocollo: #{this.state.nomina.numero_protocollo}</p>
              <p>Protocollo progetto formativo: #{this.state.nomina.ispezione.progetto.numero_protocollo}</p>
              <Link to={"/affidamento/progetto/" + this.state.nomina.ispezione.id_progetto}
                className="login-form-forgot" >Scheda di progetto</Link>
              <div>Stato: <strong>{nominaStatic.getStatoLabel(this.state.nomina)}</strong></div>
            </React.Fragment>
          }
          <Divider />
          {
            this.state.nomina && <div>
              <React.Fragment>
                <h4>Sede</h4>
                <p>{this.state.nomina.ispezione.progetto.scheda_progetto_json.relatedSubjects.AfcHeadquartersSection?.indirizzo?.full_address}</p>
              </React.Fragment>
              <NominaProgettoActions
                nomina={this.state.nomina}
                canEditOwnNomina={this.canEditOwnNomina()}
                feedback={(feedback, note) => this.feedback(feedback, note)}
                refusing={this.state.refusing}
                downloadNomina={() => this.downloadNomina()}
                uploadNomina={(file) => this.uploadNomina(file)}
                downloading_nomina={this.state.downloading_nomina}
                uploading_nomina={this.state.uploading_nomina}

              />
            </div>

          }
        </Card>
        {this.state.nomina && this.state.nomina.stato!==nominaStatic.stato.rifiutata && this.state.nomina.stato!==nominaStatic.stato.rifiutato_confermato && this.state.nomina.ispezione.stato >= nominaStatic.stato_ispezione.ispettori_nominati && <Card
          style={{
            marginBottom: "12px",
            backgroundColor: "#fff"
          }}
          bordered={false}>
          <IspezioneVerbale
            ispezione={this.state.nomina.ispezione}
            download={(idVerbale) => this.downloadVerbale(idVerbale)}
            upload={(file, id) => this.uploadVerbale(file, id)}
            uploading={this.state.uploading_verbale}
            downloading={this.state.downloading_verbale}
            canEditVerbale={this.canEditOwnNomina()}
          >

          </IspezioneVerbale>
        </Card>}




        {this.state.nomina && <Card
          style={{
            marginBottom: "12px",
            backgroundColor: "#fff"
          }}
          bordered={false}>
          <Row>
            <Col span={24}>
              <div id="actions" style={{ marginTop: 16 }}>
                {this.canEditOwnNomina() &&
                  <React.Fragment>
                    <Documenti
                      accreditation_id={this.state.nomina.ispezione.id_progetto}
                      canDelete={this.state.nomina.stato === nominaStatic.stato.attesa_conferma}
                      canAddNote={false}
                      onRemoving={(data)=>this.removing()}
                      onFinish={(data)=>this.finish()}
                      onUpload={(data) => this.refresh()}
                      onDelete={(data) => this.refresh()}
                      cannotAdd={this.state.nomina?.media?.find(el => el.tipo.sottodominio === nominaStatic.tipo_media.autocertificazione_ispettore)
                        || this.state.nomina.stato !== nominaStatic.stato.attesa_conferma}
                      media_types={this.props.GlobalStore.stubs.tipo_media}
                      medias={this.state.nomina.media}
                      current_domain="nomina"
                      current_subdomain="autocertificazione_ispettore"
                      required_types={[]}
                      ref_id={this.state.nomina.id}
                      root='progetto'
                    >
                      {this.state.nomina.stato === nominaStatic.stato.attesa_conferma &&
                        <Button size="small" disabled={this.state.downloading} loading={this.state.downloading} className="m5h" type="secondary" icon="file-word" onClick={() => {
                          this.setState({ downloading: true }, () => {
                            nominaService.download_autocertificazione_word(
                              this.state.nomina.ispezione.id_progetto,
                              this.state.nomina.id,
                              (res) => {
                                this.setState({ downloading: false });
                                this.saveBlobWord(res.data, "autocertificazione.odt")
                              },
                              () => this.setState({ downloading: false })
                            )
                          });
                        }}>Scarica formato Odt</Button>
                      }
                    </Documenti>
                    <Documenti
                      accreditation_id={this.state.nomina.ispezione.id_progetto}
                      canDelete={this.state.nomina.stato === nominaStatic.stato.attesa_conferma}
                      canAddNote={false}
                      onRemoving={(data)=>this.removing()}
                      onFinish={(data)=>this.finish()}
                      onUpload={(data) => this.refresh()}
                      onDelete={(data) => this.refresh()}
                      cannotAdd={this.state.nomina?.media?.find(el => el.tipo.sottodominio === nominaStatic.tipo_media.dichiarazione_disponibilita_ispettore)
                        || this.state.nomina.stato !== nominaStatic.stato.attesa_conferma}
                      media_types={this.props.GlobalStore.stubs.tipo_media}
                      medias={this.state.nomina.media}
                      current_domain="nomina"
                      current_subdomain="dichiarazione_disponibilita_ispettore"
                      required_types={[]}
                      ref_id={this.state.nomina.id}
                      root='progetto'
                    >
                      {this.state.nomina.stato === nominaStatic.stato.attesa_conferma &&
                        <Button size="small" disabled={this.state.downloading_disponibilita} loading={this.state.downloading_disponibilita} className="m5h" type="secondary" icon="file-word" onClick={() => {
                          this.setState({ downloading_disponibilita: true }, () => {
                            nominaService.download_dichiarazione_disponibilita(
                              this.state.nomina.ispezione.id_progetto,
                              this.state.nomina.id,
                              (res) => {
                                this.setState({ downloading_disponibilita: false });
                                this.saveBlobWord(res.data, "DichiarazioneDisponibilità.odt")
                              },
                              () => this.setState({ downloading_disponibilita: false })
                            )
                          });

                        }}>Scarica formato Odt</Button>
                      }
                    </Documenti>
                  </React.Fragment>
                }
              </div>
            </Col>
          </Row></Card>}



      </div>


    )
  }
}


export default NominaProgettoRoute;
