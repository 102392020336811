import BaseRequest from "@common/services/_baseRequest";



export default {

	download: (codice_template, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'utility/download-template/process?template=' + codice_template,
			cb,
			ecb || null,
			null
		);
	}
}
