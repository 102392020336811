import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import formatter from '@common/utils/formatters/_';
import eventBroker from '@common/utils/services/EventBroker';
import { Spin, message } from 'antd';
import affidamentoStatic from '@common/utils/static/affidamento';
import checkPermission from '@common/utils/check/permissions';
import Discenti from '../../../components/Discenti/Discenti';

import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';


//TODO: inserire subjectType come elemento del form visibile solo lato amministrazione regionale

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'elenco_discenti',
                type: 'title',
                element: 'h4',
                text: 'Elenco dei Discenti',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcLearnerListSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'elenco_discenti_table',
                type: 'child',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcLearnerListSection ? true : false; }
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <Discenti
                    {...component.props}
                    learnerList={component.props.domanda.learner.AfcLearnerListSection?.learnerList}
                    saveLearnerList={(list) => component.saveLearnerList(list)}
                    savingLearner={component.state.savingLearner}
                  />
                </div>
              }
            ],
          },
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: 'inail',
                type: 'title',
                element: 'h4',
                text: 'Inail',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },


      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Sede',
                type_string: 'text', // field type
                name: 'learner.AfcInailSection.headquarter',
                props: {
                  placeholder: 'Sede ',
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Matricola',
                type_string: 'text', // field type
                name: 'learner.AfcInailSection.serial',
                props: {
                  placeholder: 'Matricola N°',
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
              }
            ]
          },{
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Posizione contributiva',
                type_string: 'text', // field type
                name: 'learner.AfcInailSection.positionNumber',
                props: {

                  placeholder: 'Posizione N°',
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInailSection ? true : false; }
                  },
                ],
              }
            ]
          },
          
        ]
      },

      {
        cols: [
          {
            items: [
              {
                name: 'inps',
                type: 'title',
                element: 'h4',
                text: 'Inps',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInpsSection ? true : false; }
                  },
                ]
              }
            ],
          }
        ],
      },


      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Sede',
                type_string: 'text', // field type
                name: 'learner.AfcInpsSection.headquarter',
                props: {
                  placeholder: 'Sede ',
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInpsSection ? true : false; }
                  },
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Matricola',
                type_string: 'text', // field type
                name: 'learner.AfcInpsSection.serial',
                props: {
                  placeholder: 'Matricola N°',
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInpsSection ? true : false; }
                  },
                ],
              }
            ]
          },{
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Posizione contributiva',
                type_string: 'text', // field type
                name: 'learner.AfcInpsSection.positionNumber',
                props: {

                  placeholder: 'Posizione N°',
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.domanda.learner.AfcInpsSection ? true : false; }
                  },
                ],
              }
            ]
          },
          
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'button',
                name: 'btn_submit_soggetti_correlati',
                text: 'Salva i dati',
                onClick: 'saveData',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    //onSubmit: 'submit',
  }
};


class DiscentiForm extends Component {

  constructor(props) {
    super(props);
    this.getBackLearnerEdizione = this.getBackLearnerEdizione.bind(this);
    eventBroker.on('getBackLearnerEdizione', this.getBackLearnerEdizione);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  }

  state = {
    loading: false,
    savingLearner: false,
    tabLoaded: true,
    dati: {
      domanda: {}
    }

  }

  componentDidMount() {
    this.setState({
      tabLoaded: false
    });
    this.load(this.props)
  }

  load(props) {
    this.setState({
      tabLoaded: true,
      dati: {
        domanda: props.domanda
      }
    });
  }


  prepareData(data) {
    /*if (this.props.domanda.learner.AfcInailSection) {
      let afcInailSection = formatter.replaceFormDatesOut(['del', 'insurancePeriodsStart', 'insurancePeriodsEnd'], data.learner.AfcInailSection, 'YYYY-MM-DD');
      data.learner.AfcInailSection = afcInailSection;
    }*/

    let domanda = this.props.domanda;
    domanda = {
      ...domanda, ...data?.domanda, learner: {
        ...domanda.learner, ...data?.learner
      }
    };
    return domanda;
  }

  getBackLearnerEdizione() {
    return this.prepareData(this.formRef?.props.form.getFieldsValue());
  }

  async validate() {
    return new Promise((resolve) => {
      this.formRef.props.form.validateFields((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }


  /*async submit() {

    let valid_form = await this.validate();
    if (valid_form) {
      let data = this.formRef.props.form.getFieldsValue();
      const bkpDomanda = this.state.dati.domanda;
      if(data.length > 0){
        let domanda = this.prepareData(data);
        this.setState({
          loading: true,
          tabLoaded: false,
          dati: {
            domanda: domanda
          }
        });

        this.props.saveJson(domanda);
      }
    }


  }*/


  submit() {
    //let domanda = this.state.dati.domanda;

    let domanda = this.prepareData(this.formRef.props.form.getFieldsValue());
    this.props.saveJson(domanda);

  }

  saveLearnerList(list) {
    let domanda = this.props.domanda;
    //const bkpDomanda = domanda;
    domanda.learner.AfcLearnerListSection.learnerList = list;
    this.setState({
      dati: {
        domanda: domanda
      }
    });
  }

  getValues() {
    let dati = this.props.progetto_padre.scheda_progetto_json;
    if (dati && dati.learner && dati.learner.AfcInailSection) {
      dati.learner.AfcInailSection = formatter.replaceFormDatesIn(['insurancePeriodsEnd', 'insurancePeriodsStart', 'del'], dati.learner.AfcInailSection)
    }
    return dati
  }

  render() {
    return <div style={{ opacity: !this.props.saved ? 0.5 : 1 }}>
      {
        !this.props.saved ? <Spin /> : null
      }
      {checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'learner')}
          feedback={this.props.section_feedback ? this.props.section_feedback['learner'] : null}
        />
      }
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.props.saving || false}
        readOnly={this.props.readOnly}
        form_model={formLayout(this)}
        saveData={() => this.submit()}
        //submit={(data) => this.submit(data)}
        values={this.getValues()}

        min_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let min = value;
          let max = this.formRef.props.form.getFieldsValue().domanda.learner.AfcInailSection.insurancePeriodsEnd;

          if (max && min > max) cb("Il campo dal deve essere minore di al");
          else cb()
        }}
        max_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let max = value;
          let min = this.formRef.props.form.getFieldsValue().domanda.learner.AfcInailSection.insurancePeriodsStart;

          if (min && min > max) cb("Il campo dal deve essere minore di al");
          else cb()
        }}
      />
    </div>
  }
}
export default DiscentiForm;