import React, { Component } from 'react';
import ModelServices from '../../services/QualificaRepertorioNazionale';

import CommonForm from '@common/components/form';
import SpinnerLoading from '@common/components/SpinnerLoading';
import { message } from 'antd';

const model = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Denominazione',
              type_string: 'text', // field type
              name: 'descrizione',
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'btn',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
}



class UpdateModel extends Component {
  base_url = '/qualifiche_repertorio_nazionale'

  state = {
    loading: false,
    open_drawer: false,
    start_load: true,
    model: {}
  }

  componentDidMount() {
    this.init(this.props)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id && this.props.id)
      this.init(this.props)
  }

  init(props) {
    this.setState({ loading: true, start_load: true })
    ModelServices.view({}, props.id, (res) => {
      this.setState({ loading: false, start_load: false, model: res.data.data })
    }, () => {
      this.setState({ loading: false, start_load: false })
    })
  }

  submit(data) {
    if (!this.state.loading) {
      this.setState({ loading: true });
      ModelServices.update(data, this.props.id, () => {
        message.success("Operazione effettuata con successo");
        this.props.updateRecord();
        this.setState({ open_drawer: false, loading: false });
      }, () => {
        this.setState({ loading: false })
      })
    }
  }

  initDataForm() {
    let valori_form = {
      ...this.state.model
    };
    //valori_form.valido_dal = valori_form.valido_dal !== null?moment(valori_form.valido_dal, "YYYY-MM-DD"):null;
    return valori_form;
  }

  render() {
    return (!this.state.start_load ?
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.state.loading || false}
        form_model={model}
        values={
          this.initDataForm()
        }

        submitted={(data) => this.submit(data)}
      /> : <SpinnerLoading />
    );
  }
}
export default UpdateModel;