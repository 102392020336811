import React, { Component } from "react";
import CommonForm from "@common/components/form";
import checkPermission from "@common/utils/check/permissions";
import formatter from "@common/utils/formatters/_";
import SpinnerLoading from "@common/components/SpinnerLoading";
import CommissioneService from "../../services/CommissioneEsami";
import eventBroker from "@common/utils/services/EventBroker";
import affidamentoStatic from "@common/utils/static/affidamento";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";
import { Spin } from "antd";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "select",
                label: "Tipologia",
                name: "testType",
                options_props: "_tipo_test",
                props:{
                  //disabled: true
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: "select",
                label: "Caratteristica",
                name: "testFeature",
                options_props: "_caratteristiche_test",
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "datepicker",
                label: "Data conseguimento esame",
                format: "DD/MM/YYYY",
                name: "date",
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label: "Competenza/e oggetto della valutazione",
                name: "testSkills",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label: "Prestazione attesa",
                name: "testPerformance",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label: "Criteri di accettabilità della prova",
                name: "testCriteria",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label: "Modalità di svolgimento delle prove",
                name: "testProcedure",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label: "Tempi e durata di somministrazione delle prove",
                name: "testDuration",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label:
                  "Strumenti per la realizzazione della prova di verifica finale (attrezzature e/o materiali, spazi, ecc.)",
                name: "finalTestTools",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label:
                  "Strumenti per la valutazione delle prestazioni (griglie di analisi, schede di osservazioni, etc.)",
                name: "valutationTools",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "textarea",
                label:
                  "Livello QNQ/EQF",
                name: "levelEQF",
                type_string: "text",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: "btn",
                type: "submit",
                text: "Salva",
                btn_type: "primary",
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submit",
  };
};

export default class ProvaFinaleEsameForm extends Component {
  constructor(props) {
    super(props);
    this.getBackReferences = this.getBackReferences.bind(this);
    eventBroker.on("getBackReferences", this.getBackReferences);
  }

  state = {
    loaded: false,
    loading: false,
    generalData: {},
    id_ente: this.props.id_ente || null,
    send: false,
  };

  componentDidMount() {
    this.load(this.props);
    this.setState({ loaded: true });
  }

  load(props) { 
    this.setState({ loading: true });
    this.setState({
      generalData: props.scheda_progetto_json,
    });
    this.setState({ loading: false });
 
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, [
      "entePermissions",
    ]);
  }

  getValues() {
    let data = this.state.generalData.exam;
    data = formatter.replaceFormDatesIn(['date'], data)

    return data;
  }

  getBackReferences() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  submit(data) {

    let dati = {
      ...data,
      scheda: "exam",
    };
    this.setState({ send: true });

    this.props.SaveData({exam:dati});
  }

  render() {
    return (
      <div
        style={{ opacity: !this.props.saved || this.state.loading ? 0.5 : 1 }}
      >
        {!this.props.saved ? <Spin /> : null}
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "viewValutazioneScheda",
        ]) && (
          <ValidazioneScheda
            canGiveFeedback={
              this.props.canGiveSectionFeedback &&
              this.props.stato_flusso ===
                affidamentoStatic.stato_flusso_progetto.audit_iniziato
            }
            refresh={(reset) => this.props.refresh(reset)}
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) =>
              this.props.saveFeedback(note, valid, "exam")
            }
            feedback={
              this.props.section_feedback
                ? this.props.section_feedback["exam"]
                : null
            }
          />
        )}

        {!this.state.progettiList == null ? (
          <SpinnerLoading message="Caricamento dati..." />
        ) : (
          <CommonForm
            wrappedComponentRef={(inst) => (this.formRef = inst)}
            loading={this.props.saving || this.state.loading || false}
            form_model={formLayout(this)}
            values={this.getValues()}
            readOnly={ this.props.readOnly
            }
            submit={(data) => this.submit(data)}
            _tipo_test={this.props.GlobalStore.stubs?.tipo_prove_verifica?.filter(item => item.label === 'Finale')}
            _caratteristiche_test={
              this.props.GlobalStore.stubs.caratteristiche_prove_verifica
            }
          />
        )}
      </div>
    );
  }
}
