import React, { Component } from 'react';
import { Button, Card, Popconfirm, message } from 'antd';
import FileSaver from 'file-saver';
import { connect } from "react-redux";
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import domandaService from '../services/Domanda';
import AttivaProgetto from './Actions/AttivaProgetto';


class ActionsAffidamento extends Component {

  state = {
    loaded: false,
    loading_winner:false,
    loading_loser:false,
    sending: false,
    domanda: {
      id: null
    },
    open_modal_doc: false
  }

  winner(){
    this.setState({
      loading_winner:true,
    },()=>{
      domandaService.winner(this.props.match.params.id,
        (res) => {
          message.success("Il formulario è stato accettato correttamente");
         // this.props.refresh(false);
          this.props.refreshInline(res.data.data)
          this.setState({
            loading_winner:false,
          });
        },
        err => this.setState({
          loading_winner:false,
        })
      );
    });

  }

  loser(){
    this.setState({
      loading_loser: true
    },()=>{
      domandaService.loser(this.props.match.params.id,
        (res) => {
          message.success("Il formulario è stato rigettato correttamente");
          //this.props.refresh(false);
          this.props.refreshInline(res.data.data)
          this.setState({
            loading_loser: false
          });
        },
        err => this.setState({
          loading_loser: false
        })
      )
    });
  }

  send(){
    this.setState({
      sending: true
    },()=>{
      domandaService.send(this.props.match.params.id,
        (res) => {
          this.setState({
            sending: false
          });
          message.success("Il formulario compilato è stata confermato correttamente");
          //this.props.refresh(false);
          this.props.refreshInline(res.data.data)
        },
        err => this.setState({
          sending: false
        })
      )
    });
  }


  componentDidMount() {
    if (this.props.domanda) this.loaded(this.props);
  }

  loaded(props) {
    this.setState({ domanda: props.domanda, loaded: true });
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "affidamento");

    //this.props.refresh();
  }

  renderValutaActions() {
    return this.props.domanda?.stato === affidamentoStatic.stato.inviata && (this.props.domanda.procedura?.stato === 2 || this.props.domanda.procedura?.fino_ad_esaurimento) && <div>
      <Popconfirm placement="top" title="Si conferma la registrazione dell'esito?"
        onConfirm={() => {
          this.winner()
        }}
        okText="Si" cancelText="No">
        <Button
          loading={this.state.loading_winner} disabled={this.state.loading_loser}
          className="m5h bg-green" type="primary" icon="check"
          style={{ marginRight: 8 }}>Vincitore</Button>
      </Popconfirm>

      <Popconfirm placement="top" title="Si conferma la registrazione dell'esito?"
        onConfirm={() => {
          this.loser()
        }}
        okText="Si" cancelText="No">
        <Button
          loading={this.state.loading_loser}
          disabled={this.state.loading_winner}
          className="m5h" type="danger" ghost icon="close">Non vincitore</Button>
      </Popconfirm>
    </div>
  }

  renderAttivaActions() {
    return this.props.domanda?.stato === affidamentoStatic.stato.vincitore ? <div>
      <AttivaProgetto
        {...this.props}
      >
      </AttivaProgetto>
    </div> : null
  }

  renderPresentaActions(){
    return (this.props.domanda.stato === affidamentoStatic.stato.compilata) ? <div>
      <Popconfirm placement="top" title="Sei sicuro di voler confermare?"
        disabled={this.props.domanda.procedura?.stato === affidamentoStatic.stato_procedura.chiuso}
        onConfirm={() => {
          this.send()
        }}
        okText="Si" cancelText="No">
        <Button
          disabled={this.props.domanda.procedura?.stato === affidamentoStatic.stato_procedura.chiuso}
          loading={this.state.sending}
          className="m5h" type="primary" icon="check"
          style={{ marginRight: 8 }}>Conferma il formulario compilato</Button>
      </Popconfirm>
    </div> : null
  }



  render() {


    return this.state.loaded  ?
      <div>
        <Card
          className="no-pad"
          bordered={false}>
        {/*TODO implementare le azioni dopo che è stato deciso il workflow*/}
          {checkPermission.hasPermission(this.props.UserStore.user, ['presentaDomanda']) ? this.renderPresentaActions() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['valutaDomanda']) ? this.renderValutaActions() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['attivaProgetto']) ? this.renderAttivaActions() : null}
        </Card>
  </div>
      : null
  }
}


export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ActionsAffidamento);