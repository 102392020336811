import React, { Component } from 'react';

import TableComponent from '@components/table';
import affidamentoStatic from '@common/utils/static/affidamento';
import moment from 'moment';
import ModelServices from '@affidamento/services/Corsi';

import _ from '@common/utils/formatters/_';

const model = (component) => {
  let {renderActions} = component.props;
  if (!renderActions) renderActions = [];

  let {extraActions} = component.props;
  if (!extraActions) extraActions = [];

  return {
    with_server_pagination: true,
    columns: [
      ...renderActions,
      {
        title: 'Cognome',
        dataIndex: '',
        sorter: true,
        key: 'anagrafica.cognome',
        render: element => <div>
          {element.anagrafica?.cognome || ''}
        </div>
      },
      {
        title: 'Nome',
        dataIndex: '',
        sorter: true,
        key: 'anagrafica.nome',
        render: element => <div>
          {element.anagrafica?.nome || ''}
        </div>
      },
      {
        title: 'Data Iscrizione',
        dataIndex: '',
        sorter: true,
        key: 'data',
        render: element => <div>
          {element.data? moment(element.data, 'DD-MM-YYYY').format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        title: 'Monte Ore',
        dataIndex: '',
        sorter: true,
        key: 'monte_ore',
        render: element => <div>
          {element.monte_ore && <span>{`${element.monte_ore.ore < 10 ? '0'+element.monte_ore.ore : element.monte_ore.ore}:${element.monte_ore.minuti < 10 ? '0'+element.monte_ore.minuti : element.monte_ore.minuti}`}</span>}
        </div>
      },
      {
        title: 'Stato',
        dataIndex: '',
        sorter: true,
        key: 'stato',
        render: element => <div>
          {affidamentoStatic.getStatoIscrizioneLabel(element.stato || 0) }
        </div>
      },
      ...extraActions
    ]
  };
}

class TabellaDiscenti extends Component {

  default_order = 'id'

  state = {
    loading: false,
    params: {},
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.fetch();
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(this.state.params, this.default_order);

    if (this.props.filterStatus)
      params.stato = this.props.filterStatus;

    params.fields = ['anagrafica', 'created_at', 'data', 'id', 'id_progetto', 'giornate_assenze', 'stato', 'stato_flusso', 'updated_at', ...(this.props.fields || [])].join(',')
    if (this.props.fields) {
      params.expand = this.props.fields.join(',');
    }
    params.expand = ['monte_ore'].join(',');

    this.setState({ loading: true });
    ModelServices.listDiscenti(params, this.props.corso.id, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  updateQueryStr(obj) {
    if (obj.order && obj.sort) {
      obj = {
        sort: (obj.order === 'descend' ? '-' : '') + obj.sort
      }
    }
    this.setState({
      params: {
        ...this.state.params,
        ...obj
      }
    }, () => {
      this.fetch();
    });
  }

  render() {
    return (

      <TableComponent
        data={this.state.data}
        model={model(this)}
        table_props={{ rowKey: 'id' }}
        size="medium"
        ref="table_component"
        loading={this.state.loading}
        with_server_pagination={true}
        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
        updateSort={(sort, order) => this.updateQueryStr({ sort: sort, order: order })}
      />
    );
  }
}

export default TabellaDiscenti;
