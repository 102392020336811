import React, { Component } from 'react';
import { Table, Tag, Card } from 'antd';
import moment from 'moment';
import ModelServices from '@accreditamento/services/Accreditamento';
import ModelServicesProgetto from  '@affidamento/services/Progetto'

import { ArrowRightOutlined } from '@ant-design/icons';
import XMLViewer from 'react-xml-viewer'

const replaceText = (str) => {
  if (str.match(/Pratica inviata/)) return "Esito invio";
  if (str.match(/Pratica acquisita Dirigente/)) return "Nuova pratica";
  if (str.match(/Pratica acquisita/)) return "Esito acquisizione";
  if (str.match(/Richiesta integrazioni/)) return "Richiesta integrazioni";
  if (str.match(/Pratica assegnata/)) return "Assegnazione";
  if (str.match(/Feedback ispettore/)) return "Risposta ispettore";
  return str;
}

const cols = (component) => {
  return [
    {
      title: "Data Invio",
      dataIndex: 'created_at',
      key: 'created_at',
      render: element => <span>
        {
          moment.unix(element).format('DD/MM/YYYY')
        }
      </span>
    },
    {
      title: "Mittente",
      dataIndex: 'from',
      sorter: false,

    },
    {
      title: "Destinatario",
      dataIndex: 'to',
      sorter: false,

    }
    ,
    {
      title: "Oggetto",
      dataIndex: 'subject',
      sorter: false,
      render: element => <span>
        {
          replaceText(element)
        }
      </span>
    }
  ];
}

const expandedRowRender = (record, index, indent, expanded, component) => {

  const child_columns = (component, childName) => {
    switch(childName){
      case 'FLOW':
        return [
          {
            //title: 'Flusso',
            dataIndex: 'content',
            render: (text, record) => {
              let tags = [];
              let colors = {
                primary: 'geekblue',
                success: 'green',
                danger: 'volcano'
              };
              if(text.inviato && text.inviato === true){
                tags.push(
                  <Tag color={colors.primary} key={'inviata'}>
                    {'Inviata'}
                  </Tag>
                );
              }
              if(text.accettazione && text.accettazione === true){
                tags.push(<ArrowRightOutlined style={{ paddingRight: '10px' }} />);
                tags.push(
                  <Tag color={colors.primary} key={'accettata'}>
                    {'Accettata'}
                  </Tag>
                );
              }
              if(text.consegna && text.consegna === true){
                tags.push(<ArrowRightOutlined style={{ paddingRight: '10px' }} />);
                tags.push(
                  <Tag color={colors.success} key={'consegnata'}>
                    {'Consegnata'}
                  </Tag>
                );
              }
              if(text.mancata_consegna && text.mancata_consegna === true){
                tags.push(<ArrowRightOutlined style={{ paddingRight: '10px' }} />);
                tags.push(
                  <Tag color={colors.danger} key={'mancata consegnata'}>
                    {'Mancata Consegna'}
                  </Tag>
                );
              }
              return <span>
              {
                tags.map(tag => tag)
              }
              </span>
            },
          }
        ]
      case 'MESSAGE':
        return [
          {
            //title: 'Testo',
            dataIndex: 'content',
            render: (text, record) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
          }
        ]
      case 'XML':
        return [
          {
            dataIndex: 'content',
            render: (text, record) => <XMLViewer xml={text} />,
          }
        ];
    }
    
  }

  const getBodyRicevuta = (indexStr, record) => {
    const receive_pec_message = JSON.parse(record.receive_pec_message);
    const journals = receive_pec_message?.journals || [];
    let journal = null;
    let ricevuta = null;
    journals.map(j => {
      if(Array.isArray(indexStr)) {
        if(indexStr.includes(j.action)){
          journal = j;
        }
      } else {
        if(j.action === indexStr){
          journal = j;
        }
      }
    });
    if(journal !== null && journal.body !== undefined){
      let buff = new Buffer(journal.body, 'base64');
      ricevuta = buff.toString('ascii');
    }
    return ricevuta;
  }

  const getDataRicevuta = (indexStr, record) => {
    const receive_pec_message = JSON.parse(record.receive_pec_message);
    const journals = receive_pec_message?.journals || [];
    let journal = null;
    let ricevuta = null;
    journals.map(j => {
      if(Array.isArray(indexStr)) {
        if(indexStr.includes(j.action)){
          journal = j;
        }
      } else {
        if(j.action === indexStr){
          journal = j;
        }
      }
    });
    console.warn(indexStr, journals, journal)
    if(journal !== null){
      ricevuta = moment(journal.date).format('DD/MM/YYYY hh:mm:ss')
    }
    return ricevuta;
  }

  const getDatiCertRicevuta = (indexStr, record) => {
    const receive_pec_message = JSON.parse(record.receive_pec_message);
    const journals = receive_pec_message?.journals || [];
    let journal = null;
    let ricevuta = null;
    journals.map(j => {
      if(Array.isArray(indexStr)) {
        if(indexStr.includes(j.action)){
          journal = j;
        }
      } else {
        if(j.action === indexStr){
          journal = j;
        }
      }
    });
    if(journal !== null && journal.datiCert !== undefined){
      let buff = new Buffer(journal.datiCert, 'base64');
      ricevuta = buff.toString('ascii');
    }
    return ricevuta;
  }

  const getDetailsRicevuta = (indexStr, record) => {
    const receive_pec_message = JSON.parse(record.receive_pec_message);
    const journals = receive_pec_message?.journals || [];
    let journal = null;
    let ricevuta = null;
    journals.map(j => {
      if(Array.isArray(indexStr)) {
        if(indexStr.includes(j.action)){
          journal = j;
        }
      } else {
        if(j.action === indexStr){
          journal = j;
        }
      }
    });
    if(journal !== null){
      ricevuta = journal.details;
    }
    return ricevuta;
  }

  const contentList = (activeTab,component,record) => {
    let indexStr = '';
    let ricevuta = null;
    let timestamp = null;
    let datiCert = null;
    let details = null;

    switch(activeTab){
      case 'invio':
        const message = JSON.parse(record.message);
        ricevuta = message.content;
        timestamp = moment.unix(record.created_at).format('DD/MM/YYYY hh:mm:ss');
        return <Table
        showHeader={false}
        columns={child_columns(component, 'MESSAGE')}
        dataSource={[ { content: `Data: ${timestamp}` }, { content: ricevuta } ]}
        rowKey={(r) => "record_" + Math.random()}
        pagination={false} />
      case 'accettazione':
        indexStr = 'Message:PEC:Acceptance';
        ricevuta = getBodyRicevuta(indexStr, record);
        timestamp = getDataRicevuta(indexStr, record);
        datiCert = getDatiCertRicevuta(indexStr, record);
        return <>
        <Table
          showHeader={false}
          columns={child_columns(component, 'MESSAGE')}
          dataSource={[ {content: `Data: ${timestamp}`}, { content: ricevuta } ]}
          rowKey={(r) => "record_" + Math.random()}
          pagination={false} />
        <Table
          showHeader={false}
          columns={child_columns(component, 'XML')}
          dataSource={[ { content: datiCert } ]}
          rowKey={(r) => "record_" + Math.random()}
          pagination={false} />
        </>
      case 'consegna':
        indexStr = 'Message:PEC:Delivery';
        ricevuta = getBodyRicevuta(indexStr, record);
        timestamp = getDataRicevuta(indexStr, record);
        datiCert = getDatiCertRicevuta(indexStr, record);
        return <>
        <Table
          showHeader={false}
          columns={child_columns(component, 'MESSAGE')}
          dataSource={[ {content: `Data: ${timestamp}`}, { content: ricevuta } ]}
          rowKey={(r) => "record_" + Math.random()}
          pagination={false} />
        <Table
          showHeader={false}
          columns={child_columns(component, 'XML')}
          dataSource={[ { content: datiCert } ]}
          rowKey={(r) => "record_" + Math.random()}
          pagination={false} />
        </>
      case 'mancata_consegna':
        indexStr = [
          'Message:PEC:NoticeOfNonAcceptance',
          'Message:PEC:NoticeOfNonDeliveryTempMax',
          'Message:PEC:MessageAnomaly',
          'Message:PEC:NoticeOfNonAcceptanceForVirus',
          'Message:PEC:SecurityIssue',
          'Message:PEC:NoticeOfNonDeliveryForVirus',
          'Message:PEC:NoticeOfNonDelivery'];
        timestamp = getDataRicevuta(indexStr, record);
        details = getDetailsRicevuta(indexStr, record);
        return <>
        <Table
          showHeader={false}
          columns={child_columns(component, 'MESSAGE')}
          dataSource={[ {content: `Data: ${timestamp}`}, { content: details } ]}
          rowKey={(r) => "record_" + Math.random()}
          pagination={false} />
        </>
      default: return null;
    }
  };

  return <>
  <Card>
    <Card.Grid style={{width: '100%', padding: '0'}}>
      <Table
      showHeader={false}
      columns={child_columns(component, 'FLOW')}
      dataSource={[ { content: { 'inviato': true, 'accettazione': record.accettazione, 'consegna': record.consegna, 'mancata_consegna': record.mancata_consegna } } ]}
      rowKey={(r) => "record_" + r.id}
      pagination={false} />
    </Card.Grid>
    <Card.Grid style={{width: '100%', padding: '0'}} >
      <Card
      tabList={[
        {
          tab: 'Comunicazione inviata',
          key: 'invio',
        },
        record.accettazione === true ? {
          tab: 'Ricevuta di accettazione',
          key: 'accettazione',
        } : {},
        record.consegna === true ? {
          tab: 'Ricevuta di consegna',
          key: 'consegna',
        } : {},
        record.mancata_consegna === true ? {
          tab: 'Ricevuta di mancata consegna',
          key: 'mancata_consegna',
        } : {},
      ]}
      activeTabKey={component.state.activeTabKey1 || 'invio'}
      onTabChange={key => {
        component.setState({activeTabKey1: key})
      }}>
        {contentList(component.state.activeTabKey1 || 'invio',component,record)}
      </Card>
    </Card.Grid>
  </Card>
  </>
}

class ComunicazioniPecList extends Component {
  state = {
    data: [],
    loading: false,
  }
  model = [
    {
      title: "Data Invio",
      dataIndex: 'created_at',
      key: 'created_at',
      render: element => <span>
        {
          moment.unix(element).format('DD/MM/YYYY')
        }
      </span>
    },
    {
      title: "Mittente",
      dataIndex: 'from',
      sorter: false,

    },
    {
      title: "Destinatario",
      dataIndex: 'to',
      sorter: false,
    },
    {
      title: "Oggetto",
      dataIndex: 'subject',
      sorter: false,
      render: element => <span>
        {
          replaceText(element)
        }
      </span>
    }
  ];

  componentDidMount() {
    switch (this.props.filter_by) {
      case 'accreditamento': 
      this.callPecAccreditamento();
        break;
      case 'progetto': 
      this.callPecProgetto();
        break;

  }
}

  callPecAccreditamento() {
    this.setState({ loading: true }, () =>
    ModelServices.getPecLog(this.props.id_accreditamento, (res) => {
      this.setState({
        data: res.data.data,
        loading: false
      });
    }, () => this.setState({ loading: true }))
  );
  }

  callPecProgetto() {
    this.setState({ loading: true }, () =>
    ModelServicesProgetto.getPecLogProgetto(this.props.id_progetto, (res) => {
      this.setState({
        data: res.data.data,
        loading: false
      });
    }, () => this.setState({ loading: true }))
  );
  }

  render() {
    return <div>
      <Table
        rowKey={(record) => "pec_log_" + record.id}
        expandedRowRender={(record, index, indent, expanded) => expandedRowRender(record, index, indent, expanded, this)}
        columns={cols(this)}
        dataSource={this.state.data}
        loading={this.state.loading}
        />
    </div>
  }

}
export default ComunicazioniPecList;

