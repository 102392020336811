import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Icon, Popconfirm, Upload } from 'antd';
import ModelServices from '@affidamento/services/Progetto';
import FileSaver from 'file-saver';

class Esito extends Component {

  state = {
  }

  saveBlobWord(blob, nameFile) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, nameFile);
  }

  renderDecreto() {
    return <div>
      <p>{this.props.labelDecreto? this.props.labelDecreto : 'Carica il decreto autorizzativo all\'erogazione del corso firmato digitalmente'}</p>

      <Button disabled={this.state.loading} loading={this.state.downloading} className="m5h" type="primary" icon="file-word" onClick={() => {
        if(!this.state.loading) {
          this.setState({
            loading: true,
            downloading: true
          }, () => {
            ModelServices.download_autorizzazione_edizione_word(
              this.props.progetto.id,
              this.props.progetto.id_progetto,
              (res) => {
                this.saveBlobWord(res.data, 'autorizzazione.odt')
                this.setState({
                  loading: false,
                  downloading: false
                })
              },
              () => this.setState({
                  loading: false,
                  downloading: false
                })
            )
          })
        }
      }}>Scarica</Button>
      <Upload
        accept={'.p7m,.pdf'}
        multiple={false}
        fileList={null}
        customRequest={obj => {
          this.props.uploadDecreto(obj.file);
        }}
        {...this.props} >
        <Button className="m5w"
          loading={this.props.uploading_decreto || false} >
          <Icon type="upload" />{"Carica"}
        </Button>
      </Upload>
      { /*
      <DownloadUpload
        accept={'.p7m,.pdf'}
        downloading={this.props.downloading_decreto}
        uploading={this.props.uploading_decreto}
        download={() => this.props.downloadDecreto()}
        upload={(file) => this.props.uploadDecreto(file)}
      >
      </DownloadUpload> */
      }
    </div>
  }


  renderActions() {
    return <div>
      {
        this.props.canEvaluate &&
        <React.Fragment>
          <Popconfirm placement="top" title="Confermi i dati inseriti?"
            onConfirm={() => {
              this.props.prepareApprove()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.props.approving}
              disabled={this.props.approving || this.props.rejecting}
              className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Conferma lavorazione</Button>
          </Popconfirm>
          <Popconfirm placement="top" title="Vuoi rigettare la pratica?"
            onConfirm={() => {
              this.props.prepareReject()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.props.rejecting}
              disabled={this.props.approving || this.props.rejecting}
              className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Rigetta</Button>
          </Popconfirm>
        </React.Fragment>
      }
      <React.Fragment>
        {
          this.props.canConfirmApprove &&
          <Popconfirm placement="top" title="Sei sicuro?"
            onConfirm={() => {
              this.props.confirmApprove()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.props.confirming_approve}
              disabled={this.props.confirming_approve}
              className="m5h" type="primary" icon="save" style={{ marginRight: 8 }}>Conferma edizione</Button>
          </Popconfirm>
        }
        {
          this.props.canConfirmReject &&
          <Popconfirm placement="top" title="Confermi il rigetto?"
            onConfirm={() => {
              this.props.confirmReject()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.props.confirming_reject}
              disabled={this.props.confirming_reject}
              className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Conferma il rigetto</Button>
          </Popconfirm>
        }

        {
          this.props.canSuspend &&
          <Popconfirm placement="top" title="Confermi la sospensione?"
            onConfirm={() => {
              this.props.suspend()
            }}
            okText="Si" cancelText="No">
            <Button
              loading={this.props.suspending}
              disabled={this.props.suspending}
              className="m5h" type="danger" ghost icon="close" style={{ marginRight: 8 }}>Sospendi</Button>
          </Popconfirm>
        }
      </React.Fragment>
    </div>
  }


  render() {
    return <div style={{ marginTop: 16 }}>
      {this.props.canUploadDecreto &&
        this.renderDecreto()
      }
      {this.renderActions()}
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(Esito);

