import React from 'react';
import {
  Layout,
  Menu,
  Icon,
} from 'antd';

import checkPermissions from '../utils/check/permissions';

import '../less/Sidebar.less';
import { Link, matchPath  } from "react-router-dom";
import { ROUTES } from '../../AppRoutes';

const { Sider } = Layout;

export default class Sidebar extends React.Component {

  state = {
    openKeys: [],
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'OPEN_MENU'
    })
    this.rootSubmenuKeys = ROUTES.filter(route => route.title && route.icon).map(route => route.path);
  }


  logout() {

    localStorage.removeItem('token');
    this
      .props
      .dispatch({ type: 'LOGOUT' });

    if(localStorage.getItem('provider') === null){
      this.props.history.push('/login');
    } else {
      const apiUrl = this.props.GlobalStore.API_URL;
      const baseUrl = this.props.GlobalStore.BASE_URL;
      const returnTo = encodeURIComponent(`${baseUrl}#`);
      console.warn("returnTo", returnTo)
      window.location.href = `${apiUrl}auth/saml/slo?returnTo=${returnTo}`;
      
      return null;
    }

    /*AuthServices.logout(()=>{
      localStorage.removeItem('token');
      this
        .props
        .dispatch({ type: 'LOGOUT' });

      this.props.history.push('/login');
    })*/


  }

  renderAccountMenu() {
    let name = this.props.UserStore.user?.anagrafica ? this.props.UserStore.user?.anagrafica?.nome + ' ' + this.props.UserStore.user?.anagrafica?.cognome : this.props.UserStore.user.username
    return <span>
      {/*<Avatar>{this.props.UserStore.user?.anagrafica?.nome?.charAt(0)}{this.props.UserStore.user?.anagrafica?.cognome?.charAt(0)}</Avatar>*/}
      <Icon type="user" />
      <span style={{ display: "inline-table", lineHeight: "14px" }}>
        <strong>{name}</strong>
        <br />
        {Array.isArray(this.props.UserStore.user?.role) && <small>({this.props.UserStore.user.role.map(r => this.humanize(r.name)).join(', ')})</small>}
      </span>
    </span>
  }

  humanize(data) {
    if(data === 'DirettoreAmministrativo') return 'Dirigente' 
    else if(data === 'FunzionarioCoordinatoreAmministrativo') return 'Funzionario Coordinatore'
    else if(data === 'DirigenteAmministrativo') return 'Resposanbile RdP' 
    else return data
  }

  renderMyEntiMenuTitle() {
    return <span>
      <Icon type="desktop" />
      <span style={{ display: "inline-table", lineHeight: "14px" }}>
        <strong>{this.props.UserStore.user?.ente?.denominazione}</strong>
        <br />
        <small>(Ente operativo)</small>
      </span>
    </span>
  }

  render() {
    let logged = this.props.UserStore.loggedIn && localStorage.getItem('token') ? true : false;
    let match = ROUTES.find(route => matchPath(this.props.location.pathname, route) !== null) || {};
    return <Sider
      className="sidebar"
      //width={200}
      breakpoint="lg"
      collapsedWidth="0"
      onCollapse={(collapsed) => {
        setTimeout( () => {
          this.props.dispatch({ type: 'OPEN_SIDEBAR', val: !collapsed });
        }, 500);
      }}
      theme="light"
      collapsible
      //collapsed={!this.props.GlobalStore.open_menu}
    >

      <Menu
        theme="light"
        mode="inline"
        selectable={true}
        selectedKeys={[match.parent, match.path]}
        defaultSelectedKeys={['/dashboard']}
        openKeys={[...this.state.openKeys, match.parent]}
        onOpenChange={openKeys => {
          const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
          if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
          } else {
            this.setState({
              openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
          }
        }}
      >
        {
          logged ?
            <Menu.SubMenu key="/user" className={"ant-menu-submenu-title-no-ellipsis"} title={this.renderAccountMenu()}>
              <Menu.Item key="/account">
                <span>Account</span>
                <Link to={'/account'} />
              </Menu.Item>
              <Menu.Item key="setting:logout" onClick={() => this.logout()}>
                <span className="nav-text">Esci</span>
              </Menu.Item>
            </Menu.SubMenu>
            :
            <Menu.Item key="/login">
              <Icon type="user" /> Login
              <Link to="/login" />
            </Menu.Item>
        }
        <Menu.Divider />

        {
          logged && this.props.UserStore.user.permissions.entePermissions ?
            <Menu.SubMenu key="/user/accreditamento/myenti" className={"ant-menu-submenu-title-no-ellipsis"} title={this.renderMyEntiMenuTitle()}>
                <Menu.Item key="/accreditamento/myenti">
                  <span>I miei enti</span>
                  <Link to={'/accreditamento/myenti'} />
                </Menu.Item>
              </Menu.SubMenu>
          : null
        }
        {
          logged && this.props.UserStore.user.permissions.entePermissions ?
            <Menu.Divider />
            :
            null
        }

        {
          ROUTES.filter(route => route.title && route.icon).map((route) => (
            (!route.permission || checkPermissions.hasPermission(this.props.UserStore.user, route.permission)) &&
              <Menu.Item key={route.path}>
                {route.icon && <Icon type={route.icon} />}
                <span>{ typeof route.title === "function" ? route.title(this.props) : route.title }</span>
                <Link to={route.path}/>
              </Menu.Item>
          ))
        }

      </Menu>

    </Sider>;
  }

}


