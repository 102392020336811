import React, { Component } from "react";
import { Button, message, Spin } from "antd";
import CommonForm from "@common/components/form";
import eventBroker from "@common/utils/services/EventBroker";
import affidamentoStatic from "@common/utils/static/affidamento";
import checkPermission from "@common/utils/check/permissions";
import Documenti from "@common/components/Documenti";
import allegatiStatic from "@common/utils/static/allegati";
import PropTypes from "prop-types";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";
import CommissioneService from "../../services/CommissioneEsami";
import FileSaver from "file-saver";

import Piattaforma from "@assets/avvio_piattaforma.pdf";

export default class Allegati extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  };

  state = {
    loading: true,
    loaded: false,
    downloading: false,
    medias: this.props.domanda_json.attachments.mainSection ? this.props.domanda_json.attachments.mainSection : [],
  };

  componentDidMount() {
    this.load(this.props);
  }

  load(props) {
    this.setState({
      loaded: true,
      loading: false,
    });
  }

  saveBlobWord(blob, nome_file) {
    const _blob = new Blob([blob], {
      type: "application/vnd.oasis.opendocument.text",
    });
    FileSaver.saveAs(_blob, nome_file);

    this.setState({
      loading: false,
      downloading: false,
    });
  }

  submit() {
    let dati = {
      generalData: this.props.domanda_json.generalData,
      references: this.props.domanda_json.references,
      attachments:{mainSection: this.state.medias },
      idEnte: this.props.domanda_json.idEnte,
      scheda: "allegati"
    }
    this.props.SaveData(dati)
  }

  render() {
    return (
      <div
        style={{ opacity: !this.props.readOnly && !this.props.saved ? 0.5 : 1 }}
      >
        {!this.props.readOnly && !this.props.saved ? <Spin /> : null}
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "viewValutazioneScheda",
        ]) && (
          <ValidazioneScheda
            canGiveFeedback={
              this.props.canGiveSectionFeedback &&
              this.props.stato_flusso ===
                affidamentoStatic.stato_flusso_progetto.audit_iniziato
            }
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) =>
              this.props.saveFeedback(note, valid, "attachments")
            }
            feedback={
              this.props.section_feedback
                ? this.props.section_feedback["attachments"]
                : null
            }
          />
        )}

        <div>
          <h3
            style={{
              color: "#0066CC",
              textTransform: "uppercase",
              fontWeight: "600",
            }}
          >
            Scarica i template
          </h3>

          <Button
            loading={this.state.downloading}
            size="small"
            disabled={this.state.loading}
            className="m5h m5w"
            type="secondary"
            icon="file"
            onClick={() => {
              if (!this.state.loading) {
                this.setState(
                  {
                    loading: true,
                    downloading: true,
                  },
                  () => {
                    CommissioneService.download_word_1(
                      this.props.id_commissione,
                      (res) => this.saveBlobWord(res.data, 'monitoraggio_finale_presenze_assenze.odt'),
                      () =>
                        this.setState({ loading: false, downloading: false })
                    );
                  }
                );
              }
            }}
          >
            Scarica Modello monitoraggio finale presenze/assenze allievi{" "}
          </Button>
          {/* https://cb.schema31.it/cb/issue/424367
          <Button
            loading={this.state.downloading}
            size="small"
            disabled={this.state.loading}
            className="m5h m5w"
            type="secondary"
            icon="file-word"
            onClick={() => {
              if (!this.state.loading) {
                this.setState(
                  {
                    loading: true,
                    downloading: true,
                  },
                  () => {
                    CommissioneService.download_word_2(
                      this.props.id_commissione,
                      (res) => this.saveBlobWord(res.data),
                      () =>
                        this.setState({ loading: false, downloading: false })
                    );
                  }
                );
              }
            }}
          >
            Relazione sul progetto formativo realizzato{" "}
          </Button>
          */}
          <Button
            loading={this.state.downloading}
            size="small"
            disabled={this.state.loading}
            className="m5h m5w"
            type="secondary"
            icon="file"
            onClick={() => {
              if (!this.state.loading) {
                this.setState(
                  {
                    loading: true,
                    downloading: true,
                  },
                  () => {
                    CommissioneService.download_word_3(
                      this.props.id_commissione,
                      (res) => this.saveBlobWord(res.data, 'collegio_docenti_esami_finali.odt'),
                      () =>
                        this.setState({ loading: false, downloading: false })
                    );
                  }
                );
              }
            }}
          >
            Verbale collegio docenti ammissione esami finali{" "}
          </Button>
        </div>

        <br />

        <Documenti
          readOnly={this.props.readOnly}
          canDelete={true}
          required_dates={false}
          unique={true}
          media_types={
            this.props.GlobalStore.stubs
              ? this.props.GlobalStore.stubs.tipo_media
              : []
          }
          medias={this.state.medias}
          current_domain="commissioni_esame"
          current_subdomain="modello"
          ref_id={this.props.id_commissione}
          onUpload={(f) => {
            this.setState({ medias: [...this.state.medias, f] });
          }}
          onDelete={(id) => {
            this.setState({
              medias: this.state.medias.filter((x) => x.id !== id),
            });
          }}
        />
        <Documenti
          readOnly={this.props.readOnly}
          canDelete={true}
          required_dates={false}
          unique={true}
          media_types={
            this.props.GlobalStore.stubs
              ? this.props.GlobalStore.stubs.tipo_media
              : []
          }
          medias={this.state.medias}
          current_domain="commissioni_esame"
          current_subdomain="Relazione"
          ref_id={this.props.id_commissione}
          onUpload={(f) => {
            this.setState({ medias: [...this.state.medias, f] });
          }}
          onDelete={(id) => {
            this.setState({
              medias: this.state.medias.filter((x) => x.id !== id),
            });
          }}
        />
        <Documenti
          readOnly={this.props.readOnly}
          canDelete={true}
          required_dates={false}
          unique={true}
          media_types={
            this.props.GlobalStore.stubs
              ? this.props.GlobalStore.stubs.tipo_media
              : []
          }
          medias={this.state.medias}
          current_domain="commissioni_esame"
          current_subdomain="Verbale"
          ref_id={this.props.id_commissione}
          onUpload={(f) => {
            this.setState({ medias: [...this.state.medias, f] });
          }}
          onDelete={(id) => {
            this.setState({
              medias: this.state.medias.filter((x) => x.id !== id),
            });
          }}
        />

        <Button
          type="primary"
          className="bg-green"
          loading={this.state.loading}
          icon="save"
          disabled={this.props.readOnly}
          onClick={() => this.submit()}
        >
          Salva dati
        </Button>
      </div>
    );
  }
}
