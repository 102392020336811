import React, { Component } from 'react';
import { Icon, AutoComplete, message } from 'antd';
import ComuniService from '@common/services/locations/Comuni';
import CommonForm from '@common/components/form';

import formatter from '@common/utils/formatters/_';

const Option = AutoComplete.Option;

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'select',
                                label: 'Titolo d’uso dei locali',
                                name: 'id_tipologia_uso_locali',
                                onChange: 'changedTitoloUsoLocali',
                                props: {
                                    // size: 'large',
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                options_props: 'uso_locali',
                                //onChange: 'selectedResidenzaProvincia',
                            },
                        ],
                    },
                ],
            },
            {
              cols: [
                {
                  items: [
                    {
                      name: 'nulling_name',
                      type: 'title',
                      element: 'h4',
                      text: 'Contratto disponibilità immobile',
                      props: {
                        style: { textAlign: 'left' },
                        className: 'm10h'
                      },
                    }
                  ],
                }
              ],
            },
            /**
             * Vanno nella connessione per gestire particelle/subalterni diversi per stessa sede
             */
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'integer',
                                label: 'Foglio',
                                name: 'foglio',
                                props: {
                                    //size: 'large',
                                    style: { width: '100%' },
                                    min: 0,
                                },
                                validations: [
                                  {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                  },
                                ]
                            },
                        ],
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'Particella',
                                input_type: 'text',
                                name: 'particella',
                                props: {
                                    //size: 'large',
                                    style: { width: '100%' },
                                },
                                validations: [
                                  {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                  },
                                ]
                            },
                        ],
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'integer',
                                label: 'Subalterno',
                                name: 'subalterno',
                                props: {
                                    //size: 'large',
                                    style: { width: '100%' },
                                    min: 0,
                                }
                            },
                        ],
                    }
                ]
            },
            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'datepicker',
                                label: 'Registrato in data',
                                name: 'contratto_data_registrazione',
                                props: {
                                  //size: 'large',
                                  style: { width: '100%' },
                                },
                                showIf: [
                                  {
                                      type: 'prop_func',
                                      func: 'validateTitoloPossessoLocali'
                                  }
                                ],
                                validations: [
                                  {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                  },
                                ]
                            },
                        ],
                    },/*
                    {
                        span: 12,
                        items: [
                            {
                                type: 'datepicker',
                                label: 'In vigore dal',
                                name: 'contratto_in_vigore_dal',
                                props: {
                                    //size: 'large',
                                    style: { width: '100%' },
                                }, showIf: [
                                    {
                                        type: 'prop_func',
                                        func: 'validateTitoloPossessoLocali'
                                    }
                                ],
                                // validations: [
                                //   {
                                //     type: 'required',
                                //     error_message: 'Campo obbligatorio',
                                //   },
                                // ]
                            },
                        ],
                    },*/
                    {
                        span: 12,
                        items: [
                            {
                                type: 'autocomplete',
                                label: 'Presso l\'ufficio del registro di',
                                type_string: 'text', // field type
                                name: 'contratto_presso_ufficio',
                                props: {
                                  disabled: component.props.readOnly,
                                  // size: 'large',
                                  prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />
                                },
                                showIf: [
                                  {
                                      type: 'prop_func',
                                      func: 'validateTitoloPossessoLocali'
                                  }
                                ],
                                validations: [
                                  {
                                    type: 'required',
                                    error_message: 'Campo obbligatorio',
                                  },
                                ],
                                withIcon: true,
                                dataFunc: 'getComune',
                                onSelectFunc: 'setComune',
                                onSearch: 'searchingComune'
                            },
                        ]
                    },/*
                    {
                        span: 12,
                        items: [
                            {
                                type: 'datepicker',
                                label: 'Con scadenza',
                                name: 'contratto_data_scadenza',
                                props: {
                                    //size: 'large',
                                    style: { width: '100%' },
                                }, showIf: [
                                    {
                                        type: 'prop_func',
                                        func: 'validateTitoloPossessoLocali'
                                    }
                                ],
                                // validations: [
                                //   {
                                //     type: 'required',
                                //     error_message: 'Campo obbligatorio',
                                //   },
                                // ]
                            },
                        ],
                    },*/
                    {
                        span: 12,
                        items: [
                            {
                                type: 'radio',
                                label: 'Sono previste clausole risolutorie',
                                name: 'contratto_clausole_risolutorie',
                                onChange: 'changeClausoleRisolutorie',
                                option_type: 'button', // normal|button
                                // le opzioni come le selects
                                options: [
                                    {
                                        value: "1",
                                        label: "Si"
                                    },
                                    {
                                        value: "0",
                                        label: "No"
                                    }
                                ], showIf: [
                                    {
                                        type: 'prop_func',
                                        func: 'validateTitoloPossessoLocali'
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        span: 24,
                        items: [
                            {
                                type: 'select',
                                label: 'Destinazione d\'uso catastale',
                                name: 'id_destinazione_uso_catastale',
                                props: {
                                    // size: 'large',
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                options_props: 'destinazione_catastale',
                                //onChange: 'selectedResidenzaProvincia',
                            },
                        ],
                    },
                ],
            },/*
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'upload_doc_locale',
                                type: 'title',
                                element: 'h3',
                                text: 'Upload Documenti',
                                props: {
                                    style: { textAlign: 'center' },
                                },
                            },
                        ],
                    },
                ],
            },*//*
            {
                cols: [
                    {

                        items: [
                            {
                                name: 'child',
                                type: 'child',
                                child: <Documenti
                                    canDelete={true}
                                    canAddNote={false}
                                    media_types={component.props.tipo_media}
                                    medias={component.props.values ? component.props.values.media : component.state.medias}
                                    current_domain="sede"
                                    current_subdomain="locale_contratto"
                                    required_types={[]}
                                    ref_id={component.props.id_ente}
                                    onUpload={(media) => {
                                        component.setState({ medias: [...component.state.medias, media] });
                                    }} />,

                            }
                        ],
                    },
                ],
            },*//*
            Eliminare il campo ma inserire un controllo rispetto alla Data di scadenza
            del contratto di affitto/comodato (almeno 24 mesi dalla data della domanda per
            l’accreditamento provvisorio  e di 12 mesi per l’accreditamento definitivo
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'radio',
                                label: 'Durata disponibilità',
                                name: 'durata_disponibilita_superiore_24_mesi',
                                option_type: 'button', // normal|button
                                // le opzioni come le selects
                                options: [
                                    {
                                        value: "1",
                                        label: "Si"
                                    },
                                    {
                                        value: "0",
                                        label: "No"
                                    }
                                ],
                            }
                        ],
                    },
                ],
            },
            */
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'radio',
                                label: 'La sede formativa viene utilizzata esclusivamente per attività formative, di ricerca e di orientamento anche se non soggetta ad accreditamento',
                                name: 'utilizzo_esclusivo_formazione',
                                option_type: 'button', // normal|button
                                // le opzioni come le selects
                                options: [
                                    {
                                        value: "1",
                                        label: "Si"
                                    },
                                    {
                                        value: "0",
                                        label: "No"
                                    }
                                ],
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'integer',
                                label: 'Capacità massima numero allievi',
                                type_string: 'number', // field type
                                name: 'numero_totale_allievi',
                                props: {
                                    disabled: component.props.readOnly,
                                    //size: 'large',
                                    placeholder: 'N. allievi',
                                    maxLength: 5,
                                    min:0,
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                            },
                        ],
                    }
                ],
            }
    ],
    onSubmit: 'submitted',
  }
}

class CaratteristicheForm extends Component {
  state = {
    loading: false,
    loaded: false,
    comuni: [],
    files: {},
    fine_lucro: null,
    divisione: null,
  };

  async validate() {
    return new Promise(resolve => {
      this.formRef.props.form.validateFields(errors => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          message.error('Ricontrolla i campi');
          resolve(false);
        }
      });
    });
  }

  getFormData() {
    return formatter.replaceFormDatesOut(['contratto_data_registrazione', 'contratto_in_vigore_dal', 'contratto_data_scadenza'], this.formRef.props.form.getFieldsValue());
  }

  changeClausoleRisolutorie(value) {
    this.props.onChangeClausole(value)
  }

  changedTitoloUsoLocali(value) {
    if (value !== 2 && value !== 3) {
      this.changeClausoleRisolutorie("0")
      this.formRef.props.form.setFields({
        contratto_clausole_risolutorie: {
          value: "0"
        }
      });
      this.setState({contratto_clausole_risolutorie: "0"});
    } else {
        if(this.state.contratto_clausole_risolutorie && this.state.contratto_clausole_risolutorie !== null){
            this.setState({contratto_clausole_risolutorie: null},()=>{
                this.formRef.props.form.setFields({
                    contratto_clausole_risolutorie: {
                      value: "0"
                    }
                  });
            });
        }
    }
    
    this.props.onChangeTitoloUsoLocali(value)
  }

  render() {

    return (
      <div>
        <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.props.loading || false}
            form_model={formLayout(this)}
            values={
                formatter.convertBoolean(['contratto_clausole_risolutorie','utilizzo_esclusivo_formazione'], this.props.values)
            }
            readOnly={this.props.readOnly}

            uso_locali={this.props.GlobalStore.stubs.tipologia_uso_locali}
            destinazione_catastale={this.props.GlobalStore.stubs.destinazione_catastale}

            validateTitoloPossesso={(value, form) => {
                return (form.getFieldValue('titolo_possesso') === 2 || form.getFieldValue('titolo_possesso') === 3);
            }}

            validateTitoloPossessoLocali={(value, form) => {
                return (form.getFieldValue('id_tipologia_uso_locali') === 2 || form.getFieldValue('id_tipologia_uso_locali') === 3);
            }}

            changedTitoloUsoLocali={(value)=>this.changedTitoloUsoLocali(value)}
            changeClausoleRisolutorie={(field, e) => this.changeClausoleRisolutorie(e.target.value)}

            getComune={() => {
                //console.log('GET COMUNE NASCITA', this.state.comuni);
                return this.state.comuni.map(city => {
                  return (
                    <Option key={"comune_"+city.id} value={'' + city.comune}>
                      {city.comune}
                    </Option>
                  );
                });
              }}

              searchingComune={async value => {
                if (
                  !this.state.searching_comuni
                ) {
                  this.setState({ searching_comuni: false });
                  //console.log(this.state.selected_residenza_provincia)
                  ComuniService.list(
                    {
                      id_regione: 18,
                      comune: value,
                    },
                    res => {
                      this.setState({
                        comuni: res.data.data.list || [], //(cities.length > 0) ? cities.slice(0, 10) : [],
                        searching_comuni: false,
                      });
                    },
                    () => null
                  );
                }
              }}
        />
      </div>
    );
  }
}
export default CaratteristicheForm;