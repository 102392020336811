import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, message, Modal } from 'antd';

import ModelServices from '@accreditamento/services/Audit';

import moment from 'moment';

import auditStatic from '@common/utils/static/audit';

import CommonForm from '@common/components/form';

import checkPermission from '@common/utils/check/permissions';

import SingleIntegrazione from './SingleIntegrazione';
import RichiestaIntegrazioneForm from './RichiestaIntegrazioneForm';

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};




class SingleAudit extends Component {

  state = {
    loaded: false,
    accreditamento: {
      id: null
    },
    audit: {},
    open_modal_audit_refuse: false,
    open_modal_audit_integration: false,
    loading: false,
    approving: false,
    refusing: false,
    suspending: false
  }

  componentDidMount() {
    if (this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento)) this.loaded(newProps)
  }

  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, loaded: true });
  }

  isIstruttore() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) &&
      this.props.accreditamento.istruttore && this.props.accreditamento.istruttore[0].id === this.props.UserStore.user.id
      ? true : false
  }

  canView() {

    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      this.isIstruttore() ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.accreditamento.id_user === this.props.UserStore.user.id) ||
      this.props.audit.id_user === this.props.UserStore.user.id
    ) ? true : false;
  }

  canGiveFeedback() {

    if (!this.isValidated()) return false;

    return this.props.UserStore.user.id === this.props.audit.id_user && this.props.audit.stato === auditStatic.stato.in_corso ? true : false
  }

  disableApprove(){
    let invalid = false;
    if(this.props.accreditamento.istruttore_sections_feedback){
    Object.entries(this.props.accreditamento.istruttore_sections_feedback).forEach(([key, val]) => {
      if(!val['valid']) invalid = true;
    });
  }
    return invalid;
  }

  renderActions() {
    return this.canGiveFeedback() ? <div>
      <p>Verifica la documentazione della pratica, valida le sezioni del formulario, approva, rifiuta o richiedi una integrazione documentale</p>
      <Button
        loading={this.state.approving} disabled={this.state.loading || this.disableApprove()}
        className="m5h" type="primary" icon="check" onClick={() => {
          if (!this.state.loading) {
            this.setState({
              loading: true, approving: true
            }, () => {
              ModelServices.approve(
                this.props.accreditamento.id,
                this.props.audit.id,
                (res) => {
                  this.setState({ loading: false, approving: false })
                  this.props.refreshInline(res.data.data)
                },
                (err) => this.setState({ loading: false, approving: false })
              )
            })

          }
        }}
        style={{ marginRight: 8 }}>Approva</Button>

      {
        !this.props.audit.integrazione || this.props.audit.integrazione.length < 1/*2 - palesemente una change request */
          ? <Button loading={this.state.suspending} disabled={this.state.loading} className="m5h" type="primary" icon="file" style={{ marginRight: 8 }} ghost onClick={() => this.setState({ open_modal_audit_integration: true })}>Richiedi integrazione</Button>
          : null
      }
      <Button loading={this.state.refusing} disabled={this.state.loading} className="m5h" type="danger" icon="close" onClick={() => this.setState({ open_modal_audit_refuse: true })}>
        Rifiuta perchè inammissibile</Button>
      <Modal
        title="Inserisci note"
        visible={this.state.open_modal_audit_refuse}
        onOk={async () => {

          if (!this.formNoteRef.props.form.getFieldsValue()['note'] || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
            message.error('Inserisci le note');
            return;
          }

          if (!this.state.loading) {
            this.setState({
              loading: true, refusing: true
            }, () => {

              ModelServices.refuse(
                this.props.accreditamento.id,
                this.props.audit.id,
                { note: this.formNoteRef.props.form.getFieldsValue()['note'] },
                (res) => {
                  this.props.refreshInline(res.data.data);
                  this.setState({ open_modal_audit_refuse: false, loading: false, refusing: false })
                },
                (err) => this.setState({ loading: false, refusing: false })
              )
            })
          }
        }}
        onCancel={() => this.setState({ open_modal_audit_refuse: false })}
      >
        <CommonForm
          wrappedComponentRef={inst => (this.formNoteRef = inst)}
          loading={this.state.loading || false}
          form_model={formNoteLayout}
          values={{

          }}
        />
      </Modal>
      <Modal
        title="Richiedi integrazione documentale"
        visible={this.state.open_modal_audit_integration}
        destroyOnClose={true}
        confirmLoading={this.state.suspending}
        width="90%"
        onOk={async () => {

          let values = this.refs.integration_ref.getValues()

          if (!this.state.loading) {
            this.setState({
              loading: true, suspending: true
            }, () => {
              ModelServices.integration(
                this.props.accreditamento.id,
                this.props.audit.id,
                { requested_media: values },
                (res) => {
                  this.props.refreshInline(res.data.data);
                  this.setState({ open_modal_audit_integration: false, loading: false, suspending: false })
                },
                () => this.setState({ loading: false, suspending: false })
              )
            })
          }
        }
        }
        onCancel={() => this.setState({ open_modal_audit_integration: false })}
      >
        <RichiestaIntegrazioneForm
          ref="integration_ref"
          accreditamento={this.props.accreditamento}
        />
      </Modal>
    </div> : null
  }

  isValidated() {
    if (!this.props.accreditamento.istruttore_sections_feedback)
      return false;

    return Object.keys(this.props.progress.showed_sections).every(x =>
      !this.props.progress.showed_sections[x] || this.props.accreditamento.istruttore_sections_feedback[x]
    );
  }

  render() {
    return (this.state.loaded && this.canView()) ?
      <div key={"audit_" + this.props.audit.id} style={{ marginBottom: 8 }}>
        <div>
          <p style={{ marginBottom: 0 }}>Utente: <strong>{this.props.audit.user.username} ({this.props.audit.user.anagrafica.nome + " " + this.props.audit.user.anagrafica.cognome})</strong><br />
          Stato: <strong className="color-green">{auditStatic.getStatoLabel(this.props.audit)}</strong><br />
          Data: <strong>{moment.unix(this.props.audit.created_at).format('DD/MM/YYYY')}</strong></p>
        </div>
        {this.props.audit.note && this.props.audit.note.map(n => {
          return <div key={"nota_" + n.id}><small><strong>{(n.user) ? n.user.username : " - "}:</strong> {n.note}</small></div>
        })}
        {this.props.accreditamento.stato_flusso === 6 && !this.isValidated() ? 'Conferma le schede' : ''}
        {this.renderActions()}
        {
          (this.props.audit.integrazione && this.props.audit.integrazione.length > 0)
            ?
            this.props.audit.integrazione.map(i => {
              return <SingleIntegrazione
                key={'integrazione_' + i.id}
                refresh={() => this.props.refresh()}
                refreshInline={(data) => this.props.refreshInline(data)}
                audit={this.props.audit}
                integrazione={i}
                accreditamento={this.props.accreditamento}
              />
            })
            : null
        }
      </div>
      : null
  }
}

const AuditExport = connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SingleAudit);

export default withRouter(AuditExport);
