/**
 * @component
 *
 * Formattatori comuni
 */
export default {
	/**
	 * Vedi se un utente ha un determinato permesso
	 *
	 * @public
	 */
	hasPermission: (user, permission) => {


		try {

			let has = false;
			if (Array.isArray(permission)) {
				//console.log('array', user)
				permission.forEach(p => {
					//console.log('ver',p,user)
					if (user.permissions[p]) has = true;
				})
			} else {
				return (user.permissions[permission]) ? true : false
			}

			return has;
			//return (Object.keys(user.permissions).find(p => p = permission)) ? true : false;
		} catch (e) {
			return false;
		}
	},

	/**
	 * Vedi se un utente ha un determinato ruolo
	 *
	 * @public
	 */
	 hasRole: (user, role) => {


		try {

			let has = false;
			if (Array.isArray(role)) {
				//console.log('array', user)
				role.forEach(p => {
					//console.log('ver',p,user)
					user.role.map(r => {
						if(r.name === p){
							has = true;
						}
					});
				})
			} else {
				user.role.map(r => {
					if(r.name === role){
						has = true;
					}
				});
			}

			return has;
			//return (Object.keys(user.permissions).find(p => p = permission)) ? true : false;
		} catch (e) {
			return false;
		}
	},

	/**
	 * Vedi se un utente gestisce una organizzazione con iscrizione in stato approvata (==1)
	 * @param  {[type]} user [description]
	 * @return {[type]}      [description]
	 */
	hasOrganizzazioneCompleteRequest: (user) => {
		try {
			return (user.organizzazione.length === 1 && user.organizzazione[0].iscrizione.stato === 1);
		} catch (e) {
			return false;
		}
	},

	/**
	 * Vedi se un utente gestisce una organizzazione
	 * @param  {[type]} user [description]
	 * @return {[type]}      [description]
	 */
	hasOrganizzazione: (user) => {
		try {
			return (user.organizzazione.length === 1);
		} catch (e) {
			return false;
		}
	}

}