import BaseRequest from '@common/services/_baseRequest';

export default {

  allProjects: (id , vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      "get",
      'affidamento/commissione-esame/'+ id + '/allowed-progetto',
      cb,
      ecb || null,

    );
  },

  download_word_1: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/download-template-word/MONITORAGGIO_FINALE_PRESENZE_ASSENZE_ALLIEVI',
			cb,
			ecb || null,
			null
		);
	},

  download_word_2: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/download-template-word/RELAZIONE_PROGETTO_FORMATIVO',
			cb,
			ecb || null,
			null
		);
	},

  download_word_3: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/download-template-word/VERBALE_COLLEGIO_DOCENTI',
			cb,
			ecb || null,
			null
		);
	},

  saveIstruttoria: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "POST",
      'affidamento/commissione-esame/' + id + '/istruttoria',
      cb,
      ecb
    );
  },

  SelectEdizione: (id, id_progetto , vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      "get",
      'affidamento/commissione-esame/'+ id +'/allowed-progetto/' +  id_progetto +'/allowed-edizione',
      cb,
      ecb || null,

    );
  },

  SaveData: (scheda_progetto_json = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      'affidamento/commissione-esame/'+ id +'/save-json',
      cb,
      ecb
    );
  },

  getDomanda: (id, id_progetto, id_edizione, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {} ,
      {},
      null,
      'get',
      'affidamento/commissione-esame/'+ id +'/allowed-progetto/'+ id_progetto +'/allowed-edizione/'+ id_edizione +'/general-data',
      cb,
      ecb
    );
  },

  getDomande: (id, vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/commissione-esame/'+ id + '/domande',
      cb,
      ecb
    );
  },

  list: (vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/commissione-esame',
      cb,
      ecb
    );
  },

  detail: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/commissione-esame/' + id,
      cb,
      ecb
    );
  },

  create: (data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/progetto',
      cb,
      ecb
    );
  },

  createCommissione: (data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/commissione-esame',
      cb,
      ecb
    );
  }, 


  newEdition: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      //'affidamento/commissione-esame/' + id_progetto + '/new-edition', <- da togliere
      'affidamento/commissione-esame/' + id_progetto + '/editione', 
      cb,
      ecb
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "put",
      'affidamento/commissione-esame/' + id,
      cb,
      ecb
    );
  },

  delete: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/commissione-esame/' + id_progetto,
      cb,
      ecb
    );
  },

  save_json: (scheda_progetto_json = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      'affidamento/commissione-esame/' + id_progetto + '/save-json',
      cb,
      ecb
    );
  },

  save_istruttoria_coordinatore: (istruttoria_progetti_coordinatore = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { istruttoria_progetti_coordinatore: istruttoria_progetti_coordinatore },
      null,
      'post',
      'affidamento/commissione-esame/' + id_progetto + '/istruttoria-coordinatore',
      cb,
      ecb
    );
  },

  save_istruttoria_presidente: (istruttoria_progetti_presidente = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { istruttoria_progetti_presidente: istruttoria_progetti_presidente },
      null,
      'post',
      'affidamento/commissione-esame/' + id_progetto + '/istruttoria-presidente',
      cb,
      ecb
    );
  },

  progress: (params = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'affidamento/commissione-esame/' + id_progetto + '/progress',
      cb,
      ecb
    );
  },

  info_and_validate: (scheda_progetto_json = {}, id_progetto = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      'affidamento/commissione-esame/' + id_progetto + '/info-and-validate',
      cb,
      ecb
    );
  },

  printPdf: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame/' + id_progetto + '/print-pdf',
      cb,
      ecb
    );
  },

  upload: (id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/commissione-esame/' + id + '/upload',
      cb,
      ecb
    );
  },

  uploadByDominio: (id, dominio, sottodominio, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      `affidamento/commissione-esame/${id}/attachment`,
      cb,
      ecb
    );
  },

  requestOpenRegistration: (project_id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/commissione-esame/' + project_id + '/request-open-registration',
      cb,
      ecb
    );
  },

  approveOpenRegistration: (project_id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame/' + project_id + '/approve-open-registration',
      cb,
      ecb
    );
  },

  rejectOpenRegistration: (project_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame/' + project_id + '/reject-open-registration',
      cb,
      ecb
    );
  },

  setOpenRegistrationDate: (project_id, data, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame/' + project_id + '/set-open-registration-date',
      cb,
      ecb
    );
  },

  confirm: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame/' + id + '/confirm',
      cb,
      ecb
    );
  },

  send: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame/' + id + '/send',
      cb,
      ecb
    );
  },

  downloadAutorizzazione: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame/' + id_progetto + '/download-autorizzazione',
      cb,
      ecb
    );
  },

  downloadAutorizzazione2: (id_commissione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame/' + id_commissione + '/download-template-word/LETTERA_INCARICO',
      cb,
      ecb
    );
  },

  downloadAutorizzazione3: (id_commissione, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      'affidamento/commissione-esame/' + id_commissione + '/download-template-word/ACCETTA_INCARICO',
      cb,
      ecb
    );
  },

  rollback: (id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/commissione-esame/' + id + '/rollback',
      cb,
      ecb
    );
  },

  assign: (id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame/' + id + '/assign',
      cb,
      ecb
    );
  },

  feedbackSection: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame/' + id_progetto + '/feedback-section',
      cb,
      ecb
    );
  },

  storicoDiff: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      'affidamento/commissione-esame/' + id_progetto + '/storico-diff',
      cb,
      ecb
    );
  },

  editionDiff: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      'affidamento/commissione-esame/' + id_progetto + '/edition-diff',
      cb,
      ecb
    );
  },

  formularioDiff: (id_progetto, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      'affidamento/commissione-esame/' + id_progetto + '/formulario-diff',
      cb,
      ecb
    );
  },

  prepareCourse: (id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/commissione-esame/' + id + '/prepare-course',
      cb,
      ecb
    );
  },

  loadRegistrationData: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'post',
      'affidamento/commissione-esame/' + id + '/iscrizione',
      cb,
      ecb
    );
  },

  getCopertura: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/commissione-esame/' + id + '/copertura',
      cb,
      ecb
    );
  },

  download_approvazione_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/approvazione-word',
			cb,
			ecb || null,
			null
		);
	},

  download_autorizzazione_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/autorizzazione-word',
			cb,
			ecb || null,
			null
		);
	},

  download_risposta_controdeduzioni_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/risposta-controdeduzioni-word',
			cb,
			ecb || null,
			null
		);
	},

  download_ostativi_word: (id, cb, ecb = null) => {
		var req = new BaseRequest();
		req.makeRequest(
			{},
			{},
			null,
			"get_file",
			'affidamento/commissione-esame/' + id + '/ostativi-word',
      cb,
			ecb || null,
			null
		);
	},

  exportPratiche: (params = {}, cb = null, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			params,
			null,
			null,
			'get_file',
			'affidamento/commissione-esame/export',
			cb,
			ecb || null,
			null
		);
	},

  declinaIncarico: (id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/commissione-esame/' + id + '/declina-incarico',
      cb,
      ecb
    );
  },
}
