import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Progress,
  Row,
  Modal,
  Drawer,
  message,
  Popconfirm,
  Tooltip,
  Icon,
} from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import SpinnerLoading from "@common/components/SpinnerLoading";
import CheckPermissions from "@common/decorators/CheckPermissions";
import Wrapper from "@common/decorators/Wrapper";
import checkPermission from "@common/utils/check/permissions";
import affidamentoStatic from "@common/utils/static/affidamento";
import progettoService from "../services/Progetto";
import SchedaFormulario from "../components/SchedaFormularioEdizione";
import ComunicazioniPecList from '@accreditamento/components/comuncazioni-pec/ComunicazioniPecList';
import eventBroker from "@common/utils/services/EventBroker";
import ActionsEdizione from "../components/ActionsEdizione";
import AssegnazioneIstruttore from "../components/Assegnazione/AssegnazioneIstruttore";
import auditStatic from "@common/utils/static/audit";
import ProjectServices from "@affidamento/services/Progetto";
import ModelServices from "../services/Edizione";

import _ from "lodash";
import PagamentoCard from "../components/PagamentoCard";
import PagamentoService from "../services/PagamentoService";

const getStato = (progetto, component) => {
  let stato = "ente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttoreAmministrativoPermissions",
    ])
  )
    stato = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigenteAmministrativoPermissions",
    ])
  )
    stato = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    stato = "ispettore";
  return progetto.stato_flusso_description["stato_" + stato];
};

const renderDescriptionText = (record, component) => {
  if (!record.stato_flusso_description) return "";
  let user = "ente";

  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigenteAmministrativoPermissions",
    ])
  )
    user = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttoreAmministrativoPermissions",
    ])
  )
    user = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    user = "ispettore";

  return record.stato_flusso_description["descrizione_" + user];
};

@Wrapper({
  route: "Progetto",
  hasSidebar: true,
  hasHeader: true,
  hasFooter: true,
})
@CheckPermissions([
  "direttoreAmministrativoPermissions",
  "istruttoreAmministrativoPermissions",
  "dirigenteAmministrativoPermissions",
  "adminPermissions",
  "HelpdeskPermissions",
])
class EdizioneBackofficeSingolaRoute extends Component {
  state = {
    mounted: false,
    loading: false,
    loaded: true,
    saving: false,
    saved: true,
    calculating_progress: false,
    getting_info: false,
    loading_riepilogo: false,
    dati: null,
    progress_obj: {
      progress: 0,
      required_sections: {},
      valid_sections: {},
      showed_sections: {},
      errors: {},
    },
    showNote: false,
    edizione_numero: null,
    progetto_padre: {},
    pagamenti: null,
    isPrimaEdizione: false,
  };

  constructor(props) {
    super(props);
    this.state.edizione_numero = this.props.match.params.id;
    this.loadPagamenti = this.loadPagamenti.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true, loaded: false }, () => {
      this.loadProgetto();
      this.checkIfPrimaEdizione();
      this.loadPagamenti();
    });
  }

  loadPagamenti() {
    PagamentoService.pagamentiProgetto(this.props.match.params.id).then(
      (response) => {
        const pagamenti = _.get(response.data, "data.pagamenti");
        this.setState({ pagamenti });
      }
    );
  }

  checkIfPrimaEdizione() {
    ModelServices.isPrimaEdizioneInviata(this.props.match.params.id).then(
      (response) => {
        const isPrimaEdizione = _.get(response.data, "data");
        this.setState({ isPrimaEdizione });
      }
    );
  }

  loadProgetto() {
    ModelServices.detailBackoffice(
      this.state.edizione_numero,
      {
        expand: [
          "media",
          "istruttore",
          "audit",
          "audit.user",
          "audit.integrazione",
          "comunicazioneOstativa",
          "comunicazioneOstativa.controdeduzioni",
          "comunicazioneOstativa.controdeduzioni.media",
          "ispezione",
          "ispezione.nomine",
          "ispezione.nomine.ispettore",
          "ispezione.nomine.media",
          "ispezione.verbali",
          "ispezione.verbali.media",
          "ispezione.verbali.sede",
          "ispezione.verbali.sede.indirizzo",
          "ispezione.controdeduzioni",
          "ispezione.controdeduzioni.media",
          "conIstruttore",
          "rup",
        ].join(","),
      },
      (res) => {
        ProjectServices.detail(
          {
            expand: [
              "media",
              "istruttore",
              "audit",
              "audit.user",
              "audit.integrazione",
              "comunicazioneOstativa",
              "comunicazioneOstativa.controdeduzioni",
              "comunicazioneOstativa.controdeduzioni.media",
              "ispezione",
              "ispezione.nomine",
              "ispezione.nomine.ispettore",
              "ispezione.nomine.media",
              "ispezione.verbali",
              "ispezione.verbali.media",
              "ispezione.verbali.sede",
              "ispezione.verbali.sede.indirizzo",
              "ispezione.controdeduzioni",
              "ispezione.controdeduzioni.media",
              "conIstruttore",
              "rup",
            ].join(","),
          },
          res.data.data.id_progetto,
          (edizioneResponse) => {
            this.setState({
              dati: res.data.data,
              progress_obj: res.data.data.progress,
              loaded: true,
              progetto_padre: edizioneResponse.data.data,
            });
          }
        );
        /*this.setState({
          dati: res.data.data,
          progress_obj: res.data.data.progress,
          loaded: true
        });*/
      },
      (err) =>
        this.setState({
          loaded: true,
        })
    );
  }

  loadRegistrationData(id) {
    this.setState(
      {
        loading: true,
        loading_load_registration_data: true,
      },
      () => {
        ProjectServices.loadRegistrationData(
          this.state.edizione_numero,
          {},
          (res) => {
            this.setState({
              model_anagrafica: res.data.data,
              loading: false,
              loading_load_registration_data: false,
            });
            this.props.history.push(
              "/affidamento/progetto/" + id + "/iscrizione/" + res.data.data.id
            );
          },
          () =>
            this.setState({
              loading: false,
              loading_load_registration_data: false,
            })
        );
      }
    );
  }

  canGiveSectionFeedback() {
    return (
      this.canGiveSectionFeedbackIstr() || this.canGiveSectionFeedbackRup()
    );
  }

  canGiveSectionFeedbackIstr() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ["addAudit"]) &&
      this.state.dati.istruttore &&
      this.state.dati.istruttore.length > 0 &&
      this.state.dati.istruttore[0].id === this.props.UserStore.user.id
    );
  }

  canGiveSectionFeedbackRup() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ["addAudit"]) &&
      this.state.dati.rup &&
      this.state.dati.rup.length > 0 &&
      this.state.dati.rup[0].id === this.props.UserStore.user.id &&
      this.state.dati.istruttore.length == 0
    );
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: "application/pdf" });
    FileSaver.saveAs(_blob, "stampa.pdf");

    this.refresh(false);
    //this.refreshInline(data)
  }

  canAssignIstruttore() {
    return (
      this.state.dati.stato === affidamentoStatic.stato_progetto.presentato ||
      (this.state.dati.conIstruttore &&
        this.state.dati.conIstruttore.length == 0 &&
        checkPermission.hasPermission(this.props.UserStore.user, [
          "dirigenteAmministrativoPermissions",
        ]) === true)
    );
  }

  editableState() {
    let editable =
      this.isEnte() &&
      (this.state.dati?.stato === affidamentoStatic.stato_progetto.registrato ||
        (this.state.dati?.stato ===
          affidamentoStatic.stato_progetto.predisposizione &&
          this.state.dati?.stato_flusso ===
            affidamentoStatic.stato_flusso_progetto.predisposizione));
    return editable;
  }

  sectiontMappingGetName(name) {
    let o = {
      generalData: "Dati generali del progetto",
      relatedSubjects: "Soggetti correlati",
      learner: "Discenti",
      teachingProgramming: "Programmazione didattica",
      financialStatement: "Scheda finanziaria",
      nonTrainingElements: "Elementi non formativi",
    };

    return o[name];
  }

  propertyNameMapping(name) {
    const p = {
      examRequired: "Previsto Esame finale",
      certificateType:
        "Tipologia dell’attestazione finale rilasciata alla fine del corso",
      title: "Qualifica professionale",
      position: "Figura professionale",
      activityType: "Tipologia attività",
      macroTypology: "Macrotipologia",
      courseTitle: "Titolo del corso",
      level: "Livello offerta formativa",
      modality: "Modalità di svolgimento",
      basicInfo: "Informazioni di base sul percorso",
      educationalContent: "Classificazione dei contenuti formativi/educativi",
      claClassification: "Classificazione CLA istat a 3 digit",
      role: "Ruolo e attività del capofila nel progetto",
      experiences: "Esperienze nel campo della formazione professionale",
      beneficiary: "Voucher/buoni formativi",
      cycleType: "Ciclo unico/pluriennale",
      stageDescription: "Descrizione Stage",
      guidedToursDescription: "Descrizione visite guidate",
      studyTravelDescription: "Descrizione viaggi studio",
      stageCompanyList:
        "Aziende che si sono dichiarate disponibili ad ospitare gli allievi",
      headquarterList: "Sede Operativa",
      staffPersonalDataList: "Elenco del personale ",
      article: "Riferimenti Legge regionale 18/85",
      accruedSkills: "Competenze maturate",
      startDate: "Data di avvio del corso",
      endDate: "Data di conclusione del corso",
      editionNumber: "Numero edizione",
      financing: "Finanziamento",
      axis: "Asse",
      specificObjective: "Obiettivo specifico",
      entryFee: "Tassa di iscrizione",
      courseCost: "Costo intero corso comprensivo di IVA",
      monthlyFee: "Retta mensile di frequenza",
      otherPayment: "Altro gravame economico",
      paymentsDescription:
        "Descrizione modalità e tempi di versamento della retta",
      courseType: "Tipologia di corso",
      sectorType: "Settore",
      ageAdmission: "Requisiti di ammissione in termini di età",
      educationalQualification: "Titolo di studio",
      professionalExperience: "Esperienza professionale",
      professionalPosition: "Posizione nella professione",
      prerequisitesVerification: "Verifica prerequisiti",
      candidateSelection: "Selezione candidati",
      projectId: "Codice identificativo del progetto",
      courseId: "Codice identificativo del corso",

      positionNumber: "Posizione N°",
      del: "Del",
      declaredStudent: "Allievi dichiarati",
      insurancePeriodsStart: "Inizio periodo assicuratvio",
      insurancePeriodsEnd: "Fine periodo assicurativo",
      learnerList: "Elenco dei Discenti",
      subjectType: "Tipo soggetto",

      projectAims: "Obiettivi progettuali",
      exhibitionClarity: "Chiarezza espositiva",
      territorialContext:
        "Descrizione del contesto territoriale di riferimento",
      selectionOrientationActivities: "Attività di selezione e orientamento",
      recognitionCredits: "Procedure di riconoscimento dei crediti formativi",
      disabledStudentsInsertion:
        "Azioni per favorire l’inserimento di eventuali allievi in situazioni di disabilità",
      advertising: "Modalità di pubblicizzazione dell’intervento",
      monitoring: "Monitoraggio dell’intervento",
      SpecializationStrategyContribution:
        "Capacità della proposta di contribuire all’attuazione della strategia regionale di specializzazione intelligente",
      territoryCoherence: "Coerenza con le esigenze specifiche del territorio",
      employmentOpportunities:
        "Incidenza della proposta rispetto alle opportunità occupazionali",

      partnerList: "Enti Partner",
      valueAdded: "Valore aggiunto apportato al progetto dal soggetto partner",
      legalForm: "Forma giuridica",
      statuslegalForm: "Stato Forma giuridica partenariato",
      numberOfTeachers: "Numero docenti/formatori/tutor",
      projectRepresentative: "Referente Progetto",
      entityPersonalData: "Dati anagrafici dell’Ente",
      legalRepresentativePersonalData: "Dati anagrafici Rappresentante legale",
      contacts: "Contatti",
      projectRepresentativePersonalData:
        "Dati anagrafici Referente del progetto",

      totalB1: "Totale preparazione",
      totalB2: "Totale realizzazione",
      totalB3: "Totale diffusione dei risultati",
      totalB4: "Totale direzione progetto e controllo interno",
      totalDirect: "Totale costi diretti",
      totalIndirect: "Totale costi indiretti",
      total: "Totale",
      dataSectionA: "Sezione A",

      accompanyingMeasuresDesription: "Misure di accompagnamento",
      courseArticulationList: "Elento di articolazione del corso per annualità",
      hoursMax: "Durata del corso",
      classroomHours: "Ore in aula",
      labHours: "Ore in laboratorio",
      stageHours: "Ore di stage",
      annuity: "Annualità",
      totalHours: "Totale ore",
      cycleDuration: "Furata in numero di anni",
      judgmentMethod: "Modalità di Valutazione dei Partecipanti",
      finalTestDescription: "Descrizione della prova d'esame finale",
      partecipantsMax: "Numero massimo partecipanti",
      studentsFeatures: "Caratteristiche degli allievi",
      companyName: "Denominazione azienda",
      legalRepresentative: "Rappresentante legale",
      address: "Indirizzo",
      corporatePurpose: "Oggetto sociale",
      studentsNumber: "Numero di allievi",
      totalStageHours: "Ore totali di stage",
    };

    return p[name] ? p[name] : null;
  }

  isEnte() {
    //TODO usare il permesso specifico
    return checkPermission.hasPermission(this.props.UserStore.user, [
      "entePermissions",
    ]);
  }

  calculateProgress() {
    if (!this.state.calculating_progress) {
      this.setState({ calculating_progress: true });

      ModelServices.progressBackoffice(
        this.state.edizione_numero,
        {},
        async (res) => {
          this.setState({
            progress_obj: res.data.data,
            loaded: true,
            calculating_progress: false,
          });
        },
        (err) =>
          this.setState({
            loaded: true,
            calculating_progress: false,
          })
      );
    }
  }

  save_json(json) {
    this.setState(
      {
        saving: true,
        saved: false,
      },
      () => {
        ModelServices.save_json(
          this.state.edizione_numero,
          json,
          (res) => {
            message.success("Dati salvati con successo");
            //this.updateProgress(res.data.data.progress);
            //this.refresh(false);
            eventBroker.trigger("reloadOnSectorChange");
            this.setState({
              dati: res.data.data,
              progress_obj: res.data.data.progress,
              saving: false,
              saved: true,
            });
          },
          () => {
            this.setState({
              saving: false,
              saved: true,
            });
          }
        );
      }
    );
  }

  getInfoAndValidate() {
    if (!this.state.getting_info) {
      this.setState({ getting_info: true });

      let domanda = this.state.dati.scheda_progetto_json;

      //Recupero il form dei dati generali e lo mergio in domanda json
      if (this.state.dati.scheda_progetto_json.generalData) {
        let generalData = eventBroker.trigger("getBackGeneralData");
        if (generalData)
          domanda.generalData = { ...domanda.generalData, ...generalData };
      }

      //Recupero il form dei discenti e lo mergio in domanda json
      if (this.state.dati.scheda_progetto_json.learner) {
        let data = eventBroker.trigger("getBackLearner");
        if (data) domanda.learner = { ...domanda.learner, ...data.learner };
      }

      //Recupero il form dei soggetti correlati e lo mergio in domanda json
      if (this.state.dati.scheda_progetto_json.relatedSubjects) {
        let data = eventBroker.trigger("getBackRelatedSubjects");
        if (data)
          domanda.relatedSubjects = {
            ...domanda.relatedSubjects,
            ...data.domanda.relatedSubjects,
          };
      }

      //Recupero il form dei dati generali e lo mergio in domanda json
      if (this.state.dati.scheda_progetto_json.teachingProgramming) {
        let teachingProgramming = eventBroker.trigger(
          "getBackTeachingProgramming"
        );
        if (teachingProgramming)
          domanda.teachingProgramming = {
            ...domanda.teachingProgramming,
            ...teachingProgramming,
          };
      }

      //TODO: ricontrollare come arrivano i dati dopo aver terminato lo sviluppo dei salvataggi dei vari tab

      ModelServices.info_and_validate(
        this.state.edizione_numero,
        domanda,
        (res) => {
          if (res.data.data.valid) {
            this.setState({ show_completed: true, getting_info: false });
          } else {
            this.setState({
              progress_obj: res.data.data,
              show_errors: true,
              getting_info: false,
            });
          }
        },
        (err) =>
          this.setState({
            getting_info: false,
          })
      );
    }
  }

  refresh(reset = true) {
    //console.log('REFRESH', this.props);
    if (reset) {
      this.setState({
        loaded: false,
      });
    }
    this.loadProgetto();
  }

  refreshInline(data) {
    this.setState(
      {
        dati: { ...this.state.dati, ...data },
      },
      () => {
        this.calculateProgress();
      }
    );
  }

  updateProgress(progress) {
    this.setState({
      progress_obj: progress,
    });
  }

  canChangeIstruttore() {
    //TODO aggiungere che lo stato deve essere diverso da accolto, accolto e confermato, rigettato
    return (
      this.state.dati.istruttore &&
      this.state.dati.istruttore.length > 0 &&
      checkPermission.hasPermission(this.props.UserStore.user, [
        "dirigenteAmministrativoPermissions",
      ]) === true
    );
  }

  async saveIstruttore(id_user) {
    return new Promise((savedIstruttore) => {
      this.setState(
        {
          assigning: true,
        },
        () => {
          ModelServices.assignBackoffice(
            this.state.edizione_numero,
            { id_user: id_user },
            (res) => {
              //this.refresh(false);
              this.setState({
                assigning: false,
                dati: res.data.data,
              });
              savedIstruttore(true);
            },
            () => {
              savedIstruttore(false);
              this.setState({
                assigning: false,
              });
            }
          );
        }
      );
    });
  }

  loadProjectDiff() {
    if (!this.state.loading_storico_diff && !this.state.diff_storico_obj) {
      this.setState({ loading_storico_diff: true });
      ModelServices.storicoDiff(
        this.state.edizione_numero,
        {},
        (res) => {
          this.setState({
            diff_storico_obj: res.data.data,
            loading_storico_diff: false,
            show_storico_diff: true,
          });
        },
        () => this.setState({ loading_storico_diff: false })
      );
    } else if (this.state.diff_storico_obj) {
      this.setState({ show_storico_diff: true });
    }
  }

  loadPrevEditionDiff() {
    if (!this.state.loading_edition_diff /*&& !this.state.diff_edition_obj*/) {
      this.setState({ loading_edition_diff: true });
      ModelServices.editionDiff(
        this.state.edizione_numero,
        {},
        (res) => {
          this.setState({
            diff_edition_obj: res.data.data,
            loading_edition_diff: false,
            show_edition_diff: true,
          });
        },
        () => this.setState({ loading_edition_diff: false })
      );
    } else if (this.state.diff_edition_obj) {
      this.setState({ show_edition_diff: true });
    }
  }

  loadDomandaDiff() {
    if (!this.state.loading_domanda_diff && !this.state.diff_domanda_obj) {
      this.setState({ loading_domanda_diff: true });
      ModelServices.formularioDiff(
        this.state.edizione_numero,
        {},
        (res) => {
          this.setState({
            diff_domanda_obj: res.data.data,
            loading_domanda_diff: false,
            show_domanda_diff: true,
          });
        },
        () => this.setState({ loading_domanda_diff: false })
      );
    } else if (this.state.diff_domanda_obj) {
      this.setState({ show_domanda_diff: true });
    }
  }

  refreshSedeSvolgimento() {
    let dati = this.state.dati;
    dati.scheda_progetto_json.relatedSubjects.AfcHeadquartersSection.provincia =
      null;
    dati.scheda_progetto_json.relatedSubjects.AfcHeadquartersSection.id = null;
    dati.scheda_progetto_json.relatedSubjects.AfcHeadquartersSection.comune =
      null;
    dati.scheda_progetto_json.relatedSubjects.AfcHeadquartersSection.indirizzo =
      null;

    this.setState({
      dati: dati,
    });
  }

  canViewProjectDifference() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ["editAudit"]) &&
      this.state.dati.audit &&
      this.state.dati.audit.length > 1 &&
      this.state.dati.istruttore &&
      this.state.dati.istruttore.length > 0 &&
      this.state.dati.istruttore[0].id === this.props.UserStore.user.id
    );
  }

  canViewDomandaDifference() {
    return (
      this.state.dati &&
      checkPermission.hasPermission(this.props.UserStore.user, [
        "viewProgetto",
        "manageOwnProgetto",
      ]) &&
      this.state.dati.id_tipo_finanziamento ===
        affidamentoStatic.tipo_finanziamento.finanziato &&
      !this.props.UserStore.user.ente
    );
  }

  canViewPrevEditionDifference() {
    return (
      this.state.dati &&
      this.state.dati.id_edizione_precedente &&
      checkPermission.hasPermission(this.props.UserStore.user, [
        "viewProgetto",
        "manageOwnProgetto",
      ]) &&
      this.state.dati.id_tipo_finanziamento ===
        affidamentoStatic.tipo_finanziamento.autofinanziato &&
      !this.props.UserStore.user.ente
    );
  }

  flattenObject(ob, predicate = () => {}) {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (!predicate(i)) continue;

      if (typeof ob[i] == "object" && ob[i] !== null) {
        toReturn[i] = ob[i]; //aggiungo ogni oggetto
        var flatObject = this.flattenObject(ob[i], predicate);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  renderDomandaDiff() {
    let filter = (i) =>
      i !== "progetto" &&
      i !== "id" &&
      !i.startsWith("id_") &&
      !i.endsWith("_at");
    return (
      <React.Fragment>
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "manageProgetto",
          "istruttoreAmministrativoPermissions",
        ]) && (
          <Button
            size="default"
            icon="diff"
            type="primary"
            className="m5h m5w"
            loading={this.state.loading_domanda_diff}
            onClick={() => this.loadDomandaDiff()}
          >
            Visualizza modifiche al formulario
          </Button>
        )}
        <Modal
          visible={this.state.show_domanda_diff}
          cancelButtonProps={{ style: { display: "none" } }}
          width={500}
          destroyOnClose={false}
          onOk={() => this.setState({ show_domanda_diff: false })}
          onCancel={() => this.setState({ show_domanda_diff: false })}
        >
          {this.state.diff_domanda_obj &&
          Object.keys(this.state.diff_domanda_obj).length > 0 ? (
            <div>
              <h3>Modifiche riscontrate</h3>
              {Object.keys(this.state.diff_domanda_obj).map((e) => {
                return this.sectiontMappingGetName(e) ? (
                  <div key={"section_domanda_" + e}>
                    <h4>{this.sectiontMappingGetName(e)} </h4>
                    <ul>
                      {Object.keys(
                        this.flattenObject(
                          this.state.diff_domanda_obj[e],
                          filter
                        )
                      ).map(
                        (d, i, a) =>
                          this.propertyNameMapping(d) && (
                            <li key={"diff_domanda_" + e + "_" + d + "_" + i}>
                              {this.propertyNameMapping(d)}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                ) : null;
              })}
            </div>
          ) : (
            <h3>Nessuna modifica</h3>
          )}
        </Modal>
      </React.Fragment>
    );
  }

  renderStoricoDiff() {
    let filter = (i) =>
      i !== "progetto" &&
      i !== "id" &&
      !i.startsWith("id_") &&
      !i.endsWith("_at");
    return (
      <React.Fragment>
        <Button
          size="default"
          icon="diff"
          type="primary"
          className="m5h m5w"
          loading={this.state.loading_storico_diff}
          onClick={() => this.loadProjectDiff()}
        >
          Visualizza modifiche al progetto
        </Button>
        <Modal
          visible={this.state.show_storico_diff}
          cancelButtonProps={{ style: { display: "none" } }}
          width={500}
          destroyOnClose={false}
          onOk={() => this.setState({ show_storico_diff: false })}
          onCancel={() => this.setState({ show_storico_diff: false })}
        >
          {this.state.diff_storico_obj &&
          Object.keys(this.state.diff_storico_obj).length > 0 ? (
            <div>
              <h3>Modifiche riscontrate</h3>
              {Object.keys(this.state.diff_storico_obj).map((e) => {
                return this.sectiontMappingGetName(e) ? (
                  <div key={"section_project_" + e}>
                    <h4>{this.sectiontMappingGetName(e)} </h4>
                    <ul>
                      {Object.keys(
                        this.flattenObject(
                          this.state.diff_storico_obj[e],
                          filter
                        )
                      ).map(
                        (d, i, a) =>
                          this.propertyNameMapping(d) && (
                            <li key={"diff_storico_" + e + "_" + d + "_" + i}>
                              {this.propertyNameMapping(d)}{" "}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                ) : null;
              })}
            </div>
          ) : (
            <h3>Nessuna modifica</h3>
          )}
        </Modal>
      </React.Fragment>
    );
  }

  renderPrevEditionDiff() {
    let filter = (i) =>
      i !== "progetto" &&
      i !== "id" &&
      !i.startsWith("id_") &&
      !i.endsWith("_at");
    return (
      <React.Fragment>
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "manageProgetto",
          "istruttoreAmministrativoPermissions",
        ]) && (
          <Button
            size="default"
            icon="diff"
            type="primary"
            className="m5h m5w"
            loading={this.state.loading_edition_diff}
            onClick={() => this.loadPrevEditionDiff()}
          >
            Visualizza modifiche edizione precedente
          </Button>
        )}
        <Modal
          visible={this.state.show_edition_diff}
          cancelButtonProps={{ style: { display: "none" } }}
          width={500}
          destroyOnClose={false}
          onOk={() => this.setState({ show_edition_diff: false })}
          onCancel={() => this.setState({ show_edition_diff: false })}
        >
          {this.state.diff_edition_obj &&
          Object.keys(this.state.diff_edition_obj).length > 0 ? (
            <div>
              <h3>Modifiche riscontrate</h3>
              {Object.keys(this.state.diff_edition_obj).map((e) => {
                return this.sectiontMappingGetName(e) ? (
                  <div key={"section_project_" + e}>
                    <h4>{this.sectiontMappingGetName(e)} </h4>
                    <ul>
                      {Object.keys(
                        this.flattenObject(
                          this.state.diff_edition_obj[e],
                          filter
                        )
                      ).map(
                        (d, i, a) =>
                          this.propertyNameMapping(d) && (
                            <li key={"diff_edition_" + e + "_" + d + "_" + i}>
                              {this.propertyNameMapping(d)}{" "}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                ) : null;
              })}
            </div>
          ) : (
            <h3>Nessuna modifica</h3>
          )}
        </Modal>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="scheda">
        <Drawer
          title="Comunicazioni"
          width={1024}
          closable={true}
          onClose={() => {
            this.setState({
              show_peclog: false,
            });
          }}
          visible={this.state.show_peclog}
          destroyOnClose={true}
        >
          <ComunicazioniPecList
            id_progetto={this.state.dati ? this.state.dati.id : null}
            filter_by="progetto"
          />
        </Drawer>
        <Card
          style={{ marginBottom: "12px", backgroundColor: "#fff" }}
          bordered={false}
          className="shadow"
        >
          <h3 className="sectionTitle">
            {" "}
            {this.state.dati ? this.state.dati.titolo_progetto : ""}
          </h3>

          <p>
            Edizione
            {this.state.showNote && (
              <Tooltip
                placement="top"
                title="Il presente formulario deve essere debitamente compilato, rilegato con pagine numerate progressivamente, Sottoscritto dal Rappresentante legale dell’ente di formazione e/o dal capofila del Raggruppamento temporaneo. Nel caso di costituenda ATI/ATS, il formulario dovrà essere sottoscritto dai Rappresentanti legali di tutti i soggetti."
              >
                <Icon className="m5w" type="info-circle" />
              </Tooltip>
            )}
          </p>
        </Card>

        <div style={{ padding: "12px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Bacheca</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/affidamento/progetti/edizioni`}>Edizioni</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Compilazione scheda</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Row
          type="flex"
          className="ant-flex-margin"
          style={{ marginBottom: 12, marginTop: 6 }}
        >
          <Col style={{ flex: "1 1 70%" }}>
            <Row
              style={{ height: "100%", flexDirection: "column" }}
              type="flex"
            >
              <Card
                style={{ flex: "1 1 auto", marginBottom: 12 }}
                bordered={false}
              >
                {this.state.dati?.ente ? (
                  <h4>
                    <span style={{ color: "#000" }}>ENTE DI FORMAZIONE:</span>{" "}
                    {this.state.dati.ente.denominazione}
                  </h4>
                ) : (
                  ""
                )}
                {this.state.dati &&
                  this.state.dati?.stato <
                    affidamentoStatic.stato_progetto.presentato && (
                    <Button
                      icon="file-pdf"
                      className="m5h m5w"
                      type="primary"
                      loading={this.state.loading_riepilogo}
                      onClick={() => {
                        this.setState({ loading_riepilogo: true });
                        ModelServices.printPdf(
                          this.state.edizione_numero,
                          (res) => {
                            this.saveBlob(res.data);
                            this.setState({ loading_riepilogo: false });
                          },
                          (err) => {
                            this.setState({ loading_riepilogo: false });
                          }
                        );
                      }}
                    >
                      Scarica Domanda PDF
                    </Button>
                  )}
                {this.state.dati?.stato ===
                  affidamentoStatic.stato_progetto.predisposizione &&
                  this.state.dati?.stato_flusso >
                    affidamentoStatic.stato_flusso_progetto.predisposizione && (
                    <Popconfirm
                      placement="top"
                      title="Confermi di voler riportare la pratica in lavorazione?"
                      onConfirm={() => {
                        if (!this.state.loading_rollback_state) {
                          this.setState(
                            {
                              loading_rollback_state: true,
                            },
                            () =>
                              ModelServices.rollback(
                                this.state.edizione_numero,
                                (res) => {
                                  //this.refresh(true);
                                  this.refreshInline(res.data.data);
                                  this.setState({
                                    loading_rollback_state: false,
                                  });
                                },
                                () => {
                                  this.setState({
                                    loading_rollback_state: false,
                                  });
                                }
                              )
                          );
                        }
                      }}
                      okText="Si"
                      cancelText="No"
                    >
                      <Button
                        disabled={this.state.loading_rollback_state}
                        loading={this.state.loading_rollback_state}
                        className="m5h"
                        type="primary"
                        icon="undo"
                      >
                        Riporta in lavorazione
                      </Button>
                    </Popconfirm>
                  )}
                {this.state.dati &&
                  this.canViewProjectDifference() &&
                  this.renderStoricoDiff()}
                {this.state.dati &&
                  this.canViewDomandaDifference() &&
                  this.renderDomandaDiff()}
                {this.state.dati &&
                  this.canViewPrevEditionDifference() &&
                  this.renderPrevEditionDiff()}
              </Card>

              {checkPermission.hasPermission(this.props.UserStore.user, [
                "manageProgetto",
                "istruttoreAmministrativoPermissions",
                "entePermissions",
                "direttoreAmministrativoPermissions",
              ]) && (
                <PagamentoCard
                  idProgetto={this.props.match.params.id}
                  pagamenti={this.state.pagamenti}
                  rispostaPagamentoUrl={`${process.env.REACT_APP_BASE_URL}#${this.props.location.pathname}`}
                  oggetto="edizione"
                  isPrimaEdizione={this.state.isPrimaEdizione}
                  domandaJson={this.state.dati?.scheda_progetto_json}
                />
              )}

              <Card
                style={{ flex: "1 1 auto" }}
                bordered={false}
                className="shadow"
              >
                <Row>
                  <Col lg={24} sm={24} xs={24}>
                    <div id="actions">
                      <h4 className="p10h">Stato edizione</h4>
                      <p>
                        Stato:{" "}
                        <strong>
                          {this.state.dati
                            ? getStato(this.state.dati, this)
                            : ""}
                        </strong>
                      </p>
                      <p>
                        {this.state.dati
                          ? renderDescriptionText(this.state.dati, this)
                          : ""}
                      </p>
                      {this.state.loaded && (
                        <ActionsEdizione
                          {...this.props}
                          refresh={(reset) => this.refresh(reset)}
                          refreshInline={(data) => this.refreshInline(data)}
                          progetto={this.state.dati}
                          section_feedback={
                            this.state.dati
                              ? this.state.dati.section_feedback
                              : {}
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24} sm={24} xs={24}>
                    <div id="actions">
                      {checkPermission.hasRole(this.props.UserStore.user, [
                        "Ente",
                        "DirettoreAmministrativo",
                        '"IstruttoreAmministrativo',
                        "FunzionarioCoordinatoreAmministrativo",
                      ]) && (
                        <Button
                          icon="mail"
                          className="m5h m5w"
                          type="primary"
                          onClick={() => {
                            this.setState({ show_peclog: true });
                          }}
                        >
                          Lista Comunicazioni
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {checkPermission.hasPermission(this.props.UserStore.user, [
                    "manageRegistration",
                  ]) &&
                    this.state.dati?.stato_flusso ===
                      affidamentoStatic.stato_flusso_progetto
                        .registrazioni_aperte && (
                      <Button
                        icon="mail"
                        className="m5h m5w"
                        type="primary"
                        loading={this.state.loading_load_registration_data}
                        onClick={() =>
                          this.loadRegistrationData(this.state.dati?.id)
                        }
                      >
                        Compila la domanda di partecipazione
                      </Button>
                    )}
                </Row>
              </Card>
            </Row>
          </Col>
          {checkPermission.hasPermission(this.props.UserStore.user, [
            "entePermissions",
          ]) && this.editableState() ? (
            <Col style={{ flex: "1 1 30%" }}>
              <Card style={{ height: "100%" }} bordered={false}>
                <div style={{ textAlign: "center" }}>
                  <h4 className="p10h">Percentuale popolamento</h4>

                  <Progress
                    width={100}
                    type="circle"
                    percent={this.state.progress_obj.progress}
                  />
                  <p className="p30h p20w text-justify">
                    {this.state.progress_obj.progress !== 100
                      ? "Compila le sezioni del formulario fino al raggiungimento del 100%, clicca sul pulsante informazioni per avere dettagli sui dati mancanti"
                      : "Congratulazioni, la compilazione è completa, puoi procedere all'inoltro del progetto"}
                  </p>
                  {this.state.progress_obj.progress < 100 ? (
                    <Button
                      size="small"
                      icon="info"
                      type="primary"
                      loading={this.state.getting_info}
                      onClick={() => this.getInfoAndValidate()}
                    >
                      Informazioni
                    </Button>
                  ) : null}

                  <Button
                    size="small"
                    icon="reload"
                    type="primary"
                    style={{ marginLeft: 8 }}
                    ghost
                    loading={this.state.calculating_progress}
                    onClick={() => this.calculateProgress()}
                  >
                    Aggiorna
                  </Button>

                  <Modal
                    visible={this.state.show_errors}
                    cancelButtonProps={{ style: { display: "none" } }}
                    width={600}
                    destroyOnClose={false}
                    onOk={() => this.setState({ show_errors: false })}
                    onCancel={() => this.setState({ show_errors: false })}
                  >
                    {this.state.progress_obj.errors_translations ? (
                      <ul>
                        {Object.keys(
                          this.state.progress_obj.errors_translations
                        ).map((e) => {
                          if (
                            this.state.progress_obj.errors_translations[e]
                              .length > 0
                          ) {
                            return (
                              <div key={"section_" + e}>
                                <h4>{this.sectiontMappingGetName(e)}</h4>
                                <ul>
                                  {this.state.progress_obj.errors_translations[
                                    e
                                  ].map((err, i, a) => {
                                    return (
                                      <li key={"err_" + e + "_" + i}>
                                        <strong>{err[0]}</strong> {err[1]}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    ) : null}
                  </Modal>

                  <Modal
                    visible={this.state.show_completed}
                    cancelButtonProps={{ style: { display: "none" } }}
                    width={600}
                    destroyOnClose={false}
                    onOk={() => this.setState({ show_completed: false })}
                    onCancel={() => this.setState({ show_completed: false })}
                  >
                    <div>
                      Il formulario risulta completo e con tutte le informazioni
                      necessarie. Proseguire con il salvataggio dei dati per non
                      perdere le ultime informazioni immesse.
                    </div>
                  </Modal>
                </div>
              </Card>
            </Col>
          ) : null}
          {checkPermission.hasPermission(this.props.UserStore.user, [
            "assegnaProgetto",
          ]) &&
          this.state.dati &&
          this.state.dati.stato_fluso !==
            affidamentoStatic.stato_flusso_progetto.progetto_completato &&
          this.state.dati.stato_fluso !==
            affidamentoStatic.stato_flusso_progetto.conferma_revoca ? (
            <React.Fragment>
              <div style={{ padding: 6 }}>
                <AssegnazioneIstruttore
                  canAssignIstruttore={this.canAssignIstruttore()} //{this.state.dati.stato === affidamentoStatic.stato_progetto.presentato}
                  canChangeIstruttore={this.canChangeIstruttore()}
                  auditExists={
                    this.state.dati.audit &&
                    this.state.dati.audit.length > 0 &&
                    this.state.dati.audit.filter(
                      (a) => a.stato === auditStatic.stato.in_corso
                    )
                  }
                  assign={(id_user) => this.saveIstruttore(id_user)}
                  assigning={this.state.assigning}
                  progetto={this.state.dati}
                />
              </div>
            </React.Fragment>
          ) : null}
        </Row>
        <Card
          style={{ marginBottom: "12px", backgroundColor: "#fff" }}
          bordered={false}
          className="shadow"
        >
          {!this.state.loaded || !this.state.dati ? (
            <SpinnerLoading message="Caricamento dati..." />
          ) : (
            <div>
              <SchedaFormulario
                {...this.props}
                formulario={"progetto"}
                id_ente={this.state.dati.id_ente}
                isEditable={this.editableState()}
                domanda_json={this.state.dati.scheda_progetto_json}
                refresh={(reset) => this.refresh(reset)}
                refreshInline={(data) => this.refreshInline(data)}
                progress_obj={this.state.progress_obj}
                updateProgress={this.updateProgress.bind(this)}
                saveJson={(json) => this.save_json(json)}
                saving={this.state.saving}
                saved={this.state.saved}
                id_progetto={this.state.dati.id}
                section_feedback={this.state.dati.section_feedback}
                stato_flusso={this.state.dati.stato_flusso}
                canGiveSectionFeedback={this.canGiveSectionFeedback()}
                progetto={this.state.dati}
                showNote={this.state.showNote}
                refreshSedeSvolgimento={() => this.refreshSedeSvolgimento()}
                progetto_padre={this.state.progetto_padre || {}}
              />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default EdizioneBackofficeSingolaRoute;
