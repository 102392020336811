import React, { Component } from 'react';

import {
    Button, Drawer, Table, Tooltip
} from 'antd';


import EditMetodologie from './EditMetodologie';
import AttrezzatureTable from './AttrezzatureTable';


const columns = (component) => {
    let ret = [
        {
            title: 'Ciclo',
            dataIndex: 'annuity',
            key: 'annuity',
            render: (text, record) => (<div>{component.props.type_of_cicle}</div>)
        },
    ];

    if (!component.props.hiddenActions) {
      ret.push(
        {
          title: 'Azioni',
          dataIndex: '',
          sorter: false,
          key: 'azione',
          render: (text, record) => (

            <div>
              {!component.props.loading_data &&
                <React.Fragment>
                  <Tooltip placement="top" title={component.props.readOnly ? "Dettagli metodologie e risorse umane" : "Modifica metodologie e risorse umane"}>
                    <Button type={record.annuity ? 'default' : 'danger'} className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record.annuity, record, 'edit_metodologie')} />
                  </Tooltip>
                  <Tooltip placement="top" title="Organizzazione e logistica">
                    <Button className="m2w" size="small" shape="circle" icon="setting" onClick={() => component.open(record.annuity, record, 'edit_attrezzature')} />
                  </Tooltip>
                </React.Fragment>}

            </div>
          ),
        }
      )
    }
    return ret;
}

class Metodologie extends Component {

    state = {
        open_drawer_edit_metodologie: false,
        open_drawer_edit_attrezzature: false,
        element_opened: {},
        metodologieList: []
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loading: false,
            metodologieList: props.metodologieList
        });
    }

    update(data) {
        console.warn("Metodologie.js update called", data)
       let list = this.state.metodologieList;
        //var index = this.state.metodologieList.map(el => el.annuity).indexOf(this.state.annuity_opened);
        /*var index = this.state.annuity_opened;
        if(typeof list[index] !== 'undefined'){
            list[index].annuity = data.annuity
            //list[index].methods = data.methods
            list[index].organogramList = data.organogramList
            list[index].teacherList = data.teacherList
            list[index].specialUserList = data.specialUserList
        }*/
        if(data !== undefined){
            console.warn("Metodologie.js update called list 1", list)
            var index = list.length;
            list[index] = {
                annuity: data.annuity,
                organogramList: data.organogramList,
                teacherList: data.teacherList,
                specialUserList: data.specialUserList
            };
            /*list[index].annuity = data.annuity
            list[index].organogramList = data.organogramList
            list[index].teacherList = data.teacherList
            list[index].specialUserList = data.specialUserList*/
            console.warn("Metodologie.js update called list 2", list)
            this.props.saveMetodologieList(list)
        }
        this.setState(
            {
                //open_drawer_edit_metodologie: typeof data !== 'undefined' ? false : true,
                //open_drawer_edit_metodologie: true,
                metodologieList: list,
                //element_opened: {},
                //annuity_opened: null
            }, () => { this.props.saveMetodologieList(list) }
        );
    }

    closeDrawerEditMetodologie(childName){
        console.warn("Metodologie.js closeDrawerEditMetodologie called from", childName)
        this.setState({ open_drawer_edit_metodologie: false });
    }

    updateAttrezzature(data) {
        let list = this.state.metodologieList;
         var index = this.state.metodologieList.map(el => el.annuity).indexOf(this.state.annuity_opened);
                list[index].equipmentList = data.equipmentList
                list[index].audioVisualList = data.audioVisualList
         this.setState(
             {
                open_drawer_edit_attrezzature: false,
                 metodologieList: list,
                 element_opened: {},
                 annuity_opened: null
             }, () => { this.props.saveMetodologieList(this.state.metodologieList) }
         );
     }



    open(annuity, element, action) {
        this.setState({
            annuity_opened: annuity,
            element_opened: element
        }, () => this.setState({ ['open_drawer_' + action]: true }))
    }

    render() {
        return (
            <div>
                <Table
                    rowKey={(record) => record.annuity}
                    loading={this.props.loading}
                    columns={columns(this)}
                    dataSource={this.props.metodologieList}
            />
                <Drawer
                    title="METODOLOGIE E RISORSE UMANE COINVOLTE PER ANNUALITA'"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_metodologie: false, annuity_opened: null })}
                    visible={this.state.open_drawer_edit_metodologie}
                    width={window.innerWidth - (window.innerWidth/100*5)}
                    destroyOnClose={true}
                >
                    <EditMetodologie
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_metodologie: false })}
                        metodologie={this.state.element_opened}
                        annuity={this.state.annuity_opened}
                        onUpdate={(data) => {
                            this.update(data);
                        }}
                        onCloseDrawerEditMetodologie={ (childName) => this.closeDrawerEditMetodologie(childName) }
                        readOnly={this.props.readOnly}
                    />
                </Drawer>

                <Drawer
                    title="ORGANIZZAZIONE E LOGISTICA"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_attrezzature: false, annuity_opened: null })}
                    visible={this.state.open_drawer_edit_attrezzature}
                    width={window.innerWidth - (window.innerWidth/100*5)}
                    destroyOnClose={true}
                >
                    <AttrezzatureTable
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_attrezzature: false })}
                        metodologie={this.state.element_opened}
                        annuity={this.state.annuity_opened}
                        onUpdate={(data) => {
                            this.updateAttrezzature(data);
                        }}
                        readOnly={this.props.readOnly}
                    />
                </Drawer>

            </div>
        );
    }
}
export default Metodologie;