import BaseRequest from '@common/services/_baseRequest';


export default {

  list: (params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'anagrafica',
      cb,
      ecb
    );
  },

  search: (cf, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'anagrafica/search/' + cf,
      cb,
      ecb
    );
  },

  getUtentiByAnagrafica: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'anagrafica/find-utenti-by-anagrafica/' + id,
      cb,
      ecb
    );
  },

  view: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      'anagrafica/' + id,
      cb,
      ecb
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'put',
      'anagrafica/' + id,
      cb,
      ecb
    );
  },

  create: (params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'anagrafica',
      cb,
      ecb
    );
  },

  delete: (id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'anagrafica/' + id,
      cb,
      ecb
    );
  },

  addContatto: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'anagrafica/' + id + '/contatto',
      cb,
      ecb
    );
  },

  editContatto: (id_contatto, params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'put',
      'anagrafica/' + id + '/contatto/' + id_contatto,
      cb,
      ecb
    );
  },

  deleteContatto: (id, id_contatto, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'anagrafica/' + id + '/contatto/' + id_contatto,
      cb,
      ecb
    );
  },


  addInfoIspettore: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'anagrafica/' + id + '/info-ispettore',
      cb,
      ecb
    );
  },

  editInfoIspettore: (id_info, params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'put',
      'anagrafica/' + id + '/info-ispettore/' + id_info,
      cb,
      ecb
    );
  },

  deleteInfoIspettore: (id, id_info, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'anagrafica/' + id + '/info-ispettore/' + id_info,
      cb,
      ecb
    );
  },



  addIndirizzo: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'anagrafica/' + id + '/indirizzo',
      cb,
      ecb
    );
  },

  editIndirizzo: (id_indirizzo, params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'put',
      'anagrafica/' + id + '/indirizzo/' + id_indirizzo,
      cb,
      ecb
    );
  },

  deleteIndirizzo: (id, id_indirizzo, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'anagrafica/' + id + '/indirizzo/' + id_indirizzo,
      cb,
      ecb
    );
  },

  editDocumento: (data, id, id_documento, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'put',
      'anagrafica/' + id + '/documento/' + id_documento,
      cb,
      ecb
    );
  },

  addDocumentoMedia: (data = {}, id = null, id_documento = null, file = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      'post',
      'anagrafica/' + id + '/documento/' + id_documento + '/media',
      cb,
      ecb
    );
  },

  removeDocumentoMedia: (id, id_documento, id_media, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      {},
      'delete',
      'anagrafica/' + id + '/documento/' + id_documento + '/media/' + id_media,
      cb,
      ecb
    );
  },

  addEsperienzaFormativa: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'anagrafica/' + id + '/esperienza-formativa',
      cb,
      ecb
    );
  },

  deleteEsperienzaFormativa: (id, id_esperienza, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'anagrafica/' + id + '/esperienza-formativa/' + id_esperienza,
      cb,
      ecb
    );
  },

  addEsperienzaProfessionale: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      'post',
      'anagrafica/' + id + '/esperienza-professionale',
      cb,
      ecb
    );
  },

  deleteEsperienzaProfessionale: (id, id_esperienza, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'anagrafica/' + id + '/esperienza-professionale/' + id_esperienza,
      cb,
      ecb
    );
  },


  getOreDiCorso: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'anagrafica/' + id + '/ore-di-corso',
      cb,
      ecb
    );
  },


}
