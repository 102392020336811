import React, { Component } from 'react';
import {connect} from 'react-redux';

import FileSaver from 'file-saver';

import { Row, Col, Button, Table, Tooltip, Popconfirm,
Modal } from 'antd';

import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';


import format from '@common/utils/formatters/_';


import InfrastrutturaServices from '@accreditamento/services/Infrastruttura';
import CreateAttrezzatura from '@accreditamento/forms/completeRequest/infrastruttura/attrezzature/Create';
import UpdateAttrezzatura from '@accreditamento/forms/completeRequest/infrastruttura/attrezzature/Update';


const getTipoAula = (component, record) => {
  try {
    let type = format.replaceStub(record.id_tab_tipo_aula, 'tipo_aula', component.props.GlobalStore)

    return type;
  } catch(e) {
    console.error(e)
    return 'errore'
  }
}

const columns = (component) => {
  return [{
        title: 'Aula',
        dataIndex: 'aula',
        render: (text, record) => (<div style={{width: 150}}>{
          record.aula.nome_aula + " - " + getTipoAula(component, record.aula)
        }
        </div>),
    }, {
        title: 'Tipologia',
        dataIndex: 'tipologia',
        render: (text, record) => (<div style={{width: 120}}>{
          format.replaceStub(record.tipologia, 'tipo_attrezzatura', component.props.GlobalStore )
        }
        </div>),
    }, {
        title: 'Quantità',
        dataIndex: 'quantita',
        //key: 'quantita',
        render: (text, record) => (<div style={{width: 80}}>{record.quantita}</div>)
    }, {
        title: 'Mesi di disponibilità',
        dataIndex: 'mesi_disponibilita',
        //key: 'mesi_disponibilita',
        render: (text, record) => (<div style={{width: 80}}>{record.mesi_disponibilita}</div>)

    },
    {
        title: 'Azioni',
        dataIndex: 'azione',
        key: 'azione',
        render: (text, record) => (
            <div style={{width: 80}}>
                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                  <Button type={component.props.accreditamento.progress && component.props.accreditamento.progress.errors.attrezzature && component.props.accreditamento.progress.errors.attrezzature.find(el => el === record.id) ? 'danger' : 'default'} 
                  className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.editAttrezzatura(record)} />
                </Tooltip>
                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questa aula?"
                onConfirm={() => component.deleteAttrezzatura(record.id)} okText="Si" cancelText="No">
                  <Button disabled={component.props.readOnly} loading={component.state.deleting_id === record.id} className="m2w" size="small" shape="circle" icon="delete" />
                </Popconfirm>
            </div>
        ),
    }]
}


const uploadFormLayout = (component) =>{
return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'simple_upload',
                name: 'dragger',
                accept: '.p7m,.pdf',
                action: '/', // url upload
                listType: 'text', // text,picture,picture-card
                multiple: false,
                fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
                customRequest: 'customUploadRequest', // sovrascrive upload
                onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
                text: "Carica lista p7m o pdf firmato",
                props: {
                  disabled: component.props.readOnly,
                  showUploadList: false
                }
              },
            ],
          },
        ],
      },
    ],
    onSubmit: 'submitted',
  };
}

class Attrezzature extends Component {

  state = {
    loading: false,
    attrezzature: [],
    show_add: false,
    adding: false,
    show_edit: false,
    editing: false,
    editing_element: null,
    deleting: false,
    deleting_id: null,
    media_file: null
  };

  componentDidMount() {
    this.setState({loading: true, media_file: this.props.sede.media.find(el => el.tipo.sottodominio === 'autocertificazione_attrezzature') });
    setTimeout(()=>{
      InfrastrutturaServices.listAttrezzatura(
        this.props.accreditamento.id, this.props.sede.enteSede.id, (res) => {
        this.setState({loading: false, attrezzature: res.data.data })
      }, () => this.setState({loading: false}) )
    }, 200)

  }

  createAttrezzatura(data, file){
    if(!this.state.adding) {
      //console.log('aggiungo', data, file)
      this.setState({loading: true, adding: true});

      InfrastrutturaServices.addAttrezzatura(
        this.props.accreditamento.id,
        this.props.sede.enteSede.id,
        data,
        file,
        (res) => {
          if(this.props.onAdd) this.props.onAdd(res.data.data);
          this.setState({
            loading: false,
            adding: false,
            show_add: false,
            attrezzature: [...this.state.attrezzature, res.data.data]
          })
      }, () => this.setState({loading: false, adding: false}) )

    }
  }

  editAttrezzatura(element) {
    this.setState({
      show_edit: true,
      editing_element: element
    })
  }

  updateAttrezzatura(data, file){
    if(!this.state.editing) {
      //console.log('aggiorno', data, file)
      this.setState({loading: true, editing: true});

      InfrastrutturaServices.editAttrezzatura(
        this.props.accreditamento.id,
        this.props.sede.enteSede.id,
        this.state.editing_element.id,
        data,
        file,
        (res) => {
          if(this.props.onEdit) this.props.onEdit(res.data.data);
          this.setState({loading: false, editing: false, show_edit: false,
            attrezzature: this.state.attrezzature.map(el => {
            return (el.id === this.state.editing_element.id) ? res.data.data : el
          })
        })
      }, () => this.setState({editing: false, loading: false}) )

    }
  }

  deleteAttrezzatura(id) {
    if(!this.state.deleting) {

      this.setState({loading: true, deleting: true, deleting_id: id});
      InfrastrutturaServices.deleteAttrezzatura( this.props.accreditamento.id, this.props.sede.enteSede.id, id, () => {
        if(this.props.onDelete) this.props.onDelete(id);
        this.setState({loading: false, deleting: false, deleting_id: null, attrezzature: this.state.attrezzature.filter(el=>el.id !== id)})
      }, () => this.setState({loading: false, deleting: false, deleting_id: null }) )

    }

  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "attrezzature.pdf");

    this.setState({loading: false, downloading: false});

  }

  uploadAttrezzature(file) {
    if(!this.state.loading) {
      this.setState({
        loading: true
      }, () => {
        InfrastrutturaServices.uploadAttrezzature(
          this.props.accreditamento.id,
          this.props.sede.id_ente_sede, {
          filename: 'file'
        }, { file: file, name: file.name, chiave: 'file'}, (res) => {

          if(this.props.onAdd) this.props.onAdd({})
          this.setState({ loading: false, media_file: res.data.data });

        }, () => this.setState({ loading: false }))
      })

    }
  }

  render() {
    console.warn("Attrezzature.js this.props.readOnlyDocumenti", this.props.readOnlyDocumenti)
    return (
      <div>
        <Table
        columns={columns(this)}
        loading={this.state.loading}
        dataSource={this.state.attrezzature}
        rowKey={(record)=>record.id}
        />

        {
          this.props.readOnly !== true &&
          <Button
            style={{marginTop: 16}}
            onClick={()=>this.setState({show_add: !this.state.show_add})}
            type='primary'
            disabled={this.props.readOnly}
          >
            Inserisci una nuova attrezzatura
          </Button>
        }

        {
          this.props.readOnly !== true &&
          <>
          <h4 style={{marginTop: 16}}>Autocertificazione</h4>
          <p>Quando ritieni di aver completato l&apos;inserimento delle attrezzature, scarica il file con la lista, firmalo digitalmente e ricaricalo per autocertificare i dati inseriti</p>
          <Row>
            <Col span={6}>
              <Button
                loading={this.state.downloading}
                disabled={this.state.loading || this.props.readOnly}
                className="m5h" type="primary" icon="file" onClick={() => {
                  if(!this.state.loading) {
                    this.setState({
                      loading: true,
                      downloading: true
                    }, () => {

                      InfrastrutturaServices.downloadAttrezzature(
                        this.props.accreditamento.id,
                        this.props.sede.id_ente_sede,
                        (res) => this.saveBlob(res.data),
                        () => this.setState({loading: false, downloading: false})
                      )

                    })
                  }

                }}>Scarica lista</Button>
            </Col>
            <Col span={6}>
              <CommonForm
                wrappedComponentRef={inst => (this.formRef = inst)}
                loading={this.state.loading || false}
                form_model={uploadFormLayout(this)}
                values={{
                }}
                readOnly={this.props.readOnly}
                fileListProp={() => []}
                customUploadRequest={obj => {
                  this.uploadAttrezzature(obj.file);
                }}
              />
              {
                this.state.media_file ?
                  <div key={this.state.media_file.id}><p style={{ display: 'inline-block' }}>lista firmata: </p><DocSpan key={this.state.media_file.id} file={this.state.media_file} /></div>
                : null
              }
            </Col>
          </Row>
          </>       
        }
        
        <Modal
          visible={this.state.show_add}
          onOk={() => this.setState({show_add: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={800}
          destroyOnClose={true}
          onCancel={()=>this.setState({show_add: false})}
        >
          <CreateAttrezzatura
            loading={this.state.adding}
            GlobalStore={this.props.GlobalStore}
            accreditamento={this.props.accreditamento}
            sede={this.props.sede}
            onSubmit={(data, file)=>this.createAttrezzatura(data, file)}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
          />
        </Modal>
        <Modal
          visible={this.state.show_edit}
          onOk={() => this.setState({show_edit: false})}
          okButtonProps={{style: {display: 'none'}}}
          width={800}
          destroyOnClose={true}
          onCancel={()=>this.setState({show_edit: false})}
        >
          <UpdateAttrezzatura
            loading={this.state.editing}
            GlobalStore={this.props.GlobalStore}
            values={this.state.editing_element}
            sede={this.props.sede}
            accreditamento={this.props.accreditamento}
            onSubmit={(data, file)=>this.updateAttrezzatura(data, file)}
            readOnly={this.props.readOnly}
            readOnlyDocumenti={this.props.readOnlyDocumenti ? this.props.readOnlyDocumenti : false}
          />
        </Modal>
      </div>
    );
  }
}
export default connect(({GlobalStore})=>({GlobalStore}))(Attrezzature);