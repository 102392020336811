import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Button, Card, Drawer, Popconfirm, message } from "antd";
import DownloadUpload from "@common/components/DownloadUpload";
import ModelServices from "../../services/CommissioneEsami";
import CommonForm from "@common/components/form";
import moment from "moment";
import eventBroker from "@common/utils/services/EventBroker";

import IstruttoriList from "@modules/utente/components/ListIstruttori";
import ListPresidentiCommissioneEsame from "@modules/utente/components/ListPresidentiCommissioneEsame";
import FileSaver from "file-saver";
import checkPermission from "@common/utils/check/permissions";

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "date",
                type: "title",
                element: "h4",
                text: "Seleziona le date",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "select",
                mode: "multiple",
                label: "seleziona",
                name: "date",
                options_props: "dateScelte",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit_dati_generali",
                text: "Salva i dati",
                //onClick: 'showDrawer',
                props: {
                  icon: "save",

                  className: "bg-green",
                },
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submit",
  };
};

const istruttori_table = (component_list, component_assegnazione) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: "Nome",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "nome",
        render: (element) => (
          <div>{element.anagrafica ? element.anagrafica.nome : ""}</div>
        ),
      },
      {
        title: "Cognome",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "cognome",
        render: (element) => (
          <div>{element.anagrafica ? element.anagrafica.cognome : ""}</div>
        ),
      },
      {
        title: "Email",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "email",
        render: (element) => <div>{element.email}</div>,
      },
      {
        title: "Pratiche assegnate",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: "pratiche",
        //  JayBee_BugFix_#4809
        render: (element) => <div>{element.count_dossier || 0}</div>,
      },
      {
        title: "",
        dataIndex: "",
        sorter: false,
        key: "created_at",
        render: (element) => (
          <div style={{ width: 120 }}>
            <Popconfirm
              placement="top"
              title="Confermi di voler assegnare la pratica a questo istruttore?"
              onConfirm={() => {
                component_assegnazione.assign(element.id);
              }}
              okText="Si"
              cancelText="No"
            >
              <Button
                disabled={component_assegnazione.props.assigning}
                loading={component_assegnazione.props.assigning}
                className="m5h"
                type="primary"
                icon="save"
                size="small"
              >
                Assegna la pratica
              </Button>
            </Popconfirm>
          </div>
        ),
      },
    ],
  };
};

const presidenti_table = (component_list, component_assegnazione) => {
  return {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: "Nome",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "nome",
        render: (element) => (
          <div>{element.anagrafica ? element.anagrafica.nome : ""}</div>
        ),
      },
      {
        title: "Cognome",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "cognome",
        render: (element) => (
          <div>{element.anagrafica ? element.anagrafica.cognome : ""}</div>
        ),
      },
      {
        title: "Email",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "email",
        render: (element) => <div>{element.email}</div>,
      },
      {
        title: "Pratiche assegnate",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        key: "pratiche",
        //  JayBee_BugFix_#4809
        render: (element) => <div>{element.count_dossier || 0}</div>,
      },
      {
        title: "",
        dataIndex: "",
        sorter: false,
        key: "created_at",
        render: (element) => (
          <div style={{ width: 120 }}>
            <Popconfirm
              placement="top"
              title="Confermi di voler assegnare la pratica a questo presidente?"
              onConfirm={() => {
                component_assegnazione.assign(element.id);
              }}
              okText="Si"
              cancelText="No"
            >
              <Button
                disabled={component_assegnazione.props.assigning}
                loading={component_assegnazione.props.assigning}
                className="m5h"
                type="primary"
                icon="save"
                size="small"
              >
                Assegna
              </Button>
            </Popconfirm>
          </div>
        ),
      },
    ],
  };
};

class AssegnazioneIstruttore extends Component {
  state = {
    loaded: false,
    istruttoria_progetti_coordinatore: null,
    visible:
      this.props.progetto.istruttoria_progetti_coordinatore != null
        ? false
        : true,
    progetto: {
      id: null,
    },
    assigning: false,
    open_modal_istruttori: false,
    date_commissione: checkPermission.hasRole(
      this.props.UserStore.user,
      "FunzionarioCoordinatoreAmministrativo") ? [
        this.props.progetto.scheda_progetto_json?.generalData?.AfcProposedDate?.first
          .date || [],
        this.props.progetto.scheda_progetto_json?.generalData?.AfcProposedDate?.second.date || [],
        this.props.progetto.scheda_progetto_json?.generalData?.AfcProposedDate?.third
          .date || [],
      ] : [] ,
    date: null,
  };

  componentDidMount() {
    if (this.props.progetto.id) this.loaded(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.progetto !== this.props.progetto) {
      this.loaded(this.props);
    }
  }

  loaded(props) {
    this.setState({ progetto: props.progetto, loaded: true });
  }

  async assign(id) {
    let savedIstruttore = await this.props.assign(id);
    if (savedIstruttore)
      message.success("L'istruttore è stato assegnato correttamente");
    this.state.open_modal_istruttori = !savedIstruttore;
  }

  getUserUrl(item) {
    return "/istruttore/" + item.id;
  }

  submit(date) {
    this.setState(
      {
        loading: true,
        istruttoria_progetti_coordinatore: {
          ...this.state.istruttoria_progetti_coordinatore,
          date,
        },
      },
      () => {
        ModelServices.save_istruttoria_coordinatore(
          this.state.istruttoria_progetti_coordinatore,
          this.state.progetto.id,
          (res) => {
            message.success("Dati salvati con successo");
            //this.updateProgress(res.data.data.progress);
            //this.refresh(false);
            eventBroker.trigger("reloadOnSectorChange");
            this.setState({
              date: res.data.data,
              progress_obj: res.data.data.progress,
              loading: false,
              visible: false,
            });
          },
          () => {
            this.setState({
              loading: false,
              visible: true,
            });
          }
        );
      }
    );
  }

  uploadFile(file) {
    this.setState(
      {
        file: { file: file, name: file.name },
        filename: "file",
      },
      () => this.upload()
    );
    return;
  }

  upload() {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          uploading: true,
        },
        () => {
          ModelServices.upload(
            this.state.progetto.id,
            {
              filename: "file",
              note: this.state.note || "",
            },
            { ...this.state.file, chiave: "file" },
            (res) => {
              message.success(
                "La dichiarazione è stata caricata correttamente"
              );
              this.setState({
                loading: false,
                uploading: false,
                istruttoria_progetti_coordinatore: {
                  attachments: {
                    mainSection: res.data.data.media.filter(
                      (f) =>
                        f.tipo.sottodominio == "coordinatore_lettera_incarico"
                    ),
                  },
                  coordinatore: this.props.UserStore.user || [],
                  presidente: this.props.progetto.presidente || [],
                },
              });
            }
          );
        }
      );
    }
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], {
      type: "application/vnd.oasis.opendocument.pdf",
    });
    FileSaver.saveAs(_blob, "Nomina_Presidente_Commissione_esame_di_qualifica.odt");

    this.setState({
      loading: false,
      downloading: false,
    });
  }

  render() {
    return this.state.loaded ? (
      <div style={{ height: "100%" }}>
        <Card bordered={false}>
          <h4>Assegnazioni</h4>

          {this.props.canAssignIstruttore
           ? (
            <div>
              <p style={{ marginBottom: 0 }}>
                Devi assegnare la pratica a un istruttore
              </p>
              <Button
                className="m5h"
                style={{ marginRight: 8 }}
                type="primary"
                icon="user-add"
                onClick={() => this.setState({ open_modal_istruttori: true })}
              >
                Cerca un istruttore
              </Button>
            </div>
          ) : null}

          {this.props.canAssignPresidente ? (
            <div>
              <p style={{ marginBottom: 0 }}>
                Devi assegnare la pratica ad un presidente di commissione
              </p>
              <Button
                className="m5h"
                style={{ marginRight: 8 }}
                type="primary"
                icon="user-add"
                onClick={() => this.setState({ open_modal_presidente: true })}
              >
                Cerca un presidente di commissione
              </Button>
            </div>
          ) : null}

          {this.state.progetto.presidente &&
            checkPermission.hasRole(
              this.props.UserStore.user,
              "FunzionarioCoordinatoreAmministrativo"
            ) &&
            this.state.progetto.presidente.length > 0 &&
            this.state.visible && (
              <div>
                <DownloadUpload
                  accept={".p7m,.pdf"}
                  downloading={this.state.downloading}
                  uploading={this.state.uploading}
                  download={() => {
                    this.setState({ downloading: true }, () => {
                      ModelServices.downloadAutorizzazione2(
                        this.props.progetto.id,
                        (res) => {
                          this.saveBlob(res.data);
                        }
                      );
                    });
                  }}
                  upload={(file) => this.uploadFile(file)}
                ></DownloadUpload>
                <CommonForm
                  wrappedComponentRef={(inst) => (this.formRef = inst)}
                  loading={this.props.saving || this.state.loading || false}
                  form_model={formLayout(this)}
                  readOnly={false}
                  submit={(data) => this.submit(data)}
                  dateScelte={
                    this.state.date_commissione.map((el) => {
                      return {
                        key: el,
                        label: moment(el, moment.ISO_8601).format("DD/MM/YYYY"),
                        value: el,
                      };
                    }) || []
                  }
                />
              </div>
            )}

          {(this.state.progetto.istruttore &&
            this.state.progetto.istruttore.length > 0) ||
          (this.state.progetto.rup && this.state.progetto.rup.length > 0) ? (
            <div>
              {this.state.progetto.rup &&
                this.state.progetto.rup.length > 0 && (
                  <p>
                    Pratica assegnata al RdP:{" "}
                    {this.state.progetto.rup.map((i) => (
                      <strong key={i.id}>{`${i.anagrafica.cognome} ${
                        i.anagrafica.nome
                      } ${i.username ? "(" + i.username + ")" : ""}`}</strong>
                    ))}
                  </p>
                )}
              {this.state.progetto.istruttore &&
                this.state.progetto.istruttore.length > 0 && !this.state.progetto.presidente?.length > 0 &&  (this.state.progetto.istruttore != this.state.progetto.presidente) && (
                  <p>
                    Pratica assegnata all&apos;istruttore:{" "}
                    {this.state.progetto.istruttore.map((i) => (
                      <strong key={i.id}>{`${i.anagrafica.cognome} ${
                        i.anagrafica.nome
                      } ${i.username ? "(" + i.username + ")" : ""}`}</strong>
                    ))}
                  </p>
                )}
              {this.state.progetto.coordinatore &&
                this.state.progetto.coordinatore.length > 0 && (
                  <p>
                    Pratica assegnata al funzionario coordinatore:{" "}
                    {this.state.progetto.coordinatore.map((i) => (
                      <strong key={i.id}>{`${i.anagrafica.cognome} ${
                        i.anagrafica.nome
                      } ${i.username ? "(" + i.username + ")" : ""}`}</strong>
                    ))}
                  </p>
                )}
              {this.state.progetto.presidente &&
                this.state.progetto.presidente.length > 0 && (
                  <p>
                    Pratica assegnata al presidente di commissione:{" "}
                    {this.state.progetto.presidente.map((i) => (
                      <strong key={i.id}>{`${i.anagrafica.cognome} ${
                        i.anagrafica.nome
                      } ${i.username ? "(" + i.username + ")" : ""}`}</strong>
                    ))}
                  </p>
                )}
              {this.props.canChangeIstruttore ? (
                this.props.auditExists ? (
                  <Popconfirm
                    placement="top"
                    title="Cambiando istruttore in questa fase, con una istruttoria in corso, questa verrà sospesa e il nuovo istruttore dovrà iniziarne una nuova, confermi la scelta?"
                    onConfirm={() =>
                      this.setState({ open_modal_istruttori: true })
                    }
                    okText="Si"
                    cancelText="No"
                  >
                    <Button
                      className="m5h"
                      type="primary"
                      icon="sync"
                      size="small"
                    >
                      Cambia istruttore
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    className="m5h"
                    type="primary"
                    icon="sync"
                    size="small"
                    onClick={() =>
                      this.setState({ open_modal_istruttori: true })
                    }
                  >
                    Cambia istruttore
                  </Button>
                )
              ) : null}
            </div>
          ) : null}
        </Card>

        <Drawer
          title="Cerca un istruttore"
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({ open_modal_istruttori: false });
          }}
          visible={this.state.open_modal_istruttori}
          width={1024}
        >
          <IstruttoriList
            {...this.props}
            base_url={"/affidamento/progetto/" + this.state.progetto.id}
            table_model={(component) => istruttori_table(component, this)}
          />
        </Drawer>

        <Drawer
          title="Cerca presidente di commissione"
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({ open_modal_presidente: false });
          }}
          visible={this.state.open_modal_presidente}
          width={1024}
        >
          <ListPresidentiCommissioneEsame
            {...this.props}
            base_url={
              "/affidamento/commissione-esame/" + this.state.progetto.id
            }
            table_model={(component) => presidenti_table(component, this)}
          />
        </Drawer>
      </div>
    ) : null;
  }
}

const AssegnazioneExport = connect(({ UserStore, GlobalStore }) => ({
  UserStore,
  GlobalStore,
}))(AssegnazioneIstruttore);

export default withRouter(AssegnazioneExport);
