import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';

import ModelServices from '../services/Media';
import SchedaAllegato from '../components/SchedaAllegato';
import SpinnerLoading from '@components/SpinnerLoading';

@Wrapper({ route: 'DettaglioAllegatoRoute', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['adminPermissions'])
class DettaglioAllegatoRoute extends Component {

  state = {
    loading: false,
    data: null,
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true }, () => {
      this.loadDetail();
    });
  }

  loadDetail() {
    ModelServices.detail({ 
      expand:['modello'].join(',')
    }, this.props.match.params.id,
      res => {
        this.setState(
          {
            data: res.data.data,
            loading: false
          },
        );
      },
      () => {
        this.setState({ loading: false });
      }
    )
  }

  renderTitle() {
    return 'Dettaglio allegato';
  }

  refresh() {
    this.loadDetail();
  }

  refreshInline(data) {

    this.setState({
      data: { ...this.state.data, ...data }
    });
  }


  render() {

    return (
      <div className="scheda">
        {this.state.loading || !this.state.data ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/allegati">Allegati</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <SchedaAllegato {...this.props} refresh={() => this.refresh()} refreshInline={(data) => this.refreshInline(data)} allegato={this.state.data} progress_obj={this.state.data?.progress || { progress: 0, valid_sections: [] }} />
          </>
        }
      </div>
    );
  }
}

export default DettaglioAllegatoRoute;
