import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import RisorseUmaneTable from './RisorseUmaneTable'

const formLayout = (component) => {
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Numero Decreto autorizzativo all\'erogazione del corso',
                                name: 'decretoAutorizzativoNumero',
                                type_string: 'text',
                                props: {
                                    disabled: (component.props && component.props.initialValues && component.props.initialValues.decretoAutorizzativoNumero && component.props.initialValues.decretoAutorizzativoNumero !== null) ? true : false
                                },
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'datepicker',
                                format: 'DD/MM/YYYY',
                                label: 'Data Decreto autorizzativo all\'erogazione del corso',
                                name: 'decretoAutorizzativoData',
                                props: {
                                    disabled: (component.props && component.props.initialValues && component.props.initialValues.decretoAutorizzativoData) ? true : false
                                },
                                validations: [
                                    {
                                      type: 'required',
                                      error_message: 'Campo obbligatorio',
                                    }
                                ]
                            }
                        ],
                    },
                ],
            },
            {
                cols: 
                (component.props && component.props.initialValues && component.props.initialValues.decretoAutorizzativoData && component.props.initialValues.decretoAutorizzativoNumero && component.props.initialValues.decretoAutorizzativoNumero !== null)
                ? [] 
                :
                [
                    {
                        items: [
                            {
                                name: 'btn',
                                type: 'submit',
                                text: 'Salva informazioni su Decreto autorizzativo all\'erogazione del corso',
                                btn_type: 'primary',
                                props: {
                                    readOnly: (component.props && component.props.initialValues && component.props.initialValues.decretoAutorizzativoData && component.props.initialValues.decretoAutorizzativoData !== null&& component.props.initialValues.decretoAutorizzativoNumero && component.props.initialValues.decretoAutorizzativoNumero !== null) ? true : false
                                },
                            },
                        ],
                    },
                ],
            }

        ],
        onSubmit: 'submitted',
    }
}

class FormDecreto extends Component {

    state = {
        loaded: false,
        loading: false,
        loadingTable: false,
        metodologie: {}
    };

    componentDidMount() {
        this.load(this.props)
    }



    load(props) {
        this.setState({
            loaded: true,
            metodologie: props.metodologie
        });
        //this.props.onUpdate(props.metodologie);
    }


    submit(data) {
        if(this.props.onSubmit){
            this.props.onSubmit(data);
        }
    }

    render() {
        return (
            <div>
                <CommonForm
                    loading={this.props.loading || false}
                    form_model={formLayout(this)}
                    values={this.props.initialValues}
                    readOnly={this.props.readOnly}
                    submitted={(data) => this.submit(data)}
                />
            </div>
        )
    }
}

export default FormDecreto;