import { Tabs, Card, Row, Col } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';

import checkPermission from '@common/utils/check/permissions';
import DatiGeneraliForm from "@affidamento/forms/formulario/DatiGeneraliForm";


import SedeService from '@accreditamento/services/Sede';
import EnteService from '@accreditamento/services/Ente';
import SpinnerLoading from "@common/components/SpinnerLoading";

import affidamentoStatic from '@common/utils/static/affidamento';
import CalendarioCorsi from "../registro/CalendarioCorsi";
import TabellaDiscenti from "../registro/TabellaDiscenti";
import PannelloDettaglioCorso from "./PannelloDettaglioCorso";

const TabPane = Tabs.TabPane;


export default class DettaglioCorso extends Component {

  static propTypes = {
    isEditable: PropTypes.bool,
  }

  state = {
    current_key: "1",
    lesson: null,
    loading: false,
    scheda_progetto_json: this.props.corso?.scheda_progetto_json,
  }

  componentDidMount() {
      this.setState({loading: true},
        ()=>{
        if(this.props.corso)
            EnteService.macrotipologieAccreditate({}, this.props.corso.id_ente, res=>{
                this.setState({
                    loading: false,
                    macrotipologie: res?.data.data,
                })
        }, ()=>this.setState({loading: false}))
      });
  }


  loadProvinceSediAccreditate(id_macrotipologia) {
    this.updateSelectedMacrotipologia(id_macrotipologia);

    SedeService.getProvinceSediAccreditate(id_macrotipologia, this.props.corso.id_ente, this.props.id_progetto, (res) => {
      this.setState({
        province_accreditate: res.data.data,
        loaded_sedi: true,
      });
    }, () => this.setState({
      loaded_sedi: true,
    }))
  }





  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  render() {
    return (<div style={{ marginTop: 12, marginBottom: 12 }}>

      <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
        {checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione']) &&
          <Col style={{ flex: "1 1 70%" }} >
            <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
              {this.state.loading ? <SpinnerLoading /> :
                <PannelloDettaglioCorso {...this.props}/>
              }
            </Row>
          </Col>
        }
      </Row>

      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={(k) => this.setState({ current_key: "" + k })}
            defaultActiveKey={"1"}
            activeKey={this.state.current_key}
            size="medium"
            renderTabBar={this.renderTabBar}>
            <TabPane
              tab={<span>Dettagli</span>} key="1">
              <div id="dettagli">
                {this.state.scheda_progetto_json &&
                  <DatiGeneraliForm
                    {...this.props}
                    generalData={this.state.scheda_progetto_json.generalData}
                    teachingProgramming={this.state.scheda_progetto_json.teachingProgramming}
                    macrotipologie={this.state.macrotipologie}
                    readOnly={true}
                    id_ente={this.props.corso?.id_ente}
                    saved={true}
                    loadProvinceSediAccreditate={(data) => this.loadProvinceSediAccreditate(data)}
                  />
                }
              </div>
            </TabPane>
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione']) &&
              <TabPane
                tab={<span className={this.props.corso?.countLezioni > 0 ? 'checked' : 'default'}>Calendario<br /></span>} key="2">
                <div id="calendario">
                  <CalendarioCorsi
                    {...this.props}
                    tab={this.state.current_key}
                    id_corso={this.props.match.params.id}
                    isEditableState={false}
                    />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewDiscentiPerProgetto']) &&
              <TabPane
                tab={<span className={this.props.corso?.countIscritti > 0 ? 'checked' : 'default'}>Discenti</span>} key="4">
                <div id="discenti">
                  <TabellaDiscenti
                      ref={ref => this.tabellaRef = ref}
                      {...this.props}
                      isEditableState={false}
                      filterStatus={[affidamentoStatic.stato_iscrizione.iscritto, affidamentoStatic.stato_iscrizione.attesa_giustificativo, affidamentoStatic.stato_iscrizione.espulso].join(',')}
                      fields={[]}
                      renderActions={[]}
                    />

                </div>
              </TabPane>
            }

          </Tabs>
        </StickyContainer>
      </Card>
    </div>)
  }
}

