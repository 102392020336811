import React, { Component } from 'react';
import { connect } from 'react-redux';
import allegatiStatic from '@common/utils/static/allegati'

import { Row, Col, message } from 'antd';

import formatter from '@common/utils/formatters/_';
import CommonForm from '@common/components/form';
import Documenti from '@common/components/Documenti';

const formLayoutRequisiti = {
    layout: 'vertical',
    multistep: false,
    rows: [
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Nulla osta igienicosanitario rilasciato dall’ASL per la rispondenza dei locali all’uso didattico',
                            name: 'nulla_osta_tecnico_sanitario',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Certificato di collaudo statico o perizia giurata redatta da un tecnico abilitato',
                            name: 'collaudo_statico',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Certificato di prevenzione incendi rilasciata dai VVFF obbligatoria per le strutture che prevedono la presenza contemporanea di un numero di persone maggiore o uguale a 100',
                            name: 'certificato_prevenzione_incendi',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Dichiarazione di conformità degli impianti e delle attrezzature ai sensi della L.46/90 e del D.P.R. 380/2001 rilasciata dalle ditte installatrici',
                            name: 'copia_conforme_collaudo',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Perizia giurata rilasciata da tecnico abilitato contenente: 1) dichiarazione di conformità dell’immobile alle leggi sull’abbattimento delle barriere architettoniche; 2) conformità alle norme di sicurezza; 3) destinazione d’uso e superficie di ogni locale; 4) numero allievi per singola aula, elaborati grafici completi di arredi ed attrezzature, per aula didattica, aula informatica e/o laboratorio',
                            name: 'perizia_giurata',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Certificato di agibilità rilasciato dal comune in cui è ubicata la sede operativa (o copia della Segnalazione Certificata di Agibilità redatta da un professionista abilitato e presentata al SUAP del Comune)',
                            name: 'relazione_attrezzature',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'Visura catastale',
                            name: 'certificato_catastale',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'submit',
                            name: 'btn',
                            text: 'Conferma',
                            props: {
                                icon: 'check',
                                className: 'bg-green'
                            }
                        },
                    ],
                },
            ],
        },
    ],
    onSubmit: 'onSubmit',
}

const formLayoutNormativa = {
    layout: 'vertical',
    multistep: false,
    rows: [
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',                                    
                            label: 'Documento sulla sicurezza ai sensi del D. Lgs. N° 81/2008 contenente: -Relazione sulla valutazione dei rischi; -Individuazione delle misure di prevenzione e protezione e delle procedure di sicurezza, nomina del responsabiledella sicurezza che deve essere in possesso dell’attestato previsto dalla normativa vigente al momento della domanda',
                            name: 'documento_sicurezza',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'nomina del Responsabile del Servizio Prevenzione e Protezione e dei relativi addetti corredata di attesto',
                            name: 'comunicazione_responsabile',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'radio',
                            label: 'nomina del medico e documento attestante accettazione della nomina da parte di quest’ultimo con sottoscrizione completa di firma e timbro ',
                            name: 'comunicazione_medico',
                            onChange: 'ch',
                            option_type: 'button', // normal|button
                            // le opzioni come le selects
                            options: [
                                {
                                    value: "1",
                                    label: "Si"
                                },
                                {
                                    value: "0",
                                    label: "No"
                                }
                            ],
                            validations: [
                                {
                                  type: 'required',
                                  error_message: 'Campo obbligatorio',
                                },
                                {
                                  pattern: '^1$',
                                  message: 'Devi selezionare "Si"'
                                }
                              ],
                            props:{
                                style: { paddingBottom: '75px' }
                            }
                        }
                    ],
                },
            ],
        },
        {
            cols: [
                {
                    items: [
                        {
                            type: 'submit',
                            name: 'btn',
                            text: 'Conferma',
                            props: {
                                icon: 'check',
                                className: 'bg-green'
                            }
                        },
                    ],
                },
            ],
        },
    ],
    onSubmit: 'onSubmit',
}

class RequisitiForm extends Component {

    state = {
        loaded: false,
        required_files: [],
        medias: []
    }



    componentDidMount() {
        this.setState({
            loaded: true,
            medias: this.props.media,
            required_files: this.getRequiredFilesFromProps()
        });
    }

    getRequiredFilesFromProps() {
        let req = [];
        if (this.props.values) {
            Object.keys(this.props.values).forEach(k => {
                if (this.props.values[k] === true || this.props.values[k] === "1") req.push(k);
            });
        }

        return req;
    }

    updateValues(a, b, c) {
        let required = [];
        Object.keys(c).forEach(key => {
            if (parseInt(c[key]) === 1) required.push(key);
        });

        this.setState({ required_files: required });
    }

    submitForm(data) {
        let valid = true;
        Object.keys(data).forEach(key => {
            if (parseInt(data[key]) === 1 && !this.state.medias.some(el => el.tipo.sottodominio === key))
                valid = false;
        })
        if (!valid) {
            message.error('Inserisci tutti i documenti obbligatori');
            return;
        }

        if (this.props.onSubmit) this.props.onSubmit(data);
    }

    changed(name, e) {
        let required_files_not_ordered = []; 
        if (parseInt(e.target.value) === 1) {
            required_files_not_ordered = [...this.state.required_files, name];
        } else {
            required_files_not_ordered = this.state.required_files.filter(el => el !== name);
        }
        this.setState({ required_files: required_files_not_ordered })
    }

    render() {
        console.warn("required_files", this.state.required_files);
        const filteredAccreditationMediaNotOrdered = allegatiStatic.filteredAccreditationMedia(  this.props.GlobalStore.stubs.tipo_media.filter(el => el.dominio === 'requisiti_aule' && this.state.required_files.indexOf(el.sottodominio) !== -1), this.props.accreditamento.tipo_accreditamento)
        const input_ordered = this.props.type == 'requisiti' ?
        [
            'nulla_osta_tecnico_sanitario',
            'collaudo_statico',
            'certificato_prevenzione_incendi',
            'copia_conforme_collaudo',
            'perizia_giurata',
            'relazione_attrezzature',
            //'documento_sicurezza',
            //'comunicazione_responsabile',
            //'comunicazione_medico',
            'certificato_catastale'
        ]
            :
        [
        //'nulla_osta_tecnico_sanitario',
        //'collaudo_statico',
        //'certificato_prevenzione_incendi',
        //'copia_conforme_collaudo',
        //'perizia_giurata',
        //'relazione_attrezzature',
        'documento_sicurezza',
        'comunicazione_responsabile',
        'comunicazione_medico',
        //'certificato_catastale'
        ];

        let filteredAccreditationMedia = [];
        input_ordered.map(input => {
            filteredAccreditationMediaNotOrdered.map(field => {
                if(input === field.sottodominio){
                    filteredAccreditationMedia.push(field);
                }
                return null;
            });
            return null;
        });
        console.warn("filteredAccreditationMedia", filteredAccreditationMedia);

        const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;

        return (
            <div>
                { this.state.loaded ?
                    <Row gutter={16}>
                        <Col md={10}>
                            <CommonForm
                                wrappedComponentRef={inst => (this.formRefBools = inst)}
                                loading={this.props.loading || false}
                                form_model={this.props.type == 'requisiti' ? formLayoutRequisiti : formLayoutNormativa}
                                ch={(name, ev) => this.changed(name, ev)}
                                values={                                    
                                        this.props.type == 'requisiti' ?
                                        [
                                            'nulla_osta_tecnico_sanitario',
                                            'collaudo_statico',
                                            'certificato_prevenzione_incendi',
                                            'copia_conforme_collaudo',
                                            'perizia_giurata',
                                            'relazione_attrezzature',
                                            //'documento_sicurezza',
                                            //'comunicazione_responsabile',
                                            //'comunicazione_medico',
                                            'certificato_catastale'
                                        ]
                                            :
                                        [
                                        //'nulla_osta_tecnico_sanitario',
                                        //'collaudo_statico',
                                        //'certificato_prevenzione_incendi',
                                        //'copia_conforme_collaudo',
                                        //'perizia_giurata',
                                        //'relazione_attrezzature',
                                        'documento_sicurezza',
                                        'comunicazione_responsabile',
                                        'comunicazione_medico',
                                        //'certificato_catastale'
                                    ]}
                                onSubmit={(values) => {
                                    this.submitForm(values)
                                }}
                                readOnly={this.props.readOnly}

                            />
                        </Col>
                        <Col md={14}>
                            {
                                filteredAccreditationMedia.map(accreditationMedia => {
                                    let isRealReadOnlyDocumentoModificato = isRealReadOnly;
                                    let filteredDocumentoModificato = this.state.medias.filter(element => element.tipo.dominio === 'requisiti_aule' && element.tipo.sottodominio === accreditationMedia.sottodominio);
                                    if(isRealReadOnlyDocumentoModificato){
                                        //Dovrebbe essere readOnly, ma se non ci sono documenti devo comunque
                                        //consentire l'inserimento
                                        if(filteredDocumentoModificato.length === 0){
                                            isRealReadOnlyDocumentoModificato = false;
                                        }
                                    }

                                    return <Documenti
                                    canDelete={!isRealReadOnlyDocumentoModificato}
                                    accreditation_id={this.props.accreditation_id}
                                    unique={true}
                                    media_types={filteredAccreditationMedia}
                                    readOnly={isRealReadOnlyDocumentoModificato}
                                    medias={this.state.medias}
                                    current_domain="requisiti_aule"
                                    current_subdomain={accreditationMedia.sottodominio}
                                    required_types={
                                        this.props.GlobalStore.stubs.tipo_media
                                            .filter(el => this.state.required_files.indexOf(el.sottodominio) !== -1 && el.dominio === 'requisiti_aule' && el.obbligatorieta && el.stato === allegatiStatic.stato.attivo )
                                            .map(e => e.tipo_media)}
                                    ref_id={this.props.id_sede_accreditamento}
                                    onUpload={f => {
                                        this.setState({ medias: [...this.state.medias, f] })
                                    }}
                                    onDelete={id => {
                                        this.setState({ medias: this.state.medias.filter(x => x.id !== id) })
                                    }}
                                    ListItem={{style: {paddingTop: '0'}}}
                                    descriptionSection={{lg: 14}}
                                    iconSection={{lg: 2}}
                                    uploadSection={{lg: 8}}
                                />
                                })
                            }
                            
                            {allegatiStatic.thereAreMediasFor(this.props.GlobalStore.stubs.tipo_media, 'requisiti_aule', 'documenti_requisiti_aule', this.props.accreditamento.tipo_accreditamento) &&
                                <Documenti
                                    canAddNote={false}
                                    canDelete={!isRealReadOnly}
                                    accreditation_id={this.props.accreditation_id}
                                    unique={true}
                                    media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
                                    readOnly={isRealReadOnly}
                                    medias={this.state.medias}
                                    current_domain="requisiti_aule"
                                    current_subdomain={"documenti_requisiti_aule"}
                                    required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'requisiti_aule', 'documenti_requisiti_aule')}
                                    ref_id={this.props.id_sede_accreditamento}
                                    onUpload={f => {
                                        this.setState({ medias: [...this.state.medias, f] })
                                    }}
                                    onDelete={id => {
                                        this.setState({ medias: this.state.medias.filter(x => x.id !== id) })
                                    }}
                                />
                            }

                        </Col>
                    </Row> : null}
            </div>
        );
    }
}

export default connect(({ GlobalStore }) => ({ GlobalStore }))(RequisitiForm);
