import React, { Component } from 'react';

import moment from 'moment';
import { message } from 'antd';

import CommonForm from '@common/components/form';

import EfficaciaServices from '@accreditamento/services/Efficacia';


const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 2,
              items: [
                {
                  type: 'checkbox',
                  label: 'Autofinanziato',
                  name: 'autofinanziato',
                },
              ],
          },
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Tipologia',
                name: 'id_tipo_accreditamento',
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: 'tipo_accreditamento',
              },
            ],
          },
          {
            span: 10,
            items: [
              {
                type: 'select',
                label: 'Anno',
                name: 'anno',
                props: {
                  // size: 'large',
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                options_props: 'anni',
              },
            ],
          }
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'currency',
                label: 'Costi allievo autorizzati',
                name: 'costi_allievo_autorizzati',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'maxCurrency'
                  }
                ],
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'currency',
                label: 'Costi allievo rendicontati',
                name: 'costi_allievo_rendicontati',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            props: {

              style: { float: 'right' }
            },
            items: [
              {
                type: 'submit',
                name: 'btn_submit_attrezzatura',
                text: 'Salva',
                //onClick: 'showDrawer',
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'onSubmit',
  }
}

class Create extends Component {

  state = {
    loading: false,
    loaded: false,
    fileList: [],
    comuni: [],
    selected_aula: null
  };

  async submitFormData(data) {

    if (parseFloat(data.costi_allievo_rendicontati) > parseFloat(data.costi_allievo_autorizzati)) {
      message.error('L\'importo rendicontato è troppo alto');
      return;
    }

    if(!data.autofinanziato && !data.id_tipo_accreditamento) {
      message.error('Il campo tipologia è obbligatorio se non autofinanziato');
      return;
    }

    if (!this.state.loading) {
      await this.setState({ loading: true });
      EfficaciaServices.addCostiAttivita(this.props.accreditamento.id, data, (res) => {
        this.props.onCreate();
        this.setState({ loading: false })
        this.reset();
      }, (err) => this.setState({ loading: false }))
    }

  }


  getTipoAccreditamento() {

    return this.props.tipo_accreditamento.map(e => {
      return {
        key: e.tipologia.id,
        label: e.tipologia.tipologia,
        value: e.tipologia.id
      }
    })
  }

  getAnni() {
    let years = [];
    let current = parseInt(moment().format('YYYY'));

    for (let i = 0; i <= 4; i++) {
      let curr = current - i;
      years.push({
        key: 'anno_' + curr,
        value: curr,
        label: '' + curr
      })
    }

    return years;
  }

  reset() {
    this.formRef.props.form.resetFields();
  }

  render() {

    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={{}}
          readOnly={this.props.readOnly}
          onSubmit={(data) => this.submitFormData(data)}

          tipo_accreditamento={this.getTipoAccreditamento()}
          anni={this.getAnni()}

          maxCurrency={(rule, value, cb) => {
            if (value > 10000000000) cb("Valore non valido");
            else cb()
          }}
        />
      </div>
    );
  }
}
export default Create;