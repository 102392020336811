import { Breadcrumb, Button, Card, Col, Progress, Row, Modal, message, Tooltip, Icon } from "antd";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CheckPermissions from '@common/decorators/CheckPermissions';
import Wrapper from '@common/decorators/Wrapper';
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import Actions from '../components/Actions';
import domandaService from '../services/Domanda';
import SchedaFormulario from "../components/SchedaFormulario";

import ModelServices from '../services/Domanda';

import eventBroker from '@common/utils/services/EventBroker';


const getStato = (domanda, component) => {
  let stato = 'ente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigenteAmministrativoPermissions'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttoreAmministrativoPermissions'])) stato = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';
  return domanda.stato_flusso_formatted['stato_' + stato];
}


const renderDescriptionText = (record, component) => {
  if (!record.stato_flusso_formatted) return ''
  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigenteAmministrativoPermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttoreAmministrativoPermissions'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';

  return record.stato_flusso_formatted['descrizione_' + user];
}


@Wrapper({ route: 'CompilazioneFormulario', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewDomanda', 'viewOwnDomanda'])
class CompilazioneFormularioRoute extends Component {


  state = {
    mounted: false,
    loading: false,
    saving: false,
    saved: true,
    calculating_progress: false,
    getting_info: false,
    loading_riepilogo: false,
    dati: null,
    progress_obj: {
      progress: 0,
      required_sections: {},
      valid_sections: {},
      showed_sections: {},
      errors: {}
    },
    showNote:false
  }

  componentDidMount() {
    this.setState({ mounted: true, loaded: false }, () => {
      if (this.props.match.params.id) {
        this.loadDomanda(this.props);
      }
    });
  }

  loadDomanda(props) {
    domandaService.detail({
      expand: [
        'procedura',
        'procedura.sezioni',
        'procedura.defaultSezioni'
      ].join(',')
    }, props.match.params.id,
      (res) => {
        this.setState({
          dati: res.data.data,
          progress_obj: res.data.data.progress,
          loaded: true
        });
      },
      err => this.setState({
        loaded: true
      })
    );
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "formulario.pdf");

    this.refresh(false);
  }

  save_json(json) {
    this.setState({
      saving: true,
      saved: false,
    },
      () => {
        ModelServices.save_json(json, this.props.match.params.id,
          (res) => {
            message.success('Dati salvati con successo');
            eventBroker.trigger('reloadOnSectorChange');
            this.setState({
              dati: res.data.data,
              progress_obj: res.data.data.progress,
              saving: false,
              saved: true,
            });
          },
          () => {
            this.setState({
              saving: false,
              saved: true
            });
          }
        );
      });


  }

  editableState() {
    //TODO return editable in base a chi sei e in base allo stato
    let editable = this.isEnte() && (this.state.dati?.stato === affidamentoStatic.stato.bozza || this.state.dati?.stato === affidamentoStatic.stato.compilata) && this.state.dati?.procedura?.stato !== affidamentoStatic.stato_procedura.chiuso;
    return editable;
  }

  sectiontMappingGetName(name) {

    let o = {
      generalData: 'Dati generali del progetto',
      relatedSubjects: 'Soggetti correlati',
      learner: 'Discenti',
      teachingProgramming: 'Programmazione didattica',
      financialStatement: 'Scheda finanziaria',
      nonTrainingElements: 'Elementi non formativi'
    }

    return o[name];
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  calculateProgress() {
    if (!this.state.calculating_progress) {
      this.setState({ calculating_progress: true });

      domandaService.progress({}, this.props.match.params.id,
        async (res) => {
          this.setState({
            progress_obj: res.data.data,
            loaded: true,
            calculating_progress: false
          });
        },
        err => this.setState({
          loaded: true,
          calculating_progress: false
        })
      )
    }
  }

  getInfoAndValidate() {
    if (!this.state.getting_info) {
      this.setState({ getting_info: true });

      let domanda = this.state.dati.domanda_json;

      //Recupero il form dei dati generali e lo mergio in domanda json
      if (this.state.dati.domanda_json.generalData) {
        let generalData = eventBroker.trigger('getBackGeneralData');
        if (generalData)
          domanda.generalData = { ...domanda.generalData, ...generalData };
      }

      //Recupero il form dei discenti e lo mergio in domanda json
      if (this.state.dati.domanda_json.learner) {
        let data = eventBroker.trigger('getBackLearner');
        if (data)
          domanda.learner = { ...domanda.learner, ...data.learner };
      }

      //Recupero il form dei soggetti correlati e lo mergio in domanda json
      if (this.state.dati.domanda_json.relatedSubjects) {
        let data = eventBroker.trigger('getBackRelatedSubjects');
        if (data)
          domanda.relatedSubjects = { ...domanda.relatedSubjects, ...data.domanda.relatedSubjects };
      }

      //Recupero il form dei dati generali e lo mergio in domanda json
      if (this.state.dati.domanda_json.teachingProgramming) {
        let teachingProgramming = eventBroker.trigger('getBackTeachingProgramming');
        if (teachingProgramming)
          domanda.teachingProgramming = { ...domanda.teachingProgramming, ...teachingProgramming };
      }

      //TODO: ricontrollare come arrivano i dati dopo aver terminato lo sviluppo dei salvataggi dei vari tab

      domandaService.info_and_validate(domanda, this.props.match.params.id,
        (res) => {

          if (res.data.data.valid) {
            this.setState({ show_completed: true, getting_info: false });
          } else {
            this.setState({
              progress_obj: res.data.data,
              show_errors: true,
              getting_info: false
            })
          }

        },
        err => this.setState({
          getting_info: false
        })
      )
    }
  }

  refresh(reset = true) {
    //console.log('REFRESH', this.props);
    if (reset) {
      this.setState({
        loaded: false,
      })
    }
    this.loadDomanda(this.props);
  }

  refreshInline(data) {
    this.setState({
      dati: { ...this.state.dati, ...data }
    }, () => {
      this.calculateProgress();
    }
    );
  }

  updateProgress(progress) {
    this.setState({
      progress_obj: progress,
    });
  }

  refreshSedeSvolgimento() {
    let dati = this.state.dati;
    if(dati.domanda_json.relatedSubjects && dati.domanda_json.relatedSubjects.AfcHeadquartersSection) {
      dati.domanda_json.relatedSubjects.AfcHeadquartersSection.provincia = null;
      dati.domanda_json.relatedSubjects.AfcHeadquartersSection.id = null;
      dati.domanda_json.relatedSubjects.AfcHeadquartersSection.comune = null;
      dati.domanda_json.relatedSubjects.AfcHeadquartersSection.indirizzo = null;
    }
   
    this.setState({
      dati: dati
    })
  }

  render() {

    return (
      <div className="scheda">
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle"> {this.state.dati?.procedura ? this.state.dati.procedura.bando.titolo_bando : ''}</h3>
          <p>
            Compila il formulario di partecipazione al bando di formazione.
            {this.state.showNote &&
              <Tooltip placement="top" title="Il presente formulario deve essere debitamente compilato, rilegato con pagine numerate progressivamente, Sottoscritto dal Rappresentante legale dell’ente di formazione e/o dal capofila del Raggruppamento temporaneo. Nel caso di costituenda ATI/ATS, il formulario dovrà essere sottoscritto dai Rappresentanti legali di tutti i soggetti."><Icon className="m5w" type="info-circle" /></Tooltip>
            }
          </p>
        </Card>

        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Bacheca</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/affidamento/formulari">Gestione formulari</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Compilazione scheda
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
          <Col style={{ flex: "1 1 70%" }} >
            <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
              <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
                bordered={false}>
                {this.state.dati?.ente ? <h4><span style={{ color: '#000' }}>ENTE DI FORMAZIONE:</span> {this.state.dati.ente.denominazione}</h4> : ''}
                {this.state.dati &&
                  <Button icon="file-pdf" className="m5h m5w" type="primary" loading={this.state.loading_riepilogo} onClick={() => {
                    this.setState({ loading_riepilogo: true })
                    domandaService.printPdf(this.state.dati.id, (res) => {
                      this.saveBlob(res.data);
                      this.setState({ loading_riepilogo: false })
                    }, (err) => {
                      this.setState({ loading_riepilogo: false })
                    })
                  }}>Scarica formulario compilato</Button>
                }
              </Card>
              <Card style={{ flex: "1 1 auto" }}
                bordered={false}
                className="shadow">
                <Row>
                  <Col lg={24} sm={24} xs={24}>
                    <div id="actions">
                      <h4 className="p10h">Stato di compilazione formulario</h4>
                      <p>Stato: <strong className={this.state.dati && this.state.dati.stato === affidamentoStatic.stato.vincitore ? 'color-green uppercase' : ''}>{this.state.dati ? getStato(this.state.dati, this) : ''}</strong></p>
                      <p>{this.state.dati ? renderDescriptionText(this.state.dati, this) : ''}</p>
                      {this.state.loaded ?
                        <Actions
                          {...this.props}
                          refresh={(reset) => this.refresh(reset)}
                          refreshInline={(data) => this.refreshInline(data)}
                          domanda={this.state.dati}
                        /> : null}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Col>
          {checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.editableState() ?
            <Col style={{ flex: "1 1 30%" }} >
              <Card
                style={{ height: "100%" }}
                bordered={false}>

                <div style={{ textAlign: 'center' }}>
                  <h4 className="p10h">Percentuale compilazione del formulario</h4>

                  <Progress width={100} type="circle" percent={this.state.progress_obj.progress} />
                  <p className="p30h p20w text-justify">
                    {
                      this.state.progress_obj.progress !== 100
                        ?
                        "Compila le sezioni del formulario fino al raggiungimento del 100%, clicca sul pulsante informazioni per avere dettagli sui dati mancanti"
                        : "Congratulazioni, la compilazione è completa, puoi procedere all'inoltro del formulario compilato"
                    }
                  </p>
                  {
                    this.state.progress_obj.progress < 100
                      ?
                      <Button
                        size="small"
                        icon="info"
                        type="primary"
                        loading={this.state.getting_info}
                        onClick={() => this.getInfoAndValidate()}>Informazioni</Button>
                      : null
                  }

                  <Button
                    size="small"
                    icon="reload"
                    type="primary"
                    style={{ marginLeft: 8 }}
                    ghost
                    loading={this.state.calculating_progress}
                    onClick={() => this.calculateProgress()}>Aggiorna</Button>


                  <Modal
                    visible={this.state.show_errors}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    width={600}

                    destroyOnClose={false}
                    onOk={() => this.setState({ show_errors: false })}
                    onCancel={() => this.setState({ show_errors: false })}
                  >
                    <ul>
                      {
                        Object.keys(this.state.progress_obj.errors_translations).map(e => {
                          if (this.state.progress_obj.errors_translations[e].length > 0) {
                            return <div key={"section_" + e}>
                              <h4>{this.sectiontMappingGetName(e)}</h4>
                              <ul>{
                                this.state.progress_obj.errors_translations[e].map((err, i, a) => {
                                  return <li key={"err_" + e + "_" + i}><strong>{err[0]}</strong> {err[1]}</li>
                                })
                              }
                              </ul>
                            </div>
                          }
                          return null;
                        })
                      }
                    </ul>
                  </Modal>

                  <Modal
                    visible={this.state.show_completed}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    width={600}

                    destroyOnClose={false}
                    onOk={() => this.setState({ show_completed: false })}
                    onCancel={() => this.setState({ show_completed: false })}
                  >
                    <div>
                      Il formulario risulta completo e con tutte le informazioni necessarie. Proseguire con il salvataggio dei dati per non perdere le ultime informazioni immesse.
                      </div>
                  </Modal>



                </div>
              </Card>
            </Col> : null
          }
        </Row>
        <Card style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          {!this.state.loaded || !this.state.dati ? <SpinnerLoading message='Caricamento dati...' /> :
            <div>
              <SchedaFormulario
                {...this.props}
                formulario={'domanda'}
                id_ente={this.state.dati.id_ente}
                isEditable={this.editableState()}
                domanda_json={this.state.dati.domanda_json}
                refresh={(reset) => this.refresh(reset)}
                progress_obj={this.state.progress_obj}
                updateProgress={this.updateProgress.bind(this)}
                saveJson={(json) => this.save_json(json)}
                saving={this.state.saving}
                saved={this.state.saved}
                showNote={this.state.showNote}
                refreshSedeSvolgimento = {()=>this.refreshSedeSvolgimento()}
              />

            </div>
          }

        </Card>

      </div>
    );
  }
}

export default CompilazioneFormularioRoute;