import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, Card, Tooltip, Drawer } from "antd";
import CommonForm from "@common/components/form";

import Wrapper from "@common/decorators/Wrapper";
import CheckPermissions from "@common/decorators/CheckPermissions";

import ModelServices from "@affidamento/services/Corsi";
import MediaServices from '@common/services/media/Media';

import SpinnerLoading from "@components/SpinnerLoading";
import QrAttestati from "./QrAttestati";

import _ from "@common/utils/formatters/_";

import FileSaver from 'file-saver';

const formLayout = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Dettagli Edizione",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type_string: 'text',
                label: "Titolo del corso",
                name: "titolo_progetto",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type_string: 'text',
                label: "Ente erogatore",
                name: "ente.denominazione",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: "datepicker",
                label: "Data effettiva Avvio",
                name: "data_effettiva_avvio",
              },
            ],
          },
        ],
      },

      {
        cols: [
          {
            items: [
              {
                name: "titolo",
                type: "title",
                element: "h4",
                text: "Valutazione",
                props: {
                  style: { textAlign: "left" },
                  className: "m10h",
                },
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type_string: 'text',
                label: "Superata",
                name: "esame_valutazione.superata",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type_string: 'text',
                label: "Giudizio",
                name: "esame_valutazione.giudizio",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type_string: 'text',
                label: "Valutazione",
                name: "esame_valutazione.valutazione",
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: 
            component.state.data && 
            component.state.data.esame_valutazione !== null && 
            component.state.data.esame_valutazione.media &&
            component.state.data.esame_valutazione.media !== null &&
            component.state.data.esame_valutazione.media.length != 0 &&
            component.state.data.esame_valutazione.media.some(m => m.tipo.sottodominio !== null && m.tipo.sottodominio.includes("SISFO_Dirigente_Esame_Attestato_") && m.ext === 'p7m')
           ? 
            [
              {
                name: 'download',
                type: 'child',
                child: <div>
                <h4 class="text-danger">Attestato</h4>
                <Button
                  loading={component.props.downloading || false}
                  disabled={component.props.downloading || component.props.uploading || false}
                  icon="download" onClick={
                    () => {

                      /*
                      //Scarichiamo il file della attivazione
                      EnteServices.getMediasAttivazione({provvedimentoType: component.props.provvedimentoType}, component.props.values.id_ente, component.props.values.id, (res) => {                        
                        console.warn('getMediasAttivazione res', res);
                        console.warn('mediasAttivazione[0]', mediasAttivazione[0]);
                        const blob = new Blob([res.data], { type: mediasAttivazione[0].mime_type });
                        //var fileURL = window.URL.createObjectURL(blob);
                        //window.open(fileURL, '_blank');
                        FileSaver.saveAs(blob, mediasAttivazione[0].nome_file);
                      }, (err) => {
                          console.error("EnteServices.getMediasAttivazione", err)
                      });  
                      */     

                      const medias = component.state.data.esame_valutazione.media.filter(m => m.tipo.sottodominio !== null && m.tipo.sottodominio.includes("SISFO_Dirigente_Esame_Attestato_") && m.ext === 'p7m');
                      const lastMedia = medias[medias.length - 1];
                      console.warn("download last media", lastMedia);
                      MediaServices.preview(lastMedia.id, async (res) => {
                        const blob = new Blob([res.data], { type: lastMedia.mime_type });
                        FileSaver.saveAs(blob, lastMedia.nome_file);
                      }, () => null)

                    }
                  }
                ><span>Download</span></Button>                  
                <br/><br/>
                </div>
              }                  
            ] : []
          }
        ]
      },
    ],
  };
};

@Wrapper({
  route: "Attestato",
  hasSidebar: true,
  hasHeader: true,
  hasFooter: true,
})
class AttestatoRoute extends Component {
  state = {
    id: null,
    loading: false,
    params: {},
    readOnly: true,
  };

  componentDidMount() {
    this.fetch(this.props.location.search);
    this.getValues()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search);
    }
  }
  fetch(query) {
    const stId = window.location.href.split("/")[6];
    this.setState({ id: stId });

    let { params, pager } = _.getFilterParam(query, this.default_order);

    if (params.stato === "-1") {
      delete params.stato;
    }

    this.setState({ loading: true });
    ModelServices.list(
      {
        ...params,
        fields: [
          "id",
          "titolo_progetto",
          "ente",
          "ente.denominazione",
          "edizione",
          "discriminante",
          "data_effettiva_avvio",
          "data_presunta_avvio",
          "stato",
          "stato_flusso",
          "stato_flusso_description",
        ].join(","),
        expand: ["istruttore", "esame", "esame_valutazione"].join(","),
      },
      (res) => {
        let data = _.getResponse(res);
        data.data = data.data.filter((e) => e.discriminante == "EDIZIONE");
        data.data = data.data.filter((e) => e.id == this.state.id);
        this.setState({
          loading: false,
          data: data.data[0],
        });
      },
      (e) => {
        this.setState({ loading: false });
        //console.log('errore dati', e)
      }
    );
  }

  getValues(data) {
    return  data;
  }

  render() {
    return (
      <div className="scheda">
        {this.state.loading ? (
          <SpinnerLoading />
        ) : (
          <div>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">Registro Attestati</h3>
              <p>Lista dei registri Attestati</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Registro Attestati</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card className="shadow no-pad" bordered={false}>
              <CommonForm
                form_model={formLayout(this)}
                wrappedComponentRef={(inst) => (this.formNoteRef = inst)}
                loading={this.props.loading || false}
                values={this.getValues(this.state.data)}
                readOnly={this.state.readOnly}
              />

              <Tooltip placement="top" title="Visualizza QR">
                <Button
                  type="primary"
                  onClick={() => this.setState({ open_drawer: true })}
                >
                  Genera Qr attestato
                </Button>
                <Drawer
                  title="Genera Qr"
                  placement="right"
                  closable={true}
                  onClose={() => this.setState({ open_drawer: false })}
                  visible={this.state.open_drawer}
                  width={600}
                  destroyOnClose={true}
                >
                  <QrAttestati
                    registration_id={this.state.id}
                    url="affidamento/creazioneqr/qrattestato/"
                  />
                </Drawer>
              </Tooltip>
            </Card>
            
          </div>
        )}
      </div>
    );
  }
}

export default AttestatoRoute;
