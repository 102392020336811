import TableComponent from '@common/components/table';
import { Button, Card, Drawer, Modal, Popconfirm, Row, Tooltip } from 'antd';
import React, { Component } from 'react';
import ElencoRegionaleServices from '../../accreditamento/services/ElencoRegionale'
import queryString from 'query-string';
import FilterForm from '@common/components/filterForm';
import ExportTable from './ExportTable';
import InsertEnteAccreditato from './InsertEnteAccreditato';
import _ from '@common/utils/formatters/_';
import moment from 'moment';
import FileSaver from 'file-saver';
import { message } from 'antd';
import checkPermission from '@common/utils/check/permissions';


const model = (component) => {
    return {
        with_server_pagination: true,
        columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                width: '60px',
                render: element => <div>{
                    !element.manuale &&
                    <Tooltip placement="top" title={"Visualizza dettaglio dell'ente"}>
                        <Button className="m2w" size="small" shape="circle" icon={"eye"} onClick={() => component.props.history.push('/enti/' + (element.id || element.id_ente) + '/accreditamenti')} />
                    </Tooltip>}
                    { element.manuale && checkPermission.hasPermission(component.props.UserStore.user, [ 'adminPermissions', 'dirigentePermissions']) &&
                    <Tooltip placement="top" title={"Inserisci accreditamento"}>
                        <Button className="m2w" size="small" shape="circle" icon={"plus"} onClick={() => component.addAccreditamento(element)} />
                    </Tooltip>
                }

                </div>,
            },
            {
                title: 'ID Ente',
                dataIndex: '',
                sorter: true,
                key: 'id_ente',
                render: element => <div>{element.id_ente}</div>,
            },
            {
                title: 'Denominazione',
                dataIndex: '',
                sorter: true,
                key: 'denominazione',
                render: element => <div>{element.denominazione}</div>,
            },
            {
                title: 'Macrotipologie',
                dataIndex: '',
                sorter: true,
                key: 'tipologie',
                render: element => <div>{element.tipologie}</div>,
            },
            {
                title: 'Comune sede legale',
                dataIndex: '',
                sorter: false,
                key: 'comune_sede_legale',
                render: element => <div>{element.comune_sede_legale}</div>,
            },
            {
                title: 'Indirizzo sede legale',
                dataIndex: '',
                sorter: false,
                key: 'indirizzo_sede_legale',
                render: element => <div>{element.indirizzo_sede_legale}</div>
            },
            {
                title: 'Provincia sede legale',
                dataIndex: '',
                sorter: false,
                key: 'provincia_sede_legale',
                render: element => <div>{element.provincia_sede_legale}</div>,
            },
        ],
        expandable: true,
        expandable_columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                width: '60px',
                render: element => element.manuale && checkPermission.hasPermission(component.props.UserStore.user, [ 'adminPermissions', 'dirigentePermissions']) && < div>
                    <Tooltip placement="top" title="Modifica">
                        <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.open(element)} />
                    </Tooltip>
                    <Tooltip placement="top" title="Revoca">
                        <Popconfirm placement="leftTop" title="Sicuro di voler revocare questo accreditamento?" onConfirm={() => component.revoca(element.id)} okText="Si" cancelText="No">
                            <Button className="m2w" size="small" shape="circle" icon="delete" />
                        </Popconfirm>
                    </Tooltip>
                </div>,
            },
            {
                title: 'Tipologia',
                dataIndex: 'tipologia',
                sorter: false,
                key: 'tipo_accreditamento',
            },
            {
                title: 'Sede formativa',
                dataIndex: '',
                sorter: false,
                key: 'indirizzo_sede_formativa',
                render: element => <div>{element.comune_sede_formativa !== null ? element.comune_sede_formativa + ' (' + element.provincia_sede_formativa + ') ' + element.indirizzo_sede_formativa : ''}</div>
            },
            {
                title: 'Decreto',
                dataIndex: 'numero_decreto',
                sorter: false,
                key: 'numero_decreto',
            },
            {
                title: 'Data decreto',
                dataIndex: '',
                sorter: false,
                key: 'data_decreto',
                render: element => <div> {element.data_decreto ? moment(element.data_decreto, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</div>
            },
        ],
        expanded_props: null,
        onExpand: 'onExpand'
    }
}

const filterFormModel = (component) => {

    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'string',
                                label: 'Denominazione',
                                name: 'denominazione',
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Provincia',
                                name: 'provincia',
                                options: [
                                    {
                                        key: 0,
                                        value: null,
                                        label: "Tutte"
                                    },
                                    ...component.props.GlobalStore.stubs.provincia
                                ],
                                //options_props: 'province',
                                props: {
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Tipologia',
                                name: 'tipologia',
                                options: [
                                    {
                                        value: null,
                                        label: "Tutte"
                                    },
                                    {
                                        value: 1,
                                        label: "Provvisorio"
                                    },
                                    {
                                        value: 2,
                                        label: "Definitivo"
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'submit',
                                type: 'submit',
                                text: 'Cerca',
                                btn_type: 'primary'
                            }
                        ]
                    }
                ]
            },
        ]
    };
}

class ListEnti extends Component {

    default_order = 'id'
    base_url = '/enti'

    state = {
        loading: false,
        open_drawer: false,
        editing_id: null,
        data: {}
    }

    componentDidMount() {
        this.fetch(this.props.location.search)
    }

    /**
    * Se cambia la stringa aggiorna
    * @param  {[type]} newProps [description]
    * @return {[type]}          [description]
    */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.fetch(this.props.location.search)
        }
    }

    refresh() {
        this.fetch(this.props.location.search)
    }

    /**
    * Chiamato da sort in tabella
    * @param  {[type]} obj [description]
    * @return {[type]}     [description]
    */
    updateQueryStr(obj) {
        _.formatSortChange(this.props, this.base_url, obj);
    }

    fetch(query) {
        let { params, pager } = _.getFilterParam(query, this.default_order);
        this.setState({ loading: true });
        ElencoRegionaleServices.list(params, res => {
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                parseInt(query.substring(6)),
                data.data.length,
                pager)
            );

        }, (e) => {
            this.setState({ loading: false });
        })
    }

    open(element) {
        this.setState({
            edit: true,
            id_element_opened: element.id || element.id_ente
        },
            () => {
                this.setState({ open_drawer: true })
            })
    }

    addAccreditamento(element) {
        this.setState({
            element_opened: element,
            open_drawer: true
        })
    }

    revoca(id) {
        this.setState({ loading: true });
        ElencoRegionaleServices.revoca(id, res => {
            message.success('Accreditamento revocato con successo');
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                data.current,
                data.total,
                { key: this.default_order, order: 'ascend' })
            );
        }, (e) => {
            this.setState({ loading: false });
        })

    }

    render() {
        return (
            <div>
                <FilterForm
                    formLayout={filterFormModel(this)}
                    base_url={this.base_url}
                    search={this.props.location.search}
                    history={this.props.history}
                    province={this.props.GlobalStore.stubs.provincia}
                />
                <Card
                    className="shadow no-pad"
                    bordered={false}
                >
                    <Row type="flex" justify="start" className="m10h">
                        {checkPermission.hasPermission(this.props.UserStore.user, [ 'adminPermissions', 'dirigentePermissions']) &&
                        <div className="m5h m5w">
                            <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true, edit: false })} loading={this.state.loading}>Inserisci nuovo ente</Button>
                        </div>
                        }
                        <div className="m5h m5w">
                            <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_export: true })} loading={this.state.loading}>Genera albo</Button>
                        </div>
                    </Row>
                    <TableComponent
                        data={this.state.data}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        model={model(this)}
                        onExpand={(record, props) => {
                            let data = {
                                key: record.id_ente,
                                data: record.sedi_formative,
                            };
                            this.refs.table_component.updateChildrenTableData(record.id_ente, record.sedi_formative);
                            return data;
                        }}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                    />
                    {this.state.open_drawer &&
                        <Drawer
                            title="DATI DELL' ACCREDITAMENTO"
                            placement="right"
                            closable={true}
                            onClose={() => this.setState({ open_drawer: false, element_opened: null, id_element_opened: null, edit: false})}
                            visible={this.state.open_drawer}
                            width={window.innerWidth - (window.innerWidth / 100 * 5)}
                            destroyOnClose={true}
                        >
                            <InsertEnteAccreditato
                                {...this.props}
                                edit={this.state.edit}
                                onUpdate={() => {
                                    this.setState({ open_drawer: false, element_opened: null, id_element_opened: null, edit: false })
                                    this.refresh()
                                }}
                                id_elenco_regionale={this.state.id_element_opened}
                                element_opened={this.state.element_opened}
                            ></InsertEnteAccreditato>
                        </Drawer>
                    }
                    {this.state.open_export &&
                        <Modal
                            title="Genera albo"
                            visible={this.state.open_export}
                            onOk={() => {
                                let params = queryString.parse(this.props.location.search);
                                params = {
                                    fields: this.exportRef.getSelection().join(','),
                                    ...params,
                                };
                                ElencoRegionaleServices.exportAlbo(params, (res) => {
                                    const _blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                    FileSaver.saveAs(_blob, 'elenco_enti_accreditati.ods');
                                });
                            }}
                            onCancel={() => this.setState({ open_export: false })}
                        >
                            <ExportTable ref={r => { this.exportRef = r }} />
                        </Modal>
                    }
                </Card>
            </div>)
    }
}

export default ListEnti;