import React, { Component } from 'react';
import { connect } from "react-redux";
import { notification, message } from 'antd';
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import IspezioneService from '../../services/Ispezione';
import NominaService from '../../services/Nomina'
import SceltaIspettore from './SceltaIspettore';
import IspezioneNomina from './IspezioneNomina';
import nominaStatic from '@common/utils/static/nomina';
import FileSaver from 'file-saver';
import IspezioneVerbale from './IspezioneVerbale';
import moment from 'moment';
import SceltaControdeduzioni from './SceltaControdeduzioni';
import Controdeduzioni from '../controdeduzioni/Controdeduzioni';
import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';


const getStatoNomina = (component) => {
  let stato = 'ente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['manageProgetto'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['manageOwnProgetto']) &&
    component.props.progetto.istruttore && component.props.progetto.istruttore.length > 0 && component.props.progetto.istruttore[0].id === component.props.UserStore.user.id) stato = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['manageOwnNomina'])) stato = 'ispettore';

  return stato;
}

class ActionsIspezione extends Component {

  state = {
  }



  canSendControdeduzioni(i) {
    return !i.controdeduzioni && this.props.progetto.stato === affidamentoStatic.stato_progetto.erogazione &&
      this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.verbale_firmato &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) &&
      this.props.UserStore.user && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.props.progetto.ente.id
  }

  canDownloadControdeduzioni(i) {
    return i.controdeduzioni && i.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.in_corso && this.props.progetto.stato === affidamentoStatic.stato_progetto.erogazione &&
      this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.verbale_firmato &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) &&
      this.props.UserStore.user && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.props.progetto.ente.id
  }

  canViewControdeduzioni(i) {
    return i.controdeduzioni && ((checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && (
      (this.props.UserStore.user && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.props.progetto.ente.id) ||
      (this.props.progetto.istruttore && this.props.progetto.istruttore.length > 0 && this.props.progetto.istruttore[0].id === this.props.UserStore.user.id)
    )) || checkPermission.hasPermission(this.props.UserStore.user, ['manageProgetto']))
  }

  assignrandom() {
    if (!this.state.random_assigning) {
      this.setState({
        random_assigning: true
      }, () => {
        IspezioneService.randomassign(this.props.progetto.id, (res) => {
          if (res.data === null) {
            notification.warning({
              placement: 'topLeft',
              duration: 5,
              message: "Ricerca ispettore",
              description: 'Al momento non ci sono ispettori disponibili',
            });
            this.setState({ random_assigning: false });
          } else {
            this.setState({ random_assigning: false, random_ispettore: res.data.data, open_modal_ispettore_random: true });
          }

        }, () => {
          this.setState({ random_assigning: false });
        });
      })
    }
  }


  assign(ispettore, ispezione) {
    if (!this.state.assigning) {
      this.setState({ assigning: true });
      IspezioneService.assign(this.props.progetto.id, { id_ispettore: ispettore, id_ispezione: ispezione }, (res) => {
        message.success("L'ispettore è stato assegnato correttamente");
        this.props.refresh(false);
        this.setState({ open_modal_ispettori: false, open_modal_ispettore_random: false, assigning: false })
      }, () => this.setState({ assigning: false }));
    }
  }

  addControdeduzione(feedback, note, idIspezione) {
    if (!this.state.adding) {
      this.setState({ adding: true }, () => {
        IspezioneService.addControdeduzioni(
          this.props.progetto.id,
          idIspezione,
          { feedback: feedback, note: note },
          (res) => {
            this.setState({ adding: false })
            this.props.refreshInline(res.data.data);
          },
          () => this.setState({ adding: false })
        )
      })
    }
  }

  getNomineOk(i) {
    return i.nomine ? i.nomine.filter(n => n.stato !== nominaStatic.stato.rifiutato_confermato).sort((a, b) => {
      return a.id > b.id ? 1 : -1
    }) : []
  }

  getNomineKo(i) {
    return i.nomine ? i.nomine.filter(n => n.stato === nominaStatic.stato.rifiutato_confermato).sort((a, b) => {
      return a.id > b.id ? 1 : -1
    }) : []
  }

  canNominateIspettore(i) {
    return this.getNomineOk(i).length < 2 && i.stato === nominaStatic.stato_ispezione.in_corso &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageProgetto']) &&
      this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.ispezione_necessaria
  }

  canEditOwnNomina() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnNomina'])
  }


  saveBlob(blob, title, type) {
    const _blob = new Blob([blob], { type: type });
    FileSaver.saveAs(_blob, title);
  }

  downloadNomina(n) {
    if (!this.state.downloadingNomina) {
      this.setState({
        downloadingNomina: true
      }, () => {
        NominaService.download_word(
          this.props.progetto.id,
          n.id,
          (res) => {
            this.saveBlob(res.data, "dispositivo_di_servizio.odt", 'application/vnd.oasis.opendocument.text');
            this.setState({ downloadingNomina: false })
            message.success("Il dispositivo di servizio è stato scaricato correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ downloadingNomina: false })
        )
      })
    }
  }

  uploadNomina(file, n) {
    if (!this.state.uploadingNomina) {
      this.setState({
        uploadingNomina: true
      }, () => {
        NominaService.upload(
          this.props.progetto.id,
          n.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploadingNomina: false })
            message.success("La nomina è stata inviata correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploadingNomina: false })
        )
      })
    }
  }

  downloadControdeduzioni(i) {
    if (!this.state.downloadingControdeduzioni) {
      this.setState({
        downloadingControdeduzioni: true
      }, () => {
        IspezioneService.getControdeduzioni(
          this.props.progetto.id,
          i.id,
          (res) => {
            this.saveBlob(res.data, 'controdeduzioni.pdf', 'application/pdf');
            this.setState({ downloadingControdeduzioni: false })
            message.success("Le controdeduzioni sono state scaricate correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ downloadingControdeduzioni: false })
        )
      })
    }
  }

  uploadControdeduzioni(file, i) {
    if (!this.state.uploadingControdeduzioni) {
      this.setState({
        uploadingControdeduzioni: true
      }, () => {
        IspezioneService.uploadControdeduzioni(
          this.props.progetto.id,
          i.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploadingControdeduzioni: false })
            message.success("La controdeduzione è stata inviata correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploadingControdeduzioni: false })
        )
      })
    }
  }

  downloadVerbale(idVerbale) {
    this.setState({ downloading_verbale: true }, () => {
      NominaService.download_verbale(
        this.props.progetto.id,
        idVerbale,
        (res) => {
          this.saveBlob(res.data, 'verbale.pdf', 'application/pdf');
          this.setState({
            downloading_verbale: false,
          })
        }, () => this.setState({
          downloading_verbale: false,
        })
      )
    })
  }

  uploadVerbale(file, idVerbale) {
    if (!this.state.uploading_verbale) {
      this.setState({
        uploading_verbale: true
      }, () => {
        NominaService.upload_verbale(
          this.props.progetto.id, idVerbale,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploading_verbale: false })
            message.success("Il verbale è stato caricato correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploading_verbale: false })
        )
      })
    }
  }

  render() {
    return <div>
      {this.props.ispezione &&
        <div>
          {
            <div>
              <div style={{ marginBottom: 16 }}>
                <strong>Stato: {
                  this.props.ispezione.stato_flusso_formatted['stato_' + getStatoNomina(this)]
                }</strong><br />
                {this.props.ispezione.data_termine ? moment(this.props.ispezione.data_termine, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' (data trasmissione verbali)' : ''}
                {this.canSendControdeduzioni(this.props.ispezione) &&
                  <SceltaControdeduzioni
                    loading={this.state.adding}
                    addControdeduzione={(feedback, note) => this.addControdeduzione(feedback, note, this.props.ispezione.id)}
                    data_termine = {this.props.ispezione.data_termine}
                  ></SceltaControdeduzioni>

                }
                {this.canViewControdeduzioni(this.props.ispezione) &&
                  <Controdeduzioni
                    controdeduzioni={this.props.ispezione.controdeduzioni}
                    canView={this.canViewControdeduzioni(this.props.ispezione)}
                    canDownloadControdeduzioni={this.canDownloadControdeduzioni(this.props.ispezione)}
                    data_trasmissione={this.props.ispezione.data_termine}
                    canEditControdeduzioni={false}
                    canSendControdeduzioni={false}
                    downloadControdeduzioni={() => this.downloadControdeduzioni(this.props.ispezione)}
                    uploadControdeduzioni={(file) => this.uploadControdeduzioni(file, this.props.ispezione)}
                    loadingControdeduzioni={this.state.downloadingControdeduzioni}
                    uploadingControdeduzioni={this.state.uploadingControdeduzioni}
                    media={this.props.ispezione.controdeduzioni?.media?.filter(el => el.tipo.sottodominio === comunicazioneOstativaStatic.tipo_media_controdeduzioni.sottodominio_verbale_controdeduzioni)}
                  ></Controdeduzioni>
                }
              </div>
              {this.canNominateIspettore(this.props.ispezione) &&
                <SceltaIspettore
                  ispezione={this.props.ispezione}
                  random_assigning={this.state.random_assigning}
                  assigning={this.state.assigning}
                  assignrandom={() => this.assignrandom()}
                  assign={(ispettore, ispezione) => this.assign(ispettore, ispezione)}
                  open_modal_ispettore_random={this.state.open_modal_ispettore_random}
                  open_modal_ispettori={this.state.open_modal_ispettori}
                  random_ispettore={this.state.random_ispettore}
                  resetRandomIspettore={() => this.setState({ random_ispettore: {} })}
                  closeModal={() => this.setState({ open_modal_ispettore_random: false })}
                ></SceltaIspettore>
              }
              <React.Fragment>
                {
                  this.props.ispezione.verbali && this.props.ispezione.verbali.length > 0 &&

                  <IspezioneVerbale
                    ispezione={this.props.ispezione}
                    download={(idVerbale) => this.downloadVerbale(idVerbale)}
                    upload={(file, id) => this.uploadVerbale(file, id)}
                    uploading={this.state.uploading_verbale}
                    downloading={this.state.downloading_verbale}
                    canEditVerbale={this.canEditOwnNomina()}
                  >

                  </IspezioneVerbale>
                }
                {(this.props.ispezione.nomine && this.props.ispezione.nomine.length > 0) &&
                  <div style={{ marginTop: 8 }}>
                    <IspezioneNomina
                      title={'ISPETTORI'}
                      nomine={this.getNomineOk(this.props.ispezione)}
                      UserStore={this.props.UserStore}
                      downloadNomina={(n) => this.downloadNomina(n)}
                      uploadNomina={(file, n) => this.uploadNomina(file, n)}
                      downloadingNomina={this.state.downloadingNomina}
                      uploadingNomina={this.state.uploadingNomina}
                      stato={getStatoNomina(this)}
                    />
                  </div>
                }
                {(this.props.ispezione.nomine && this.props.ispezione.nomine.length > 0) && this.getNomineKo(this.props.ispezione).length > 0 && <div style={{ marginTop: 8 }}>
                  <IspezioneNomina
                    title={'NOMINE RIFIUTATE'}
                    nomine={this.getNomineKo(this.props.ispezione)}
                    UserStore={this.props.UserStore}
                    downloadNomina={(n) => this.downloadNomina(n)}
                    uploadNomina={(file, n) => this.uploadNomina(file, n)}
                    downloadingNomina={this.state.downloadingNomina}
                    uploadingNomina={this.state.uploadingNomina}
                    stato={getStatoNomina(this)}
                  /></div>

                }

              </React.Fragment></div>
          }
        </div>}
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ActionsIspezione);