export default {

    stato : {
        attivo : 1,
        disattivo : 2
    },

    getSezioneFromSottosezione(sottosezione) {
        let sezione = '';
        switch (sottosezione) {
            case "Rappresentante legale": sezione = 1; break;
            case "Ente": sezione = 1; break;
            case "Caratteristiche sede": sezione = 2; break;
            case "Attrezzature": sezione = 2; break;
            case "Requisiti aule": sezione = 2; break;
            case "Capacità gestionali": sezione = 3; break;
            case "Affidabilità economica e finanziaria": sezione = 4; break;
            case "Qualità": sezione = 5; break;
            default: break;
        }
        return sezione;
    },

    getDominioFromSottosezione(sottosezione) {
        let dominio = '';
        switch (sottosezione) {
            case "Rappresentante legale": dominio = "ente"; break;
            case "Ente": dominio = "ente"; break;
            case "Caratteristiche sede": dominio = "sede"; break;
            case "Attrezzature": dominio = "ente_sede_attrezzatura"; break;
            case "Requisiti aule": dominio = "requisiti_aule"; break;
            case "Capacità gestionali": dominio = "rapporto_lavoro"; break;
            case "Affidabilità economica e finanziaria": dominio = "ente"; break;
            case "Qualità": dominio = "ente"; break;
            default: break;
        }
        return dominio;
    },

    getSottoDominioFromSottosezione(sottosezione) {
        let sottodominio = '';
        switch (sottosezione) {
            case "Rappresentante legale": sottodominio = "rappresentante_legale"; break;
            case "Ente": sottodominio = "documenti_ente"; break;
            case "Caratteristiche sede": sottodominio = "caratteristiche_sede"; break;
            case "Attrezzature": sottodominio = "attrezzature"; break;
            case "Requisiti aule": sottodominio = "documenti_requisiti_aule"; break;
            case "Capacità gestionali": sottodominio = "documenti_capacita_gestionali"; break;
            case "Affidabilità economica e finanziaria": sottodominio = "affidabilita_economica"; break;
            case "Qualità": sottodominio = "qualita"; break;
            default: break;
        }
        return sottodominio;
    },

    sottodominiAggiuntivi: [
        "rappresentante_legale",
        "documenti_ente",
        "caratteristiche_sede",
        "attrezzature",
        "documenti_requisiti_aule",
        "documenti_capacita_gestionali",
        "affidabilita_economica",
        "qualita"
    ],

    filteredAccreditationMedia(tipi_media, tipo_accreditamento){
        return tipi_media.filter(el => { return el.stato === this.stato.attivo && el.tipo_accreditamento.includes(tipo_accreditamento + '') })
    },

    filteredRequiredMedia(tipi_media, dominio, sottodominio){
        return tipi_media.filter(el => { return el.dominio === dominio &&
            el.sottodominio === sottodominio && el.obbligatorieta && el.stato === this.stato.attivo }
            ).map(el=>el.tipo_media);
    },

    filteredRequiredCodiceMedia(tipi_media, dominio, sottodominio){
        return tipi_media.filter(el => { return el.dominio === dominio &&
            el.sottodominio === sottodominio && el.obbligatorieta && el.stato === this.stato.attivo }
            ).map(el=>el.codice);
    },

    filteredRequiredMediaCodice(tipi_media, dominio, sottodominio, codice){
        return tipi_media.filter(el => { return el.dominio === dominio &&
            el.sottodominio === sottodominio &&
            el.codice === codice && el.obbligatorieta && el.stato === this.stato.attivo }
            ).map(el=>el.codice);
    },

    filteredOptionalMedia(tipi_media, dominio, sottodominio){
        return tipi_media.filter(el => { return el.dominio === dominio &&
            el.sottodominio === sottodominio && el.stato === this.stato.attivo }
            ).map(el=>el.tipo_media);
    },


    thereAreMediasFor(tipi_media, dominio, sottodominio, tipo_accreditamento){
        return tipi_media.find(el =>
            el.dominio === dominio &&
            el.sottodominio === sottodominio
            && el.stato === this.stato.attivo
            && el.tipo_accreditamento.includes(tipo_accreditamento + '')) ? true : false;
    }
}