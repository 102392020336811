import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, message, Modal, Checkbox } from 'antd';
import ModelServices from '@accreditamento/services/Ispezione';
import checkPermission from '@common/utils/check/permissions';

class SceltaSedi extends Component {

  state = {
    loaded: false,
    loading: false,
    assigning: false,
    selecting_sede: false,
    selected_sedi: []
  }

  render() {
    return (this.props.accreditamento.stato_flusso === 7 && checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions'])) ?
      <div>
         <Modal
          title="Seleziona le sedi per cui è necessario l'accreditamento"
          visible={this.state.selecting_sede}
          onOk={async () => {

            if(!this.state.loading) {
              this.setState({
                loading: true,
                ispezione_ok: true
              }, () => {
                if (this.state.selected_sedi && this.state.selected_sedi.length === 0) {
                  message.error("Seleziona almeno una sede")
                  this.setState({ loading: false, ispezione_ok: false })
                  return
                }
                ModelServices.ispezione_sedi(
                  this.props.accreditamento.id, { sedi: this.state.selected_sedi.map(s=>s.id).join(',') },
                  (res) => {
                    this.setState({loading: false, selecting_sede: false, ispezione_ok: false})
                    this.props.refreshInline({...res.data.data})
                  }, () => this.setState({loading: false, selecting_sede: false, ispezione_ok: false}))
              })
            }
          }}
          onCancel={() => this.setState({ selecting_sede: false })}
        >
          {
            this.props.accreditamento.sedi.map(sede => {
              return <p><Checkbox
                  checked={this.state.selected_sedi.find(s=>s.id === sede.id) ? true : false}
                  onChange={()=> {

                    if(this.state.selected_sedi.find(s=>s.id === sede.id)) {
                      this.setState({selected_sedi: this.state.selected_sedi.filter(s => s.id !== sede.id) })
                    } else {
                      this.setState({selected_sedi: [...this.state.selected_sedi, sede]})
                    }
                  }}
                >
                  {sede.indirizzo.via} {sede.indirizzo.ncivico} {sede.indirizzo.locComune.comune} {sede.indirizzo.locComune.provincia_sigla}
                </Checkbox></p>
            })
          }
          {
            (this.props.accreditamento.sedi.length === 0)
            ?
            <p>Non ci sono sedi legate alla richiesta, l&apos;ispezione non è necessaria</p>
            : null
          }
        </Modal>
        <Button
          onClick={()=>this.setState({selecting_sede: true}) }
          disabled={this.state.loading} loading={this.state.ispezione_ok} className="m5h" type="primary" icon="check" style={{ marginRight: 8 }}>Seleziona le sedi in cui effettuare l&apos;ispezione</Button>
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(SceltaSedi);