import React, { Component } from 'react';
import { Button, Card, Popconfirm, Tabs, message } from 'antd';
import FileSaver from 'file-saver';
import { connect } from "react-redux";
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import auditStatic from '@common/utils/static/audit';
import CommonForm from '@common/components/form';
import ModelServices from '../services/Edizione';
import ModelServicesProgetto from '../services/Progetto';
import IspezioneService from '../services/Ispezione';
import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';

import DocSpan from '@common/components/DocSpan';
import AuditDocumentale from './audit/AuditDocumentale';
import AuditService from '../services/AuditProgetto';
import ComunicazioneOstativa from './comunicazione-ostativa/ComunicazioneOstativa';
import Controdeduzioni from './controdeduzioni/Controdeduzioni';
import EsitoProgetto from './ProgrammazioneDidattica/edizione/EsitoProgetto';
import FormDecreto from './ProgrammazioneDidattica/edizione/FormDecreto';
import DownloadUpload from '@common/components/DownloadUpload';
import ActionsIspezione from './ispezione/ActionsIspezione';
import ProgrammaInizioCorso from './Actions/ProgrammaInizioCorso';
import formatter from '@common/utils/formatters/_';
import moment from 'moment';
import EsitoIspezione from './EsitoIspezione';
import SceltaIspezione from './ispezione/SceltaIspezione';

const TabPane = Tabs.TabPane;

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              customRequest: 'customUploadRequest', // sovrascrive upload
              filename: 'file',
              text: "Carica file p7m o pdf firmato",
              props: {
                showUploadList: true
              }
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'btn_submit',
              type: 'submit',
              text: 'Invia richiesta apertura candidature',
              btn_type: 'primary',
              showIf: [
                {
                  type: 'prop_func',
                  func: 'checkOpenRegistrationUploaded'
                },
              ],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};

const openRegistrationormLayout = (component) => {
  return {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [{
            type: 'datepicker',
            label: 'Data apertura candidature',
            name: 'data_apertura_candidature',
            format: 'DD/MM/YYYY',
            props: {
              size: 'normal',
              style: { width: '100%' },
              disabled: component.state.loading
            },
            showIf: [
              {
                type: 'prop_func',
                func: 'checkCanSetDate'
              },
            ],
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio',
              },
              {
                validator: 'min_date'
              },
            ],
          },
          {
            type: 'datepicker',
            label: 'Data chiusura candidature',
            name: 'data_chiusura_candidature',
            format: 'DD/MM/YYYY',
            props: {
              size: 'normal',
              style: { width: '100%' },
              disabled: component.state.loading
            },
            showIf: [
              {
                type: 'prop_func',
                func: 'checkCanSetDate'
              },
            ],
            validations: [
              {
                type: 'required',
                error_message: 'Campo obbligatorio',
              },
              {
                validator: 'min_date'
              },
            ],
          },
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'btn_submit_open_registration',
              type: 'submit',
              text: 'Conferma il periodo di candidatura',
              btn_type: 'primary',
              showIf: [
                {
                  type: 'prop_func',
                  func: 'checkCanSetDate'
                },
              ],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
}};

class ActionsEdizione extends Component {

  state = {
    starting: false,
    loaded: false,
    downloadingComunicazione: false,
    approvingOpenRegistration: false,
    rejectingOpenRegistration: false,
    confirmOpenRegistration: false,
    prepareCourse: false,
    progetto: {}
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.progetto !== this.props.progetto) {
      this.loaded(this.props);
    }
  }

  componentDidMount() {
    if (this.props.progetto) this.loaded(this.props);
  }

  loaded(props) {
    this.setState({ progetto: props.progetto, loaded: true });
  }

  saveBlob(blob, title) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, title);
  }

  saveBlobWord(blob) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, "controdeduzioni.odt");
  }

  renderRequestFileFirmato() {
    return <div style={{ marginTop: 18 }}>
      {
        this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_dichiarazione_accettazione_firmata).map(l => {
          return <div key={'prog_' + l.id}>
            <p style={{ display: 'inline-block' }}>Domanda di autorizzazione all'avvio firmata: </p>
            <DocSpan key={l.id} file={l} />
            {l.note && l.note.map(n => {
              return <div key={"nota_" + n.id}><small><strong>{n.user ? n.user.anagrafica?.nome + ' ' + n.user.anagrafica?.cognome : " - "}:</strong> {n.note}</small></div>
            })}
          </div>
        })
      }
    </div>
  }

  renderPubbicizzazioneFile() {

    let media=this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_richiesta_pubblicizzazione_corso);

    return media && <div>
    {
      media.map(l => {
        return <div key={'com_ost_' + l.id}>
          <p style={{ display: 'inline-block' }}>Richiesta pubblicizzazione del corso: </p>
          <DocSpan key={l.id} file={l} />
        </div>
      })
    }
  </div>
  }

  renderDecretoFileFirmato() {

    let media=this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_decreto_autorizzativo);

    return media && <div>
    {
      media.map(l => {
        return <div key={'com_ost_' + l.id}>
          <p style={{ display: 'inline-block' }}>Decreto autorizzativo all'erogazione del corso firmato: </p>
          <DocSpan key={l.id} file={l} />
        </div>
      })
    }
  </div>
  }

  //  gestione valutazione richiesta di pubblicizzazione corsi - Attore: Istruttore
  renderValuateOpenRegistrationAction() {
    return (this.props.progetto.stato === affidamentoStatic.stato_progetto.avvio_progetto && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.preparazione_registrazioni)
      ?
      <div>
        {
          this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_richiesta_pubblicizzazione_corso).map(l => {
            return <div key={'prog_' + l.id}>
              <p style={{ display: 'inline-block' }}>Richiesta apertura candidature firmata: </p>
              <DocSpan key={l.id} file={l} />
              {l.note && l.note.map(n => {
                return <div key={"nota_" + n.id}><small><strong>{n.user ? n.user.anagrafica?.nome + ' ' + n.user.anagrafica?.cognome : " - "}:</strong> {n.note}</small></div>
              })}
            </div>
          })
        }
        <Button
          loading={this.state.approvingOpenRegistration}
          disabled={this.state.loading}
          className="m5h"
          type="primary"
          icon="check"
          onClick={() => {
            if (!this.state.loading) {
              this.setState({
                loading: true, approvingOpenRegistration: true
              }, () => {
                ModelServices.approveOpenRegistration(
                  this.props.progetto.id,
                  { id_user: this.props.progetto.istruttore[0] ? this.props.progetto.istruttore[0].id : this.props.progetto.rup[0].id},
                  (res) => {
                    message.success("Apertura candidature approvata");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  }, () => {
                    this.endLoading();
                  }
                )
              })
            }
          }}
          style={{ marginRight: 8 }}>
          Approva apertura candidature
        </Button>
        <Button
          loading={this.state.rejectingOpenRegistration}
          disabled={this.state.loading}
          className="m5h"
          type="danger"
          icon="close"
          style={{ marginRight: 8 }}
          onClick={() => {
            if (!this.state.loading) {
              this.setState({
                loading: true, rejectingOpenRegistration: true
              }, () => {
                ModelServices.rejectOpenRegistration(
                  this.props.progetto.id,
                  (res) => {
                    message.success("Richiesta nuova documentazione");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  }, () => {
                    this.endLoading();
                  }
                )
              })
            }
          }}>
          Richiedi nuova documentazione
        </Button>
      </div>
      :
      null
  }

  //  gestione conferma richiesta di pubblicizzazione corsi - Attore: Dirigente
  renderConfirmOpenRegistrationAction() {
    return (this.props.progetto.stato === affidamentoStatic.stato_progetto.avvio_progetto && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.approvazione_registrazioni)
      ?
      <div>

        {
          this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_richiesta_pubblicizzazione_corso).map(l => {
            return <div key={'prog_' + l.id}>
              <p style={{ display: 'inline-block' }}>Richiesta apertura candidature firmata: </p>
              <DocSpan key={l.id} file={l} />
              {l.note && l.note.map(n => {
                return <div key={"nota_" + n.id}><small><strong>{n.user ? n.user.anagrafica?.nome + ' ' + n.user.anagrafica?.cognome : " - "}:</strong> {n.note}</small></div>
              })}
            </div>
          })
        }
        <Button
          loading={this.state.confirmOpenRegistration}
          disabled={this.state.loading}
          className="m5h"
          type="primary"
          icon="check"
          onClick={() => {
            if (!this.state.loading) {
              this.setState({
                loading: true, confirmOpenRegistration: true
              }, () => {
                ModelServices.approveOpenRegistration(
                  this.props.progetto.id,
                  { id_user: this.props.UserStore.user.id },
                  (res) => {
                    message.success("Apertura candidature confermata");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  }, () => {
                    this.endLoading();
                  }
                )
              })
            }
          }}
          style={{ marginRight: 8 }}>
          Conferma apertura candidature
          </Button>
      </div>
      :
      null
  }

  renderEnteActions() {
    return (this.props.progetto.stato === affidamentoStatic.stato_progetto.predisposizione && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.predisposizione && this.state.progetto.progress.valid)
      ?
      <div>
        <p>Se hai completato la lavorazione del progetto procedi con la conferma</p>
        <Popconfirm placement="top" title="Confermi i dati inseriti?"
          onConfirm={() => {

            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.confirm(
                  this.props.progetto.id_progetto,
                  this.props.progetto.id,
                  (res) => {
                    message.success("Confermato correttamente");
                    //this.props.refresh(false);
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  }, () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Conferma lavorazione</Button>
        </Popconfirm>
      </div>
      :
      (this.props.progetto.stato === affidamentoStatic.stato_progetto.predisposizione
        && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.compilato
        && !this.state.progetto.media.find(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_dichiarazione_accettazione_firmata))
        ?
        <div>
          <DownloadUpload
            accept={'.p7m,.pdf'}
            downloading={this.state.downloading}
            uploading={this.state.uploading}
            download={() => {
              this.setState({ downloading: true }, () => {
                ModelServices.printPdf(
                  this.props.progetto.id_progetto,
                  this.props.progetto.id,
                  (res) => {
                    this.saveBlob(res.data, "dichiarazione_accettazione.pdf");
                    //this.props.refresh(false);
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  }, () => this.endLoading()
                )
              })
            }}
            upload={(file) => this.uploadFile(file)}
          >
          </DownloadUpload>

        </div>
        :
        (this.props.progetto.stato === affidamentoStatic.stato_progetto.predisposizione
          && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.firmato
          && this.state.progetto.media.find(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_dichiarazione_accettazione_firmata))
          && this.props.isEdizionePagata
          ?
          <div>
            <div>
              <Popconfirm placement="top" title="Conferma l’invio"
                onConfirm={() => {
                  if (!this.state.loading) {
                    this.setState({
                      sending: true,
                      loading: true
                    }, () =>
                      ModelServices.send(
                        this.state.progetto.id_progetto, this.state.progetto.id,
                        (res) => {
                          message.success("Invio avvenuto con successo");
                          //this.props.refresh(false);
                          this.props.refreshInline(res.data.data);
                          this.endLoading();
                        },
                        () => this.endLoading()
                      )
                    )
                  }
                }}
                okText="Si" cancelText="No">
                <Button
                  loading={this.state.sending}
                  disabled={this.state.loading}
                  className="m5h" type="primary" icon="mail">Invia all&apos;amministrazione</Button>
              </Popconfirm>
            </div>
          </div>
          :
          this.props.progetto.stato === affidamentoStatic.stato_progetto.avvio_progetto
            && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.registrazione_necessaria
            ?
            <div>
              <p>Firmare digitalmente e caricare il documento di pubblicizzazione del corso</p>
              <CommonForm
                wrappedComponentRef={inst => (this.formRef = inst)}
                loading={this.state.loading || false}
                form_model={formLayout}
                file={this.state.file?.name}
                customUploadRequest={obj => {
                  this.uploadRequestOpenRegistration(obj);
                }}

                submitted={data => {
                  this.requestOpenRegistration(data);
                }}

                checkOpenRegistrationUploaded={(data) => {
                  return this.state.uploaded;
                }}
              />
            </div>
            :
            this.props.progetto.stato === affidamentoStatic.stato_progetto.avvio_progetto
              && this.props.progetto.stato_flusso >= affidamentoStatic.stato_flusso_progetto.preparazione_registrazioni
              && this.props.progetto.stato_flusso <= affidamentoStatic.stato_flusso_progetto.registrazioni_confermate
              ?
              <div>
                {
                  this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_richiesta_pubblicizzazione_corso).map(l => {
                    return <div key={'prog_' + l.id}>
                      <p style={{ display: 'inline-block' }}>Richiesta apertura candidature firmata: </p>
                      <DocSpan key={l.id} file={l} />
                      {l.note && l.note.map(n => {
                        return <div key={"nota_" + n.id}><small><strong>{n.user ? n.user.anagrafica?.nome + ' ' + n.user.anagrafica?.cognome : " - "}:</strong> {n.note}</small></div>
                      })}
                    </div>
                  })
                }
                <CommonForm
                  wrappedComponentRef={inst => (this.formNoteRef = inst)}
                  loading={this.state.loading || false}
                  form_model={openRegistrationormLayout(this)}

                  submitted={data => {
                    this.setOpenRegistrationDate(data);
                  }}

                  checkCanSetDate={(data) => {
                    return this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.registrazioni_confermate;
                  }}

                  min_date={(rule, value, cb) => {
                    if (!value || value.length === 0) cb();
                    if (moment().diff(value, 'days') > 0) cb("La data inserita non può essere precedente alla data odierna");
                    else cb();
                  }}
                />
              </div>
              :
              this.props.progetto.stato === affidamentoStatic.stato_progetto.avvio_progetto
                && this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_in_preparazione
                ?
                <div>
                  <p>Inserisci la data di inizio corso</p>
                  <ProgrammaInizioCorso
                    {...this.props}
                    loading={this.state.loading || false}
                    id_progetto={this.state.progetto.id}
                  />
                </div>
                :
                null
  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload() {
    if (!this.state.loading) {

      this.setState({
        loading: true,
        uploading: true
      }, () => {
        ModelServices.upload(this.state.progetto.id_progetto, this.state.progetto.id, {
          filename: 'file',
          note: this.state.note || "",
        }, { ...this.state.file, chiave: 'file' }, (res) => {

          message.success("La dichiarazione è stata caricata correttamente");
          //this.props.refresh(false);
          this.props.refreshInline(res.data.data);
          this.endLoading();
          this.setState({ open_modal_doc: false });

        }, () => { this.endLoading() })
      })
    }
  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  requestOpenRegistration(data) {
    if (!this.state.loading) {

      this.setState({
        loading: true,
        uploading: true
      }, () => {
        ModelServices.requestOpenRegistration(this.state.progetto.id, {
          filename: 'file',
          data_chiusura_candidature: moment(data.data_chiusura_candidature).format('YYYY-MM-DD')
        }, { ...this.state.file, chiave: 'file' }, (res) => {

          message.success("La richiesta di pubblicizzazione è stata caricata correttamente");
          this.props.refreshInline(res.data.data);
          this.endLoading();
          this.setState({ open_modal_doc: false });

        }, () => { this.endLoading() })
      })
    }
  }

  /**
   * Inserisci data apertura iscrizioni
   * @return {[type]} [description]
   */
  setOpenRegistrationDate(data) {
    if (!this.state.loading) {

      this.setState({
        loading: true,
        uploading: true
      }, () => {
        ModelServices.setOpenRegistrationDate(this.state.progetto.id,
          {
            data_apertura_candidature: moment(data.data_apertura_candidature).format('YYYY-MM-DD'),
            data_chiusura_candidature: moment(data.data_chiusura_candidature).format('YYYY-MM-DD')
          },
          (res) => {
            message.success("Data apertura candidature trasmessa correttamente");
            this.props.refreshInline(res.data.data);
            this.endLoading();
            this.setState({ open_modal_doc: false });

          }, () => { this.endLoading() })
      })
    }
  }

  /**
   * resetta gli stati di invio
   * @return {[type]} [description]
   */
  endLoading() {
    this.setState({
      loading: false,
      uploading: false,
      uploaded: false,
      confirming: false,
      sending: false,
      downloading: false,
      integrating: false,
      rejectingOpenRegistration: false,
      approvingOpenRegistration: false,
      confirmOpenRegistration: false
    })
  }

  uploadFile(file) {
    this.setState({
      file: { file: file, name: file.name },
      filename: 'file',
    }, () => this.upload())
    return;
  }

  uploadRequestOpenRegistration(data) {
    this.setState({
      file: { file: data.file, name: data.file.name },
      filename: 'file',
    }/*, () => this.requestOpenRegistration(data)*/)
    this.setState({ uploaded: true });
    return;
  }

  startAudit() {
    if (!this.state.starting) {
      this.setState({ starting: true }, () => {
        AuditService.start(
          this.state.progetto.id,
          (res) => {
            message.success("L'audit è iniziato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
            this.setState({ starting: false });
          },
          () => {
            this.setState({ starting: false });
          });
      });
    }
  }

  approve(id_audit) {
    if (!this.state.approving) {
      this.setState({
        approving: true
      }, () => {
        AuditService.prepareApprove(
          this.state.progetto.id,
          id_audit,
          (res) => {
            this.setState({ approving: false })
            message.success("Approvato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ approving: false })
        )
      })
    }
  }

  approveControdeduzioni() {
    if (!this.state.approving_controdeduzioni) {
      this.setState({
        approving_controdeduzioni: true
      }, () => {
        AuditService.approveControdeduzioni(
          this.state.progetto.id,
          (res) => {
            this.setState({ approving_controdeduzioni: false })
            message.success("Approvato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ approving_controdeduzioni: false })
        )
      })
    }
  }

  refuse(id_audit, note) {
    if (!this.state.refusing) {
      this.setState({ refusing: true }, () => {
        AuditService.suspendComunicazioneOstativa(
          this.state.progetto.id,
          id_audit,
          { note: note },
          (res) => {
            this.setState({ refusing: false });
            message.success("Rigettato correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ refusing: false })
        )
      })
    }
  }

  suspend(id_audit, note) {
    if (!this.state.suspending) {
      this.setState({ suspending: true }, () => {
        AuditService.suspend(
          this.state.progetto.id,
          id_audit,
          { note: note },
          (res) => {
            this.setState({ suspending: false });
            message.success("Sospeso correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ suspending: false })
        )
      })
    }
  }

  requireIntegration(id_audit) {
    if (!this.state.requiring) {
      this.setState({ requiring: true }, () => {
        AuditService.integration(
          this.state.progetto.id,
          id_audit,
          (res) => {
            this.setState({ requiring: false })
            message.success("La richiesta di integrazione è stata inviata correttamente");
            this.props.refreshInline(res.data.data);

          },
          (err) => this.setState({ requiring: false })
        )
      })
    }
  }

  integration(id_audit) {
    if (!this.state.integrating) {
      this.setState({
        integrating: true,
      }, () => {
        AuditService.confirm_integrazione(
          this.state.progetto.id, id_audit,
          (res) => {
            this.setState({ integrating: false });
            message.success("Riportato in lavorazione correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => { this.setState({ integrating: false }) }
        )
      }
      )
    }
  }

  downloadComunicazione() {
    if (!this.state.downloadingComunicazione) {
      this.setState({
        downloadingComunicazione: true
      }, () => {
        ModelServicesProgetto.download_ostativi_word(
          this.state.progetto.id,
          (res) => {
            this.saveBlobWord(res.data, 'ostativi.odt')
            this.setState({ downloadingComunicazione: false })
          },
          () => this.setState({ downloadingComunicazione: false })
        )
      })
    }
  }

  uploadComunicazione(file) {
    if (!this.state.uploadingComunicazione) {
      this.setState({
        uploadingComunicazione: true
      }, () => {
        AuditService.uploadComunicazione(
          this.state.progetto.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploadingComunicazione: false })
            message.success("La comunicazione ostativa è stata inviata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploadingComunicazione: false })
        )
      })
    }
  }

  downloadDecreto() {
    if (!this.state.downloading_decreto) {
      this.setState({
        downloading_decreto: true
      }, () => {
        AuditService.downloadDecreto(
          this.state.progetto.id,
          (res) => {
            this.saveBlob(res.data, "Decreto_autorizzativo.pdf");
            this.setState({ downloading_decreto: false })
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ downloading_decreto: false })
        )
      })
    }
  }

  uploadDecreto(file) {
    if (!this.state.uploading_decreto) {
      this.setState({
        uploading_decreto: true
      }, () => {
        AuditService.uploadDecreto(
          this.state.progetto.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploading_decreto: false })
            message.success("Il decreto autorizzativo è stato caricato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploading_decreto: false })
        )
      })
    }
  }

  editControdeduzioni(data) {
    if (!this.state.editingControdeduzioni) {
      this.setState({
        editingControdeduzioni: true
      }, () => {
        AuditService.editControdeduzioni(
          this.state.progetto.id,
          this.state.progetto.comunicazioneOstativa.id,
          { note: data.note },
          (res) => {
            this.setState({ editingControdeduzioni: false })
            message.success("La controdeduzione è stata salvata");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ editingControdeduzioni: false });
            if(err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  downloadControdeduzioni() {
    if (!this.state.loadingControdeduzioni) {
      this.setState({
        loadingControdeduzioni: true
      }, () => {
        AuditService.downloadControdeduzioni(
          this.state.progetto.id,
          this.state.progetto.comunicazioneOstativa.id,
          (res) => {
            this.saveBlobWord(res.data);
            this.setState({ loadingControdeduzioni: false })
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ loadingControdeduzioni: false });
            if(err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  uploadControdeduzioni(file) {
    if (!this.state.uploadingControdeduzioni) {
      this.setState({
        uploadingControdeduzioni: true
      }, () => {
        AuditService.uploadControdeduzioni(
          this.state.progetto.id,
          this.state.progetto.comunicazioneOstativa.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploadingControdeduzioni: false })
            message.success("La controdeduzione è stata caricata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ uploadingControdeduzioni: false })
            if(err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  sendControdeduzioni() {
    if (!this.state.sendingControdeduzioni) {
      this.setState({
        sendingControdeduzioni: true
      }, () => {
        AuditService.sendControdeduzioni(
          this.state.progetto.id,
          this.state.progetto.comunicazioneOstativa.id,
          (res) => {
            this.setState({ sendingControdeduzioni: false })
            message.success("La controdeduzione è stata inviata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ sendingControdeduzioni: false });
            if(err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  prepareReject() {
    if (!this.state.rejecting) {
      this.setState({
        rejecting: true
      }, () => {
        AuditService.prepareReject(
          this.state.progetto.id,
          (res) => {
            this.setState({ rejecting: false })
            message.success("Rigettato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ rejecting: false })
        )
      })
    }
  }

  confirmReject() {
    if (!this.state.confirming_reject) {
      this.setState({
        confirming_reject: true
      }, () => {
        AuditService.confirmReject(
          this.state.progetto.id,
          (res) => {
            this.setState({ confirming_reject: false })
            message.success("Rigettato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ confirming_reject: false })
        )
      })
    }
  }

  confirmApprove() {
    if (!this.state.confirming_approve) {
      this.setState({
        confirming_approve: true
      }, () => {
        AuditService.confirmApprove(
          this.state.progetto.id,
          (res) => {
            this.setState({ confirming_approve: false })
            message.success("Approvato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ confirming_approve: false })
        )
      })
    }
  }

  accept() {
    if (!this.state.accepting) {
      this.setState({
        accepting: true,
      }, () => {
        IspezioneService.accept(this.state.progetto.id,
          (res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ accepting: false, })
            message.success("La conferma è stata effettuata correttamente");
          },
          () => this.setState({ accepting: false })
        )
      })
    }
  }

  suspendIspezione() {
    if (!this.state.suspending) {
      this.setState({
        suspending: true,
      }, () => {
        IspezioneService.suspend(this.state.progetto.id,
          (res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ suspending: false, })
            message.success("Il corso è stato sospeso correttamente");
          },
          () => this.setState({ suspending: false })
        )
      })
    }
  }

  refuseIspezione() {
    if (!this.state.refusing) {
      this.setState({
        refusing: true,
      }, () => {
        IspezioneService.refuse(this.state.progetto.id,
          (res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ refusing: false, })
            message.success("Il corso è stato chiuso correttamente");
          },
          () => this.setState({ refusing: false })
        )
      })
    }
  }

  confirmSuspendIspezione() {
    if (!this.state.confirming) {
      this.setState({
        confirming: true,
      }, () => {
        IspezioneService.confirmSuspend(this.state.progetto.id,
          (res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ confirming: false, })
            message.success("Il corso è stato sospeso correttamente");
          },
          () => this.setState({ confirming: false })
        )
      })
    }
  }

  confirmRefuseIspezione() {
    if (!this.state.confirming) {
      this.setState({
        confirming: true,
      }, () => {
        IspezioneService.confirmRefuse(this.state.progetto.id,
          (res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ confirming: false, })
            message.success("Il corso è stato chiuso correttamente");
          },
          () => this.setState({ confirming: false })
        )
      })
    }
  }

  restartProject() {
    if (!this.state.confirming) {
      this.setState({
        confirming: true,
      }, () => {
        IspezioneService.restartProject(this.state.progetto.id,
          (res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ confirming: false, })
            message.success("Il corso è stato riavviato correttamente");
          },
          () => this.setState({ confirming: false })
        )
      })
    }
  }

  isValidated() {
    if (!this.state.progetto.section_feedback)
      return false;

    return Object.keys(this.state.progetto.progress.showed_sections).every(x =>
      !this.state.progetto.progress.showed_sections[x] || this.state.progetto.section_feedback[x]
    );
  }

  canGiveFeedback() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editAudit']) &&
      this.state.progetto.istruttore && this.state.progetto.istruttore.length > 0 && this.state.progetto.istruttore[0].id === this.props.UserStore.user.id &&
      this.isValidated() && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato
  }

  canGiveFeedbackRup() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editAudit']) &&
      this.state.progetto.rup && this.state.progetto.rup.length > 0 && this.state.progetto.rup[0].id === this.props.UserStore.user.id &&
      this.isValidated() && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato && this.state.progetto.istruttore.length == 0
  }

  canView() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, [
        'manageOwnProgetto']) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && (
        (this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.state.progetto.ente.id) ||
        (this.state.progetto.istruttore && this.state.progetto.istruttore.length > 0 && this.state.progetto.istruttore[0].id === this.props.UserStore.user.id)
      )
      ));
  }

  canStartAudit() {
    let ist=this.isAssignedIstruttore();
    let rup=this.isAssignedRup();
    return checkPermission.hasPermission(this.props.UserStore.user, ['addAudit']) && (ist == true || rup == true);
  }

  isAssignedRup() {
    return (this.state.progetto.rup && this.state.progetto.rup.length > 0 && this.state.progetto.rup[0].id === this.props.UserStore.user.id);
  }

  isAssignedIstruttore() {
    return (this.state.progetto.istruttore && this.state.progetto.istruttore.length > 0 && this.state.progetto.istruttore[0].id === this.props.UserStore.user.id);
  }

  canSendIntegration() {
    return this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.integrazioni &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.state.progetto.ente.id
  }

  canRequireIntegration() {
    return (this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.sospeso &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && checkPermission.hasPermission(this.props.UserStore.user, ['dirigenteAmministrativoPermissions']))
  }

  canSendComunicaizoneOstativa() {
    return (this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.comunicazione_ostativa_richiesta &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && checkPermission.hasPermission(this.props.UserStore.user, ['dirigenteAmministrativoPermissions']))
  }

  canEditControdeduzioni() {
    return this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.comunicazione_ostativa_inviata && this.state.progetto.comunicazioneOstativa?.controdeduzioni?.stato === comunicazioneOstativaStatic.stato_controdeduzioni.in_corso &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.state.progetto.ente.id
  }

  canUploadControdeduzioni() {
    return this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.comunicazione_ostativa_inviata && this.state.progetto.comunicazioneOstativa?.controdeduzioni?.stato === comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.state.progetto.ente.id
  }

  canUploadDecreto() {
    return (this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.avvio_progetto_predisposto &&
      !this.state.progetto?.media?.find(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_decreto_autorizzativo) &&
      this.state.progetto.id_tipo_finanziamento === affidamentoStatic.tipo_finanziamento.autofinanziato && !this.state.progetto.id_edizione_precedente &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && checkPermission.hasPermission(this.props.UserStore.user, ['dirigenteAmministrativoPermissions']))
  }

  canInsertDecretoInfo() {
    return (
      this.state.progetto?.media?.find(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_decreto_autorizzativo) &&
      this.state.progetto.id_tipo_finanziamento === affidamentoStatic.tipo_finanziamento.autofinanziato && !this.state.progetto.id_edizione_precedente &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageProgetto']))
  }

  canSendControdeduzioni() {
    return this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.comunicazione_ostativa_inviata && this.state.progetto.comunicazioneOstativa?.controdeduzioni?.stato === comunicazioneOstativaStatic.stato_controdeduzioni.firmata &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.state.progetto.ente.id
  }

  canEvaluate() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editAudit']) && (
      this.state.progetto.istruttore && this.state.progetto.istruttore.length > 0 && this.state.progetto.istruttore[0].id === this.props.UserStore.user.id 
      || this.state.progetto.rup && this.state.progetto.rup.length > 0 && this.state.progetto.rup[0].id === this.props.UserStore.user.id) &&
      this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.controdeduzioni_inviate;
  }

  canConfirmApprove() {
    return (this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.avvio_progetto_predisposto &&
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigenteAmministrativoPermissions']) && checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) &&
      (this.state.progetto.id_tipo_finanziamento === affidamentoStatic.tipo_finanziamento.finanziato || this.state.progetto.id_edizione_precedente || this.state.progetto?.media?.find(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_decreto_autorizzativo)))
  }

  canConfirmReject() {
    return (this.state.progetto.stato === affidamentoStatic.stato_progetto.avvio_sospeso && this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.rigetto_predisposto &&
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigenteAmministrativoPermissions']) && checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']))
  }

  canEvaluateIspezione() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) &&
      this.state.progetto.istruttore && this.state.progetto.istruttore.length > 0 && this.state.progetto.istruttore[0].id === this.props.UserStore.user.id &&
      this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.attesa_esito;
  }

  canConfirmSuspendIspezione() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) &&
      this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_sospeso;
  }

  canConfirmRefuseIspezione() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) &&
      this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.revoca_corso;
  }

  canRestartProject() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['restartCourse']) &&
      this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.conferma_sospeso;
  }

  canSceltaIspezione() {
    return this.state.progetto.stato === affidamentoStatic.stato_progetto.erogazione &&
      this.state.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.corso_in_erogazione &&
      checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && checkPermission.hasPermission(this.props.UserStore.user, ['dirigenteAmministrativoPermissions'])
  }

  needIspezione() {
    if (!this.state.loading) {
      this.setState({
        loading: true
      }, () => {
        IspezioneService.needIspezione(this.state.progetto.id,
          (res) => {
            this.setState({ loading: false })
            this.props.refreshInline(res.data.data);
          },
          () => this.setState({ loading: false })
        )
      })
    }
  }

  disableApprove(){
    let invalid = false;
    if(this.props.section_feedback){
      Object.entries(this.props.section_feedback).forEach(([key, val]) => {
        if(!val['valid']) invalid = true;
      });
    }
    return invalid;
  }

  submitDecretoInfo(formInput) {
    if(formInput.decretoAutorizzativoData){
      formInput.decretoAutorizzativoData = moment(formInput.decretoAutorizzativoData, 'YYYY-MM-DD');
    }
    AuditService.saveDecretoInfo(
      this.state.progetto.id,
      formInput,
      (res) => {
        this.setState({ saved_decreto_info: true })
        message.success("Salvataggio avvenuto correttamente");
        this.props.refreshInline(res.data.data);
      },
      (err) => this.setState({ saved_decreto_info: false })
    )
  }

  decretoInfoInitialValues(){
    let initialValues = {}

    if(this.props.progetto && this.props.progetto.data_decreto_autorizzativo && this.props.progetto.data_decreto_autorizzativo !== null){
      initialValues.decretoAutorizzativoData = moment(this.props.progetto.data_decreto_autorizzativo, "YYYY-MM-DD");
    }
    if(this.props.progetto && this.props.progetto.numero_decreto_autorizzativo && this.props.progetto.numero_decreto_autorizzativo !== null){
      initialValues.decretoAutorizzativoNumero = this.props.progetto.numero_decreto_autorizzativo;
    }
    return initialValues;
  }

  render() {
    let canViewEditAudit = this.state.progetto.audit && this.state.progetto.audit.filter(a => a.level === auditStatic.level.istruttore).length > 0 &&
      checkPermission.hasPermission(this.props.UserStore.user, ['viewAudit', 'editAudit'])

    let auditTabVisible = canViewEditAudit || checkPermission.hasPermission(this.props.UserStore.user, ['addAudit']);

    return this.state.loaded ?
      <div>
        <Card
          className="no-pad"
          bordered={false}>
          {/*TODO implementare le azioni dopo che è stato deciso il workflow*/}
          {checkPermission.hasPermission(this.props.UserStore.user, ['manageProgetto', 'manageOwnProgetto']) ? this.renderRequestFileFirmato() : null}
          {this.renderDecretoFileFirmato()}
          {this.renderPubbicizzazioneFile()}
          {checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && (this.props.UserStore.user.ente && this.props.UserStore.user.ente.id === this.state.progetto.ente.id) ? this.renderEnteActions() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) ? this.renderConfirmOpenRegistrationAction() : null}
          {checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) && (
            (this.props.progetto.istruttore && this.props.progetto.istruttore.length > 0 && this.props.progetto.istruttore[0].id === this.props.UserStore.user.id) || 
            (this.props.progetto.rup && this.props.progetto.rup.length > 0 && this.props.progetto.rup[0].id === this.props.UserStore.user.id) ) 
            ? this.renderValuateOpenRegistrationAction() : null}        </Card>
        <React.Fragment>
          {this.canSceltaIspezione() &&
            <SceltaIspezione
              needIspezione={(esito) => this.needIspezione(esito)}
              loading={this.state.loading}
            ></SceltaIspezione>}
          <ActionsIspezione
            {...this.props}
            progetto={this.state.progetto}
          ></ActionsIspezione>
          { 
          this.canInsertDecretoInfo() && 
          <FormDecreto 
          onSubmit={ (formInput) => this.submitDecretoInfo(formInput) }
          initialValues={ this.decretoInfoInitialValues() }
          /> 
          }
          <EsitoProgetto
            canEvaluate={this.canEvaluate()}
            canConfirmApprove={this.canConfirmApprove()}
            canConfirmReject={this.canConfirmReject()}
            canUploadDecreto={this.canUploadDecreto()}
            prepareReject={() => this.prepareReject()}
            confirmReject={() => this.confirmReject()}
            prepareApprove={() => this.approveControdeduzioni()}
            confirmApprove={() => this.confirmApprove()}
            downloadDecreto={() => this.downloadDecreto()}
            uploadDecreto={(file) => this.uploadDecreto(file)}
            approving={this.state.approving_controdeduzioni}
            rejecting={this.state.rejecting}
            confirming_reject={this.state.confirming_reject}
            confirming_approve={this.state.confirming_approve}
            downloading_decreto={this.state.downloading_decreto}
            uploading_decreto={this.state.uploading_decreto}
            progetto={this.state.progetto}
           />
          <EsitoIspezione
            isFinanziato={this.state.progetto.id_tipo_finanziamento === affidamentoStatic.tipo_finanziamento.finanziato}
            canConfirmApprove={this.canConfirmApprove()}
            canEvaluate={this.canEvaluateIspezione()}
            acceptIspezione={() => this.accept()}
            accepting={this.state.accepting}
            suspendIspezione={() => this.suspendIspezione()}
            suspending={this.state.suspending}
            refuseIspezione={() => this.refuseIspezione()}
            refusing={this.state.refusing}
            canConfirmSuspend={this.canConfirmSuspendIspezione()}
            canConfirmRefuse={this.canConfirmRefuseIspezione()}
            confirmSuspendIspezione={() => this.confirmSuspendIspezione()}
            confirmRefuseIspezione={() => this.confirmRefuseIspezione()}
            confirming={this.state.confirming}
            canRestartProject={this.canRestartProject()}
            restartProject={() => this.restartProject()}
          ></EsitoIspezione>
        </React.Fragment>
        <React.Fragment>
          <Tabs size="medium">
            {
              auditTabVisible &&
              <TabPane tab={
                <span>Audit</span>
              } key="1">
                <AuditDocumentale
                  {...this.props}
                  canView={this.canView()}
                  canViewRefuse={this.state.progetto.id_tipo_finanziamento === affidamentoStatic.tipo_finanziamento.autofinanziato}
                  canStartAudit={(this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.istruttoria || this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.assegnato_rup) && this.canStartAudit()}
                  startedAudit={this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
                  canSendIntegration={this.canSendIntegration()}
                  canGiveFeedback={this.canGiveFeedback() || this.canGiveFeedbackRup()}
                  canRequireIntegration={this.canRequireIntegration()}
                  isValidated={this.isValidated()}
                  loading={this.state.starting}
                  startAudit={() => this.startAudit()}
                  audit={this.props.progetto.audit}
                  approve={(id) => this.approve(id)}
                  refuse={(id, note) => this.refuse(id, note)}
                  suspend={(id, note) => this.suspend(id, note)}
                  requireIntegration={(id) => this.requireIntegration(id)}
                  integration={(id) => this.integration(id)}
                  approving={this.state.approving}
                  refusing={this.state.refusing}
                  suspending={this.state.suspending}
                  integrating={this.state.integrating}
                  requiring={this.state.requiring}
                  disabledApprove = {this.disableApprove()}
                >
                </AuditDocumentale>
              </TabPane>}

            {
              canViewEditAudit && this.state.progetto.comunicazioneOstativa &&

              <TabPane tab={
                <span>Comunicazione ostativa</span>
              } key="2">
                <ComunicazioneOstativa
                  {...this.props}
                  canView={this.canView()}
                  canSendComunicazioneOstativa={this.canSendComunicaizoneOstativa()}
                  downloadComunicazione={() => this.downloadComunicazione()}
                  uploadComunicazione={(file) => this.uploadComunicazione(file)}
                  downloadingComunicazione={this.state.downloadingComunicazione}
                  uploadingComunicazione={this.state.uploadingComunicazione}
                  media={this.state.progetto?.media?.filter(el => el.tipo.sottodominio === affidamentoStatic.tipo_media.sottodominio_comunicazione_ostativa)}
                  note={this.state.progetto.comunicazioneOstativa?.note}
                />
                <Controdeduzioni
                  canView={this.canView()}
                  controdeduzioni={this.state.progetto.comunicazioneOstativa?.controdeduzioni}
                  data_trasmissione={this.state.progetto.comunicazioneOstativa?.data_trasmissione}
                  comunicazione={this.state.progetto.comunicazioneOstativa}
                  canEditControdeduzioni={this.canEditControdeduzioni()}
                  canDownloadControdeduzioni={this.canUploadControdeduzioni()}
                  canUploadControdeduzioni={this.canUploadControdeduzioni()}
                  canSendControdeduzioni={this.canSendControdeduzioni()}
                  editControdeduzioni={(data) => this.editControdeduzioni(data)}
                  editingControdeduzioni={this.state.editingControdeduzioni}
                  downloadControdeduzioni={() => this.downloadControdeduzioni()}
                  loadingControdeduzioni={this.state.loadingControdeduzioni}
                  uploadControdeduzioni={(file) => this.uploadControdeduzioni(file)}
                  uploadingControdeduzioni={this.state.uploadingControdeduzioni}
                  sendControdeduzioni={() => this.sendControdeduzioni()}
                  sendingControdeduzioni={this.state.sendingControdeduzioni}
                  media={this.state.progetto.comunicazioneOstativa?.controdeduzioni?.media?.filter(el => el.tipo.sottodominio === comunicazioneOstativaStatic.tipo_media.sottodominio_controdeduzioni_firmata)}
                >
                </Controdeduzioni>
              </TabPane>
            }
            {
              canViewEditAudit && this.state.progetto.ispezione && this.state.progetto.ispezione.length !== 0 &&
              this.state.progetto.ispezione.map((i, index) => {
                let n = index + 1;
                let key = 3 + index;
                return <TabPane tab={
                  <span>Ispezione {n}</span>
                } key={key + ''}>
                  <ActionsIspezione
                    {...this.props}
                    progetto={this.state.progetto}
                    ispezione={i}
                  >
                  </ActionsIspezione>
                </TabPane>
              })

            }
          </Tabs>


        </React.Fragment>
      </div>
      : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ActionsEdizione);