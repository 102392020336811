import React, { Component } from "react";
import { Button, Card, Popconfirm, Tabs, message } from "antd";
import FileSaver from "file-saver";
import { connect } from "react-redux";
import checkPermission from "@common/utils/check/permissions";
import affidamentoStatic from "@common/utils/static/affidamento";
import ModelServices from "../services/Esami";
import AuditDocumentale from "./audit/AuditDocumentale";
import AuditService from "../services/AuditCommissioneEsame";

const TabPane = Tabs.TabPane;

class ActionsEsame extends Component {
  state = {
    starting: false,
    loaded: false,
    downloadingComunicazione: false,
    approvingOpenRegistration: false,
    rejectingOpenRegistration: false,
    confirmOpenRegistration: false,
    prepareCourse: false,
    progetto: {},
  };

  canView() {
    return true;
  }

  isAssignedRdpAttestati() {
    return (
      this.props.progetto.stato == 14 &&
      this.props.progetto.stato_flusso == 35 &&
      checkPermission.hasRole(this.props.UserStore.user, ["RdP Attestati"])
    );
  }

  isAssignedIstruttoreAttestati() {
    return (
      this.props.progetto.stato == 3 &&
      this.props.progetto.stato_flusso == 5 &&
      checkPermission.hasRole(this.props.UserStore.user, [
        "Istruttore Attestati",
      ])
    );
  }

  canStartAudit() {
    let ist = this.isAssignedIstruttoreAttestati();
    let rup = this.isAssignedRdpAttestati();
    return ist == true || rup == true;
  }

  componentDidMount() {
    if (this.props.progetto) this.loaded(this.props);
  }

  loaded(props) {
    this.setState({ progetto: props.progetto, loaded: true });
  }

  saveBlob(blob, title) {
    const _blob = new Blob([blob], { type: "application/pdf" });
    FileSaver.saveAs(_blob, title);
  }

  saveBlobWord(blob) {
    const _blob = new Blob([blob], {
      type: "application/vnd.oasis.opendocument.text",
    });
    FileSaver.saveAs(_blob, "controdeduzioni.odt");
  }

  renderEnteActions() {
    return this.props.progetto.stato == 1 &&
      this.props.progetto.stato_flusso == 1 ? (
      <div>
        <p>
          Se hai completato la lavorazione dell'esame procedi con la conferma
        </p>
        <Popconfirm
          placement="top"
          title="Confermi i dati inseriti?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState(
                {
                  confirming: true,
                  loading: true,
                },
                () =>
                  ModelServices.confirmExam(
                    this.props.progetto.id,
                    (res) => {
                      message.success("Lavorazione confermata correttamente");
                      //this.props.refresh(false);
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    }
                  )
              );
            }
          }}
          okText="Si"
          cancelText="No"
        >
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h"
            type="primary"
            icon="check"
          >
            Conferma lavorazione
          </Button>
        </Popconfirm>
      </div>
    ) : null;
  }

  renderPresidenteActions() {
    return this.props.progetto.scheda_progetto_json
      .valutazioniCompletePresidente &&
      this.props.progetto.stato == 16 &&
      this.props.progetto.stato_flusso == 15 ? (
      <div>
        <p>
          Se hai completato la lavorazione dell'esame procedi con la conferma
        </p>
        <Popconfirm
          placement="top"
          title="Confermi i dati inseriti?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState(
                {
                  confirming: true,
                  loading: true,
                },
                () =>
                  ModelServices.confirmPresidente(
                    this.props.progetto.id,
                    (res) => {
                      message.success("Lavorazione confermata correttamente");
                      //this.props.refresh(false);
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    }
                  )
              );
            }
          }}
          okText="Si"
          cancelText="No"
        >
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h"
            type="primary"
            icon="check"
          >
            Conferma lavorazione
          </Button>
        </Popconfirm>
      </div>
    ) : null;
  }

  renderDirigenteActions() {
    return this.props.progetto.scheda_progetto_json
      .valutazioniCompleteDirigente &&
      this.props.progetto.stato == 17 &&
      this.props.progetto.stato_flusso == 19 ? (
      <div>
        <p>
          Se hai completato la lavorazione dell'esame procedi con la conferma
        </p>
        <Popconfirm
          placement="top"
          title="Confermi i dati inseriti?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState(
                {
                  confirming: true,
                  loading: true,
                },
                () =>
                  ModelServices.confirmDirigente(
                    this.props.progetto.id,
                    (res) => {
                      message.success("Lavorazione confermata correttamente");
                      //this.props.refresh(false);
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    }
                  )
              );
            }
          }}
          okText="Si"
          cancelText="No"
        >
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h"
            type="primary"
            icon="check"
          >
            Conferma lavorazione
          </Button>
        </Popconfirm>
      </div>
    ) : null;
  }

  renderEnteAttestatiActions() {
    return this.props.progetto.scheda_progetto_json.valutazioni &&
      this.props.progetto.scheda_progetto_json.valutazioniComplete == true &&
      this.props.progetto.stato_flusso == 4 ? (
      <div>
        <p>
          Se hai completato la lavorazione dell'esame procedi con la conferma
        </p>
        <Popconfirm
          placement="top"
          title="Confermi i dati inseriti?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState(
                {
                  confirming: true,
                  loading: true,
                },
                () =>
                  ModelServices.confirmAttestato(
                    this.props.progetto.id,
                    (res) => {
                      message.success("Lavorazione confermata correttamente");
                      //this.props.refresh(false);
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    }
                  )
              );
            }
          }}
          okText="Si"
          cancelText="No"
        >
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h"
            type="primary"
            icon="check"
          >
            Conferma lavorazione
          </Button>
        </Popconfirm>
      </div>
    ) : null;
  }

  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload() {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          uploading: true,
        },
        () => {
          ModelServices.upload(
            this.state.progetto.id,
            {
              filename: "file",
              note: this.state.note || "",
            },
            { ...this.state.file, chiave: "file" },
            (res) => {
              message.success(
                "La dichiarazione è stata caricata correttamente"
              );
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data);
              this.endLoading();
              this.setState({ open_modal_doc: false });
            },
            () => {
              this.endLoading();
            }
          );
        }
      );
    }
  }

  canSendIntegration() {
    return (
      this.state.progetto.stato ===
        affidamentoStatic.stato_progetto.avvio_sospeso &&
      this.state.progetto.stato_flusso ===
        affidamentoStatic.stato_flusso_progetto.integrazioni &&
      checkPermission.hasPermission(this.props.UserStore.user, [
        "manageOwnCommissioneEsame",
      ]) &&
      this.props.UserStore.user.ente &&
      this.props.UserStore.user.ente.id === this.state.progetto.ente.id
    );
  }

  isValidated() {
    if (
      this.props.progetto.section_feedback?.exam &&
      this.props.progetto.section_feedback?.discent
    ) {
      return true;
    } else return false;
  }

  canGiveFeedback() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ["editAudit"]) &&
      this.state.progetto.istruttore &&
      this.state.progetto.istruttore.length > 0 &&
      this.state.progetto.istruttore[0].id === this.props.UserStore.user.id &&
      this.state.progetto.stato_flusso ===
        affidamentoStatic.stato_flusso_progetto.audit_iniziato &&
      this.isValidated()
    );
  }

  canGiveFeedbackRup() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ["editAudit"]) &&
      this.state.progetto.rup &&
      this.state.progetto.rup.length > 0 &&
      this.state.progetto.rup[0].id === this.props.UserStore.user.id &&
      this.state.progetto.stato_flusso ===
        affidamentoStatic.stato_flusso_progetto.audit_iniziato &&
      this.state.progetto.istruttore.length == 0 &&
      this.isValidated()
    );
  }

  canRequireIntegration() {
    return (
      this.state.progetto.stato ===
        affidamentoStatic.stato_progetto.avvio_sospeso &&
      this.state.progetto.stato_flusso ===
        affidamentoStatic.stato_flusso_progetto.sospeso &&
      checkPermission.hasPermission(this.props.UserStore.user, [
        "manageOwnCommissioneEsame",
      ]) &&
      checkPermission.hasPermission(this.props.UserStore.user, [
        "dirigenteAmministrativoPermissions",
      ])
    );
  }

  startAudit() {
    if (!this.state.starting) {
      this.setState({ starting: true }, () => {
        if (
          this.props.location.pathname ==
          "/affidamento/esami/" + this.props.progetto.id
        ) {
          AuditService.EsameStart(
            this.state.progetto.id,
            (res) => {
              message.success("L'audit è iniziato correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data);
              this.setState({ starting: false });
            },
            () => {
              this.setState({ starting: false });
            }
          );
        } else {
          AuditService.start(
            this.state.progetto.id,
            (res) => {
              message.success("L'audit è iniziato correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data);
              this.setState({ starting: false });
            },
            () => {
              this.setState({ starting: false });
            }
          );
        }
      });
    }
  }

  approve(id_audit) {
    if (!this.state.approving) {
      this.setState(
        {
          approving: true,
        },
        () => {
          if (
            checkPermission.hasRole(this.props.UserStore.user, [
              "RdP Attestati",
            ]) ||
            checkPermission.hasRole(this.props.UserStore.user, [
              "Istruttore Attestati",
            ])
          ) {
            AuditService.prepareApproveAttestati(
              this.state.progetto.id,
              id_audit,
              (res) => {
                this.setState({ approving: false });
                message.success("Approvato correttamente");
                //this.props.refresh(false);
                this.props.refreshInline(res.data.data);
              },
              (err) => this.setState({ approving: false })
            );
          } else {
            AuditService.prepareApprove(
              this.state.progetto.id,
              id_audit,
              (res) => {
                this.setState({ approving: false });
                message.success("Approvato correttamente");
                //this.props.refresh(false);
                this.props.refreshInline(res.data.data);
              },
              (err) => this.setState({ approving: false })
            );
          }
        }
      );
    }
  }

  refuse(id_audit, note) {
    if (!this.state.refusing) {
      this.setState({ refusing: true }, () => {
        AuditService.suspendComunicazioneOstativa(
          this.state.progetto.id,
          id_audit,
          { note: note },
          (res) => {
            this.setState({ refusing: false });
            message.success("Il progetto è stato rigettato correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ refusing: false })
        );
      });
    }
  }

  suspend(id_audit, note) {
    if (!this.state.suspending) {
      this.setState({ suspending: true }, () => {
        AuditService.suspend(
          this.state.progetto.id,
          id_audit,
          { note: note },
          (res) => {
            this.setState({ suspending: false });
            message.success("Sospeso correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ suspending: false })
        );
      });
    }
  }

  requireIntegration(id_audit) {
    if (!this.state.requiring) {
      this.setState({ requiring: true }, () => {
        AuditService.integration(
          this.state.progetto.id,
          id_audit,
          (res) => {
            this.setState({ requiring: false });
            message.success(
              "La richiesta di integrazione è stata inviata correttamente"
            );
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ requiring: false })
        );
      });
    }
  }

  integration(id_audit) {
    if (!this.state.integrating) {
      this.setState(
        {
          integrating: true,
        },
        () => {
          AuditService.confirm_integrazione(
            this.state.progetto.id,
            id_audit,
            (res) => {
              this.setState({ integrating: false });
              message.success("Riportato in lavorazione correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data);
            },
            (err) => {
              this.setState({ integrating: false });
            }
          );
        }
      );
    }
  }

  disableApprove() {
    let invalid = false;
    if (this.props.section_feedback) {
      Object.entries(this.props.section_feedback).forEach(([key, val]) => {
        if (!val["valid"]) invalid = true;
      });
    }
    return invalid;
  }

  endLoading() {
    this.setState({
      loading: false,
      uploading: false,
      uploaded: false,
      confirming: false,
      sending: false,
      downloading: false,
      integrating: false,
      rejectingOpenRegistration: false,
      approvingOpenRegistration: false,
      confirmOpenRegistration: false,
    });
  }

  render() {
    return this.state.loaded ? (
      <div>
        <React.Fragment>
          <AuditDocumentale
            {...this.props}
            canView={this.canView()}
            canViewRefuse={true}
            canStartAudit={this.canStartAudit()}
            startedAudit={
              this.props.progetto.stato_flusso ===
              affidamentoStatic.stato_flusso_progetto.audit_iniziato
            }
            canSendIntegration={this.canSendIntegration()}
            canGiveFeedback={
              this.canGiveFeedback() || this.canGiveFeedbackRup()
            }
            canRequireIntegration={this.canRequireIntegration()}
            isValidated={this.isValidated()}
            loading={this.state.starting}
            startAudit={() => this.startAudit()}
            audit={this.props.progetto.audit}
            approve={(id) => this.approve(id)}
            refuse={(id, note) => this.refuse(id, note)}
            suspend={(id, note) => this.suspend(id, note)}
            requireIntegration={(id) => this.requireIntegration(id)}
            integration={(id) => this.integration(id)}
            approving={this.state.approving}
            refusing={this.state.refusing}
            suspending={this.state.suspending}
            integrating={this.state.integrating}
            requiring={this.state.requiring}
            disabledApprove={this.disableApprove()}
          ></AuditDocumentale>
        </React.Fragment>
        <Card className="no-pad" bordered={false}>
          {(checkPermission.hasRole(
            this.props.UserStore.user,
            "DipendenteRegionale"
          ) &&
            checkPermission.hasRole(this.props.UserStore.user, "Valutatore") &&
            this.props.progetto.scheda_progetto_json.exam &&
            this.props.progetto.scheda_progetto_json.valutazioni) ||
          this.state.canView
            ? this.renderEnteActions()
            : null}
          {checkPermission.hasRole(this.props.UserStore.user, "Ente") &&
          checkPermission.hasRole(this.props.UserStore.user, "EnteAfc")
            ? this.renderEnteAttestatiActions()
            : null}
          {checkPermission.hasRole(this.props.UserStore.user, "Valutatore")
            ? this.renderPresidenteActions()
            : null}
          {checkPermission.hasRole(
            this.props.UserStore.user,
            "DirettoreAmministrativo"
          )
            ? this.renderDirigenteActions()
            : null}
        </Card>
      </div>
    ) : null;
  }
}

export default connect(({ UserStore, GlobalStore }) => ({
  UserStore,
  GlobalStore,
}))(ActionsEsame);
