import React, { Component } from 'react';
import { Button, Card, Tooltip, Tag } from 'antd';

import moment from 'moment';

import checkPermission from '@common/utils/check/permissions';

import FilterForm from '@common/components/filterForm';
import TableComponent from '@common/components/table';

import ModelServices from '../services/Domanda';

import affidamentoStatic from '@common/utils/static/affidamento';
import FileSaver from 'file-saver';

import _ from '@common/utils/formatters/_';


const renderActions = (element, component) => {

  return <div style={{ display: 'inline-block' }}>

    <Tooltip placement="top" title="Visualizza formulario">
      <Button className="m2w" size="small" shape="circle" icon="eye" onClick={() => component.props.history.push("/affidamento/formulari/" + element.id_procedura + "/compilati/" + element.id)} />
    </Tooltip>

    <Tooltip placement="top" title="Stampa formulario">
      <Button disabled={component.state.loading || !element.data_pdf} className="m2w" size="small" shape="circle" icon="file-pdf" onClick={() => component.printPdf(element.id)} />
    </Tooltip>

  </div>
}

const renderStatusIndicator = (record, component) => {

  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigenteAmminisrativoPermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttoreAmministrativoPermissions'])) user = 'istruttore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';

  let colors = ['green', 'red', 'orange'];
  let defined_c = '';
  colors.forEach(c => {
    if (affidamentoStatic.pallini[user][c].indexOf(parseInt(record.stato_flusso)) !== -1) defined_c = c;
  })

  return (defined_c !== '') ?
    <Tooltip placement="topRight" title={record.stato_flusso_formatted['descrizione_' + user]}>
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
  : <span className={"pallino invisible"}></span>;
}

const model = (component) => {
  let cols = {
    with_server_pagination: true,
    columns: [
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'actions',
        //width: "300px",
        render: element => <div style={{ width: 140 }}>
          {
            renderStatusIndicator(element, component)
          }
          {
            renderActions(element, component)
          }
        </div>,
      },
      {
        title: 'Denominazione Progetto',
        dataIndex: '',
        sorter: true,
        key: 'titolo',
        render: element => <div>
          {<strong>{element.titolo || element.domanda_json.generalData.AfcProjectInfoFormSection.courseTitle || ''}</strong>}
        </div>
      },
      {
        title: 'Qualifica professionale',
        dataIndex: '',
        sorter: false,
        key: '',
        render: element => <div>
          {/*TODO sorgente errata, deve essere preso dalle qualifiche professionali*/}
          {element.domanda_json.generalData.AfcIndexSection ? (component.props.GlobalStore.stubs.tipo_funzioni.find(i => i.value === element.domanda_json.generalData.AfcIndexSection.title)?.label) : ''}
        </div>
      },
      {
        title: 'Soggetto Proponente',
        dataIndex: '',
        sorter: true,
        key: 'ente.denominazione',
        render: element => <div>
          {element.ente.denominazione || ''}
        </div>
      },
      {
        title: 'Data creazione',
        dataIndex: '',
        sorter: true,
        key: 'data_creazione',
        render: element => <div style={{ width: 140 }}>
          {element.data_creazione ? moment(element.data_creazione, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
        </div>,
      },
      {
        title: 'Data produzione PDF',
        dataIndex: '',
        sorter: true,
        key: 'data_pdf',
        render: element => <div style={{ width: 140 }}>
          {element.data_pdf ? moment(element.data_pdf, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : ''}
        </div>
      },
      {
        title: '',
        dataIndex: '',
        sorter: false,
        key: 'stato',
        render: element => <div style={{ width: 140 }}>
          {element.stato === affidamentoStatic.stato.vincitore || element.stato > 4 ? <Tag color="green">{affidamentoStatic.getStatoLabel(affidamentoStatic.stato.vincitore)}</Tag> : element.stato === affidamentoStatic.stato.non_vincitore ? <Tag color="red">{affidamentoStatic.getStatoLabel(element)}</Tag> : affidamentoStatic.getStatoLabel(element.stato) }
        </div>,
      }
    ]
  }

  return cols;
}

const filterFormModel = (component) => {

  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Denominazione Progetto',
                type_string: 'text',
                name: 'titolo',
              }
            ]
          },
          {
            items: [
                {
                    type: 'input',
                    label: 'Qualifica professionale',
                    type_string: 'text',
                    name: 'qualifica_professionale',
                  }
            ]
          },
          {
            items: [
                  {
                    type: 'input',
                    label: 'Soggetto Proponente',
                    name: 'ente',
                    type_string: 'text',
                  }
            ]
          }

        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'submit',
                type: 'submit',
                text: 'Cerca',
                btn_type: 'primary'
              }
            ]
          }
        ]
      },
    ]
  };
}

class TableFormulariCompilati extends Component {
  base_url = '/affidamento/formulari/'+ this.props.match.params.id +'/compilati';
  default_order = 'id'

  state = {
    loading: false,
    data: {
      total: 0,
      current: 1,
      data: []
    },
  }

  componentDidMount() {
    this.refresh();
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetch(this.props.location.search)
    }
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  fetch(query) {
    let { params, pager } = _.getFilterParam(query, this.default_order);
    this.setState({ loading: true });
    ModelServices.list(params, this.props.match.params.id, res => {
      let data = _.getResponse(res);
      this.setState({
        loading: false,
        data: data
      }, () => this.refs.table_component.updateTableData(
        data.data,
        data.current,
        data.total,
        pager)
      );

    }, (e) => {
      this.setState({ loading: false });
      //console.log('errore dati', e)
    })
  }

  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.fetch(this.props.location.search)
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    _.formatSortChange(this.props, this.base_url, obj);
  }

  printPdf(id) {
    ModelServices.printPdf(id, res => {
      const _blob = new Blob([res.data], { type: 'application/pdf' });
      FileSaver.saveAs(_blob, "formulario.pdf");
    });
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.props.location.search}
          history={this.props.history}
        />
        <Card
          className="shadow no-pad"
          bordered={false}>


          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: 'id' }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>
      </div>
    );
  }
}

export default TableFormulariCompilati;
