import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import moment from 'moment';

import formatter from '@common/utils/formatters/_';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Giorno della settimana',
                name: 'weekDay',
                options_props: '_giorni_settimana',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
        ],
      },
     /* {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Dal',
                format: 'DD/MM/YYYY',
                name: 'fromDate',
                type_string: 'text',
                props: {
                  style: { width: '100%' },
                },
                
                validations: [
                  {

                    type: 'required',
                    error_message: 'Campo obbligatorio',

                  }, {
                    validator: 'min_date',
                  }
                ]
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Al',
                format: 'DD/MM/YYYY',
                name: 'toDate',
                type_string: 'text',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',

                  },
                  {
                    validator: 'max_date'
                  }
                ]
              }
            ],
          },
        ]
      },*/
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Dalle ore',
                name: 'fromTime',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_time'
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'timepicker',
                label: 'Alle ore',
                name: 'toTime',
                props: {
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'max_time'
                  }
                ],
              }
            ]
          },
          /*{
              span: 16,
              items: [
                  {
                      type: 'input',
                      label: 'Ora',
                      name: 'hour',
                      type_string: 'text',
                      validations: [
                          {
                            type: 'required',
                            error_message: 'Campo obbligatorio',
                          }
                        ]
                    }
              ],
          },*/
        ]
      },
/*
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Materia',
                name: 'subject',
                options_props: '_contenuto_formativo',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
        ],
      }, /*
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Insegnante',
                name: 'teacher',
                type_string: 'text',
                options_props: '_teacherList',
                /*validations: [
                    {
                      type: 'required',
                      error_message: 'Campo obbligatorio',
                    }
                  ]
              }
            ],
          },
        ]
      },*/
      {
        cols: [
          {
            items: [
              {
                name: 'btn',
                type: 'submit',
                text: 'Salva',
                btn_type: 'primary'
              },
            ],
          },
        ],
      }

    ],
    onSubmit: 'submitted',
  }
}

class EditOrarioSettimanale extends Component {

  state = {
    loaded: false,
    loading: true,
    orarioSettimanale: {}
  };

  componentDidMount() {
    this.load(this.props)
  }

  load(props) {
    this.setState({
      loaded: true,
      loading: false,
      orarioSettimanale: props.orarioSettimanale
    });
  }

  submit(data) {
    if (!this.state.loading) {
      data.subject_description = this.props.GlobalStore.stubs.contenuto_formativo.find(el => el.value === data.subject)?.label;
      data.weekDay_description = this.props.GlobalStore.stubs.giorni_settimana.find(el => el.value === data.weekDay)?.label;
      data.teacher_description = this.props.teacherList.find(el => el.value === data.teacher)?.label;
      this.setState({
        loading: true,
        orarioSettimanale: data
      });
      if (this.props.create) {
        this.props.onCreate(data);
      } else {
        this.props.onUpdate(data);
      }
    }
  }



  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formSelRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this)}
          values={formatter.replaceFormDatesIn(['fromDate', 'toDate'], this.state.orarioSettimanale)}
          readOnly={this.props.readOnly}
          submitted={(data) => this.submit(data)}
          _contenuto_formativo={this.props.GlobalStore.stubs.contenuto_formativo}
          _giorni_settimana={this.props.GlobalStore.stubs.giorni_settimana}
          _teacherList={this.props.teacherList}
          
          min_date={(rule, value, cb) => {
            if( !this.formSelRef.props.form.isFieldValidating('toDate')){
              this.formSelRef.props.form.validateFields(['toDate']);
            }
           

            if (!value || value.length === 0) cb();
            let min = value;

            if (min < moment()) cb("La data di avvio deve essere maggiore della data odierna");
            let max = this.formSelRef.props.form.getFieldsValue()['toDate'];

            if (max && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
            else cb()
          }}
          max_date={(rule, value, cb) => {
            if( !this.formSelRef.props.form.isFieldValidating('fromDate')){
              this.formSelRef.props.form.validateFields(['fromDate']);
            }
            //this.formSelRef.props.form.validateFields(['fromDate']);
            if (!value || value.length === 0) cb();
            let max = value;

            let min = this.formSelRef.props.form.getFieldsValue()['fromDate'];

            if (min && min > max) cb("La data di avvio deve essere minore della data di conclusione");
            else cb()
          }}
          min_time={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            let min = value;

            let max = this.formSelRef.props.form.getFieldsValue()['toTime'];

            if (max && min > max) cb("L'orario di avvio deve essere minore dell'orario di conclusione");
            else cb()
          }}
          max_time={(rule, value, cb) => {
            if (!value || value.length === 0) cb();
            let max = value;

            let min = this.formSelRef.props.form.getFieldsValue()['fromTime'];

            if (min && min > max) cb("L'orario di avvio deve essere minore dell'orario di conclusione");
            else cb()
          }}
        />
      </div>
    )
  }
}

export default EditOrarioSettimanale;