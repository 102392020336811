import React, { Component } from "react";
import {
  Button,
  Card,
  Popconfirm,
  message,
  Tooltip,
  Modal,
  Radio,
  Row,
} from "antd";
import TableComponent from "@common/components/table";

import ModelServices from "@accreditamento/services/Accreditamento";
import EnteServices from "@accreditamento/services/Ente";
import UtilServices from "@common/services/Util";

import moment from "moment";

import queryString from "query-string";
import FileSaver from "file-saver";
import FilterForm from "@common/components/filterForm";
import accreditamentoStatic from "@common/utils/static/accreditamento";
import checkPermission from "@common/utils/check/permissions";
import RadioGroup from "antd/lib/radio/group";

import JoinLongArray from "@common/components/JoinLongArray";

import Documenti from "@common/components/Documenti";

import _ from "@common/utils/formatters/_";

import MediaServices from "../../../common/services/media/Media";

/*
const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

const uniqueContacts = (string) => {
  try {
    return string.split(", ").filter(onlyUnique).join(", ")
  } catch (e) {
    return ""
  }
}


const _today = moment();

const scadenzaAccreditamento = ( element ) => {

  if(element.blocked_timer === 1) return <p>Timer bloccato</p>;

  if(element.data_acquisizione && !element.data_termine_accreditamento) {

    let acquisizione = moment(element.data_acquisizione, 'YYYY-MM-DD');
    let to_add = accreditamentoStatic.timer.accreditamento + parseInt(element.integration_added_days);
    let scadenza = acquisizione.clone().add(to_add, 'days');
    if(scadenza >= _today) {
      let df = scadenza.diff( _today, 'days' );
      return <p>{"Scadenza accreditamento:"}<strong>{df + " giorni"}</strong>{ "( data acquisizione: " + acquisizione.format('DD/MM/YYYY') + ")"}</p>;
    } else {
      return <p>Accreditamento scaduto</p>;
    }
  } else {
    return null
  }
}

const scadenzaAuditDocumentale = ( element ) => {
  if(element.blocked_timer === 1) return <p>Timer bloccato</p>;

  if(element.data_timer_audit && !element.data_termine_audit) {
    let acquisizione = moment(element.data_timer_audit, 'YYYY-MM-DD');

    let to_add = accreditamentoStatic.timer.audit + parseInt(element.integration_added_days);

    let _scadenza = acquisizione.clone().add(to_add, 'days');
    if(_scadenza >= _today) {
      let df = _scadenza.diff( _today, 'days' );
      return <p>{"Scadenza audit documentale:"}<strong>{df + " giorni"}</strong>{ "( data acquisizione: " + acquisizione.format('DD/MM/YYYY') + ")"}</p>;
    } else {
      return <p>Audit documentale scaduto</p>;
    }
  } else {
    return ""
  }
}
*/

const canShowVariazioni = (element) => {
  return (
    element.tipo === accreditamentoStatic.tipo.variazione ||
    element.tipo === accreditamentoStatic.tipo.definitivo ||
    element.tipo === accreditamentoStatic.tipo.rinnovo
  );
};

const renderActions = (element, component) => {
  // Sono interessato al solo eds unico documento di rinuncia!
  let media_rinuncia = [];

  element.media.map((m, i) => {
    if (m.tipo.sottodominio === "doc_rinuncia") {
      media_rinuncia = m;
    }
  });

  element = { ...element, media_rinuncia };

  console.warn("renderActions", element, element.media_rinuncia.length);

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip placement="top" title="Visualizza scheda">
        <Button
          className="m2w"
          size="small"
          shape="circle"
          icon="eye"
          onClick={() =>
            component.props.history.push("/accreditamento/scheda/" + element.id)
          }
        />
      </Tooltip>

      {/* Duplicazione scheda */}
      {
        /*(moment(new Date()).diff(moment(element.created_at), 'months') > 18) &&
      checkPermission.hasPermission(component.props.UserStore.user, ['entePermissions']) &&*/
        (element.tipo === accreditamentoStatic.tipo.provvisorio ||
          element.tipo ===
            accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio) &&
          checkPermission.hasPermission(component.props.UserStore.user, [
            "entePermissions",
            "superAdminPermissions",
          ]) && (
            /*element.stato < accreditamentoStatic.stato.inviata &&*/
            <Tooltip placement="top" title="Duplica scheda">
              <Popconfirm
                disabled={
                  !checkPermission.hasPermission(
                    component.props.UserStore.user,
                    ["entePermissions", "superAdminPermissions"]
                  )
                }
                placement="leftTop"
                title="Sicuro di voler duplicare questa scheda"
                onConfirm={() => component.addCopy(element.id)}
                okText="Si"
                cancelText="No"
              >
                <Button
                  className="m2w"
                  size="small"
                  shape="circle"
                  disabled={
                    !checkPermission.hasPermission(
                      component.props.UserStore.user,
                      ["entePermissions", "superAdminPermissions"]
                    )
                  }
                  icon="copy"
                />
              </Popconfirm>
            </Tooltip>
          )
      }

      <Tooltip placement="top" title="Stampa scheda">
        <Button
          disabled={component.state.loading}
          className="m2w"
          size="small"
          shape="circle"
          icon="file-pdf"
          onClick={() => component.printPdf(element.id)}
        />
      </Tooltip>
      {canShowVariazioni(element) &&
        !checkPermission.hasPermission(component.props.UserStore.user, [
          "entePermissions",
          "ispettorePermissions",
        ]) && (
          <Tooltip placement="top" title="Variazioni">
            <Button
              disabled={component.state.loading}
              className="m2w"
              size="small"
              shape="circle"
              icon="file-unknown"
              onClick={() => component.docVariazioni(element.id)}
            />
          </Tooltip>
        )}

      {/* Cancellazione scheda */}
      {element.stato < accreditamentoStatic.stato.inviata &&
        checkPermission.hasPermission(component.props.UserStore.user, [
          "entePermissions",
          "superAdminPermissions",
        ]) && (
          <Tooltip placement="top" title="Elimina scheda">
            <Popconfirm
              placement="leftTop"
              title="Sicuro di voler rimuovere questa scheda?"
              onConfirm={() => component.delete(element.id)}
              okText="Si"
              cancelText="No"
            >
              <Button
                className="m2w"
                size="small"
                shape="circle"
                icon="delete"
              />
            </Popconfirm>
          </Tooltip>
        )}

      {/* Pulsante richiesta definitivo */}
      {
        //( element.tipo === accreditamentoStatic.tipo.provvisorio || element.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio || element.tipo === accreditamentoStatic.tipo.riconoscimento_ente)  &&
        (element.tipo_accreditamento ===
          accreditamentoStatic.tipo_accreditamento.provvisorio ||
          element.tipo_accreditamento ===
            accreditamentoStatic.tipo_accreditamento.riconoscimento) &&
          element.stato === accreditamentoStatic.stato.accolta_e_confermata &&
          element.stato_flusso ===
            accreditamentoStatic.stato_flusso.accolta_confermata_firmata &&
          checkPermission.hasPermission(component.props.UserStore.user, [
            "entePermissions",
            "superAdminPermissions",
          ]) &&
          renderDurata(moment.unix(element.created_at)) <= 18 &&
          (!element.storico ||
            !element.storico.some((obj) => {
              return obj.tipo === accreditamentoStatic.tipo.definitivo;
            })) && (
            <div className="m10h">
              <Tooltip
                placement="top"
                title="Richiedi l'accreditamento definitivo per questa domanda."
              >
                <Button
                  type="primary"
                  size="small"
                  block
                  onClick={() => component.definitivo(element.id)}
                >
                  Ric. Definitiva
                </Button>
              </Tooltip>
            </div>
          )
      }

      {/* Pulsante MOSTRA RINUNCIA */}
      {(element.stato === accreditamentoStatic.stato.rinuncia_confermata ||
        element.stato ===
          accreditamentoStatic.stato.rinuncia_in_istruttoria) && (
        <Tooltip placement="top" title="Visualizza Rinuncia">
          <Button
            className="m2w"
            size="small"
            shape="circle"
            type="danger"
            icon="eye"
            onClick={() =>
              component.setState({
                showModalRinuncia: true,
                acc_accreditamento: element,
              })
            }
          />
        </Tooltip>
      )}

      {/* Pulsante richiesta rinnovo */}
      {
        //(element.tipo === accreditamentoStatic.tipo.definitivo  || element.tipo === accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo || element.tipo === accreditamentoStatic.tipo.riconoscimento_ente) &&
        (element.tipo_accreditamento ===
          accreditamentoStatic.tipo_accreditamento.definitivivo ||
          element.tipo === accreditamentoStatic.tipo.riconoscimento_ente) &&
          element.stato === accreditamentoStatic.stato.accolta_e_confermata &&
          element.stato_flusso ===
            accreditamentoStatic.stato_flusso.accolta_confermata_firmata &&
          checkPermission.hasPermission(component.props.UserStore.user, [
            "entePermissions",
            "superAdminPermissions",
          ]) &&
          (!element.storico ||
            !element.storico.some((obj) => {
              return obj.tipo === accreditamentoStatic.tipo.rinnovo;
            })) && (
            //(renderDurata(moment.unix(element.created_at)) <= 18) &&
            <div className="m10h">
              <Tooltip
                placement="top"
                title="Effettua il mantenimento per questa domanda."
              >
                <Button
                  type="primary"
                  className="bg-green"
                  size="small"
                  block
                  onClick={() => component.rinnova(element.id)}
                >
                  Ric. Mantenimento
                </Button>
              </Tooltip>
            </div>
          )
      }

      {/* Pulsante richiesta variazione */}
      {element.stato === accreditamentoStatic.stato.accolta_e_confermata &&
        element.stato_flusso ===
          accreditamentoStatic.stato_flusso.accolta_confermata_firmata &&
        checkPermission.hasPermission(component.props.UserStore.user, [
          "entePermissions",
          "superAdminPermissions",
        ]) && (
          <div className="m10h">
            <Tooltip
              placement="top"
              title="Richiedi una variazione per questa domanda."
            >
              <Button
                type="primary"
                className="bg-green"
                size="small"
                block
                onClick={() => {
                  component.setState({
                    showModalVariazione: true,
                    id_variazione: element.id,
                  });
                }}
              >
                Ric. Variazione
              </Button>
            </Tooltip>
          </div>
        )}

      {/* Pulsante richiesta rinuncia */}
      {element.tipo !== accreditamentoStatic.tipo.variazione &&
        element.stato === accreditamentoStatic.stato.accolta_e_confermata &&
        element.stato_flusso ===
          accreditamentoStatic.stato_flusso.accolta_confermata_firmata &&
        checkPermission.hasPermission(component.props.UserStore.user, [
          "entePermissions",
          "superAdminPermissions",
        ]) && (
          <div className="m10h">
            <Tooltip
              placement="top"
              title="Richiedi una rinuncia per questa domanda."
            >
              <Button
                type="primary"
                className="bg-green"
                size="small"
                block
                onClick={() => {
                  component.setState({
                    showModalRinuncia: true,
                    acc_accreditamento: element,
                  });
                }}
              >
                Rinuncia
              </Button>
            </Tooltip>
          </div>
        )}
    </div>
  );
};

const renderDurata = (date) => {
  var now = moment(new Date()); //todays date
  var end = moment(date).add(18, "M"); // another date +18 months
  var months = Math.ceil(end.diff(now, "months", true)); // diff in months
  return months;
};

const renderPallino = (record, component) => {
  let user = "ente";

  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigentePermissions",
    ])
  )
    user = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttorePermissions",
    ])
  )
    user = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    user = "ispettore";

  let colors = ["green", "red", "orange"];
  let defined_c = "";
  colors.forEach((c) => {
    if (
      accreditamentoStatic.pallini[user][c].indexOf(
        parseInt(record.stato_flusso)
      ) !== -1
    )
      defined_c = c;
  });

  return defined_c !== "" ? (
    <Tooltip
      placement="topRight"
      title={record.stato_flusso_formatted["descrizione_" + user]}
    >
      <span className={"pallino " + defined_c}></span>
    </Tooltip>
  ) : (
    <span className={"pallino invisible"}></span>
  );
};

const getScadenzaAudit = (record, component) => {
  let stato = "ente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigentePermissions",
    ])
  )
    stato = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttorePermissions",
    ])
  )
    stato = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    stato = "ispettore";

  return record.blocked_timer === 1 ||
    record.stato_flusso_formatted["stato_" + stato] == "Accolta" ||
    record.stato_flusso_formatted["stato_" + stato] == "Rifiutata" ? (
    <div>
      {" "}
      <strong>Timer bloccato</strong>
    </div>
  ) : record.data_timer_audit && !record.data_termine_audit ? (
    <div>
      <Tooltip
        placement="topRight"
        title="Giorni alla scadenza dell'audit documentale"
      >
        <strong>
          {30 +
            record.integration_added_days -
            moment().diff(
              moment(record.data_timer_audit, "YYYY-MM-DD"),
              "days"
            )}
        </strong>
      </Tooltip>
    </div>
  ) : (
    ""
  );
};

const getScadenzaAccreditamento = (record, component) => {
  let stato = "ente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigentePermissions",
    ])
  )
    stato = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttorePermissions",
    ])
  )
    stato = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    stato = "ispettore";

  return record.blocked_timer === 1 ||
    record.stato_flusso_formatted["stato_" + stato] == "Accolta" ||
    record.stato_flusso_formatted["stato_" + stato] == "Rifiutata" ? (
    <div>
      {" "}
      <strong>Timer bloccato</strong>
    </div>
  ) : record.data_acquisizione && !record.data_termine_accreditamento ? (
    <div>
      <Tooltip placement="topRight" title="Giorni alla scadenza">
        <strong>
          {90 +
            record.integration_added_days -
            moment().diff(
              moment(record.data_acquisizione, "YYYY-MM-DD"),
              "days"
            )}
        </strong>
      </Tooltip>
    </div>
  ) : (
    ""
  );
};

const getStato = (record, component) => {
  let stato = "ente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigentePermissions",
    ])
  )
    stato = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttorePermissions",
    ])
  )
    stato = "istruttore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    stato = "ispettore";

  if (
    record.stato_flusso_formatted["stato_" + stato] == "Rifiutata" ||
    record.stato_flusso_formatted["stato_" + stato] == "Accolta"
  ) {
    return <div>{record.stato_flusso_formatted["stato_" + stato]}</div>;
  } else
    return (
      <div>
        {record.stato_flusso_formatted["stato_" + stato]}
        {checkPermission.hasPermission(component.props.UserStore.user, [
          "dirigentePermissions",
        ])
          ? getScadenzaAccreditamento(record, component)
          : null}
        {checkPermission.hasPermission(component.props.UserStore.user, [
          "istruttorePermissions",
        ])
          ? getScadenzaAudit(record, component)
          : null}
      </div>
    );
};

const model = (component) => {
  let cols = {
    with_server_pagination: true, // metti paginazione lato server
    columns: [
      {
        title: "",
        dataIndex: "",
        sorter: false,
        key: "actions",
        //width: "300px",
        render: (element) => (
          <div style={{ width: 140 }}>
            {renderPallino(element, component)}
            {renderActions(element, component)}
          </div>
        ),
      },
      {
        title: "N. prot.",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        headerSort: false,
        key: "numero_protocollo",
        render: (element) => (
          <div style={{ width: 80 }}>
            {/* <strong>{(element.numero_protocollo && element.stato_flusso !== 3) ? element.numero_protocollo : ''}</strong> */}
            {
              <strong>
                {element.numero_protocollo ? element.numero_protocollo : ""}
              </strong>
            }
          </div>
        ),
      },
      {
        title: "Acquisita il",
        dataIndex: "",
        sorter: true,
        key: "data_acquisizione",
        render: (element) => (
          <div style={{ width: 140 }}>
            {element.data_acquisizione
              ? moment(element.data_acquisizione, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : ""}
            {/*
            accreditamentoStatic.getTipoLabel(element) === 'Provvisorio' &&
            <p><strong>Durata: {renderDurata(moment.unix(element.created_at))} mesi</strong></p>
          */}
          </div>
        ),
      },
      checkPermission.hasPermission(component.props.UserStore.user, [
        "entePermissions",
      ])
        ? {
            title: "Ultimo aggiornamento",
            dataIndex: "updated_at", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
            sorter: true,
            key: "updated_at",
            render: (element) => (
              <div style={{ width: 140 }}>
                {moment.unix(element).format("DD/MM/YYYY HH:mm")}
              </div>
            ),
          }
        : {},
      {
        title: "Tipo Acc.",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        //key: 'tipo_accreditamento',
        render: (element) => (
          <div style={{ width: 100 }}>
            {accreditamentoStatic.getTipoNoRifLabel(element)}
            <br />
          </div>
        ),
      },
      {
        title: "Stato",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: true,
        key: "stato",
        render: (element) => <div>{getStato(element, component)}</div>,
      },
      {
        title: "Tipologie",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        //key: 'tipologia_accreditamento',
        render: (element) => (
          <div style={{ width: 140 }}>
            {JoinLongArray(
              ", ",
              element.tipologie &&
                element.tipologie.map((obj) => obj.tipologia),
              50
            )}
            {/*element.tipologie && element.tipologie.map((obj, i) => {
            return <p style={{ paddingBottom: '3px' }} key={i}>{obj.tipologia}</p>;
          })*/}
          </div>
        ),
      },
      !checkPermission.hasPermission(component.props.UserStore.user, [
        "entePermissions",
      ])
        ? {
            title: "Ente",
            dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
            sorter: true,
            key: "ente",
            render: (element) => (
              <div style={{ width: 140 }}>{element.ente.denominazione}</div>
            ),
          }
        : {},
      {
        title: "Responsabile Acc.",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        //key: 'ana_nome',
        render: (element) => (
          <div style={{ width: 140 }}>
            {element.istruttore[0] && (
              <p>
                <strong>Istruttore</strong>
                <br /> {element.istruttore[0].anagrafica.nome}{" "}
                {element.istruttore[0].anagrafica.cognome}
              </p>
            )}
          </div>
        ),
      },
      {
        title: "Settori",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        //key: 'settori',
        render: (element) => (
          <div style={{ width: 160 }}>
            {element.settori
              ? JoinLongArray(
                  ", ",
                  element.settori.map((obj) => obj.nome_settore),
                  50
                )
              : null}
            {/*element.settori && element.settori.map((obj, i) => {
            return <p style={{ paddingBottom: '3px' }} key={i}>{obj.nome_settore}</p>;
          })*/}
          </div>
        ),
      },
      {
        title: "Sedi",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        //key: 'settori',
        render: (element) => (
          <div style={{ width: 160 }}>
            {
              element.sedi
                ? JoinLongArray(
                    ", ",
                    element.sedi.map((obj) => {
                      return obj.indirizzo.full_address;
                    }),
                    50
                  )
                : null
              /*&& element.sedi.map((obj, i) => {
            return <p style={{ paddingBottom: '3px' }} key={i}>{obj.indirizzo.full_address}</p>;
          })*/
            }
          </div>
        ),
      },
      {
        title: "Ore annue di formazione",
        dataIndex: "", // dataIndex lo lasciamo sempre vuoto per avere maggiore libertà sui campi
        sorter: false,
        //key: 'settori',
        render: (element) => (
          <div style={{ width: 80 }}>
            {260 *
              8 *
              element.aule.filter(
                (aula) =>
                  aula.id_tab_tipo_aula === 3 ||
                  aula.id_tab_tipo_aula === 4 ||
                  aula.id_tab_tipo_aula === 5
              ).length}
          </div>
        ),
      },
    ],
  };

  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttorePermissions",
    ])
  ) {
    cols.columns.push({
      title: "Data assegnazione",
      dataIndex: "",
      sorter: true,
      key: "data_timer_audit",
      render: (element) => (
        <div style={{ width: 140 }}>
          {element.data_timer_audit
            ? moment(element.data_timer_audit, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )
            : ""}
        </div>
      ),
    });
  }

  return cols;
};

const getStatiFilter = (component) => {
  let u_type = "ente";

  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "dirigentePermissions",
    ])
  )
    u_type = "dirigente";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "ispettorePermissions",
    ])
  )
    u_type = "ispettore";
  if (
    checkPermission.hasPermission(component.props.UserStore.user, [
      "istruttorePermissions",
    ])
  )
    u_type = "istruttore";

  return [
    {
      key: 0,
      value: null,
      label: "Tutte",
    },
    ...accreditamentoStatic.getStatiFiltri(u_type),
  ];
};

const filterFormModel = (component) => {
  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: "integer",
                label: "Num. protocollo",
                name: "numero_protocollo",
              },
            ],
          },
          {
            items: [
              {
                type: "select",
                label: "Tipo accreditamento",
                name: "tipo",
                options: [
                  {
                    value: null,
                    label: "Tutte",
                  },
                  {
                    value: "" + accreditamentoStatic.tipo.provvisorio,
                    label: "Provvisorio",
                  },
                  {
                    value: "" + accreditamentoStatic.tipo.definitivo,
                    label: "Definitivo",
                  },
                  {
                    value: "" + accreditamentoStatic.tipo.rinnovo,
                    label: "Mantenimento",
                  },
                  {
                    value: "" + accreditamentoStatic.tipo.variazione,
                    label: "Variazione",
                  },
                  {
                    value:
                      "" +
                      accreditamentoStatic.tipo
                        .riconoscimento_pregresso_provvisorio,
                    label: "Accreditamento pregresso provvisorio",
                  },
                  {
                    value:
                      "" +
                      accreditamentoStatic.tipo
                        .riconoscimento_pregresso_definitivo,
                    label: "Accreditamento pregresso definitivo",
                  },
                  {
                    value: "" + accreditamentoStatic.tipo.riconoscimento_ente,
                    label: "Riconoscimento Ente",
                  },
                ],
              },
            ],
          },
          {
            items: [
              {
                type: "select",
                label: "Stato",
                name: "stato",
                options: getStatiFilter(component),
              },
            ],
          },
        ],
      },
      component.props.UserStore.user.role.some((r) => r.name !== "Ente")
        ? {
            cols: [
              {
                items: [
                  {
                    type: "input",
                    label: "Ente",
                    name: "ente",
                    input_type: "text",
                  },
                ],
              },
              {
                items: [
                  {
                    type: "input",
                    label: "Partita iva",
                    name: "partita_iva",
                    input_type: "text",
                  },
                ],
              },
              {
                items: [
                  {
                    type: "input",
                    label: "Codice fiscale",
                    name: "cfente",
                    input_type: "text",
                  },
                ],
              },
              {
                items: [
                  {
                    type: "select",
                    label: "Tipologie",
                    name: "tipologia_id",
                    options: [
                      {
                        key: 0,
                        value: null,
                        label: "Tutte",
                      },
                      ...component.props.GlobalStore.stubs.tipo_accreditamento.map(
                        (t) => {
                          return {
                            ...t,
                            value: "" + t.value,
                          };
                        }
                      ),
                    ],
                  },
                ],
              },
              {
                items: [
                  {
                    type: "select",
                    label: "Settori",
                    name: "settore_id",
                    options: [
                      {
                        key: 0,
                        value: null,
                        label: "Tutti",
                      },
                      ...component.props.GlobalStore.stubs.settori.map((t) => {
                        return {
                          ...t,
                          value: "" + t.value,
                        };
                      }),
                    ],
                  },
                ],
              },
              checkPermission.hasPermission(component.props.UserStore.user, [
                "dirigentePermissions",
              ])
                ? {
                    items: [
                      {
                        type: "input",
                        label: "Istruttore",
                        name: "istruttore",
                        input_type: "text",
                      },
                    ],
                  }
                : {},
            ],
          }
        : { cols: [] },
      {
        cols: [
          {
            items: [
              {
                name: "submit",
                type: "submit",
                text: "Cerca",
                btn_type: "primary",
              },
            ],
          },
        ],
      },
    ],
  };
};

class ListModel extends Component {
  base_url = "/accreditamento/richieste";
  default_order = "-created_at";

  state = {
    loading: false,
    open_drawer: false,
    editing_id: null,
    tipoVariazione: 1,
    tipoAccreditamento: 1,
    id_variazione: null,
    data: {
      total: 0,
      current: 1,
      data: [],
    },
    enti: [],
    tipologie: [],
    settori: [],
    istruttore: [],
    medias: [],
    scadenza_adeguamento: false, //true|false - true se attivo il tasto di accreditamento pregresso
  };

  updateMedias(media) {
    let just = localStorage.getItem("document_form_provvedimento");
    if (just) {
      just = JSON.parse(just);
    } else {
      just = { medias: [] };
    }

    just.medias = [...just.medias, media];
    localStorage.setItem("document_form_provvedimento", JSON.stringify(just));

    if (this.props.onUpload) this.props.onUpload(media);
    this.setState({ medias: [...this.state.medias, media] });
  }

  deleteMedia(id) {
    try {
      if (this.props.onDeleteMedia) this.props.onDeleteMedia(id);
    } catch (e) {
      console.error(e);
    }
  }

  sendRinuncia(id_accreditamento) {
    this.setState({ showModalRinuncia: false });
    if (this.state.medias.length !== 0) {
      this.setState({ loading: true });
      ModelServices.rinuncia(
        { medias: this.state.medias },
        id_accreditamento,
        (res) => {
          message.success("Rinuncia avvenuta con successo!");
          this.setState({ loading: false });
          this.refresh();
        },
        (e) => this.setState({ loading: false })
      );
    }
  }

  componentDidMount() {
    this.callAndUpdate(this.props);
  }

  /**
   * Carica dati
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  callAndUpdate(props) {
    let { params, pager } = _.getFilterParam(
      props.location.search,
      this.default_order
    );
    this.setState({ loading: true });
    EnteServices.list({}, (res) => {
      console.warn("EnteServices.list", res.data.data);
      this.setState({
        enti: res.data.data,
      });
    });
    ModelServices.list(
      params,
      (res) => {
        let data = _.getResponse(res);
        console.warn("ModelServices.list - res", res);
        console.warn("ModelServices.list - data", data);
        this.setState(
          {
            loading: false,
            data: data,
          },
          () =>
            this.refs.table_component.updateTableData(
              data.data,
              data.current,
              data.total,
              pager
            )
        );
      },
      (e) => {
        this.setState({ loading: false });
        //console.log('errore dati', e)
      }
    );

    UtilServices.scadenza_adeguamento(
      (res) => {
        let data = _.getResponse(res);
        this.setState({
          scadenza_adeguamento: data.data,
        });
      },
      (e) => {
        this.setState({ loading: false });
        console.error("errore dati", e);
      }
    );
  }

  /**
   * Se cambia la stringa aggiorna
   * @param  {[type]} newProps [description]
   * @return {[type]}          [description]
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.location.search !== this.props.location.search) {
      this.callAndUpdate(newProps);
    }
  }

  getTimer(m) {
    return (
      <div className="color-orange">
        <strong>{m.timer}</strong>
      </div>
    );
  }
  getTimerInterno(m) {
    return (
      <div className="color-orange">
        <strong>{m.timer_interno}</strong>
      </div>
    );
  }
  /**
   * Ricarica dopo update
   * @return {[type]} [description]
   */
  refresh() {
    this.callAndUpdate(this.props);
  }

  /**
   * Chiamato da sort in tabella
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  updateQueryStr(obj) {
    try {
      let q_s = queryString.parse(this.props.location.search);
      // brutto forte
      if (obj.sort) {
        if (q_s.sort && obj.sort) {
          if (q_s.sort === obj.sort) {
            if (q_s.sort[0] === "") {
              q_s.sort = q_s.sort.replace("-", "");
            } else {
              q_s.sort = "-" + q_s.sort;
            }
          } else {
            q_s = { ...q_s, ...obj };
          }
        } else {
          q_s = { ...q_s, ...obj };
        }
      } else {
        q_s = { ...q_s, ...obj };
      }

      this.props.history.push(this.base_url + "?" + queryString.stringify(q_s));
    } catch (e) {
      //console.log('errore cambio url',e);
    }
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  refuseRecord(id) {
    this.setState({ loading: true });
    ModelServices.refuse(
      id,
      () => {
        this.refresh();
        this.setState({ loading: false });
      },
      () => this.setState({ loading: false })
    );
  }

  /**
   * Chiamato da tabella
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  approveRecord(id) {
    this.setState({ loading: true });
    ModelServices.approve(
      { id: id },
      (res) => {
        //console.log(res);
        //const blob = new Blob([res.data], { type: "application/pdf" });
        //FileSaver.saveAs(blob, "approvazione_richiesta.pdf");
        message.success("Email inviata, registrazione confermata!");
        this.refresh();
        this.setState({ loading: false });
      },
      (e) => this.setState({ loading: false })
    );
  }

  addNew(tipo_accreditamento) {
    this.setState({ loading: true }, () => {
      //var dateAccreditamento = this.state.data.data.map((obj) => {
      //  return moment.unix(obj.created_at).toDate();
      //});
      //var maxDate = new Date(Math.max.apply(null, dateAccreditamento));
      //var diff = moment(new Date()).diff(moment(maxDate), 'months');
      // if (dateAccreditamento.length > 0 && diff <= 24) {
      //   message.warn("Non è possibile creare una nuova domanda di accreditamento prima dei 24 mesi dalla precedente")
      //   this.setState({ loading: false })
      // } else {

      ModelServices.create(
        tipo_accreditamento, //{ ...this.props.UserStore },
        (res) => {
          //console.log('RESR', res);
          message.success(
            "Nuova domanda creata con successo, procedere con la compilazione dei dati!"
          );
          this.setState({ loading: false });
          this.props.history.push("/accreditamento/scheda/" + res.data.data.id);
        },
        () => this.setState({ loading: false })
      );

      // }
    });
  }

  addCopy(id) {
    this.setState({ loading: true });
    ModelServices.duplica(
      id,
      (res) => {
        message.success(
          "Nuova domanda duplicata con successo, procedere con la compilazione dei dati!"
        );
        this.setState({ loading: false });
        this.props.history.push("/accreditamento/scheda/" + res.data.data.id);
      },
      (e) => this.setState({ loading: false })
    );
  }

  changeTipo(id, id_tipo) {
    this.setState({ loading: true });
    ModelServices.variazione(
      id,
      this.state.tipoVariazione,
      (res) => {
        var label = accreditamentoStatic.getTipoLabel({ tipo: id_tipo });
        message.success(
          "Predisposizione domanda di tipo " + label + " avvenuta con successo!"
        );
        this.setState({ loading: false });
        this.props.history.push("/accreditamento/scheda/" + res.data.data.id);
      },
      (e) => this.setState({ loading: false })
    );
  }

  rinnova(id) {
    this.setState({ loading: true });
    ModelServices.rinnova(
      id,
      (res) => {
        message.success("Predisposizione rinnovo avvenuto con successo!");
        this.setState({ loading: false });
        this.props.history.push("/accreditamento/scheda/" + res.data.data.id);
      },
      (e) => this.setState({ loading: false })
    );
  }

  definitivo(id) {
    this.setState({ loading: true });
    ModelServices.definitivo(
      id,
      (res) => {
        message.success(
          "Predisposizione domanda di accreditamento definitivo avvenuta con successo!"
        );
        this.setState({ loading: false });
        this.props.history.push("/accreditamento/scheda/" + res.data.data.id);
      },
      (e) => this.setState({ loading: false })
    );
  }

  delete(id) {
    this.setState({ loading: true });
    ModelServices.delete(
      id,
      (res) => {
        message.success("Domanda eliminata con successo!");
        this.setState({ loading: false });
        this.refresh();
      },
      (e) => this.setState({ loading: false })
    );
  }

  printPdf(id_accreditamento) {
    this.setState({ loading: true }, () => {
      ModelServices.printPdf(
        id_accreditamento,
        (res) => {
          this.setState({ loading: false });
          this.saveBlob(res.data);
        },
        (err) => this.setState({ loading: false })
      );
    });
  }

  docVariazioni(id_accreditamento) {
    this.setState({ loading: true }, () => {
      ModelServices.docVariazioni(
        id_accreditamento,
        (res) => {
          this.setState({ loading: false });
          this.saveBlob(res.data);
        },
        (err) => this.setState({ loading: false })
      );
    });
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: "application/pdf" });
    FileSaver.saveAs(_blob, "accreditamento.pdf");

    this.refresh();
  }

  render() {
    return (
      <div>
        <FilterForm
          formLayout={filterFormModel(this)}
          base_url={this.base_url}
          search={this.props.location.search}
          history={this.props.history}
          enti={this.state.enti}
        />
        <Card className="shadow no-pad" bordered={false}>
          <Row type="flex" justify="start" className="m10h">
            {checkPermission.hasPermission(this.props.UserStore.user, [
              "entePermissions",
            ]) && (
              <div className="m5h m5w">
                <Button
                  className="m5h bg-green"
                  icon="plus"
                  onClick={() =>
                    this.addNew(accreditamentoStatic.tipo.provvisorio)
                  }
                  loading={this.state.loading}
                >
                  Nuova richiesta di accreditamento provvisorio
                </Button>
              </div>
            )}

            {checkPermission.hasPermission(this.props.UserStore.user, [
              "entePermissions",
            ]) && (
              <div className="m5h m5w">
                <Button
                  className="m5h bg-green"
                  icon="plus"
                  onClick={() =>
                    this.addNew(accreditamentoStatic.tipo.definitivo)
                  }
                  loading={this.state.loading}
                >
                  Nuova richiesta di accreditamento definitivo
                </Button>
              </div>
            )}

            {/**checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) 
          ?
            this.state.scadenza_adeguamento ?
            <div className="m5h m5w">
              <Button className="m5h bg-green" icon="plus" onClick={() => this.addNew(accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo)} loading={this.state.loading}>Nuova richiesta di accreditamento pregresso</Button>
            </div>
            : null
          : null
          */}

            {checkPermission.hasPermission(this.props.UserStore.user, [
              "entePermissions",
            ]) &&
              this.state.scadenza_adeguamento && (
                <Popconfirm
                  disabled={
                    !checkPermission.hasPermission(this.props.UserStore.user, [
                      "entePermissions",
                      "superAdminPermissions",
                    ])
                  }
                  placement="top"
                  title="Tipo di accreditamento"
                  onConfirm={() =>
                    this.addNew(
                      accreditamentoStatic.tipo
                        .riconoscimento_pregresso_definitivo
                    )
                  }
                  onCancel={() =>
                    this.addNew(
                      accreditamentoStatic.tipo
                        .riconoscimento_pregresso_provvisorio
                    )
                  }
                  okText="Definitivo"
                  cancelText="Provvisorio"
                >
                  <div className="m5h m5w">
                    <Button
                      className="m5h bg-green"
                      icon="plus"
                      loading={this.state.loading}
                    >
                      Nuova richiesta di accreditamento pregresso
                    </Button>
                  </div>
                </Popconfirm>
              )}

            {checkPermission.hasPermission(this.props.UserStore.user, [
              "entePermissions",
            ]) && (
              <div className="m5h m5w">
                <Button
                  className="m5h bg-green"
                  icon="plus"
                  onClick={() =>
                    this.addNew(accreditamentoStatic.tipo.riconoscimento_ente)
                  }
                  loading={this.state.loading}
                >
                  Nuova richiesta di riconoscimento ente formazione
                </Button>
              </div>
            )}

            {checkPermission.hasPermission(this.props.UserStore.user, [
              "dirigentePermissions",
            ]) && (
              <Button
                className="m5h bg-green"
                icon="plus"
                onClick={() => {
                  if (!this.state.loading) {
                    this.setState({ loading: true }, () => {
                      let params = queryString.parse(
                        this.props.location.search
                      );
                      ModelServices.exportPratiche(
                        { ...params, role: "dirigentePermissions" },
                        (res) => {
                          const _blob = new Blob([res.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          });
                          FileSaver.saveAs(_blob, "elenco_pratiche.ods");
                          this.setState({ loading: false });
                        },
                        () => this.setState({ loading: false })
                      );
                    });
                  }
                }}
                loading={this.state.loading}
              >
                Esporta elenco
              </Button>
            )}
          </Row>

          <TableComponent
            data={this.state.data}
            model={model(this)}
            table_props={{ rowKey: "id" }}
            size="medium"
            ref="table_component"
            loading={this.state.loading}
            with_server_pagination={true}
            updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
            updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
          />
        </Card>

        <Modal
          title="Tipo variazione richiesta:"
          visible={this.state.showModalVariazione}
          onOk={() =>
            this.changeTipo(
              this.state.id_variazione,
              accreditamentoStatic.tipo.variazione
            )
          }
          onCancel={() => {
            this.setState({ showModalVariazione: false, id_variazione: null });
          }}
        >
          <RadioGroup>
            <Radio.Group
              defaultValue={1}
              buttonStyle="solid"
              onChange={(e) =>
                this.setState({ tipoVariazione: e.target.value })
              }
            >
              <Radio.Button value={1}>Ex-ante</Radio.Button>
              <Radio.Button value={2}>Ex-post</Radio.Button>
            </Radio.Group>
          </RadioGroup>
        </Modal>

        <Modal
          width="80vw"
          title={
            this.state.acc_accreditamento &&
            this.state.acc_accreditamento.media_rinuncia.length === 0
              ? "Inoltro domanda di Rinuncia accreditamento"
              : "Domanda di rinuncia inoltrata"
          }
          visible={this.state.showModalRinuncia}
          onCancel={() => {
            this.setState({ showModalRinuncia: false });
          }}
          onOk={() => this.sendRinuncia(this.state.acc_accreditamento.id)}
          okButtonProps={{ style: { display: "none" } }}
        >
          <div>
            <div>Sedi interessate alla rinuncia:</div>
            <div>
              {this.state.acc_accreditamento &&
              this.state.acc_accreditamento.sedi
                ? JoinLongArray(
                    ", ",
                    this.state.acc_accreditamento.sedi.map((obj) => {
                      return obj.indirizzo.full_address;
                    }),
                    50
                  )
                : null}
            </div>
          </div>

          {this.state.acc_accreditamento &&
          this.state.acc_accreditamento.media_rinuncia.length === 0 ? (
            <>
              <Documenti
                accept={".p7m,.pdf"}
                canDelete={true}
                canAddNote={false}
                tempFile={true}
                unique={true}
                media_types={
                  this.props.GlobalStore.stubs
                    ? this.props.GlobalStore.stubs.tipo_media
                    : []
                }
                medias={this.state.medias}
                current_domain="accreditamento"
                current_subdomain="doc_rinuncia"
                required_types={this.props.GlobalStore.stubs.tipo_media
                  .filter(
                    (el) =>
                      el.sottodominio === "doc_rinuncia" &&
                      el.dominio === "accreditamento"
                  )
                  .map((e) => e.tipo_media)}
                ref_id={
                  this.state.acc_accreditamento
                    ? this.state.acc_accreditamento.id
                    : ""
                }
                onUpload={(f) => this.updateMedias(f)}
                onDelete={(id) => this.deleteMedia(id)}
                readOnly={false}
                hideModalNoteFacoltative={true}

                /* Provvedimenti */
                //tempFile={true}

                /* In comune tra Provvedimenti e Documenti-esempio */
                /* --- 
                    hideModalNoteFacoltative={true}
                    canDelete={true}
                    canAddNote={false}
                    unique={true}
                    tempFile={true}
                    readOnly={false}
                    media_types={this.props.GlobalStore.stubs ? this.props.GlobalStore.stubs.tipo_media : []}                    
                    medias={this.state.medias}   
                    ref_id={this.state.acc_accreditamento ? this.state.acc_accreditamento.id : ''}
                    current_domain="accreditamento"
                    current_subdomain="doc_rinuncia"   
                    onUpload={(f) => this.updateMedias(f)}
                    onDelete={(id)=> this.deleteMedia(id)}   
                    required_types={this.props.GlobalStore.stubs ? this.props.GlobalStore.stubs.tipo_media
                      .filter(el => el.sottodominio === 'accreditamento' && el.dominio === 'doc_rinuncia')
                      .map(e => e.tipo_media) : []
                    }                                                                      
                    --- */

                /* Documenti-esempio */
                //connection={false}
                //accreditation_id={this.state.acc_accreditamento ? this.state.acc_accreditamento.id : ''}
                //required_dates={false}
              />

              <div>
                <b>Attenzione:</b> caricando l'autocertificazione con i motivi
                di rinuncia e premendo su OK si avvia l'iter di rinuncia
                dell'accreditamento.
                <br />
                Non sarà possibile inviare un nuovo accreditamento prima di 2
                anni.
              </div>

              <Popconfirm
                placement="right"
                title="Confermi di voler inoltrare richiesta di rinuncia per questa pratica?"
                onConfirm={() =>
                  this.sendRinuncia(this.state.acc_accreditamento.id)
                }
                okText="Si"
                cancelText="No"
              >
                <Button
                  disabled={this.state.medias.length === 0}
                  type="primary"
                  className="bg-green m10h"
                  size="small"
                >
                  Inoltra richiesta
                </Button>
              </Popconfirm>
            </>
          ) : (
            <>
              <br />
              <br />

              <table>
                <tr>
                  <th>Domanda di rinuncia protocollata con n°: </th>
                  <td>
                    {this.state.acc_accreditamento &&
                      this.state.acc_accreditamento.numero_protocollo_rinuncia}
                  </td>
                </tr>
                <tr>
                  <th>Data protocollo: </th>
                  <td>
                    {this.state.acc_accreditamento &&
                      moment(
                        this.state.acc_accreditamento.data_protocollo_rinuncia,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")}
                  </td>
                </tr>
              </table>
              <br />
              <br />

              <Button
                loading={
                  (this.state.acc_accreditamento &&
                    this.state.acc_accreditamento.downloading) ||
                  false
                }
                disabled={
                  (this.state.acc_accreditamento &&
                    this.state.acc_accreditamento.downloading) ||
                  false
                }
                icon="download"
                onClick={() => {
                  MediaServices.download(
                    this.state.acc_accreditamento.media_rinuncia.id,
                    async (res) => {
                      const blob = new Blob([res.data], {
                        type: this.state.acc_accreditamento.media_rinuncia
                          .mime_type,
                      });
                      FileSaver.saveAs(
                        blob,
                        this.state.acc_accreditamento.media_rinuncia.tipo
                          .nome_file
                      );
                    },
                    () => null
                  );
                }}
              >
                <span>
                  {this.state.acc_accreditamento &&
                  this.state.acc_accreditamento.media_rinuncia &&
                  this.state.acc_accreditamento.media_rinuncia.tipo
                    ? this.state.acc_accreditamento.media_rinuncia.tipo
                        .tipo_media
                    : ""}
                </span>
                <br />
              </Button>

              <br />
              <br/><br/>

<Button
    loading={(this.state.acc_accreditamento && this.state.acc_accreditamento.downloading) || false}
    disabled={(this.state.acc_accreditamento && this.state.acc_accreditamento.downloading) || false}
    icon="download" onClick={
    () => {    
          MediaServices.download(this.state.acc_accreditamento.media_rinuncia.id, async (res) => {
            const blob = new Blob([res.data], { type: this.state.acc_accreditamento.media_rinuncia.mime_type });
            FileSaver.saveAs(blob, this.state.acc_accreditamento.media_rinuncia.tipo.nome_file);
          }, () => null)
        }
  }
>
  <span>{this.state.acc_accreditamento && this.state.acc_accreditamento.media_rinuncia && this.state.acc_accreditamento.media_rinuncia.tipo ? this.state.acc_accreditamento.media_rinuncia.tipo.tipo_media : ''}</span><br/> 
</Button>  
            </>
          )}
        </Modal>
      </div>
    );
  }
}

export default ListModel;
