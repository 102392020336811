import React, { Component } from 'react';

import { Popconfirm, List, Icon, Row, Col, Modal } from 'antd';

import moment from 'moment';

import DocumentiForm from './DocumentiForm';
import MediaServices from '../services/media/Media';
import DocSpan from './DocSpan';
import CommonForm from './form';


const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    },

  ],
  onSubmit: 'submitted',
};

/**
 * Required props:
 *
 * media_types -> tipi di file
 * medias -> lista file
 *
 * current_domain -> dominio da cui prendere i file
 * required_types -> nome dei tipi obbligatori
 *
 * ref_id -> id di riferimento del modello (ad esempio ente o accreditamento)
 */
class Documenti extends Component {


  state = {
    loading: false,
    removing: [],
    medias: []
  }



  async componentDidMount() {
    //console.log('props', this.props)
    //console.log(this.props)
    await this.setState({ medias: this.props.medias })
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.medias !== newProps.medias) {
      //console.log('props', this.props)
      //console.log(this.props)
      await this.setState({ medias: newProps.medias })
    }
  }

  update() {
    //this.props.onUpdate({documenti})
  }

  avabileTipiMedia() {
    try {
      if (!this.props.current_subdomain) return this.props.media_types.filter(tipo => tipo.dominio === this.props.current_domain)
      if (this.props.current_subdomain) return this.props.media_types.filter(tipo => tipo.sottodominio === this.props.current_subdomain)
    } catch (e) {
      return []
    }
  }

  /**
   * Soft delete
   * @param  {[type]} id [description]
   * @return {[type]}    [description]
   */
  async removeFile(id) {
    if (this.props.onRemoving) this.props.onRemoving(id);
    if (!this.props.tempFile && !this.state.removing.includes(id)) {
      this.setState({ removing: [...this.state.removing, id] }, () => {
        MediaServices.deleteConnection(id, {
          connection: this.props.connection === false ? false : true,
          domain: this.props.current_domain,
          related_id: this.props.ref_id || ""
        }, () => {
          if (this.props.onDelete) this.props.onDelete(id);
          if (this.props.onFinish) this.props.onFinish(id);

          this.setState({
            medias: this.state.medias.filter(item => item.id !== id),
            /*this.state.medias.map(m => {
              return m.id !== id ? m : { ...m, deleted: 1 }
            }),*/
            removing: this.state.removing.filter(el => el !== id)
          })

        }, () => {
          if (this.props.onFinish) this.props.onFinish(id);
          this.setState({ removing: this.state.removing.filter(el => el !== id) })
        })
      });
    } else if (this.props.tempFile && !this.state.removing.includes(id)) {
      if (this.props.onRemoving) this.props.onRemoving(id);
      this.setState({ removing: [...this.state.removing, id] }, () => {
        MediaServices.deleteTemp(id, () => {
          if (this.props.onDelete) this.props.onDelete(id);
          if (this.props.onFinish) this.props.onFinish(id);

          this.setState({
            // medias: this.state.medias.map(m => {
            //   return m.id !== id ? m : { ...m, deleted: 1 }
            // }),
            medias: this.state.medias.filter(item => item.id !== id),
            removing: this.state.removing.filter(el => el !== id)
          })

        }, () => {
          if (this.props.onFinish) this.props.onFinish(id);
          this.setState({ removing: this.state.removing.filter(el => el !== id) })
        })
      });
    }
  }

  getSource(id_tipo = null) {
    try {

      return (!id_tipo) ? this.state.medias.filter(m => m.tipo && m.tipo.dominio === this.props.current_domain)
        : this.state.medias.filter(m => m.tipo && m.tipo.id === id_tipo)

    } catch (e) {
      return []
    }
  }

  getExists(id_tipo = null) {
    try {
      return (!id_tipo) ? this.state.medias.filter(m => m.tipo && m.tipo.dominio === this.props.current_domain).length > 0
        : this.state.medias.filter(m => m.tipo && m.tipo.id === id_tipo && m.deleted !== 1).length > 0
    } catch (e) {
      return false
    }
  }

  getTypeName(item) {
    return (this.props.required_types && this.props.required_types.length > 0 && this.props.required_types.indexOf(item.tipo_media) !== -1) ? <React.Fragment><span style={{ color: 'red' }}>* </span>{item.tipo_media}</React.Fragment> : item.tipo_media;
  }


  getValidity(item) {
    try {

      let val_str = "";
      if (moment(item.valido_dal, 'YYYY-MM-DD').isValid()) val_str += moment(item.valido_dal, 'YYYY-MM-DD').format('DD/MM/YYYY');
      if (moment(item.valido_al, 'YYYY-MM-DD').isValid()) val_str += " - " + moment(item.valido_al, 'YYYY-MM-DD').format('DD/MM/YYYY');

      if (val_str === "") throw new Error("Not valid");

      return <p><strong>Validità:</strong> {val_str}</p>
    } catch (e) {
      return ""
    }
  }


  render() {
    //const token = localStorage.getItem('token');
    return (
      <div>
        <List
          dataSource={this.avabileTipiMedia()}
          renderItem={type_item => (
            <List.Item style={(this.props.ListItem && this.props.ListItem.style) ? this.props.ListItem.style : {}}>
              <div style={{ width: '100%' }}>
                <Row>
                  <Col 
                  sm={ this.props.descriptionSection && this.props.descriptionSection.sm ? this.props.descriptionSection.sm : 24 }
                  xs={ this.props.descriptionSection && this.props.descriptionSection.xs ? this.props.descriptionSection.xs : 24 }
                  lg={ this.props.descriptionSection && this.props.descriptionSection.lg ? this.props.descriptionSection.lg : 8 }
                  >
                    {!this.props.hideTitle ? <h4 className="text-danger" style={{ maxWidth: '80%' }}> {this.getTypeName(type_item)}</h4> : null}
                    {this.props.children}
                    {type_item.facsimile && <DocSpan className="m2h" key={type_item.facsimile.id} file={type_item.facsimile} />}
                    {(!this.props.cannotAdd && !this.props.readOnly) ?
                    <DocumentiForm
                      root={this.props.root}
                      required_dates={this.props.required_dates || type_item.mesi_validita > 0}
                      accreditation_id={this.props.accreditation_id}
                      accept={this.props.accept}
                      uploadButtonText={this.props.uploadButtonText}
                      tipo_media={[type_item]}
                      ref_id={this.props.ref_id}
                      tempFile={this.props.tempFile}
                      onInsert={(f) => {
                        console.warn("onInsert f", f);
                        console.warn("onInsert this.state.medias", this.state.medias);
                        if(typeof this.state.medias == 'undefined'){
                          this.setState({ medias: [f] })
                        }else{
                          this.setState({ medias: [...this.state.medias, f] })
                        }

                        if (this.props.unique) {
                          let ex = this.getSource(f.tipo_media);
                          ex.filter(el => el.id !== f.id).map(ff => this.removeFile(ff.id));
                        }
                        //this.props.onUpload ? this.props.onUpload(this.state.medias) : null
                        if(this.props.onUpload)
                          this.props.onUpload(f);
                      }} 
                      downloadTemplateEnabled={this.props.downloadTemplateEnabled || false}
                      downloadTemplateOnClick={this.props.downloadTemplateOnClick || undefined}
                      hideModalNoteFacoltative={this.props.hideModalNoteFacoltative || false}
                      /> : null}
                  </Col>
                  <Col 
                  sm={ this.props.iconSection && this.props.iconSection.sm ? this.props.iconSection.sm : 24 }
                  xs={ this.props.iconSection && this.props.iconSection.xs ? this.props.iconSection.xs : 24 }
                  lg={ this.props.iconSection && this.props.iconSection.lg ? this.props.iconSection.lg : 2 }
                  >
                    {(this.getExists(type_item.id)) ?
                      <Icon style={{ color: 'green', fontSize: 24 }} type="check-circle" /> :
                      <Icon style={{ color: 'red', fontSize: 24 }} type="close-circle" />}
                  </Col>
                  <Col 
                  sm={ this.props.uploadSection && this.props.uploadSection.sm ? this.props.uploadSection.sm : 24 }
                  xs={ this.props.uploadSection && this.props.uploadSection.xs ? this.props.uploadSection.xs : 24 }
                  lg={ this.props.uploadSection && this.props.uploadSection.lg ? this.props.uploadSection.lg : 14 }
                  >
                    <List
                      dataSource={this.getSource(type_item.id)}
                      renderItem={item => {
                        return (
                          <List.Item>
                            <Row style={{ width: '100%' }}>
                              <Col span={20}>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  <DocSpan key={item.id} file={item} /><br />
                                  {this.getValidity(item)}
                                  {item.note.map((n, i) => {
                                    return <div key={"nn_" + i}><small><strong>{(n.user) ? n.user.username : " - "}:</strong> {n.note}</small></div>
                                  })}
                                </div>
                              </Col>
                              <Col span={4}>
                                {(item.deleted !== 1 && this.props.canDelete && !this.props.readOnly) ?
                                  <React.Fragment>
                                    {this.state.removing.indexOf(item.id) === -1 ?
                                      <Popconfirm placement="leftTop" title="Sicuro di voler rimuovere questo elemento" onConfirm={() => this.removeFile(item.id)} okText="Si" cancelText="No">
                                        <Icon type="close" style={{ fontSize: 14, marginLeft: 10, cursor: 'pointer', color: 'red' }} />
                                      </Popconfirm>
                                      : <Icon type="loading" style={{ fontSize: 14, marginLeft: 10 }} />
                                    }
                                  </React.Fragment>
                                  : null}
                                {(this.props.canAddNote && !this.props.readOnly) ?
                                  <Icon type="edit" style={{ fontSize: 14, marginLeft: 10, cursor: 'pointer' }} onClick={() => {
                                    this.setState({ item_id: item.id, open_modal_note: true })
                                  }} /> : null}
                              </Col>
                            </Row>
                          </List.Item>
                        )
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </List.Item>
          )}
        />
        <Modal
          title="Inserisci note"
          visible={this.state.open_modal_note}
          onOk={() => {
            MediaServices.addNote(this.state.item_id, { note: this.formNoteRef.props.form.getFieldsValue()['note'] }, (res) => {
              this.setState({
                open_modal_note: false, medias: this.state.medias.map(m => {
                  return m.id === this.state.item_id ? { ...m, note: res.data.data.note } : m
                })
              });
            }, err => console.error(err))
          }}
          onCancel={() => this.setState({ open_modal: false })}
        >
          <CommonForm

            wrappedComponentRef={inst => (this.formNoteRef = inst)}
            loading={this.props.loading || false}
            form_model={formNoteLayout}
            values={{

            }}
          />
        </Modal>
      </div>
    );
  }
}
export default Documenti;
