import React, { Component } from "react";
//import { connect } from "react-redux";

import { message } from "antd";

import Documenti from "@common/components/Documenti";

import CommonForm from "@common/components/form";

import format from "@common/utils/formatters/_";

import CapacitaGestionaliServices from "../../../services/CapacitaGestionali";

import allegatiStatic from "@common/utils/static/allegati";

const formLayout = (component, documenti_capacita_gestionali_allegato_1, documenti_capacita_gestionali_allegato_2, documenti_capacita_gestionali_allegato_3) => {

  return {
    layout: "vertical",
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                type: "radio",
                label:
                  "Dichiaro di applicare il CCNL agli operatori  della Formazione Professionale",
                name: "assunzione_applicazione_ccnl",
                option_type: "button", // normal|button
                onChange: 'assunzione_ccnl',
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "radio",
                label:
                  "Dichiaro di attenermi alle procedure di pubblicizzazione dell’attività",
                name: "procedure_pubblicazione",
                option_type: "button", // normal|button
                onChange: 'pubblicizzazione_attività',
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                props: {
                  disabled: component.props.readOnly,
                },
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: "radio",
                label:
                  "Dichiaro di attenermi alle procedure di aggiornamento delle competenze delle risorse umane",
                name: "aggiornamento_competenze",
                option_type: "button", // normal|button
                onChange: 'risorse_umane',
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si",
                  },
                  {
                    value: "0",
                    label: "No",
                  },
                ],
                validations: [
                  {
                    type: "required",
                    error_message: "Campo obbligatorio",
                  },
                ],
              },
            ],
          },
        ],
      },
      { 
        cols: [
          {
            items: [
              {
                name: 'child',
                type: 'child',
                child: component.state.assunzione_applicazione_ccnl == 1 ?
                  <Documenti
                  canDelete={false}
                  canAddNote={false}
                    media_types={allegatiStatic.filteredAccreditationMedia(
                      component.props.GlobalStore.stubs.tipo_media,
                      component.props.accreditamento.tipo_accreditamento
                    )}
                    medias={component.props.accreditamento.ente.media}
                    unique={true}
                    current_domain="ente"
                    current_subdomain="documenti_capacita_gestionali_allegato_3"
                    required_types={allegatiStatic.filteredRequiredMedia(
                      component.props.GlobalStore.stubs.tipo_media,
                      "ente",
                      "documenti_capacita_gestionali_allegato_3"
                    )}
                    ref_id={component.props.accreditamento.ente.id}
                    readOnly={documenti_capacita_gestionali_allegato_3}
                    accreditation_id={component.props.accreditamento.id} 
                  />
                  : ''
                  
                
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'child',
                type: 'child',
                child: component.state.procedure_pubblicazione == 1 ?
                <Documenti
                canDelete={false}
                canAddNote={false}
                media_types={allegatiStatic.filteredAccreditationMedia(
                  component.props.GlobalStore.stubs.tipo_media,
                  component.props.accreditamento.tipo_accreditamento
                )}
                medias={component.props.accreditamento.ente.media}
                unique={true}
                current_domain="ente"
                current_subdomain="documenti_capacita_gestionali_allegato_1"
                required_types={allegatiStatic.filteredRequiredMedia(
                  component.props.GlobalStore.stubs.tipo_media,
                  "ente",
                  "documenti_capacita_gestionali_allegato_1"
                )}
                ref_id={component.props.accreditamento.ente.id}
                readOnly={documenti_capacita_gestionali_allegato_1}
                accreditation_id={component.props.accreditamento.id}
              /> : ''
                
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'child',
                type: 'child',
                child: component.state.aggiornamento_competenze == 1 ?
                <Documenti
            canDelete={false}
            canAddNote={false}
            media_types={allegatiStatic.filteredAccreditationMedia(
              component.props.GlobalStore.stubs.tipo_media,
              component.props.accreditamento.tipo_accreditamento
            )}
            medias={component.props.accreditamento.ente.media}
            unique={true}
            current_domain="ente"
            current_subdomain="documenti_capacita_gestionali_allegato_2"
            required_types={allegatiStatic.filteredRequiredMedia(
              component.props.GlobalStore.stubs.tipo_media,
              "ente",
              "documenti_capacita_gestionali_allegato_2"
            )}
            ref_id={component.props.accreditamento.ente.id}
            readOnly={documenti_capacita_gestionali_allegato_2}
            accreditation_id={component.props.accreditamento.id}
            /> :''
                
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: "submit",
                name: "btn_submit",
                text: "Salva sezione capacità gestionale",
                props: {
                  style: { float: "right" },
                  className: "bg-green",
                  //type: "primary",
                  icon: "save",
                },
                showIf: [
                  {
                    type: "prop_func",
                    func: "checkPermissions",
                  },
                ],
                //onClick: 'showDrawer'
              },
            ],
          },
        ],
      },
    ],
    onSubmit: "submitted",
  };
};

class CreateModel extends Component {
  state = {
    loading: false,
    model: {},
    assunzione_applicazione_ccnl: 1,
    procedure_pubblicazione: 1,
    aggiornamento_competenze: 1,
  };

  getFormLayout() {}

  componentDidMount() {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.capacita !== this.props.capacita) this.setData(newProps);
  }

  setData(props) {
    if (props.capacita)
      this.setState({
        model: format.convertBoolean(
          [
            "assunzione_applicazione_ccnl",
            "procedure_pubblicazione",
            "aggiornamento_competenze",
          ],
          props.capacita
        ),
      });
  }

  async confirm(data) {
    if (!this.state.loading) {
      await this.setState({ loading: true });

      CapacitaGestionaliServices.edit(
        data,
        this.props.accreditamento.id,
        (res) => {
          this.setState({
            loading: false,
            model: format.convertBoolean(
              [
                "assunzione_applicazione_ccnl",
                "procedure_pubblicazione",
                "aggiornamento_competenze",
              ],
              res.data.data
            ),
          });
          message.success("Dati salvati");
          if (this.props.onUpdate) this.props.onUpdate();
        },
        (err) => this.setState({ loading: false })
      );
    }
  }



  render() {
    const isRealReadOnly = (this.props.readOnlyDocumenti && this.props.readOnlyDocumenti === true) ? this.props.readOnlyDocumenti : this.props.readOnly;
    const allMedias = this.props.accreditamento.ente.media;
    let documenti_capacita_gestionali_allegato_1 = isRealReadOnly;
    let documenti_capacita_gestionali_allegato_2 = isRealReadOnly;
    let documenti_capacita_gestionali_allegato_3 = isRealReadOnly;

    if(isRealReadOnly){
      let mediaIdToSearch1 = 0;
      let mediaIdToSearch2 = 0;
      let mediaIdToSearch3 = 0;

      const requiredMedia1 = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, "ente", "documenti_capacita_gestionali_allegato_1" )[0];
      const requiredMedia2 = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, "ente", "documenti_capacita_gestionali_allegato_2" )[0];
      const requiredMedia3 = allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, "ente", "documenti_capacita_gestionali_allegato_3" )[0];
      
      const mediaTypes = allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media,this.props.accreditamento.tipo_accreditamento);

      mediaTypes.map(element => {
        if(element.label === requiredMedia1){
          mediaIdToSearch1 = element.value;
        }
        if(element.label === requiredMedia2){
          mediaIdToSearch2 = element.value;
        }
        if(element.label === requiredMedia3){
          mediaIdToSearch3 = element.value;
        }
      });
      if(mediaIdToSearch1 !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch1){
            found = true;
          }
        })
        documenti_capacita_gestionali_allegato_1 = found;
      }

      if(mediaIdToSearch2 !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch2){
            found = true;
          }
        })
        documenti_capacita_gestionali_allegato_2 = found;
      }

      if(mediaIdToSearch3 !== 0){
        let found = false;
        allMedias.map(m => {
          if(m.tipo_media === mediaIdToSearch3){
            found = true;
          }
        })
        documenti_capacita_gestionali_allegato_3 = found;
      }
    }
    return (
      <div style={{ marginTop: 24 }}>
        <CommonForm
          wrappedComponentRef={(inst) => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout(this, documenti_capacita_gestionali_allegato_1, documenti_capacita_gestionali_allegato_2, documenti_capacita_gestionali_allegato_3)}
          values={this.state.model}
          submitted={(dati) => this.confirm(dati)}
          readOnly={this.props.readOnly}
          checkPermissions={() => true}
          assunzione_ccnl={ (name, val) => {this.setState({ assunzione_applicazione_ccnl: val.target.value })} }
          pubblicizzazione_attività={ (name, val) => {this.setState({ procedure_pubblicazione: val.target.value })} }
          risorse_umane={ (name, val) => {this.setState({ aggiornamento_competenze: val.target.value })} }
        />
      </div>
    );
  }
}
export default CreateModel;
