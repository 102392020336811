import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import checkPermission from '@common/utils/check/permissions';
import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';
import EnteService from '@accreditamento/services/Ente';

import formatter from '@common/utils/formatters/_';
import moment from 'moment';
import QualificaProfessionaliService from '../../../repertorio/services/QualificaProfessionale'

import { Icon, Spin, Tooltip , Button , Drawer } from 'antd';
import QrIscrizione from '../courseRegistration/QrIscrizione';


import PropTypes from 'prop-types';
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';
import Ateco2007Services from '@modules/tabelle/services/Ateco2007';
import IstatProfessioniServices from '@modules/tabelle/services/IstatProfessioni';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'dati_generali_progetto',
                type: 'title',
                element: 'h4',
                text: 'Dati generali del progetto',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Tipologia di corso',
                name: 'AfcProjectInfoFormSection.courseType',
                option_type: 'button',
                onChange: 'changedTipologia',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ],
                options: [
                  {
                    value: 1, //TODO controllare i valori dello stub
                    label: "Finanziato"
                  },
                  {
                    value: 2, //TODO controllare i valori dello stub
                    label: "Autofinanziato"
                  }
                ]
              }
            ],
          },
          {
            span: 16,
            items: [
              {
                type: 'input',
                label: 'Denominazione del progetto',
                type_string: 'text', // field type
                name: 'AfcProjectInfoFormSection.courseTitle',
                //props: {
                //  disabled: component.props.readOnly,
                //},
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcProjectInfoFormSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'dati_finanziamento',
                type: 'title',
                element: 'h4',
                text: 'Dati finanziamento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Finanziamento',
                type_string: 'text',
                name: 'AfcFinancingDataFormSection.financing',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Asse',
                type_string: 'text',
                name: 'AfcFinancingDataFormSection.axis',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Obiettivo specifico',
                type_string: 'text',
                name: 'AfcFinancingDataFormSection.specificObjective',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcFinancingDataFormSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'riferimenti_legge_regionale',
                type: 'title',
                element: 'h4',
                text: 'Riferimenti Legge regionale 18/85',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLegalReferenceSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'Riferimenti Legge regionale 18/85',
                name: 'AfcLegalReferenceSection.article',
                option_type: 'button',
                onChange: 'changedRiferimentiLegge',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcLegalReferenceSection ? true : false; }
                  }
                ],
                options: [
                  {
                    value: 1, //TODO controllare i valori dello stub
                    label: "Art. 40"
                  },
                  {
                    value: 2, //TODO controllare i valori dello stub
                    label: "Art. 41"
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'Riferimenti_SIURP',
                type: 'title',
                element: 'h4',
                text: 'Riferimenti SIURP',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSiurpReferenceSection && !component.isEnte() ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Codice identificativo del progetto',
                type_string: 'text',
                name: 'AfcSiurpReferenceSection.projectId',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSiurpReferenceSection && !component.isEnte() ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Codice corso',
                type_string: 'text',
                name: 'AfcSiurpReferenceSection.courseId',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSiurpReferenceSection && !component.isEnte() ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'edizione',
                type: 'title',
                element: 'h4',
                text: 'Edizioni',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEditionSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            //span: 8,
            items: [
              {
                type: 'integer',
                label: 'Numero edizioni',
                name: 'AfcEditionSection.editionNumber',
                props: {
                  min: 1
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEditionSection ? true : false; }
                  }
                  //TODO Deve essere inserito in fase di attivazione e poi negli anni successivi(Se  successiva alla prima prevedere la possibilità di selezionare e copiare i dati da una selezione precedente e consentirne la modifica)
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'macrotipologia',
                type: 'title',
                element: 'h4',
                text: 'Macrotipologia',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcMacroTypologySection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Macrotipologia',
                name: 'AfcMacroTypologySection.macroTypology',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_macrotipologie',
                onChange: 'selectedMacrotipologia',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcMacroTypologySection ? true : false; }
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'periodo_svolgimento_corso',
                type: 'title',
                element: 'h4',
                text: 'Periodo svolgimento corso indicativo',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ]
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di avvio del corso',
                format: 'DD/MM/YYYY',
                name: 'AfcCourseTimeSection.startDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'min_date',
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'datepicker',
                label: 'Data di conclusione del corso',
                format: 'DD/MM/YYYY',
                name: 'AfcCourseTimeSection.endDate',
                props: {
                  size: 'large',
                  style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    validator: 'max_date'
                  }
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcCourseTimeSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'modalita_formativa',
                type: 'title',
                element: 'h4',
                text: 'Modalità formativa',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalModalitySection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                mode: 'multiple',
                label: 'Classificazione CLA istat a 3 digit',
                name: 'AfcEducationalModalitySection.claClassification',
                options_props: '_modalita_formativa',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalModalitySection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'contenuto_formativo',
                type: 'title',
                element: 'h4',
                text: 'Contenuto formativo',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalContentSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Classificazione dei contenuti formativi/educativi',
                name: 'AfcEducationalContentSection.educationalContent',
                options_props: '_contenuto_formativo',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcEducationalContentSection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'modalita_svolgimento',
                type: 'title',
                element: 'h4',
                text: 'Modalità di svolgimento',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExecutionModalitySection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Modalità di svolgimento',
                name: 'AfcExecutionModalitySection.modality',
                options_props: '_modalita_svolgimento',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExecutionModalitySection ? true : false; }
                  }
                ],
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'oneri_imposti_allievi',
                type: 'title',
                element: 'h4',
                text: 'Oneri imposti agli allievi',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility) ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Tassa di iscrizione (in €)',
                name: 'AfcLearnerPaymentsSection.entryFee',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility)  ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Costo intero corso comprensivo di IVA (in €) (Per anno nel caso di corsi pluriennali)',
                name: 'AfcLearnerPaymentsSection.courseCost',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility)  ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Retta mensile di frequenza (in €) ',
                name: 'AfcLearnerPaymentsSection.monthlyFee',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility)  ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Altro gravame economico (in €) ',
                name: 'AfcLearnerPaymentsSection.otherPayment',
                props: {
                  min: 0,
                  onBlur: () => { return component.totalOneriImpostiAgliAllievi() }
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility)  ? true : false; }
                  }
                ],
              }
            ],
          },
          {
            span: 8,
            items: [
              {
                type: 'integer',
                label: 'Totale',
                name: 'AfcLearnerPaymentsSection.total',
                props: {
                  disabled: true,
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility)  ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Descrizione modalità e tempi di versamento della retta e modalità di atto di rinuncia',
                type_string: 'text',
                name: 'AfcLearnerPaymentsSection.paymentsDescription',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcLearnerPaymentsSection && component.props.generalData.AfcLearnerPaymentsSection.visibility)  ? true : false; }
                  }
                ],
              }
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'tipologia_attivita',
                type: 'title',
                element: 'h4',
                text: 'Tipologia attività',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcActivityTypeSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Tipologia attività',
                name: 'AfcActivityTypeSection.activityType',
                options_props: '_tipologia_attivita',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcActivityTypeSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'esame_attestazione_finale',
                type: 'title',
                element: 'h4',
                text: 'Esame e attestazione finale',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExamSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'radio',
                label: 'Previsto Esame finale ',
                name: 'AfcExamSection.examRequired',
                option_type: 'button',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExamSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                options: [
                  {
                    value: 1,
                    label: "No"
                  },
                  {
                    value: 2,
                    label: "Si"
                  }
                ]
              }
            ],
          },
          {
            span: 18,
            items: [
              {
                type: 'select',
                label: 'Tipologia dell’attestazione finale rilasciata alla fine del corso',
                options_props: '_attestazione_finale',
                name: 'AfcExamSection.certificateType',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcExamSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'settore',
                type: 'title',
                element: 'h4',
                text: 'Settore',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSectorSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Area tematica di riferimento del corso',
                name: 'AfcSectorSection.courseThematicArea',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_aree_tematiche_corso',
                onChange: 'selectedAreaTematicaCorso',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSectorSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Specificare area tematica di riferimento del corso',
                type_string: 'text', // field type
                name: 'AfcSectorSection.courseThematicAreaOther',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.state._aree_tematiche_corso_altro_selected ? true : false; }
                  }
                ],
                validations: component.state._aree_tematiche_corso_altro_selected ? [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ] : []
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Settore di riferimento del corso',
                name: 'AfcSectorSection.sectorType',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_settori_riferimento_formulario',
                onChange: 'selectedSettoreRiferimentoFormulario',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSectorSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Specificare settore riferimento del corso',
                type_string: 'text', // field type
                name: 'AfcSectorSection.sectorTypeOther',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.state._settori_riferimento_formulario_selected ? true : false; }
                  }
                ],
                validations: component.state._settori_riferimento_formulario_selected ? [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ] : []
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'info_base_sul_percorso',
                type: 'title',
                element: 'h4',
                text: 'Informazioni di base sul percorso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcTrainingBasicInfoSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'input',
                label: <>Informazioni di base sul percorso
                  { component.props.showNote &&
                    <Tooltip placement="top" title="I dati e le informazioni inserite nel presente paragrafo saranno utilizzati per valutare la coerenza interna del progetto formativo (area di valutazione B, criterio B.2)"><Icon className="m5w" type="info-circle" /></Tooltip>
                  }
                </>,
                type_string: 'text', // field type
                name: 'AfcTrainingBasicInfoSection.basicInfo',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcTrainingBasicInfoSection ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'repertorio',
                text: <>Profilo Professionale
                  {component.props.showNote &&
                    <Tooltip placement="top" title="Per le Figure professionali (e i relativi indirizzi) del Repertorio nazionale di IeFP si rinvia a quanto previsto nel Decreto Ministeriale 11 novembre 2011, così come integrato dal Decreto Ministeriale 23 aprile 2012."><Icon className="m5w" type="info-circle" /></Tooltip>
                  }</>,
                type: 'title',
                element: 'h4',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'corso',
                text: <>Corso</>,
                type: 'title',
                element: 'h4',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif41_selected ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Figura professionale',
                name: 'AfcIndexSection.title',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                },
                onChange: 'selectedAtecoCp',
                options_props: '_figure_professionali',
                validations: [
                  {
                    type: 'required_if_func',
                    error_message: 'Selezionare almeno uno tra \'Figura professionale\', \'Qualifica repertorio nazionale\', \'Profili gestiti da normative regionali/nazionali\'',
                    //func: () => { return component.validationQualificaProfessionale() }
                    func: () => { return component.validationSectionRepertorio() }
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Qualifica repertorio nazionale',
                name: 'AfcIndexSection.nationalRepertoireQualification',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_qualifiche_repertorio_nazionale',
                validations: [
                  {
                    type: 'required_if_func',
                    error_message: 'Selezionare almeno uno tra \'Figura professionale\', \'Qualifica repertorio nazionale\', \'Profili gestiti da normative regionali/nazionali\'',
                    func: () => { return component.validationSectionRepertorio() }
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Profili gestiti da normative regionali/nazionali',
                name: 'AfcIndexSection.profilesManagedByRegulations',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_profili_gestiti_da_normative_regionali_nazionali',
                validations: [
                  {
                    type: 'required_if_func',
                    error_message: 'Selezionare almeno uno tra \'Figura professionale\', \'Qualifica repertorio nazionale\', \'Profili gestiti da normative regionali/nazionali\'',
                    func: () => { return component.validationSectionRepertorio() }
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ]
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Denominazione corso',
                type_string: 'text', // field type
                name: 'AfcIndexSection.denominazioneCorso',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif41_selected ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione corso',
                type_string: 'text', // field type
                name: 'AfcIndexSection.descrizioneCorso',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif41_selected ? true : false; }
                  }
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                mode: 'multiple',
                label: 'Codice Ateco 2007',
                name: 'AfcIndexSection.ateco2007',
                props: {
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_ateco2007',
                validations: [
                  {
                    type: 'required_if_func',
                    error_message: 'Campo obbligatorio',
                    func: () => { return component.validationSectionRepertorioAteco() }
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && (component.state.rif41_selected || component.state.rif40_selected ) ? true : false; }
                  }
                ]
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'textarea',
                label: 'Eventuali normative inerenti la regolamentazione del corso',
                type_string: 'text', // field type
                name: 'AfcIndexSection.normativeCorso',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif41_selected ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Riferimenti del Repertorio Regionale delle Qualifiche e delle Competenze',
                type_string: 'text', // field type
                name: 'AfcIndexSection.riferimentiRepertorio',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif41_selected ? true : false; }
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                mode: 'multiple',
                label: 'ISTAT Professioni (CP 2011)',
                name: 'AfcIndexSection.istatCp2011',
                props: {
                  disabled: component.props.readOnly,
                  showSearch: true,
                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                },
                options_props: '_istatCp2011',
                validations: [
                  {
                    type: 'required_if_func',
                    error_message: 'Campo obbligatorio',
                    func: () => { return component.validationSectionRepertorioIstatProfessioni() }
                  },
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ]
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'textarea',
                label: 'Descrizione del Profilo Professionale',
                type_string: 'text', // field type
                name: 'AfcIndexSection.profilesManagedDescription',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'radio',
                label: 'La Figura Professionale è regolamentata da apposite norme' ,
                option_type: 'button',
                name: 'AfcIndexSection.profilesManagedRegolamentation',
                onChange: 'setDescrizineNormativa',
                options: [
                  {
                    value: "1",
                    label: 'Si'
                  },
                  {
                    value: "0",
                    label: 'No'
                  }
                ],
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcIndexSection && component.state.rif40_selected ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Normativa di riferimento ',
                type_string: 'text', // field type
                name: 'AfcIndexSection.profilesManagedRegolamentationText',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return (component.props.generalData.AfcIndexSection && component.state.normativa_selected === true && component.state.rif40_selected) ? true : false}
                  }
                ],
              }
            ]
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                name: 'criteri_selezione_ammissione',
                type: 'title',
                element: 'h4',
                text: 'Criteri di selezione per l’ammissione al corso',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Requisiti di ammissione in termini di età',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.ageAdmission',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Titolo di studio',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.educationalQualification',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 8,
            items: [
              {
                type: 'input',
                label: 'Esperienza professionale',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.professionalExperience',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Competenze maturate',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.accruedSkills',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Posizione nella professione e modalità di accertamento del possesso degli stessi',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.professionalPosition',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },

        ]
      },
      {
        cols: [
          {
            items: [
              {
                name: 'dati_progetto',
                type: 'title',
                element: 'h4',
                text: 'Dati progetto',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Modalità con cui l’organismo intende accertare che i partecipanti siano effettivamente in Possesso del livello di competenza necessario per partecipare con successo all’attività formativa',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.prerequisitesVerification',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Modalità di selezione dei partecipanti in caso di numero soggetti idonei superiore al massimo previsto.',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.candidateSelection',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Altre attestazioni di cui si è previsto il rilascio e indicazione delle relative modalità',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.altreAttestazioni',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Presentazione dell’intervento proposto',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.presentazioneIntervento',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Obiettivi dell’offerta formativa',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.obiettiviOfferta',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Risultati attesi',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.risultatiAttesi',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Sbocchi occupazionali o eventuali possibilità di lavoro per i partecipanti al corso',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.sbocchiOccupazionali',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Analisi dei fabbisogni e caratteristiche del contesto nel quale si realizza il progetto',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.analisiFabbisogni',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'textarea',
                label: 'Tipologia utenti',
                type_string: 'text', // field type
                name: 'AfcSelectionCriteriaSection.tipologiaUtenti',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return component.props.generalData.AfcSelectionCriteriaSection ? true : false; }
                  }
                ],
              }
            ]
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit_dati_generali',
                text: 'Salva i dati',
                //onClick: 'showDrawer',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submit',
  }
};

export default class DatiGeneraliForm extends Component {

  constructor(props) {
    super(props);
    this.getBackGeneralData = this.getBackGeneralData.bind(this);
    eventBroker.on('getBackGeneralData', this.getBackGeneralData);
  }

  static propTypes = {
    domanda_json: PropTypes.object,
    generalData: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    loaded: false,
    loading: false,
    generalData: {},
    settori: [],
    qualifiche: [],
    ateco2007: [],
    istatCp2011:[],
    _aree_tematiche_corso_altro_selected: false,
    _settori_riferimento_formulario_selected: false,
    qualifiche_repertorio_nazionale: this.props.GlobalStore.stubs.qualifiche_repertorio_nazionale || [],
    profili_gestiti_da_normative_regionali_nazionali: this.props.GlobalStore.stubs.profili_gestiti_da_normative_regionali_nazionali || [],
    normativa_selected: false,
    rif40_selected: false,
    rif41_selected: false,
    rif_selected: false
  };

  componentDidMount() {
    this.load(this.props);

    Ateco2007Services.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'ateco2007_' + el.id,
            label: el.codice + ' - ' + el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ ateco2007: rows,})
    });

    IstatProfessioniServices.list({sort:'descrizione', pageSize: 0}, res => {
      let rows = res.data.data ?
        res.data.data.list.map(el => {
          return {
            key: 'istatCp2011_' + el.id,
            label: el.descrizione,
            value: el.id
          }
        })
        : [];

        this.setState({ istatCp2011: rows })
    })

    this.setState({ normativa_selected: this.props.generalData.AfcIndexSection.profilesManagedRegolamentation == "1" })
    this.setState({ rif40_selected: this.props.generalData.AfcLegalReferenceSection.article === 1 })
    this.setState({ rif41_selected: this.props.generalData.AfcLegalReferenceSection.article === 2 })
    this.setState({ _aree_tematiche_corso_altro_selected: this.props.generalData.AfcSectorSection.courseThematicArea === 16 })
    this.setState({ _settori_riferimento_formulario_selected: this.props.generalData.AfcSectorSection.sectorType === 27 })

  }

  load(props) {

    this.setState({ loading: true })
    let req = [];
    if (props.generalData.AfcMacroTypologySection?.macroTypology) {
      req.push(EnteService.settoriAccreditatiByMacrotipologia(props.generalData.AfcMacroTypologySection.macroTypology, this.props.id_ente))
    } else {
      req.push(Promise.resolve(null));
    }

    if (props.generalData.AfcSectorSection?.sectorType) {
      req.push(QualificaProfessionaliService.getBySettore(props.generalData.AfcSectorSection.sectorType))
    } else {
      req.push(Promise.resolve(null));
    }

    Promise.all(req).then(res => {

      let [res1, res2] = res;

      let settori = res1 && res1.data && res1.data.data || [];
      if (props.generalData.AfcSectorSection?.sectorType && !settori.find(el => el.value === props.generalData.AfcSectorSection?.sectorType)) {
        settori.push( this.props.GlobalStore.stubs.settori.find(el => el.value === props.generalData.AfcSectorSection?.sectorType))
      }

      this.setState(
        {
          settori: settori || [],
          qualifiche: res2.data.data || [],
          loading: false,
        }
      );
    },
    ).catch(() => {
      this.setState({ loading: false });
    });

    this.setState({
      loaded: true,
      loaded_source: true,
      generalData: props.generalData,
      teachingProgramming : props.teachingProgramming,
    });
  }

  getBackGeneralData() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  validationQualificaProfessionale() {
    let cert_type = this.formSelRef?.props.form?.getFieldValue('AfcExamSection.certificateType');
    return cert_type !== 1 && cert_type !== 2 && cert_type !== 3 && cert_type !== 14;

  }

  validationSectionRepertorio(){
    const title = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
    const nationalRepertoireQualification = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.nationalRepertoireQualification');
    const profilesManagedByRegulations = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.profilesManagedByRegulations');
    
    //almeno uno dei tre deve essere valorizzato
    return title===null && nationalRepertoireQualification===null && profilesManagedByRegulations===null ? true : false;
  }

  validationSectionRepertorioAteco(){
    const title = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
    const ateco2007 = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.ateco2007');
    
    //Se Qualifica professionale è selezioanto -> anche ateco deve esserlo
    return title!==null && ateco2007===null ? true : false;
  }

  validationSectionRepertorioIstatProfessioni(){
    const title = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
    const istatCp2011 = this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.istatCp2011');
    
    //Se Qualifica professionale è selezioanto -> anche istat professioni deve esserlo
    return title!==null && istatCp2011===null ? true : false;
  }

  validationFiguraProfessionale() {
    return this.formSelRef?.props.form?.getFieldValue('AfcIndexSection.title');
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  getValues() {
    let generalData = this.state.generalData;
    if (generalData.AfcCourseTimeSection) {
      generalData.AfcCourseTimeSection = formatter.replaceFormDatesIn(['startDate', 'endDate'], generalData.AfcCourseTimeSection)
    }
    return generalData
  }

  prepareData(data) {
    if (this.props.generalData.AfcCourseTimeSection) {
      let AfcCourseTimeSection = formatter.replaceFormDatesOut(['startDate', 'endDate'], data.AfcCourseTimeSection, 'YYYY-MM-DD');
      data.AfcCourseTimeSection = AfcCourseTimeSection;
    }
    return data;
  }

  totalOneriImpostiAgliAllievi() {
    let data = this.formSelRef.props.form.getFieldsValue()['AfcLearnerPaymentsSection']
    data['total'] = data['entryFee'] + data['courseCost'] + data['monthlyFee'] + data['otherPayment'];
    this.formSelRef.props.form.setFieldsValue({ AfcLearnerPaymentsSection: data });
  }

  submit(data) {
    let domanda = this.props.domanda_json;
    domanda.generalData = this.prepareData(data);
    if (this.state.decreto) {
      if (domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection?.entityPersonalData) {
        domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.entityPersonalData.decreto = this.state.decreto;
      }
      if (domanda.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList) {
        domanda.relatedSubjects.AfcProjectStaffSection.staffPersonalDataList = [];
        domanda.relatedSubjects.AfcProjectStaffSection.projectRepresentative = null;
        domanda.relatedSubjects.AfcProjectStaffSection.numberOfTeachers = 0;
      }

    }

    domanda.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList = this.props.methodList;
    /*this.setState({
   loading: true,
   loaded: false,
 });*/

    this.props.saveJson(domanda);

    /*ModelServices.save_json(domanda, this.props.match.params.id,
      res => {
        if (this.props.updateProgress) {
          this.props.updateProgress(res.data.data.progress);
          this.props.refresh(false);
          message.success('Dati salvati con successo');
        }
        this.setState({
          loading: false,
          loaded: true,
          generalData: res.data.data.domanda_json.generalData
        });
      },
      () => {
        this.setState({
          loading: false,
          loaded: true
        });
      }
    );*/
  }

  getSettoreByMacrotipologia(data) {
    let sectorSection = this.formSelRef.props.form.getFieldsValue()['AfcSectorSection'];
    if (sectorSection) {
      sectorSection.sectorType = null;
      this.formSelRef.props.form.setFieldsValue({ AfcSectorSection: sectorSection });
      this.setState({ 
        settori: this.props.GlobalStore.stubs.settori,
        loading: false
      });
      /*EnteService.settoriAccreditatiByMacrotipologia(data, this.props.id_ente, res => {
        this.setState({
          settori: res.data.data.length !== 0 ? res.data.data : this.props.GlobalStore.stubs.settori,
          loading: false,
        });
      }, () => this.setState({ loading: false }));*/
    }
  }

  /*selectedSettore(data) {
    this.formSelRef.props.form.setFieldsValue({
      AfcIndexSection: {
        title: null
      }
    });
    this.setState({ loading: true });

    let req = [
      AccreditamentoService.getBySettore(data),
      QualificaProfessionaliService.getBySettore(data)
    ];

    Promise.all(req).then(res => {
      let [res1, res2] = res;
      const decreto = res1.data.data;

      if(decreto !== -1){
        let req1 = [
          EnteService.professionalitaCoinvolte({}, this.props.id_ente, res1.data.data),
          EnteService.attrezzatureAccreditate({}, this.props.id_ente, res1.data.data),
          EnteService.audiovisiviAccreditati({}, this.props.id_ente, res1.data.data),
        ]
  
        Promise.all(req1).then(res2 => {
          let [res3, res4, res5] = res2
          this.props.resetRisorseUmane(res3.data.data, res4.data.data, res5.data.data)
          this.setState({ loading: false,});
        })
      }
      
      this.setState(
        {
          decreto: decreto,
          qualifiche: res2.data.data,
          loading: false
        },
        ()=> {this.props.resetPersonaleEnti(decreto);}
      );

    },
    ).catch(() => {
      this.setState({ loading: false });
    });

  }*/

  selectedSettoreRiferimentoFormulario(data) {
    const settori_riferimento_formulario = this.props.GlobalStore.stubs.settori_riferimento_formulario;
    const settori_riferimento_formulario_selected = settori_riferimento_formulario.find(el => el.value === data && el.key === 'settori_riferimento_formulario_00' );
    if(typeof settori_riferimento_formulario_selected !== 'undefined' ){
      this.setState({_settori_riferimento_formulario_selected: true});
    }else{
      this.setState({_settori_riferimento_formulario_selected: false});
    }

  }

  selectedAreaTematicaCorso(data) {
    const aree_tematiche_corso = this.props.GlobalStore.stubs.aree_tematiche_corso
    const areaTEmaticaselected = aree_tematiche_corso.find(el => el.value === data && el.key === 'aree_tematiche_corso_00' );
    if(typeof areaTEmaticaselected !== 'undefined' ){
      this.setState({_aree_tematiche_corso_altro_selected: true});
    } else {
      this.setState({_aree_tematiche_corso_altro_selected: false});
    }
  }


  selectedAtecoCp(data) {
    const ateco = this.state.ateco2007;
    const istat = this.state.istatCp2011;
    const figuraSelected = this.formSelRef.props._figure_professionali.find(el => el.value == data);
    const a = JSON.parse(figuraSelected.ateco).map((obj) => {return "ateco2007_"+obj;});
    const i = JSON.parse(figuraSelected.cp_istat).map((obj) => {return "istatCp2011_"+obj;});
    const atecoSelected = ateco.filter(el => { return  a.includes(el.key) })
    const istatSelected = istat.filter(el => { return  i.includes(el.key) })
    const form = this.formSelRef.props.form.getFieldsValue()['AfcIndexSection'];
    let atecoDefaultValue = [];
    let istatDefaultValue = [];
    atecoSelected.map(row=>{atecoDefaultValue.push(''+row.value+'')});
    istatSelected.map(row=>{istatDefaultValue.push(''+row.value+'')});
    form['ateco2007'] = atecoDefaultValue;
    form['istatCp2011'] = istatDefaultValue;
    this.formSelRef.props.form.setFieldsValue({ AfcIndexSection: form })
  
  }
  


  render() {
    return <div style={{ opacity: !this.props.saved || this.state.loading ? 0.5 : 1 }}>
      {
        !this.props.saved ? <Spin /> : null
      }
      { checkPermission.hasPermission(this.props.UserStore.user, ['viewValutazioneScheda']) &&
        <ValidazioneScheda
          canGiveFeedback={this.props.canGiveSectionFeedback && this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
          loadingFeedback={this.props.loadingFeedback}
          saveFeedback={(note, valid) => this.props.saveFeedback(note, valid, 'generalData')}
          feedback={this.props.section_feedback ? this.props.section_feedback['generalData'] : null}
        />
      }
      { checkPermission.hasPermission(this.props.UserStore.user, ['docentePermissions'])  &&
       <Button
          type="primary"
          icon="plus"
          disabled={!this.props.readOnly}
          onClick={()=>this.setState({open_drawer: true})}>
          Genera Qr docente
        </Button>
        }
        <Drawer
          title="Genera Qr docente"
          placement="right"
          closable={true}
          onClose={() => this.setState({ open_drawer: false })}
          visible={this.state.open_drawer}
          width={600}
          destroyOnClose={true}
        >
          <QrIscrizione
          registration_id={this.props.id_progetto}
          url="affidamento/creazioneqr/qriscrizionedocente/"
          
          />
        </Drawer>

      <CommonForm
        loading={this.props.saving || this.state.loading || false}
        wrappedComponentRef={inst => (this.formSelRef = inst)}
        form_model={formLayout(this)}
        values={this.getValues()}
        readOnly={this.props.readOnly}
        selectedMacrotipologia={data => {
          this.getSettoreByMacrotipologia(data);
          this.props.loadProvinceSediAccreditate(data)
        }}
        //selectedSettore={data => this.selectedSettore(data)}
        selectedAreaTematicaCorso={data => this.selectedAreaTematicaCorso(data)}
        selectedSettoreRiferimentoFormulario={data => this.selectedSettoreRiferimentoFormulario(data)}
        selectedAtecoCp={data => this.selectedAtecoCp(data)}
        _macrotipologie={this.props.macrotipologie}
        _modalita_formativa={this.props.GlobalStore.stubs.modalita_formativa}
        _contenuto_formativo={this.props.GlobalStore.stubs.contenuto_formativo}
        _modalita_svolgimento={this.props.GlobalStore.stubs.modalita_svolgimento}
        _livello_offerta_formativa={this.props.GlobalStore.stubs.livello_offerta_formativa}
        _tipologia_attivita={this.props.GlobalStore.stubs.tipologia_attivita}
        _attestazione_finale={this.props.GlobalStore.stubs.attestazione_finale}
        _tipo_funzione={this.props.GlobalStore.stubs.tipo_funzioni}
        _figura_professionale={this.props.GlobalStore.stubs.tipo_funzioni}  //TODO
        _settori={this.state.settori}
        _settori_riferimento_formulario={this.props.GlobalStore.stubs.settori_riferimento_formulario}
        _settori_riferimento_formulario_selected={this.state._settori_riferimento_formulario_selected}
        //_qualifiche={this.state.qualifiche}
        //_figure_professionali={this.props.GlobalStore.stubs.figure_professionali}
        _figure_professionali={(this.props.GlobalStore.stubs.figure_professionali_rep || []).filter( entry => entry.ateco)}
        _qualifiche={this.state.qualifiche}
        _qualifiche_repertorio_nazionale={this.state.qualifiche_repertorio_nazionale}
        _ateco2007={this.state.ateco2007}
        _istatCp2011={this.state.istatCp2011}
        _aree_tematiche_corso={this.props.GlobalStore.stubs.aree_tematiche_corso}
        _aree_tematiche_corso_altro_selected={this.state._aree_tematiche_corso_altro_selected}
        _profili_gestiti_da_normative_regionali_nazionali={this.state.profili_gestiti_da_normative_regionali_nazionali}
        submit={(data) => this.submit(data)}
        setDescrizineNormativa={(key, e)=>this.setState({normativa_selected: e.target.value == "1" ? true : false })}
        changedRiferimentiLegge={(key, e)=> {if(parseInt(e.target.value) === 1) {this.setState({rif40_selected: true, rif41_selected: false})} else if (parseInt(e.target.value) === 2) {this.setState({rif40_selected: false, rif41_selected: true})} }}

        min_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let min = value;

          if (min < moment()) cb("La data di avvio deve essere maggiore della data odierna");
          let max = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['endDate'];

          if (max && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
          else cb()
        }}
        max_date={(rule, value, cb) => {
          if (!value || value.length === 0) cb();
          let max = value;

          let min = this.formSelRef.props.form.getFieldsValue()['AfcCourseTimeSection']['startDate'];

          if (min && min > max) cb("La data di avvio deve essere minore della data di conclusione del corso");
          else cb()
        }}
      />

    </div>
  }
}