import { BackTop, Col, Layout, Menu, Row } from "antd";
import React from "react";
import LogoPor from "../../assets/logo-por.png";
import LogoRegione from "../../assets/logo-regione.png";
import ManualeUtente from "../../assets/manuale.pdf";
import "../less/Footer.less";

export default class Footer extends React.Component {
  state = {
    request_in_progress: false,
    mounted: false,
  };

  componentDidMount() {
    // this.mounted = true
    // LoadingServicesUtils((r) => this.handleRequestStatusChange(r, this));
  }

  componentWillUnmount() {
    //this.mounted = false
  }

  onMenuClick(info) {
    let path = "";
    switch (info.key) {
      case "login":
        path = "/login";
        break;
      case "dashboard":
        path = "/dashboard";
        break;
      case "contatti":
        path = "/contatti";
        break;
      case "documenti_utili":
        path = "/documenti-utili";
        break;
      case "privacy":
        path = "/privacy";
        break;
      case "note_legali":
        path = "/note-legali";
        break;
      default:
        break;
    }

    if (this.props.match.path !== path) this.props.history.push(path);
  }

  render() {
    return (
      <Layout.Footer className="footer">
        <div>
          <Row className="m20h">
            <Col
              lg={2}
              md={3}
              xs={8}
              style={{ borderRight: "1px solid #fff", textAlign: "center" }}
            >
              <img
                src={LogoRegione}
                width={60}
                alt="SiSfo - Regione Calabria"
              />
            </Col>
            <Col lg={8} md={8} xs={16} style={{ paddingLeft: "20px" }}>
              <h3>Regione Calabria</h3>
              <h4>SISTEMA INFORMATIVO DI SUPPORTO ALLA FORMAZIONE</h4>
            </Col>
            <Col lg={14} md={14} xs={24} className="logoPor">
              <img src={LogoPor} alt="SiSfo - Regione Calabria" />
            </Col>
          </Row>
          <Row style={{ borderTop: "1px solid #fff" }}>
            <Col lg={6} md={6} xs={24} className="p10h copy">
              Tutti i diritti riservati &copy; 2019
            </Col>
            <Col lg={18} md={18} xs={24} style={{ textAlign: "right" }}>
              <Menu
                onClick={(info) => this.onMenuClick(info)}
                selectedKeys={[this.state.current]}
                mode="horizontal"
              >
                {/* {
                this.props.UserStore.loggedIn ?
                <Menu.Item key="dashboard">
                  <Icon type="lock" />Dashboard
                </Menu.Item> :
                <Menu.Item key="login">
                  <Icon type="lock" />Login
                </Menu.Item>
              } */}
                <Menu.Item key="manuale">
                  <a target={"_blank"} href={ManualeUtente}>
                    <font color="#65dcdf">Manuale</font>
                  </a>
                </Menu.Item>
                <Menu.Item key="contatti">Contatti</Menu.Item>
                <Menu.Item key="documenti_utili">Documenti Utili</Menu.Item>
                <Menu.Item key="note_legali">Note Legali</Menu.Item>
                <Menu.Item key="privacy">Privacy Policy</Menu.Item>
              </Menu>
              <BackTop
                style={{ bottom: "22px", right: "20px" }}
                visibilityHeight={800}
              />
            </Col>
          </Row>
        </div>
      </Layout.Footer>
    );
  }
}
