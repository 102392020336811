import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import DatiRappresentante from './DatiRappresentante';
import DatiIndirizzo from './DatiIndirizzo';
import { Icon, Tooltip } from 'antd';

const formLayout = (component) => {
    /*console.warn("DatiEnteSingolo", component.props.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.entityPersonalData)
    let decretiPerSede = []
    component.props.domanda.relatedSubjects.AfcSingleEntityPartnerLeaderSection.entityPersonalData.enteSede.map(el => {
        if(el.decreto !== null && decretiPerSede.indexOf(el.decreto) === -1){
            decretiPerSede.push(el.decreto);
        }
    });
    console.warn("decretiPerSede",decretiPerSede);
    const decretiString = decretiPerSede.join(',');*/
    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ente_singolo_capofila',
                                type: 'title',
                                element: 'h4',
                                text: 'Dati anagrafici dell\'ente',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                },
                            }
                        ],
                    }
                ],
            },

            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'Denominazione',
                                type_string: 'text', // field type
                                name: 'entityPersonalData.denominazione',
                                props: {
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                    placeholder: 'Denominazione'
                                },
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio'
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'Codice fiscale',
                                type_string: 'text', // field type
                                name: 'entityPersonalData.cfente',
                                props: {
                                    disabled: component.props.enteSisfo || component.props.readOnly || (!component.props.enteSisfo && component.state.data.entityPersonalData.cfente),
                                    placeholder: 'Codice fiscale'
                                },
                                validations: [
                                    {
                                        pattern: '^[0-9]{11,11}$',
                                        message: 'Codice fiscale non valido',
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'P. Iva',
                                type_string: 'text', // field type
                                name: 'entityPersonalData.partita_iva',
                                props: {
                                    placeholder: 'P. Iva',
                                    disabled: component.props.enteSisfo || component.props.readOnly || (!component.props.enteSisfo && component.state.data.entityPersonalData.partita_iva),
                                },
                                validations: [
                                    {
                                        pattern: '^[0-9]{11,11}$',
                                        message: 'Partita iva non valida',
                                    },
                                ],
                            }
                        ]
                    }
                ]
            },



            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: 'Natura giuridica',
                                type_string: 'text', // field type
                                name: 'entityPersonalData.formaGiuridica.descrizione',
                                props: {
                                    placeholder: 'Natura giuridica',
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                },
                            }
                        ]
                    },
                    {
                        span: 12,
                        items: [
                            {
                                type: 'integer',
                                label: <>Riferimento decreto di accreditamento <Tooltip placement="top" title="Riportare gli estremi dell’atto di accreditamento per la Macro-tipologia a cui il progetto afferisce."><Icon type="info-circle" /></Tooltip></>,
                                name: 'entityPersonalData.numero_decreto_accreditamento',
                                props: {
                                    placeholder: 'Riferimento decreto di accreditamento',
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                    min: 0
                                },
                            }
                        ]
                    },

                ]
            },

            {
                cols: [
                    {
                        items: [
                            {
                                name: 'partner_sede_legale',
                                type: 'title',
                                element: 'h4',
                                text: 'Sede Legale',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                },
                            }
                        ],
                    }
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ente_referente_progetto_dati',
                                type: 'child',
                                child: <div style={{ marginBottom: 16 }}>
                                    <React.Fragment>
                                        <div
                                            ref={(ref) => component.dati_indirizzo_sede_legale_container = ref}
                                        >
                                            <DatiIndirizzo
                                                {...component.props}
                                                readOnly={component.props.enteSisfo || component.props.readOnly}
                                                dati={component.state.data && component.state.data.entityPersonalData && component.state.data.entityPersonalData.sedeLegale ? component.state.data.entityPersonalData.sedeLegale.indirizzo : {}}
                                                ref={(ref) => component.dati_indirizzo_sede_legale = ref}
                                                subform={true}
                                            ></DatiIndirizzo>
                                        </div>
                                    </React.Fragment>
                                </div>
                            }
                        ],
                    },
                ],
            },

            {
                cols: [
                    {
                        items: [
                            {
                                name: 'rappresentante_legale',
                                type: 'title',
                                element: 'h4',
                                text: 'Rappresentante legale',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                },
                            }
                        ],
                    }
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ente_rappresentante_legale_dati',
                                type: 'child',
                                child: <div style={{ marginBottom: 16 }}>
                                    <React.Fragment>
                                        <div
                                            ref={(ref) => component.dati_rappr_legale_container = ref}
                                        >
                                            <DatiRappresentante
                                                {...component.props}
                                                readOnly={component.props.readOnly || component.props.enteSisfo}
                                                dati={component.state.data ? component.state.data.legalRepresentativePersonalData : {}}
                                                ref={(ref) => component.dati_rappr_legale = ref}
                                                subform={true}
                                            ></DatiRappresentante>
                                        </div>
                                    </React.Fragment>
                                </div>
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'rappresentante_legale_indirizzo',
                                type: 'title',
                                element: 'h4',
                                text: 'Residenza del rappresentante legale',
                                props: component.props.enteSisfo || component.props.readOnly ? {
                                    style: { textAlign: 'left', display: 'none' },
                                    className: 'm10h'
                                } : 
                                {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                }
                            }
                        ],
                    }
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'rappresentante_legale_indirizzo_dati',
                                type: 'child',
                                child:  component.props.enteSisfo || component.props.readOnly ? <div style={{ marginBottom: 16, display: 'none' }}>
                                    <React.Fragment>
                                        <div
                                            ref={(ref) => component.dati_indirizzo_rappr_legale_container = ref}
                                        >
                                            <DatiIndirizzo
                                                {...component.props}
                                                readOnly={component.props.enteSisfo || component.props.readOnly}
                                                dati={component.state.data && component.state.data.legalRepresentativePersonalData ? component.state.data.legalRepresentativePersonalData.residenza : {}}
                                                ref={(ref) => component.dati_indirizzo_rappr_legale = ref}
                                                subform={true}
                                            ></DatiIndirizzo>
                                        </div>
                                    </React.Fragment>
                                </div> : <div style={{ marginBottom: 16 }}>
                                    <React.Fragment>
                                        <div
                                            ref={(ref) => component.dati_indirizzo_rappr_legale_container = ref}
                                        >
                                            <DatiIndirizzo
                                                {...component.props}
                                                readOnly={component.props.enteSisfo || component.props.readOnly}
                                                dati={component.state.data && component.state.data.legalRepresentativePersonalData ? component.state.data.legalRepresentativePersonalData.residenza : {}}
                                                ref={(ref) => component.dati_indirizzo_rappr_legale = ref}
                                                subform={true}
                                            ></DatiIndirizzo>
                                        </div>
                                    </React.Fragment>
                                </div>
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ente_contatti',
                                type: 'title',
                                element: 'h4',
                                text: 'Contatti sede',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                },
                            }
                        ],
                    }
                ],
            },

            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Telefono',
                                type_string: 'text', // field type
                                name: 'contacts.telefono',
                                props: {
                                    placeholder: 'Telefono',
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                },
                                validations: [
                                    {
                                        pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                        message: 'Telefono non valido'
                                    },
                                ],
                            }
                        ]
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'E-Mail',
                                type_string: 'text', // field type
                                name: 'contacts.email',
                                props: {
                                    placeholder: 'E-Mail',
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                },
                                validations: [
                                    {
                                        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
                                        message: 'E-mail non valida'
                                    },
                                ]


                            }
                        ]
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Pec',
                                type_string: 'text', // field type
                                name: 'contacts.pec',
                                props: {
                                    placeholder: 'Pec',
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                },
                                validations: [
                                    {
                                        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
                                        message: 'E-mail non valida'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        span: 6,
                        items: [
                            {
                                type: 'input',
                                label: 'Fax',
                                type_string: 'text', // field type
                                name: 'contacts.fax',
                                props: {
                                    placeholder: 'Fax',
                                    disabled: component.props.enteSisfo || component.props.readOnly,
                                },
                                validations: [
                                    {
                                        pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                        message: 'Fax non valido'
                                    }
                                ],
                            }
                        ]
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'referente_progetto',
                                type: 'title',
                                element: 'h4',
                                text: 'Referente del progetto',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                }
                            }
                        ],
                    }
                ],
            },

            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'select',
                                label: 'Seleziona un referente dalla lista del personale',
                                name: 'projectRepresentativePersonalData.id',
                                options_props: 'referenti_progetto',
                                onChange: 'setReferente',
                                props: {
                                    disabled: component.props.readOnly,
                                    showSearch: true,
                                    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                },
                                showIf: [
                                    {
                                        type: 'custom_func',
                                        func: () => { return component.state.data?.referenti ? true : false; }
                                    },
                                ]
                            }
                        ],
                    }
                ],
            },

            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ente_referente_progetto_dati',
                                type: 'child',
                                child:
                                <div style={{ marginBottom: 16 }}>
                                <React.Fragment>
                                    <div
                                        ref={(ref) => component.dati_rappr_progetto_container = ref}
                                    >
                                        <DatiRappresentante
                                            {...component.props}
                                            readOnly={component.props.enteSisfo || component.props.readOnly}
                                            dati={component.state.data?.projectRepresentativePersonalData}
                                            ref={(ref) => component.dati_rappr_progetto = ref}
                                            subform={true}
                                        ></DatiRappresentante>
                                    </div>
                                </React.Fragment>
                            </div>
                                
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'Telefono',
                                type_string: 'text', // field type
                                name: 'projectRepresentativePersonalData.contatti.telefono',
                                props: {
                                    disabled: component.props.readOnly,
                                    placeholder: 'Tel'
                                },
                                validations: [
                                    {
                                        pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                        message: 'Telefono non valido'
                                    }
                                ],
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'Cellulare',
                                type_string: 'text', // field type
                                name: 'projectRepresentativePersonalData.contatti.cellulare',
                                props: {
                                    placeholder: 'Cel',
                                    disabled: component.props.readOnly
                                },
                                validations: [
                                    {
                                        pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                                        message: 'Telefono non valido'
                                    }
                                ],
                            }
                        ]
                    },
                    {
                        span: 8,
                        items: [
                            {
                                type: 'input',
                                label: 'E-mail',
                                type_string: 'text', // field type
                                name: 'projectRepresentativePersonalData.contatti.pec',
                                props: {
                                    disabled: component.props.readOnly,
                                    placeholder: 'Email'
                                },
                                validations: [
                                    {
                                        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
                                        message: 'E-mail non valida'
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ente_referente_progetto_dati',
                                type: 'child',
                                child: component.props.enteSisfo || component.props.readOnly ? <div style={{ marginBottom: 16, display: 'none' }}>
                                    <React.Fragment>
                                        <div
                                            ref={(ref) => component.dati_indirizzo_referente_container = ref}
                                        >
                                            <DatiIndirizzo
                                                {...component.props}
                                                readOnly={component.props.enteSisfo || component.props.readOnly}
                                                dati={component.state.data && component.state.data.projectRepresentativePersonalData ? component.state.data.projectRepresentativePersonalData.residenza : {}}
                                                ref={(ref) => component.dati_indirizzo_referente = ref}
                                                subform={true}
                                            ></DatiIndirizzo>
                                        </div>
                                    </React.Fragment>
                                </div> : <div style={{ marginBottom: 16 }}>
                                    <React.Fragment>
                                        <div
                                            ref={(ref) => component.dati_indirizzo_referente_container = ref}
                                        >
                                            <DatiIndirizzo
                                                {...component.props}
                                                readOnly={component.props.enteSisfo || component.props.readOnly}
                                                dati={component.state.data && component.state.data.projectRepresentativePersonalData ? component.state.data.projectRepresentativePersonalData.residenza : {}}
                                                ref={(ref) => component.dati_indirizzo_referente = ref}
                                                subform={true}
                                            ></DatiIndirizzo>
                                        </div>
                                    </React.Fragment>
                                </div>
                            }
                        ],
                    },
                ],
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'ruolo_esperienze',
                                type: 'title',
                                element: 'h4',
                                text: 'Ruolo ed esperienze del soggetto',
                                props: {
                                    style: { textAlign: 'left' },
                                    className: 'm10h'
                                },
                                showIf: [
                                    {
                                      type: 'custom_func',
                                      func: () => { return component.props.progetto.discriminante != 'EDIZIONE' ? true : false; }
                                    },
                                  ]
                            }
                        ],
                    }
                ],
            },
            {
                cols: [
                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: component.props.capofila ? 'Ruolo e attività del capofila nel progetto' : 'Ruolo e attività nel progetto',
                                type_string: 'text', // field type
                                name: 'role',
                                props: {
                                    placeholder: 'Ruolo e attività nel progetto',
                                    disabled: component.props.readOnly,
                                },
                                validations: [
                                    {
                                        type: component.props.capofila ? 'required' : '',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                showIf: [
                                    {
                                      type: 'custom_func',
                                      func: () => { return component.props.progetto.discriminante != 'EDIZIONE' ? true : false; }
                                    },
                                  ]

                            }
                        ]
                    },

                    {
                        span: 12,
                        items: [
                            {
                                type: 'input',
                                label: <>Esperienze nel campo della formazione professionale
                                    {component.props.showNote &&
                                        <Tooltip placement="top" title="I dati e le informazioni inserite nel presente paragrafo saranno utilizzati per valutare la coerenza interna del progetto formativo (area di valutazione G, criterio G.1)."><Icon className="m5w" type="info-circle" /></Tooltip>
                                    }
                                </>,
                                type_string: 'text', // field type
                                name: 'experiences',
                                props: {
                                    placeholder: 'Esperienze nel campo della formazione professionale',
                                    disabled: component.props.readOnly,
                                },
                                validations: [
                                    {
                                        type: component.props.capofila ? 'required' : '',
                                        error_message: 'Campo obbligatorio',
                                    },
                                ],
                                showIf: [
                                    {
                                      type: 'custom_func',
                                      func: () => { return component.props.progetto.discriminante != 'EDIZIONE' ? true : false; }
                                    },
                                  ]
                            }
                        ]
                    },
                ]
            },


            {
                cols: [
                    {
                        span: 24,
                        items: [
                            {
                                type: 'input',
                                label: 'Valore aggiunto apportato al progetto dal soggetto partner',
                                disabled: component.props.readOnly,
                                type_string: 'text',
                                name: 'plusValue',
                                props: {
                                    placeholder: 'Valore aggiunto apportato al progetto dal soggetto partner',
                                    disabled: component.props.readOnly,
                                },
                                showIf: [
                                    {
                                        type: 'custom_func',
                                        func: () => { return component.props.capofila ? false : true; }
                                    },
                                ]

                            }
                        ]
                    },
                ]
            },
        ]
    }
}


class DatiEnteSingolo extends Component {

    state = {
        loaded: false,
        loading: false,
        comuni: [],
    }

    componentDidMount() {

        this.setState({
            data: {...this.state.data, ...this.props.data},
            loaded: true,
        });
    }


    getReferentiProgetto() {
        if (this.state.data?.referenti) {
            return this.state.data.referenti.map(e => {
                return {
                    key: e.id,
                    label: e.nome + ' ' + e.cognome,
                    value: e.id
                }
            });
        } else {
            return [];
        }

    }    
    

        


    setReferenteProgetto(value) {
        let referente = this.state.data.referenti?.find(function (element) {
            return element.id === value;
        });

        let data = this.state.data;
        data.projectRepresentativePersonalData = {
            ...referente,
            contatti: referente.contatti?.length > 0 ? referente.contatti[0] : null
        }
        this.setState({
            data: data
        });
    }

    render() {
        return this.state.loaded && <div style={{ opacity: this.state.loading ? 0.5 : 1 }}>
            {
                <CommonForm
                    wrappedComponentRef={inst => (this.formRef = inst)}
                    loading={this.state.loading || false}
                    form_model={formLayout(this)}
                    values={this.state.data}
                    referenti_progetto={this.getReferentiProgetto()}
                    setReferente={(value) => this.setReferenteProgetto(value)}
                    subform={this.props.subform}
                />
            }
        </div>
    }

}

export default DatiEnteSingolo