import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';



import ModelServices from '@affidamento/services/Corsi';

import SpinnerLoading from '@components/SpinnerLoading';

import eventBroker from '@common/utils/services/EventBroker';
import DettaglioCorso from '../components/Calendario/DettaglioCorso';

@Wrapper({ route: 'RegistroElettronico', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewProgetto', 'adminPermissions'])
class DettaglioCorsoRoute extends Component {

  state = {
    loading: false,
    data: null,
  }

  constructor(props) {
    super(props);
    eventBroker.on('reloadCorso', data => {
      this.setState(
        {
          data: data,
        }
      );
    });
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    this.setState({ loading: true },
        ()=>{
            ModelServices.detail({expand: 'countIscritti,countDocenti,countLezioni,ultimaIspezione,oreLezioniTotali,oreLezioniErogate' }, this.props.match.params.id, res=>{
                this.setState(
                    {
                      data: res.data.data,
                      loading: false
                    }
                  );
            }, ()=>this.setState({ loading: false }));
        });
  }

  renderTitle() {
    return this.state.data?.titolo_progetto || '#Titolo non valido#';
  }

  render() {

    return (

      <div className="scheda">
        {this.state.loading ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
              <p>Dettaglio corso</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/calendario">Calendario corsi</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <DettaglioCorso {...this.props} corso={this.state.data}  />
          </>
        }
      </div>
    );
  }
}

export default DettaglioCorsoRoute;
