import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'antd';

import ModelServices from '@accreditamento/services/Esito';

import FileSaver from 'file-saver';
import accreditamentoStatic from '@common/utils/static/accreditamento';
import CommonForm from '@common/components/form';
import DocSpan from '@common/components/DocSpan';
import checkPermission from '@common/utils/check/permissions';
import formatter from '@common/utils/formatters/_';
import moment from 'moment';


const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              name: 'titolo_profilo',
              type: 'text',
              text: 'Carica il decreto firmato digitalmente',
              props: {
                style: { textAlign: 'left' }
              },
            },
          ],
        },
      ],
    },
  ],
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Numero decreto',
              type_string: 'text',
              name: 'numero_decreto_dirigente',
              props: {
                size: 'large',
                style: { width: '30%' },
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
            {
              type: 'datepicker',
              label: 'Data decreto',
              name: 'data_decreto_dirigente',
              props: {
                style: { width: '30%' },
              },
              validations:  [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ] 
            },
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Seleziona il file",
              props: {
                showUploadList: false
              }
            },
            {
              type: 'submit',
              name: 'btn_submit_decreto',
              text: 'Conferma',
              props: {
                  icon: 'save',
                  className: 'bg-green'
              }
          }
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};
class DecretoAccreditamento extends Component {



  state = {
    loaded: false,
    accreditamento: {
      id: null
    },

    loading: false,
    confirming: false,
    downloading: false,
    uploading: false,
    sending: false,

    fileList: [],
    note: '',
    open_modal_doc: false
  }

  componentDidMount() {
    if (this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.accreditamento.id &&
      newProps.accreditamento !== this.props.accreditamento) this.loaded(newProps)
  }


  loaded(props) {
    this.setState({ accreditamento: props.accreditamento, loaded: true});
  }

  saveBlob(blob) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, "decreto.pdf");

    //this.props.refresh();
  }


  saveBlobWord(blob, nameFile) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, nameFile);
  }


  isEnte() {
    return (this.props.accreditamento.id_user === this.props.UserStore.user.id) ? true : false;
  }


  upload(file) {
    if(!this.state.loading) {

      this.setState({
        loading: true
      }, () => {
        ModelServices.upload_decreto(this.props.accreditamento.id, {
          filename: 'file'
        }, { file: file, name: file.name, chiave: 'file' }, (res) => {

          this.props.refreshInline(res.data.data)
          this.setState({ open_modal_doc: false, loading: false });

        }, () => this.setState({ open_modal_doc: false, loading: false }))
      })

    }
  }

  submit(data) {
    if (!this.state.loading) {
        this.setState({ loading: false }, () => {
          data = this.getFormData(data);
          ModelServices.confirm_decreto(this.props.accreditamento.id, data, null,(res) => {
            this.props.refreshInline(res.data.data);
            this.setState({ loading: false });
        }, (err) => {
          this.setState({ loading: false })})
        })
    }
  }

  getFormData(data) {
    return formatter.replaceFormDatesOut(['data_decreto_dirigente'], data);
}

  renderUploadButton() {
    return <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={{
            }}
            fileListProp={() => []}
            customUploadRequest={obj => {
              this.upload(obj.file);
            }}
            submitted={(data) => this.submit(data)}
          />
  }

  renderDownloadUploadDecretoDirigente() {
    return <React.Fragment> 
           { (this.props.accreditamento.tipo !== accreditamentoStatic.tipo.riconoscimento_ente && this.props.accreditamento.tipo !== accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo && this.props.accreditamento.tipo !== accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio) ? <Button disabled={this.state.loading} loading={this.state.downloading} className="m5h" type="secondary" icon="file-word" onClick={() => {
        if(!this.state.loading) {
          this.setState({
            loading: true,
            downloading: true
          }, () => {
            ModelServices.download_decreto_word(
              this.props.accreditamento.id,
              (res) => {
                this.saveBlobWord(res.data, 'decreto.odt')
                this.setState({
                  loading: false,
                  downloading: false
                })
              },
              () => this.setState({
                  loading: false,
                  downloading: false
                })
            )
          })
        }
      }}>Scarica decreto di accreditamento Odt</Button> :
      <Button disabled={this.state.loading} loading={this.state.downloading} className="m5h" type="secondary" icon="file-word" onClick={() => {
        if(!this.state.loading) {
          this.setState({
            loading: true,
            downloading: true
          }, () => {
            ModelServices.download_accoglimento_word(
              this.props.accreditamento.id,
              (res) => {
                this.saveBlobWord(res.data, 'accoglimento.odt')
                this.setState({
                  loading: false,
                  downloading: false
                })
              },
              () => this.setState({
                  loading: false,
                  downloading: false
                })
            )
          })
        }
      }}>Scarica comunicazione di accoglimento Odt</Button>
      }
        {this.renderUploadButton()}
       </React.Fragment>
  }

  renderDownloadUploadDecretoIstruttore() {
    return this.renderUploadButton();
  }

  renderDecreto() {
    let d = this.props.accreditamento.media.find(m => m.tipo.sottodominio === 'decreto');
    return (d) ? <div>
      <p>{this.props.accreditamento.tipo === accreditamentoStatic.tipo.riconoscimento_ente ? 'Comunicazione di accoglimento' : 'Decreto di accreditamento: '}<DocSpan key={d.id} file={d} /></p>
      <p>Numero: {this.props.accreditamento.numero_decreto_accreditamento}</p>
      <p>Data: {moment(this.props.accreditamento.data_decreto_dirigente, 'YYYY-MM-DD').format('DD/MM/YYYY')} </p>
    </div> : null
  }

  renderDecretoPregresso() {
    if (this.props.accreditamento.tipo ===  accreditamentoStatic.tipo.riconoscimento_pregresso_definitivo
      || this.props.accreditamento.tipo ===  accreditamentoStatic.tipo.riconoscimento_pregresso_provvisorio ){

        let sedi = this.props.accreditamento.sedi.filter(s => s.numero_protocollo !== null);

        if(sedi && sedi.length > 0){
          return(
            <>{
              sedi.map(
                (s) => (
                  <p>
                    Protocollo accreditamento pregresso #{s.numero_protocollo}
                    <br/>
                    Sede: {s.indirizzo.full_address}
                    <br/>
                    { s.media && <DocSpan key={s.media.find(m => m.tipo.sottodominio === 'decreto_pregresso').id} file={s.media.find(m => m.tipo.sottodominio === 'decreto_pregresso')} /> }
                  </p>
                )
              )
            }</>
          );
        }
    } else {
      return null;
    }

  }

  render() {
    return this.props.accreditamento && this.props.accreditamento.id ?
      <div>
        {this.renderDecretoPregresso()}
        {this.renderDecreto()}
        {checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions']) && this.props.accreditamento.stato_flusso === 14 ? this.renderDownloadUploadDecretoDirigente() : null}
        {checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) && this.props.accreditamento.stato_flusso === 22 ? this.renderDownloadUploadDecretoIstruttore() : null}
      </div>
      : null
  }
}


export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(DecretoAccreditamento);