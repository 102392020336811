import React, { Component } from 'react';
import { connect } from "react-redux";

import { Row, Col } from 'antd';

import moment from 'moment';

import nominaStatic from '@common/utils/static/nomina';
import DocSpan from '@common/components/DocSpan';
import DownloadUpload from '@common/components/DownloadUpload';

const getStato = (record, component) => {
  return record.stato_flusso_formatted['stato_'+component.props.stato]
}
class IspezioneNomina extends Component {

  state = {

  }

  canUpload(n) {
    return this.props.UserStore.user.id === n.id_user && (
      n.stato === nominaStatic.stato.nomina_ispettore ||
      n.stato === nominaStatic.stato.download_dirigente
    )
  }

  render() {
    return <div>
      <Row>
        <Col span={24}>
        <h4>{this.props.title}</h4>
        </Col>
        {this.props.nomine && this.props.nomine.map(n => {
          return <Col span={12}>
            <p>Stato: <strong>{getStato( n, this)}</strong><br />
              {n.ispettore.username} - {n.ispettore.anagrafica.nome} {n.ispettore.anagrafica.cognome}<br />
              {(n.data_assegnazione) ? moment(n.data_assegnazione, 'YYYY-MM-DD').format('DD/MM/YYYY') + " (Data assegnazione)" : ''}<br />
              {n.note_ispettore && n.note_ispettore !== '' ? <span><strong>Note:</strong> {n.note_ispettore}</span> : ''}
            </p>
            {
              n.media.filter(el => el.tipo.sottodominio === nominaStatic.tipo_media.sottodominio_feedback_ispettore).map(l => {
                return <div key={l.id}><p style={{ display: 'inline-block' }}>Documento firmato dall&apos;ispettore: </p><DocSpan key={l.id} file={l} /></div>
              })
            }
            
            {this.canUpload(n) &&
              <div>
                <p>Scarica la nomina e carica il file firmato digitalmente</p>
                <DownloadUpload
                  accept={'.p7m,.pdf'}
                  downloading={this.props.downloadingNomina}
                  uploading={this.props.uploadingNomina}
                  download={() => this.props.downloadNomina(n)}
                  upload={(file) => this.props.uploadNomina(file,n)}
                >
                </DownloadUpload></div>
            }
          </Col>
        })}
      </Row>
    </div>
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(IspezioneNomina);