import React, { Component } from 'react';

import { message, Checkbox, InputNumber } from 'antd';

import formatter from '@common/utils/formatters/_';


class Macrotipologie extends Component {

  state = {
    loaded: true,
    selected_tipologia: [],
    selected_settore: []
  }



  componentDidMount() {
    this.setState({
      sede: this.props.sede,
      selected_tipologia: (this.props.sede.tipoAccreditamento) ? this.props.sede.tipoAccreditamento : [],
      selected_settore: (this.props.sede.settore) ? this.props.sede.settore : []
    })

  }

  isSelectedMacrotipologia(id, id_tipologia) {
    return this.state.selected_tipologia.find(el =>
        el.id_con_tipo_accreditamento === id &&
        el.id_tipologia === id_tipologia
    ) ? true : false
  }

  isNotOrientamento(id, id_tipologia) {
    return this.state.selected_tipologia.find(el =>
        el.id_con_tipo_accreditamento === id &&
        el.id_tipologia === id_tipologia && 
        el.id_tipologia != 4
      ) ? true : false
  }

  selectTipologia(e, id, id_tipologia) {

    if(e.target.checked) {
      this.setState({
        selected_tipologia: [...this.state.selected_tipologia,
        {
          id_con_tipo_accreditamento: id,
          id_tipologia: id_tipologia,
          percent: 0
        }]
      })
    } else {
      let settori_da_togliere = this.getChildrenSettori(id_tipologia);
      this.setState({
        selected_tipologia: this.state.selected_tipologia.filter(el =>
          el.id_con_tipo_accreditamento !== id
          ),
        selected_settore: this.state.selected_settore.filter(s =>
          settori_da_togliere.filter(ss => ss.id === s.id_con_settore).length === 0
          )
      })
    }

  }

  isSelectedSettore(id, id_settore) {
    return this.state.selected_settore.find(el =>
        el.id_con_settore === id &&
        el.id_settore === id_settore
      ) ? true : false
  }

  selectSettore(e, id, id_settore) {

    if(e.target.checked) {
      this.setState({
        selected_settore: [...this.state.selected_settore,
        {
          id_con_settore: id,
          id_settore: id_settore
        }]
      })
    } else {
      this.setState({
        selected_settore: this.state.selected_settore.filter(el =>
          el.id_con_settore !== id )
      })
    }

  }

  getChildrenSettori(id_tipologia) {
    try {
      return this.props.accreditamento.accreditamentosettori.filter(el => el.id_tipo_accreditamento === id_tipologia)
    } catch(e) {
      return [];
    }
  }

  setPercent( value, id_tipologia) {
    let macro = this.state.selected_tipologia.find(e => e.id_tipologia === id_tipologia);

    macro.percent = parseFloat(value);

    let total = this.state.selected_tipologia.filter(e => e.id_tipologia !== id_tipologia)
    .map(e => e.percent);

    let tt = 0;
    total.map(l => tt += parseInt(l));
    let max = parseFloat(100.01);

    if(max - tt - value < 0) {
      message.error('Stai superando il 100%');
      macro.percent = max - tt;
    }


    this.setState({
      selected_tipologia: [
        ...this.state.selected_tipologia.filter(e => e.id_tipologia !== id_tipologia),
        macro
      ]
    })
  }

  getAuleNum(){
    try{
      return this.props.sede.aule.filter(aula => (aula.id_tab_tipo_aula == 3 || aula.id_tab_tipo_aula == 4 || aula.id_tab_tipo_aula == 5)).length 
    } catch(e) {
      return 0
    }
  }

  getTotaleOre(id_tipologia) {
    return parseInt((260 * 8 * this.getAuleNum()) / 100 * this.state.selected_tipologia.find(e=>e.id_tipologia === id_tipologia).percent)
  }

  async validate() {
    return new Promise((resolve) => {
      let vals = 0;
      this.state.selected_tipologia.map(e => vals += e.percent);

      if(this.state.selected_tipologia.length == 1 && this.state.selected_tipologia[0].id_tipologia == 4) {
        resolve(true);
        return;
      } 

      let max = parseFloat(100.01);
      let min = parseFloat(99.9);
      if(vals < min && vals > max) {
        resolve(false);
      } else {
        resolve(true)
      }
    });
  }

  getFormData() {
    return {
      macrotipologie: this.state.selected_tipologia,
      settori: this.state.selected_settore,
    }
  }

  render() {
    return (
      <div style={{marginBottom: 16}}>
        {this.state.loaded ?
        <React.Fragment>
          <h3>Macrotipologie</h3>
          {
            this.props.accreditamento.accreditamentotipologie.map( macro => {
              return <div key={"macro_car_sede_"+macro.id_tipologia}>
                <Checkbox
                  disabled={this.props.readOnly}
                  checked={this.isSelectedMacrotipologia(macro.id, macro.id_tipologia)}
                  onChange={(e) => this.selectTipologia( e, macro.id, macro.id_tipologia )}
                >
                  {formatter.replaceStub(macro.id_tipologia, 'tipo_accreditamento', this.props.GlobalStore)}
                </Checkbox>
                {
                  this.isSelectedMacrotipologia(macro.id, macro.id_tipologia)  && this.isNotOrientamento(macro.id, macro.id_tipologia) 
                  ?
                  <div>
                    <span style={{marginRight: 16}}>Percentuale di ore</span>
                    <InputNumber
                      disabled={this.props.readOnly && !this.props.enableMacrotipologie}
                      min={0} max={100} defaultValue={0} decimalSeparator={','}
                      value={this.state.selected_tipologia.find(e=>e.id_tipologia === macro.id_tipologia).percent}
                      onChange={(value)=>this.setPercent(value, macro.id_tipologia)} />
                    <span style={{marginLeft: 16}}>Totale: {this.getTotaleOre(macro.id_tipologia)} ore</span>
                  </div>
                  : null
                }
                <hr style={{borderTop: 'none'}} />
              </div>
            })
          }
        </React.Fragment>
         : null}
      </div>
    );
  }
}

export default Macrotipologie;