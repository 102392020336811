import React, { Component } from 'react';
//import { connect } from "react-redux";

import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Telefono',
              name: 'telefono',
              props: {
                style: { width: '100%' },
                size: 'large',
              },

            },
          ]
        },
        {
          items: [
            {
              type: 'input',
              label: 'Cellulare',
              name: 'cellulare',
              props: {
                style: { width: '100%' },
                size: 'large',
              },

            },
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Fax',
              name: 'fax',
              props: {
                style: { width: '100%' },
                size: 'large',
              },

            },
          ]
        },
        {
          items: [
            {
              type: 'input',
              label: 'Sito web',
              name: 'sitoweb',
              props: {
                style: { width: '100%' },
                size: 'large',
              },

            },
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'input',
              label: 'Pec',
              name: 'pec',
              props: {
                style: { width: '100%' },
                size: 'large',
              },
              validations: [
                {
                  type: 'email',
                  error_message: 'Pec non valida'
                },
              ]
            },
          ]
        },
        {
          items: [
            {
              type: 'input',
              label: 'Email',
              name: 'email',
              props: {
                style: { width: '100%' },
                size: 'large',
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  type: 'email',
                  error_message: 'Email non valida'
                },
              ]
            },
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
};


class ContattoForm extends Component {

  state = {
    loading: false
  };


  confirm(data) {
    this.props.submit(data);
  }

  getValues() {
    return this.props.values
  }



  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.props.loading || false}
          form_model={formLayout}
          values={this.getValues()}
          submitted={(dati) => this.confirm(dati)}
        />
      </div>
    );
  }
}

export default ContattoForm