import React from 'react';
import CommonForm from '@common/components/form';
import _ from "lodash";

const EsenzioneForm = props => {
    const {domandaJson, saveJson, readOnly} = props;
    const exemptionData = _.get(domandaJson, 'exemptionData', {});
    const exemption = _.get(exemptionData, 'exemption', null);
    const exemptionReason = _.get(exemptionData, 'exemptionReason', null);
    console.warn('xxx', domandaJson)

    return(
        <CommonForm
            loading={false}
            //wrappedComponentRef={inst => (this.formSelRef = inst)}
            form_model={getFormLayout({exemptionData})}
            values={exemptionData}
            readOnly={readOnly}
            handleSubmit={ values => handleSubmit(values, domandaJson, saveJson)}
            onChangeExemption={onChangeExemption}
            showSubmitButton={ (fieldValue, form, fieldName,) => showSubmitButton(fieldValue, form, fieldName, exemption, exemptionReason )}
        />
    );
}

export default EsenzioneForm;

function handleSubmit(values, domandaJson, saveJson){
    const updatedDomanda = {
        ...domandaJson,
        exemptionData: values
    };
    saveJson(updatedDomanda);
}

function onChangeExemption(...rest){
    console.warn('ccc', rest)
}

function showSubmitButton(fieldValue, form, fieldName, prevExemption, prevExemptionReason){
    const currentExemption = form.getFieldValue("exemption");
    const currentExemptionReason = form.getFieldValue("exemptionReason");

    return currentExemption != prevExemption || currentExemptionReason != prevExemptionReason;
}

function getFormLayout(params){
    const exemption = _.get(params, 'exemptionData.exemption', null);
    const exemptionReason = _.get(params, 'exemptionData.exemptionReason', null);
    
    return {
        layout: 'vertical',
        multistep: false,
        onSubmit: 'handleSubmit',
        rows: [
            {
                cols: [
                    {
                        span: 6,
                        items: [
                            {
                                type: 'radio',
                                label: 'Esente al pagamento',
                                name: 'exemption',
                                option_type: 'button',                            
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ],
                                options: [
                                    {
                                        value: "1",
                                        label: "Si"
                                    },
                                    {
                                        value: "0",
                                        label: "No"
                                    }
                                ],
                                onChange: 'onChangeExemption'
                            }                        
                        ],
                    },
                    {
                        span: 18,
                        items: [                            
                            {
                                type: 'textarea',
                                label: 'Motivazione esenzione',
                                type_string: 'text', // field type
                                name: 'exemptionReason',
                                validations: [
                                    {
                                        type: 'required',
                                        error_message: 'Campo obbligatorio',
                                    }
                                ],
                                showIf: [
                                    {
                                        type: "field_validation",
                                        field: "exemption",
                                        operator: "==",
                                        value: "1",
                                    }                                   
                                ],
                            }                           
                        ],
                    }
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                type: 'submit',
                                name: 'btn_submit_dati_generali',
                                text: 'Salva i dati',
                                props: {
                                    icon: 'save',        
                                    className: 'ant-btn-primary'
                                },
                                showIf: [                                    
                                    {
                                        type: "prop_func",
                                        func: "showSubmitButton"
                                    }
                                ],
                            }
                        ],
                    },
                ],
              },
        ]
    }  
}; 