import React, { Component } from 'react';
import { Icon, Tabs, message } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import checkPermission from '@common/utils/check/permissions';
import RiferimentiCommissioniForm from '../forms/formulario/RiferimentiCommissioniForm';
import DatiGeneraliFormCommissioni from '../forms/formulario/DatiGeneraliFormCommissioni';
import AllegatiCommissioni from '../forms/formulario/AllegatiCommissioni';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CommissioneService from '../services/CommissioneEsami';
import affidamentoStatic from '@common/utils/static/affidamento';
import SchedaCommissioniValutazione from "../forms/formulario/SchedaCommissioniValutazione";

import PropTypes from 'prop-types';

const TabPane = Tabs.TabPane;

export default class SchedaCommissioni extends Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    id_ente: PropTypes.number,
    isEditable: PropTypes.bool,
    refresh: PropTypes.func,
    domanda_json: PropTypes.object,
    progress_obj: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    progettiList: null,
    allprojectList: null,
    loaded: false,
    dati_progetto: this.props.domanda_json || null

  }

  componentDidMount() {
    if (this.props.id_ente) {
      this.callProjects()
      this.setState({ loaded: true });

    }
  }



  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isValidSection(name) {

    if (this.props.section_feedback && this.props.section_feedback[name]) {
      return this.props.section_feedback[name].valid ? 'checked' : 'invalid';
    } else if (this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato && (!this.props.section_feedback || !this.props.section_feedback[name])) {
      return 'default';
    }

    //if (this.props.stato_flusso < affidamentoStatic.stato_flusso_progetto.presentato) {
    if (this.props.progress_obj.valid_sections[name])
      return 'checked';
    else if (this.props.progress_obj.showed_sections[name] && !this.props.progress_obj.valid_sections[name])
      return 'invalid';
    //}

    return 'default';
  }

  saveFeedback(note, valid, section) {
    if (!this.state.loadingFeedback) {
      this.setState({
        loadingFeedback: true,
      }, () => {

        {
          CommissioneService.feedbackSection(
            this.props.id_commissione,
            {
              section: section,
              note: note,
              valid: valid
            },
            (res) => {
              //this.props.refreshInline(res.data.data);
              message.success("La sezione è stata salvata correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data)
              this.setState({ loadingFeedback: false });
            },
            (err) => this.setState({ loadingFeedback: false })
          );
        }
        
      });
    }
  }

  updateProgress(progress) {
    this.props.progress_obj.valid_sections = progress.valid_sections;
    if (this.props.updateProgress) {
      this.props.updateProgress(progress);
    }
  }

  callProjects() {
    this.setState({ loading: true })

    CommissioneService.allProjects(this.props.id_commissione,{},
      (res) => {
        this.setState({
          progettiList: res.data.data.map(e => {
            let label = 'N.D.';
            if(e.titolo_progetto !== null){
              label = `${e.titolo_progetto} [Prot N.${e.numero_protocollo} del ${e.data_protocollo}]`;
            }
            return {
                key: e.id,
                value: e.id,
                label: label,
                id: e.id
            }
        }),
        allprojectList: res.data.data,
        });
      }, () => this.setState({ loading: false })
    );

    this.setState({ loading: false })
  }

  SaveData(json) {
    this.setState({ loading: true })

    if(json.scheda == "references") {
      json = {
        ...this.state.dati_progetto,
        references: json.references
      }
    }

    if(json.scheda == "dati_generali") {
      json = {
        ...this.state.dati_progetto,
        generalData: json.generalData
      }
    }

    if(json.scheda == "allegati") {
      json = {
        ...this.state.dati_progetto,
        attachments: json.attachments
      }
    }

    CommissioneService.SaveData(json, this.props.id_commissione,
      (res) => {
        message.success('Dati salvati con successo');
        this.setState({
          dati: res.data.data,
          progress_obj: res.data.data.progress,
          dati_progetto:res.data.data.scheda_progetto_json
        });

        this.props.progressCommissioni(res.data.data.progress)


        //Le due chiamate sono una conseguenza dell'altra
        //Altrimenti può capitate che questa GET venga fatta prima
        //della POST
        if(json.scheda == "riferimenti") {
        CommissioneService.getDomanda(this.props.id_commissione, json.references.mainSection.id_progetto, json.references.mainSection.id_edizione,
          (res) => {
            message.success('Dati salvati con successo');
            this.setState({
              dati: res.data.data,
              progress_obj: res.data.data.progress,
              dati_progetto:res.data.data.scheda_progetto_json
            });


          },
        )
        }

      },
    )
  }
  


  render() {
    return (<div>
      { !this.state.loaded
      ?
      <SpinnerLoading message='Caricamento dati...' />
      :
      <StickyContainer>
        <Tabs
          onChange={(k) => this.setState({ current_key: "" + k })}
          defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
          {this.props.domanda_json?.generalData &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('references')}>RIFERIMENTI<br /><Icon type="check-circle" /></span>} key="1">
              <div id="riferimenti">
                <div style={{ padding: 14 }}>
                  <RiferimentiCommissioniForm
                    {...this.props}
                    generalData={this.props.domanda_json.generalData}
                    updateProgress={this.updateProgress.bind(this)}
                    readOnly={!this.props.isEditable}
                    progettiList={this.state.progettiList}
                    SaveData={(json) => this.SaveData(json)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    
                />
                </div>
              </div>
            </TabPane>
          }
                    {this.props.domanda_json?.generalData &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('generalData')}>DATI GENERALI <br /><Icon type="check-circle" /></span>} key="2">
              <div id="datiGenerali">
                <div style={{ padding: 14 }}>
                  <DatiGeneraliFormCommissioni
                    {...this.props}
                    datiProgetto={this.state.dati_progetto}
                    teachers={this.state.dati_progetto ? this.state.dati_progetto.generalData.AfcTeachers.teacherList : []}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    SaveData={(json) => this.SaveData(json)}
                    allprojectList={this.state.allprojectList}
                />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('attachments')}>ALLEGATI <br /><Icon type="check-circle" /></span>} key="3">
              <div id="allegati">
                <div style={{ padding: 14 }}>
                  <AllegatiCommissioni
                    {...this.props}
                    ref = {(ref) => {this.programmazione_didattica_ref = ref}}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    SaveData={(json) => this.SaveData(json)}

                 />
                </div>
              </div>
            </TabPane>
          }
                        {(this.props.UserStore.user.role[0].name ==
                  "IstruttoreAmministrativo" || this.props.UserStore.user.role[0].name ==
                  "DirigenteAmministrativo") && (
                  <TabPane
                    tab={
                      <span className="default">
                        ISTRUTTORIA
                        <br />
                        COMMISSIONI
                        <br />
                      </span>
                    }
                    key="10"
                  >
                    <div id="schedaIstruttoria">
                      <div style={{ padding: 14 }}>
                        <SchedaCommissioniValutazione
                          {...this.props}
                          professionalita={this.state.professionalita}
                          readOnly={!this.props.canGiveSectionFeedback}
                        />
                      </div>
                    </div>
                  </TabPane>
                )}
        </Tabs>
      </StickyContainer>
    }</div>);
  }
}

