import React, { Component } from 'react';

import {
    Button, Drawer, Table, Tooltip, Popconfirm
} from 'antd';

import EditOrarioSettimanale from './EditOrarioSettimanale';
import moment from 'moment';

const columns = (component) => {
    let ret = [
        {
            title: 'Giorno della settimana',
            dataIndex: 'weekDay_description',
            key: 'weekDay_description',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.weekDay_description}</div>)
        },
        {
            title: 'Dal',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.fromDate ? moment(record.fromDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</div>)
        },
        {
            title: 'Al',
            dataIndex: 'toDate',
            key: 'toDate',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.toDate ? moment(record.toDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</div>)
        },
        {
            title: 'Dalle ore',
            dataIndex: 'fromTime',
            key: 'fromTime',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.fromTime ? moment(record.fromTime, 'HH:mm:ss').format('HH:mm') : ''}</div>)
        },
        {
            title: 'Alle ore',
            dataIndex: 'toTime',
            key: 'toTime',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.toTime ? moment(record.toTime, 'HH:mm:ss').format('HH:mm') : ''}</div>)
        },
        {
            title: 'Materia',
            dataIndex: 'subject_description',
            key: 'subject_description',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.subject_description}</div>)
        },
        {
            title: 'Insegnante',
            dataIndex: 'teacher_description',
            key: 'teacher_description',
            render: (text, record) => (<div className={!component.recordIsValid(record) ? 'record-not-valid' : ''}>{record.teacher_description}
                {!component.recordIsValid(record) && <Tooltip placement="top" title={!component.teacherIsValid(record) ? "Attenzione: insegnante non valido" : !component.periodIsValid(record) ? "Attenzione: il periodo della lezione non è compreso nel periodo di svolgimento del corso" : "Attenzione: gli orari delle lezioni sono sovrapposti"}><Button className="m2w record-not-valid" size="small" shape="circle" icon={"exclamation"} /></Tooltip>}
            </div>)
        },
    ];

    if (!component.props.hiddenActions) {
        ret.push(
            {
                title: 'Azioni',
                dataIndex: 'azione',
                key: 'azione',
                render: (text, record) => (
                    <div>
                        {!component.props.loading_data &&
                            <React.Fragment>
                                <Tooltip placement="top" title={component.props.readOnly ? "Dettagli" : "Modifica"}>
                                    <Button className="m2w" size="small" shape="circle" icon={component.props.readOnly ? "eye" : "edit"} onClick={() => component.open(record, record.id, 'edit_orario')} />
                                </Tooltip>
                                <Popconfirm disabled={component.props.readOnly} placement="leftTop" title="Sicuro di voler rimuovere questo orario?"
                                    onConfirm={() => component.deleteOrario(record.id)} okText="Si" cancelText="No">
                                    <Button disabled={component.props.readOnly} className="m2w" size="small" shape="circle" icon="delete" />
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                ),
            }
        );
    }
    return ret;
}

class OrarioSettimanale extends Component {

    state = {
        open_drawer_edit_orario: false,
        create_orario: false,
        element_opened: {},
        orarioList: [],
        errorMessage: '',
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loading: false,
            orarioList: props.orarioList
        });
    }


    deleteOrario(id) {
        this.setState({
            orarioList: this.state.orarioList.filter(value => { return value.id !== id })
        }, () => {
            this.props.saveOrarioList(this.state.orarioList)
        });
    }

    update(data) {
        let list = this.state.orarioList;
        var index = this.state.orarioList.map(el => el.id).indexOf(this.state.id_element_opened);
        list[index] = {
            id: this.state.id_element_opened,
            weekDay: data.weekDay,
            fromDate: data.fromDate.format('YYYY-MM-DD', "it-IT"),
            toDate: data.toDate.format('YYYY-MM-DD', "it-IT"),
            fromTime: data.fromTime.format('HH:mm'),
            toTime: data.toTime.format('HH:mm'),
            subject: data.subject,
            teacher: data.teacher,
            subject_description: data.subject_description,
            weekDay_description: data.weekDay_description,
            teacher_description: data.teacher_description
        }
        this.setState(
            {
                open_drawer_edit_orario: false,
                orarioList: list,
                element_opened: {},
                id_element_opened: null
            }, () => { this.props.saveOrarioList(this.state.orarioList) }
        );
    }

    create(data) {
        console.warn("OrarioSettimanale data", data)
        let id = 0;
        if (this.state.orarioList.length > 0) {
            id = Math.max.apply(Math, this.state.orarioList.map(function (o) { return o.id; })) + 1
        }
        let list = this.state.orarioList;
        list.push({
            id: id,
            fromTime: data.fromTime.format('HH:mm'),
            fromDate: data.fromDate.format('YYYY-MM-DD', "it-IT"),
            subject: data.subject,
            subject_description: data.subject_description,
            teacher: data.teacher,
            teacher_description: data.teacher_description,
            teacher_obj: data.teacher_obj,
            weekDay: data.weekDay,
            subject: data.subject,
            weekDay_description: data.weekDay_description,
        });
        this.setState(
            {
                open_drawer_edit_orario: false,
                orarioList: list,
                element_opened: {},
                id_element_opened: null
            }, () => {
                this.props.saveOrarioList(this.state.orarioList);
            });
    }

    validOverlapping(list, data) {
        let valid = true;
        let fromDate = moment(data.fromDate, 'YYYY-MM-DD');
        let toDate = moment(data.toDate, 'YYYY-MM-DD');
        let fromTime = moment(data.fromTime, 'HH:mm');
        let toTime = moment(data.toTime, 'HH:mm');
        list.forEach(element => {
            if (element.weekDay === data.weekDay) {
                let currentFromDate = moment(element.fromDate, 'YYYY-MM-DD');
                let currentToDate = moment(element.toDate, 'YYYY-MM-DD');
                if (currentToDate >= fromDate && currentFromDate <= toDate) {
                    let currentFromTime = moment(element.fromTime, 'HH:mm');
                    let currentToTime = moment(element.toTime, 'HH:mm');
                    if (currentToTime > fromTime && currentFromTime < toTime) {
                        valid = false;
                    }
                }
            }
        });
        return valid;
    }

    open(element, id, action) {
        this.setState({
            create_orario: false,
            element_opened: element,
            id_element_opened: id,
        }, () => this.setState({ ['open_drawer_' + action]: true }))
    }

    recordIsValid(record) {
        return (this.validOverlapping(this.state.orarioList.filter(el => el !== record), record) && this.teacherIsValid(record) && this.periodIsValid(record));
    }

    periodIsValid(record) {
        if (!this.props.startDate || !this.props.endDate)
            return true;
        let fromDate = moment(record.fromDate, 'YYYY-MM-DD');
        let toDate = moment(record.toDate, 'YYYY-MM-DD');
        let startCourse = moment(this.props.startDate, 'YYYY-MM-DD');
        let endCourse = moment(this.props.endDate, 'YYYY-MM-DD');
        if (!startCourse && endCourse) {
            return toDate <= endCourse;
        } else if (startCourse && !endCourse) {
            return fromDate >= startCourse;
        } else return startCourse && endCourse && fromDate >= startCourse && toDate <= endCourse
    }

    teacherIsValid(record) {
        return (!record.teacher || this.props.teacherList.some(el => el.value === record.teacher));
    }

    render() {
        return (
            <div>
                {!this.props.readOnly && <Button disabled={this.props.readOnly} type="primary" className="m5h" icon="plus" onClick={() => this.setState({ open_drawer_edit_orario: true, create_orario: true })}>Aggiungi</Button>}
                <Table
                    rowKey={(record) => record.id}
                    loading={this.props.loading}
                    columns={columns(this)}
                    dataSource={this.state.orarioList}
                />
                <Drawer
                    title="ORARIO SETIMANALE"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_orario: false, element_opened: null })}
                    visible={this.state.open_drawer_edit_orario}
                    width={window.innerWidth / 3}
                    destroyOnClose={true}
                >
                    <EditOrarioSettimanale
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_orario: false })}
                        orarioSettimanale={this.state.element_opened}
                        idOrarioSettimanale={this.state.id_element_opened}
                        create={this.state.create_orario}
                        onUpdate={(data) => {
                            this.update(data);
                        }}
                        onCreate={(data) => this.create(data)}
                        readOnly={this.props.readOnly}
                    />
                </Drawer>
            </div>
        );
    }
}
export default OrarioSettimanale;