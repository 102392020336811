import React, { Component, Fragment } from 'react';
import { Button, message, Spin } from 'antd';
import CommonForm from '@common/components/form';
import eventBroker from '@common/utils/services/EventBroker';
import affidamentoStatic from '@common/utils/static/affidamento';
import checkPermission from '@common/utils/check/permissions';
import Documenti from '@common/components/Documenti';
import allegatiStatic from '@common/utils/static/allegati'
import PropTypes from 'prop-types'
import ValidazioneScheda from '@modules/affidamento/components/audit/ValidazioneScheda';
import ModelService from '../../services/Progetto';

export default class Allegati extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  };

  state = {
    loading: true,
    loaded: false,
    medias: []
  };

  componentDidMount() {
    this.load(this.props);
  }

  load(props) {
    this.setState({
      loaded: true,
      loading: false,
    });
  }

  render() {
    return <div style={{ opacity: !this.props.readOnly && !this.props.saved ? 0.5 : 1 }}>
      {
        !this.props.readOnly && !this.props.saved ? <Spin /> : null
      }
      { (checkPermission.hasPermission(this.props.UserStore.user, ['manageOwnProgetto']) || checkPermission.hasPermission(this.props.UserStore.user, ['direttoreAmministrativoPermissions'])) && this.props.accreditamento &&
      <Fragment>
        <Documenti
          canDelete={ false }
          accreditation_id={this.props.accreditamento?.id || null}
          unique={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          readOnly={ true }
          medias={this.props.accreditamento.allMedias}
          current_domain="ente"
          current_subdomain="documenti_legale"
          required_types={[]}
          ListItem={{style: {paddingTop: '0'}}}
          descriptionSection={{lg: 14}}
          iconSection={{lg: 2}}
          uploadSection={{lg: 8}}
      />
      <Documenti
          canDelete={ false }
          accreditation_id={this.props.accreditamento.id}
          unique={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          readOnly={ true }
          medias={this.props.accreditamento.allMedias}
          current_domain="requisiti_aule"
          current_subdomain="comunicazione_medico"
          required_types={[]}
          ListItem={{style: {paddingTop: '0'}}}
          descriptionSection={{lg: 14}}
          iconSection={{lg: 2}}
          uploadSection={{lg: 8}}
      />
      <Documenti
          canDelete={ false }
          accreditation_id={this.props.accreditamento.id}
          unique={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          readOnly={ true }
          medias={this.props.accreditamento.allMedias}
          current_domain="requisiti_aule"
          current_subdomain="documento_sicurezza"
          required_types={[]}
          ListItem={{style: {paddingTop: '0'}}}
          descriptionSection={{lg: 14}}
          iconSection={{lg: 2}}
          uploadSection={{lg: 8}}
      />

      <Documenti
          readOnly={ checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) ? false : true }
          canDelete={ true }
          canAddNote={false}
          required_dates={false}
          unique={true}
          tempFile={true}
          media_types={allegatiStatic.filteredAccreditationMedia(this.props.GlobalStore.stubs.tipo_media, this.props.accreditamento.tipo_accreditamento)}
          medias={this.props.progetto.media}
          current_domain="afc_allegati"
          current_subdomain="antimafia"
          required_types={allegatiStatic.filteredRequiredMedia(this.props.GlobalStore.stubs.tipo_media, 'afc_allegati', 'antimafia')}
          ref_id={this.props.accreditamento.id}
          onUpload={(f) => {
            ModelService.uploadByDominio(
              this.props.progetto.id,
              "afc_allegati",
              "antimafia",
              {'media': f},
              (res) => {
                //this.props.refreshInline(res.data.data);
                message.success("La sezione è stata salvata correttamente");
                //this.props.refresh(false);
                this.props.refreshInline(res.data.data)
                this.setState({ loadingFeedback: false });
              },
              (err) => this.setState({ loadingFeedback: false })
            );
          }}
          onDelete={(id)=> console.warn("onDelete", id)}
          ListItem={{style: {paddingTop: '0'}}}
          descriptionSection={{lg: 14}}
          iconSection={{lg: 2}}
          uploadSection={{lg: 8}}
      />

      </Fragment>
      }

      

    </div>
  }
}
