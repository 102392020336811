import BaseRequest from "@common/services/_baseRequest";


export default {

	list: ( id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"get",
			'affidamento/capacita-gestionali/' + id_progetto,
			cb,
			ecb || null,
			null
		);
	},

	listProgetto: ( id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"get",
			'affidamento/capacita-gestionali/' + id_progetto + '/progetto',
			cb || null,
			ecb || null
		);
	},

	edit: (data, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'affidamento/capacita-gestionali/' + id_progetto,
			cb,
			ecb || null,
			null
		);
	},

	addOrganigramma: (data, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'affidamento/capacita-gestionali/' + id_progetto + '/organigramma',
			cb,
			ecb || null,
			null
		);
	},

	updateOrganigramma: (data, id, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"put",
			'affidamento/capacita-gestionali/' + id_progetto + '/organigramma/' + id,
			cb,
			ecb || null,
			null
		);
	},

	deleteOrganigramma: (id, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"delete",
			'affidamento/capacita-gestionali/' + id_progetto + '/organigramma/' + id,
			cb,
			ecb || null,
			null
		);
	},

	addDocente: (data, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'affidamento/capacita-gestionali/' + id_progetto + '/docente',
			cb,
			ecb || null,
			null
		);
	},

	updateDocente: (data, id, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"put",
			'affidamento/capacita-gestionali/' + id_progetto + '/docente/' + id,
			cb,
			ecb || null,
			null
		);
	},

	deleteDocente: (id, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"delete",
			'affidamento/capacita-gestionali/' + id_progetto + '/docente/' + id,
			cb,
			ecb || null,
			null
		);
	},

	addUtenzaSpeciale: (data, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"post",
			'affidamento/capacita-gestionali/' + id_progetto + '/utenza-speciale',
			cb,
			ecb || null,
			null
		);
	},

	updateUtenzaSpeciale: (data, id, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			data,
			null,
			"put",
			'affidamento/capacita-gestionali/' + id_progetto + '/utenza-speciale/' + id,
			cb,
			ecb || null,
			null
		);
	},

	deleteUtenzaSpeciale: (id, id_progetto, cb, ecb = null) => {
		var req = new BaseRequest();
		return req.makeRequest(
			{},
			{},
			null,
			"delete",
			'affidamento/capacita-gestionali/' + id_progetto + '/utenza-speciale/' + id,
			cb,
			ecb || null,
			null
		);
	}
}
