import React, { Component } from 'react';
import CommonForm from '@common/components/form';
import checkPermission from '@common/utils/check/permissions';
import formatter from '@common/utils/formatters/_';
import SpinnerLoading from "@common/components/SpinnerLoading";
import CommissioneService from '../../services/CommissioneEsami';
import eventBroker from '@common/utils/services/EventBroker';
import affidamentoStatic from "@common/utils/static/affidamento";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";
import {  Spin } from "antd";

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'references',
                type: 'title',
                element: 'h4',
                text: 'Riferimenti',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Progetto',
                name: 'references.mainSection.id_progetto',
                options_props: 'progettiList',
                onChange: 'SelectEdizione',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                props: {
                  showSearch: true,
                  filterOption: (input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                },
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Edizione',
                name: 'references.mainSection.id_edizione',
                options_props: 'edizioniList',
                onChange: '',
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  }
                ],
                props: {
                  showSearch: true,
                  filterOption: (input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                },
              }
            ],
          },
        ]
      },
      {
        cols: [
          {
            items: [
              {
                type: 'submit',
                name: 'btn_submit_dati_generali',
                text: 'Salva i dati',
                //onClick: 'showDrawer',
                showIf: [
                  {
                    type: 'custom_func',
                    func: () => { return !component.props.anteprima && !component.props.readOnly; }
                  }
                ],
                props: {
                  icon: 'save',

                  className: 'bg-green'
                }
              }
            ],
          },
        ],
      },
    ],
    onSubmit: 'submit',
  }
};

export default class RiferimentiForm extends Component {

  constructor(props) {
    super(props);
    this.getBackReferences = this.getBackReferences.bind(this);
    eventBroker.on('getBackReferences', this.getBackReferences);
  }

  state = {
    loaded: false,
    loading: false,
    generalData: {},
    id_ente: this.props.id_ente || null,
    progetto_designato: null,
    progettiList: null,
    edizioniList: null,
    send: false
  };
  

  componentDidMount() {
    this.load(this.props);
    this.setState({ loaded: true })
  }


  load(props) {

    this.setState({ loading: true })
    this.setState({
      id_ente: props.id_ente,
        generalData: props.generalData,
        progettiList: props.progettiList
    });
    this.setState({ loading: false })

    if(this.props.domanda_json.references.mainSection.id_progetto != null) {
      this.SelectEdizione(this.props.domanda_json.references.mainSection.id_progetto)
    }
    

  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']);
  }

  getValues() {
    let data = this.props.domanda_json;
    
    return data;
  }

  getBackReferences() {
    return this.formSelRef?.props.form.getFieldsValue();
  }


  submit(data) {


     let dati = {
      ...data,
      generalData: this.props.domanda_json.generalData,
      attachments: this.props.domanda_json.attachments,
      idEnte: this.props.domanda_json.idEnte,
      scheda: "riferimenti"
    }
    console.error(dati,"ducati")
    this.setState({ send: true })

    this.props.SaveData(dati)
    
  }

  SelectEdizione(value) {

    this.setState({ loading: true })

    CommissioneService.SelectEdizione(this.props.id_commissione ,value,{},
      (res) => {
        this.setState({
          edizioniList: res.data.data.map(e => {
            let label = 'N.D.';
            if(e.titolo_progetto !== null){
              label = `${e.titolo_progetto} [Prot N.${e.numero_protocollo} del ${e.data_protocollo}]`;
            }
            return {
                key: e.id,
                value: e.id,
                label: label,
                id: e.id
            }
        }),
        });
      }, () => this.setState({ loading: false })
    );

    this.setState({ loading: false })

  }


  


  render() {

    return <div style={{ opacity: !this.props.saved || this.state.loading ? 0.5 : 1 }}>
              {!this.props.saved ? <Spin /> : null}
        {checkPermission.hasPermission(this.props.UserStore.user, [
          "viewValutazioneScheda",
        ]) && (
          <ValidazioneScheda
            canGiveFeedback={
              this.props.canGiveSectionFeedback &&
              this.props.stato_flusso ===
                affidamentoStatic.stato_flusso_progetto.audit_iniziato
            }
            loadingFeedback={this.props.loadingFeedback}
            saveFeedback={(note, valid) =>
              this.props.saveFeedback(note, valid, "references")
            }
            feedback={
              this.props.section_feedback
                ? this.props.section_feedback["references"]
                : null
            }
          />
        )}

     {!this.state.progettiList == null
      ?
      <SpinnerLoading message='Caricamento dati...' />
      :  
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.props.saving || this.state.loading || false}
        form_model={formLayout(this)}
        values={this.getValues()}
        readOnly={this.props.domanda_json.references.mainSection.id_progetto != null || this.state.send} 
        submit={(data) => this.submit(data)}
        progettiList={this.props.progettiList}
        SelectEdizione={(value) => this.SelectEdizione(value)}
        edizioniList={this.state.edizioniList}

      />
  }
    </div>
  }
}