import React, { Component } from "react";

import Docenti from "./risorse-capacita/Docenti";
import UtenzeSpeciali from "./risorse-capacita/UtenzeSpeciali";
import CapacitaGestionali from "@modules/affidamento/services/CapacitaGestionali";

import { Table, Checkbox, Button } from "antd";

const columnsOrganigramma = (component) => {
 
  return [
    {
      title: "Seleziona",
      dataIndex: "selezione",
      key: "selezione",
      render: (text, record) => (
        <div>
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkOrganigramma(e, record)}
            checked={component.props.metodologie.organogramList?.some(
              (el) =>
                el.cf === record.cf && el.id_funzione === record.id_funzione
            )}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: "Funzione",
      dataIndex: "funzione",
      key: "funzione",
      render: (text, record) => <div>{record.funzione}</div>,
    },
    {
      title: "Dati anagrafici",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.nome + " " + record.cognome}
          <br />
          {record.cf}
        </div>
      ),
    },
  ];
};

const columnsDocenti = (component) => {
  return [
    /*{
      title: 'Funzione',
      dataIndex: 'funzione',
      key: 'funzione',
      render: (text, record) => (<div style={{ width: 120 }}>{record.funzione}</div>),
    },*/
    {
      title: "Seleziona",
      dataIndex: "selezione",
      key: "selezione",
      render: (text, record) => (
        <div>
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkDocente(e, record)}
            checked={component.props.metodologie.teacherList?.some(
              (el) => el.cf === record.cf
            )}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: "Dati anagrafici",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.nome + " " + record.cognome}
          <br />
          {record.cf}
        </div>
      ),
    },
  ];
};

const columnsUtenzeSpeciali = (component) => {
  return [
    {
      title: "Seleziona",
      dataIndex: "selezione",
      key: "selezione",
      render: (text, record) => (
        <div>
          <Checkbox
            disabled={component.props.readOnly}
            onChange={(e) => component.props.checkUtenzaSpeciale(e, record)}
            checked={component.props.metodologie.specialUserList?.some(
              (el) =>
                el.cf === record.cf && el.id_funzione === record.id_funzione
            )}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: "Professione",
      dataIndex: "professione",
      key: "professione",
      render: (text, record) => <div>{record.funzione}</div>,
    },
    {
      title: "Dati anagrafici",
      dataIndex: "nome",
      key: "nome",
      render: (text, record) => (
        <div>
          {record.nome + " " + record.cognome}
          <br />
          {record.cf}
        </div>
      ),
    },
  ];
};

class RisorseUmaneTable extends Component {
  state = {
    loading: false,
    loading_organigramma: false,
    loading_docenti: false,
    loading_utenze_speciali: false,
    open_drawer: false,
    capacita: {},
    sedi: [],
    funzioni: [],
    docenti: [],
    utenze_speciali: [],
    accreditamento: {}
  };

  componentDidMount() {
    this.loadData(this.props, null, false);
  }

  loadData(props, el = null, refresh = true) {

    if(props.reloadProgress && refresh) props.reloadProgress();

    let str_loading = (!el) ? 'loading' : 'loading_' + el;

    this.setState({[str_loading]: true});

    CapacitaGestionali.listProgetto( props.progetto_padre.id, (res) => {
      this.setState({
        accreditamento: res.data.data.accreditamento,
        capacita: res.data.data.capacita,
        funzioni: res.data.data.funzioni,
        docenti: res.data.data.docenti,
        utenze_speciali: res.data.data.utenze_speciali,
        sedi: res.data.data.sedi,
        [str_loading]: false
      })
    }, () => this.setState({[str_loading]: false}))

  }

  getPr() {
    let p = Object.assign({}, this.props);
    delete p.accreditamento;

    return p
  }

  render() {
    return (
      <div>
        <h4 className="m20h">Organigramma aziendale</h4>
        <Table
          locale={{
            emptyText: this.props.domanda_json.relatedSubjects
              ?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.numero_decreto_accreditamento
              ? ""
              : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali",
          }}
          loading={this.props.loading}
          columns={columnsOrganigramma(this)}
          dataSource={this.props.professionalita?.organigramma}
          rowKey="cf"
        />
        <h4 className="m20h">Personale docente</h4>
        <Docenti
            accreditamento={this.state.accreditamento}
            docenti={this.state.docenti}
            onUpdate={()=>this.loadData(this.props, 'docenti')}
            loading_data={this.state.loading}
            loading_docenti={this.state.loading_docenti}
            readOnly={this.props.readOnly}
            sedi={this.state.sedi}
            {...this.getPr()}
        />
        <br/><br/>
        <h4>Seleziona Docenti</h4>
        <Table
          locale={{
            emptyText: this.props.domanda_json.relatedSubjects
              ?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.numero_decreto_accreditamento
              ? ""
              : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali",
          }}
          loading={this.props.loading}
          columns={columnsDocenti(this)}
          dataSource={this.props.professionalita?.docenti}
          rowKey="cf" />
        <h4 className="m20h">Utenze speciali</h4>
        <UtenzeSpeciali
            accreditamento={this.state.accreditamento}
            utenze_speciali={this.state.utenze_speciali}
            onUpdate={()=>this.loadData(this.props, 'utenze_speciali')}
            loading_data={this.state.loading}
            loading_utenze_speciali={this.state.loading_utenze_speciali}
            readOnly={this.props.readOnly}
            sedi={this.state.sedi}
            {...this.getPr()}
        />
        <br/><br/>
        <h4>Seleziona Utenze speciali</h4>
        <Table
          locale={{
            emptyText: this.props.domanda_json.relatedSubjects
              ?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.numero_decreto_accreditamento
              ? ""
              : "Per visualizzare la lista è necessario scegliere un settore e salvare la scheda Dati Generali",
          }}
          loading={this.props.loading}
          columns={columnsUtenzeSpeciali(this)}
          dataSource={this.props.professionalita?.utenzeSpeciali}
          rowKey="cf" />
      </div>
    );
  }
}
export default RisorseUmaneTable;
