import React, { Component } from 'react';
import { Button, Icon, message } from 'antd';
import ModelServices from '../services/User';
import CommonForm from '@common/components/form';
import CreateAnagrafica from '@anagrafica/forms/Create';
import userStatic from '@common/utils/static/user.js'
import DatiIndirizzo from '@affidamento/components/soggettiCorrelati/DatiIndirizzo'
import format from '@common/utils/formatters/_';

export default class CreateModel extends Component {

  state = {
    loading: false,
    searched_anagrafica: false,
    model_anagrafica: {},
    open_drawer: false
  }


  getFormLayout() {

    let form_rows = [];
    form_rows.push({
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Username',
              type_string: 'text', // field type
              name: 'username',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    });
    form_rows.push({
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'email',
              label: 'Email',
              name: 'email',
              props: {
                size: 'large',
                prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    });

    form_rows.push({
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Password',
              type_string: 'password', // field type
              name: 'password',
              props: {
                size: 'large',
                prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              showIf: [
                {
                  type: 'custom_func',
                  func: () => { return !this.props.noPassword ? true : false; }
                },
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
                {
                  pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})',
                  message: 'La password deve contenere almeno dieci caratteri, una maiuscola, una minuscola, un numero e un carattere speciale'
                }
              ],
            },
          ],
        },
      ],
    });

    if(this.props.UserStore.user.entiTutor && this.props.UserStore.user.entiTutor.length > 1){
      form_rows.push({
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Ente',
                type_string: 'text', // field type
                name: 'id_ente',
                options_props: 'entiTutor',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },

        ],
      });
    }

    if ((!this.props.role || (this.props.role && this.props.role.length === 0)) && (this.props.roles && this.props.roles.length > 0)) {
      form_rows.push({
        cols: [
          {
            items: [
              {
                type: 'select',
                label: 'Ruolo',
                mode: 'multiple',
                name: 'role_name',
                options: this.props.roles.filter(r => r !== 'Ente').map(r => {
                  return {
                    label: userStatic.utente_generico[r.name] ? userStatic.utente_generico[r.name] : r.name,
                    value: r.name
                  }
                }),
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ]
          },
        ],
      });
    }


    form_rows.push({
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Azienda di appartenenza',
              type_string: 'text', // field type
              name: 'azienda_appartenenza',
              props: {
                size: 'large',
                prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
              },
              showIf: [
                {
                  type: 'custom_func',
                  func: () => { return this.props.showAziendaAppartenenza ? true : false; }
                },
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    });


    if (this.props.infoIspettore) {

      form_rows.push({
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'email',
                label: 'Pec',
                name: 'pec',
                placeholder: 'Inserisci una PEC valida',
                props: {
                  size: 'large',
                  prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },

                validations: [],
              },
            ],
          },
        ],
      });

      form_rows.push({
        cols: [
          {
            span: 24,
            items: [
              {
                type: 'select',
                label: 'Esterno all\'amministrazione',
                name: 'esterno_amministrazione',
                options: [
                  {
                    value: "1",
                    label: "SI"
                  },
                  {
                    value: "0",
                    label: "NO"
                  },
                ],
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                  disabled: this.props.role === 'Valutatore'
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      });

      form_rows.push({
        cols: [
          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Dipartimento',
                type_string: 'text', // field type
                name: 'dipartimento',
                options_props: 'dipartimenti',
                onChange: 'selectedDipartimento',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: "0",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },

          {
            span: 12,
            items: [
              {
                type: 'select',
                label: 'Settore/Unità operativa',
                type_string: 'text', // field type
                name: 'id_settore_regionale',
                options_props: 'settori',
                props: {
                  size: 'large',
                  prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: "0",
                  },
                ],
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              },
            ],
          },
        ],
      });

      form_rows.push({
        cols: [
          { span: 24,
            items: [
              {
                name: 'titolo_residenza',
                type: 'title',
                element: 'h4',
                text: 'Sede di lavoro',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: "0",
                  },
                ],
              },
            ],
          },
          { span: 24,
            items: [
              {
                name: 'indirizzo_dati',
                type: 'child',
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: "0",
                  },
                ],
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => this.dati_sede_lavoro_container = ref}
                    >
                      <DatiIndirizzo
                        {...this.props}
                        readOnly={false}
                        dati={{}}
                        ref={(ref) => this.dati_sede_lavoro = ref}
                        subform={true}
                      ></DatiIndirizzo>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },

        ],
      });

      form_rows.push(    {
        cols: [
          { span: 24,
            items: [
              {
                name: 'titolo_cv',
                type: 'title',
                element: 'h4',
                text: 'Curriculum',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: "0",
                  },
                ],
              },
            ],
          },
          {
            span: 24,
            items: [
              {
                type: 'simple_upload',
                name: 'cv',
                accept: '.pdf',
                action: '/',
                customRequest: 'customUploadRequest',
                fileList: 'files',
                listType: 'text', // text,picture,picture-card
                onRemove: 'onRemove',
                multiple: false,
                filename: 'file',
                text: "Carica il curriculum pdf",
                showIf: [
                  {
                    type: 'field_validation',
                    field: 'esterno_amministrazione',
                    operator: '==',
                    value: "0",
                  },
                ],
                props: {
                  style: { width: '100%' },
                  showUploadList: true
                },
  
              }
            ]
          }
  
        ],
      });
    }

    if(this.props.infoIspettore || this.props.showTelefono){
      form_rows.push({
        cols: [
          { span: 24,
            items: [
              {
                name: 'titolo_contatti',
                type: 'title',
                element: 'h4',
                text: 'Contatti',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Telefono',
                type_string: 'text', // field type
                name: 'telefono',
                props: {
                  size: 'large',
                  prefix: <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                    message: 'Telefono non valido'
                  }
                ],
              },
            ],
          },

          {
            span: 12,
            items: [
              {
                type: 'input',
                label: 'Cellulare',
                type_string: 'text', // field type
                name: 'cellulare',
                props: {
                  size: 'large',
                  prefix: <Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },

                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                  {
                    pattern: '^([+]{0,1})([0-9 ]{6,50})$',
                    message: 'Cellulare non valido'
                  }
                ],
              },
            ],
          },
        ],
      });
    }

    if(this.props.showResidenza){
      form_rows.push({
        cols: [
          { span: 24,
            items: [
              {
                name: 'titolo_residenza',
                type: 'title',
                element: 'h4',
                text: 'Indirizzo di residenza',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              },
            ],
          },
          { span: 24,
            items: [
              {
                name: 'indirizzo_dati',
                type: 'child',
                child: <div style={{ marginBottom: 16 }}>
                  <React.Fragment>
                    <div
                      ref={(ref) => this.dati_indirizzo_container = ref}
                    >
                      <DatiIndirizzo
                        {...this.props}
                        readOnly={false}
                        dati={{}}
                        ref={(ref) => this.dati_indirizzo = ref}
                        subform={true}
                      ></DatiIndirizzo>
                    </div>
                  </React.Fragment>
                </div>
              }
            ],
          },
        ],

      })
    }

  
    form_rows.push({
      cols: [
        {
          items: [
            {
              name: 'btn',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            },
          ],
        },
      ],
    });

    return {
      layout: 'vertical',
      multistep: false,
      rows: form_rows,
      onSubmit: 'submitted',
    }
  }


  submit(data) {

    if (!this.state.loading) {
      this.setState({ loading: true });

      data.id_anagrafica = this.state.model_anagrafica.id;
      if (this.props.role && this.props.role.length > 0) {
        data.role_name = this.props.role;
      }

      if (this.props.addByEnte) {
        let dati_indirizzo = this.dati_indirizzo? this.dati_indirizzo.formRef.props.form.getFieldsValue() : {};
        
        if(this.props.UserStore.user.entiTutor && this.props.UserStore.user.entiTutor.length === 1){
          data.id_ente = this.props.UserStore.user.entiTutor[0].id
        }
        
        ModelServices.createTutorAziendale({...data, indirizzo:dati_indirizzo}, () => {
          message.success("Tutor aziendale creato con successo");
          this.setState({ loading: false }, () => {
            this.props.onCreate();
          });

        }, () => {
          this.setState({ loading: false });
        });
      } else if (this.props.infoIspettore) {

        if(data.esterno_amministrazione === "0" && (!this.state.fileList || this.state.fileList === null || this.state.fileList.length < 1)){
          message.error("Carica il curriculum");
          this.setState({ loading: false });
          return;
        }

        let file = null;
        if(this.state.fileList && this.state.fileList.length > 0){
          file = this.state.fileList;
        }

        let dati_sede_lavoro = this.dati_sede_lavoro? this.dati_sede_lavoro.formRef.props.form.getFieldsValue() : {};
        data.sede_lavoro = dati_sede_lavoro;
          ModelServices.createIspettore(data, file, () => {
            message.success("Utente creato con successo");
            this.setState({ loading: false }, () => {
              this.props.onCreate();
            });
  
          }, () => {
            this.setState({ loading: false });
          });
      } else {
        ModelServices.create(data, () => {
          message.success("Utente creato con successo");
          this.setState({ loading: false }, () => {
            this.props.onCreate();
          });

        }, () => {
          this.setState({ loading: false });
        });
      }
    }
  }

  getValues(){
    let model = {...this.state.model} 
    if (this.props.role === 'Valutatore' && this.props.infoIspettore) {
      model.esterno_amministrazione = "0"
    }
    return model;
  }

  render() {
    return <div  style={{ opacity: this.state.loading ? 0.5 : 1 }}>
      {this.state.model_utente ?
        <div style={{ textAlign: 'center' }}>
          {this.props.role === "IspettoreAccreditamento" &&
            <p>&Egrave; stato trovato un utente con questo codice fiscale, vuoi aggiungere il ruolo Ispettore?</p>}
          {this.props.role === "Valutatore" &&
             <p>&Egrave; stato trovato un utente con questo codice fiscale, vuoi aggiungere il ruolo Valutatore?</p>}
          <h3>{this.state.model_utente.anagrafica.nome + " " + this.state.model_utente.anagrafica.cognome}</h3>
          <p>{format.formatDate(this.state.model_utente.anagrafica.nato_il, 'DD/MM/YYYY') + " " + this.state.model_utente.anagrafica.nato_a}</p>
          <Button 
           loading={this.state.loading}
           disabled={this.state.loading}
          type="primary" size="large" style={{ marginRight: 12 }} onClick={() => { 
            this.setState({ loading: true })
            ModelServices.addRole(this.state.model_utente.id, {role:this.props.role}, () => {
              message.success("Ruolo aggiunto con successo");
              this.setState({ loading: false }, () => {
                this.props.onCreate();
              });   
            }, () => {
              this.setState({ loading: false });
            });
          }}>Si</Button>
          <Button 
          loading={this.state.loading}
          disabled={this.state.loading}
          type="primary" ghost size="large" style={{ marginRight: 12 }} onClick={() => this.setState({ model_utente: null })}>No</Button>
        </div> :

        !this.state.model_anagrafica.id?
          <CreateAnagrafica
            UserStore={this.props.UserStore}
            GlobalStore={this.props.GlobalStore}
            withMatricola={this.props.withMatricola ? this.props.withMatricola : false}
            returnAnagrafica={(ana) => this.setState({ model_anagrafica: ana })}
            returnUtente={(u) => this.setState({ model_utente: u })}
            role={this.props.role}
          />
          :
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={this.getFormLayout()}
            values={this.getValues()}
            dipartimenti={this.props.GlobalStore.stubs.dipartimenti_regionali}
            settori={this.state.settori_dipartimento || []}
            entiTutor = {this.props.UserStore.user.entiTutor? this.props.UserStore.user.entiTutor.map(e => {
              return {
              key: 'ente_denominazione_' + e.id,
              value: e.id,
              label: e.denominazione
            }}) : []}
            selectedDipartimento={(data) => {
              if(this.formRef) this.formRef.props.form.setFieldsValue({ 'id_settore_regionale': null })
              this.setState({settori_dipartimento : this.props.GlobalStore.stubs.settori_regionali?.filter(el => el.id_dipartimento_regionale === data )})
            }}
            files={()=>this.state.fileList}

            onRemove={() => {

              this.setState ({
                fileList: [],
              });

            }}
            file={this.state.fileName}
            customUploadRequest={obj => {
              this.setState({
                fileList: [{
                    chiave: 'file',
                    uid: obj.file.uid,
                    name: obj.file.name,
                    file: obj.file,
                    status: 'done',
                }],
                fileName: obj.file.name
              });
            }}


            submitted={(data) => this.submit(data)}
          />
      }
    </div>
  }

}
