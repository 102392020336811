import React, { Component } from "react";
import CommonForm from '@common/components/form';

import _ from '@common/utils/formatters/_';

const formLayout = (component) => {
  return {
    layout: 'vertical',
    multistep: false,
    rows: [
      {
        cols: [
          {
            items: [
              {
                name: 'locali_title',
                type: 'title',
                element: 'h4',
                text: 'Locali',
                props: {
                  style: { textAlign: 'left' },
                  className: 'm10h'
                },
              }
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Titolo di possesso :' + component.replaceTipoUso(),
                name: 'locali_possesso'
              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'note_locali_possesso',
                props: {
                },
                validations: [
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: (component.props.sede.utilizzo_esclusivo_formazione) ? 'Sono destinati esclusivamente ad attività formativa? Si' : 'Sono destinati esclusivamente ad attività formativa? No',
                name: 'locali_esclusivi_formazione'
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'note_locali_esclusivi_formazione',
                props: {
                },
                validations: [
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Sono inseriti in struttura condominiale?',
                name: 'locali_struttura_condominio',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'note_locali_struttura_condominio',
                props: {
                },
                validations: [
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Esiste perizia giurata corredata da elaborato grafico in scala 1:200 indicante gli spazi destinati all’attività, redatta da un tecnico iscritto all’albo?',
                name: 'locali_scala_tecnico',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'note_locali_scala_tecnico',
                props: {
                },
                validations: [
                ],
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Numeri totali locali: ' + component.getAuleNum(),
                type_string: 'number', // field type
                name: 'locali_numero_totale'
              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'locali_numero_totale_note',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Numero aule teoriche: ' + component.getAuleTeoriche(),
                type_string: 'number', // field type
                name: 'aule_numero_teorico',

              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'aule_numero_teorico_note',

              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Numero aule teorico pratiche: ' + component.getAuleTeoricoPratiche(),
                type_string: 'number', // field type
                name: 'aule_numero_teorico_pratiche',

              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'aule_numero_teorico_pratiche_note',

              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Numero aule esercitazioni pratiche: ' + component.getAuleLaboratorio(),
                name: 'aule_numero_esercitazioni_pratiche',/*@edit*/

              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'aule_numero_esercitazioni_pratiche_note',/*@edit*/
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Direzione/Segreteria: ' + component.getAuleDirezioneSegreteria(),
                type_string: 'number', // field type
                name: 'aule_numero_direzione',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'aule_numero_direzione_note',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
        ],
      },/*
      {
        cols: [
          {
            items: [
              {
                type: 'input',
                label: 'Segreteria',
                type_string: 'number', // field type
                name: 'aule_numero_segreteria',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'aule_numero_segreteria_note',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
        ],
      },*/
      {
        cols: [
          {
            span: 6,
            items: [
              {
                type: 'integerwithzero',
                label: 'Numero servizi igienici',
                type_string: 'number', // field type
                name: 'aule_numero_servizi_igenici',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
          {
            span: 6,
            items: [
              {
                type: 'integerwithzero',
                label: 'Di cui idonei',
                type_string: 'number', // field type
                name: 'aule_numero_servizi_igenici_idonei', /*@edit*/
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                },
                validations: [{
                  validator: 'maxServizi'
                }]
              },
            ],
          },
          {
            span: 12,
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'aule_numero_servizi_igenici_note',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Locali rispondenti alle norme vigenti in materia di edilizia scolastica',
                name: 'locali_norma_edilizia_scolastica',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'locali_norma_edilizia_scolastica_note',
                props: {
                  //size: 'large',
                  //prefix: <Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />,
                }
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Sono presenti locali adatti ad essere destinati ad aule teoriche o a laboratorio per le esercitazioni pratiche dei corsi che si intendono attivare?',
                name: 'locali_adatti',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'locali_adatti_note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Sono presenti locali rispondenti alle norme di prevenzione degli infortuni e igiene del lavoro, nonché alle condizioni di sicurezza contro i rischi derivanti dagli incendi?',
                name: 'locali_norma_prevenzione_lavoro',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'locali_norma_prevenzione_lavoro_note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Numero degli allievi che possono frequentare contemporaneamente la sede formativa: ' + component.props.sede.numero_totale_allievi,
                type_string: 'number', // field type
                name: 'numero_allievi_contemporaneamente'
              },
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'numero_allievi_contemporaneamente_note'
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Il certificato di agibilità o di collaudo statico è conforme all\'originale?',
                name: 'certificato_agibilita'
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'certificato_agibilita_note'
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'checkbox',
                label: 'Il certificato di rispondenza ai requisiti igienico sanitari è conforme all\'originale?',
                name: 'certificato_igienico_sanitario'
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'certificato_igienico_sanitario_note'
              },
            ],
          }
        ],
      },
      {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Esistono in disegni planimetrici in scala 1:50 o 1:100 dei locali destinati ad aule, reparti, laboratori, ecc. con l’indicazione delle attrezzature e degli arredi?',
                name: 'planimetria_locali_attrezzature',
                option_type: 'button', // normal|button
                // le opzioni come le selects
                options: [
                  {
                    value: "1",
                    label: "Si"
                  },
                  {
                    value: "0",
                    label: "No"
                  }
                ],
                props: {
                  //style: { width: '100%' },
                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio',
                  },
                ],
              }
            ],
          },
          {
            items: [
              {
                type: 'textarea',
                label: 'Note',
                type_string: 'text', // field type
                name: 'planimetria_locali_attrezzature_note'
              },
            ],
          }
        ],
      },
    ],
    onSubmit: 'onSubmit',
  };
}

/**
 * info locali:
 * aula teorica = aula corso
 * aula teorico-pratica = laboratorio
 */

class Locali extends Component {

  state = {
    mounted: false,
    loading: false
  };

  replaceTipoUso() {
    return _.replaceStub(this.props.sede.id_tipologia_uso_locali, 'tipologia_uso_locali', this.props.GlobalStore)
  }

  getAuleNum() {
    return this.props.aule.length
  }

  getAuleTeoriche() {
    return this.props.aule.filter(a => this.props.GlobalStore.stubs.tipo_aula.filter(ta => {
      return (ta.label.toUpperCase() === 'AULA CORSO') ? true : false;
    }).map(ta => ta.value).indexOf(a.id_tab_tipo_aula) !== -1 ).length
  }

  getAuleTeoricoPratiche() {
    return this.props.aule.filter(a => this.props.GlobalStore.stubs.tipo_aula.filter(ta => {
      return (ta.label.toUpperCase() === 'AULA INFORMATICA') ? true : false;
    }).map(ta => ta.value).indexOf(a.id_tab_tipo_aula) !== -1 ).length
  }

  getAuleLaboratorio() {
    return this.props.aule.filter(a => this.props.GlobalStore.stubs.tipo_aula.filter(ta => {
      return (ta.label.toUpperCase() === 'LABORATORIO') ? true : false;
    }).map(ta => ta.value).indexOf(a.id_tab_tipo_aula) !== -1 ).length
  }

  getAuleDirezioneSegreteria() {
    return this.props.aule.filter(a => this.props.GlobalStore.stubs.tipo_aula.filter(ta => {
      return (ta.label.toUpperCase() === 'DIREZIONE/SEGRETERIA') ? true : false;
    }).map(ta => ta.value).indexOf(a.id_tab_tipo_aula) !== -1 ).length
  }


  async validate() {
    return new Promise((resolve) => {
      this.formSelRef.props.form.validateFieldsAndScroll((errors) => {
        if (!errors || errors.length === 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getValues() {
    let obj = {
      ...this.formSelRef.props.form.getFieldsValue()
    };
    return obj;
  }

  render() {
    return <CommonForm
            readOnly={this.props.readOnly}
            wrappedComponentRef={inst => (this.formSelRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout(this)}
            values={this.props.values}
            locali_possesso={this.state.locali_possesso}
            onSubmit={() => null}
            tipicorsi={[]}
            maxServizi={(rule, value, cb) => {
              //console.log('custom validator', rule, value, cb);
              if (value > this.formSelRef.props.form.getFieldValue('aule_numero_servizi_igenici')) {
                cb("Non possono essere pià dei totali");
              } else {
                cb()
              }
            }}
        />
  }
}


export default Locali;