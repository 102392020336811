import React, { Component } from 'react';
import { Icon, Tabs, message } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import checkPermission from '@common/utils/check/permissions';
import ProvaFinaleEsameForm from '../forms/formulario/ProvaFinaleEsameForm';
import DiscentiEsameForm from '../forms/formulario/DiscentiEsameForm';
import SpinnerLoading from "@common/components/SpinnerLoading";
import Esami from '../services/Esami';
import affidamentoStatic from '@common/utils/static/affidamento';

import PropTypes from 'prop-types';

const TabPane = Tabs.TabPane;

export default class SchedaEsami extends Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    id_ente: PropTypes.number,
    refresh: PropTypes.func,
    domanda_json: PropTypes.object,
    progress_obj: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    isEditable: this.props.scheda_progetto_json.valutazioni && this.props.scheda_progetto_json.exam? false : true,
    progettiList: null,
    allprojectList: null,
    loaded: false,
    dati_progetto: this.props.scheda_progetto_json || null

  }

  componentDidMount() {
    if (this.props.id_ente) {
      this.callProjects()
      if (this.isEnte()){
        this.setState({ loaded: true })
        this.setState({ isEditable: false });
      }

    }
    this.setState({ loaded: true })
  }



  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isValidSection(name) {

    if (this.props.section_feedback && this.props.section_feedback[name]) {
      return this.props.section_feedback[name].valid ? 'checked' : 'invalid';
    } else if (this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato && (!this.props.section_feedback || !this.props.section_feedback[name])) {
      return 'default';
    }

    //if (this.props.stato_flusso < affidamentoStatic.stato_flusso_progetto.presentato) {
    if (this.props.progress_obj.valid_sections[name])
      return 'checked';
    else if (this.props.progress_obj.showed_sections[name] && !this.props.progress_obj.valid_sections[name])
      return 'invalid';
    //}

    return 'default';
  }

  saveFeedback(note, valid, section) {
    if (!this.state.loadingFeedback) {
      this.setState({
        loadingFeedback: true,
      }, () => {

        {
          Esami.feedbackSection(
            this.props.id_commissione,
            {
              section: section,
              note: note,
              valid: valid
            },
            (res) => {
              //this.props.refreshInline(res.data.data);
              message.success("La sezione è stata salvata correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data)
              this.setState({ loadingFeedback: false });
            },
            (err) => this.setState({ loadingFeedback: false })
          );
        }
        
      });
    }
  }

  updateProgress(progress) {
    this.props.progress_obj.valid_sections = progress.valid_sections;
    if (this.props.updateProgress) {
      this.props.updateProgress(progress);
    }
  }

  callProjects() {
    this.setState({ loading: true })

    /*

    Esami.allProjects(this.props.id_commissione,{},
      (res) => {
        this.setState({
          progettiList: res.data.data.map(e => {
            return {
                key: e.id,
                value: e.id,
                label: e.titolo_progetto,
                id: e.id
            }
        }),
        allprojectList: res.data.data,
        });
      }, () => this.setState({ loading: false })
    );
    */

    this.setState({ loading: false })
  }

  isEnte() {
    return checkPermission.hasPermission(this.props.UserStore.user, [
      "entePermissions",
    ]);
  }

  SaveData(json) {
    this.setState({ loading: true })

    if(json.exam?.scheda == "exam") {
      json = {
        ...this.state.dati_progetto,
        exam: json.exam
      }

    }

    if(json.discenti?.scheda == "discenti") {
      json = {
        ...this.state.dati_progetto,
        valutazioni:json.discenti.valutazioni
      }
    }




    Esami.SaveData(json, this.props.id_commissione,
      (res) => {
        message.success('Dati salvati con successo');
        this.setState({
          dati: res.data.data,
          progress_obj: res.data.data.progress,
          dati_progetto:res.data.data.scheda_progetto_json
        });
        this.props.updateProgetto(res.data.data.scheda_progetto_json)
        if(res.data.data.scheda_progetto_json.exam && res.data.data.scheda_progetto_json.valutazioni) {
          this.props.refresh()
        }
      },
    )
  }
  


  render() {
    return (<div>
      { !this.state.loaded
      ?
      <SpinnerLoading message='Caricamento dati...' />
      :
      <StickyContainer>
        <Tabs
          onChange={(k) => this.setState({ current_key: "" + k })}
          defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
          {this.props.scheda_progetto_json &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('exam')}>PROVA FINALE ESAME<br /><Icon type="check-circle" /></span>} key="1">
              <div id="exam">
                <div style={{ padding: 14 }}>
                  <ProvaFinaleEsameForm
                    {...this.props}
                    generalData={this.props.scheda_progetto_json}
                    updateProgress={this.updateProgress.bind(this)}
                    readOnly={!this.state.isEditable}
                    progettiList={this.state.progettiList}
                    SaveData={(json) => this.SaveData(json)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    refresh={(reset) => this.props.refresh(reset)}
                    
                />
                </div>
              </div>
            </TabPane>
          }
                    {this.props.scheda_progetto_json &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('valutazioni')}>Discenti <br /><Icon type="check-circle" /></span>} key="3">
              <div id="discenti">
                <div style={{ padding: 14 }}>
                  <DiscentiEsameForm
                    {...this.props}
                    ref = {(ref) => {this.programmazione_didattica_ref = ref}}
                    readOnly={!this.state.isEditable}
                    isEnte={this.isEnte()}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    SaveData={(json) => this.SaveData(json)}
                    refresh={(reset) => this.props.refresh(reset)}

                 />
                </div>
              </div>
            </TabPane>
          }
        </Tabs>
        
      </StickyContainer>
    }</div>);
  }
}

