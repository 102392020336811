import React, { Component } from 'react';
import Wrapper from '../decorators/Wrapper';
import Contatti from "../../assets/Contatti.pdf";


@Wrapper({ route: 'Contatti', hasSidebar: false, hasHeader: true, hasFooter: true })
class ContattiPageRoute extends Component {


  render() {

    return (
      <div>

        <h3>CONTATTI</h3>
        <p><strong>DipartimentoLavoro e Welfare</strong></p>
        <p><strong>Settore 6 - Formazione - Accreditamento e Servizi Ispettivi</strong></p>
        <p>
        <strong>Dirigente del Settore:</strong> Dott. Roberto Cosentino <strong>e-mail:</strong> ro.cosentino@regione.calabria.it<br /><br />
        <strong>Funzionario responsabile: </strong> Laura Zumbo <strong>e-mail:</strong> l.zumbo@regione.calabria.it<br /><br />
        Viale Europa - Cittadella Regionale - Località Germaneto 88100 Catanzaro - Italia <br />
        Apertura al pubblico: lunedì e venerdì dalle 9:30 alle 13:00.<br />
        </p>
        <p>Tel.: +39 0961 8511 - P.E.C.: accreditamento.formazione@pec.regione.calabria.it</p>
        <h4>DOWNLOAD</h4>
        <a href={Contatti} target="_blank" rel="noopener noreferrer">Contatti.pdf</a><br />
      </div>
    );
  }
}

export default ContattiPageRoute;