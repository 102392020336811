import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Steps, notification } from 'antd';

import OrganizationForm from '@accreditamento/forms/request/Organization';
import DocumentsForm from '@accreditamento/forms/request/Documents';
import DocumentsLogoForm from '@accreditamento/forms/request/DocumentsLogo';

import ProvinceService from '@common/services/locations/Province';
import TipoMediaServices from '@common/services/media/TipoMedia';

import AccreditamentoRequest from '@accreditamento/services/Request';

import '../less/Accreditamento.less';

import moment from 'moment';

const delay = ms => new Promise(res => setTimeout(res, ms));

class FormUtentiEnti extends Component {
	
	state = {
		loading: false,
		mounted: false,
		tipi_media: [],
		tipi_organizzazione: [],
		province: [],
		stato: [
			{ key: 1, value: 1, label: "Italia" },
			{ key: 2, value: 2, label: "Estero" }
		],
		values: {},
		organization: {
			cfente: "",
			viaente: "",
			civicoente: "",
			capente: "",
			provinciaente: null,
			comuneente: null,
			statoente: 1,
			partita_iva: "",
			telefonoente: "",
			anno_costituzione: "",
			iscrizione_ccia: "",
			numero_ccia: "",
			data_iscrizione: "",
			denominazione: "",
			email: "",
			pec: "",
			id_tipo_ragione_sociale: null,
			tipologia_ente: null
		},
		document: {
			anno_costituzione: null,
			data_isc_cciaa: null,
			numero_cciaa: "",
			medias: []
		},
	};
	
	componentDidMount() {
		console.warn("FormUtentiEnte componentDidMount", this.props);
		let propsOrganization = this.state.organization;
		let propsDocument = this.state.document;
		if(typeof this.props.element_opened !== 'undefined' && this.props.element_opened !== null){
			propsOrganization = this.props.element_opened.organization;
			propsDocument = this.props.element_opened.document;
			if(propsDocument.anno_costituzione){
				propsDocument.anno_costituzione = moment(propsDocument.anno_costituzione, "MM-YYYY");
			}
			if(propsDocument.data_iscrizione){
				propsDocument.data_iscrizione = moment(propsDocument.data_iscrizione);
			}
			if(propsDocument.dichiarazione_formazione){
				propsDocument.dichiarazione_formazione = propsDocument.dichiarazione_formazione === true ? '1' : '0';
			}
			if(propsDocument.modifiche_statuto){
				propsDocument.modifiche_statuto = propsDocument.modifiche_statuto !== true ? '1' : '0';
			}
			if(propsDocument.accettazione_termini){
				propsDocument.accettazione_termini = propsDocument.accettazione_termini === true ? '1' : '0';
			}
		}

		this.setState({ 
			mounted: true, 
			organization: propsOrganization,
			document: propsDocument
		}, () => {
			ProvinceService.all(res => {
				this.setState({
					province: res.data.data.map(h => {
						return { key: h.id, value: h.id, label: h.provincia + ' ' + h.sigla };
					}),
				});
			});
		});
		
	}
	
	componentWillUnmount() {
		this.setState({ mounted: false });
	}
	
	submitRequest() {
		const userId = this.props.UserStore.user.id;
		this.setState({
			loading: true,
			organization: this.refs.organization_form.getValues(),
			document: this.refs.tipi_media_form.getValues(),
		});
		let values = {
			organization: this.refs.organization_form.getValues(),
			document: this.refs.tipi_media_form.getValues(),
		};
		var val_to_send = Object.assign({}, { ...values });
		val_to_send.document.anno_costituzione = moment(values.document.anno_costituzione).format('MM-YYYY');
		val_to_send.document.data_iscrizione = moment(values.document.data_iscrizione).format('YYYY-MM-DD');
		
		const medias = [...values.document.medias];

		AccreditamentoRequest.registrazioneUtenteEnte(
			userId,
			{
				id:(typeof this.props.element_opened !== 'undefined' && this.props.element_opened !== null) ? this.props.element_opened.id: null,
				...val_to_send.organization,
				...val_to_send.document,
				medias: medias,
				base_url: this.props.GlobalStore.BASE_URL,
			},
			val_to_send.document.files,
			res => {
				this.setState({ loading: false });
				this.props.onUpdate();
				notification.success({message: 'Ente creato con successo'});
				window.scrollTo({top: 0, behavior: 'smooth' });
                delay(1500).then(() => window.location.reload());
			},
			err => {
				//console.log('errore', err);
				this.setState({ loading: false });
			}
		);
	}
			
	render() {
		return (
			<Card className="shadow" bordered={false}>
				{
					this.state.mounted ?
					<>
					<h3 className="sectionTitle" style={{ textAlign: 'center' }}>IL TUO ENTE</h3>
					<OrganizationForm
					values={this.state.organization}
					province={this.state.province}
					tipi_organizzazione={this.state.tipi_organizzazione}
					tipo_media={this.props.GlobalStore.stubs.tipo_media}
					tipi_media={this.props.GlobalStore.stubs.tipo_media}
					stato={this.state.stato}
					ref="organization_form"
					{...this.props}
					/>
					<h3 className="sectionTitle" style={{ textAlign: 'center' }}>DOCUMENTI SOCIETARI</h3>
					<DocumentsForm
					organization={this.state.organization}
					tipo_media={this.props.GlobalStore.stubs.tipo_media}
					id_ente={null}
					medias={this.state.document.medias}
					values={
						{
							...this.state.document, 
							accettazione_termini: (typeof this.props.element_opened !== 'undefined' && this.props.element_opened !== null) ? (this.props.element_opened.accettazione_termini === true ? "1" : "0") : null
						}
					}
					tipi_media={this.props.GlobalStore.stubs.tipo_media}
					ref="tipi_media_form"
					onUpload={(media) => {
						this.setState({
							document: {
								...this.state.document,
								medias: [...this.state.document.medias, media]
							}
						})
					}}
					onDeleteMedia={id => {
						try {
							this.setState({
								document: {
									...this.state.document,
									medias: this.state.document.medias.filter(el => el.id !== id)
								}
							})
						} catch (e) {
							console.error(e);
						}
					}}
					{...this.props}
					/>
					<h3 className="sectionTitle" style={{ textAlign: 'center' }}>LOGO</h3>
					<DocumentsLogoForm
					organization={this.state.organization}
					tipo_media={this.props.GlobalStore.stubs.tipo_media}
					id_ente={null}
					medias={this.state.document.medias}
					values={this.state.document}
					tipi_media={this.props.GlobalStore.stubs.tipo_media}
					ref="tipi_media_form2"
					onUpload={(media) => {
						this.setState({
							document: {
								...this.state.document,
								medias: [...this.state.document.medias, media]
							}
						})
					}}
					onDeleteMedia={id => {
						try {
							this.setState({
								document: {
									...this.state.document,
									medias: this.state.document.medias.filter(el => el.id !== id)
								}
							})
						} catch (e) {
							console.error(e);
						}
					}}
					{...this.props}
					/>
					<Button
					type="primary"
					//loading={this.state.loading}
					size="large"
					onClick={() => this.submitRequest()}
					>
					Conferma i dati
					</Button>
					</>
					: null
				}
				
			</Card>
		);
	}
}
			
export default connect(({UserStore, GlobalStore}) => ({UserStore, GlobalStore}))(FormUtentiEnti);