import React, { Component } from "react";

import eventBroker from "@common/utils/services/EventBroker";
import { Table, Input, Form, Button, InputNumber, Spin } from "antd";
import schedafinanziariaStatic from "@common/utils/static/schedafinanziaria";
import checkPermission from "@common/utils/check/permissions";
import affidamentoStatic from "@common/utils/static/affidamento";

import PropTypes from "prop-types";
import ValidazioneScheda from "@modules/affidamento/components/audit/ValidazioneScheda";

const columns = (component) => {
  const { getFieldDecorator } = component.props.form;

  return [
    {
      title: "Costi del progetto",
      dataIndex: "sectionTitle",
      key: "sectionTitle",
      render: (text, record) => (
        <div style={{ width: 300 }}>{record.title}</div>
      ),
    },
    {
      title: "Costi diretti per il personale",
      dataIndex: "staffDirectCost",
      key: "staffDirectCost",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "_staffDirectCost", {
              initialValue: record.staffDirectCost,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Altri costi diretti",
      dataIndex: "othersDirectCost",
      key: "othersDirectCost",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "_othersDirectCost", {
              initialValue: record.othersDirectCost,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Percentuali di ripartizione dei costi diretti",
      dataIndex: "costDistributionRate",
      key: "costDistributionRate",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "_costDistributionRate", {
              initialValue: record.costDistributionRate,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
  ];
};

const columnsIndiretti = (component) => {
  const { getFieldDecorator } = component.props.form;

  return [
    {
      title: "Costi indiretti del progetto",
      dataIndex: "sectionTitle",
      key: "sectionTitle",
      render: (text, record) => (
        <div style={{ width: 300 }}>{record.title}</div>
      ),
    },
    {
      title: "Costi indiretti",
      dataIndex: "indirectCost",
      key: "indirectCost",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "_indirectCost", {
              initialValue: record.indirectCost,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
                onBlur={() => component.getTotal(record.sectionName)}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: "Percentuali di ripartizione dei costi indiretti",
      dataIndex: "costDistributionRate",
      key: "costDistributionRate",
      render: (text, record) => (
        <div>
          <Form.Item>
            {getFieldDecorator(record.key + "_costDistributionRate", {
              initialValue: record.costDistributionRate,
            })(
              <InputNumber
                type="number"
                min={0}
                disabled={component.props.readOnly}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
  ];
};

class SchedaFinanziariaForm extends Component {
  constructor(props) {
    super(props);
    this.getBackfinancialStatement = this.getBackfinancialStatement.bind(this);
    eventBroker.on("getBackfinancialStatement", this.getBackfinancialStatement);
  }

  static propTypes = {
    updateProgress: PropTypes.func,
  };

  state = {
    loaded: false,
    loaded_source: false,
    loading: true,
    financialStatement: {},
    dataSource: {},
  };

  componentDidMount() {
    this.load(this.props);
  }

  load(props) {
    this.setState(
      {
        loaded: true,
        financialStatement: props.financialStatement,
      },
      () => this.loadDataSource()
    );
  }

  getBackfinancialStatement() {
    return this.formSelRef?.props.form.getFieldsValue();
  }

  loadDataSource() {
    let dataSource = {
      dataSectionB1: [],
      dataSectionB2: [],
      dataSectionB3: [],
      dataSectionB4: [],
      dataSectionC: [],
    };

    let sectionBList = [
      "dataSectionB1",
      "dataSectionB2",
      "dataSectionB3",
      "dataSectionB4",
    ];

    sectionBList.forEach((key) => {
      Object.entries(
        this.state.financialStatement.AfcProjectCostsFormSection[key]
      ).forEach(([key_child, value_child]) => {
        dataSource[key].push({
          key: key_child,
          title: schedafinanziariaStatic.sectionMap[key_child],
          sectionName: key,
          staffDirectCost: value_child.staffDirectCost,
          othersDirectCost: value_child.othersDirectCost,
          costDistributionRate: value_child.costDistributionRate,
        });
      });
    });

    Object.entries(
      this.state.financialStatement.AfcProjectCostsFormSection.dataSectionC
    ).forEach(([key_child, value_child]) => {
      dataSource.dataSectionC.push({
        key: key_child,
        sectionName: "dataSectionC",
        title: schedafinanziariaStatic.sectionMap[key_child],
        indirectCost: value_child.indirectCost,
        costDistributionRate: value_child.costDistributionRate,
      });
    });

    this.setState({
      loaded_source: true,
      loading: false,
      dataSource: dataSource,
    });
  }

  getTotal(sectionName) {
    let sumDirect = 0;
    let sumOther = 0;
    let sumIndirect = 0;
    switch (sectionName) {
      case "dataSectionB1":
        this.state.dataSource.dataSectionB1.forEach((el) => {
          sumDirect =
            sumDirect +
            this.props.form.getFieldValue(el.key + "_staffDirectCost");
          sumOther =
            sumOther +
            this.props.form.getFieldValue(el.key + "_othersDirectCost");
        });

        this.props.form.setFieldsValue({
          totalDirectB1: sumDirect,
          totalOtherB1: sumOther,
        });

        break;
      case "dataSectionB2":
        this.state.dataSource.dataSectionB2.forEach((el) => {
          sumDirect =
            sumDirect +
            this.props.form.getFieldValue(el.key + "_staffDirectCost");
          sumOther =
            sumOther +
            this.props.form.getFieldValue(el.key + "_othersDirectCost");
        });

        this.props.form.setFieldsValue({
          totalDirectB2: sumDirect,
          totalOtherB2: sumOther,
        });
        break;
      case "dataSectionB3":
        this.state.dataSource.dataSectionB3.forEach((el) => {
          sumDirect =
            sumDirect +
            this.props.form.getFieldValue(el.key + "_staffDirectCost");
          sumOther =
            sumOther +
            this.props.form.getFieldValue(el.key + "_othersDirectCost");
        });

        this.props.form.setFieldsValue({
          totalDirectB3: sumDirect,
          totalOtherB3: sumOther,
        });
        break;
      case "dataSectionB4":
        this.state.dataSource.dataSectionB4.forEach((el) => {
          sumDirect =
            sumDirect +
            this.props.form.getFieldValue(el.key + "_staffDirectCost");
          sumOther =
            sumOther +
            this.props.form.getFieldValue(el.key + "_othersDirectCost");
        });

        this.props.form.setFieldsValue({
          totalDirectB4: sumDirect,
          totalOtherB4: sumOther,
        });
        break;
      case "dataSectionC":
        this.state.dataSource.dataSectionC.forEach((el) => {
          sumIndirect =
            sumIndirect +
            this.props.form.getFieldValue(el.key + "_indirectCost");
        });

        this.props.form.setFieldsValue({
          totalIndirect: sumIndirect,
        });
        break;
      default:
        break;
    }

    let totalDirect =
      this.props.form.getFieldValue("totalDirectB1") +
      this.props.form.getFieldValue("totalOtherB1") +
      this.props.form.getFieldValue("totalDirectB2") +
      this.props.form.getFieldValue("totalOtherB2") +
      this.props.form.getFieldValue("totalDirectB3") +
      this.props.form.getFieldValue("totalOtherB3") +
      this.props.form.getFieldValue("totalDirectB4") +
      this.props.form.getFieldValue("totalOtherB4");
    this.props.form.setFieldsValue({
      totalDirect: totalDirect,
    });

    let total = totalDirect + this.props.form.getFieldValue("totalIndirect");
    this.props.form.setFieldsValue({
      total: total,
    });
  }

  handleSubmit = (e) => {
    /*this.setState({
      loading: true,
      loaded: false
    });*/

    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let financialStatement = this.state.financialStatement;

        financialStatement.AfcProjectCostsFormSection.dataSectionA =
          values.ricavi;

        financialStatement.AfcProjectCostsFormSection.totalDirectB1 =
          values.totalDirectB1;
        financialStatement.AfcProjectCostsFormSection.totalOtherB1 =
          values.totalOtherB1;

        financialStatement.AfcProjectCostsFormSection.totalDirectB2 =
          values.totalDirectB2;
        financialStatement.AfcProjectCostsFormSection.totalOtherB2 =
          values.totalOtherB2;

        financialStatement.AfcProjectCostsFormSection.totalDirectB3 =
          values.totalDirectB3;
        financialStatement.AfcProjectCostsFormSection.totalOtherB3 =
          values.totalOtherB3;

        financialStatement.AfcProjectCostsFormSection.totalDirectB4 =
          values.totalDirectB4;
        financialStatement.AfcProjectCostsFormSection.totalOtherB4 =
          values.totalOtherB4;

        financialStatement.AfcProjectCostsFormSection.totalDirect =
          values.totalDirect;
        financialStatement.AfcProjectCostsFormSection.totalIndirect =
          values.totalIndirect;
        financialStatement.AfcProjectCostsFormSection.total = values.total;

        let sectionBList = [
          "dataSectionB1",
          "dataSectionB2",
          "dataSectionB3",
          "dataSectionB4",
        ];

        sectionBList.forEach((section) => {
          Object.entries(
            this.state.financialStatement.AfcProjectCostsFormSection[section]
          ).forEach(([key_child, value_child]) => {
            let data = {
              staffDirectCost: values[key_child + "_staffDirectCost"],
              othersDirectCost: values[key_child + "_othersDirectCost"],
              costDistributionRate: values[key_child + "_costDistributionRate"],
            };
            financialStatement.AfcProjectCostsFormSection[section][key_child] =
              data;
          });
        });

        Object.entries(
          this.state.financialStatement.AfcProjectCostsFormSection.dataSectionC
        ).forEach(([key_child, value_child]) => {
          let data = {
            indirectCost: values[key_child + "_indirectCost"],
            costDistributionRate: values[key_child + "_costDistributionRate"],
          };
          financialStatement.AfcProjectCostsFormSection.dataSectionC[
            key_child
          ] = data;
        });
        let domanda = this.props.domanda_json;
        domanda.financialStatement = financialStatement;

        this.props.saveJson(domanda);

        /*ModelServices.save_json(domanda, this.props.match.params.id,
           (res) => {
             if (this.props.updateProgress) {
               this.props.updateProgress(res.data.data.progress);
               this.props.refresh(false);
               message.success('Dati salvati con successo');
             }
             this.loadDataSource();
             this.setState({
               loading: false,
               loaded: true,
               financialStatement: res.data.data.domanda_json.financialStatement
             });
           },
           () => {
             this.setState({
               loading: false,
               loaded: true
             });
           }
         );*/
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      this.state.loaded_source && (
        <div style={{ opacity: !this.props.saved ? 0.5 : 1 }}>
          {!this.props.saved ? <Spin /> : null}
          {checkPermission.hasPermission(this.props.UserStore.user, [
            "viewValutazioneScheda",
          ]) && (
            <ValidazioneScheda
              canGiveFeedback={
                this.props.canGiveSectionFeedback &&
                this.props.stato_flusso ===
                  affidamentoStatic.stato_flusso_progetto.audit_iniziato
              }
              loadingFeedback={this.props.loadingFeedback}
              saveFeedback={(note, valid) =>
                this.props.saveFeedback(note, valid, "financialStatement")
              }
              feedback={
                this.props.section_feedback
                  ? this.props.section_feedback["financialStatement"]
                  : null
              }
            />
          )}

            <Form onSubmit={this.handleSubmit}>
              <h4 className="m20h">Totale ricavi </h4>
              <Form.Item
                colon={false}
                label="Contributo pubblico + cofinanziamento privato"
              >
                {getFieldDecorator(`ricavi`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .dataSectionA,
                })(<InputNumber disabled={this.props.readOnly} />)}
              </Form.Item>
              <h4 className="m20h">Costi dell’operazione o del progetto </h4>
              <h5 className="m20h subtitle">Preparazione </h5>
              <Table
                loading={this.props.saving}
                columns={columns(this)}
                showPagination={false}
                pagination={false}
                dataSource={this.state.dataSource.dataSectionB1}
                rowKey="key"
              />
              <Form.Item
                colon={false}
                label="Totale costi diretti della preparazione"
              >
                {getFieldDecorator(`totalDirectB1`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalDirectB1,
                })(<Input disabled={true} placeholder="TOTALE PREPARAZIONE" />)}
              </Form.Item>
              <Form.Item
                colon={false}
                label="Totale altri costi diretti della preparazione"
              >
                {getFieldDecorator(`totalOtherB1`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalOtherB1,
                })(<Input disabled={true} placeholder="TOTALE PREPARAZIONE" />)}
              </Form.Item>
              <h5 className="m20h subtitle">Realizzazione</h5>
              <Table
                loading={this.props.saving}
                columns={columns(this)}
                showPagination={false}
                pagination={false}
                dataSource={this.state.dataSource.dataSectionB2}
                rowKey="key"
              />

              <Form.Item
                colon={false}
                label="Totale costi diretti della realizzazione"
              >
                {getFieldDecorator(`totalDirectB2`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalDirectB2,
                })(
                  <Input disabled={true} placeholder="TOTALE REALIZZAZIONE" />
                )}
              </Form.Item>
              <Form.Item
                colon={false}
                label="Totale altri costi diretti della realizzazione"
              >
                {getFieldDecorator(`totalOtherB2`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalOtherB2,
                })(
                  <Input disabled={true} placeholder="TOTALE REALIZZAZIONE" />
                )}
              </Form.Item>

              <h5 className="m20h subtitle">Diffusione risultati</h5>
              <Table
                loading={this.props.saving}
                columns={columns(this)}
                showPagination={false}
                pagination={false}
                dataSource={this.state.dataSource.dataSectionB3}
                rowKey="key"
              />
              <Form.Item
                colon={false}
                label="Totale costi diretti della diffusione dei risultati"
              >
                {getFieldDecorator(`totalDirectB3`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalDirectB3,
                })(
                  <Input
                    disabled={true}
                    placeholder="TOTALE DIFFUSIONE DEI RISULTATI"
                  />
                )}
              </Form.Item>
              <Form.Item
                colon={false}
                label="Totale altri costi diretti diffusione dei risultati"
              >
                {getFieldDecorator(`totalOtherB3`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalOtherB3,
                })(
                  <Input disabled={true} placeholder="TOTALE REALIZZAZIONE" />
                )}
              </Form.Item>
              <h5 className="m20h subtitle">
                Direzione progetto e controllo interno
              </h5>
              <Table
                loading={this.props.saving}
                columns={columns(this)}
                showPagination={false}
                pagination={false}
                dataSource={this.state.dataSource.dataSectionB4}
                rowKey="key"
              />
              <Form.Item
                colon={false}
                label="Totale costi diretti della direzione progetto e controllo interno"
              >
                {getFieldDecorator(`totalDirectB4`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalDirectB4,
                })(
                  <Input
                    disabled={true}
                    placeholder="TOTALE DIFFUSIONE DEI RISULTATI"
                  />
                )}
              </Form.Item>
              <Form.Item
                colon={false}
                label="Totale altri costi diretti della direzione progetto e controllo interno"
              >
                {getFieldDecorator(`totalOtherB4`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalOtherB4,
                })(
                  <Input
                    disabled={true}
                    placeholder="TOTALE DIFFUSIONE DEI RISULTATI"
                  />
                )}
              </Form.Item>
              <h4 className="m20h">totale costi diretti del progetto</h4>
              <Form.Item colon={false} label="Totale costi diretti">
                {getFieldDecorator(`totalDirect`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalDirect,
                })(
                  <Input disabled={true} placeholder="TOTALE COSTI DIRETTI" />
                )}
              </Form.Item>
              <h4 className="m20h">Costi indiretti </h4>
              <Table
                loading={this.props.saving}
                columns={columnsIndiretti(this)}
                dataSource={this.state.dataSource.dataSectionC}
                showPagination={false}
                pagination={false}
                rowKey="key"
              />
              <h4 className="m20h">totale costi indiretti del progetto</h4>
              <Form.Item colon={false} label="Totale costi indiretti">
                {getFieldDecorator(`totalIndirect`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .totalIndirect,
                })(
                  <Input disabled={true} placeholder="TOTALE COSTI INDIRETTI" />
                )}
              </Form.Item>
              <h4 className="m20h">totale costo dell'operazione</h4>
              <Form.Item colon={false} label="Totale">
                {getFieldDecorator(`total`, {
                  initialValue:
                    this.state.financialStatement.AfcProjectCostsFormSection
                      .total,
                })(<Input disabled={true} placeholder="TOTALE" />)}
              </Form.Item>
              {!this.props.anteprima && !this.props.readOnly && (
                <Button
                  htmlType="submit"
                  disabled={this.props.readOnly}
                  loading={this.state.loading}
                  type="primary"
                  icon="save"
                  className="m5h bg-green"
                >
                  Salva i dati
                </Button>
              )}
            </Form>
        
        </div>
      )
    );
  }
}

//export default SchedaFinanziariaForm;
export default Form.create()(SchedaFinanziariaForm);
