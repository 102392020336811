

import { Card, Row, Col, Button, Modal, Progress, Popconfirm, message, Tabs, Upload, Icon } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ModelServices from '../services/RichiesteQualifiche';
import AuditService from '../services/AuditRichiesta'
import FileSaver from 'file-saver';
import eventBroker from '@common/utils/services/EventBroker';
import repertorioStatic from '@common/utils/static/repertorio';
import DownloadUpload from "@common/components/DownloadUpload";
import DocSpan from "@common/components/DocSpan";
import checkPermission from '@common/utils/check/permissions';
import AssegnazioneIstruttore from "./assegnazione/AssegnazioneIstruttore";
import auditStatic from '@common/utils/static/audit';
import AuditDocumentale from "@modules/affidamento/components/audit/AuditDocumentale";
import EsitoProgetto from "@modules/affidamento/components/EsitoProgetto";
import Controdeduzioni from "@modules/affidamento/components/controdeduzioni/Controdeduzioni";
import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';
import moment from 'moment';
import CommonForm from '@common/components/form';

const formNoteLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};

const TabPane = Tabs.TabPane;

const getStato = (richiesta, component) => {

  if (!richiesta.stato_flusso_description) return '';
  let stato = 'ente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) stato = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) stato = 'istruttore';
  //if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) stato = 'ispettore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['gtrPermissions'])) stato = 'gtr';
  return richiesta.stato_flusso_description['stato_' + stato];
}

const getDescription = (record, component) => {
  if (!record.stato_flusso_description) return ''
  let user = 'ente';

  if (checkPermission.hasPermission(component.props.UserStore.user, ['dirigentePermissions'])) user = 'dirigente';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['istruttorePermissions'])) user = 'istruttore';
 // if (checkPermission.hasPermission(component.props.UserStore.user, ['ispettorePermissions'])) user = 'ispettore';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['tecnicoGtrPermissions'])) user = 'tecnico_gtr';
  if (checkPermission.hasPermission(component.props.UserStore.user, ['coordinatoreGtrPermissions'])) user = 'coordinatore_gtr';

  return record.stato_flusso_description['descrizione_' + user];
}

export default class PannelloStatoRichiestaRepertorio extends Component {

  static propTypes = {
    lesson: PropTypes.object,
    isNextLesson: PropTypes.bool,
    loading: PropTypes.bool,
  }

  state = {
    loading: false,
    data: {},
    progress_obj: { progress: 0 },
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.repertorio !== this.props.repertorio) {
      this.loaded(this.props);
    }
  }

  componentDidMount() {
    if (this.props.repertorio) this.loaded(this.props);
  }

  loaded(props) {
    this.setState({ data: props.repertorio, progress_obj: props.repertorio?.progress || { progress: 0 } });
  }

  saveBlob(blob, title) {
    const _blob = new Blob([blob], { type: 'application/pdf' });
    FileSaver.saveAs(_blob, title);
  }

  endLoading() {
    this.setState({
      confirming: false,
      loading: false,
      downloading: false,
      uploading: false,
      validating: false,
      requiring: false,
      open_modal_gtr_refuse: false,
      suspending: false,
      evaluating: false,
      ristretto: false,
      allargato: false,
      completing: false
    })
  }

  renderFileFirmato() {

    let media = this.state.data?.media?.filter(el => el.tipo.sottodominio === repertorioStatic.tipo_media.sottodominio_richiesta_firmata);

    return media && <div>
      {
        media.map(l => {
          return <div key={'com_ost_' + l.id}>
            <p style={{ display: 'inline-block' }}>Richiesta firmata: </p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }

  renderRichiestaIntegrazione() {

    let media = this.state.data?.media?.filter(el => el.tipo.sottodominio === repertorioStatic.tipo_media.richiesta_integrazione_firmata);

    return media && <div>
      {
        media.map(l => {
          return <div key={'com_ost_' + l.id}>
            <p style={{ display: 'inline-block' }}>Richiesta integrazione firmata <span>{moment.unix(l.created_at).format('DD/MM/YYYY')}</span>: </p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }

  renderDecreto() {

    let media = this.state.data?.media?.filter(el => el.tipo.sottodominio === repertorioStatic.tipo_media.sottodominio_decreto);

    return media && <div>
      {
        media.map(l => {
          return <div key={'com_ost_' + l.id}>
            <p style={{ display: 'inline-block' }}>Decreto dirigenziale di Aggiornamento del Repertorio Regionale : </p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }

  renderFileRicevutaInvioProposta() {

    let media = this.state.data?.media?.filter(el => el.tipo.sottodominio === repertorioStatic.tipo_media.sottodominio_ricevuta_invio_proposta);

    return media && <div>
      {
        media.map(l => {
          return <div key={'ric_rep_' + l.id}>
            <p style={{ display: 'inline-block' }}>Ricevuta invio proposta <span>{moment.unix(l.created_at).format('DD/MM/YYYY')}</span>: </p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }

  renderVerbaleTavoloTecnico() {

    let media = this.state.data?.media?.filter(el => el.tipo.sottodominio === repertorioStatic.tipo_media.sottodominio_verbale_tavolo_tecnico);

    return media && <div>
      {
        media.map(l => {
          return <div key={'ric_rep_' + l.id}>
            <p style={{ display: 'inline-block' }}>Verbale dell'incontro del tavolo tecnico <span>{moment.unix(l.created_at).format('DD/MM/YYYY')}</span>: </p>
            <DocSpan key={l.id} file={l} />
          </div>
        })
      }
    </div>
  }

  upload(file) {
    if (!this.state.uploading) {
      this.setState({
        uploading: true,
        loading: true
      }, () => {
        ModelServices.uploadModuloRichiesta(
          this.state.data.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.endLoading();
            message.success("La richiesta è stata caricata correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.endLoading()
        )
      })
    }
  }

  uploadIntegrazioni(file) {
    if (!this.state.uploading) {
      this.setState({
        uploading: true,
        loading: true
      }, () => {
        ModelServices.uploadModuloRichiestaIntegrazione(
          this.state.data.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.endLoading();
            message.success("La richiesta è stata caricata correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.endLoading()
        )
      })
    }
  }

  uploadVerbaleGtr(file) {
    if (!this.state.uploading) {
      this.setState({
        uploading: true,
        loading: true
      }, () => {
        ModelServices.uploadVerbaleGtr(
          this.state.data.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.endLoading();
            message.success("Il verbale è stato caricato correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.endLoading()
        )
      })
    }
  }

  sectiontMappingGetName(name) {
    let o = {
      generalData: 'Dati identificativi',
      attachments: 'Allegati',
    }
    return o[name];
  }

  propertyLabels(name) {
    let p = {
      ambito_attivita: "Ambito di attività",
      capacita_abilita: "Capacità-abilità",
      categoria: "Categoria di appartenenza",
      collocazione_contrattuale: "Collocazione contrattuale",
      collocazione_organizzativa: "Collocazione organizzativa",
      conoscenze: "Conoscenze",
      decreto_autorizzativo: "Decreto autorizzativo",
      denominazione: "Denominazione soggetto proponente",
      denominazione_figura: "Denominazione Figura",
      descrizione: "Descrizione",
      descrizione_performance: "Descrizione della performance",
      descrizione_richiesta: "Descrizione richiesta",
      esempi_denominazione_figura: "Esempi di possibili denominazioni ricorrenti nel mondo del lavoro",
      id_settore: "Settore di riferimento",
      ifts: "Repertorio nazionale delle figure per i percorsi IFTS",
      livello_complessita: "Livello di complessità",
      opportunita_mercato_lavoro: "Opportunità sul mercato del lavoro",
      percorsi_formativi: "Percorsi formativi",
      periodo_validita: "Periodo di validità",
      tipologia_rapporti_lavoro: "Tipologia rapporti di lavoro",
      figura_professionale: "Figura professionale",

      //allegati
      relazione_descrittiva: "Relazione descrittiva",
      dichiarazione_di_interesse: 'Dichiarazione di interesse',
    }
    return p[name];
  }

  errorsTranslations(name) {
    let e = {
      CANNOT_BE_NULL: "non può essere vuoto",
      WRONG_VALUE: "valore non valido"
    }

    return e[name];
  }

  startAudit() {
    if (!this.state.starting) {
      this.setState({ starting: true }, () => {
        AuditService.start(
          this.state.data.id,
          (res) => {
            message.success("L'audit è iniziato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
            this.setState({ starting: false });
          },
          () => {
            this.setState({ starting: false });
          });
      });
    }
  }

  approveControdeduzioni() {
    if (!this.state.approving_controdeduzioni) {
      this.setState({
        approving_controdeduzioni: true
      }, () => {
        ModelServices.approveControdeduzioni(
          this.state.data.id,
          (res) => {
            this.setState({ approving_controdeduzioni: false })
            message.success("La richiesta è stata approvata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ approving_controdeduzioni: false })
        )
      })
    }
  }

  approveControdeduzioniGtr() {
    if (!this.state.approving_controdeduzioni) {
      this.setState({
        approving_controdeduzioni: true
      }, () => {
        ModelServices.approveControdeduzioniGtr(
          this.state.data.id,
          (res) => {
            this.setState({ approving_controdeduzioni: false })
            message.success("La richiesta è stata approvata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ approving_controdeduzioni: false })
        )
      })
    }
  }

  prepareReject() {
    if (!this.state.rejecting) {
      this.setState({
        rejecting: true
      }, () => {
        ModelServices.prepareReject(
          this.state.data.id,
          (res) => {
            this.setState({ rejecting: false })
            message.success("La richiesta è stata rigettata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ rejecting: false })
        )
      })
    }
  }

  prepareRejectGtr() {
    if (!this.state.rejecting) {
      this.setState({
        rejecting: true
      }, () => {
        ModelServices.prepareRejectGtr(
          this.state.data.id,
          (res) => {
            this.setState({ rejecting: false })
            message.success("La richiesta è stata rigettata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ rejecting: false })
        )
      })
    }
  }

  confirmReject() {
    if (!this.state.rejecting) {
      this.setState({
        rejecting: true
      }, () => {
        ModelServices.confirmReject(
          this.state.data.id,
          (res) => {
            this.setState({ rejecting: false })
            message.success("La richiesta è stata rigettata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ rejecting: false })
        )
      })
    }
  }


  getInfoAndValidate() {
    if (!this.state.getting_info) {
      this.setState({ getting_info: true });
      let json = this.state.data.json;
      //Recupero il form dei dati identificativi
      let datiIdentificativi = eventBroker.trigger('getBackDatiIdentificativi');
      if (datiIdentificativi)
        json = { ...json, ...datiIdentificativi };

      ModelServices.info_and_validate(json, this.props.match.params.id_repertorio,
        res => {

          if (res.data.data.valid) {
            this.setState({ show_completed: true, getting_info: false });
          } else {
            this.setState({
              progress_obj: res.data.data,
              show_errors: true,
              getting_info: false
            })
          }

        },
        err => this.setState({
          getting_info: false
        })
      )
    }
  }

  renderDescriptionText(data) {
    if (data.stato === repertorioStatic.stato.predisposizione && data.progress && data.progress.progress === 100) {
      return (<div>

        <Popconfirm placement="top" title="Confermi i dati inseriti?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.confirm(
                  data.id,
                  (res) => {
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Conferma lavorazione</Button>
        </Popconfirm>
      </div>)
    } else if (data.stato === repertorioStatic.stato.compilata || data.stato === repertorioStatic.stato.da_firmare) {
      return (<div>
        <div>
          <DownloadUpload
            accept={data.validation.fileExtToSend}
            downloading={this.state.downloading}
            uploading={this.state.uploading}
            disabled_upload={data.stato === repertorioStatic.stato.compilata}
            download={() => {
              this.setState({ downloading: true }, () => {
                ModelServices.downloadModuloRichiesta(
                  data.id,
                  (res) => {
                    this.saveBlob(res.data, "modulo-richiesta.pdf");
                    this.props.refresh();
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              })
            }}
            upload={(file) => this.upload(file)}
          >
          </DownloadUpload>

        </div>



      </div>)
    } else if (data.stato === repertorioStatic.stato.pronta_invio) {
      return (<div>
        <Popconfirm placement="top" title="Sei sicuro di voler inviare la richiesta?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.send(
                  data.id,
                  (res) => {
                    message.success("La richiesta è stata inoltrata correttamente");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Invia all'amministrazione</Button>
        </Popconfirm>
      </div>)
    } else if (data.stato_flusso === repertorioStatic.stato_flusso.accepted && checkPermission.hasPermission(this.props.UserStore.user, ['activateProcedureRichiestaRepertorio']) && data.progress && data.progress.progress === 100) {

      return (<div>
        <Popconfirm placement="top" title="Sei sicuro di voler attivare la procedura semplificata?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                validating: true,
                loading: true
              }, () =>
                ModelServices.proceduraSemplificata(
                  data.id,
                  (res) => {
                    message.success("La procedura semplificata è stata attivata correttamente");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.validating}
            className="m5h" type="primary" icon="check">Attiva procedura semplificata</Button>
        </Popconfirm>
        <Popconfirm placement="top" title="Sei sicuro di voler attivare il GTR?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                evaluating: true,
                loading: true
              }, () =>
                ModelServices.proceduraStandard(
                  data.id,
                  (res) => {
                    message.success("Il GTR è stato attivato correttamente");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.evaluating}
            className="m5h m10w" icon="check"
            type="primary">Invia richiesta al Gruppo Tecnico</Button>
        </Popconfirm>
      </div>)
    } else if (data.stato_flusso === repertorioStatic.stato_flusso.confirmed_integration_suspend && checkPermission.hasPermission(this.props.UserStore.user, ['AddIntegrazioneDocumentale'])) {
      return (<div>
        <Popconfirm placement="top" title="Confermi le integrazioni?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.confirmIntegration(
                  data.id,
                  (res) => {
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Conferma l'integrazione</Button>
        </Popconfirm>
      </div>)

    } else if (data.stato_flusso === repertorioStatic.stato_flusso.integration_needed_sign && checkPermission.hasPermission(this.props.UserStore.user, ['AddIntegrazioneDocumentale'])) {
      return (<div>
        <DownloadUpload
          accept={data.validation.fileExtToSend}
          downloading={this.state.downloading}
          uploading={this.state.uploading}
          download={() => {
            this.setState({ downloading: true }, () => {
              ModelServices.downloadModuloRichiestaIntegrazione(
                data.id,
                (res) => {
                  this.saveBlob(res.data, "modulo-richiesta-integrazioni.pdf");
                  this.props.refresh();
                  this.endLoading();
                },
                () => {
                  this.endLoading();
                })
            })
          }}
          upload={(file) => this.uploadIntegrazioni(file)}
        >
        </DownloadUpload>
      </div>)
    } else if (data.stato_flusso === repertorioStatic.stato_flusso.integration_signed && checkPermission.hasPermission(this.props.UserStore.user, ['AddIntegrazioneDocumentale'])) {
      return (<div>
        <Popconfirm placement="top" title="Sei sicuro di voler inviare la richiesta?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                confirming: true,
                loading: true
              }, () =>
                ModelServices.sendIntegrazione(
                  data.id,
                  (res) => {
                    message.success("La richiesta è stata inoltrata correttamente");
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.confirming}
            className="m5h" type="primary" icon="check">Invia all'amministrazione</Button>
        </Popconfirm>
      </div>)
    }
    else if (this.state.data.stato_flusso === repertorioStatic.stato_flusso.evaluation && checkPermission.hasPermission(this.props.UserStore.user, ['requestMoreInfoRichiesta'])) {
      return (<div>
        <p>
          <Popconfirm placement="top" title="Confermi?"
            onConfirm={() => {
              if (!this.state.loading) {
                this.setState({
                  confirming: true,
                  loading: true
                }, () =>
                  ModelServices.gtrValidate(
                    data.id,
                    (res) => {
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    })
                )
              }
            }}
            okText="Si" cancelText="No">
            <Button className="m5w"
              style={{ marginRight: 12 }}
              loading={this.state.confirming}
              disabled={this.state.loading}
              icon="check"
              type="primary">
              Valida
        </Button>
          </Popconfirm>

          <Popconfirm placement="top" title="Confermi?"
            onConfirm={() => {
              if (!this.state.loading) {
                this.setState({
                  completing: true,
                  loading: true
                }, () =>
                  ModelServices.gtrCompletion(
                    data.id,
                    (res) => {
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    })
                )
              }
            }}
            okText="Si" cancelText="No">
            <Button className="m5w"
              style={{ marginRight: 12 }}
              loading={this.state.completing}
              disabled={this.state.loading}
              icon="check"
              type="primary" ghost>
              Valida con riserva
        </Button>
          </Popconfirm>



          <Button className="m5w"
            style={{ marginRight: 12 }}
            loading={this.state.suspending}
            onClick={() => this.setState({ open_modal_gtr_refuse: true })}
            disabled={this.state.loading}
            type="danger" ghost icon="close">
            Rigetta
        </Button>
          {!data.tavolo_tecnico &&
            <Popconfirm placement="top" title="Confermi?"
              onConfirm={() => {
                if (!this.state.loading) {
                  this.setState({
                    requiring: true,
                    loading: true
                  }, () =>
                    ModelServices.gtrApprofondimento(
                      data.id,
                      (res) => {
                        this.props.refreshInline(res.data.data);
                        this.endLoading();
                      },
                      () => {
                        this.endLoading();
                      })
                  )
                }
              }}
              okText="Si" cancelText="No">
              <Button className="m5w"
                style={{ marginRight: 12 }}
                loading={this.state.requiring}
                disabled={this.state.loading}
                icon="check"
                type="primary">
                Richiedi approfondimento
        </Button>
            </Popconfirm>
          }


          <Modal
            title={"Inserisci i motivi del rifiuto"}
            visible={this.state.open_modal_gtr_refuse}
            onOk={async () => {

              if (!this.formNoteRef.props.form.getFieldsValue()['note'] || this.formNoteRef.props.form.getFieldsValue()['note'] === '') {
                message.error('Inserisci le note');
                return;
              }

              if (!this.state.loading) {
                this.setState({
                  suspending: true,
                  loading: true,
                  open_modal_gtr_refuse: false
                }, () =>
                  ModelServices.gtrSuspend(
                    data.id, { note: this.formNoteRef.props.form.getFieldsValue()['note'] },
                    (res) => {
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    })
                )
              }
            }
            }
            onCancel={() => this.setState({ open_modal_gtr_refuse: false })}
          >
            <CommonForm
              wrappedComponentRef={inst => (this.formNoteRef = inst)}
              loading={this.state.loading || false}
              form_model={formNoteLayout}
              values={{}}
            />
          </Modal>


        </p>
      </div>)
    } else if (this.state.data.stato_flusso === repertorioStatic.stato_flusso.gtr_deepening && checkPermission.hasPermission(this.props.UserStore.user, ['evaluateRichiesta'])) {
      return (<div>
        <Popconfirm placement="top" title="Confermi?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                ristretto: true,
                loading: true
              }, () =>
                ModelServices.gtrTavoloTecnico(
                  data.id, { data: 1 },
                  (res) => {
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            style={{ marginRight: 12 }}
            disabled={this.state.loading}
            loading={this.state.ristretto}
            className="m5h" type="primary" icon="check">In composizione ristretta</Button>
        </Popconfirm>

        <Popconfirm placement="top" title="Confermi?"
          onConfirm={() => {
            if (!this.state.loading) {
              this.setState({
                allargato: true,
                loading: true
              }, () =>
                ModelServices.gtrTavoloTecnico(
                  data.id, { data: 2 },
                  (res) => {
                    this.props.refreshInline(res.data.data);
                    this.endLoading();
                  },
                  () => {
                    this.endLoading();
                  })
              )
            }
          }}
          okText="Si" cancelText="No">
          <Button
            disabled={this.state.loading}
            loading={this.state.allargato}
            className="m5h" type="primary" icon="check">In composizione allargata</Button>
        </Popconfirm>
      </div>)
    } else if (this.state.data.stato_flusso === repertorioStatic.stato_flusso.gtr_tavolo_tecnico && checkPermission.hasPermission(this.props.UserStore.user, ['evaluateRichiesta'])) {
      return (<div>

        <p>
          <Upload
            accept={'.p7m,.pdf'}
            multiple={false}
            fileList={null}
            customRequest={obj => {
              this.uploadVerbaleGtr(obj.file);
            }}
            {...this.props} >
            <Button className="m5w"
              disabled={this.state.uploading || false}
              loading={this.state.uploading || false} >
              <Icon type="upload" />{"Carica"}
            </Button>
          </Upload>
        </p>
      </div>)
    } else if (this.state.data.stato_flusso === repertorioStatic.stato_flusso.gtr_completion && checkPermission.hasPermission(this.props.UserStore.user, ['completeRichiesta'])) {
      return (<div>
        <p>
          <Popconfirm placement="top" title="Confermi?"
            onConfirm={() => {
              if (!this.state.loading) {
                this.setState({
                  completing: true,
                  loading: true
                }, () =>
                  ModelServices.gtrEndCompletion(
                    data.id,
                    (res) => {
                      message.success("L'integrazione è stata completata correttamente");
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    })
                )
              }
            }}
            okText="Si" cancelText="No">
            <Button
              disabled={this.state.loading}
              loading={this.state.completing}
              className="m5h" type="primary" icon="check">Completa l'integrazione</Button>
          </Popconfirm>
        </p>
      </div>)
    }else if (this.state.data.stato_flusso === repertorioStatic.stato_flusso.gtr_completed && checkPermission.hasPermission(this.props.UserStore.user, ['evaluateRichiesta'])) {
      return (<div>
        <p>
          <Popconfirm placement="top" title="Confermi?"
            onConfirm={() => {
              if (!this.state.loading) {
                this.setState({
                  completing: true,
                  loading: true
                }, () =>
                  ModelServices.gtrConfirmCompletion(
                    data.id,
                    (res) => {
                      this.props.refreshInline(res.data.data);
                      this.endLoading();
                    },
                    () => {
                      this.endLoading();
                    })
                )
              }
            }}
            okText="Si" cancelText="No">
            <Button
              disabled={this.state.loading}
              loading={this.state.completing}
              className="m5h" type="primary" icon="check">Conferma l'integrazione</Button>
          </Popconfirm>
        </p>
      </div>)
    }
  }

  editableState() {
    return this.state.data.stato <= repertorioStatic.stato.compilata || (this.state.data.stato === repertorioStatic.stato.sospesa_in_attesa_di_integrazioni && checkPermission.hasPermission(this.props.UserStore.user, ['AddIntegrazioneDocumentale']));
  }

  canGiveFeedback() {
    return checkPermission.hasPermission(this.props.UserStore.user, ['editAudit']) &&
      this.state.data.responsabile && this.state.data.responsabile.id === this.props.UserStore.user.id && this.state.data.section_feedback &&
      this.state.data.section_feedback['attachments'] && this.state.data.section_feedback['generalData'] &&
      this.state.data.stato_flusso === repertorioStatic.stato_flusso.investigation
  }

  approve(id_audit) {
    if (!this.state.approving) {
      this.setState({
        approving: true
      }, () => {
        AuditService.prepareApprove(
          this.state.data.id,
          id_audit,
          (res) => {
            this.setState({ approving: false })
            message.success("La richiesta è stata approvata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ approving: false })
        )
      })
    }
  }

  requireIntegration(id_integrazione) {
    if (!this.state.requiring) {
      this.setState({
        requiring: true
      }, () => {
        AuditService.confirmIntegration(
          this.state.data.id,
          id_integrazione,
          (res) => {
            this.setState({ requiring: false })
            message.success("Le integrazioni sono state richieste correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ requiring: false })
        )
      })
    }
  }

  refuse(id_audit, note) {
    if (!this.state.refusing) {
      this.setState({ refusing: true }, () => {
        AuditService.prepareRefuse(
          this.state.data.id,
          id_audit,
          { note: note },
          (res) => {
            this.setState({ refusing: false });
            message.success("La richiesta è stata rigettata correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ refusing: false })
        )
      })
    }
  }

  suspend(id_motivazione) {
    if (!this.state.suspending) {
      this.setState({ suspending: true }, () => {
        AuditService.confirmReject(
          this.state.data.id, id_motivazione,
          (res) => {
            this.setState({ suspending: false });
            message.success("La richiesta è stata sospesa correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ suspending: false })
        )
      })
    }
  }



  integration(id_audit, values) {
    if (!this.state.suspending) {
      this.setState({ suspending: true }, () => {
        AuditService.integration(
          this.state.data.id,
          id_audit,
          { requested_media: values },
          (res) => {
            this.setState({ suspending: false });
            message.success("La richiesta è stata sospesa correttamente");
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ suspending: false })
        )
      })
    }
  }

  uploadDecreto(file) {
    if (!this.state.uploading_decreto) {
      this.setState({
        uploading_decreto: true
      }, () => {
        ModelServices.uploadDecreto(
          this.state.data.id,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploading_decreto: false })
            message.success("Il decreto è stato caricato correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => this.setState({ uploading_decreto: false })
        )
      })
    }
  }

  getMedia() {
    this.setState({ loading_media: true })
    ModelServices.getMedia(
      this.state.data.id,
      (res) => {
        this.setState({
          documents: res.data.data,
          loading_media: false
        });
      },
      () => {
        this.setState({
          loading_media: false
        });
      })

  }

  editControdeduzioni(data, id_comunicazione) {
    if (!this.state.editingControdeduzioni) {
      this.setState({
        editingControdeduzioni: true
      }, () => {
        ModelServices.editControdeduzioni(
          this.state.data.id,
          id_comunicazione,
          { note: data.note },
          (res) => {
            this.setState({ editingControdeduzioni: false })
            message.success("La controdeduzione è stata salvata");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ editingControdeduzioni: false });
            if (err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  saveBlobWord(blob, title) {
    const _blob = new Blob([blob], { type: 'application/vnd.oasis.opendocument.text' });
    FileSaver.saveAs(_blob, title);
  }

  downloadControdeduzioni(id_motivazione) {
    if (!this.state.loadingControdeduzioni) {
      this.setState({
        loadingControdeduzioni: true
      }, () => {
        ModelServices.downloadControdeduzioni(
          this.state.data.id,
          id_motivazione,
          (res) => {
            this.saveBlobWord(res.data, 'controdeduzioni.odt');
            this.setState({ loadingControdeduzioni: false })
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ loadingControdeduzioni: false });
            if (err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  uploadControdeduzioni(file, id_motivazione) {
    if (!this.state.uploadingControdeduzioni) {
      this.setState({
        uploadingControdeduzioni: true
      }, () => {
        ModelServices.uploadControdeduzioni(
          this.state.data.id,
          id_motivazione,
          { filename: 'file' }, { file: file, name: file.name, chiave: 'file' },
          (res) => {
            this.setState({ uploadingControdeduzioni: false })
            message.success("La controdeduzione è stata caricata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ uploadingControdeduzioni: false })
            if (err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }

  sendControdeduzioni(id_motivazione) {
    if (!this.state.sendingControdeduzioni) {
      this.setState({
        sendingControdeduzioni: true
      }, () => {
        ModelServices.sendControdeduzioni(
          this.state.data.id,
          id_motivazione,
          (res) => {
            this.setState({ sendingControdeduzioni: false })
            message.success("La controdeduzione è stata inviata correttamente");
            //this.props.refresh(false);
            this.props.refreshInline(res.data.data);
          },
          (err) => {
            this.setState({ sendingControdeduzioni: false });
            if (err.response && err.response.status === 406) this.props.refresh(false);
          }
        )
      })
    }
  }


  render() {

    //let remainingDays = 30 + this.state.data.integration_added_days - moment().diff(moment(this.state.data.data_timer_audit, 'YYYY-MM-DD'), 'days');

    return (<>

      <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }} role="contentinfo" aria-label='Pannello Stato Richiesta Repertorio'>
        <Col style={{ flex: "1 1 70%" }} >
          <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
            <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
              bordered={false}>
              {this.state.data?.ente &&
                <h4><span style={{ color: '#000' }}>ENTE DI FORMAZIONE:</span> {this.state.data.ente.denominazione}</h4>
              }
              {this.state.data && (this.state.data.tipo_richiesta === repertorioStatic.tipo_richiesta.nuova) &&

                <Button icon="file-pdf" className="m5h m5w" type="primary" loading={this.state.loading_proposta} onClick={() => {
                  this.setState({ loading_proposta: true })
                  ModelServices.printRichiesta(this.state.data.id, (res) => {
                    this.saveBlob(res.data, "richiesta.pdf");
                    this.setState({ loading_proposta: false })
                  }, (err) => {
                    this.setState({ loading_proposta: false })
                  })
                }}>Scarica richiesta di repertorio</Button>
              }

              {this.state.data && (this.state.data.tipo_richiesta === repertorioStatic.tipo_richiesta.modifica || this.state.data.tipo_richiesta === repertorioStatic.tipo_richiesta.integrazione) &&
                <Button icon="file-pdf" className="m5h m5w" type="primary" loading={this.state.loading_proposta} onClick={() => {

                  this.setState({ loading_proposta: true })
                  ModelServices.printProposta(this.state.data.id, (res) => {
                    this.saveBlob(res.data, "proposta.pdf");
                    this.setState({ loading_proposta: false })
                  }, (err) => {
                    this.setState({ loading_proposta: false })
                  })

                }}>Scarica proposta di modifica/integrazione</Button>
              }
              {this.state.data?.stato > repertorioStatic.stato.predisposizione && this.state.data?.stato < repertorioStatic.stato.inviata &&

                <Popconfirm placement="top" title="Confermi di voler riportare la richiesta in lavorazione?"
                  onConfirm={() => {

                    if (!this.state.loading_rollback_state) {
                      this.setState({
                        loading_rollback_state: true
                      }, () =>
                        ModelServices.rollback(
                          this.state.data.id,
                          (res) => {
                            //this.refresh(true);
                            this.props.refreshInline(res.data.data)
                            this.setState({
                              loading_rollback_state: false
                            });
                          },
                          () => {
                            this.setState({
                              loading_rollback_state: false
                            });
                          })
                      )
                    }
                  }}
                  okText="Si" cancelText="No">
                  <Button
                    disabled={this.state.loading_rollback_state}
                    loading={this.state.loading_rollback_state}
                    className="m5h" type="primary" icon="undo">Riporta in lavorazione</Button>
                </Popconfirm>
              }
            </Card>
            <Card style={{ flex: "1 1 auto" }}
              bordered={false}
              className="shadow">
              <Row>
                <Col lg={24} sm={24} xs={24}>
                  <div id="actions">
                    <h4 className="p10h">Stato di compilazione della richiesta</h4>
                    <p>Stato: <strong>{this.state.data ? getStato(this.state.data, this) : ''}</strong></p>
                    <p>{this.state.data ? getDescription(this.state.data, this) : ''}</p>

                    <p>{this.state.data ? this.renderDescriptionText(this.state.data, this) : ''}</p>

                    <React.Fragment>
                      <EsitoProgetto
                        canEvaluate={(this.state.data.stato_flusso === repertorioStatic.stato_flusso.counterargument_sent || this.state.data.stato_flusso === repertorioStatic.stato_flusso.gtr_counterargument_sent) && checkPermission.hasPermission(this.props.UserStore.user, ['EvaluateControdeduzioni'])}
                        //  canConfirmApprove={this.canConfirmApprove()}
                        // canSuspend={this.state.data.stato_flusso === repertorioStatic.stato_flusso.counterarguments_suspend && checkPermission.hasPermission(this.props.UserStore.user, ['suspendRichiesteRepertorio'])}
                        // suspend={() => this.suspend()}
                        // suspending={this.state.suspending}
                        canUploadDecreto={this.state.data.stato_flusso === repertorioStatic.stato_flusso.validation && checkPermission.hasPermission(this.props.UserStore.user, ['activateProcedureRichiestaRepertorio'])}
                        labelDecreto={'Carica il decreto dirigenziale di Aggiornamento del Repertorio Regionale firmato digitalmente'}
                        prepareReject={() =>this.state.data.stato_flusso === repertorioStatic.stato_flusso.counterargument_sent? this.prepareReject() : this.prepareRejectGtr()}
                        canConfirmReject={(this.state.data.stato_flusso === repertorioStatic.stato_flusso.prepare_reject || this.state.data.stato_flusso === repertorioStatic.stato_flusso.gtr_prepare_reject) && checkPermission.hasPermission(this.props.UserStore.user, ['suspendRichiesteRepertorio'])}
                        confirmReject={() => this.confirmReject()}
                        prepareApprove={() => this.state.data.stato_flusso === repertorioStatic.stato_flusso.counterargument_sent? this.approveControdeduzioni() : this.approveControdeduzioniGtr()}
                        //  confirmApprove={() => this.confirmApprove()}
                        //  downloadDecreto={() => this.downloadDecreto()}
                        uploadDecreto={(file) => this.uploadDecreto(file)}
                        approving={this.state.approving_controdeduzioni}
                        rejecting={this.state.rejecting}
                        confirming_reject={this.state.rejecting}
                        //  confirming_approve={this.state.confirming_approve}
                        //  downloading_decreto={this.state.downloading_decreto}
                        uploading_decreto={this.state.uploading_decreto}
                      />
                    </React.Fragment>
                    <React.Fragment>

                      {this.renderFileFirmato()}
                      {this.renderRichiestaIntegrazione()}
                      {this.renderFileRicevutaInvioProposta()}
                      {this.renderDecreto()}
                      {this.renderVerbaleTavoloTecnico()}

                      <Tabs size="medium">

                        {((checkPermission.hasPermission(this.props.UserStore.user, ['addAudit']) && this.state.data.stato_flusso === repertorioStatic.stato_flusso.assigned) ||
                          (checkPermission.hasPermission(this.props.UserStore.user, ['viewAudit']) && this.state.data.audit && this.state.data.audit.length > 0)) &&
                          <TabPane tab={
                            <span>Audit</span>
                          } key="1">
                            <AuditDocumentale
                              {...this.props}
                              description={'Verifica la richiesta, valida le sezioni della scheda, approva, rifiuta o richiedi un\'integrazione'}
                              descriptionRefuse={'Indica le motivazioni del rifiuto'}
                              canView={true}
                              canViewRefuse={true}
                              canStartAudit={this.state.data.stato_flusso === repertorioStatic.stato_flusso.assigned && checkPermission.hasPermission(this.props.UserStore.user, ['addAudit'])}
                              media={true}
                              getMedia={() => this.getMedia()}
                              documents={this.state.documents}
                              loading_media={this.state.loading_media}
                              canConfirmIntegration={this.state.data.stato_flusso === repertorioStatic.stato_flusso.integrations_suspend && checkPermission.hasPermission(this.props.UserStore.user, ['suspendRichiesteRepertorio'])}
                              //  startedAudit={this.props.progetto.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato}
                              //  canSendIntegration={this.canSendIntegration()}
                              canGiveFeedback={this.canGiveFeedback()}
                              //  canRequireIntegration={this.canRequireIntegration()}
                              //  isValidated={this.isValidated()}
                              loading={this.state.starting}
                              startAudit={() => this.startAudit()}
                              audit={this.state.data.audit}
                              approve={(id) => this.approve(id)}
                              refuse={(id, note) => this.refuse(id, note)}
                              integration={(id_audit, values) => this.integration(id_audit, values)}
                              //  suspend={(id, note) => this.suspend(id, note)}
                              requireIntegration={(id) => this.requireIntegration(id)}
                              //  integration={(id) => this.integration(id)}
                              approving={this.state.approving}
                              refusing={this.state.refusing}
                              suspending={this.state.suspending}
                              //  integrating={this.state.integrating}
                              requiring={this.state.requiring}
                            //  disabledApprove={this.disableApprove()}
                            >
                            </AuditDocumentale>
                          </TabPane>}

                        {
                          this.state.data.comunicazioneOstativa && this.state.data.comunicazioneOstativa.length > 0 &&

                          <TabPane tab={
                            <span>Motivazione rifiuto</span>
                          } key="2">

                            {
                              this.state.data.comunicazioneOstativa.map(motivazione => {
                                return <div key={"motivazione_" + motivazione.id}>
                                  <div style={{ marginTop: 16 }}>
                                    <p style={{ display: 'inline-block' }}><strong>Motivazione rifiuto: </strong>{motivazione.note} <br />
                                      <strong>Data: </strong>{moment.unix(motivazione.created_at).format('DD/MM/YYYY')}</p>
                                  </div>

                                  {
                                    this.state.data.stato_flusso === repertorioStatic.stato_flusso.counterarguments_suspend && checkPermission.hasPermission(this.props.UserStore.user, ['suspendRichiesteRepertorio']) &&

                                    <Popconfirm placement="top" title="Confermi di voler sospendere la richiesta?"
                                      onConfirm={() => {
                                        this.suspend(motivazione.id)
                                      }}
                                      okText="Si" cancelText="No">
                                      <Button className="m5w"
                                        style={{ marginRight: 12 }}
                                        loading={this.state.suspending}
                                        disabled={this.state.suspending}
                                        type="danger" ghost icon="close">
                                        Sospendi
                                  </Button>
                                    </Popconfirm>
                                  }

                                  {
                                    motivazione.controdeduzioni &&

                                    <Controdeduzioni
                                      canView={true}
                                      controdeduzioni={motivazione.controdeduzioni}
                                      data_trasmissione={motivazione.data_trasmissione}
                                      comunicazione={motivazione}
                                      canEditControdeduzioni={motivazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.in_corso && checkPermission.hasPermission(this.props.UserStore.user, ['AddControdeduzioni'])}
                                      canDownloadControdeduzioni={motivazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare && checkPermission.hasPermission(this.props.UserStore.user, ['AddControdeduzioni'])}
                                      canUploadControdeduzioni={motivazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.da_firmare && checkPermission.hasPermission(this.props.UserStore.user, ['AddControdeduzioni'])}
                                      canSendControdeduzioni={motivazione.controdeduzioni.stato === comunicazioneOstativaStatic.stato_controdeduzioni.firmata && checkPermission.hasPermission(this.props.UserStore.user, ['AddControdeduzioni'])}
                                      editControdeduzioni={(data) => this.editControdeduzioni(data, motivazione.id)}
                                      editingControdeduzioni={this.state.editingControdeduzioni}
                                      downloadControdeduzioni={() => this.downloadControdeduzioni(motivazione.id)}
                                      loadingControdeduzioni={this.state.loadingControdeduzioni}
                                      uploadControdeduzioni={(file) => this.uploadControdeduzioni(file, motivazione.id)}
                                      uploadingControdeduzioni={this.state.uploadingControdeduzioni}
                                      sendControdeduzioni={() => this.sendControdeduzioni(motivazione.id)}
                                      sendingControdeduzioni={this.state.sendingControdeduzioni}
                                      media={motivazione?.controdeduzioni?.media?.filter(el => el.tipo.sottodominio === comunicazioneOstativaStatic.tipo_media.sottodominio_controdeduzioni_firmata)}
                                      ext={this.state.data.validation.fileExtToSend}
                                    >
                                    </Controdeduzioni>
                                  }
                                </div>

                              })
                            }

                          </TabPane>
                        }

                      </Tabs>


                    </React.Fragment>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
        {/*checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) &&*/ this.editableState() &&
          <Col style={{ flex: "1 1 30%" }} >
            <Card
              style={{ height: "100%" }}
              bordered={false}>

              <div style={{ textAlign: 'center' }}>
                <h4 className="p10h">Percentuale popolamento della richiesta</h4>

                <Progress width={100} type="circle" percent={this.state.progress_obj.progress} />
                <p className="p30h p20w text-justify">
                  {
                    this.state.progress_obj.progress !== 100
                      ?
                      "Compila le sezioni della richiesta fino al raggiungimento del 100%, clicca sul pulsante informazioni per avere dettagli sui dati mancanti"
                      : "Congratulazioni, la compilazione è completa, puoi procedere all'inoltro della richiesta"
                  }
                </p>
                {
                  this.state.progress_obj.progress < 100
                  &&
                  <Button
                    size="small"
                    icon="info"
                    type="primary"
                    loading={this.state.getting_info}
                    onClick={() => this.getInfoAndValidate()}>Informazioni</Button>
                }

                {/* <Button
                  size="small"
                  icon="reload"
                  type="primary"
                  style={{ marginLeft: 8 }}
                  ghost
                  loading={this.state.calculating_progress}
               onClick={() => this.calculateProgress()}>Aggiorna</Button> */}


                <Modal
                  visible={this.state.show_errors}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  width={600}

                  destroyOnClose={false}
                  onOk={() => this.setState({ show_errors: false })}
                  onCancel={() => this.setState({ show_errors: false })}
                >
                  {this.state.progress_obj.errors && <ul>
                    {
                      Object.keys(this.state.progress_obj.errors).map(e => {
                        if (this.state.progress_obj.errors[e].length > 0) {
                          return <div key={"section_" + e}>
                            <h4>{this.sectiontMappingGetName(e)}</h4>
                            <ul>{
                              this.state.progress_obj.errors[e].map((err, i, a) => {
                                return <li key={"err_" + e + "_" + i}><strong>{this.propertyLabels(err[0])}</strong> {this.errorsTranslations(err[1])}</li>
                              })
                            }
                            </ul>
                          </div>
                        }
                        return null;
                      })
                    }
                  </ul>}
                </Modal>

                <Modal
                  visible={this.state.show_completed}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  width={600}

                  destroyOnClose={false}
                  onOk={() => this.setState({ show_completed: false })}
                  onCancel={() => this.setState({ show_completed: false })}
                >
                  <div>
                    Il formulario risulta completo e con tutte le informazioni necessarie. Proseguire con il salvataggio dei dati per non perdere le ultime informazioni immesse.
                    </div>
                </Modal>



              </div>
            </Card>
          </Col>
        }

        {checkPermission.hasPermission(this.props.UserStore.user, ['addAudit']) &&
          <Col style={{ flex: "1 1 30%" }} >
            <Card
              style={{ height: "100%" }}
              bordered={false}>
              <React.Fragment> { this.state.data.stato_flusso !== repertorioStatic.stato_flusso.rejected && this.state.data.stato_flusso !== repertorioStatic.stato_flusso.confirm_accepted &&
                <div style={{ textAlign: 'center' }}>
                  <h4 className="p10h">Timer</h4>
                  {this.state.data.blocked_timer ?
                    <span style={{ display: 'block', fontWeight: 'bold' }}>Timer bloccato</span> :
                    <>
                      <span style={{ display: 'block' }}>Giorni disponibili per comunicare una risposta</span><br />
                      <Progress
                        width={140}
                        type="circle"
                        percent={Math.round(this.state.data.scadenza_audit / 30 * 100)}
                        format={(percent, successPercent) => this.state.data.scadenza_audit + ' / ' + 30}
                        status={'active'}
                      />
                    </>
                  }
                </div> }
              </React.Fragment>
            </Card>
          </Col>
        }

        {checkPermission.hasPermission(this.props.UserStore.user, ['viewRichiesteRepertorio']) && this.props.UserStore.user.role.some(r => r.name === 'CoordinatoreGTR' || r.name === 'TecnicoGTR') &&
          <Col style={{ flex: "1 1 30%" }} >
            <Card
              style={{ height: "100%" }}
              bordered={false}>
              <React.Fragment>{ this.state.data.stato_flusso !== repertorioStatic.stato_flusso.rejected && this.state.data.stato_flusso !== repertorioStatic.stato_flusso.confirm_accepted &&
                <div style={{ textAlign: 'center' }}>


                  <h4 className="p10h">Timer</h4>
                  {this.state.data.blocked_timer === 1 ?
                    <span style={{ display: 'block', fontWeight: 'bold' }}>Timer bloccato</span> :
                    <>
                      <span style={{ display: 'block' }}>Giorni disponibili per la valutazione</span><br />
                      <Progress
                        width={140}
                        type="circle"
                        percent={Math.round(this.state.data.scadenza_valutazione / 30 * 100)}
                        format={(percent, successPercent) => this.state.data.scadenza_valutazione + ' / ' + 30}
                        status={'active'}
                      />
                    </>
                  }
                </div>}
              </React.Fragment>
            </Card>
          </Col>
        }
        {checkPermission.hasPermission(this.props.UserStore.user, ['assignIstruttore']) &&
          <Col style={{ flex: "1 1 30%" }} >
            <Card
              style={{ height: "100%" }}
              bordered={false}>
              <React.Fragment>
                <div style={{ padding: 6 }}>
                  <AssegnazioneIstruttore
                    canAssignIstruttore={this.state.data.stato === repertorioStatic.stato.acquisita}
                    canChangeIstruttore={this.state.data.responsabile}
                    auditExists={this.state.data.audit && this.state.data.audit.length > 0 && this.state.data.audit.filter(a => a.stato === auditStatic.stato.in_corso)}
                    richiesta={this.state.data}
                    refreshInline={(data) => this.props.refreshInline(data)}
                  />
                </div>
              </React.Fragment>
            </Card>
          </Col>
        }
      </Row>
    </>
    );
  }
}
