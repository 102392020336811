import React, { Component } from 'react';
import { Icon, Tabs, message } from "antd";
import { Sticky, StickyContainer } from 'react-sticky';
import checkPermission from '@common/utils/check/permissions';
import DatiGeneraliForm from '../forms/formulario/edizione/DatiGeneraliForm';
import SoggettiCorrelatiForm from '../forms/formulario/edizione/SoggettiCorrelatiForm';
import DiscentiForm from '../forms/formulario/edizione/DiscentiForm';
import EnteService from '@accreditamento/services/Ente';
import SedeService from '@accreditamento/services/Sede';
import ProgrammazioneDidatticaForm from '../forms/formulario/edizione/ProgrammazioneDidatticaForm';
import SpinnerLoading from "@common/components/SpinnerLoading";
import progettoService from '../services/Progetto';
import edizioneService from '../services/Edizione';
import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';

import PropTypes from 'prop-types';
import ElementiNonFormativiForm from '../forms/formulario/ElementiNonFormativiForm';
import SchedaFinanziariaForm from '../forms/formulario/SchedaFinanziariaForm';
import SchedaFinanziariaFormFree from '../forms/formulario/SchedaFinanziariaFormFree';
import PresenzeGiornaliere from './registro/PresenzeGiornaliere';
import CoperturaProgetto from '../forms/formulario/CoperturaProgetto';

const TabPane = Tabs.TabPane;

export default class SchedaFormulario extends Component {

  constructor(props) {
    super(props);
    this.reloadPersonaleEnti = this.reloadPersonaleEnti.bind(this);
    eventBroker.on('reloadOnSectorChange', this.reloadPersonaleEnti);
  }

  static propTypes = {
    id_ente: PropTypes.number,
    isEditable: PropTypes.bool,
    refresh: PropTypes.func,
    domanda_json: PropTypes.object,
    progress_obj: PropTypes.object,
    updateProgress: PropTypes.func,
  }

  state = {
    current_key: "1",
    macrotipologie: [],
    professionalita: [],
    personale_enti: [],
    attrezzature: [],
    audiovisivi: [],
    loaded: false,
    methodList: this.props.progetto_padre.scheda_progetto_json?.teachingProgramming?.AfcMethodAndHumanResourcesFormSection?.methodAndHumanResourcesList || []
  }

  componentDidMount() {
    if (this.props.id_ente) {

      let req = [
        EnteService.macrotipologieAccreditate({}, this.props.id_ente),
      ];

        req.push(
          EnteService.professionalitaCoinvolte({}, this.props.id_ente, this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto),
          EnteService.attrezzatureAccreditate({}, this.props.id_ente, this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto),
          EnteService.audiovisiviAccreditati({}, this.props.id_ente, this.props.domanda_json.relatedSubjects?.AfcSingleEntityPartnerLeaderSection?.entityPersonalData?.decreto),
          );
      //else {
      //  req.push(Promise.resolve(null), Promise.resolve(null), Promise.resolve(null));
      //}

      //if (this.props.domanda_json.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList) {
        req.push(EnteService.personaleEnti(this.props.formulario, this.props.id_ente, this.props.match.params.id));
      //} else {
      //  req.push(Promise.resolve(null));
      //}

      if (this.props.domanda_json.generalData.AfcMacroTypologySection?.macroTypology) {
        this.updateSelectedMacrotipologia(this.props.domanda_json.generalData.AfcMacroTypologySection?.macroTypology)
        req.push(SedeService.getProvinceSediAccreditate(this.props.domanda_json.generalData.AfcMacroTypologySection.macroTypology,this.props.id_ente, this.props.id_progetto));
      } else {
        req.push(Promise.resolve(null));
      }

      this.setState({ loaded: false });

      Promise.all(req).then(res => {

        let [res1, res2, res3, res4, res5, res6] = res;

        let province = [];
        this.props.GlobalStore.stubs.provincia.forEach(provincia => province.push({id: provincia.id, provincia: provincia.label}))
        

        let macrotipologie = res1?.data.data || [];
        if (this.props.domanda_json.generalData.AfcMacroTypologySection?.macroTypology && !macrotipologie.find(el => el.value === this.props.domanda_json.generalData.AfcMacroTypologySection?.macroTypology)) {
          macrotipologie.push( this.props.GlobalStore.stubs.tipo_accreditamento.find(el => el.value === this.props.domanda_json.generalData.AfcMacroTypologySection?.macroTypology))}

          /*let province = res6?.data.data || [];
          if (this.props.domanda_json.relatedSubjects && this.props.domanda_json.relatedSubjects.AfcHeadquartersSection && this.props.domanda_json.relatedSubjects.AfcHeadquartersSection.provincia
            && !province.find(el => el.id === this.props.domanda_json.relatedSubjects.AfcHeadquartersSection.provincia)) {
              let provincia = this.props.GlobalStore.stubs.provincia.find(el => el.value === this.props.domanda_json.relatedSubjects.AfcHeadquartersSection.provincia)
              province.push({id: provincia.id, provincia: provincia.label})
          }*/

        this.setState({
          macrotipologie: macrotipologie,
          professionalita: res2?.data.data,
          attrezzature: res3?.data.data,
          audiovisivi: res4?.data.data,
          personale_enti: res5?.data.data.map(p => {
            return {
              ...p,
              checked: this.props.domanda_json.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList.some(element => element.id === p.id)
            }
          }),
          province_accreditate: province,
          loaded: true
        });

      });

    } else {
      this.setState({ loaded: true });
    }
  }

  reloadPersonaleEnti(id_ente) {
    id_ente = id_ente || this.props.id_ente;
    if (this.props.domanda_json?.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList) {
      this.setState({ loaded_personale_enti: true });
      EnteService.personaleEnti(this.props.formulario, id_ente, this.props.match.params.id, (res) => {
        this.setState({
          personale_enti: res.data.data.map(p => {
            return {
              ...p,
              checked: this.props.domanda_json.relatedSubjects?.AfcProjectStaffSection?.staffPersonalDataList.some(element => element.id === p.id)
            }
          }),
          loaded_personale_enti: true,
        });
      });
    }
  }

  resetRisorseUmane(professionalita, attrezzature, audiovisivi){

    let list = this.state.methodList
    list = list.map(el => { return { ...el, organogramList: [], teacherList: [], specialUserList: [], equipmentList: [], audioVisualList: [] } })

    this.setState({
      methodList: list,
      professionalita: professionalita,
      attrezzature: attrezzature,
      audiovisivi: audiovisivi,
    });
  }

  resetPersonaleEnti() {
    eventBroker.trigger('resetPersonaleEnti');
    this.setState({
      personale_enti: [],
    });
  }

  updateSelectedMacrotipologia(id_macrotipologia) {

    if (this.soggetti_correlati && this.soggetti_correlati.formRef) {
      let domanda = this.soggetti_correlati.state.dati.domanda;
      domanda.relatedSubjects.AfcHeadquartersSection.classroomList = [];

      let dati_form = this.soggetti_correlati.formRef.props.form.getFieldsValue()
      dati_form.domanda.relatedSubjects.AfcHeadquartersSection.provincia = null;
      dati_form.domanda.relatedSubjects.AfcHeadquartersSection.id = null;
      dati_form.domanda.relatedSubjects.AfcHeadquartersSection.comune = null;
      dati_form.domanda.relatedSubjects.AfcHeadquartersSection.indirizzo = null;
      this.soggetti_correlati.formRef.props.form.setFieldsValue({ domanda: dati_form.domanda });
      this.props.refreshSedeSvolgimento();
    }

    this.setState({ id_macrotipologia: id_macrotipologia })
  }

  loadProvinceSediAccreditate(id_macrotipologia) {
    this.updateSelectedMacrotipologia(id_macrotipologia);

    SedeService.getProvinceSediAccreditate(id_macrotipologia,this.props.id_ente, this.props.id_progetto, (res) => {
      this.setState({
        province_accreditate: res.data.data,
        loaded_sedi: true,
      });
    }, () => this.setState({
      loaded_sedi: true,
    }))
  }


  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  isValidSection(name) {

    if (this.props.section_feedback && this.props.section_feedback[name]) {
      return this.props.section_feedback[name].valid ? 'checked' : 'invalid';
    } else if (this.props.stato_flusso === affidamentoStatic.stato_flusso_progetto.audit_iniziato && (!this.props.section_feedback || !this.props.section_feedback[name])) {
      return 'default';
    }

    //if (this.props.stato_flusso < affidamentoStatic.stato_flusso_progetto.presentato) {
    if (this.props.progress_obj.valid_sections[name])
      return 'checked';
    else if (this.props.progress_obj.showed_sections[name] && !this.props.progress_obj.valid_sections[name])
      return 'invalid';
    //}

    return 'default';
  }

  saveFeedback(note, valid, section) {
    if (!this.state.loadingFeedback) {
      this.setState({
        loadingFeedback: true,
      }, () => {

        if(this.props.progetto.discriminante === 'PROGETTO'){
          progettoService.feedbackSection(
            this.props.id_progetto,
            {
              section: section,
              note: note,
              valid: valid
            },
            (res) => {
              //this.props.refreshInline(res.data.data);
              message.success("La sezione è stata salvata correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data)
              this.setState({ loadingFeedback: false });
            },
            (err) => this.setState({ loadingFeedback: false })
          );
        }
        if(this.props.progetto.discriminante === 'EDIZIONE'){
          edizioneService.feedbackSectionBackoffice(
            this.props.id_progetto,
            {
              section: section,
              note: note,
              valid: valid
            },
            (res) => {
              //this.props.refreshInline(res.data.data);
              message.success("La sezione è stata salvata correttamente");
              //this.props.refresh(false);
              this.props.refreshInline(res.data.data)
              this.setState({ loadingFeedback: false });
            },
            (err) => this.setState({ loadingFeedback: false })
          );
        }
        
      });
    }
  }

  updateProgress(progress) {
    this.props.progress_obj.valid_sections = progress.valid_sections;
    if (this.props.updateProgress) {
      this.props.updateProgress(progress);
    }
  }

  render() {
    return (<div>
      { !this.state.loaded
      ?
      <SpinnerLoading message='Caricamento dati...' />
      :
      <StickyContainer>
        <Tabs
          onChange={(k) => this.setState({ current_key: "" + k })}
          defaultActiveKey="1" size="medium" renderTabBar={this.renderTabBar}>
          {this.props.domanda_json?.generalData &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('generalData')}>DATI GENERALI<br /><br /><Icon type="check-circle" /></span>} key="1">
              <div id="datiGenerali">
                <div style={{ padding: 14 }}>
                  <DatiGeneraliForm
                    {...this.props}
                    generalData={this.props.domanda_json.generalData}
                    teachingProgramming={this.props.domanda_json.teachingProgramming}
                    macrotipologie={this.state.macrotipologie}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    loadProvinceSediAccreditate={(data) => this.loadProvinceSediAccreditate(data)}
                    resetPersonaleEnti={() => this.resetPersonaleEnti()}
                    resetRisorseUmane={(data1,data2,data3) => this.resetRisorseUmane(data1,data2,data3)}
                    methodList={this.state.methodList}
                    progetto_padre={this.props.progetto_padre || {}}
                />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json?.relatedSubjects
            && !checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])
            &&
            <TabPane
              tab={
                <span className={'checked'}>SOGGETTI<br />CORRELATI<br /><Icon type="check-circle" /></span>} key="2">
              <div id="soggettiCorrelati">
                <div style={{ padding: 14 }}>
                  <SoggettiCorrelatiForm
                    {...this.props}
                    personaleEnti={this.state.personale_enti}
                    domanda={this.props.domanda_json}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    province={this.state.province_accreditate}
                    ref={(ref) => this.soggetti_correlati = ref}
                    id_macrotipologia={this.state.id_macrotipologia}
                    id_progetto={this.props.id_progetto}
                    progetto_padre={this.props.progetto_padre || {}}
                  />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json?.learner
            && !checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])
            &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('learner')}>DISCENTI<br /><br /><Icon type="check-circle" /></span>} key="3">
              <div id="discenti">
                <div style={{ padding: 14 }}>
                  <DiscentiForm
                    {...this.props}
                    domanda={this.props.domanda_json}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                  />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json?.teachingProgramming &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('teachingProgramming')}>PROGRAMMAZIONE<br />DIDATTICA<br /><Icon type="check-circle" /></span>} key="4">
              <div id="programmazioneDidattica">
                <div style={{ padding: 14 }}>
                  <ProgrammazioneDidatticaForm
                    {...this.props}
                    ref = {(ref) => {this.programmazione_didattica_ref = ref}}
                    teachingProgramming={this.props.domanda_json.teachingProgramming}
                    startDate={this.props.domanda_json.generalData.AfcCourseTimeSection?.startDate}
                    endDate={this.props.domanda_json.generalData.AfcCourseTimeSection?.endDate}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    professionalita={this.state.professionalita}
                    attrezzature={this.state.attrezzature}
                    audiovisivi={this.state.audiovisivi}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                    methodList={this.state.methodList}
                    progetto_padre={this.props.progetto_padre || {}}
                 />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json?.financialStatement?.AfcProjectCostsFormSection
            && this.props.progetto.discriminante != 'EDIZIONE'
            && !checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])
            &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('financialStatement')}>SCHEDA<br />FINANZIARIA<br /><Icon type="check-circle" /></span>} key="5">
              <div id="schedaFinanziaria">
                <div style={{ padding: 14 }}>
                  <SchedaFinanziariaForm
                    {...this.props}
                    financialStatement={this.props.domanda_json.financialStatement}
                    CourseType={this.props.domanda_json.generalData}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                  />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json?.financialStatement?.AfcProjectFreeCostsFormSection
            && this.props.progetto.discriminante != 'EDIZIONE'
            && !checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])
            &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('financialStatement')}>SCHEDA<br />FINANZIARIA<br /><Icon type="check-circle" /></span>} key="5">
              <div id="schedaFinanziaria">
                <div style={{ padding: 14 }}>
                  <SchedaFinanziariaFormFree
                    {...this.props}
                    financialStatement={this.props.domanda_json.financialStatement}
                    CourseType={this.props.domanda_json.generalData}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                  />
                </div>
              </div>
            </TabPane>
          }
          {this.props.domanda_json?.nonTrainingElements
            && !checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])
            &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('nonTrainingElements')}>ELEMENTI NON<br />FORMATIVI<br /><Icon type="check-circle" /></span>} key="6">
              <div id="elNonFormativi">
                <div style={{ padding: 14 }}>
                  <ElementiNonFormativiForm
                    {...this.props}
                    nonTrainingElements={this.props.domanda_json.nonTrainingElements}
                    readOnly={!this.props.isEditable}
                    updateProgress={this.updateProgress.bind(this)}
                    saveFeedback={(valid, note, section) => this.saveFeedback(valid, note, section)}
                    loadingFeedback={this.state.loadingFeedback}
                  />
                </div>
              </div>
            </TabPane>
          }
          {this.props.progetto && this.props.progetto.stato_flusso >= affidamentoStatic.stato_flusso_progetto.corso_pronto_all_avvio
            && checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione'])
            && !checkPermission.hasPermission(this.props.UserStore.user, ['discentePermissions'])
            &&
            <TabPane
              tab={
                <span className={this.props.anteprima ? 'default' : this.isValidSection('registroElettronico')}>REGISTRO<br />ELETTRONICO<br /><Icon type="check-circle" /></span>} key="7">
              <div id="registroElettronico">
                <div style={{ padding: 14 }}>
                  <PresenzeGiornaliere {...this.props} corso={this.props.progetto} />
                </div>
              </div>
            </TabPane>
          }
          {this.props.progetto && this.props.progetto.discriminante === 'PROGETTO' && checkPermission.hasPermission(this.props.UserStore.user, ['viewCopertura']) &&
            <TabPane
              tab={
                <span className='default'>COPERTURA DEL<br />PROGETTO<br /><Icon type="check-circle" /></span>} key="8">
              <div id="coperturaProgetto">
                <div style={{ padding: 14 }}>
                  <CoperturaProgetto {...this.props} progetto={this.props.progetto} />
                </div>
              </div>
            </TabPane>
          }
        </Tabs>
      </StickyContainer>
    }</div>);
  }
}

