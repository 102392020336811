import BaseRequest from '@common/services/_baseRequest';

export default {

  list: (progetto_id, vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      `affidamento/progetto/${progetto_id}/edizioni`,
      cb,
      ecb
    );
  },
  delete: (progetto_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'delete',
      'affidamento/progetto/' + progetto_id,
      cb,
      ecb
    );
  },
  printPdf: (progetto_id, edizione_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/print-pdf`,
      cb,
      ecb
    );
  },
  create: (progetto_id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      `affidamento/progetto/${progetto_id}/edizione`,
      cb,
      ecb
    );
  },
  detail: (progetto_id, edizione_id, params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}`,
      cb,
      ecb
    );
  },
  loadRegistrationData: (progetto_id, edizione_id, params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'post',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/iscrizione`,
      cb,
      ecb
    );
  },
  progress: (progetto_id, edizione_id, params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/progress`,
      cb,
      ecb
    );
  },
  save_json: (progetto_id, edizione_id, scheda_progetto_json = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/save-json`,
      cb,
      ecb
    );
  },
  info_and_validate: (progetto_id, edizione_id, scheda_progetto_json = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      { scheda_progetto_json: scheda_progetto_json },
      null,
      'post',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/info-and-validate`,
      cb,
      ecb
    );
  },
  storicoDiff: (progetto_id, edizione_id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/storico-diff`,
      cb,
      ecb
    );
  },
  editionDiff: (progetto_id, edizione_id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/edition-diff`,
      cb,
      ecb
    );
  },
  formularioDiff: (progetto_id, edizione_id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {
        format: 1
      },
      data,
      null,
      'get',
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/formulario-diff`,
      cb,
      ecb
    );
  },
  downloadAutorizzazione: (progetto_id, edizione_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/download-autorizzazione`,
      cb,
      ecb
    );
  },
  rollback: (progetto_id, edizione_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/rollback`,
      cb,
      ecb
    );
  },
  confirm: (progetto_id, edizione_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/confirm`,
      cb,
      ecb
    );
  },
  upload: (progetto_id, edizione_id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/upload`,
      cb,
      ecb
    );
  },
  send: (progetto_id, edizione_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      `affidamento/progetto/${progetto_id}/edizione/${edizione_id}/send`,
      cb,
      ecb
    );
  },


  //API Backoffice
  listBackoffice: (vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      `affidamento/edizione`,
      cb,
      ecb
    );
  },
  printPdfBackoffice: (edizione_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "get_file",
      `affidamento/edizione/${edizione_id}/print-pdf`,
      cb,
      ecb
    );
  },
  detailBackoffice: (edizione_id, params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      `affidamento/edizione/${edizione_id}`,
      cb,
      ecb
    );
  },
  assignBackoffice: (edizione_id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      `affidamento/edizione/${edizione_id}/assign`,
      cb,
      ecb
    );
  },
  progressBackoffice: (edizione_id, params = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      params,
      {},
      null,
      'get',
      `affidamento/edizione/${edizione_id}/progress`,
      cb,
      ecb
    );
  },
  feedbackSectionBackoffice: (edizione_id, data, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      `affidamento/edizione/${edizione_id}/feedback-section`,
      cb,
      ecb
    );
  },






  getDomande: (id, vars = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      vars,
      {},
      null,
      'get',
      'affidamento/progetto/' + id + '/domande',
      cb,
      ecb
    );
  },

  newEdition: (id_progetto, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'post',
      //'affidamento/progetto/' + id_progetto + '/new-edition', <- da togliere
      'affidamento/progetto/' + id_progetto + '/editione', 
      cb,
      ecb
    );
  },

  update: (params = {}, id = null, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      params,
      null,
      "put",
      'affidamento/progetto/' + id,
      cb,
      ecb
    );
  },

  

  requestOpenRegistration: (project_id, data, file, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      file,
      "post",
      'affidamento/progetto/' + project_id + '/request-open-registration',
      cb,
      ecb
    );
  },

  approveOpenRegistration: (project_id, data = { id_user: null }, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/progetto/' + project_id + '/approve-open-registration',
      cb,
      ecb
    );
  },

  rejectOpenRegistration: (project_id, cb, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      "post",
      'affidamento/progetto/' + project_id + '/reject-open-registration',
      cb,
      ecb
    );
  },

  setOpenRegistrationDate: (project_id, data, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      "post",
      'affidamento/progetto/' + project_id + '/set-open-registration-date',
      cb,
      ecb
    );
  },

  

  

  

  prepareCourse: (id, data = {}, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      data,
      null,
      'post',
      'affidamento/progetto/' + id + '/prepare-course',
      cb,
      ecb
    );
  },

  getCopertura: (id, cb = null, ecb = null) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      'affidamento/progetto/' + id + '/copertura',
      cb,
      ecb
    );
  },

  isPrimaEdizioneInviata: (edizione_id) => {
    var req = new BaseRequest();
    return req.makeRequest(
      {},
      {},
      null,
      'get',
      `affidamento/edizione/${edizione_id}/is-prima-edizione-inviata`
    );
  },


}
