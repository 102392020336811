import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import checkPermissions from '@common/utils/check/permissions';

import List from '../components/ListSoggetti';
import Create from '../components/CreateByEnte';

@Wrapper({ route: 'SoggettiEnte', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewSoggettiCorrelati'])
class ListSoggettiEnteRoute extends Component {

  state = {
    loading: false,
    asked: false
  }

  getBreadCrumb() {
    let breads = [
      <Breadcrumb.Item key="dash"><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
    ];
    breads.push(<Breadcrumb.Item key="org"><Link to={"/soggetti"}>Soggetti correlati</Link></Breadcrumb.Item>)
    return breads;
  }

  render() {
    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
        >
          {
            (checkPermissions.hasPermission(this.props.UserStore.user, ['enteAddUser', 'adminPermissions']))
              ?
              <Create
                oncreate={() => this.refs.listTable.refresh()}
                {...this.props} />
              : null
          }
          <h3 className="sectionTitle">Soggetti correlati</h3>

        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            {this.getBreadCrumb()}
          </Breadcrumb>
        </div>

        <List ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default ListSoggettiEnteRoute;
