import React from 'react';

import PropTypes from 'prop-types';

import _ from "lodash";
import PagamentoButton from './PagamentoButton';
import { Alert, Card, Spin } from 'antd';
import checkPermission from '@common/utils/check/permissions';
import { connect } from "react-redux";
import PagamentoService from '../services/PagamentoService';
import EsenzioneForm from '../forms/esenzione/EsenzioneForm';

const messages = {
    'progetto': {
        daPagare: `Per poter inviare il progetto formativo devi effettuare il pagamento della marca da bollo virtuale di 16,00 €. Scegli "SI" e clicca sul tasto "paga", verrai reindirizzato al sistema MyPay/PagoPa e potrai selezionare il metodo di pagamento con cui procedere. Se invece sei esente dal pagamento della marca da bollo clicca su "NO" ed inserisci il motivo di esenzione.`,
        pending: "Il pagamento è in corso di elaborazione, una volta concluso con esito positivo sarà possibile inviare il progetto formativo.",
        effettuato: "Il pagamento è stato effettuato."
    },
    'edizione': {
        primaEdizione: "Per l'invio della prima edizione non è richiesto un ulteriore pagamento.",
        daPagare: `Scegli "SI" e clicca sul tasto "paga", verrai reindirizzato al sistema MyPay/PagoPa e potrai selezionare il metodo di pagamento con cui procedere. Se invece sei esente dal pagamento della marca da bollo clicca su "NO" ed inserisci il motivo di esenzione.`,
        pending: "Il pagamento è in corso di elaborazione, una volta concluso con esito positivo sarà possibile inviare l'edizione.",
        effettuato: "Il pagamento è stato effettuato."
    },
    'backoffice': {
        primaEdizione: "Per l'invio della prima edizione non è richiesto un ulteriore pagamento.",
        daPagare: "Pagamento non effettuato",
        pending: "Istanza di pagamento su myPay in corso",
        effettuato: "Il pagamento è stato effettuato.",
        esente: "L'utente ha dichiarato di essere esente. Motivo: "
    }
};

const PagamentoCard = props => {

    const {idProgetto, pagamenti, rispostaPagamentoUrl, oggetto, isPrimaEdizione} = props;
    const {domandaJson, saveJson, readOnly} = props;

    const isEnte = checkPermission.hasPermission(props.UserStore.user, 'entePermissions');

    const exemptionData = _.get(domandaJson, 'exemptionData');
    const exemption  =  _.get(domandaJson, 'exemptionData.exemption') == "1";
    const exemptionReason = _.get(domandaJson, 'exemptionData.exemptionReason');

    return (         
        <Card style={{ flex: "1 1 auto", marginBottom: 12 }}
            bordered={false}
        >  
            <h4><span style={{ color: '#000' }}>PAGAMENTO MARCA DA BOLLO DIGITALE</span></h4>
            
            {domandaJson && getMessage(pagamenti, oggetto, isPrimaEdizione, isEnte, exemption, exemptionReason)}
            
            {isEnte &&
                <EsenzioneForm
                    domandaJson={domandaJson}
                    saveJson={saveJson}
                    readOnly={readOnly}
                />
            }
            
            {showPagamentoButton(pagamenti, isPrimaEdizione, isEnte, exemptionData, exemption) && 
                <PagamentoButton idProgetto={idProgetto} rispostaPagamentoUrl={rispostaPagamentoUrl}/>
            }
            {!pagamenti &&
                <Spin/>
            }           
        </Card>
    );
}

export default connect( ({UserStore}) => ({UserStore}) )(PagamentoCard);

PagamentoCard.propTypes = {
    idProgetto: PropTypes.string.isRequired,
    pagamenti: PropTypes.array,
    rispostaPagamentoUrl: PropTypes.string,
    oggetto: PropTypes.oneOf(['progetto', 'edizione']).isRequired,
    isPrimaEdizione: PropTypes.bool,
    domandaJson: PropTypes.object,
    saveJson: PropTypes.func,
    readOnly: PropTypes.bool
}

function showPagamentoButton(pagamenti, isPrimaEdizione, isEnte, exemptionData, exemption){
    if(!pagamenti || !Array.isArray(pagamenti) || isPrimaEdizione){ 
      return false;
    }

    if(exemption || !exemptionData){
        return false;
    }

    if(!isEnte){
        return false;
    }

    if(PagamentoService.existsPagamentoInStatus(pagamenti, [0,1])){
        return false;
    }
    
    return true;
}

/**
 * dobbiamo generare il messaggio per l'utente
 * controlliamo se c'è almeno un pagamento effettuato
 * in alternativa se ce n'è almeno uno in pending
 */
function getMessage(pagamenti, oggetto, isPrimaEdizione, isEnte, exemption, exemptionReason){
    if(!pagamenti){
        return null;
    }

    if(isEnte && exemption){
        return null;
    }

    const isPagamentoEffettuato = PagamentoService.existsPagamentoInStatus(pagamenti, [1]);
    const isPagamentoPending = PagamentoService.existsPagamentoInStatus(pagamenti, [0]);

    const specificMessage = !isEnte ? messages['backoffice'] : messages[oggetto];

    let IUV = null;
    if(isPagamentoEffettuato){
        const pagamentoEffettuato = pagamenti.filter( pagamento => pagamento.stato == 1).shift();
        IUV = _.get(pagamentoEffettuato, 'mypay_identificativo_univoco_versamento');
    }

    if(isPagamentoEffettuato || isPrimaEdizione){
        if(isPrimaEdizione && specificMessage.hasOwnProperty('primaEdizione')){
            return <Alert message={specificMessage.primaEdizione} type="success" showIcon />;
        }
        return <Alert 
            message={ `${specificMessage.effettuato} ${IUV ? `Identificativo Univoco Versamento: ${IUV}`: ''}` } 
            type="success" showIcon 
        />;
    }else if(isPagamentoPending){
        return<Alert message={specificMessage.pending} type="info" showIcon />;
    }else if(exemption){
        return <Alert message={`${specificMessage.esente} ${exemptionReason}`} type="success" showIcon />;
    }else{
        return <Alert message={specificMessage.daPagare} type="warning" showIcon />;
    }
}


