import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import TabellaRichiesteRuolo from '../components/TabellaRichiesteRuolo';


//import '../less/Repertorio.less';

@Wrapper({ route: 'TabellaRichiesteRuoloRoute', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewRichiestaRuolo', 'adminPermissions'])
class TabellaRichiestaRuoloRoute extends Component {

  state = {
    loading: false,
  }

  renderTitle() {
    return 'Richieste di ruolo'
  }

  render() {

    return (
      <div>
        <Card
          style={{ marginBottom: '12px', backgroundColor: '#fff' }}
          bordered={false}
          className="shadow">
          <h3 className="sectionTitle">{this.renderTitle()}</h3>
          <p>Lista delle richieste</p>
        </Card>
        <div style={{ padding: '12px' }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Richieste di ruolo</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <TabellaRichiesteRuolo ref="listTable" {...this.props} />

      </div>
    );
  }
}

export default TabellaRichiestaRuoloRoute;
