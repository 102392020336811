import React, { Component } from 'react';
import { connect } from 'react-redux';

import { message } from 'antd';

import CommonForm from '@common/components/form';

const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Apposita segnaletica',
              name: 'segnaletica',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Denominazione del soggetto',
              name: 'denominazione_soggetto',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Logo',
              name: 'logo',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Recapito telefonico',
              name: 'recapito_telefonico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Orario di apertura al pubblico',
              name: 'orario_apertura_pubblico',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Linea telefonica di rete fissa',
              name: 'telefono_fisso',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Collegamento ad Internet',
              name: 'collegamento_internet',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Pagina web',
              name: 'pagina_web',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'radio',
              label: 'Indirizzo e-mail',
              name: 'indirizzo_email',
              option_type: 'button', // normal|button
              // le opzioni come le selects
              options: [
                {
                  value: "1",
                  label: "Si"
                },
                {
                  value: "0",
                  label: "No"
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              type: 'submit',
              name: 'btn',
              text: 'Conferma',
              props: {
                icon: 'check',
                className: 'bg-green'
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'onSubmit',
}

class RequisitiForm extends Component {

  state = {
    loaded: false
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  submitForm(data) {
    let valid = true;
    Object.keys(data).forEach(k => {
      if (parseInt(data[k]) !== 1) valid = false
    })

    if (!valid) {
      message.error("Devi selezionare si per ogni voce");
      return;
    }

    if (this.props.onSubmit) this.props.onSubmit(data);
  }



  render() {
    return (
      <div>
        <CommonForm
          wrappedComponentRef={inst => (this.formRefBools = inst)}
          loading={this.props.loading || false}
          form_model={formLayout}
          values={this.props.values}
          onSubmit={(values) => {
            this.submitForm(values)
          }}
          readOnly={this.props.readOnly}

        />
      </div>
    );
  }
}

export default connect(({ GlobalStore }) => ({ GlobalStore }))(RequisitiForm);
