import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import Wrapper from '@common/decorators/Wrapper';
import CheckPermissions from '@common/decorators/CheckPermissions';
import DettagliDiscente from '@affidamento/components/registro/DettagliDiscente';

import ModelServices from '@affidamento/services/Corsi';
import IscrizioniServices from '@affidamento/services/Iscrizione';

import SpinnerLoading from '@components/SpinnerLoading';

@Wrapper({ route: 'Discente', hasSidebar: true, hasHeader: true, hasFooter: true })
@CheckPermissions(['viewDiscentiPerProgetto', 'adminPermissions'])
class DettagliDiscenteRoute extends Component {

  state = {
    loading: false,
    data: null,
    student: null,
  }

  componentDidMount() {
    this.fetch();
  }

  renderTitle() {
    return this.state.student?.anagrafica.cognome + ' ' + this.state.student?.anagrafica.nome;
  }


  fetch() {
    this.setState({ loading: true });
    ModelServices.detail({
      expand: [
        'media',
        'tutors',
       ].join(',')
    }, this.props.match.params.id_corso || this.props.match.params.id,
      res => {

        IscrizioniServices.detail({}, this.props.match.params.id,
          res2 => {
            this.setState(
              {
                data: res.data.data,
                student: res2.data.data,
                loading: false
              }
            );
          },
          () => {
            this.setState({ loading: false });
          }
        );

        //console.warn(res.data.data)
        //this.setState(
        //  {
        //    data: res.data.data,
        //    loading: false
        //  }
        //);
      },
      () => {
        this.setState({ loading: false });
      }
    );

  }

  render() {
    return (
      <div className="scheda">
        {this.state.loading ? <SpinnerLoading /> :
          <>
            <Card
              style={{ marginBottom: '12px', backgroundColor: '#fff' }}
              bordered={false}
              className="shadow">
              <h3 className="sectionTitle">{this.renderTitle()}</h3>
              <p>Registro elettronico</p>
            </Card>
            <div style={{ padding: '12px' }}>
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard">Bacheca</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/affidamento/registro">Registro Elettronico</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={"/affidamento/registro/" + this.props.match.params.id_corso}>{this.state.data?.titolo_progetto}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{this.renderTitle()}</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <DettagliDiscente {...this.props} corso={this.state.data} studente={this.state.student}/>
          </>
        }
      </div>
    );
  }
}

export default DettagliDiscenteRoute;
