import React, { Component } from 'react';
import { message, notification } from 'antd';

import CommonForm from '@common/components/form';
import format from '@common/utils/formatters/_';
import CapacitaGestionaliServices from '@accreditamento/services/CapacitaGestionali';


const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Funzione',
              name: 'id_tipo_funzione',
              options_props: 'funzioni',
              onChange: 'setFunzione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          items: [
            {
              type: 'select',
              label: 'Esperienze nella funzione',
              name: 'esperienze_nella_funzione',
              options_props: 'dettagli_funzioni',
              mode: 'multiple',
              onChange: 'setEsperienzaFunzione',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Altro:',
              name: 'altro_esperienza_funzione',
              props: {
                size: 'large'
              },
              showIf: [
                {
                  type: 'prop_func',
                  func: 'needToAddAltro'
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 16,
          items: [
            {
              type: 'select',
              label: 'Tipo rapporto di lavoro',
              type_string: 'text', // field type
              name: 'id_tipo_rapporto_lavoro',
              options_props: 'tipo_rapporto_lavoro',
              onChange: 'setRapportoLavoro',
              onload: 'setRapportoLavoro',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          items: [
            {
              type: 'textarea',
              label: 'Altro:',
              name: 'altro_rapporto_lavoro',
              props: {
                size: 'large'
              },
              showIf: [
                {
                  type: 'prop_func',
                  func: 'needToAddAltroRapporto'
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        },
        {
          span: 8,
          items: [
            {
              type: 'integerwithzero',
              label: 'Durata (anni)',
              type_string: 'number', // field type
              name: 'durata_rapporto_lavoro',
              min: 1,
              props: {
                size: 'large'
              },
              showIf: [
                {
                  type: 'prop_func',
                  func: 'isNotDipendente'
                }
              ],
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                },
                {
                  validator: 'minUno'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 12,
          items: [
            {
              type: 'select',
              label: 'Titolo di studio',
              type_string: 'text', // field type
              name: 'titolo_studio',
              options_props: 'tipo_titolo_studio',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'select',
              label: 'Sedi',
              name: 'sedi',
              options_props: 'sedi',
              mode: 'multiple',
              props: {
                size: 'large'
              },
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio'
                }
              ],
            }
          ]
        }
      ]
    },
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'CCNL Operatore Formazione Professionale',
                name: 'ccnl_operatore_formaz_profess',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "Si",
                    label: "Si"
                  },
                  {
                    value: "No",
                    label: "No"
                  }
                ],
                showIf: [
                  {
                    type: 'prop_func',
                    func: 'needOperatoreProfessionale'
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ],
          }
        ],
    },/*
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Autorizzazione al trattamento dei dati personali secondo i termini di legge',
                name: 'autorizz_tratt_dati',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "Si",
                    label: "Si"
                  },
                  {
                    value: "No",
                    label: "No"
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ],
          }
        ],
    },*/
    {
        cols: [
          {
            items: [
              {
                type: 'radio',
                label: 'Dichiarazione della veridicità dei dati riportati, in base al D.P.R. 445 del 28/12/2000.',
                name: 'dichiaraz_veridicita_dati',
                option_type: 'button', // normal|button
                options: [
                  {
                    value: "Si",
                    label: "Si"
                  },
                  {
                    value: "No",
                    label: "No"
                  }
                ],
                props: {

                },
                validations: [
                  {
                    type: 'required',
                    error_message: 'Campo obbligatorio'
                  }
                ],
              }
            ],
          }
        ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'submit',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            }
          ]
        }
      ]
    },
  ],
  onSubmit: 'submitted',
};


class UpdateModel extends Component {

  state = {
    loading: false
  }



  getFormLayout() {

  }

  componentDidMount () {

    let selected_name = format.replaceStub( this.props.model.id_tipo_funzione, 'tipo_funzioni', this.props.GlobalStore );
    this.setState({
      selected_funzione: selected_name,
      model_anagrafica: this.props.model.anagrafica,
      altro_selected: JSON.parse(this.props.model.esperienze_nella_funzione).find(el => el === "-1") ? true : false,
      altro_rapporto_selected: this.getAnaRapportoLavoro(this.props.model.anagrafica).id_tipo_rapporto_lavoro == 10 ? true : false,
      not_dipendente: (this.getAnaRapportoLavoro(this.props.model.anagrafica).id_tipo_rapporto_lavoro != 1 && this.getAnaRapportoLavoro(this.props.model.anagrafica).id_tipo_rapporto_lavoro != 3)? true : false
    });
  }




  updateQueryStr() {

  }




  async confirm(data) {

    if(!data.dichiaraz_veridicita_dati || data.dichiaraz_veridicita_dati === 'No') {
      message.error("Devi accettare l'autorizzazione al trattamento dei dati personali e la dichiarazione della veridicità dei dati riportati");
      return;
    }

    if(!this.validate(data)) {
      return;
    }

    if(!this.state.loading) {
      await this.setState({loading: true});

      CapacitaGestionaliServices.updateOrganigramma( data, this.props.model.id, this.props.accreditamento.id, ()=>{
        this.setState({loading: false});
        if(this.props.onUpdate) this.props.onUpdate();
      }, () => this.setState({loading: false}) )

      //console.log('dati inviati', data)

    }
  }

  getDettagliFunzioni() {
    try {
      let values = this.props.GlobalStore.stubs.dettagli_funzioni[this.state.selected_funzione].istruzione_esperienza.map((el,i) => {
        return {
          key: 'dettaglio_'+i,
          value: el,
          label: el
        }
      })
      return [...values, {key: 'dettaglio_altro', value: -1, label: 'Altro'}]
    } catch(e) {
      return []
    }
  }

  selectedFunzione(value) {
    let selected_name = format.replaceStub( value, 'tipo_funzioni', this.props.GlobalStore );

    if(this.state.selected_funzione !== value) {
      this.formRef.props.form.setFields(
        {
          esperienze_nella_funzione: {
            value: []
          }
        });
    }
    this.setState({selected_funzione: selected_name});
  }

  selectedEsperienzaFunzione(value) {

    if(value.indexOf("-1") !== -1) {
      this.setState({altro_selected: true});
    } else {
      this.setState({altro_selected: false});
      this.formRef.props.form.setFields(
        {
          altro_esperienza_funzione: {
            value: ''
          }
        });
    }
  }

  selectedRapportoLavoro(value) {

    if(value == 10) {
      this.setState({altro_rapporto_selected: true});
    } else {
      this.setState({altro_rapporto_selected: false});
      this.formRef.props.form.setFields(
        {
          altro_rapporto_selected: {
            value: ''
          }
        });
    }

    if(value != 1 && value != 3) {
      this.setState({not_dipendente: true});
    } else {
      this.setState({not_dipendente: false});
      this.formRef.props.form.setFields(
        {
          not_dipendente: {
            value: null
          }
        });
    }
  }

  getSedi() {
    return this.props.sedi.map(el => {
      return {
        key: 'sede_' + el.enteSede.id,
        label: format.getIndirizzo(el.enteSede.indirizzo, this.props.GlobalStore),
        value: el.enteSede.id
      }
    })
  }

  getAnaRapportoLavoro(ana) {
    try{
      return ana.rapportoLavoro.find(el => el.id_ente === this.props.accreditamento.id_ente)
    } catch(e) {
      return {}
    }
  }

  getValues() {
    let vals = Object.assign({}, this.props.model);
    try {
      vals.esperienze_nella_funzione = JSON.parse(vals.esperienze_nella_funzione)
      vals.sedi = vals.sedi.map(el => ""+el.id)
    } catch(e) {
      vals.esperienze_nella_funzione = [];
      vals.sedi = [];
    }
    vals = {
      ...vals,
      ...this.getAnaRapportoLavoro(this.props.model.anagrafica)
    }
    return vals;
  }



  /**
   * verifica se l'accreditamento è anche formazione professionale
   * @return {Boolean} [description]
   */
  isIEFP() {
    let id_fp = this.props.GlobalStore.stubs.tipo_accreditamento.find(el => el.label === 'Istruzione e Formazione Professionale');
    try {
      return this.props.accreditamento.accreditamentotipologie.find(el => el.id_tipologia === id_fp.value) ? true : false
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  isFCorFS() {
    let id_fp = this.props.GlobalStore.stubs.tipo_accreditamento.map(el => {
      if (el.label.toUpperCase() === 'FORMAZIONE CONTINUA' || el.label.toUpperCase() === 'FORMAZIONE SUPERIORE') {
        return parseInt(el.value);
      } else {
        return null;
      }
    });
    try {
      return this.props.accreditamento.accreditamentotipologie.filter(el => id_fp.indexOf(el.id_tipologia) !== -1).length > 0 ? true : false
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  validate(data) {
    // faccio in backend la verifica sul ruolo/anagrafica
    let v1 = this.validateFunzione(data.id_tipo_funzione, data.sedi);
    let v2 = this.validateMaxTwoSedi(data.id_tipo_funzione, data.sedi);
    let v3 = this.validateRoleNumbers(data.id_tipo_funzione);
    return v1 && v2 && v3;
  }

  validateFunzione(id_funzione, sedi) {
    sedi = sedi.map(s => parseInt(s));
    let to_validate_single = [
      'Responsabile del processo di direzione',
      'Responsabile dei processi economico amministrativi',
      'Responsabile del processo di progettazione',
      'Responsabile del processo di erogazione dei servizi'
    ]

    if (to_validate_single.includes(format.replaceStub(id_funzione, 'tipo_funzioni', this.props.GlobalStore))) {
      // valuta che sia uno solo

      let funzione = this.props.funzioni.find(f => f.id !== this.props.model.id && f.id_tipo_funzione === id_funzione);
      let invalid_sede = funzione?.sedi.filter(sede => sedi.some(s => parseInt(s) === sede.id))  //sedi che hanno gia un elemento per la funzionzione selezionata
        .map(sede => this.props.sedi.find(s => s.enteSede.id === sede.id).enteSede);            // prende l'oggetto con tutte le informazioni da this.props.sedi

      if (invalid_sede && invalid_sede.length > 0) {
        notification.error({
          placement: 'topLeft',
          duration: 5,
          message: "La funzione non può essere inserita nelle seguenti sedi:",
          description: invalid_sede.map(sede => sede.indirizzo.via + " " + sede.indirizzo.ncivico).join(", "),
        })

        return false;
      }
    }

    return true;
  }

  validateMaxTwoSedi(id_funzione, sedi) {

    if (!this.isIEFP()) return true;

    let to_validate_max_double = [
      'Responsabile del processo di direzione',
      'Responsabile dei processi economico amministrativi',
      'Responsabile del processo di erogazione dei servizi',
      'Tutor'
    ];

    if (to_validate_max_double.indexOf(format.replaceStub(id_funzione, 'tipo_funzioni', this.props.GlobalStore)) !== -1) {

      let rappr_legale_cf = this.props.accreditamento.ente.rappresentanteLegale.cf;
      if (rappr_legale_cf === this.state.model_anagrafica.cf) {
        notification.error({
          placement: 'topLeft',
          duration: 5,
          message: "La funzione non può corrispondere al rappresentante legale",
          description: '',
        })
        return false;
      }

      if (sedi.length > 2) {
        notification.error({
          placement: 'topLeft',
          duration: 5,
          message: "La funzione non può essere inserita in più di 2 sedi",
          description: '',
        })
        return false;
      }
    }

    return true;
  }


  validateRoleNumbers(id_funzione) {
    if (!this.isFCorFS()) {
      return true;
    }

    let id_responsabile = this.props.GlobalStore.stubs.tipo_funzioni.find(el => el.label === 'Responsabile del processo di direzione').value;
    let other_id_respobsabili = this.props.GlobalStore.stubs.tipo_funzioni.filter(el => el.label.startsWith('Responsabile ') && el.label !== 'Responsabile del processo di direzione').map(e => e.value);
    let id_tutor = this.props.GlobalStore.stubs.tipo_funzioni.find(el => el.label === 'Tutor').value;

    let my_roles = this.props.funzioni.filter(el => el.anagrafica.id === this.state.model_anagrafica.id && el.id !== this.props.model.id);
    my_roles.push({id_tipo_funzione: id_funzione});

    let is_also_responsabile = my_roles.find(r => r.id_tipo_funzione === id_responsabile);
    let other_my_resp = my_roles.filter(r => other_id_respobsabili.includes(r.id_tipo_funzione));
    let is_also_tutor = my_roles.find(r => r.id_tipo_funzione === id_tutor);

    let max = is_also_responsabile ? 2 : 3;
    let invalid = (is_also_responsabile && is_also_tutor && other_my_resp.length > 0) ||  // se sono resp di direzione e almeno un altro resp non posso essere tutor
                  (!is_also_responsabile && is_also_tutor && other_my_resp.length > 2) || // se non sono resp di direzione e ho piu di 2 resp non posso essere tutor
                  my_roles.length > max;

    if (invalid) {
      notification.error({
        placement: 'topLeft',
        duration: 5,
        message: "Il tutor non può ricoprire tutti questi ruoli",
        description: 'Ne può avere massimo: ' + max,
      })
      return false;
    }
    return true;
  }

  render () {
    return <div>
      <h4>Dettagli funzione</h4>
        <CommonForm
          wrappedComponentRef={inst => (this.formRef = inst)}
          loading={this.state.loading || false}
          form_model={formLayout}
          values={this.getValues()}
          submitted={(dati) => this.confirm(dati)}
          funzioni={this.props.GlobalStore.stubs.tipo_funzioni}
          setFunzione={(value)=>this.selectedFunzione(value)}
          readOnly={this.props.readOnly}

          setEsperienzaFunzione={(value)=>this.selectedEsperienzaFunzione(value)}
          setRapportoLavoro={(value)=>this.selectedRapportoLavoro(value)}

          tipo_rapporto_lavoro={this.props.GlobalStore.stubs.tipo_rapporto_lavoro}
          tipo_titolo_studio={this.props.GlobalStore.stubs.tipo_titolo_studio}

          needOperatoreProfessionale={()=>this.isIEFP()}

          needToAddAltro={()=>this.state.altro_selected}
          needToAddAltroRapporto={()=>this.state.altro_rapporto_selected}
          isNotDipendente={()=>this.state.not_dipendente}

          minUno={(rule, value, cb) => {
            if (parseInt(value) < 1) cb("Minimo 1");
            else cb()
          }}

          sedi={this.getSedi()}
          dettagli_funzioni={this.getDettagliFunzioni()}
        />
    </div>
  }

}
export default UpdateModel;