import React, { Component } from 'react';

import {
    Button, Drawer, Table, Tooltip
} from 'antd';


import EditMetodologie from './EditMetodologie';
import AttrezzatureTable from './AttrezzatureTable';


class Metodologie extends Component {

    state = {
        open_drawer_edit_metodologie: false,
        open_drawer_edit_attrezzature: false,
        element_opened: {},
        metodologieList: []
    };



    componentDidMount() {
        this.load(this.props)
    }

    load(props) {
        this.setState({
            loading: false,
            metodologieList: props.metodologieList
        });
    }

    update(data) {
       let list = this.state.metodologieList;
        //var index = this.state.metodologieList.map(el => el.annuity).indexOf(this.state.annuity_opened);
        var index = -1; //this.state.annuity_opened;
        list['list'] = {};
        if(typeof list['list'] !== 'undefined'){
            list['list'].annuity = data.annuity
            list['list'].methods = data.methods
            list['list'].organogramList = data.organogramList
            list['list'].teacherList = data.teacherList
            list['list'].specialUserList = data.specialUserList
        }
        this.setState(
            {
                //open_drawer_edit_metodologie: typeof data !== 'undefined' ? false : true,
                //open_drawer_edit_metodologie: true,
                metodologieList: list,
                //element_opened: {},
                //annuity_opened: null
            }, () => { this.props.saveMetodologieList(list) }
        );
    }

    closeDrawerEditMetodologie(childName){
        this.setState({ open_drawer_edit_metodologie: false });
    }

    updateAttrezzature(data) {
        let list = this.state.metodologieList;
         var index = this.state.metodologieList.map(el => el.annuity).indexOf(this.state.annuity_opened);
                list[index].equipmentList = data.equipmentList
                list[index].audioVisualList = data.audioVisualList
         this.setState(
             {
                open_drawer_edit_attrezzature: false,
                 metodologieList: list,
                 element_opened: {},
                 annuity_opened: null
             }, () => { this.props.saveMetodologieList(this.state.metodologieList) }
         );
     }



    open(annuity, element, action) {
        this.setState({
            annuity_opened: annuity,
            element_opened: this.props.progetto.scheda_progetto_json.teachingProgramming.AfcMethodAndHumanResourcesFormSection.methodAndHumanResourcesList
        }, () => this.setState({ ['open_drawer_' + action]: true }))
    }

    render() {
        return (
            <div>
                <Button type="primary" size="large" className="green-form-button m5h" onClick={() => this.open('list', this.state.metodologieList, 'edit_metodologie')}>Gestisci</Button>
                <Drawer
                    title="METODOLOGIE E RISORSE UMANE COINVOLTE"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ open_drawer_edit_metodologie: false, annuity_opened: null })}
                    visible={this.state.open_drawer_edit_metodologie}
                    width={window.innerWidth - (window.innerWidth/100*5)}
                    destroyOnClose={true}
                >
                    <EditMetodologie
                        {...this.props}
                        close={() => this.setState({ open_drawer_edit_metodologie: false })}
                        metodologie={this.state.element_opened}
                        annuity={this.state.annuity_opened}
                        onUpdate={(data) => {
                            this.update(data);
                        }}
                        onCloseDrawerEditMetodologie={ (childName) => this.closeDrawerEditMetodologie(childName) }
                        readOnly={this.props.readOnly}
                    />
                </Drawer>

            </div>
        );
    }
}
export default Metodologie;