import { Tabs, Card, Row, Col, Button, Popconfirm, notification, Modal } from "antd";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import CalendarioCorsi from "./CalendarioCorsi";

import PannelloLezione from "./PannelloLezione";
import PresenzeGiornaliere from "./PresenzeGiornaliere";
import TabellaDocenti from "./TabellaDocenti";
import TabellaValutazioniDiscente from "./TabellaValutazioniDiscente";
import TabellaProveDiValutazione from "./TabellaProveDiValutazione";

import checkPermission from '@common/utils/check/permissions';
import PannelloInfoPresenze from "./PannelloInfoPresenze";
import ProgrammazioneDidatticaForm from "@affidamento/forms/formulario/ProgrammazioneDidatticaForm";

import ModelServices from '@affidamento/services/Corsi';
import SpinnerLoading from "@common/components/SpinnerLoading";
import Discenti from "./Discenti";

import affidamentoStatic from '@common/utils/static/affidamento';
import eventBroker from '@common/utils/services/EventBroker';

const TabPane = Tabs.TabPane;


export default class RegistroElettronico extends Component {

  static propTypes = {
    isEditable: PropTypes.bool,
  }

  state = {
    current_key: "1",
    lesson: null,
    loading: true,
    scheda_progetto_json: this.props.corso?.scheda_progetto_json,
  }

  componentDidMount() {
    if (this.props.location.state?.tab) {
      this.setState({
        current_key: '' + this.props.location.state.tab,
      });
    }
    this.loadNextLesson();
  }

  loadNextLesson() {
    if(this.state.lesson != null)
      this.setState({ loading: true, lesson: null });

    if (this.props.corso && checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione'])) {

      let params = checkPermission.hasPermission(this.props.UserStore.user, ['manageMyPresenze']) ?
        { expand: [
            'conIscrizioneLezioneDiscente',
          ].join(',')
        } : {};

      ModelServices.prossimaLezione(params,
        this.props.corso.id, res => {
          this.setState({
            lesson: res.data.data,
            loading: false,
          });
        },
        () => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  refreshInline(data) {
    this.setState({
      lesson: { ...this.state.lesson, ...data }
    }
    );
  }

  isEditableState() {
    return this.props.corso && this.props.corso.stato_flusso !== affidamentoStatic.stato_flusso_progetto.progetto_completato &&  
    this.props.corso.stato_flusso !== affidamentoStatic.stato_flusso_progetto.revoca_corso &&
    this.props.corso.stato_flusso !== affidamentoStatic.stato_flusso_progetto.conferma_revoca &&
    this.props.corso.stato_flusso !== affidamentoStatic.stato_flusso_progetto.corso_sospeso &&
    this.props.corso.stato_flusso !== affidamentoStatic.stato_flusso_progetto.conferma_sospeso
  }

  predisponiRegistro() {
    this.setState({ loading: true });
    ModelServices.predisponiRegistro(this.props.corso.id, { expand: 'countIscritti,countDocenti,countLezioni' }, res => {
      eventBroker.trigger('reloadCorso', res.data.data);
      notification.success({message: 'Registro predisposto con successo'});
      this.setState({
        loading: false,
        showResult: res.data.data.result != null,
      });
    },
      () => {
        notification.error({message: 'Errore nella predisposizione del registro'});
        this.setState({
          loading: false,
        });
      }
    );
  }

  cancellaRegistro() {
    this.setState({ loading: true });
    ModelServices.cancellaRegistro(this.props.corso.id, { expand: 'countIscritti,countDocenti,countLezioni' }, res => {
      eventBroker.trigger('reloadCorso', res.data.data);
      this.setState({
        loading: false,
      });
    },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  }


  renderTabBar(props, DefaultTabBar) {
    return (
      <Sticky bottomOffset={80}>
        {({ style }) => (
          <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
        )}
      </Sticky>
    )
  }

  render() {
    return (<div style={{ marginTop: 12, marginBottom: 12 }}>

      <Row type="flex" className="ant-flex-margin" style={{ marginBottom: 12, marginTop: 6 }}>
        {checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione']) &&
          <Col style={{ flex: "1 1 70%" }} >
            <Row style={{ height: "100%", flexDirection: 'column' }} type="flex" >
              {this.state.loading ? <SpinnerLoading /> :
                <PannelloLezione {...this.props}
                 lesson={this.state.lesson} 
                 loading={this.state.loading} 
                 isNextLesson={true}
                 refreshInline={(data) => this.refreshInline(data)} />
              }

            {checkPermission.hasPermission(this.props.UserStore.user, ['createRegistro']) &&
              this.props.corso?.stato_flusso >= affidamentoStatic.stato_flusso_progetto.avvio_progetto_predisposto &&
              this.props.corso?.stato_flusso < affidamentoStatic.stato_flusso_progetto.corso_in_erogazione &&
              <Card style={{ flex: "1 1 auto", marginTop: 12 }}
                bordered={false}
                className="shadow">
                <div>
                  <Row>
                    <Col lg={24} sm={24} xs={24}>
                      <div id="actions">
                        <h4 className="p10h">Predisposizione registro elettronico</h4>
                        {!this.props.corso.registro_predisposto &&
                          <Popconfirm placement="leftTop" title="Sicuro di voler predisporre il registro?" onConfirm={() => this.predisponiRegistro()} okText="Si" cancelText="No">
                            <Button icon="profile" ghost className="m5h m5w" type="primary" loading={this.state.loading}>Predisponi registro elettronico</Button>
                          </Popconfirm>
                        }

                        {this.props.corso.registro_predisposto &&
                          <Popconfirm placement="leftTop" title="Sicuro di voler cancellare il registro predisposto?" onConfirm={() => this.cancellaRegistro()} okText="Si" cancelText="No">
                            <Button icon="delete" ghost className="m5h m5w" type="danger" loading={this.state.loading}>Cancella registro predisposto</Button>
                          </Popconfirm>
                        }

                        {this.state.showResult &&
                          <Modal
                            title="Problemi"
                            visible={this.state.showResult}
                            onCancel={() => this.setState({ showResult: false })}
                            footer={false}
                          >
                          <div>
                            <ul>
                              {Object.entries(this.props.corso.result).map(entry => (
                                <li> {entry[0]}: {Object.entries(entry[1]).map(error => <span>{error[0]} {error[1]}</span>)} </li>
                              ))}
                            </ul>
                          </div>
                          </Modal>
                        }

                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            }

            </Row>
          </Col>
        }

        {checkPermission.hasPermission(this.props.UserStore.user, ['manageMyPresenze']) && this.props.studente &&
          <Col style={{ flex: "1 1 30%" }} >
            <PannelloInfoPresenze {...this.props} />
          </Col>
        }
      </Row>



      <Card
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        bordered={false}>
        <StickyContainer>
          <Tabs
            onChange={(k) => this.setState({ current_key: "" + k })}
            defaultActiveKey={"1"}
            activeKey={this.state.current_key}
            size="medium"
            renderTabBar={this.renderTabBar}>
              <TabPane
              tab={<span>Dettagli</span>} key="1">
              <div id="dettagli">
                {this.state.scheda_progetto_json &&
                  <ProgrammazioneDidatticaForm
                    {...this.props}
                    teachingProgramming={this.state.scheda_progetto_json.teachingProgramming}
                    readOnly={true}
                    anteprima={true}
                    hiddenActions={true}
                  />
                }
              </div>
            </TabPane>
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewLezione']) &&
              <TabPane
                tab={<span className={this.props.corso?.countLezioni > 0 ? 'checked' : 'default'}>Calendario<br /></span>} key="2">
                <div id="calendario">
                  <CalendarioCorsi
                    {...this.props}
                    tab={this.state.current_key}
                    id_corso={this.props.match.params.id}
                    studente={this.props.studente}
                    updateCallback={() => this.loadNextLesson()}
                    isEditableState={this.isEditableState()}
                    />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewDocentiPerProgetto']) &&
              <TabPane
                tab={<span className={this.props.corso?.countDocenti > 0 ? 'checked' : 'default'}>Docenti</span>} key="3">
                <div id="docenti">
                  <TabellaDocenti {...this.props} isEditableState={this.isEditableState()} />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewDiscentiPerProgetto']) &&
              <TabPane
                tab={<span className={this.props.corso?.countIscritti > 0 ? 'checked' : 'default'}>Discenti</span>} key="4">
                <div id="discenti">
                  <Discenti {...this.props}  isEditableState={this.isEditableState()} />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewMyValutazioni']) &&
              <TabPane
                tab={<span>Valutazioni</span>} key="5">
                <div id="valutazioni">
                  <TabellaValutazioniDiscente {...this.props} />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewOwnValutazioni']) &&
              <TabPane
                tab={<span>Prove di valutazione</span>} key="6">
                <div id="prove">
                  <TabellaProveDiValutazione {...this.props} />
                </div>
              </TabPane>
            }
            {checkPermission.hasPermission(this.props.UserStore.user, ['viewOwnPresenze']) &&
              <TabPane
                tab={<span>Registro</span>} key="7">
                <div id="registro">
                  <PresenzeGiornaliere {...this.props} allowRedirect={true} corso={this.props.corso} />
                </div>
              </TabPane>
            }
          </Tabs>
        </StickyContainer>
      </Card>
    </div>)
  }
}

