import TableComponent from '@common/components/table';
import { Button, Card, Drawer, Modal, Popconfirm, Row, Tooltip, notification } from 'antd';
import React, { Component } from 'react';
import ElencoRegionaleServices from '../services/ElencoRegionale'
import UserServices from '@common/services/User';
import queryString from 'query-string';
import FilterForm from '@common/components/filterForm';
import ExportTable from './ExportTable';
import InsertEnteAccreditato from './InsertEnteAccreditato';
import FormUtentiEnti from './FormUtentiEnti';
import _ from '@common/utils/formatters/_';
import moment from 'moment';
import FileSaver from 'file-saver';
import { message } from 'antd';
import checkPermission from '@common/utils/check/permissions';

const delay = ms => new Promise(res => setTimeout(res, ms));

const model = (component) => {
    return {
        with_server_pagination: true,
        columns: [
            {
                title: '',
                dataIndex: '',
                sorter: false,
                key: 'actions',
                width: '60px',
                render: element => {
                    return <div>
                        {
                            component.props.UserStore.user.ente.id === element.id 
                            ? <Tooltip placement="topRight" title={`Ente operativo`}>
                                <span className={"pallino green"}></span>
                              </Tooltip>
                            : <span className={"pallino invisible"}></span>
                        }
                        {
                            <Tooltip placement="top" title="Modifica">
                                <Button className="m2w" size="small" shape="circle" icon="edit" onClick={() => component.open(element)} />
                            </Tooltip>
                        }
                        {
                            component.props.UserStore.user.ente.id !== element.id
                            ? <Tooltip placement="top" title="Opera per questo ente">
                                <Button className="m2w" size="small" shape="circle" icon="swap" onClick={() => {
                                    const userId = component.props.UserStore.user.id;
                                    const enteId = element.id;
                                    UserServices.entiSwap(userId, enteId, {}, res => {
                                        notification.success({message: 'Ente operativo cambiato con successo'});
                                        component.setState({
                                            loading: false
                                        });
                            
                                    }, (e) => {
                                        console.error(e);
                                        component.setState({ loading: false });
                                    })
                                    window.scrollTo({top: 0, behavior: 'smooth' });
                                    delay(1500).then(() => window.location.reload());
                                }} />
                              </Tooltip>
                            : null
                        }
                    </div>
                },
            },
            {
                title: 'Denominazione',
                dataIndex: '',
                sorter: true,
                key: 'denominazione',
                render: element => {
                    return <div>{element.organization.denominazione}</div>
                },
                props:{
                    disabled: component.state.edit == false ? true : false
                }
            },
            {
                title: 'Partita IVA',
                dataIndex: '',
                sorter: false,
                key: 'indirizzo_sede_legale',
                render: element => <div>{element.organization.partita_iva}</div>
            },
            {
                title: 'Codice fiscale',
                dataIndex: '',
                sorter: false,
                key: 'comune_sede_legale',
                render: element => <div>{element.organization.cfente}</div>,
            },
        ],
        expandable: false,
    }
}

const filterFormModel = (component) => {

    return {
        layout: 'vertical',
        multistep: false,
        rows: [
            {
                cols: [
                    {
                        span: 8,
                        items: [
                            {
                                type: 'string',
                                label: 'Denominazione',
                                name: 'denominazione',
                            }
                        ]
                    },
                ]
            },
            {
                cols: [
                    {
                        items: [
                            {
                                name: 'submit',
                                type: 'submit',
                                text: 'Cerca',
                                btn_type: 'primary'
                            }
                        ]
                    }
                ]
            },
        ]
    };
}

class ListUtentiEnti extends Component {

    default_order = 'id'
    base_url = '/accreditamento/myenti'

    state = {
        loading: false,
        open_drawer: false,
        editing_id: null,
        data: {}
    }

    componentDidMount() {
        this.fetch(this.props.location.search)
    }

    /**
    * Se cambia la stringa aggiorna
    * @param  {[type]} newProps [description]
    * @return {[type]}          [description]
    */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.fetch(this.props.location.search)
        }
    }

    refresh() {
        this.fetch(this.props.location.search)
    }

    /**
    * Chiamato da sort in tabella
    * @param  {[type]} obj [description]
    * @return {[type]}     [description]
    */
    updateQueryStr(obj) {
        _.formatSortChange(this.props, this.base_url, obj);
    }

    fetch(query) {
        const userId = this.props.UserStore.user.id;
        let { params, pager } = _.getFilterParam(query, this.default_order);
        this.setState({ loading: true });
        UserServices.entiList(userId, params, res => {
            let data = _.getResponse(res);
            this.setState({
                loading: false,
                data: data
            }, () => this.refs.table_component.updateTableData(
                data.data,
                data.current,
                data.total,
                pager)
            );

        }, (e) => {
            this.setState({ loading: false });
        })
    }

    open(element) {
        console.warn("open element", element)
        this.setState({
            edit: false,
            element_opened: element
        },
            () => {
                this.setState({ open_drawer: true })
            })
    }

    render() {
        return (
            <div>
                <FilterForm
                    formLayout={filterFormModel(this)}
                    base_url={this.base_url}
                    search={this.props.location.search}
                    history={this.props.history}
                    province={this.props.GlobalStore.stubs.provincia}
                />
                <Card
                    className="shadow no-pad"
                    bordered={false}
                >
                    <Row type="flex" justify="start" className="m10h">
                        {
                        checkPermission.hasPermission(this.props.UserStore.user, [ 'entePermissions']) &&
                        <div className="m5h m5w">
                            <Button className="m5h bg-green" icon="plus" onClick={() => this.setState({ open_drawer: true, edit: true })} loading={this.state.loading}>Inserisci nuovo ente</Button>
                        </div>
                        }
                    </Row>
                    <TableComponent
                        data={this.state.data}
                        size="medium"
                        ref="table_component"
                        loading={this.state.loading}
                        with_server_pagination={true}
                        model={model(this)}
                        updatePage={(newPage) => this.updateQueryStr({ page: newPage })}
                        updateSort={(sort, order) => this.updateQueryStr({ sort: sort })}
                    />
                    {this.state.open_drawer &&
                        <Drawer
                            placement="right"
                            closable={true}
                            onClose={() => this.setState({ open_drawer: false, element_opened: null, edit: false})}
                            visible={this.state.open_drawer}
                            width={window.innerWidth - (window.innerWidth / 100 * 5)}
                            destroyOnClose={true}
                        >
                            <FormUtentiEnti
                                {...this.props}
                                edit={this.state.edit}
                                onUpdate={() => {
                                    this.setState({ open_drawer: false, element_opened: null, edit: false })
                                    this.refresh()
                                }}
                                element_opened={this.state.element_opened}
                            ></FormUtentiEnti>
                        </Drawer>
                    }
                </Card>
            </div>)
    }
}

export default ListUtentiEnti;