import React, { Component } from 'react';
import { connect } from "react-redux";

import { Button, Popconfirm, Tag } from 'antd';

import ModelServices from '@accreditamento/services/ComunicazioneOstativa';

import moment from 'moment';

import FileSaver from 'file-saver';

import comunicazioneOstativaStatic from '@common/utils/static/comunicazioneOstativa';

import DocSpan from '@common/components/DocSpan';

import checkPermission from '@common/utils/check/permissions';

import SingleControdeduzione from './SingleControdeduzione';

/*
const formLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'simple_upload',
              name: 'dragger',
              accept: '.p7m,.pdf',
              action: '/', // url upload
              listType: 'text', // text,picture,picture-card
              multiple: false,
              fileList: 'fileListProp', // funzione che ritorna la lista dei file correntemente caricati
              customRequest: 'customUploadRequest', // sovrascrive upload
              onRemove: 'onRemove', // funzione richiamata alla cancellazione del file
              text: "Carica",
              props: {
                showUploadList: false
              }
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
};


const formSelLayout = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          items: [
            {
              type: 'textarea',
              label: 'Note',
              name: 'note',
              props: {

              }
            },
          ],
        },
      ],
    }
  ],
  onSubmit: 'submitted',
};
*/


class ComunicazioneOstativa extends Component {

  state = {
    loaded: false,
    open_modal_com: false,
    accreditamento: {
      id: null
    },
    comunicazione: {
      id: null
    }
  }

  componentDidMount() {
    if(this.props.accreditamento.id) this.loaded(this.props);
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if(newProps.accreditamento.id &&
      (newProps.accreditamento !== this.props.accreditamento ||
        newProps.comunicazione !== this.props.comunicazione)) this.loaded(newProps)
  }

  loaded( props ) {
    this.setState({accreditamento: props.accreditamento, comunicazione: props.comunicazione, loaded: true});
  }




  /**
   * Carica il file
   * @return {[type]} [description]
   */
  upload() {

    ModelServices.upload(this.props.accreditamento.id, this.props.comunicazione.id, {
      filename: 'file',
      note: this.state.note || ""
    }, { ...this.state.file, chiave: 'file' }, () => {

      this.props.refresh()
      this.setState({ open_modal_com: false });

    }, () => null)
  }

  async uploadFile(file) {
    await this.setState({ file: { file: file, name: file.name }, open_modal_com: true });

    return;

  }

  saveBlob( blob ) {
    const _blob = new Blob([blob], {type: 'application/pdf'});
    FileSaver.saveAs(_blob, "nomina");

    this.props.refresh();
  }

  canDownload(){
    return ( checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) &&
      //this.props.UserStore.user.id == this.props.comunicazione.id_user &&
        (
          this.props.comunicazione.stato === comunicazioneOstativaStatic.stato.in_corso ||
          this.props.comunicazione.stato === comunicazioneOstativaStatic.stato.da_firmare
        )
    ) ? true : false
  }

  canUpload() {
    return ( checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) &&
      //this.props.UserStore.user.id === this.props.comunicazione.id_user &&
      this.props.comunicazione.stato === comunicazioneOstativaStatic.stato.da_firmare
    ) ? true : false
  }

  canSend(){
    return ( checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) &&
      this.props.comunicazione.stato === comunicazioneOstativaStatic.stato.in_corso //comunicazioneOstativaStatic.stato.firmata
    ) ? true : false
  }

  canView() {
    return (
      checkPermission.hasPermission(this.props.UserStore.user, ['dirigentePermissions', 'adminPermissions']) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['istruttorePermissions']) && this.props.UserStore.user.id === this.props.comunicazione.id_user) ||
      (checkPermission.hasPermission(this.props.UserStore.user, ['entePermissions']) && this.props.UserStore.user.id === this.props.accreditamento.id_user)
    )  ? true : false
  }



  renderActions() {
    return <div>
        {/*this.canDownload() ?
        <div>
          <p>Scarica il documento della comunicazione ostativa</p>
          <Button className="m5h" type="primary" icon="file" onClick={()=>
            ModelServices.download(
              this.props.accreditamento.id,
              this.props.comunicazione.id,
              (res)=>this.saveBlob(res.data),
              (err) => console.log(err)
            )}>Scarica file</Button>
        </div> : null }

        {this.canUpload() ?
        <div>
          <h4>Carica il documento</h4>
          <CommonForm
            wrappedComponentRef={inst => (this.formRef = inst)}
            loading={this.state.loading || false}
            form_model={formLayout}
            values={{
            }}
            fileListProp={() => []}
            customUploadRequest={obj => {
              this.uploadFile(obj.file);
            }}
          />
          <Modal
            title="Inserisci note facoltative"
            visible={this.state.open_modal_com}
            onOk={async () => {
              this.setState({
                filename: 'file',
                note: this.formSelComIsp.props.form.getFieldsValue()['note'] || ""
              }, () => this.upload())
            }}
            onCancel={() => this.setState({ open_modal_com: false })}
          >
            <CommonForm
              wrappedComponentRef={inst => (this.formSelComIsp = inst)}
              loading={this.state.loading || false}
              form_model={formSelLayout}
              values={{

              }}
            />
          </Modal>
        </div> : null*/}

        {this.canSend() ?
        <div>
          <Popconfirm placement="top" title="Confermi la comunicazione?"
          onConfirm={() => {
            if(!this.state.loading) {
              this.setState({
                loading: true
              }, () => {
                  ModelServices.confirm(
                  this.props.accreditamento.id,
                  this.props.comunicazione.id,
                  (res)=> {
                    this.setState({loading: false})
                    this.props.refreshInline(res.data.data)
                  },
                  () => this.setState({loading: false})
                )
              })
            }
          }}
          okText="Si" cancelText="No">
            <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            size="small"
            className="m5h" type="primary" ghost icon="check">Conferma</Button>
          </Popconfirm>
        </div> : null}
    </div>
  }

  render() {
    return (this.state.loaded && this.canView()) ?
      <div style={{marginTop: 16}}>
        <div>
          <h4>Comunicazione ostativa</h4>
          <Tag color="orange">{comunicazioneOstativaStatic.getStatoLabel(this.props.comunicazione)}</Tag><br />
          {this.props.comunicazione.note ? this.props.comunicazione.note : ''}<br />
          {moment(this.props.comunicazione.data_trasmissione, 'YYYY-MM-DD').isValid() ? moment(this.props.comunicazione.data_trasmissione, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' (data trasmissione)' : ''}<br />
          {
            this.props.comunicazione.media.filter(el => el.tipo.sottodominio === comunicazioneOstativaStatic.tipo_media.sottodominio_richiesta_firmata).map(l => {
              return <div key={l.id}><p style={{display: 'inline-block'}} >Comunicazione ostativa confermata: </p><DocSpan key={l.id} file={l} /></div>
            })
          }
          {this.renderActions()}
          <SingleControdeduzione
          accreditamento={this.props.accreditamento}
          comunicazione={this.props.comunicazione}
          refresh={()=>this.props.refresh()}
          refreshInline={(data)=>this.props.refreshInline(data)}
          />
        </div>
      </div>
    : null
  }
}

export default connect(({ UserStore, GlobalStore }) => ({ UserStore, GlobalStore }))(ComunicazioneOstativa);
