import React, { Component } from 'react';

import ModelServices from '../../services/QualificaRepertorioNazionale';

import CommonForm from '@common/components/form';
import { message } from 'antd';

const model = {
  layout: 'vertical',
  multistep: false,
  rows: [
    {
      cols: [
        {
          span: 24,
          items: [
            {
              type: 'input',
              label: 'Denominazione',
              type_string: 'text', // field type
              name: 'descrizione',
              validations: [
                {
                  type: 'required',
                  error_message: 'Campo obbligatorio',
                },
              ],
            }
          ],
        },
      ],
    },
    {
      cols: [
        {
          items: [
            {
              name: 'btn',
              type: 'submit',
              text: 'Conferma',
              btn_type: 'primary'
            },
          ],
        },
      ],
    },
  ],
  onSubmit: 'submitted',
}

class CreateModel extends Component {
  base_url = '/qualifiche_repertorio_nazionale'

  state = {
    loading: false
  }

  submit(data) {

    if (!this.state.loading) {
      this.setState({ loading: true });

      ModelServices.create(data, () => {
        message.success("Operazione effettuata con successo");
        this.props.oncreate();
        this.setState({ loading: false });
      }, () => {
        this.setState({ loading: false })
      })
    }
  }

  render() {
    return (
      <CommonForm
        wrappedComponentRef={inst => (this.formRef = inst)}
        loading={this.state.loading || false}
        form_model={model}
        submitted={(data) => this.submit(data)}
      />
    );
  }
}
export default CreateModel;