export default {
  sectionMap: {
    AF_1: "Personale",
    AF_2: "Coordinatore",
    AF_3: "Tutor",
    AF_4: "Docenti",
    BF_1: "Assicurazione",
    CF_1: "Utenze",
    CF_2: "Cancelleria ",
    DF_1: "Direzione e valutazione finale dell'operazione o del progetto",
    DF_2: "Direttore di corso o di progetto interno",
    DF_3: "Direttore di corso o di progetto esterno",
    DF_4: "Componenti comitati tecnico-scientifici interni",
  },
};
